/* .notifications */

.notifications-channel-container {
	display: flex;
	width: 100%;
	padding: 24px 0px;
} 

.notifications-channel-container-lined {
	border-bottom: solid;
	border-width: 1px;
	border-color: #2B2B30;
}
	.notifications-channel-icon-container {
		width: 36px;
	}
		.notifications-channel-icon {
			font-size: 18px !important;
			color: #9DA0AD;
		}

	.notifications-channel-content {
		width: calc(100% - 78px);
	}	
		.notifications-channel-content h4 {
			font-size: 13.5px;
			color: #ffffff;
			margin: 0px;
		}
		.notifications-channel-content p {
			font-size: 13.5px;
			color: #6C6C6C;
			margin: 4px 0px;
		}
	.notifications-channel-switch-container {
		padding-top: 8px;
	}
		.notifications-card-channel-header-container {
			width: 240px;
			padding-top: 4px;
			display: flex;
			margin-left: auto;
			float: right;
		}
			.notifications-card-channel-header-container h5 {
				font-size: 13px;
				color: #ffffff;
				margin: 0px;
				width: 33.3%;
				text-align: center;
			}


	.notifications-card-row-header {
		width: 100%;
		margin-top: 24px;
		padding: 6px 0px;
		display: flex;
	}
	.notifications-card-row-header-lined {
		border-top: solid;
		padding-top: 24px;
		border-width: 1px;
		border-color: #2B2B30;
	}
		.notifications-card-row-header h4 {
			font-size: 13.5px;
			font-weight: 600;
			color: #ffffff;
			margin: 0px;
		}
		.notifications-card-row-checkbox-container {
			width: 240px;
			padding-top: 4px;
			display: flex;
			margin-left: auto;
			float: right;
		}
			.notifications-card-row-checkbox-container-inner {
				padding-left: 32px;
				width: 33.3%;
			}
	.notifications-card-row {
		width: 100%;
		display: flex;
		padding: 6px 0px;
		padding-left: 16px;
	}
		.notifications-card-row h4 {
			font-size: 13.5px;
			color: #ffffff;
			margin: 0px;
		}





