/* .calendar-day-timeline-cell */

.calendar-day-timeline-cell {
	position: absolute;
	left: 0px;
	top: 20px;
	width: calc(100% - 12px);
	white-space: nowrap;
	padding: 6px 12px;
	border-radius: 5px;
	border: solid;
	border-width: 1px;
	box-shadow: 0px 0px 12px 6px rgba(0, 0, 0, 0.12);
	background: #1F1F21;
	border-color: #2B2B30;
	/*background: rgba(92, 98, 255, 0.2);
	border-color: #7278F2;*/
}
	.calendar-day-timeline-cell-error {
		background: repeating-linear-gradient(
			45deg,
			rgba(255,77,77,0.9),
			rgba(255,77,77,0.9) 6px,
			rgba(255,77,77,0.3) 6px,
			rgba(255,77,77,0.3) 12px
		) !important;
		border-color: rgba(255,77,77,0.8) !important;
	}
	.calendar-day-timeline-cell-scheduling {
		z-index: 100;
		background: repeating-linear-gradient(
			45deg,
			rgba(91,98,255,0.3),
			rgba(91,98,255,0.3) 6px,
			rgba(91,98,255,0) 6px,
			rgba(91,98,255,0) 12px
		);
		border-color: #5B62FF;
		width: calc(100%);
		margin-left: -12px;
	}
	.calendar-day-timeline-cell-scheduling .calendar-day-timeline-cell-inner h4 {
		color: white !important;
		font-weight: 600;
	}
	.calendar-day-timeline-cell-scheduling .calendar-day-timeline-cell-inner p {
		color: white !important;
		font-weight: 600;
	}
		.calendar-day-timeline-cell-inner {
			display: flex;
			margin-top: -3px;
		}
			.calendar-day-timeline-cell-inner h4 {
			    font-size: 13.5px;
			    color: #ffffff;
			    margin: 0px;
			}
			.calendar-day-timeline-cell-inner p {
			    font-size: 13.5px;
			    color: #9DA0AD;
			    margin: 0px !important;
			    margin-left: auto;
			    float: right;
			    text-align: right !important;
			    padding-left: 12px;
			}

