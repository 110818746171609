/* .copy-folder-modal-background- */

.copy-folder-modal-background {
	padding-top: 20vh;
}
	.copy-folder-modal-container {
		height: 60vh;
	}
	.copy-folder-modal-top {
		position: relative;
	}
		.copy-folder-modal-top-error {
			position: absolute;
			bottom: -12px;
		}
			.copy-folder-modal-top-error p {
			    font-size: 13.5px;
			    color: #FF4D4D;
			    margin: 0px;
			}


	.copy-folder-modal-folder-container {
		padding: 20px 32px;	
		padding-top: 0px;
		height: calc(60vh - 164px);
	}
		.copy-folder-modal-folder-container-inner {
			padding: 12px 8px;
			border: solid;
			border-width: 1px;
			border-color: #2B2B30;
			border-radius: 5px;
			max-height: 100%;
			overflow-y: scroll;
		}
	.copy-folder-modal-button-container {
		width: 100%;
		border-top: solid;
		border-width: 1px;
		border-color: #2B2B30;
		padding: 12px 32px;
	}
		.copy-folder-modal-button {
			background: #5B62FF;
			padding: 8px 12px;
			border-radius: 5px;
			box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.12);
			float: right;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
		.copy-folder-modal-button:hover {
			background: #7278F2;
			cursor: pointer;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
			.copy-folder-modal-button p {
			    font-size: 13.5px;
			    color: white;
			    margin: 0px;
			    font-weight: 400;
			}
		.copy-folder-modal-button-unselected {
			opacity: 0.3;
		}
		.copy-folder-modal-button-unselected:hover {
			background: #5B62FF;
			cursor: default;
			opacity: 0.3;
		}
		.copy-folder-modal-button-done {
			margin: 18px calc(50% - 38px);
			height: 32px;
		}
			.copy-folder-modal-button-done p {
			    font-size: 13.5px !important;
			    color: white !important;
			    margin: 0px !important;
			}
		.copy-folder-modal-loading-container {
			width: 100%;
			padding-top: 20%;

		}
			.copy-folder-modal-loading-container .client-list-container-loading-spinner {
				margin-top: 0px;
			}
			.copy-folder-modal-loading-container p {
			    font-size: 13.5px;
			    color: #9DA0AD;
			    margin: 0px;
			    width: 100%;
			    text-align: center;
			    margin-top: 8px;
			}
		.copy-folder-modal-complete-container {
			width: 100%;
			padding-top: 20%;
		}	
			.copy-folder-modal-complete-container h4 {
			    font-size: 18px;
			    color: #ffffff;
			    margin: 0px;
			    width: 100%;
			    text-align: center;
			}
			.copy-folder-modal-complete-container p {
			    font-size: 13.5px;
			    color: #9DA0AD;
			    margin: 0px;
			    margin-top: 8px;
			    width: 100%;
			    text-align: center;
			}






