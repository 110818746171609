/* .intra-workout-series-list- */

.intra-workout-series-list {
	width: 100%;
	padding: 4px 0px;
	height: calc(100vh - 54px);
	overflow-y: hidden;
}
.intra-workout-series-list-summary-header {
	width: 100%;
	padding: 12px 36px;
	padding-bottom: 16px;
	border-bottom: solid;
	border-width: 1px;
	border-color: #2B2B30;
}
	.intra-workout-series-list-summary-header h4 {
		font-size: 13.5px;
		color: #9DA0AD;
		margin: 0px;
		margin-bottom: 12px;
	}
	.intra-workout-series-list-summary-card {
		width: 100%;
		padding: 0px;
		display: flex;
	}
		.intra-workout-series-list-summary-card-col {
			width: 33%;
		}
			.intra-workout-series-list-summary-card-col-header {
				display: flex;
			}
				.intra-workout-series-list-summary-card-col-header p {
					font-size: 10px;
					color: #9DA0AD;
					margin: 0px;
					margin-bottom: 2px;
				}
				.intra-workout-series-list-summary-card-col-header-icon {
					font-size: 10px !important;
					color: #9DA0AD;
					margin-left: 4px;
					margin-top: 2px;
				}
			.intra-workout-series-list-summary-card-col-content {

			}
				.intra-workout-series-list-summary-card-col-content h4 {
					font-size: 18px;
					color: #ffffff;
					margin: 0px;
				}
				.intra-workout-series-list-summary-card-col-content-blue h4 {
					color: #5B62FF !important;
					font-weight: 700;
				}


.intra-workout-series-list-summary-body {
	height: calc(100vh - 136px);
	/*background: red;*/
	overflow-y: scroll;
	padding-bottom: 16px;
}
	.intra-workout-series-list-card {
		margin-bottom: 12px;
		width: 100%;
		padding: 0px;
	}
		.intra-workout-series-list-card-header-container {
			width: 100%;
			display: flex;
			background: #1F1F21;
			padding: 10px 16px;
			padding-left: 36px;
			padding-bottom: 9px;
			position: relative;
		}
			.intra-workout-series-list-card-header-series-letter-container {
				background: #5B62FF;
				width: 28px;
				min-width: 28px;
				height: 28px;
				border-radius: 14px;
				padding: 6px 0px;

			}
				.intra-workout-series-list-card-header-series-letter-container h4 {
					font-size: 14px;
					font-weight: 700;
					color: #1F1F21;
					margin: 0px;
					width: 100%;
					text-align: center;
				}
			.intra-workout-series-list-card-header-series-title-container {
				padding: 7px 12px;
				white-space: nowrap;
				display: flex;
				width: 50%;
				min-width: 50%;
				background: #1F1F21;
			}
				.intra-workout-series-list-card-header-series-title-container h4 {
					font-size: 13.5px;
					color: #ffffff;
					margin: 0px;
				}
				.intra-workout-series-list-card-header-series-title-container p {
					font-size: 13.5px;
					color: #5B62FF;
					margin: 0px;
					margin-left: 8px;
				}
			.intra-workout-series-list-card-header-info-container {
				width: calc(50% - 40px);
				min-width: calc(50% - 40px);
				display: flex;
				padding: 7px 8px;
			}
				.intra-workout-series-list-card-header-info-container p {
					font-size: 10px;
					color: #9DA0AD;
					margin: 0px;
					width: 50%;
					text-align: right;
				}
			.intra-workout-series-list-card-header-expand-icon-container {
				padding: 0px 3.5px;
				width: 22px;
				height: 22px;
				border-radius: 5px;
				position: absolute;
				left: 7px;
				top: 14px;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
			.intra-workout-series-list-card-header-expand-icon-container:hover {
				background: rgba(0,0,0,0.3);
				box-shadow: 0 0px 8px 2px rgba(0, 0, 0, 0.1);
				cursor: pointer;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
				.intra-workout-series-list-card-header-expand-icon {
					font-size: 15px !important;
					color: #6C6C6C;
					margin-top: 3px;
					margin-left: 0px;
				}

		.intra-workout-series-list-card-content-container {
			width: 100%;
			padding: 8px 0px;
			border-top: solid;
			border-width: 1px;
			padding-top: 0px;
			border-color: #2B2B30;
		}
			.intra-workout-series-list-card-exercise-breakdown-container {
				width: 100%;
				padding: 8px 30px;
				padding-right: 12px;
				padding-bottom: 6px;
				
			}
				.intra-workout-series-list-card-exercise-breakdown-exercise-container {
					width: 100%;
					display: flex;
					padding: 6px 0px;
					padding-left: 6px;
					border-radius: 5px;
					margin-bottom: 2px;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
					/*padding-bottom: 4px;*/
				}
				.intra-workout-series-list-card-exercise-breakdown-exercise-container:hover {
					background: #1F1F21;
					cursor: pointer;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
				.intra-workout-series-list-card-exercise-breakdown-exercise-container-selected {
					background: efblue;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
					.intra-workout-series-list-card-exercise-breakdown-exercise-container-selected h4 {
						color: #ffffff !important;
					}
					.intra-workout-series-list-card-exercise-breakdown-exercise-letter-container {
						/*background: #1F1F21; */
						
						width: 84px;
						max-width: 72px;
						height: 28px;
						max-height: 28px;
						min-width: 28px;
						border-radius: 14px;
						border: solid;
						border-width: 1px;
						border-color: #5B62FF;
						background: #5B62FF;
						padding: 5px 0px;
						display: flex;
						position: relative;
					}
					.intra-workout-series-list-card-exercise-breakdown-exercise-letter-container-small {
						width: 28px;
						padding: 6px 0px;
						border-radius: 14px;
						border: solid;
						border-width: 1px;
						border-color: #5B62FF;
						padding: 6px 0px;
						background: transparent;
					}
						.intra-workout-series-list-card-exercise-breakdown-exercise-letter-container h4 {
							

							font-size: 13.5px;
							color: #1F1F21 !important;
							margin: 0px;
							width: calc(100% - 12px);
							float: right;
							margin-left: auto;
							font-weight: 600;
							text-align: center;
						}
						.intra-workout-series-list-card-exercise-breakdown-exercise-letter-container-small h4 {
							font-size: 11px;
							color: #5B62FF !important;
							margin: 0px;
							width: 100%;
							font-weight: 600;
							text-align: center;
						}
					.intra-workout-series-list-card-exercise-breakdown-exercise-letter-container-unselected {
						border-color: #2B2B30;
						background: transparent;
					}
						.intra-workout-series-list-card-exercise-breakdown-exercise-letter-container-unselected h4 {
							color: #6C6C6C !important;
						}
					.intra-workout-series-list-card-exercise-breakdown-exercise-letter-container-current {
						width: 84px;
						max-width: 72px;
						height: 28px;
						max-height: 28px;
						min-width: 28px;
						border-radius: 14px;
						border: solid;
						border-width: 1px;
						border-color: #5B62FF;
						padding: 5px 0px;
						display: flex;
						position: relative;
					}	
						.intra-workout-series-list-card-exercise-breakdown-exercise-letter-container-current h4 {
							font-size: 13.5px;
							color: #5B62FF !important;
							margin: 0px;
							width: calc(100% - 12px);
							float: right;
							margin-left: auto;
							font-weight: 600;
							text-align: center;
						}
						.intra-workout-series-list-card-exercise-breakdown-exercise-letter-icon-container-check {
							position: absolute;
							left: 5px;
							top: 5px;
						}
							.intra-workout-series-list-card-exercise-breakdown-exercise-letter-icon-check {
								font-size: 14px !important;
								color: #1F1F21;
							}
						.intra-workout-series-list-card-exercise-breakdown-exercise-letter-icon-container-dot {
							width: 8px;
							height: 8px;
							min-width: 8px;
							background: #5B62FF;
							border-radius: 4px;
							position: absolute;
							top: 9px;
							left: 8px;
						}
							.intra-workout-series-list-card-exercise-breakdown-exercise-letter-icon-container-dot-blue {
								background: #5B62FF;
							}
							.intra-workout-series-list-card-exercise-breakdown-exercise-letter-icon-container-dot-unselected {
								background: #2B2B30;
							}
					.intra-workout-series-list-card-exercise-breakdown-exercise-title-container {
						padding: 7px 12px;
					}
						.intra-workout-series-list-card-exercise-breakdown-exercise-title-container h4 {
							font-size: 13.5px;
							color: #9DA0AD;
							margin: 0px;
						}
					.intra-workout-series-list-card-exercise-breakdown-icon-container {
						width: 20px;
						float: right;
						margin-left: auto;
						padding-top: 3px;
					}
						.intra-workout-series-list-card-exercise-breakdown-icon {
							font-size: 8px !important;
							color: #6C6C6C;
						}

		.intra-workout-series-list-card-set-breakdown-container {
			width: 100%;
			padding: 0px;
		}
			.intra-workout-series-list-card-set-breakdown-header-container {
				width: calc(100%);
				display: flex;
				padding-top: 8px;
				padding-left: 36px;
				border-top: solid;
				border-width: 1px;
				border-color: #2B2B30;
			}
				.intra-workout-series-list-card-set-breakdown-header-content {
					width: calc(33% - 10px);
				}
					.intra-workout-series-list-card-set-breakdown-header-content p {
						font-size: 10px;
						color: #6C6C6C;
						margin: 0px;
					}

					.intra-workout-series-list-card-set-breakdown-set-container {
						width: 100%;
						display: flex;
						padding: 8px 0px;
						padding-bottom: 6px;
						padding-left: 30px;
						position: relative;
						border-bottom: solid;
						border-width: 1px;
						border-color: #2B2B30;
					}

						.intra-workout-series-list-card-set-breakdown-current-set-indicator {
							position: absolute;
							top: 8px;
							left: -30px;
							height: calc(100% - 14px);
							width: 4px;
							border-radius: 2px;
							background: #5B62FF;
						}
					.intra-workout-series-list-card-set-breakdown-set-title-container {
						background: rgba(73,89,193,0.24);
						width: 32px;
						border-radius: 5px;
						margin-right: 8px;
						margin-bottom: 3px;
						position: relative;
					}
						.intra-workout-series-list-card-set-breakdown-set-title-content {
							width: 100%;
							height: 30px;
							margin-top: auto;
							position: absolute;
							top: calc(50% - 16px);
						}
							.intra-workout-series-list-card-set-breakdown-set-title-content p {
								font-size: 10px;
								color: #5B62FF;
								margin: 0px;
								width: 100%;
								text-align: center;
							}
							.intra-workout-series-list-card-set-breakdown-set-title-content h4 {
								font-size: 13.5px;
								color: #5B62FF;
								margin: 0px;
								width: 100%;
								text-align: center;
							}

						.intra-workout-series-list-card-set-breakdown-set-title-container-unselected {
							background: rgba(255,255,255,0.04);
						}
							.intra-workout-series-list-card-set-breakdown-set-title-content-unselected p {
								color: #6C6C6C;
							}
							.intra-workout-series-list-card-set-breakdown-set-title-content-unselected h4 {
								color: #6C6C6C;
							}
					.intra-workout-series-list-card-set-breakdown-set-exercises-container {
						width: calc(100% - 12px);
					}
						.intra-workout-series-list-card-set-breakdown-set-exercises-row {
							display: flex;
							position: relative;
							width: 100%;
							padding: 6px 0px;
							padding-left: 6px;
							border-radius: 5px;
							margin-bottom: 2px;
							-webkit-transition: all 0.15s;
							-moz-transition: all 0.15s;
							transition: all 0.15s;
						}
						.intra-workout-series-list-card-set-breakdown-set-exercises-row:hover {
							background: #1F1F21;
							cursor: pointer;
							-webkit-transition: all 0.15s;
							-moz-transition: all 0.15s;
							transition: all 0.15s;
						}

						.intra-workout-series-list-card-set-breakdown-set-exercises-row-selected {
							background: rgba(91,98,255,0.4);
							-webkit-transition: all 0.15s;
							-moz-transition: all 0.15s;
							transition: all 0.15s;
						}
							.intra-workout-series-list-card-set-breakdown-set-exercises-row-selected:hover {
								background: rgba(91,98,255,0.5);
								-webkit-transition: all 0.15s;
								-moz-transition: all 0.15s;
								transition: all 0.15s;
							}
						.intra-workout-series-list-card-set-breakdown-set-exercises-row-selected .intra-workout-series-list-card-exercise-breakdown-input-container {
							border-color: #5B62FF;
							background: rgba(0,0,0,0.3);
						}
						.intra-workout-series-list-card-set-breakdown-set-exercises-row-selected .intra-workout-series-list-card-exercise-breakdown-input-container-focused {
							border-color: #7278F2;
						}

				.intra-workout-series-list-card-exercise-breakdown-input-outter-container {
					width: 100%;
					display: flex;
					padding-left: 16px;
				}
				.intra-workout-series-list-card-exercise-breakdown-input-outter-container-unselected {
					opacity: 0.5;
				}
					.intra-workout-series-list-card-exercise-breakdown-input-container {
						display: flex;
						border-color: #2B2B30;
						background: rgba(0,0,0,0.1);
						border-width: 1px;
						border-style: solid;
						border-radius: 4px;
						width: calc(50% - 28px);
						position: relative;
						height: 28px;
						padding-left: 8px;
						-webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}
					.intra-workout-series-list-card-exercise-breakdown-input-container-focused {
						border-color: #5B62FF;
						background: rgba(0,0,0,0.3);
						box-shadow: 0px 0px 8px 4px rgba(0,0,0,0.15);
						-webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}
						.intra-workout-series-list-card-exercise-breakdown-input-container p {
							margin: 0px;
							color: #9DA0AD;
							font-size: 8px;
							position: absolute;
							top: 9.5px;
							right: 4px;
						}
						.intra-workout-series-list-card-exercise-breakdown-input {
							margin: 0px;
							width: 100%;
							text-align: center;
							font-size: 13.5px;
						}
					.intra-workout-series-list-card-exercise-breakdown-input-icon-container {
						width: 20px;
						height: 20px;
						padding-top: 3px;
						padding-left: 4px;
					}
						.intra-workout-series-list-card-exercise-breakdown-input-icon {
							font-size: 10px !important;
							color: #6C6C6C;
						}
					.intra-workout-series-list-card-exercise-breakdown-select-icon-container {
						width: 20px;
						height: 20px;
						padding-top: 2px;
						padding-left: 2px;
						float: right;
						margin-left: auto;
					}
						.intra-workout-series-list-card-exercise-breakdown-select-icon {
							font-size: 8px !important;
							color: #6C6C6C;
						}







