.bottom-gradient {
	position: absolute;
	bottom: 0px;
	left: 0px;
	width: 100%;
	height: 100px;
	background: rgb(33,48,62);
	background: linear-gradient(0deg, #1D242F 40%, rgba(33,48,62,0) 100%);
}

.bottom-button-container {
	margin: auto;
	margin-top: 32px;
	margin-left: calc(100% - 280px);
	width: 250px;
}

.bottom-button-container p {
	margin: 8px;
}

.clients-cta-button {
	font-size: 14px;
	margin: auto 8px;
	margin-left: auto;
	float: right;
	display: inline-block;
	padding: 10px 24px;
	padding-right: 36px;
	background-color: #5B62FF;
	color: white;
	border-radius: 4px;
	position: relative;
	box-shadow: 0 4px 38px 0 rgba(0, 0, 200, 0.15);
}

.client-header {
	display: flex;
}

.add-icon {
	position: absolute;
	top: 11px;
	right: 12px;
}