.header-container {
	text-align: left;
}

.header-container h1 {
	margin: 0px !important;
	font-size: 13.5px;
	color: #ffffff;
}


.header-container p {
	margin-bottom: 24px;
	font-size: 13.5px;
	color: #9DA0AD !important;
}



.bullet-points {
	margin: 2px !important;
}