/* .plan-folder-selector- */

.plan-folder-selector {
	width: 100%;
}

.plan-folder-selector-row-container {
	position: relative;
	width: 100%;
	display: flex;
	padding: 4px 12px;
	padding-top: 6px;
	/*background: #2E3247;*/
	border-radius: 5px;
	/*padding-left: 24px;*/
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
}
.plan-folder-selector-row-container:hover {
	background: #1F1F21 !important;
	cursor: pointer;
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s; 
}
.plan-folder-selector-row-container-selected {
	background: rgba(91, 98, 255, 0.2) !important;
}
	.plan-folder-selector-row-icon-container {
		width: 20px;
		height: 20px;
		border-radius: 5px;
		padding-left: 4px;
		z-index: 1;
	}
	.plan-folder-selector-row-icon-container-folder {
		background: rgba(128,73,193,0.24);
	}
	.plan-folder-selector-row-icon-container-cycle {
		background: rgba(184,193,73,0.12);
	}
	.plan-folder-selector-row-icon-container-plan {
		background: rgba(73,89,193,0.24);
	}
		.plan-folder-selector-row-icon {
			font-size: 11px !important;
						
		}
			.plan-folder-selector-row-icon-folder {
				color: #8049C1;
			}
			.plan-folder-selector-row-icon-cycle {
				color: #B8C149;
			}
			.plan-folder-selector-row-icon-plan {
				color: #4959C1;
			}
	.plan-folder-selector-row-text-container {
		margin-left: 6px;
		padding: 0px 6px;
		padding-top: 2px;
		margin-top: -1px;
		border-radius: 5px;
	}
		.plan-folder-selector-row-text-container p {
			font-size: 13.5px !important;
			margin: 0px;
		}
		.plan-folder-selector-row-text-container-folder p {
			color: #ffffff !important;
		}
		.plan-folder-selector-row-text-container-plan p {
			color: #6C6C6C !important;
		}
		.plan-folder-selector-row-text-container-plan-unbuilt {
			background: rgba(91, 98, 255, 0.2);
		}
		.plan-folder-selector-row-text-container-plan-unbuilt p {
			color: #5B62FF !important;
			font-weight: 500;
		}
