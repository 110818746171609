/* .my-coaching-profile-page */

.my-coaching-profile-page {
	width: 100%;
	height: 100%;
	display: flex;
	position: relative;
}
.my-coaching-profile-curtain {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background: rgba(22, 24, 32, 0.6);
	-webkit-backdrop-filter: blur(4px);
	z-index: 100;
}
	.my-coaching-profile-curtain p {
	    font-size: 13.5px;
	    color: #9DA0AD;
	    margin: 0px;
	    width: 100%;
	    text-align: center;
	    margin-top: 12px;
	}
		.my-coaching-profile-curtain .workout-plan-content-loading-spinner {
			margin-top: calc(50vh - 100px);
		}



	.my-coaching-profile-page-pane {

	}
	.my-coaching-profile-page-pane-left {
		width: 50%;
		height: 100%;
	}
		.my-coaching-profile-page-pane-left-header-container {
			padding: 32px 38px;
			border-bottom: solid;
			border-width: 1px;
			border-color: #2B2B30;
			box-shadow: 0px 8px 12px 6px rgba(0, 0, 0, 0.08);
			display: flex;
		}
			.my-coaching-profile-page-pane-left-header-title {
				width: calc(100% - 120px);
			}
				.my-coaching-profile-page-pane-left-header-title h1 {
				    font-size: 28px;
				    color: white;
				    margin: 0px;
				    margin-bottom: 8px;
				}
				.my-coaching-profile-page-pane-left-header-title p {
				    font-size: 13.5px;
				    color: #9DA0AD;
				    margin: 0px;
				}
			.my-coaching-profile-page-pane-left-header-button-container {
				width: 120px;
				padding-top: 0px;
			}
				.my-coaching-profile-page-pane-left-header-button {
				    padding: 10px 8px;
				    background: #5B62FF;
				    display: flex;
				    border-radius: 5px;
				    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.12);
				    -webkit-transition: all 0.15s;
				    -moz-transition: all 0.15s;
				    transition: all 0.15s;
							}
				.my-coaching-profile-page-pane-left-header-button:hover {
				    background: #7278F2;
				    cursor: pointer;
				    -webkit-transition: all 0.15s;
				    -moz-transition: all 0.15s;
				    transition: all 0.15s;
				}
					.my-coaching-profile-page-pane-left-header-button p {
					    font-size: 13.5px;
					    color: white;
					    font-weight: 600;
					    margin: 0px;
					    width: 100%;
					    text-align: center;
					    white-space: nowrap;
					}
		.my-coaching-profile-page-content {
			width: 100%;
			height: calc(100% - 122px);
			padding: 32px 38px;
			overflow-y: scroll;
		}
			.my-coaching-profile-page-content-section {
				padding-bottom: 32px;
				margin-bottom: 32px;
				border-bottom: solid;
				border-width: 1px;
				border-color: #2B2B30;

			}
				.my-coaching-profile-page-content-section-header {
					padding-bottom: 12px;
				}
					.my-coaching-profile-page-content-section-header h4 {
					    font-size: 18px;
					    color: #ffffff;
					    margin: 0px;
					    margin-bottom: 4px;
					}
					.my-coaching-profile-page-content-section-header p {
					    font-size: 13.5px;
					    color: #9DA0AD;
					    margin: 0px;
					}

			.my-coaching-profile-page-content-section-content-sections {
				width: 100%;
				padding: 24px 0px;
				padding-bottom: 48px;
			}
				.my-coaching-profile-page-content-section-content-sections-inner {

				}
			.my-coaching-profile-credentials-input-container {
				margin-top: 24px !important;
			}
			.my-coaching-profile-page-content-section-content-background {
				width: 100%;
			}
				.my-coaching-profile-page-content-section-content-background-section {
					width: 100%;
					padding: 12px 0px;
					padding-bottom: 60px;
				}
					.my-coaching-profile-page-content-section-content-background-section-header {
						display: flex;
						width: 100%;
						padding-top: 12px;
					}
						.my-coaching-profile-page-content-section-content-background-section-header h4 {
						    font-size: 13.5px;
						    color: #ffffff;
						    margin: 0px;
						}
						.my-coaching-profile-page-content-section-content-background-section-header-icon {
							font-size: 15px !important;
							color: #7278F2;
							margin-right: 10px;
						}
					.my-coaching-profile-page-content-section-content-background-section-body {
						width: 100%;
						padding-left: 24px;
					}
						.my-coaching-profile-page-content-section-content-background-section-body-list-container {
							padding: 20px 0px;
						}
						.my-coaching-profile-page-content-section-content-background-section-body .my-coaching-profile-page-content-section-content-sections-add-button {
							margin-left: 0px;
						}

					.my-coaching-profile-page-content-section-content-background-section-body-empty-container {
						width: 100%;
						padding: 24px 0px;
					}
						.my-coaching-profile-page-content-section-content-background-section-body-empty-container p {
						    font-size: 13.5px;
						    color: #6C6C6C;
						    margin: 0px;
						}



			.my-coaching-profile-drop-area {
				margin: 0px auto;
				margin-top: 24px;
				width: calc(100% - 48px);
				/*background: #161820;*/
				/*width: calc(100% - 48px);
				border: dashed;
				border-width: 1px;
				border-color: #6C6C6C;
				border-radius: 8px;
				margin: 0px auto;
				margin-top: 24px;
				padding: 28px 20px;
				box-shadow: 0px 0px 12px 6px rgba(0, 0, 0, 0.08);*/
			}
				.my-coaching-profile-drop-area-inner-container {
					width: 100%;
				}

				.my-coaching-profile-drop-area-inner-container-has-image {
					display: flex;
					padding-left: 24px;
				}
					.my-coaching-profile-drop-area-image-container {
						width: 100px;
						border-radius: 5px;
						max-height: 100px;
						overflow: hidden;
					}
						.my-coaching-profile-drop-area-image-container img {

						}
					.my-coaching-profile-drop-area-text-container {
						padding-left: 24px;
						padding-top: 26px;
					}
						.my-coaching-profile-drop-area-text-container h4 {
						    font-size: 18px !important;
						    color: #ffffff !important;
						    margin: 0px;
						}
						.my-coaching-profile-drop-area-text-container .my-profile-information-section-card-body-image-content-remove-button {
							margin-top: 4px;
						}
						.my-coaching-profile-drop-area-text-container .my-profile-information-section-card-body-image-content-remove-button p {
							font-size: 12px;
							margin-left: 6px;
							margin-top: -1px;
							color: #6C6C6C;
							text-align: left !important;
						}


					.my-coaching-profile-drop-area h4 {
					    font-size: 15px !important;
					    color: #ffffff;
					    margin: 0px;
					    width: 100% !important;
					    text-align: center;
					}
					.my-coaching-profile-drop-area p {
					    font-size: 13.5px;
					    color: #6C6C6C;
					    margin: 0px;
					    width: 100%;
					    text-align: center;
					    margin-top: 6px;
					}
					.my-coaching-profile-drop-area-icon-container {
						margin: 12px auto;
						margin-top: 0px;
						width: 20px;
					}
						.my-coaching-profile-drop-area-icon {
							color: #9DA0AD;
							font-size: 20px !important;
						}

			.my-coaching-profile-page-content-section-checkbox-container {
				padding: 12px 0px;
				width: 100%;
			}
				.my-coaching-profile-page-content-section-checkbox-content {
					width: 100%;
					display: flex;
					padding-left: 24px;
				}
					.my-coaching-profile-page-content-section-checkbox {
						width: 30px;
					}
					.my-coaching-profile-page-content-section-checkbox-text-container {
						width: calc(100% - 30px);
					}
						.my-coaching-profile-page-content-section-checkbox-text-container h4 {
						    font-size: 18px;
						    color: #ffffff;
						    margin: 0px;
						}
						.my-coaching-profile-page-content-section-checkbox-text-container p {
						    font-size: 13.5px;
						    color: #9DA0AD;
						    margin: 0px;
						}


			.my-coaching-profile-page-content-section-content-themes {
				display: inline-block;
				padding: 24px 0px;
			}
				.my-coaching-profile-page-theme-card {
					width: 240px;
					margin-right: 16px;
					margin-bottom: 16px;
					display: inline-block;
					box-shadow: 0px 0px 12px 6px rgba(0, 0, 0, 0.12);
					border-radius: 8px;
					border: solid;
					border-width: 1px;
					border-color: #2B2B30;
					cursor: pointer;
				}
					.my-coaching-profile-page-theme-card-theme {
						width: 100%;
						height: 120px;
						border-radius: 8px;
						padding: 16px 16px;
					}
						.my-coaching-profile-page-theme-card-theme-item {
							border-radius: 3px;
							margin-bottom: 4px;
						}
						.my-coaching-profile-page-theme-card-theme-header-primary {
							width: 50px;
							height: 8px;
						}
						.my-coaching-profile-page-theme-card-theme-header {
							width: 120px;
							height: 12px;
						}
						.my-coaching-profile-page-theme-card-theme-body {
							width: 100%;
							height: 5px;
							margin-bottom: 3px;
						}
						.my-coaching-profile-page-theme-card-theme-body-top {
							margin-top: 8px;
						}
						.my-coaching-profile-page-theme-card-theme-body-bottom {
							width: 67%;
						}
						.my-coaching-profile-page-theme-card-theme-button {
							width: 100%;
							height: 24px;
							margin-top: 12px;
							padding: 8px 0px;
						}
						.my-coaching-profile-page-theme-card-theme-button-text {
							width: 50px;
							height: 8px;
							margin: 0px auto;
						}


					.my-coaching-profile-page-theme-card-title {
						padding: 16px 12px;
						display: flex;
					}
						.my-coaching-profile-page-theme-card-title h4 {
						    font-size: 18px;
						    color: #ffffff;
						    margin: 0px;
						    margin-left: 12px;
						    margin-top: -2px;
						}

				.my-coaching-profile-page-content-section-content-sections-add-button {
					float: left;
					background: rgba(0, 0, 0, 0.2);
					border-radius: 5px;
					padding: 8px 12px;
					padding-left: 8px;
					display: flex;
					margin-left: 50px;
					margin-top: 8px;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
				.my-coaching-profile-page-content-section-content-sections-add-button:hover {
					background: rgba(0, 0, 0, 0.3);
					cursor: pointer;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
					.my-coaching-profile-page-content-section-content-sections-add-button-icon {
						color: #7278F2;
						margin-right: 4px;
						font-size: 15px !important;
					}
					.my-coaching-profile-page-content-section-content-sections-add-button p {
					    font-size: 13.5px;
					    color: #7278F2;
					    margin: 0px;
					}
				.my-coaching-profile-page-content-section-background-training-since-container {
					width: calc(100% - 48px);
					display: flex;
					margin: 12px auto;
					margin-bottom: 20px;
					padding: 24px 24px;
					padding-bottom: 8px;
					background: #1F1F21;
					border: solid;
					border-width: 1px;
					border-color: #2B2B30;
					border-radius: 5px;
					box-shadow: 0px 0px 12px 6px rgba(0, 0, 0, 0.12);
				}
					.my-coaching-profile-page-content-section-background-training-since-text {
						padding-right: 12px;
					}
						.my-coaching-profile-page-content-section-background-training-since-text h4 {
						    font-size: 18px;
						    color: #ffffff;
						    margin: 0px;
						    white-space: nowrap;
						}






	.my-coaching-profile-page-pane-right {
		background: #161820;
		width: 50%;
		height: 100%;
		overflow-y: scroll;
		border-left: solid;
		border-width: 1px;
		border-color: #2B2B30;
		box-shadow: 0px 38px 48px 16px rgba(0, 0, 0, 0.12);
	}
		.my-coaching-profile-page-pane-right-header-container {
			width: 100%;
			padding: 32px 38px;
			display: flex;
			padding-bottom: 12px;
		}
			.my-coaching-profile-page-pane-right-header-title {
				width: 100%;
			}
				.my-coaching-profile-page-pane-right-header-title h1 {
				    font-size: 28px;
				    color: white;
				    margin: 0px;
				    margin-bottom: 8px;
				}
		.my-coaching-profile-page-pane-right-header-view-selector {
			display: flex;
			margin-left: auto;
			float: right;
			border: solid;
			border-width: 1px;
			border-color: #2B2B30;
			border-radius: 5px;
			height: 34px;
		}
			.my-coaching-profile-page-pane-right-header-view-selector-option {
				padding: 8px 0px;
				width: 80px;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
			.my-coaching-profile-page-pane-right-header-view-selector-option-left {
				border-right: solid;
				border-width: 1px;
				border-color: #2B2B30;
			}
			.my-coaching-profile-page-pane-right-header-view-selector-option:hover {
				background: #1F1F21;
				cursor: pointer;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
				.my-coaching-profile-page-pane-right-header-view-selector-option p {
				    font-size: 13.5px;
				    color: #6C6C6C;
				    margin: 0px;
				    font-weight: 600;
				    width: 100%;
				    text-align: center;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
				.my-coaching-profile-page-pane-right-header-view-selector-option:hover p {
					color: #ffffff;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
			.my-coaching-profile-page-pane-right-header-view-selector-option-highlighted p {
				color: #7278F2 !important;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}

			.my-coaching-profile-page-pane-right-content-container {
				width: 100%;
				position: relative;

			}
				/* START PHONE CSS */





				/* END PHONE CSS */

				.my-coaching-profile-page-pane-right-content-bottom-text {
					width: 420px;
					margin: 16px auto;
				}
					.my-coaching-profile-page-pane-right-content-bottom-text p {
					    font-size: 12px;
					    color: #9DA0AD;
					    margin: 0px;
					    width: 100%;
					    text-align: center;
					}
					.my-coaching-profile-page-pane-right-content-bottom-text a {
						color: #7278F2;
						text-decoration: none;
					}
				.my-coaching-profile-page-pane-right-content-scroll-floater {
					position: absolute;
					bottom: 58px;
					left: calc(50% - 250px);
				}
					.my-coaching-profile-page-pane-right-content-scroll-floater p {
					    font-size: 12px;
					    color: #7278F2;
					    margin: 0px;
					    font-weight: 600;

					}
					.my-coaching-profile-page-pane-right-content-scroll-floater-icon {
						font-size: 15px !important;
						color: #7278F2;
						margin-left: 9px;
					}





