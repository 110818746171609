/* .full-analysis-composition- */

.full-analysis-composition-container {
	width: 100%;
	display: block;
	padding: 12px 0px;
}

.full-analysis-composition-selector-container {
	width: 100%;
	display: flex;
}

.full-analysis-composition-selector-option-line {
	width: 100%;
	height: 2px;
	margin-top: 4px;
	background: #4959C1;
	border-radius: 1px;
}

.full-analysis-composition-selector-option {
	padding-right: 32px;
	font-size: 14px;
}

.full-analysis-composition-selector-option-selected {
	color: #C1C4CC;
}

.full-analysis-composition-selector-option-unselected {
	color: #5F6C76;
}

.full-analysis-composition-chart-container {
	width: 100%;
}

.full-analysis-composition-chart-content {
	width: 100%;
	height: 300px;
}

.full-analysis-composition-coming-soon-container {
	width: 100%;
	height: 100%;
	padding-top: 100px;
}

.full-analysis-composition-coming-soon-container p {
	display: block;
	height: 14px;
	font-size: 12px;
	color: #C1C4CC;
	width: 100%;
	text-align: center;
	margin: auto;
}


