

.check-box {
	width: 16px;
	height: 16px;
	border-radius: 5px;
	border: solid;
	border-width: 1px;
	border-color: #2B2B30;
	padding-left: 1px;
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
	cursor: pointer;
}
.check-box:hover {
	border-color: #5C6BC9;
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
}
	.check-box-selected {
		background: #5B62FF;
		border-color: rgba(0,0,0,0);
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}
	.check-box-selected:hover {
		background: #7278F2;
		border-color: rgba(0,0,0,0);
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}

	.check-box-icon-container {
		width: 100%;
		height: 100%;
		margin-top: -2px;
	}
		.check-box-icon {
			font-size: 12px !important;
			color: #ffffff;
			
		}
	.check-box-disabled {
		opacity: 0.3;
	}

