.schedule-messages-modal-background {
	padding-top: calc(100px);
}
.schedule-messages-modal {
	height: calc(100vh - 200px);
	margin: auto;
	margin-top: 0px;
	min-width: 800px;
	width: 70%;
	background: var(--ef-background);
	border-radius: var(--ef-radius-m);
	border: solid;
	border-width: 0.5px;
	border-color: #2B2B30;
	box-shadow: 0 0px 100px 12px rgba(0, 0, 0, 0.5);
	position: relative;
	padding-bottom: 4px;
}
.schedule-messages-modal-wrapper {
	width: 100%;
	height: 100%;
}
.schedule-messages-modal-wrapper-loading {
	align-items: center;
	justify-content: center;
	padding-top: calc(50vh - 120px - 20px);
}
	.schedule-messages-modal-wrapper-loading p {
	    font-size: var(--ef-font-size-s);
	    color: var(--ef-text-dark);
	    margin: 0px;
	    width: 100%;
	    text-align: center;
	    margin-top: 8px;
	}

	.schedule-messages-modal-header {
		display: flex;
		height: 66px;
		border-bottom: solid;
		border-width: 0.5px !important;
		border-color: var(--ef-border);
		padding: 12px 24px;
	}
		.schedule-messages-modal-header-title-container {
			display: flex;
		}
			.schedule-messages-modal-header-title-automated-circle-container {
				align-items: center;
	            justify-content: center;
	            width: fit-content;
	            height: fit-content;
	            width: 42px;
	            height: 42px;
	            border-radius: 21px;
	            overflow: hidden;
	            padding: 11px 11px;
	            background: var(--ef-color-phase-transparent);
	            box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.2);

			}
				.schedule-messages-modal-header-title-automated-circle-icon {
					font-size: 20px !important;
					color: var(--ef-color-phase);
				}
				.schedule-messages-modal-header-title-automated-line {
					border-top: dashed;
					border-width: 1px;
					border-color: var(--ef-color-phase);
					width: 20px;
					height: 1px;
					margin-top: 21px;
				}
			.schedule-messages-modal-header-title-image-container {
	            align-items: center;
	            justify-content: center;
	            width: fit-content;
	            height: fit-content;
	            width: 42px;
	            height: 42px;
	            border-radius: 21px;
	            overflow: hidden;
	            background: #0B0C0F;
	            box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.2);
			}
				.schedule-messages-modal-header-title-image {
					width: 42px;
	                height: 42px;
				}
			.schedule-messages-modal-header-title {
				padding: 10px 0px;
				margin-left: 16px;
			}
				.schedule-messages-modal-header-title h4 {
				    font-size: var(--ef-font-size-l);
				    color: var(--ef-text-light);
				    font-weight: 400;
				    margin: 0px;
				}
		.schedule-messages-modal-header-save-container {
			margin-left: auto;
			float: right;
			padding: 7px 0px;
		}
			.schedule-messages-modal-header-save-button {
			    padding: 6px 10px;
			    background: var(--ef-cta);
			    display: flex;
			    border-radius: 5px;
			    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.12);
			    -webkit-transition: all 0.15s;
			    -moz-transition: all 0.15s;
			    transition: all 0.15s;
			}
			.schedule-messages-modal-header-save-button:hover {
			    background: var(--ef-cta-light);
			    cursor: pointer;
			    -webkit-transition: all 0.15s;
			    -moz-transition: all 0.15s;
			    transition: all 0.15s;
			}
			.schedule-messages-modal-header-save-button p {
			    font-size: var(--ef-font-size-s);
			    color: var(--ef-text-white);
			    margin: 0px;
			}
    .schedule-messages-modal-body {
    	width: 100%;
    	border-top: solid;
		border-width: 2px !important;
		border-color: var(--ef-border-highlight-dark);
		padding: 20px 32px;
    }
    	.schedule-messages-modal-body-info-container {
    		width: 100%;
    		padding-bottom: 20px;
    	}
    	.schedule-messages-modal-body-options-container {
    		display: flex;
    		padding-bottom: 12px;
    		border-bottom: solid;
			border-width: 1px;
			border-color: var(--ef-border);
    	}
    		.schedule-messages-modal-body-info-container p {
			    font-size: var(--ef-font-size-s);
			    color: var(--ef-text-mid);
			    margin: 0px;
			    font-weight: 300;
			}
    		.schedule-messages-modal-body-option {
    			padding: 6px 10px;
    			padding-bottom: 7px;
    			margin-right: 6px;
    			border-radius: var(--ef-radius-m);
    			border: solid;
				border-width: 1px;
				border-color: var(--ef-border);
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
    		}
    		.schedule-messages-modal-body-option:hover {
    			border-color: var(--ef-cta-light);
    			cursor: pointer;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
    		}
    		.schedule-messages-modal-body-option-selected {
				border-color: var(--ef-cta);
				background: var(--ef-cta-pseudotransparent);
    		}
    			.schedule-messages-modal-body-option p {
				    font-size: var(--ef-font-size-s);
				    color: var(--ef-text-dark);
				    margin: 0px;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
				.schedule-messages-modal-body-option-selected p {
					color: var(--ef-text-light);
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
				.schedule-messages-modal-body-option:hover p {
					color: var(--ef-text-light);
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}

		.schedule-messages-modal-body-content {
			width: 100%;
		}
			.schedule-messages-modal-body-content-wrapper {
				width: 100%;
				padding-top: 20px;
			}
			.schedule-messages-modal-body-content-wrapper-empty {
				width: 100%;
				padding-top: 200px;
			}
				.schedule-messages-modal-body-content-empty-header {
					width: 100%;
				}
					.schedule-messages-modal-body-content-empty-header p {
					    font-size: var(--ef-font-size-s);
					    color: var(--ef-text-dark);
					    margin: 0px;
					    width: 100%;
					    text-align: center;
					}

				.schedule-messages-modal-body-content-empty-button-container {
					width: 100%;
				}
					.schedule-messages-modal-body-content-empty-button {
					    padding: 6px 0px;
					    margin: 12px auto;
					    width: 120px;
					    background: var(--ef-cta);
					    display: flex;
					    border-radius: 5px;
					    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.12);
					    -webkit-transition: all 0.15s;
					    -moz-transition: all 0.15s;
					    transition: all 0.15s;
					}
					.schedule-messages-modal-body-content-empty-button:hover {
					    background: var(--ef-cta-light);
					    cursor: pointer;
					    -webkit-transition: all 0.15s;
					    -moz-transition: all 0.15s;
					    transition: all 0.15s;
					}
						.schedule-messages-modal-body-content-empty-button p {
						    font-size: var(--ef-font-size-s);
						    color: var(--ef-text-white);
						    margin: 0px;
						    width: 100%;
						    text-align: center;
						    white-space: nowrap;
						}





