.new-program-modal-container {

	height: 240px;
	margin: auto;
	margin-top: 0px;
	min-width: 400px;
	width: 67%;
	max-width: 600px;
	background: #161719;
	border-radius: var(--ef-radius-m);
	border: solid;
	border-width: 0.5px;
	border-color: #2B2B30;
	box-shadow: 0 0px 100px 12px rgba(0, 0, 0, 0.5);
	position: relative;
	padding-bottom: 4px;
}
.new-program-modal-background {
	padding-top: calc(50vh - 180px);
}

.new-program-modal-top {
	width: 100%;
	padding: 16px 36px;
	padding-bottom: 4px;
}

.new-program-modal-top h3 {
	margin-bottom: 8px;
	font-weight: 600;
	color: #ffffff;
	font-size: 18px;
}

.new-program-modal-top p {
	margin: 0px;
	color: #9DA0AD;
	font-size: 13.5px;
	line-height: 18px !important;
	min-height: 36px;
}
	.new-program-modal-top span {
		margin: 0px;
		color: #ffffff;
		font-weight: 600;
		font-size: 13.5px;
		line-height: 18px !important;
	}

.new-program-modal-body-container {
	width: 100%;
	padding: 0px 36px;
	padding-right: 28px;
	height: 80px;
}
	.new-program-modal-input-container {
		width: 100%;
		height: 100%;
		max-height: 100px;
		overflow-y: hidden;
	}
	.new-program-modal-body-container-loading {
		padding-top: 100px;
	}
		.new-program-modal-body-container-loading p {
			font-size: 13.5px;
			color: #6C6C6C;
			margin: 0px;
			margin-top: 8px;
			width: 100%;
			text-align: center;
		}

	.new-program-modal-body-container-phases {
		display: flex;
	}
		.new-program-modal-body-container-phases .new-program-modal-input-container {
			width: calc(100% - 240px);
			margin: auto;
		}
		.new-program-modal-body-container-phases .new-program-modal-input-container ion-input {
			text-align: center !important;
		}
		.new-program-modal-input-button-container {
			width: 100px;
			padding: 6px;
			background: #1F1F21;
			height: 36px;
			margin-top: 10px;
			border-radius: 5px;
			border:  solid;
			border-color: #2B2B30;
			border-width: 1px;
			box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
		.new-program-modal-input-button-container:hover {
			background: #28282D;
			cursor: pointer;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
			.new-program-modal-input-button-container p {
				width: 100%;
				text-align: center;
				margin: 0px;
				color: #9DA0AD;
			}
	.new-program-modal-body-container-done {
		padding-top: 70px;
	}
		.new-program-modal-body-container-done h4 {
			font-size: 18px;
			color: #ffffff;
			margin: 0px;
			margin-top: 0px;
			width: 100%;
			text-align: center;
		}
		.new-program-modal-body-container-done p {
			font-size: 13.5px;
			color: #6C6C6C;
			margin: 0px;
			margin-top: 6px;
			width: 100%;
			text-align: center;
		}
		.new-program-modal-top span {
			margin: 0px;
			color: #ffffff;
			font-weight: 600;
			font-size: 13.5px;
		}


.new-program-modal-bottom {
	width: 100%;
	height: 60px;
	padding: 28px;
	padding-top: 3px;
	display: flex;
	/*float: right;*/
}

.new-program-modal-close-button {
	font-size: 13.5px;
	float: left;
	border-radius: 5px;
	color: #C2C5CD;
	background: #1F1F21;
	padding: 6px 12px;
	margin-top: 4px;
	margin-left: auto;
	float: right;
	display: inline-block;
	cursor: pointer;
	margin-right: 18px;
	border: solid;
	border-width: 1px;
	border-color: #2B2B30;
	text-align: center;
	height: 30px;
	box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
}
	.new-program-modal-close-button:hover {
		background: #28282D;
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}

.new-program-modal-cta-button {
	font-size: 14px;
	float: right;
	background: var(--ef-cta);
	border-radius: 5px;
	color: white;
	padding: 6px 12px;
	margin-top: 4px;
	display: inline-block;
	cursor: pointer;
	box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
	height: 30px;
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
}
	.new-program-modal-cta-button:hover {
		background: var(--ef-cta-light);
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}
	.new-program-modal-cta-button-done {
		margin: 0px auto;
		margin-top: 48px;
		display: block;
		float: none;
		width: 58px;
	}

.new-program-modal-cta-button-red {
	font-size: 13.5px;
	float: right;
	background-color: rgba(255,60,60);
	border-radius: 5px;
	height: 30px;
	color: white;
	text-align: center;
	padding: 6px 12px;
	margin-top: 4px;
	display: inline-block;
	cursor: pointer;
	box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
}
.new-program-modal-cta-button-red:hover {
	background-color: rgba(255,80,80);
	
}

.new-program-modal-floating-close {
	position: absolute;
	top: 18px;
	right: 24px;
	width: 30px;
	height: 30px;
	padding: 5px;
	color: #6C6C6C;
	border-radius: 5px;
	cursor: pointer;
	z-index: 500;
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
}

.new-program-modal-floating-close:hover {
	color: #ffffff;
	background: #28282D;
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
}