.activity-feed {
	width: 100%;
	padding: 0px;
}

.activity-feed-today-container {
	padding-bottom: 24px;
}
	.activity-feed-today-header-container {
		padding: 24px 0px;
	}
		.activity-feed-today-header-container h4 {
		    font-size: 18px;
		    font-weight: 400;
		    color: #ffffff;
		    margin: 0px;
		}
	.activity-feed-end-container {

		display: flex;
	}
		.activity-feed-end-icon {
			width: 22px;
			height: 22px;
			border-radius: 11px;
			border-color: #2B2B30 !important;
			border-width: 2px !important;
			border: solid;
			margin-left: 14px;
			margin-top: 8px;
		}
		.activity-feed-end-container p {
		    font-size: 13.5px;
		    color: #6C6C6C80;
		    margin: 0px;
		    margin-left: 6px;
		    margin-top: 10px;
		}