.create-workout-exercise-list-container {
	height: calc(100% - 28px);
	max-height: calc(100% - 28px);
	width: calc(100% + 48px);
	margin-left: -24px;
	padding: 24px;
	overflow-y: scroll;
}
	.create-workout-exercise-list-cell-outter-container {
		width: 100%;
	}
	.create-workout-exercise-list-cell-purposed-container {
		height: 4px;
		width: 100%;
		border-radius: 2px;
		background: var(--ef-cta-light);
		margin-bottom: 2px;
	}

.create-workout-exercise-list-new-exercise-container  {
	position: absolute;
	top: -102px;
	right: 0px;
	
	padding: 8px 12px;
	padding-right: 12px;
	display: flex;
	font-size: 13.5px !important;
	border-radius: 5px;
	background: black;
	color: #ffffff !important;
	background: var(--ef-cta);
	border: solid;
	border-width: 0.5px;
	border-color: #2B2B30;
	margin: auto;
	margin-top: 24px;
	box-shadow: 0 2px 6px 2px rgba(0,0,0,0.1);
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
}
.create-workout-exercise-list-new-exercise-container:hover {
	background-color: var(--ef-cta-light);
	cursor: pointer;
	box-shadow: 0 2px 20px 0 rgba(0, 0, 50, 0.05);
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
}
	.create-workout-exercise-list-new-exercise-container  p {
		width: 100%;
		text-align: center;
		font-size: 12px;
		font-weight: 600;
		margin: 0px;
		color: white;
		margin-right: 12px;
	}

.new-workout-modal-page-header-text-right {
	font-size: 13.5px !important;
	margin-top: -8px;
	padding: 0px;
	padding-right: 8px;
	color: #ffffff !important;
}

	.create-workout-exercise-list-empty-container {
		width: calc(80% + 48px);
		padding: 0px 38px;
		max-width: 800px;
		margin: 0px 0px;
		display: flex;
		padding-top: 12px;
		margin-top: 10vh;
		/*margin-top: 24px;
		border-radius: 5px;
		border: solid;
		border-color: #2B2B30;
		border-width: 1px;
		background: #1F1F21;
		box-shadow: 0 2px 12px 8px rgba(0, 0, 0, 0.1);*/
	}
		.create-workout-exercise-list-empty-image-container {
			max-width: 100px;
			min-width: 100px;
			padding-top: 24px;
			margin-right: 38px;
		}
		.create-workout-exercise-list-empty-content-container {
			display: block;
			margin-top: 24px;
		}
			.create-workout-exercise-list-empty-content-container h4 {
				font-size: 18px;
				color: #ffffff;
				margin: 0px;
				margin-bottom: 6px;
			}
			.create-workout-exercise-list-empty-content-container p {
				font-size: 13.5px;
				color: #9DA0AD;
				width: 100%;
				margin: 12px 0px;
				line-height: 18px;
			}
				.create-workout-exercise-list-empty-content-button-row-container {
					display: flex;
				}

			.create-workout-exercise-list-empty-button-container {
				display: flex;
				float: left;
				white-space: nowrap;
				width: auto !important;
				padding: 8px 12px !important;
				padding-right: 12px !important;
				background: var(--ef-cta);
				border-radius: 5px;
				margin: 24px 0px;
				box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
			.create-workout-exercise-list-empty-button-container:hover {
				background: var(--ef-cta-light);
				cursor: pointer;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
				.create-workout-exercise-list-empty-button-container p {
					color: white !important;
					font-size: 13.5px;
					font-weight: 500;
					width: 100%;
					text-align: center;
					margin: 0px 0px;
					/*margin-right: 12px;*/
					padding-top: 0px;
				}
			.create-workout-exercise-list-empty-button-hotkey-container {
				margin-left: 2px;
				background: rgba(255, 255, 255, 0.3);
				border-radius: 5px;
				padding: 0px 7px;
				margin-top: -1px;
			}
				.create-workout-exercise-list-empty-button-hotkey-container p {
				    font-size: 11px;
				    margin: 0px;
				}





