.client-picker-row-input-container {
	width: 100%;
	position: relative;
}
	.client-picker-row-input-container .date-selector-input-container {
		background: #1F1F21;
	}
	.client-picker-client-name-input-container {
		height: 38px;
		/*background-color: rgba(0,0,1,0.1);*/
		background: #1F1F21;
		padding: 0px 8px;
		width: auto;
		border-radius: 20px;
		border: solid;
		border-color: #181818;
		border-width: 1px !important;
		font-size: 13.5px;
		color: #ffffff;
		display: flex;
		box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}
	.client-picker-client-name-input-container:hover {
		background-color: #28282D;
		box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}
	.client-picker-client-name-input-container-focus {
		border-color: #5B62FF;
		background-color: rgba(0,0,1,0.1);
		box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}
		.client-picker-client-name-input-arrow-icon-container {
			width: 22px;
			height: 22px;
			
			border-radius: 5px;
			padding: 4px 5px;
			margin-top: 6px;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
		.client-picker-client-name-input-arrow-icon-container:hover {
			background: rgba(0,0,0,0.2);
			cursor: pointer;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}	
			.client-picker-client-name-input-arrow-icon {
				font-size: 13.5px !important;
				color: #6C6C6C;
			}
			.client-picker-client-name-input-arrow-icon-container:hover .client-picker-client-name-input-arrow-icon {
				color: #ffffff;
			}
		.client-picker-row-input-container-date-duration-container {
			position: absolute;
			right: 12px;
			top: 9px;
		}
			.client-picker-row-input-container-date-duration-container p {
			    font-size: 13.5px;
			    color: #9DA0AD;
			    margin: 0px;
			}
		.client-picker-client-name-menu-container {
			position: absolute;
			top: 50px;
			right: 0px;
			width: calc(100%);
			min-height: 100px;
			max-height: 260px;
			background: #1F1F21;
			border-radius: 16px;
			box-shadow: 0 4px 20px 12px rgba(0, 0, 0, 0.1);
			overflow-y: scroll;
			z-index: 100;
		}
				.client-picker-client-name-input-container-selected-client {
					padding-top: 6px;
					background: #000000;
				}
					.client-picker-client-name-input-container-selected-client-text-container {
						padding-top: 4px;

					}
						.client-picker-client-name-input-container-selected-client-text-container h4 {
						    font-size: 13.5px;
						    color: #ffffff;
						    margin: 0px;
						}

					.client-picker-client-name-input-container-selected-client-remove-container {
						width: 22px;
						height: 22px;
						margin-top: 2px;
						border-radius: 5px;
						padding: 3px 4px;
						margin-left: auto;
						float: right;
						-webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}
					.client-picker-client-name-input-container-selected-client-remove-container:hover {
						background: #28282D;
						cursor: pointer;
						-webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}
						.client-picker-client-name-input-container-selected-client-remove-icon {
							font-size: 13.5px !important;
							color: #6C6C6C;
						}
						.client-picker-client-name-input-container-selected-client-remove-container:hover .assign-modal-body-client-name-input-container-selected-client-remove-icon {
							color: #ffffff;
						}




