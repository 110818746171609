/* new-appointment-modal */

.new-appointment-modal-background {
	padding-top: 8px;
	background: rgba(12,13,20,0.4);
	-webkit-backdrop-filter: blur(1px);
}
	.new-appointment-modal {
		height: calc(100vh - 16px);
		margin: auto;
		margin-right: 8px;
		margin-top: 0px;
		min-width: 500px;
		width: 33%;
		max-width: 600px;
		background: #161719;
		border-radius: 5px;
		border: solid;
		border-width: 0.5px;
		border-color: #2B2B30;
		box-shadow: 0 0px 100px 12px rgba(0, 0, 0, 0.3);
		position: relative;
		padding: 32px;
	}
		.new-appointment-modal-header {
			width: 100%;
			padding: 4px 0px;
			padding-bottom: 24px;
			border-bottom: solid;
			border-width: 1px;
			border-color: #2B2B30;
			position: relative;
		}
			.new-appointment-modal-header p {
			    font-size: 13.5px;
			    color: #9DA0AD;
			    margin: 0px;
			    margin-bottom: 2px;
			}
			.new-appointment-modal-header h2 {
			    font-size: 28px;
			    color: #ffffff;
			    margin: 0px;
			}
			.new-appointment-modal-header-floating-close {
				position: absolute;
				top: 4px;
				right: 0px;
				width: 27px;
				height: 27px;
				padding: 3px;
				border-radius: 5px;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
			.new-appointment-modal-header-floating-close:hover {
				cursor: pointer;
				background: rgba(0, 0, 0, 0.2);
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
				.new-appointment-modal-header-floating-close-icon {
					font-size: 20px !important;
					color: #6C6C6C;
				}
				.new-appointment-modal-header-floating-close:hover .new-appointment-modal-header-floating-close-icon {
					color: #ffffff;
				}
			.new-appointment-modal-client-name-menu-search-container {
				height: 40px;
				width: 100%;
				background: red;
			}
				.new-appointment-modal-client-name-menu-inner-container {
					background: red;
				}
		.new-appointment-modal-content {
			width: calc(100% + 32px);
			margin-left: -16px;
			padding: 12px 0px;
			height: calc(100% - 48px);
		}
			.new-appointment-modal-content-body {
				width: 100%;
				height: calc(100% - 70px);
				padding: 0px 16px;
				position: relative;
			}
			.new-appointment-modal-curtain {
				height: calc(100vh - 300px);
				left: -12px;
				width: calc(100% + 24px);
			}
				.new-appointment-modal-content-body .assign-modal-body-row {
					padding: 12px 0px;
				}
				.new-appointment-modal-content-body .assign-modal-body-client-name-menu-container {
					width: 100%;
					right: 0px;
				}

			.new-appointment-modal-content-button-container {
				width: 100%;
				padding: 12px 16px;
			}
				.new-appointment-modal-content-button {
					width: 100%;
					background: #5B62FF;
					border-radius: 5px;
					box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.12);
					padding: 12px 12px;
					display: flex;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
				.new-appointment-modal-content-button:hover {
					background: #7278F2;
					cursor: pointer;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
					.new-appointment-modal-content-button p {
					    font-size: 13.5px;
					    font-weight: 500;
					    color: white;
					    margin: 0px;
					    width: 100%;
					    text-align: center;
					    padding-bottom: 2px;
					}
					.new-appointment-modal-content-button-icon {
						font-size: 14px !important;
						margin-top: 1px;
					}
				.new-appointment-modal-content-button-unselected {
					opacity: 0.3;
				}
				.new-appointment-modal-content-button-unselected:hover {
					background: #5B62FF;
					cursor: default;
				}

			.assign-modal-body-row-timeline-container {
				width: 100%;
				height: calc(100vh - 440px);
				overflow-y: scroll;
			}
				.assign-modal-body-row-timeline-container p {
				    font-size: 12px;
				    color: #9DA0AD;
				    margin: 0px;
				    width: 100%;
				    text-align: center;
				    margin-top: 8px;
				}
				.new-appointment-modal-content-loading-spinner-trainer-schedule {
					margin-top: 15vh;
				}
			.new-appointment-modal-content-loading {
				padding-top: calc(40vh - 100px);
			}
				.new-appointment-modal-content-loading p {
				    font-size: 13.5px;
				    color: #9DA0AD;
				    margin: 0px;
				    margin-top: 12px;
				    width: 100%;
				    text-align: center;
				}
		.assign-modal-body-row-col-container {
			width: 100%;
			display: flex;
		}
			.assign-modal-body-col-3 {
				width: 33%;
				padding-right: 12px;
			}
			.assign-modal-body-col-2 {
				width: 50%;
				padding-right: 12px;
			}











