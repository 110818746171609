

.rep-x-weight-breakdown-info-container {
    width: 100%;
}

.rep-x-weight-breakdown-info-row-container {
    width: 100%;
    padding-top: 12px;
}

.rep-x-weight-breakdown-info-blank-container {
    padding-top: 40px;
    text-align: center;
    color: #5F6C76;
}

.rep-x-weight-breakdown-info-row-content {
    width: 100%;
    padding: 6px 12px;
    display: flex;
    color: #C1C4CC;
}

.rep-x-weight-breakdown-info-row-name-container {
    width: 20%;
}

.rep-x-weight-breakdown-info-row-reps-container {
    width: 20%;
}

.rep-x-weight-breakdown-info-row-weight-container {
    width: 20%;
}

.rep-x-weight-breakdown-info-row-header-container {
    width: 20%;
    font-size: 12px;
    color: #5F6C76;
}