/* .workout-plan-list-cycle-overview- */

.workout-plan-list-cycle-overview {
	width: 100%;
	height: 100%;
	position: relative;
}
    .workout-plan-list-cycle-overview-loading-overlay {
        position: absolute;
        left: 0px;
        top: 0px;
        z-index: 10;
        width: 100%;
        height: 100%;
        background: #1F1F21A0;
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
    }
        .workout-plan-list-cycle-overview-loading-overlay p {
            width: 100%;
            text-align: center;
            font-size: var(--ef-font-size-s);
            color: var(--ef-text-dark);
            margin: 0px;
            margin-top: 12px;
        }

	.workout-plan-list-cycle-overview-header-card {
		/*background: #1F1F21;
        
        box-shadow: 0 4px 38px 0 rgba(0, 0, 0, 0.1);*/
        border-bottom: solid;
        border-width: 1px;
        border-color: var(--ef-border-highlight-dark);
        width: 100%;
        padding: 20px 24px;
        display: flex;
        position: relative;
	}
        .workout-plan-list-cycle-overview-header-card h1 {
            font-size: var(--ef-font-size-xl);
            color: var(--ef-text-white);
            margin: 0px;
            margin-top: 2px;
        }
		.workout-plan-list-cycle-overview-header-card-icon-container {
			width: 32px;
            height: 32px;
            border-radius: var(--ef-radius-m);
            padding: 6px 6px;
            background: var(--ef-color-program-transparent);
		}
			.workout-plan-list-cycle-overview-header-card-icon {
				font-size: 20px !important;
				color: var(--ef-color-program);
			}
		.workout-plan-list-cycle-overview-header-card-text-container {
			padding-left: 8px;
			padding-top: 0px;
            flex: 1;
		}
        .workout-plan-list-cycle-overview-header-card-menu-button-container-button-right {
            margin-left: 12px !important;
        }
        .workout-plan-list-cycle-overview-header-card-menu-button-container p {
            font-size: var(--ef-font-size-s) !important;
        }
    .workout-plan-list-cycle-overview-header-card-line {
        width: 100%;
        height: 1px;
        background: var(--ef-border);
    }
	.workout-plan-list-cycle-overview-body-container {
		width: 100%;
		height: calc(100% - 74px);
		overflow-y: scroll;
        padding: 24px 24px;
        padding-bottom: 20px;
	}
    .workout-plan-list-cycle-overview-body-container-phases {
        width: 100%;
        max-width: 100%;
        padding: 16px 16px;
        background: #1F1F21;
    }
        .workout-plan-list-cycle-overview-body-container-phases-header {
            width: 100%;
        }
            .workout-plan-list-cycle-overview-body-container-phases-header h4 {
                font-size: 13.5px;
                color: #9DA0AD;
                margin: 0px;
                margin-bottom: 28px;
            }
        .workout-plan-list-cycle-overview-body-container-phases-content {
            width: 100%;
        }
            .workout-plan-list-cycle-overview-card-content-container-inner-phases {
                display: inline-block;
                margin-bottom: 16px;
            }

    .workout-plan-list-cycle-overview-body-new-cycle-button-container {
        width: 100%;
        height: 50px;
        margin-top: 8px;
        border: solid;
        border-width: 1px;
        background: var(--ef-button-card);
        border-color: var(--ef-border);
        border-radius: var(--ef-radius-m);
        box-shadow: 0px 1px 6px 1px rgba(0,0,0,0.3);
        -webkit-transition: all 0.15s;
        -moz-transition: all 0.15s;
        transition: all 0.15s;
    }
    .workout-plan-list-cycle-overview-body-new-cycle-button-container:hover {
        background: var(--ef-card-light);
        border-color: var(--ef-border-light);
        cursor: pointer;
        -webkit-transition: all 0.15s;
        -moz-transition: all 0.15s;
        transition: all 0.15s;
    }
        .workout-plan-list-cycle-overview-body-new-cycle-button-inner {
            display: flex;
            height: 20px;
            margin: 14px calc(50% - 60px);
        }
            .workout-plan-list-cycle-overview-body-new-cycle-button-icon-container {
                width: 17px;
                height: 17px;
                border-radius: 6px;
                border: solid;
                border-width: 1px;
                border-color: var(--ef-color-cycle);
                background-color: var(--ef-color-cycle-transparent);
                margin-right: 10px;
                margin-top: 1px;
            }
                .workout-plan-list-cycle-overview-body-new-cycle-button-icon {
                    font-size: 16px !important;
                    color: var(--ef-color-cycle);
                }
            .workout-plan-list-cycle-overview-body-new-cycle-button-inner p {
                font-size: var(--ef-font-size-m);
                color: var(--ef-text-light);
                margin: 0px;
                white-space: nowrap;
            }

	.workout-plan-list-header-button-container {
            display: flex;
        }
            .workout-plan-list-header-button {
                padding: 6px 12px !important;
                padding-right: 12px;
                display: flex;
                font-size: 10px;
                border-radius: 5px;
                background: black;
                margin-left: 12px;
                color: #ffffff !important;
                float: right;
            	margin-left: auto;
                margin-top: 0px !important;
                white-space: nowrap;
                height: 30px !important;
            }

            .workout-plan-list-header-button-copy {
                background: #1F1F21 !important;
                border: solid;
                border-width: 1px;
                border-color: #2B2B30;
                box-shadow: 0 2px 4px 2px rgba(5, 5, 23, 0.1);
                white-space: nowrap;
                -webkit-transition: all 0.15s;
                -moz-transition: all 0.15s;
                transition: all 0.15s;
            }
            .workout-plan-list-header-button-copy:hover {
                background: #1F1F21 !important;
                -webkit-transition: all 0.15s;
                -moz-transition: all 0.15s;
                transition: all 0.15s;
            }
                .workout-plan-list-header-button p {
                	color: #ffffff;
                    font-size: var(--ef-font-size-s) !important;
                    margin-right: 8px;
                    font-weight: 500;
                }
                .workout-plan-list-header-upper-content-container-buttons-icon {
                    font-size: 10px !important;
                    margin-top: 2px;
                }

                .workout-plan-list-header-button-dark {
                    background: #1F1F21 !important;
                    border: solid;
                    border-width: 0.5px;
                    border-color: #2E3247;
                    box-shadow: 0 2px 4px 2px rgba(5, 5, 23, 0.1);
                }
                .workout-plan-list-header-button-dark:hover {
                   
                }

                .workout-plan-list-header-button-blue {
                    background: var(--ef-cta);
                    border: none !important;
                    color: white !important;
                    padding-top: 9px;
                    box-shadow: var(--ef-shadow-button-dark) !important;
                }
            .workout-plan-list-header-button-blue-unselected {
                background: rgba(92, 98, 255, 0.3) !important;
                position: relative;
                cursor: default !important;
            }
            .workout-plan-list-header-button-blue-unselected:hover {
                background: rgba(92, 98, 255, 0.3) !important;
                position: relative;
                cursor: default !important;
            }
            .workout-plan-list-header-button-blue:hover .workout-plan-list-header-button-blue-unselected {
                cursor: default !important;
                background: rgba(92, 98, 255, 0.3) !important;
            }
                .workout-plan-list-header-button-blue-unselected p {
                    opacity: 0.3 !important;
                }
                .workout-plan-list-header-button-blue-unselected .workout-plan-list-header-upper-content-container-buttons-icon {
                    opacity: 0.3;
                }
                .workout-plan-list-header-button-tooltip {
                    display: none;
                    position: absolute;
                    z-index: 100;
                    right: 0px;
                    top: 34px;
                    width: 240px;
                    padding: 16px;
                    border-radius: 5px;
                    box-shadow: 0px 0px 24px 12px rgba(0, 0, 0, 0.2);
                    background: rgba(0, 0, 0, 0.6);
                    -webkit-backdrop-filter: blur(2px);
                    backdrop-filter: blur(2px);
                }
                    .workout-plan-list-header-button-tooltip p {
                        font-size: 13.5px;
                        color: #9DA0AD;
                        margin: 0px;
                        white-space: normal;
                        opacity: 1.0 !important;
                    }
                .workout-plan-list-header-button-blue:hover .workout-plan-list-header-button-tooltip {
                    display: block;
                }
        .workout-plan-list-header-add-menu-container {
            position: absolute;
            width: 360px;
            right: 20px !important;
            top: 50px !important;
            background: #1F1F21;
            z-index: 200;
            border-radius: 5px;
            box-shadow: 0 2px 24px 12px rgba(5, 5, 23, 0.12);
            -webkit-transition: all 0.35s;
            -moz-transition: all 0.35s;
            transition: all 0.35s;
        }
        .workout-plan-list-header-add-menu-container-cycle {
            right: 120px !important;
        }
            .workout-plan-list-header-add-menu-content {
                position: relative;
                padding: 16px;
                padding-top: 20px;
                height: 168px;
            }
            .workout-plan-list-header-add-menu-content-loading {
                width: 100%;
                padding-top: 0px;
            }
                .workout-plan-list-header-add-menu-content-loading p {
                    font-size: 10px;
                    width: 100%;
                    text-align: center;
                    margin: 0px;
                    padding-top: 68px;
                }
            .workout-plan-list-header-add-menu-content-complete {
                width: 100%;
                padding-top: 0px;
            }
                .workout-plan-list-header-add-menu-content-complete h4 {
                    font-size: 13.5px;
                    width: 100%;
                    text-align: center;
                    margin: 0px;
                    padding-top: 54px;
                }
                .workout-plan-list-header-add-menu-content-complete p {
                    font-size: 10px;
                    width: 100%;
                    text-align: center;
                    margin: 0px;
                }
                .workout-plan-list-header-add-menu-triangle {
                    /*position: relative;
                    top: -12px;
                    left: 260px;
                    width: 0;
                    height: 0;
                    border-left: 12px solid transparent;
                    border-right: 12px solid transparent;
                    border-bottom: 20px solid #1A1B2A;*/
                }
                    .workout-plan-list-header-add-menu-content-header {

                    }
                        .workout-plan-list-header-add-menu-content-header h4 {
                            font-size: 13.5px;
                            color: #ffffff;
                            margin: 0px;
                            margin-bottom: 4px;
                        }
                        .workout-plan-list-header-add-menu-content-header p {
                            font-size: 10px;
                            color: #9DA0AD;
                            margin: 0px;
                        }
                    .workout-plan-list-header-add-menu-content-input-button {

                        width: 100%;
                        padding: 6px;
                        font-size: 13.5px;
                        text-align: center;
                        border-radius: 5px;
                        height: 32px;
                    }
                    .workout-plan-list-header-add-menu-content-input-button-full {
                    	width: 100% !important;
                    	max-width: 100%;
                    	margin-top: 0px;
                    }
                        .workout-plan-list-header-add-menu-content-input-button-dark {
                            background: #181B2A;
                            border: solid;
                            border-width: 0.5px;
                            border-color: #2E3247;
                            color: #6C6C6C;
                            box-shadow: 0 2px 20px 0 rgba(5, 5, 23, 0.2);
                            white-space: nowrap;
                        }
                            .workout-plan-list-header-add-menu-content-input-button-dark p {
                                white-space: nowrap;

                            }
                        .workout-plan-list-header-add-menu-content-input-button-blue {
                            background: #4959C1;
                            border: none;
                            color: #ffffff;
                            box-shadow: 0 2px 6px 2px rgba(23, 23, 200, 0.2);
                        }

                .workout-plan-list-header-add-menu-content-input-container-cycles {
                    display: flex;
                    width: 100%;
                    padding: 12px 0px;
                }
                    .workout-plan-list-header-add-menu-content-input-content {
                        width: 33%;

                        border-radius: 5px;
                        border-width: 1px !important;
                        border-color: #2B2B30 !important;
                        border: solid;
                        height: 36px;
                        padding: 9px 0px;
                        margin: 0px auto;
                    }
                        .workout-plan-list-header-add-menu-content-input-content h4 {
                            font-size: 13.5px;
                            width: 100%;
                            text-align: center;
                            margin: 0px;
                            color: #ffffff;
                        }
                    .workout-plan-list-header-add-menu-content-input-content-button {
                        width: calc(33% - 12px);
                        border-radius: 5px;
                        border-width: 1px !important;
                        border-color: #2B2B30 !important;
                        border: solid;
                        height: 36px;
                        padding: 9px 0px;
                        background: #1F1F21 !important;
                        box-shadow: 0 2px 12px 2px rgba(0, 0, 0, 0.12);
                        -webkit-transition: all 0.15s;
                        -moz-transition: all 0.15s;
                        transition: all 0.15s;
                    }
                    .workout-plan-list-header-add-menu-content-input-content-button:hover {
                        background: #28282D !important;
                        -webkit-transition: all 0.15s;
                        -moz-transition: all 0.15s;
                        transition: all 0.15s;
                        cursor: pointer;
                    }
                        .workout-plan-list-header-add-menu-content-input-content-button h3 {
                            font-size: 13.5px;
                            width: 100%;
                            text-align: center;
                            margin: 0px;
                            color: #ffffff;
                        }

        .workout-plan-list-cycle-overview-header-button-dark {
            
            border-radius: 5px;
            border-width: 0.5px !important;
            border-color: #2B2B30 !important;
            border: solid;
            height: 30px;
            padding: 6.5px 8px;
            float: right;
            margin-left: 12px;
            margin-top: 3px;
            background: #1F1F21 !important;
            box-shadow: 0 2px 12px 2px rgba(0, 0, 0, 0.12);
            -webkit-transition: all 0.15s;
            -moz-transition: all 0.15s;
            transition: all 0.15s;
            display: flex;
        }
        .workout-plan-list-cycle-overview-header-button-dark-icon {
            font-size: 10px !important;
            margin-top: 3px;
            color: #ffffff;
        }
        .workout-plan-list-cycle-overview-header-button-dark:hover {
            background: #1F1F21 !important;
            -webkit-transition: all 0.15s;
            -moz-transition: all 0.15s;
            transition: all 0.15s;
            cursor: pointer;
        }
            .workout-plan-list-cycle-overview-header-button-dark p {
                font-size: 12px !important;
                width: 100%;
                text-align: center;
                margin: 0px;
                color: #ffffff;
                font-weight: 500;
                white-space: nowrap;
            }

.workout-plan-list-cycle-overview-header-card-text-container-editing {
    display: flex;
    margin-top: -14px !important;
    height: 20px;
}


    .workout-plan-list-cycle-overview-header-card-text-container-save-button {
        padding: 7px 12px;
        padding-right: 16px;
        background: var(--ef-cta);
        border-radius: 5px;
        display: flex;
        height: 36px;
        margin-top: 11px;
        margin-left: 8px;
        -webkit-transition: all 0.15s;
        -moz-transition: all 0.15s;
        transition: all 0.15s;
    }
    .workout-plan-list-cycle-overview-header-card-text-container-save-button:hover {
        background: var(--ef-cta-light);
        cursor: pointer;
        -webkit-transition: all 0.15s;
        -moz-transition: all 0.15s;
        transition: all 0.15s;
    }
        .workout-plan-list-cycle-overview-header-card-text-container-save-button p {
            font-size: 13.5px;
            color: white;
            font-weight: 600;
            margin-top: 2px;
            margin-left: 6px;
        }
        .workout-plan-list-cycle-overview-header-card-text-container-save-button-icon {
            font-size: 13.5px !important;
            margin-top: 3px;
            color: #ffffff;
        }




