.client-card {
	background-color: #2E3A48;
	box-shadow: 0 0px 40px 0 rgba(0, 0, 0, 0.07);
	width: 100%;
	border-radius: 6px;
	display: flex;
	padding: 20px 30px;
}

.client-card-image-container {
	width: 150px;
}

.client-card-content-container {
	width: calc(100% - 150px);
	display: block;
}

.client-card-content-body {
	display: flex;
}

.client-card-user-info-container {
	width: 50%;
}

.client-card-user-notes-container {
	width: 50%;
}

.client-card-image {
	width: 80px;
	height: 80px;
	border-radius: 40px;
	background-color: rgba(0,0,0,0.3);
}

.pill-container {
	padding-top: 12px;
	display: flex
}

.pill {
	height: 28px;
	width: auto;
	display: inline-block;
	padding: 6px 12px;
	font-size: 12px;
	background-color: rgba(0,0,0,0.3);
	border-radius: 14px;
	margin-right: 12px;
}

.search-container {
	width: 100%;
	padding-top: 24px;
}

.scroller-container {
	padding-top: 24px;
}

.scroller-container p {
	font-size: 12px;
	opacity: 0.8;
	margin-bottom: 8px;
}

.workout-card-container {
	width: calc(100% + 120px);
	height: 500px;
	margin-left: -60px;
	padding-left: 60px;
	display: inline-flex;
	overflow-x: scroll;
}