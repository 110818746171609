.workout-plan-list-plan-overview {
	
	height: 100%;
}

.workout-plan-list-plan-overview-header-card-icon-container-cycle {
	border-color: rgba(184,193,73,1);
}
	.workout-plan-list-plan-overview-header-card-icon-cycle {
		color: #B8C149;
	}

	.workout-plan-list-plan-overview-body-card {
		width: 100%;
        padding: 0px 4px;   
        padding-bottom: 0px; 
        display: flex;
        overflow-x: scroll;
	}
	.workout-plan-list-cycle-overview-header-card-menu-button-container {
		height: 32px;
		margin-top: 0px !important;
		background: var(--ef-card);
		border: solid;
        border-width: 1px;
        border-color: var(--ef-border);
        box-shadow: var(--ef-shadow-button-dark);
        float: right;
        margin-left: auto;
        border-radius: var(--ef-radius-s);
        padding: 6px 12px;
        display: flex;
        -webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}
		.workout-plan-list-cycle-overview-header-card-menu-button-container p {
			font-size: var(--ef-font-size-s) !important;
			color: var(--ef-text-mid);
			font-weight: 400;
		}
		.workout-plan-list-cycle-overview-header-card-menu-button-container:hover {
			background: var(--ef-card-light);
			cursor: pointer;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
		.workout-plan-list-cycle-overview-header-card-menu-button-icons {
			font-size: 13.5px !important;
			color: #9DA0AD;
			margin-left: 8px;
		}

		.workout-plan-list-cycle-overview-header-card-menu-container {
			position: absolute;
			top: 64px;
			right: 50px;
			width: 200px;
			min-height: 60px;

			border: solid !important;

			border-color: #2B2B30 !important;
			border-width: 0.999px !important;
			
			border-radius: var(--ef-radius-m);
			background: var(--ef-card);
			box-shadow: 0px 4px 24px 12px rgba(0, 0, 0, 0.2);
			padding: 12px 0px;
			z-index: 200;
		}
			.workout-plan-list-cycle-overview-header-card-menu-header-row {
				padding: 4px 16px;
				padding-bottom: 12px;
				border-bottom: solid;
				border-color: #00000030;
				border-width: 1px;
			}
				.workout-plan-list-cycle-overview-header-card-menu-header-row p {
					font-size: 13.5px;
					font-weight: 500;
					color: #ffffff;
					margin: 0px;
				}
			.workout-plan-list-cycle-overview-header-card-menu-header-row-divider {
				width: 100%;
				margin-bottom: 8px;
				border-bottom: solid;
				border-color: #2B2B30;
				border-width: 1px;
			}
			.workout-plan-list-cycle-overview-header-card-menu-row {
				width: calc(100% - 8px);
				padding: 8px 8px;
				margin: 0px 4px;
				display: flex;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
				border-radius: 5px;
			}
			.workout-plan-list-cycle-overview-header-card-menu-row:hover {
				background: rgba(200,220,240,0.04);
				cursor: pointer;
				color: #ffffff !important;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
				.workout-plan-list-cycle-overview-header-card-menu-row p {
					font-size: 13.5px;
					color: #ffffff;
					margin: 0px;
					font-weight: 500;
					margin-top: 3px;
					margin-left: 8px;
				}
				.workout-plan-list-cycle-overview-header-card-menu-row:hover p {
					color: #ffffff !important;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
			.workout-plan-list-cycle-overview-header-card-menu-row-icon-container {
				min-width: 20px;
				height: 20px;
				border-radius: 5px;
				background: rgba(255,255,255,0);
				padding: 3px 0px;
			}
			.workout-plan-list-cycle-overview-header-card-menu-row:hover .workout-plan-list-cycle-overview-header-card-menu-row-icon-container {
				background: rgba(255,255,255,0);
			}
				.workout-plan-list-cycle-overview-header-card-menu-row-icon {
					font-size: 15px !important;
					color: #9DA0AD;
				}



