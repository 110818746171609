.chat-container {
    
    height: 100%;
    width: 100%;
}

    .chat-container-inner {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .chat-container-inner-loading {
        align-items: center;
        justify-content: center;
    }
        .chat-container-inner-loading p {
            font-size: 13.5px;
            color: #6C6C6C;
            margin: 0px;
            margin-top: 6px;
        }
.chat-header-container {
    position: relative;
    display: flex;
    background: var(--ef-card);
    border-bottom: solid;
    border-width: 1px;
    border-color: var(--ef-border);
    height: 74px;
}
    .chat-header-icons-container {
        width: 90px;
        height: 100%;
        position: relative;
        padding-left: 16px;
    }
        .chat-header-icon {
            position: absolute;
            top: 12px;
            align-items: center;
            justify-content: center;
            width: fit-content;
            height: fit-content;
            width: 50px;
            height: 50px;
            border-radius: 25px;
            overflow: hidden;
            background: #0B0C0F;
            box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.2);
        }
            .chat-header-icon-image { 
                width: 50px;
                height: 50px;
            }
            .chat-header-icon h4 {
                font-size: var(--ef-font-size-l);
                color: var(--ef-text-mid);
                margin: 0px;
                width: 100%;
                text-align: center;
                padding-top: 15px;
            }

        .chat-header-icon-trainer {
            z-index: 0;
        }
        .chat-header-icon-client {
            top: 10px;
            width: 54px;
            height: 54px;
            border-radius: 27px;
            left: 54px;
            z-index: 1;
            border-width: 1px;
            border: solid;
            border-color: var(--ef-card);
        }

.chat-header-top-container {
    padding: 24px 36px;
}
    .chat-header-top-container h1 {
        font-size: var(--ef-font-size-xl);
        color: #ffffff;
        margin: 0px;
        font-weight: 400;
    }

    .chat-header-scheduled-button {
        margin-left: auto;
        float: right;
        padding: 5px 12px;
        height: 28px;
        background: var(--ef-card-button);
        border: solid;
        border-width: 1px;
        border-color: #2E3142;
        display: flex;
        border-radius: var(--ef-radius-s);
        margin-top: 20px;
        margin-right: 20px;
        box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.12);
        -webkit-transition: all 0.15s;
        -moz-transition: all 0.15s;
        transition: all 0.15s;
    }
    .chat-header-scheduled-button:hover {
        background: var(--ef-card-light);
        cursor: pointer;
        -webkit-transition: all 0.15s;
        -moz-transition: all 0.15s;
        transition: all 0.15s;
    }
        .chat-header-scheduled-button p {
            font-size: var(--ef-font-size-s);
            color: var(--ef-text-light);
            margin: 0px;
        }



.chat-header-bottom-container {
    display: flex;
    width: 100%;
    background: #1F1F21;
    padding: 16px 36px;
    padding-right: 12px;
    position: relative;
    height: 48px;
}
    .chat-header-bottom-container h2 {
        font-size: 13.5px;
        color: #ffffff;
        margin: 0px;
    }

.chat-board-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: hidden;
    position: relative;
}
.chat-messages-container {
    display: flex;
    flex-direction: column-reverse; /* Display messages from bottom to top */
    flex-grow: 1;
    overflow-y: auto; /* Enable scrollbar within this container */
    position: absolute; /* Add position absolute */
    bottom: 0; /* Position the container at the bottom */
    left: 0; /* Position the container at the left */
    right: 0; /* Position the container at the right */
    top: 0; /* Position the container at the top */
    padding: 24px 16px;
}

.chat-message-input-wrapper {
    width: 100%;
    padding: 16px;
    padding-top: 0px;
}

.chat-message-test {
    width: 100%;
    height: 400px;
}
