/* .discover-nodes- */

.discover-nodes-container {
	width: 100%;
	overflow-y: hidden;
	height: 100%;
	display: block;
	position: relative;
}

.discover-nodes-searching-container {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	background: rgba(29,36,47,0.85);
	padding-top: calc(50vh - 240px);
	z-index: 100;
}

.discover-nodes-searching-container h3 {
	font-size: 16px;
	color: #4959C1;
	width: 100%;
	text-align: center;
}

.discover-nodes-searching-circle {
	margin: auto;
	width: 164px;
	height: 164px;
	background: #111920;
	border: solid;
	border-width: 2px;
	border-radius: 82px;
	z-index: 100;
	-webkit-transition: all 0.6s;
	-moz-transition: all 0.6s;
	transition: all 0.6s;
}

.discover-nodes-searching-circle-blue {
	border-color: #4959C1;
	box-shadow: 0px 2px 12px 24px rgba(30, 0, 200, 0.16);
	color: #4959C1;
}

.discover-nodes-searching-circle-dark {
	border-color: rgba(0,0,0,0);
	box-shadow: 0px 2px 12px 12px rgba(0, 0, 0, 0.08);
	color: #5F6C76;
}

.discover-nodes-ble-icon {
	margin-left: 68px;
	margin-top: 64px;
}

.discover-nodes-content-container {
	width: 100%;
	height: 100%;
	overflow-y: scroll;
	padding-bottom: 24px;
}

.discover-nodes-cta-button {
	font-size: 14px;
	margin-top: 24px;
	display: block;
	text-align: center;
	padding: 10px 24px;
	padding-right: 36px;
	background-color: #4959C1;
	color: white;
	border-radius: 4px;
	box-shadow: 0 4px 38px 0 rgba(0, 0, 200, 0.15);
}

.discover-nodes-empty-container {
	padding-top: 284px;
}
	.discover-nodes-empty-image-container {
		width: 540px;
		left: calc(50% - 270px);
		top: 160px;
		position: absolute;
	}