.search-container {
	padding: 0px 16px;
	padding-top: 0px !important;
	background-color: #1F1F21;
	box-shadow: 0 4px 24px 6px rgba(0, 0, 0, 0.1);
	border-radius: 5px;
	width: 100%;
	margin-bottom: 24px;
	display: flex;
	font-size: 13.5px;
	color: #9DA0AD;
	border-color: #2E3247;
	border-width: 1px;
	border-style: solid;
}
	.search-container-focused {
		border-color: #4959C1;
		background-color: rgba(0,0,1,0.1);
		box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}
.search-text-container {
	margin: auto 0px;
	width: 80%;
	height: 100%;
	vertical-align: middle;
	display: flex;
}

.search-icon {
	font-size: 13.5px !important;
	margin: auto;
	color: #5F6C76;
	margin-right: 6px;
	-webkit-transition: all 0.35s;
  	-moz-transition: all 0.35s;
  	transition: all 0.35s;
}

.search-icon-highlighted {
	font-size: 13.5px !important;
	margin: auto;
	color: #C1C4CC;
	margin-right: 6px;
	-webkit-transition: all 0.35s;
  	-moz-transition: all 0.35s;
  	transition: all 0.35s;
}