.node-cell-container {
	border: solid;
	border-width: 1px;
	border-color: rgba(0,0,0,0);
	border-radius: 4px;
	width: 100%;
	padding: 22px;
	display: block;
	margin-bottom: 12px;
	background: #1F1F21;
	border: solid;
	border-width: 1px;
	border-color: #2B2B30;
	box-shadow: 0px 2px 16px 8px rgba(0, 0, 0, 0.12);
	-webkit-transition: all 0.1s;
	-moz-transition: all 0.1s;
	transition: all 0.1s;
}

.node-cell-container-green {
	border-color: #49C16B !important;
	box-shadow: 0px 0px 16px rgba(73, 193, 107, 0.56) !important;
	-webkit-transition: all 0.05s;
	-moz-transition: all 0.05s;
	transition: all 0.05s;
}

.node-cell-header-container {
	width: 100%;
	display: flex;
}

.node-cell-title {
	width: calc(100% - 20px);
}

.node-cell-title h2 {
	font-size: 18px;
	color: #C1C4CC;
	margin: 0px;
}

.node-cell-ble-icon-container {
	width: 20px;
}

.node-cell-ble-icon-not-connected {
	color: #5F6C76;
}

.node-cell-ble-icon-connected {
	color: #4959C1;
}

.node-cell-info-container {
	width: 100%;
	display: flex;
}

.node-cell-info-content {
	padding-top: 8px;
	width: 50%;
	padding-right: 12px;
	z-index:0;
	display: block;
}

.node-cell-info-content p {
	color: #5F6C76;
	font-size: 14px;
	z-index: 2;
}
.node-cell-info-content-circle-container {
	padding-top: 6px;
	display: flex;
}

.node-cell-info-content-circle-container p {
	margin: 0px;
	margin-top: -4px;

}

.node-cell-info-disconnected-container {
	width: 100%;
	height: 48px;
	padding: 16px;
}

.node-cell-info-disconnected-container p {
	color: #5F6C76;
	font-size: 14px;
	text-align: center;
}

.node-cell-battery-dark {
	color: #5F6C76;
	display: flex;

}

.node-cell-battery-dark p {
	color: #5F6C76;
	padding-left: 4px;
	margin: 0px;
}

.node-cell-battery-green {
	color: rgba(0,255,0,1) ;
	display: flex;
}

.node-cell-battery-green p {
	color: rgba(0,255,0,1) ;
	padding-left: 4px;
	margin: 0px;
	text-align: left;
}

.node-cell-info-failed-calibration {
	width: 100%;
	height: 48px;
	padding: 16px;
	color: red;
	display: flex;
}

.node-cell-info-failed-calibration p {
	font-size: 14px;
	text-align: center;
}

.node-cell-info-recalibration {
	width: 100%;
	height: 48px;
	padding: 16px;
	color: #13CDE7;
	display: flex;
}

.node-cell-info-recalibration p {
	font-size: 14px;
	text-align: center;
	padding-left: 12px;
}
