
.workout-plan-list-plan {
	width: 100%;
	overflow: hidden;
	height: calc(100% - 0px);	
	position: relative;
}
	.workout-plan-list-plan-content {
		width: 100%;
		height: calc(100vh - 134px);
		overflow-y: scroll;
	}

	.workout-plan-list-plan-header-card {
        width: 100%;
        display: block;
        position: relative;
	}
		.workout-plan-list-plan-header-card-icon-container {
			width: 32px;
            height: 32px;
            border-radius: 5px;
            padding: 7px 10px;
            background: var(--ef-color-phase-transparent);
		}
			.workout-plan-list-plan-header-card-icon {
				font-size: 14px !important;
				color: #8049C1;
			}
			.workout-plan-list-plan-header-card-icon-container-plan {
				background: var(--ef-color-phase-transparent);
				border-color: #5B62FF;
			}
				.workout-plan-list-plan-header-card-icon-plan {
					color: var(--ef-color-phase);
				}
			.workout-plan-list-plan-header-card-icon-container-workout {
				background: var(--ef-color-phase-transparent);
			}
				.workout-plan-list-plan-header-card-icon-workout {
					color: #49C16B;
				}
					.workout-plan-list-plan-header-card-header {
						display: flex;
						flex: 1;
						width: 100%;
						padding: 20px 24px;
						border-left: solid;
						border-width: 1px;
						border-color: var(--ef-border);

					}
					.workout-plan-list-plan-body-card-header-text {
						display: flex;
						padding-top: 3px;
					}		
						.workout-plan-list-plan-body-card-header-text h4 {
							font-size: 13.5px;
							color: #9DA0AD;
							margin: 0px;
						}	
						.workout-plan-list-plan-body-card-header-text p {
							font-size: 13.5px;
							color: #5B62FF;
							font-weight: 600;
							margin: 0px;
							margin-left: 8px;
						}	
					.workout-plan-list-plan-body-card-header {
					}
				.workout-plan-list-plan-overview-header-card-icon-container-workout {
					background: var(--ef-color-workout-transparent) !important;
				}
					.workout-plan-list-plan-overview-header-card-icon-workout {
						width: 16px;
						height: 16px;
						border-radius: 8px;
						border-width: 2px !important;
						border: solid;
						margin-top: 2px;
						margin-left: 2px;
						border-color: var(--ef-color-workout);
					}

					.workout-plan-list-plan-header-card-body {
						width: 100%;
						padding: 26px 36px;
						padding-bottom: 0px;
					}
						.workout-plan-list-plan-header-card-section-title-container {
							padding-bottom: 12px;
						}
							.workout-plan-list-plan-header-card-section-title-container h4 {
								font-size: var(--ef-font-size-m);
								color: var(--ef-text-mid);
								margin: 0px;
								margin-bottom: 4px;
							}
							.workout-plan-list-plan-header-card-section-title-container p {
								font-size: var(--ef-font-size-s);
								color: var(--ef-text-dark);
								margin: 0px;
							}
						.workout-plan-list-plan-header-card-body-desc {
							display: flex;
							padding-top: 12px;
						}
							.workout-plan-list-plan-header-card-body-desc p {
								width: 180px;
							}

					.workout-plan-list-plan-header-card-body-row-container {
						width: 100%;
						display: flex;
					}
						.workout-plan-list-plan-header-card-body-row {
							width: 50%;
						}
						
							.workout-plan-list-plan-header-card-body-col {
								width: 100%;
								display: flex;
								padding-top: 12px;
							}
								.workout-plan-list-plan-header-card-body-col p {
									width: 140px;
									min-width: 140px;
									font-size: var(--ef-font-size-s);
									color: var(--ef-text-dark);
								}
								.workout-plan-list-plan-header-card-body-col h4 {
									font-size: var(--ef-font-size-s);
									color: var(--ef-text-light);
								}

							.workout-plan-list-plan-header-card-bod-rep-scheme-container {
								width: 100%;
								display: flex;
							}
								.workout-plan-list-plan-header-card-bod-rep-scheme-container h4 {
									padding-right: 8px;
									color: var(--ef-text-light);
									font-size: var(--ef-font-size-s);
								}
					.workout-plan-list-plan-header-card-menu-container {
						width: 100%;
						display: flex;
						padding-top: 36px;
						padding-bottom: 12px;
					}
						.workout-plan-list-plan-header-card-menu-item {
							padding-right: 20px;
							margin-right: 8px;
							position: relative;
							cursor: pointer;
						}
							.workout-plan-list-plan-header-card-menu-item p {
							    font-size: var(--ef-font-size-m);
							    color: var(--ef-text-dark);
							    margin: 0px;
								-webkit-transition: all 0.15s;
								-moz-transition: all 0.15s;
								transition: all 0.15s;
							}
							.workout-plan-list-plan-header-card-menu-item:hover p {
								color: var(--ef-text-light);
								-webkit-transition: all 0.15s;
								-moz-transition: all 0.15s;
								transition: all 0.15s;
							}
						.workout-plan-list-plan-header-card-menu-item-selected {

						}
							.workout-plan-list-plan-header-card-menu-item-selected p {
							    color: var(--ef-text-light);
							}
						.workout-plan-list-plan-header-card-menu-selected-bar {
							position: absolute;
							bottom: -13px;
							left: 0px;
							width: calc(100% - 20px);
							height: 2px;
							border-radius: 1px;
							background: #5437DC;
							box-shadow: 0 -4px 24px 4px #5437DC8F;
						}

		.workout-plan-list-plan-header-card-header-wrapper {
			width: 100%;
			display: flex;
			border-bottom: solid;
			border-width: 1px;
			border-color: var(--ef-border-highlight-dark);
		}
			.workout-plan-list-plan-header-card-header-back-container {
				width: 70px;
				border-right: solid;
				border-width: 1px;
				border-color: var(--ef-border-highlight-dark);
				padding: 20px;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
			.workout-plan-list-plan-header-card-header-back-container:hover {
				width: 132px;
				cursor: pointer;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
			.workout-plan-list-plan-header-card-header-back-container-workout:hover {
				width: 122px;
			}
				.workout-plan-list-plan-header-card-header-back-icon-container {
					width: 32px;
					height: 32px;
					padding: 7px 11px;
					display: flex;
					border-radius: var(--ef-radius-m);
					background-color: var(--ef-color-program-transparent);
					overflow: hidden;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
				.workout-plan-list-plan-header-card-header-back-icon-container-workout {
					background-color: var(--ef-color-phase-transparent);
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
					.workout-plan-list-plan-header-card-header-back-icon {
						font-size: 16px !important;
						margin-top: 1px;
						color: var(--ef-color-program);
						-webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}
					.workout-plan-list-plan-header-card-header-back-icon-container-workout .workout-plan-list-plan-header-card-header-back-icon {
						color: var(--ef-color-phase);
						-webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}
				.workout-plan-list-plan-header-card-header-back-icon-container p {
					white-space: nowrap;
					opacity: 0.0 !important;
					font-size: var(--ef-font-size-s);
					color: var(--ef-cta-light);
					margin: 0px;
					margin-left: 2px;
					margin-top: 1px;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
				.workout-plan-list-plan-header-card-header-back-icon-container-workout p {
					color: var(--ef-color-phase);
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
				.workout-plan-list-plan-header-card-header-back-container:hover .workout-plan-list-plan-header-card-header-back-icon-container {
					background: #7963E03F;
					width: 92px;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
				.workout-plan-list-plan-header-card-header-back-container:hover .workout-plan-list-plan-header-card-header-back-icon-container-workout {
					background: var(--ef-color-phase-transparent) !important;
					width: 82px;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
				.workout-plan-list-plan-header-card-header-back-container:hover .workout-plan-list-plan-header-card-header-back-icon-container p {
					opacity: 1.0 !important;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
				.workout-plan-list-plan-header-card-header-back-container:hover .workout-plan-list-plan-header-card-header-back-icon {
					color: var(--ef-cta-light);
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
				.workout-plan-list-plan-header-card-header-back-container:hover .workout-plan-list-plan-header-card-header-back-icon-container-workout .workout-plan-list-plan-header-card-header-back-icon {
					color: var(--ef-color-phase);
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
		.workout-plan-list-plan-header-card-text-container {
			padding-left: 12px;
			display: flex;
			position: relative;
			padding-top: 10px;
			margin-bottom: -6px;
		}
			.workout-plan-list-plan-header-card-text-container-title {

			}
			.workout-plan-list-plan-header-card-text-container-title:hover {
				cursor: pointer;
			}
				.workout-plan-list-plan-header-card-text-container-title h1 {
					font-size: var(--ef-font-size-xl);
		            color: var(--ef-text-light);
		            margin: 0px;
		            -webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
				.workout-plan-list-plan-header-card-text-container-title:hover h1 {
		            color: var(--ef-text-mid);
		            text-decoration: underline;
		            -webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
			.workout-plan-list-plan-header-card-text-container h2 {
				font-size: var(--ef-font-size-xl);
	            color: var(--ef-color-phase);
	            margin: 0px;
	            margin-right: 12px;
	            font-weight: 400;
			}
			.workout-plan-list-plan-header-card-text-container-workout h1 {

			}
			.workout-plan-list-plan-header-card-text-container-workout h2 {
				color: var(--ef-color-workout);
			}

			.workout-plan-list-plan-header-card-text-folder-name-container {
				position: absolute;
				left: 12px;
				top: -8px;
				flex: 1;
			}
				.workout-plan-list-plan-header-card-text-folder-name-container p {
					color: var(--ef-text-dark);
					font-size: var(--ef-font-size-s);
					margin: 0px;
					white-space: nowrap;
				}
				.workout-plan-list-plan-header-card-text-folder-name-container span {
					color: var(--ef-color-phase) !important;
				}

		.workout-plan-list-plan-body-card-content {
			width: calc(100% - 24px);
			padding: 0px;
			margin: auto;
			
			
			background: var(--ef-background);
		    margin-bottom: 4px;
			border-radius: 12px !important;
			border-width: 1px !important;
			border: solid;
			border-color: var(--ef-border);
		}
			.workout-plan-list-plan-body-card-content-workouts {
				padding: 8px;
				padding-bottom: 12px;
				overflow: hidden;
			}
		.workout-plan-list-plan-body-card-content .assign-modal-body-add-button-container {
			padding-left: 40px !important;
			width: calc(100% + 32px);
			margin-top: -10px;
		}
			.workout-plan-list-plan-body-card-content .assign-modal-body-add-button {
				width: 100% !important;
				max-width: 100% !important;
				min-width: 100% !important;
				background: var(--ef-button-dark);
				border-radius: var(--ef-radius-m);
				border: solid;
				border-width: 1px;
				border-color: var(--ef-border);
				padding: 12px;
			}
			.workout-plan-list-plan-body-card-content .assign-modal-body-add-button:hover {
				background: var(--ef-button-card);
			}
				.workout-plan-list-plan-body-card-content .assign-modal-body-add-button p {
					width: 100%;
					text-align: center;
				}
			.workout-plan-list-plan-body-card-row {
				width: 100%;
				margin-bottom: 4px;
				display: flex;
				position: relative;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}

				.workout-plan-list-plan-body-card-row-inner {
					display: flex;
					width: calc(100% - 30px);
					padding: 16px 0px;
				}
				.workout-plan-list-plan-body-card-col {
					width: 35%;
				}
				.workout-plan-list-plan-body-card-col-sm {
					width: 15%;
				}
				.workout-plan-list-plan-body-card-col-large {
					width: calc(50% - 80px);
				}
				.workout-plan-list-plan-body-card-col-xs {
					width: 80px;
					margin-top: 0px;
				}
					.workout-plan-list-plan-body-card-col-xs h4 {
						color: #6C6C6C;
					}
					.workout-plan-list-plan-body-card-col-sm h4 {
						color: #6C6C6C;
						font-weight: 300;
					}
			.workout-plan-list-plan-body-card-row-branch-container {
				position: absolute;
				left: 16px;
				top: -128px;
				z-index: 0;
			}
				.workout-plan-list-plan-body-card-row-branch {
					width: 50px;
					height: 156px;
					border-bottom-left-radius: 14px;
					border-left: solid;
					border-bottom: solid;
					border-width: 1px !important;
					border-color: var(--ef-color-workout);
				}
				.workout-plan-list-plan-body-card-row-branch-unselected {
					border-color: var(--ef-border-light);
				}
			.workout-plan-list-plan-body-card-row-card-container {
				width: 100%;
				padding-left: 42px;
				position: relative;
				z-index: 1;
			}
			.workout-plan-list-plan-body-card-arrow-icon-container {

				width: 20px;
				height: 20px;
				min-width: 20px;
				min-height: 20px;
				padding: 1px 5px;
				margin-top: 12px;
				border-radius: 5px;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
			.workout-plan-list-plan-body-card-arrow-icon-container:hover {
				background: rgba(0,0,0,0.2);
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
				.workout-plan-list-plan-body-card-arrow-icon {
					font-size: 12px !important;
					color: #6C6C6C;
				}

			.workout-plan-list-plan-body-card-arrow-icon-container-add {
				width: auto;
				display: flex;
				white-space: nowrap;
				background: rgba(0, 0, 0, 0.1);
				height: 28px;
				margin-right: 0px;
				width: 146px;
				padding: 5px 8px;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
				.workout-plan-list-plan-body-card-arrow-icon-container-add .workout-plan-list-plan-body-card-arrow-icon {
					margin-top: 2px;
					margin-right: 4px;
				}
				.workout-plan-list-plan-body-card-arrow-icon-container-add p {
				    font-size: 13.5px;
				    color: #6C6C6C;
				    margin: 0px;
				}
			.workout-plan-list-plan-body-card-row:hover .workout-plan-list-plan-body-card-arrow-icon-container-add {
				background: rgba(0, 0, 0, 0.3);
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
				.workout-plan-list-plan-body-card-row:hover .workout-plan-list-plan-body-card-arrow-icon-container-add p {
					color: #5B62FF;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}

				.workout-plan-list-plan-body-card-row:hover .workout-plan-list-plan-body-card-arrow-icon {
					color: #5B62FF;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}


				.workout-plan-list-plan-body-card-header {
					height: 50px;
					background: #1F1F21;
					border: solid;
					border-width: 1px !important;
					border-color: var(--ef-border-light);
					border-radius: var(--ef-radius-m);
					display: flex;
					z-index: 1;
					position: relative;
					box-shadow: 0 4px 12px 6px rgba(0, 0, 0, 0.12);
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;	
				}
					.workout-plan-list-plan-body-card-header:hover {
						background: var(--ef-card-light);
						-webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}
				.workout-plan-list-plan-body-card-header-noborder {
					width: 100%;
					display: flex;
					border-color: transparent !important;
				}
				.workout-plan-list-plan-body-card-header-icon-wrapper {
					width: 50px;
					height: 100%;
					padding: 8px 9px;
			        border-right: solid;
			        border-width: 1px !important;
			        border-color: var(--ef-border-highlight-dark);
				}


				.workout-plan-list-plan-body-card-header:hover {
					/*background: #28282D;
					cursor: pointer;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;	*/
				}
				.workout-plan-list-plan-body-card-header-icon-container:hover .workout-plan-list-plan-body-card-header-arrow-icon {
					color: #ffffff;
					cursor: pointer;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;	
				}
					.workout-plan-list-plan-body-card-header-icon-container {
						width: 30px;
			            height: 30px;
			            border-radius: var(--ef-radius-m);
			            padding: 6px 6px;
			            background: var(--ef-color-phase-transparent);
					}
					.workout-plan-list-plan-body-card-header-icon-container-workout {
			            background: var(--ef-color-workout-transparent);
					}
						.workout-plan-list-plan-body-card-header-icon {
							width: 18px;
							height: 18px;
						}
						.workout-plan-list-plan-body-card-header-icon-workout {
							width: 15px;
							height: 15px;
							border-radius: 8px;
							border-width: 2px !important;
							border: solid;
							margin-top: 1.5px;
							margin-left: 1.5px;
							border-color: var(--ef-color-workout);
						}

					.workout-plan-list-plan-body-card-header-content-wrapper {
						flex: 1;
						height: 100%;
						padding: 8px 20px;
						padding-left: 12px;
						display: flex;
			            border-left: solid;
			            border-width: 1px !important;
			            border-color: var(--ef-border-light) !important;
					}
					.workout-plan-list-plan-body-card-header-drag-handle-container {
						width: 20px;
						height: 20px;
						padding-top: 7px;
						cursor: move;
					}
						.workout-plan-list-plan-body-card-header-drag-handle-icon {
							color: var(--ef-text-dark) !important;
							font-size: 18px !important;
						}
					.workout-plan-list-plan-body-card-header-text-container {
						padding-left: 4px;
						padding-top: 5px;
						display: flex;
						flex: 1;
					}	
						.workout-plan-list-plan-body-card-header-text-container p {
							font-size: 13.5px;
							color: #5B62FF;
							font-weight: 600;
							margin-left: 8px;
						}
						.workout-plan-list-plan-body-card-header-text-container h4 {
							font-size: var(--ef-font-size-lg);
							color: var(--ef-text-light);
							font-weight: 600;
							margin: 0px;
							margin-left: 0px;
						}
						.workout-plan-list-plan-body-card-header-text-container span {
							font-size: var(--ef-font-size-lg);
							color: var(--ef-color-phase);
							font-weight: 300;
							margin: 0px;
							margin-left: 0px;
							margin-right: 8px;
						}
						.workout-plan-list-plan-body-card-header-text-container-workout span {
							color: var(--ef-color-workout);
						}
						.workout-plan-list-plan-body-card-header-phase-count-text-container {
							padding-top: 7px;
						}
							.workout-plan-list-plan-body-card-header-phase-count-text-container {

							}

	.workout-plan-list-plan-body-card-content-body {
			width: 100%;
			padding: 0px;
			padding-bottom: 48px;
			position: relative;
		}
	.workout-plan-list-plan-body-container {
		width: 100%;
		height: calc(100% - 92px);
		margin-top: 12px;
		overflow-y: scroll;
		padding: 12px;
		padding-top: 0px;

	}

	.workout-plan-list-plan-body-card {
		width: 100%;
        padding: 20px 4px;   
        padding-bottom: 32px; 
        border-top: solid;
		border-width: 1px;
		border-color: #2B2B30;
        overflow: hidden;
	}


	.workout-plan-list-plan-muscle-selector {
	    width: 100%;
	    padding: 0px 24px;
	    
	}
		.workout-plan-list-plan-muscle-selector-muscle-images-container {
            width: 430px;
            height: 400px;
            max-height: 400px;
            /*margin: 0px auto;*/
            position: absolute;
            z-index: 10;
            top: 0px;
            left: calc(50% - 215px);
            /*margin-left: -2.2px;*/
        }
            .workout-plan-list-plan-muscle-selector-body-img {
                width: 440px;
                height: 400px;
                min-height: 400px;
                position: absolute;
                /*background: rgba(0,0,255,0.2);*/
            }
            .workout-plan-list-plan-muscle-selector-empty-body-container {
		        height: 100%;
		        width: 100%;
		        margin: 0px auto;
		        margin-left: calc(50% - 215px);
		        z-index: 100;
		    }
		        .workout-plan-list-plan-muscle-selector-empty-body-img {
		            height: 400px;
		            max-height: 400px;
		        }

				    .workout-plan-list-plan-muscle-selector-content {
				        width: 440px;
				        height: 400px;
				        margin: 0px auto;
				        position: relative;
				    }
			.workout-plan-list-header-button {
				position: relative;
                padding: 6px 12px;
                display: flex;
                font-size: 10px;
                border-radius: 5px;
                background: black;
                margin-left: auto;
                float: right;
                height: 28px;
                margin-top: 4px;
                color: #ffffff !important;
                -webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
            }
            	.workout-plan-list-header-button p {
	                color: white;
	                font-size: 12px;
	                padding-left: 6px;
	                margin-right: 0px;
	            }
            .workout-plan-list-header-button:hover {
            	background-color: #1F1F21 !important;
				cursor: pointer;
				box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
            }
            	.workout-plan-list-header-button-blue {
                    background: #5437DC;
                    border: none;
                    color: white !important;
                    box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
                }
                .workout-plan-list-header-button-blue:hover {
	            	background-color: #7963E0 !important;
					cursor: pointer;
					box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
	            }
	        .workout-plan-list-header-upper-content-container-buttons-icon {
	        	color: #ffffff;
	        	font-size: 14px !important;
	        }
	        .workout-plan-list-header-upper-content-container-buttons-hotkey-icon {
	        	background: rgba(255, 255, 255, 0.3);
	        	padding: 1.5px 4px;
	        	border-radius: 5px;
	        	margin-left: 6px;
	        	font-weight: 700 !important;
	        }
	       		.workout-plan-header-card-menu-button-container {
	       			margin-left: 12px;
	       			margin-top: 3px;
	       		}

.workout-plan-list-plan-empty-container {

}
	.workout-plan-list-plan-empty-card {
        width: 60%;
        min-width: 420px;
        max-width: 560px;
        margin: 0px auto;
        margin-top: 12vh;
        display: block;
	}
		.workout-plan-list-plan-empty-card-icon-container {
			width: 100%;
			margin-bottom: 24px;
			padding-left: calc(45% - 150px);
		}
			.workout-plan-list-plan-empty-card-icon-image {
				max-width: 300px;
			}
		.workout-plan-list-plan-empty-card h4 {
			color: #ffffff;
	        font-size: 18px;
	        margin: 0px;
		}
		.workout-plan-list-plan-empty-card p {
			color: #9DA0AD;
	        font-size: 13.5px;
	        margin: 0px;
	        line-height: 20px;
	        margin-top: 8px;
		}
		.workout-plan-list-plan-empty-card-button {
			background: #5B62FF;
			border-radius: 5px;
			padding: 6px 12px;
			box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
			margin-top: 16px;
			float: left;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
		.workout-plan-list-plan-empty-card-button:hover {
			cursor: pointer;
			background: #7278F2;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
			.workout-plan-list-plan-empty-card-button p {
				font-size: 12px;
				color: white;
				font-weight: 500;
				margin: 0px;
				width: 100%;
				text-align: center;
			}
		.workout-plan-list-plan-body-card-header-tag-container {
			width: 100%;
			display: inline-flex;
			padding: 0px 36px;
			padding-bottom: 24px;
		}
			.workout-plan-list-plan-body-card-header-tag-container h4 {
				font-size: 13.5px;
				color: #6C6C6C;
				margin: 0px;
				width: 100px;
				padding-top: 4px;
				font-weight: 400;
			}
			.workout-plan-list-plan-body-card-header-tag {
				padding: 4px 12px;
				height: 26px;
				border-radius: 13px;
				border: solid;
				border-width: 1px;
				border-color: #2B2B30;
				margin-right: 8px;
				margin-bottom: 8px;
			}
				.workout-plan-list-plan-body-card-header-tag p {
					font-size: 12px;
					color: #9DA0AD;
					margin: 0px;
				}
	.workout-plan-list-plan-card-spacer {
		height: 24px;
		width: 100%;
	}

