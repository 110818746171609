
.goal-log-row {
	width: 100%;
	display: flex;
	padding: 16px 0px;
	border-bottom: solid;
	border-width: 1px;
	border-color: #1A1A1A;
}
	.goal-log-card-body-col-name-icon {
		width: 10px;
		height: 10px;
		border-radius: 5px;
		background: #6C6C6C;
		margin-right: 8px;
	}
	.goal-log-card-body-col-name-icon-green {
		background: #5BFF62;
	}
	.goal-log-card-body-col-name-icon-blue {
		background: #5B62FF;
	}
	.goal-log-card-body-col-name-icon-yellow {
		background: #FFD15B;
	}
	.goal-log-card-body-col-name-icon-red {
		background: #FF4D4D;
	}