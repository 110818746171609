.client-search-container {
	padding: 12px 0px;
}


.client-container h3 {
	font-size: 13.5px;
	color: #ffffff;
}

.client-container {
	height: calc(100vh - 174px);
	width: 100%;
	padding: 0px !important;
	/*padding: 20px 0px;
	border-radius: 5px;
	background-color: #1F1F21;
	box-shadow: 0 4px 24px 6px rgba(0, 0, 0, 0.1);*/
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
}
	.client-container-modal {
		/*height: calc(100vh) !important;*/
		height: calc(100vh - 400px);
		margin-top: 24px;
		border-radius: 5px;
		border-width: 1px !important;
		border-color: #2B2B30 !important;
		border: solid;
		padding: 16px 0px;
		box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
	}
	.client-container-new-workout {
		height: calc(100vh - 100px);
		padding: 0px;
	}


.client-search-container p {
	font-size: 12px;
}

.search-bar {
	margin-top: 0px;
}

.client-list-upper-content {
	width: 100%;
	display: flex;
	padding: 0px 20px;
}	
	.client-container-new-workout .page-title-header-container {
		padding-left: 0px !important; 
	}
	.client-container-new-workout .client-list-upper-content {
		padding: 0px 0px !important; 
	}
	.client-list-upper-content-search-container {
		width: 50%;
	}
	.client-list-upper-content-search-container-full {
		width: 100% !important;
	}
		.client-list-upper-content-search-container-right {
			width: 40% !important;
			float: right !important;
			margin-left: auto;
			margin-top: -60px;
		}
	.client-list-upper-content-showing-number-container {
		width: 120px;
		padding-top: 12px;
		padding-left: 12px;
	}	
		.client-list-upper-content-showing-number-container p {
			font-size: 10px;
			color: #6C6C6C;
		}
	.client-list-upper-content-add-button-container {
		width: 90px;
		margin-left: auto;
	}
		.client-list-upper-content-add-button {
			padding: 8px 12px;
			padding-left: 12px;
			display: flex;
			font-size: 12px;
			border-radius: 5px;
			margin-left: 0px;
			color: #ffffff !important;
			background: #1F1F21;
			border: solid;
			border-width: 0.5px;
			border-color: #2B2B30;
			box-shadow: 0 2px 12px 2px rgba(0, 0, 0, 0.1);
			float: right;
			white-space: nowrap;
			margin-top: 2px;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
		.client-list-upper-content-add-button:hover {
			background: #1F1F21;
			cursor: pointer;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
			.client-list-upper-content-add-button-icon {
				font-size: 13px !important;
				margin-top: 1px;
				margin-right: 4px;
				margin-left: auto;
			}


.client-list-list-headding {
	width: 100%;
	display: flex;
	padding: 16px 0px;
	padding-left: 76px;
	padding-right: 48px;
	background: #1F1F21;
	/*border-bottom: solid;
	border-width: 1px;
	border-color: #2B2B30;*/
}
	.client-list-list-headding-row {
		width: 25%;
	}
		.client-list-list-headding-row p {
			font-size: 10px;
			color: #9DA0AD;
			margin: 0px;
		}
	

.cell-container {
	width: calc(100%);
	padding: 0px;
	padding-top: 0px;

	height: calc(100% - 62px);
	overflow-y: scroll;
	display: block;
	padding-bottom: 36px;
}

.cell-container-modal {
	width: calc(100% + 48px);
	padding: 24px;
	padding-top: 12px;
	margin-left: -24px;
	height: calc(100vh - 380px);
	overflow-y: scroll;
	display: block;
	padding-bottom: 36px;
}


.new-client-icon-container {
	/*width: 100px;
	height: 100px;
	border-radius: 50px;
	background: #4959C1;
	margin: auto;*/
}

.new-client-cell-container {
	padding: 28px 20px;
	width: calc(25% - 16px);
	height: 226px;
	border-radius: 4px;
	background-color: #202A34;
	box-shadow: 0 4px 38px 0 rgba(0, 0, 0, 0.1);
	margin-right: 16px;
	margin-top: 0px; 
	margin-bottom: 12px;
	display: inline-block;
}
.new-client-cell-container p {
	display: none;
	text-align: center;
}

.client-list-loading-container {
	width: 100%;
	/*padding-top: calc(40vh - 100px);*/
}
	.client-list-loading-container p {
		width: 100%;
        margin-top: 8px;
        font-size: 13.5px;
        color: #ffffff;
        text-align: center;
	}
.client-list-container-loading-container {
        height: 100%;
        width: 100%;
    }
        .client-list-container-loading-container p {
            width: 100%;
            margin-top: 8px;
            color: #ffffff;
            text-align: center;
        }
        .client-list-container-loading-spinner {
            margin: 0px auto;
            margin-top: calc(50vh - 160px);
            width: 24px;
            height: 24px;
            border: 3px solid rgba(0,0,0,0); /* Light grey */
            border-top: 3px solid #5437DC;
            border-right: 3px solid #5437DC;
            border-bottom: 3px solid #5437DC;
            border-radius: 12px;
            animation: spin 1s linear infinite;
        }
            @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
            }

.client-list-container-blank {
	width: 100%;
	height: 100%;
	padding-top: 25vh;
}
	.client-list-container-blank-img-container {
		width: 120px;
		min-width: 120px;
		margin: 0px auto;
	}
	.client-list-container-blank-text-container {
		width: 400px;
		margin: 12px auto;
	}
		.client-list-container-blank-text-container h4 {
		    font-size: 18px;
		    color: white;
		    margin: 0px;
		    width: 100%;
		    text-align: center;
		    margin-bottom: 8px;
		}
		.client-list-container-blank-text-container p {
		    font-size: 13.5px;
		    line-height: 18px;
		    color: #ffffff;
		    margin: 0px;
		    width: 100%;
		    text-align: center;
		}
	.client-list-container-blank-button {
		background: #5B62FF;
		padding: 10px 12px;
		padding-right: 16px;
		border-radius: 5px;
		display: flex;
		margin: 24px auto;
		width: 194px;
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}
	.client-list-container-blank-button:hover {
		background: #7278F2;
		cursor: pointer;
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}
		.client-list-container-blank-button p {
			margin: 0px;
			font-size: 13.5px;
			font-weight: 600;
			color: white;
			opacity: 1;
			white-space: nowrap;
		}
		.client-list-container-blank-button-icon {
			font-size: 16px !important;
			color: white;
			margin-right: 8px;
		}

@media screen and (max-width: 920px) {
	.new-client-cell-container {
		width: calc(33% - 16px);
	}
}

@media screen and (max-width: 600px) {
	.new-client-cell-container {
		width: calc(50% - 16px);
	}
}