/* .client-programs- */

.client-programs {
	width: 100%;
	padding-top: 32px;
	padding-left: 48px;
}
	.client-programs-header {

	}
		.client-programs-header h1 {
		    font-size: 21px;
		    color: #ffffff;
		    margin: 0px;
		}
		.client-programs-header p {
		    font-size: 13.5px;
		    color: #9DA0AD;
		    margin: 0px;
		    margin-top: 4px;
		}
			.client-programs-menu {
				width: 100%;
				display: flex;
				padding-top: 24px;
				border-bottom: solid;
				border-width: 1px;
				border-color: #2B2B30;
			}
			.client-programs-menu-item {
				padding-right: 40px;
				padding-bottom: 8px;
				position: relative;
			}
			.client-programs-menu-item:hover {
				cursor: pointer;
			}
				.client-programs-menu-item p {
				    font-size: 13.5px;
				    color: #6C6C6C;
				    margin: 0px;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
				.client-programs-menu-item:hover p {
					color: #ffffff;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
			.client-programs-menu-item-selected {

			}
				.client-programs-menu-item-selected p {
				    font-size: 13.5px;
				    color: #ffffff;
				    font-weight: 500;
				    margin: 0px;
				}

			.client-programs-menu-item-bar {
				height: 2px;
				border-radius: 1px;
				background: #5B62FF;
				width: calc(100% - 40px);
				position: absolute;
				left: 0px;
				bottom: -1px;
			}


	.client-programs-empty-container {
		width: 100%;
		padding-top: 15vh;
	}
		.client-programs-empty-img-container {
			width: 20%;
			margin: 0px auto;
		}
			.client-programs-empty-img-container img {
				width: 100%;
			}
		.client-programs-empty-container h4 {
		    font-size: 18px;
		    color: #ffffff;
		    margin: 0px;
		    width: 100%;
		    text-align: center;
		    margin-top: 20px;
		}
		.client-programs-empty-container p {
		    font-size: 13.5px;
		    color: #9DA0AD;
		    margin: 0px;
		    margin-top: 8px;
		    width: 100%;
		    text-align: center;
		}




