.workout-history-card {
	width: 100%;
	display: flex;
	border-radius: 4px;
	background-color: #1F1F21;
	box-shadow: 0 4px 38px 0 rgba(0, 0, 0, 0.2);
	min-height: 100px;
	margin-bottom: 24px;
	padding: 0px 0px;
}

.workout-history-card-image-container {
	width: 180px;
	height: 100%;
}

.workout-history-card-info-container {
	width: calc(100% - 160px);
	height: 100%;
	display: block;
	padding: 24px;
	padding-left: 24px;
}

.workout-history-card-info-container h2 {
	margin: 0px;
	margin-bottom: 4px;
	font-size: 18px;
	color: #E5E5E5;
}

.workout-history-card-info-container p {
	margin: 0px;
	font-size: 12px;
	color: #E5E5E5;
}

.workout-history-card-info-header-container {
	position: relative;
}

.workout-history-card-inline-icon {
	margin-bottom: -2px;
}

.workout-history-card-info-content {
	display: flex;
	margin-top: 12px;
}

.workout-history-card-info-content-container {
	width: 33%;
	display: block;
}

.workout-history-card-info-content-container h2 {
	margin: 0px;
	color: #E5E5E5;
}

.workout-history-card-info-content-container p {
	margin: 0px;
	font-size: 14px;
	color: #E5E5E5;
}

.workout-history-card-info-header-label {
	font-size: 12px !important;
	opacity: 0.7 !important;
	padding-bottom: 2px;
}

.workout-history-card-info-label {
	font-weight: 500;
	font-size: 16px;
}

.workout-history-card-image-circle {
	background-color: rgba(0,0,0,0.3);
	height: 160px;
	width: 160px;
	border-radius: 4px;
	margin: 0px 0px;
	overflow: hidden;
}

.workout-history-card-pill-green {
	position: absolute;
	top: 0px;
	right: 0px;
	background-color: rgba(100,255,100,0.1);
	padding: 4px 12px;
	color: rgba(100,255,100);
	font-size: 12px;
	border-radius: 4px;
	min-width: 80px;
	margin: auto;
	text-align: center;
}

.workout-history-card-pill-red {
	position: absolute;
	top: 0px;
	right: 0px;
	background-color: rgba(255,100,100,0.1);
	padding: 4px 12px;
	color: rgba(255,100,100);
	font-size: 12px;
	border-radius: 4px;
	min-width: 80px;
	margin: auto;
	text-align: center;
}



