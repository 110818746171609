.workout-profile-general-full-card {
	width: 100%;
	border-radius: 5px;
	background-color: #1F1F21;
	box-shadow: 0 4px 38px 0 rgba(0, 0, 0, 0.1);
	min-height: 80px;
	margin-bottom: 12px;
	padding: 24px 36px;
}
	.workout-profile-general-full-card-content {
		width: 100%;
		display: flex;
	}

		.client-profile-general-full-card-content p {
			font-size: 10px;
			color: #9DA0AD;
			margin: 0px;
			margin-bottom: 2px !important;
		}

		.client-profile-general-full-card-content h4 {
			font-size: 13.5px;
			color: #ffffff;
			margin: 0px;
		}
	.workout-profile-general-full-card-content-loading {
		padding-top: 10px;
	}
		.workout-profile-general-full-card-content-loading p {
			width: 100%;
			text-align: center;
		}

	.workout-profile-general-full-card-col-2 {
		width: 50%;
	}
	.workout-profile-general-full-card-col-3 {
		width: 33%;
	}
	.workout-profile-general-full-card-col-4 {
		width: 25%;
	}

.workout-profile-general-card-row {
	display: flex;
	width: 100%;
}
	.workout-profile-general-half-card {
		width: calc(50% - 6px);
		border-radius: 5px;
		background-color: #1F1F21;
		box-shadow: 0 4px 38px 0 rgba(0, 0, 0, 0.1);
		min-height: 184px;
		margin-bottom: 12px;
		padding: 24px 36px;
		margin-right: 12px;
	}
		.workout-profile-general-card-header {
			width: 100%;
			padding-bottom: 24px;
		}
			.workout-profile-general-card-header h2 {
				font-size: 13.5px;
				color: #ffffff;
				margin: 0px;
				margin-bottom: 4px;
			}
			.workout-profile-general-card-header p {
				font-size: 10px;
				color: #9DA0AD;
				margin: 0px;
			}

		.workout-profile-general-half-card-content {
			
		}
			.workout-profile-general-half-card-content h3 {
				padding-top: 42px;
				font-size: 18px;
				margin: 0px;
				color: #ffffff;
			}
			.workout-profile-general-half-card-content p {
				font-size: 10px;
				color: #9DA0AD;
				margin: 0px;
				margin-top: 10px;
			}

		.workout-profile-general-half-card-content-empty {
			width: 100%;
			padding-top: 48px;
		}
			.workout-profile-general-half-card-content-empty h5 {
				font-size: 10px;
				margin: 0px;
				color: #ffffff;
				width: 100%;
				text-align: center;
			}
			.workout-profile-general-half-card-content-empty p {
				font-size: 10px;
				color: #9DA0AD;
				margin: 0px;
				margin-top: 2px;
				width: 100%;
				text-align: center;
			}
		.workout-profile-general-half-card-content-loading {
			width: 100%;
			padding-top: 48px;
		}	
			.workout-profile-general-half-card-content-loading p {
				font-size: 10px;
				color: #9DA0AD;
				margin: 0px;
				margin-top: 2px;
				width: 100%;
				text-align: center;
			}

	.workout-profile-general-half-card-chart-container {
		width: 100%;
		height: 80px;
		padding-top: 8px;
	}

	.workout-profile-general-full-card-plans {
		
	}
		.workout-profile-general-card-header-plans {
			border-bottom: solid;
			border-width: 0.5px;
			border-color: #2B2B30;
			padding-bottom: 20px;
			width: calc(100% + 72px);
			margin-left: -36px;
			padding-left: 36px;
		}
	.workout-profile-general-card-content-plans-container {
		
		overflow-y: scroll;
		width: calc(100% + 36px);
		margin-left: -18px;
		padding: 0px 18px;
	}
	.workout-profile-general-card-content-plans-container-empty {
		width: 100%;
		padding-top: 84px;
	}
		.workout-profile-general-card-content-plans-container-empty h5 {
			font-size: 10px;
			margin: 0px;
			color: #ffffff;
			width: 100%;
			text-align: center;
		}
		.workout-profile-general-card-content-plans-container-empty p {
			font-size: 10px;
			color: #9DA0AD;
			margin: 0px;
			margin-top: 2px;
			width: 100%;
			text-align: center;
		}

.workout-profile-card-header-container {
	width: 100%;
	display: block;
}


.workout-profile-card-header-container h2 {
	color: #ffffff;
	font-size: 13.5px;
	margin: 0px;
	margin-bottom: 4px;
}

.workout-profile-card-header-container p {
	color: #9DA0AD;
	margin: 0px;
	font-size: 11px;
	font-weight: 500;
}
	.workout-profile-body-stats-info-container {
		width: 100%;
		display: flex;
		padding-top: 12px;
	}
	.workout-profile-body-stats-info-content {
		width: 33%;
	}
		.workout-profile-body-stats-info-content h3 {
			color: #ffffff;
			font-size: 13.5px;
			margin: 0px;
		}

		.workout-profile-body-stats-info-content p {
			color: #9DA0AD;
			margin: 0px;
			font-size: 11px;
			font-weight: 500;
			margin-bottom: 4px;
		}


/*.workout-profile-general-full-card {
	width: 100%;
	border-radius: 4px;
	background-color: #1F1F21;
	box-shadow: 0 4px 38px 6px rgba(0, 0, 0, 0.1);
	min-height: 100px;
	margin-bottom: 24px;
	padding: 36px 36px;
}

.workout-profile-general-personal {
	display: flex;
	width: 100%;
	height: 100%;
}

.workout-profile-general-w-2 {
	width: 50%;
	height: 100%;
	display: block;
}

.workout-profile-general-profile-image-container {
	width: 180px;
	height: 100%;
}

.workout-profile-general-profile-info-container {
	width: calc(100% - 180px);
	height: 100%;
	display: block;
}

.workout-profile-general-profile-info-header-container {
	position: relative;
}

.workout-profile-general-profile-info-content {
	display: flex;
	margin-top:48px;
}

.workout-profile-general-w-2 h2 {
	margin: 0px;
}

.workout-profile-general-w-2 p {
	margin: 0px;
	font-size: 14px;
}

.workout-profile-general-profile-info-container h2 {
	margin: 0px;
	color: #E5E5E5;
}

.workout-profile-general-profile-info-container p {
	margin: 0px;
	font-size: 16px;
	color: #E5E5E5;
}

.workout-profile-general-profile-circle {
	background-color: rgba(0,0,0,0.3);
	height: 160px;
	width: 160px;
	border-radius: 4px;
	margin: 0px 0px;
	overflow: hidden;
}

.workout-profile-general-image {
	display: block;
  	max-width: 400px;
  	max-height: 162px;
  	width: auto;
  	height: auto;
}

.workout-profile-body-stats-container {
	display: block;
}

.workout-profile-body-stats-image-container {
	height: 60px;
	width: 100%;
	display: flex;
}

.workout-profile-body-stats-image-container h2 {
	margin: auto;
	margin-left: 24px;
	color: #E5E5E5;
}

.workout-profile-body-stats-image {
	height: 60px;
	width: 60px;
}

.workout-profile-body-stats-info-container {
	width: 100%;
	display: flex;
}

.workout-profile-body-stats-info-content {
	display: block;
	width: 33.3%;
	padding-top: 12px;
	padding-right: 12px;
}

.workout-profile-body-stats-info-content p {
	font-size: 12px;
	margin: 0px;
	margin-bottom: 4px;
	color: #C1C4CC;
}

.workout-profile-body-stats-info-content h3 {
	font-size: 20px;
	margin: 0px;
	color: #C1C4CC;
}



.workout-profile-chart-container {
	width: 100%;
	height: 300px;
	position: relative;
	padding-top: 12px;
}
*/

