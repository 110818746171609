.experience-container {
	width: 100%;
	display: flex;
	height: 60px;
	margin-bottom: 36px;
}

.experience-selected-container {
	width: 25%;
	background-color: #4A5AC1;
	height: 68px;
	padding: 2px 20px;
	border-radius: 5px;
	text-align: center;
	box-shadow: 0 4px 38px 0 rgba(0, 0, 200, 0.15);
	-webkit-transition: all 0.35s;
  	-moz-transition: all 0.35s;
  	transition: all 0.35s;
}

.experience-selected-container p {
	color: white !important;
	font-size: 13.5px !important;
}

.experience-unselected-container {
	width: 25%;
	height: 68px;
	background-color: none;
	padding: 2px 20px;
	text-align: center;
	box-shadow: none;
	-webkit-transition: all 0.35s;
  	-moz-transition: all 0.35s;
  	transition: all 0.35s;
}

.experience-unselected-container p {
	color: #C2C5CD !important;
	font-size: 13.5px !important;
}