.alert-block-container {
    margin: 8px 0px;
    color: #F02E4A;
    width: 100%;
    border: solid;
    border-width: 1px;
    border-color: #F02E4A;
    border-radius: 4px;
    background: rgba(193,89,73,0.06);
    display: flex;
    padding: 12px;
}

.alert-block-icon-container {
    width: 20px;
    color: #F02E4A;
}

.alert-block-text-container {
    width: calc(100% - 20px);
    
}
    .alert-block-text-container p {
        color: #F02E4A;
        font-size: 10px;
    }