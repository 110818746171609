.dashboard {
	width: 100%;
	height: 100%;
	display: flex;
	padding: 0px;
	padding-left: 0px;
	background-color: #161719;
}

.dashboard-tablet {
	border-radius: 12px;
	overflow: hidden;
}

iframe {
	position: absolute !important;
	width: 0px !important;
	height: 0px !important;
	z-index: 0 !important;
}

::-webkit-scrollbar {
	height: 4px;
    width: 4px;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}

/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
    background: rgba(0,0,0,0.3);
    border-radius: 3px;
}

p {
	opacity: 1 !important;
}

.switch-container {
	height: 100%;
	width: 100%;
}

.dashboard-modal-container {
	width: 100%;
	height: 100%;
	overflow-y: scroll;
	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 100;
}

.dashboard-modal-container-confirm {
	top: 0px;
	left: 0px;
}

.dashboard-content-container {
	background-color: #161719; /* #202436;*/
	width: calc(100% - 220px);
	height: 100%;
	overflow-y: scroll;
	overflow-x: visible;
	-webkit-transition: all 0.35s;
  	-moz-transition: all 0.35s;
  	transition: all 0.35s;
  	position: relative;
  	overflow: hidden;
}
	.dashboard-content-container-full-width {
		width: 100% !important;
	}
	.dashboard-content-container-dark {
		background-color: #161719;
	}

.dashboard-menu-container {
	background-color: #161719;
	width: 220px;
	height: 100%;
	border-right: solid;
	border-width: 0.5px;
	border-color: #2B2B30;
	-webkit-transition: all 0.35s;
  	-moz-transition: all 0.35s;
  	transition: all 0.35s;
  	
}

.dashboard-content-container-back-highlight {
	/*background: rgb(36,39,61);
	background: linear-gradient(170deg, #181B2A 0%, rgba( 20,24,36,1.0) 100%);
	
	position: absolute;
	width: 1000px;
	height: 200vh;
	top: -100px;
	right: -200px;
	z-index: 0;
	transform: rotate(50deg);
	box-shadow: 0 4px 38px 12px rgba(12,13,21, 0.1);*/
}

	.dashboard-content-container-back-highlight-dark {
		/*background: rgb(36,39,61);
		background: linear-gradient(170deg, rgba(26,30,47,1) 0%, rgba(26,27,42,1) 100%);*/
	}

@media screen and (max-width: 1100px) {
  	.dashboard-content-container {
		width: calc(100% - 55px);
		-webkit-transition: all 0.35s;
  		-moz-transition: all 0.35s;
  		transition: all 0.35s;
	}

	.dashboard-menu-container {
		width: 55px;
		-webkit-transition: all 0.35s;
  		-moz-transition: all 0.35s;
  		transition: all 0.35s;
	}
}

/*@media screen and (max-width: 800px) {

  	.dashboard-content-container {
		width: calc(100% - 80px);
		-webkit-transition: all 0.35s;
  		-moz-transition: all 0.35s;
  		transition: all 0.35s;
	}

	.dashboard-menu-container {
		width: 80px;
		-webkit-transition: all 0.35s;
  		-moz-transition: all 0.35s;
  		transition: all 0.35s;
	}
}*/

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}


.menu-enter {
	transform-origin: top;
	transform: scaleY(0.8);
	opacity: 0;
}
/* This is where we can add the transition*/
.menu-enter-active {
	/*top: 45px;
	width: 200px;
	max-height: 200px;*/
	transform-origin: top;
	transform: scaleY(1.0);
	opacity: 1.0;
	transition: all 200ms;
}
/* This fires as soon as the this.state.showList is false */
.menu-exit {
	transform-origin: top;
	transform: scaleY(1.0);
	opacity: 1.0;
}
/* fires as element leaves the DOM*/
.menu-exit-active {
	transform-origin: top;
	transform: scaleY(0.8);
	opacity: 0;
	transition: all 200ms;
}

.slide-enter {
	transform: translateX(-80%);
	opacity: 0;
}
/* This is where we can add the transition*/
.slide-enter-active {
	transform: translateX(0px);
	opacity: 1.0;
	transition: all 350ms;
}
/* This fires as soon as the this.state.showList is false */
.slide-exit {
	transform: translateX(0px);
	opacity: 1.0;
}
/* fires as element leaves the DOM*/
.slide-exit-active {
	transform: translateX(-80%);
	opacity: 0.0;
	transition: all 350ms;
}




.slide-right-enter {
	transform: translateX(80%);
	opacity: 0;
}
/* This is where we can add the transition*/
.slide-right-enter-active {
	transform: translateX(0px);
	opacity: 1.0;
	transition: all 350ms;
}
/* This fires as soon as the this.state.showList is false */
.slide-right-exit {
	transform: translateX(0px);
	opacity: 1.0;
}
/* fires as element leaves the DOM*/
.slide-right-exit-active {
	transform: translateX(80%);
	opacity: 0.0;
	transition: all 350ms;
}


.dashboard-trial-overlay {
	padding-top: 0px;
	overflow-y: hidden;
	width: 100%;
	height: 100%;
	min-height: 100%;	
	background: rgba(12,13,20,0.6);
	vertical-align: middle;
	position: relative;
	-webkit-backdrop-filter: blur(12px);
	z-index: 999;
}

.dashboard-trial-modal {
	height: 2%;
	min-height: 230px;
	width: 600px;
	right: calc(50% - 300px);
	/*min-height: 540px;*/
	top: calc(25% - 32px);
	min-width: 600px;
	background: #161719;
	border-radius: 5px;
	border: solid;
	border-width: 1px;
	border-color: #2B2B30;
	padding: 36px;

	/*border-bottom-right-radius: 0px;
	border-top-right-radius: 0px;*/
	overflow: hidden;
	box-shadow: -50px 0px 300px 100px rgba(0, 0, 0, 0.3);
	z-index: 500 !important;
	position: absolute;
	-webkit-transition: all 0.35s;
	-moz-transition: all 0.35s;
	transition: all 0.35s;
}
	.dashboard-trial-modal h4 {
    font-size: 21px;
    color: white;
    margin: 0px;
	}
	.dashboard-trial-modal p {
    font-size: 16px;
    color: #ffffff;
    margin: 0px;
    margin-top: 24px;
	}
		.dashboard-trial-modal-button {
	    padding: 12px 8px;
	    margin-top: 32px;
	    background: #5B62FF;
	    display: flex;
	    border-radius: 5px;
	    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.12);
	    text-decoration: none !important;
	    -webkit-transition: all 0.15s;
	    -moz-transition: all 0.15s;
	    transition: all 0.15s;
		}
			.dashboard-trial-modal-button:hover {
			    background: #7278F2;
			    cursor: pointer;
			    -webkit-transition: all 0.15s;
			    -moz-transition: all 0.15s;
			    transition: all 0.15s;
			}
				.dashboard-trial-modal-button p {
			    font-size: 13.5px;
			    color: white;
			    width: 100%;
			    text-align: center;
			    text-decoration: none !important;
			    margin: 0px;
			}



/* Mobile CSS */
@media screen and (max-width: 560px) {
	.dashboard-menu-container {
		display: none;
	}
	.dashboard-content-container {
		width: 100% !important;
	}
}


