/* .my-coaching-profile-  */

.my-coaching-profile {

}
	.my-coaching-profile-page-container {
		width: 100%;
		height: calc(100% - 52px);
	}
		.my-coaching-profile-page-container-inner {
			width: 100%;
			height: 100%;
		}