.form-tempo-input{
	position: relative;
	padding-top: 0px;
}

.form-tempo-input p {
	margin: 0px;
}

input[type=text]:focus {
  border: none;
}

.form-tempo-input-container {
	display: flex;
	border-color: #2B2B30;
	color: #ffffff;
	font-size: 13.5px;
	background: rgba(0,0,0,0.1);
	border-width: 1px;
	border-style: solid;
	border-radius: 5px;
	width: calc(100% - 12px);
	height: 36px;
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
}
	.form-tempo-input-container-no-border {
		border-style: none !important;
		white-space: nowrap;
	}
	.form-tempo-input-container-focused {
		border-color: #5B62FF;
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}
	.form-tempo-input-text-show-container {
		position: relative;
		width: 100%;
	}
		.form-tempo-input-text-show-container h5 {
			font-size: 13.5px !important;
			color: #ffffff;
			margin: 0px;
			width: 100%;
			text-align: center;
			padding-top: 8px;
		}
	.form-tempo-input-text-input-container {
		color: rgba(255,0,0,0);
		width: calc(100% - 13px);
		text-align: center;
		position: absolute;
	}
		.form-tempo-input-typing-blinker {
			right: auto;
			left: calc(50% - 21px);
		}

.form-tempo-input-container-alert {
	background-color: rgba(100,0,0,0.12);
	padding: 0px 12px;
	width: auto;
	border-radius: 4px;
	border: solid;
	border-color: #F02E4A;
	font-size: 13.5px;
	border-width: 1px !important;
	color: #ffffff;
}

.form-tempo-input-container-search {
	background-color: #1F1F21;
	padding: 8px 12px;
	width: auto;
	border-radius: 5px;
	margin: 8px 0px;
}

.form-tempo-input-label {
	font-size: 13.5px;
	color: #ffffff;
	font-weight: 500;
	position: absolute;
	top: -12px;
	left: 0px;
}

.form-tempo-input-alert-label {
	font-size: 10px;
	color: #F02E4A;
	position: absolute;
	text-align: right;
	top: -10px;
	right: 0px;
}

	.form-tempo-input-param-colon {
		font-size: 10px;
        padding: 0px 2px;
        margin-top: -4px;
        color: #9DA0AD;
	}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
margin: 0;
}


