/* .calendar-day-timeline */

.calendar-day-timeline {
	width: 100%;
	padding: 12px 0px;
	position: relative;
}
	.calendar-day-timeline-inner {

	}
		.calendar-day-timeline-section {
			display: flex;
			height: 100px;
		}
		.calendar-day-timeline-section-end {
			height: 10px;
		}
			.calendar-day-timeline-section-time-container {
				width: 50px; 
			}
				.calendar-day-timeline-section-time-container p {
				    font-size: 13.5px;
				    color: #9DA0AD;
				    margin: 0px;
				}
			.calendar-day-timeline-section-content {
				width: calc(100% - 50px);
				margin-top: 8px;
			}
				.calendar-day-timeline-section-content-inner {
					width: calc(100%);
					border-top: solid;
					border-width: 1px;
					border-color: #2B2B30;
					height: 50px;
				}
	.calendar-day-timeline-appts-container {
		width: 100%;
		position: absolute;
		top: 0px;
		left: 0px;
		z-index: 5;
		padding-left: 62px;
	}
		.calendar-day-timeline-appts-container-inner {
			position: relative;
			width: 100%;
		}
			


