.workout-plan-list-cycle-overview-card-content-cell {
	width: 100%;
	background: var(--ef-card);
	border-radius: var(--ef-radius-m);
	box-shadow: 0px 0px 12px 6px rgba(0, 0, 0, 0.12);
	display: flex;
	height: 102px;

	padding: 0px;
	border: solid;
	border-width: 1px;
	border-color: var(--ef-border);

	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
}
.workout-plan-list-cycle-overview-card-content-cell:hover {
	box-shadow: 0px 4px 12px 16px rgba(20, 50, 20, 0.1);
	border-color: var(--ef-border-light);
	background: var(--ef-card-light);
/*	cursor: pointer;*/
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
	/*background: #1F1F21;
	cursor: pointer;
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;*/
}
.workout-plan-list-cycle-overview-card-content-cell-purposed {
	background: #5B62FF !important;
	border-color: #5B62FF;
}
.workout-plan-list-cycle-overview-card-content-cell-no-boarder {
	/*border: none;*/
}
.workout-plan-list-cycle-overview-card-content-cell-dragging {
	background: repeating-linear-gradient(
		45deg,
		rgba(26,27,34,0.9),
		rgba(26,27,34,0.9) 6px,
		rgba(26,27,34,0.3) 6px,
		rgba(26,27,34,0.3) 12px
	) !important;
	opacity: 0.7;
}
	.workout-plan-list-cycle-overview-card-content-cell-header {
		display: flex;
		width: 50px;
		position: relative;
	}
		.workout-plan-list-cycle-overview-card-content-cell-header p {
			color: #ffffff;
		}
		.workout-plan-list-cycle-overview-card-content-cell-header-dot {
			width: 10px;
			height: 10px;
			border-radius: 5px;
			position: absolute;
			left: -5px;
			top: 21px;
			background: var(--ef-color-phase);
		}
		.workout-plan-list-cycle-overview-card-content-cell-header-dot-dark {
			background: var(--ef-text-dark);
		}
		.workout-plan-list-cycle-overview-card-content-cell-header-icon-wrapper {
			width: 50px;
			padding: 10px 10px;
			border-right: solid;
			border-width: 1px;
			border-color: var(--ef-border-highlight-dark);
		}
		.workout-plan-list-cycle-overview-card-content-cell-header-icon-container {
			width: 30px;
            height: 30px;
            border-radius: var(--ef-radius-m);
            padding: 5px 7px;
            background: var(--ef-color-phase-transparent);
		}
		.workout-plan-list-cycle-overview-card-content-cell-header-icon-container-dark {
			background: #7074842F;
		}
			.workout-plan-list-cycle-overview-card-content-cell-header-icon {
				width: 20px;
				height: 20px;
			}
		.workout-plan-list-cycle-overview-card-content-cell-header-text-container {
			padding-left: 0px;
			padding-top: 7px;
			white-space: nowrap;
			display: flex;
		}
			.workout-plan-list-cycle-overview-card-content-cell-header-text-container p {
				margin-right: 12px;
				overflow: hidden;
				font-weight: 300;
				color: var(--ef-color-phase);
				font-size: var(--ef-font-size-m);
			}
			.workout-plan-list-cycle-overview-card-content-cell-header-text-container h4 {
				font-size: var(--ef-font-size-m);
				color: var(--ef-text-light);
				font-weight: 600;
				margin: 0px;
				margin-left: 0px;
			}
			.workout-plan-list-cycle-overview-card-content-cell-header-text-container-no-plan h4 {
				color: var(--ef-text-mid);
			}
			.workout-plan-list-cycle-overview-card-content-cell-header-text-container-no-plan p {
				color: var(--ef-text-dark);
			}
		.workout-plan-list-cycle-overview-card-content-cell-header-periodization-container {
			position: absolute;
			top: -10px;
			right: -22px;
			background: rgba(114, 120, 242, 0.2);
			padding: 2px 6px;
			border-radius: 3px;
		}
			.workout-plan-list-cycle-overview-card-content-cell-header-periodization-container p {
			    font-size: 12px !important;
			    font-weight: 500;
			    color: #7278F2 !important;
			    margin: 0px;
			}
			.workout-plan-list-cycle-overview-card-content-cell-header-periodization-container-green {
				background: rgba(114, 242, 120, 0.2);
			}
				.workout-plan-list-cycle-overview-card-content-cell-header-periodization-container-green p {
					color: #72F278 !important;
				}
		.workout-plan-list-cycle-overview-card-content-cell-header-drag-handle-container {
			z-index: 10;
			width: 24px;
			height: 26px;
			margin-left: -4px;
			padding-top: 1px;
			cursor: move;
		    cursor: grab;
		    cursor: -moz-grab;
		    cursor: -webkit-grab;
		}
			.workout-plan-list-cycle-overview-card-content-cell-header-drag-handle-container:active {
			    cursor: grabbing;
			    cursor: -moz-grabbing;
			    cursor: -webkit-grabbing;
			}
			.workout-plan-list-cycle-overview-card-content-cell-header-drag-handle-icon {
				color: #6C6C6C;
				font-size: 16px !important;
			}


.workout-plan-list-cycle-overview-card-content-cell-content-no-plan {
	width: 100%;
	margin: 0px;
}
	.workout-plan-list-cycle-overview-card-content-cell-content-no-plan h5 {
		font-size: 13.5px;
		margin: 0px;
		margin-top: 12px;
		margin-bottom: 4px;
		width: 100%;
		text-align: center;
		color: #ffffff;
	}
	.workout-plan-list-cycle-overview-card-content-cell-content-no-plan h4 {
	    font-size: 13.5px;
	    padding-top: 8px;
	    white-space: nowrap;
	    color: #ffffff;
	    margin: 0px;
	}
	.workout-plan-list-cycle-overview-card-content-cell-content-no-plan p {
		width: 80%;
		margin: 0px auto;
		text-align: center;
	}

	.workout-plan-list-cycle-overview-card-content-cell-content-no-plan-add-button {
		position: absolute;
		display: flex;
		padding: 6px 10px;
		background: var(--ef-cta);
		padding-right: 6px;
		border-radius: 5px;
		top: 50px;
		left: calc(50% - 90px);
		box-shadow: 0px 0px 12px 6px rgba(0, 0, 0, 0.12);
		float: left;
		z-index: 3;
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}
	.workout-plan-list-cycle-overview-card-content-cell-content-no-plan-add-button:hover {
		background: var(--ef-cta-light);
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
		cursor: pointer;
	}
		.workout-plan-list-cycle-overview-card-content-cell-content-no-plan-add-button p {
			color: var(--ef-text-white);
			font-size: 12px;
			margin: 0px;
			width: 100%;
			text-align: center;
			white-space:nowrap
		}
		.workout-plan-list-cycle-overview-card-content-cell-content-no-plan-add-icon {
			font-size: 13px !important;
			color: var(--ef-text-white);
			margin-left: 8px;
			margin-top: 1px;
		}
	.workout-plan-list-cycle-overview-card-content-cell-content-no-plan-add-circle {
		width: 36px;
		height: 36px;
		border-radius: 18px;
		background: rgba(0,0,0,0.2);
		margin: 0px auto;
		padding-top: 10px;
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;	
	}
		.workout-plan-list-cycle-overview-card-content-cell-content-no-plan-add-circle h3 {
			font-size: 13.5px; 
			margin: 0px;
			color: #ffffff;
			width: 100%;
			text-align: center;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;	
		}
		.workout-plan-list-cycle-overview-card-content-cell:hover .workout-plan-list-cycle-overview-card-content-cell-content-no-plan-add-circle {
			background: #4959C1;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;	
		}
		.workout-plan-list-cycle-overview-card-content-cell:hover .workout-plan-list-cycle-overview-card-content-cell-content-no-plan-add-circle h3 {
			color: white;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;	
		}


		.workout-plan-list-cycle-overview-card-content-cell-content-inner {
			width: 100%;
			padding: 0px 24px;
			padding-top: 8px;
			position: relative;
		}
			.workout-plan-list-cycle-overview-card-content-cell-content-inner-loading {
				width: 100%;
				padding-top: 45px;
				padding-right: 65px;
			}
				.workout-plan-list-cycle-overview-card-content-cell-content-inner-loading p {
					width: 100%;
					text-align: center;
				}

		.workout-plan-list-cycle-overview-card-content-cell:hover .workout-plan-list-cycle-overview-card-content-header-buttons-container {
			opacity: 1.0;
			width: 148px;
		    -webkit-transition: all 0.15s;
		    -moz-transition: all 0.15s;
		    transition: all 0.15s;
		}
		.workout-plan-list-cycle-overview-card-content-cell:hover .workout-plan-list-cycle-overview-card-content-header-buttons-container-single {
			opacity: 1.0;
			width: 46px;
			z-index: 5;
		    -webkit-transition: all 0.15s;
		    -moz-transition: all 0.15s;
		    transition: all 0.15s;
		}
	
			.workout-plan-list-cycle-overview-card-content-header-buttons-container {
				opacity: 0.0;
				width: 0px;
				position: absolute;
				overflow: hidden;
				padding: 8px 0px;
				top: 4px;
				right: 0px;
				display: flex;
			    -webkit-transition: all 0.15s;
			    -moz-transition: all 0.15s;
			    transition: all 0.15s;
			}
				.workout-plan-list-cycle-overview-card-content-header-button {
					
				    padding: 8px 8px;
				    width: 30px;
				    height: 30px;
				    margin-left: 4px;
				    background: var(--ef-card);
				    border: solid;
				    border-width: 1px;
				    border-color: var(--ef-border-light);
				    display: flex;
				    border-radius: var(--ef-radius-m);
				    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.12);
				    -webkit-transition: all 0.15s;
				    -moz-transition: all 0.15s;
				    transition: all 0.15s;
				}

				.workout-plan-list-cycle-overview-card-content-header-button-blue {
					background: var(--ef-cta);
					border-color: var(--ef-cta);
				}
				.workout-plan-list-cycle-overview-card-content-header-button:hover {
				    background: var(--ef-card-light);
				    border-color: var(--ef-border-light);
				    cursor: pointer;
				    -webkit-transition: all 0.15s;
				    -moz-transition: all 0.15s;
				    transition: all 0.15s;
				}
				.workout-plan-list-cycle-overview-card-content-header-button-blue:hover {
					background: var(--ef-cta-light);
				    border-color: var(--ef-cta-light);
				}
				.workout-plan-list-cycle-overview-card-content-header-button-delete:hover {
					background: var(--ef-alert);
					border-color: var(--ef-alert);
				}
					.workout-plan-list-cycle-overview-card-content-header-button-icon {
						font-size: 13px !important;
						color: var(--ef-text-mid);
					}
					.workout-plan-list-cycle-overview-card-content-header-button-icon-blue {
						color: var(--ef-text-white);
					}
					.workout-plan-list-cycle-overview-card-content-header-button:hover .workout-plan-list-cycle-overview-card-content-header-button-icon {
						color: var(--ef-text-light);
					}
					.workout-plan-list-cycle-overview-card-content-header-button:hover .workout-plan-list-cycle-overview-card-content-header-button-icon-blue {
						color: var(--ef-text-white);
					}
					.workout-plan-list-cycle-overview-card-content-header-button-delete:hover .workout-plan-list-cycle-overview-card-content-header-button-icon {
						color: var(--ef-text-white);
					}

.workout-plan-list-cycle-overview-card-content-cell-header-body-container {
	width: 100%;
	display: flex;
	height: 60px;
	overflow: hidden;
}
	.workout-plan-list-cycle-overview-card-content-cell-content-col {
		width: calc(50% - 20px);
		min-width: 280px;
	}
		.workout-plan-list-cycle-overview-card-content-cell-content-row {
			padding-top: 7px;
			padding-left: 0px;
			width: 100%;
			white-space: nowrap;
			position: relative;
			display: flex;
		}
			.workout-plan-list-cycle-overview-card-content-cell-content-row h4 {
				font-weight: 400;
				color: white;
				white-space: nowrap;
				font-size: 13.5px !important;
			}
			.workout-plan-list-cycle-overview-card-content-cell-content-row span {
				font-weight: 400;
				color: #9DA0AD;
				font-size: 13.5px !important;
				margin-right: 6px;
				width: 120px !important;
			}
			.workout-plan-list-cycle-overview-card-content-cell-content-row-dot {
				position: absolute;
				top: 11px;
				left: -14px;
				height: 8px;
				width: 8px;
				border-radius: 4px;
				background: #FF4D4D;
			}

			.workout-plan-list-cycle-overview-card-content-cell-content-row-placeholder-title {
				background: var(--ef-text-dark);
				height: 12px;
				min-width: 20px;
				border-radius: 7px;
				margin-right: 12px;
				opacity: 0.2;
				z-index: 0;
			}
			.workout-plan-list-cycle-overview-card-content-cell-content-row-placeholder-content {
				background: var(--ef-text-light);
				height: 12px;
				min-width: 20px;
				border-radius: 7px;
				position: absolute;
				left: 126px;
				opacity: 0.2;
				z-index: 1;
			}
		.workout-plan-list-cycle-overview-card-content-cell-header-body-container-overlay-wrapper {
			position: absolute;
			z-index: 2;
			width: calc(100% - 14px);
			height: 60px;
			left: 0px;
			top: 40px;
			overflow: hidden;
		}
			.workout-plan-list-cycle-overview-card-content-cell-header-body-container-overlay {
				width: 100%;
				height: 100%;
				background: rgb(31,31,33);
				background: linear-gradient(90deg, rgba(31,31,33,1) 25%, rgba(31,31,33,0) 50%, rgba(31,31,33,1) 75%);
				animation: wipe 5s ease-in-out infinite;
			}
			.workout-plan-list-cycle-overview-card-content-cell:hover .workout-plan-list-cycle-overview-card-content-cell-header-body-container-overlay {
				background: linear-gradient(90deg, rgba(40,40,45,1) 25%, rgba(40,40,45,0) 50%, rgba(40,40,45,1) 75%);
			}
            @keyframes wipe {
                0% { transform: scaleX(1); }
                1% { transform: scaleX(1); }
                48% { transform: scaleX(3); }
                52% { transform: scaleX(3); }
                99% { transform: scaleX(1); }
                100% { transform: scaleX(1); }
            }
				.workout-plan-list-cycle-overview-card-content-cell-content-row-placeholder-title-a { 
					width: 100px;
				}
				.workout-plan-list-cycle-overview-card-content-cell-content-row-placeholder-content-a { 
					width: 70px;
				}
				.workout-plan-list-cycle-overview-card-content-cell-content-row-placeholder-title-b { 
					width: 70px;
				}
				.workout-plan-list-cycle-overview-card-content-cell-content-row-placeholder-content-b { 
					width: 64px;
				}
				.workout-plan-list-cycle-overview-card-content-cell-content-row-placeholder-title-c { 
					width: 80px;
				}
				.workout-plan-list-cycle-overview-card-content-cell-content-row-placeholder-content-c { 
					width: 60px;
				}
				.workout-plan-list-cycle-overview-card-content-cell-content-row-placeholder-title-d { 
					width: 95px;
				}
				.workout-plan-list-cycle-overview-card-content-cell-content-row-placeholder-content-d { 
					width: 84px;
				}



		.workout-plan-list-cycle-overview-card-content-cell-content-rep-scheme-container {
			width: 100%;
			display: flex;
		}
			.workout-plan-list-cycle-overview-card-content-cell-content-rep-scheme-container h4 {
				padding-right: 6px;
			}
	.workout-plan-list-cycle-overview-card-content-cell-content {
		position: relative;
		width: calc(100% - 36px);
		border-width: 1px !important;
		border-left: solid;
		border-color: var(--ef-border);
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}
		.workout-plan-list-cycle-overview-card-content-cell:hover .workout-plan-list-cycle-overview-card-content-cell-content {
			border-color: var(--ef-border-light);
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}

.workout-plan-list-cycle-overview-card-content-cell-content-options {
	width: calc(100% + 40px);
	margin-left: -20px;
	display: flex;
	
	padding: 12px 20px;
	padding-bottom: 0px;
	margin-top: 12px;
}
	.workout-plan-list-cycle-overview-card-content-cell-select-arrow-icon-container {
		bottom: 0px;
		right: -6px;
		height: 24px;
		border-radius: 5px;
		border: solid;
		border-width: 0.5px;
		border-color: #2B2B30;
		cursor: pointer;
		display: flex;
		padding: 3.5px 12px;
		padding-right: 8px;
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}
	.workout-plan-list-cycle-overview-card-content-cell-select-arrow-icon-container:hover {
		background: #28282D !important;
	}
		.workout-plan-list-cycle-overview-card-content-cell-select-arrow-icon-container p {
			font-size: 12px;
			color: #6C6C6C !important;
			margin: 0px;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
		.workout-plan-list-cycle-overview-card-content-cell-select-arrow-icon-container:hover p {
			color: #ffffff !important;
		}
		.workout-plan-list-cycle-overview-card-content-cell:hover .workout-plan-list-cycle-overview-card-content-cell-select-arrow-icon-container p {
			color: #ffffff;
		}
	.workout-plan-list-cycle-overview-card-content-cell:hover .workout-plan-list-cycle-overview-card-content-cell-select-arrow-icon-container {
		background: #1F1F21;
		box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
	}
		.workout-plan-list-cycle-overview-card-content-cell-select-arrow-icon {
			font-size: 11px !important;
			margin-top: 3px;
			margin-left: 4px;
			color: #6C6C6C;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
		.workout-plan-list-cycle-overview-card-content-cell:hover .workout-plan-list-cycle-overview-card-content-cell-select-arrow-icon {
			color: #9DA0AD;
		}

