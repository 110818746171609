.login-container {
	padding: 0px;
	background-color: #28282D;
	position: relative;
}

.login-background-container {
	width: 100%;
	height: 100%;
}
	.login-background-image {
		object-fit: cover;
		max-width: 100vw;
		min-height: 100vh;
		opacity: 0.2;
	}

	.login-logo-container {
		position: absolute;
		top: 24px;
		left: 24px;
	}
		.login-logo-container p {
			font-size: 13.5px;
			color: #ffffff;
			font-weight: 500;
		}
		.login-logo-container span {
			color: #F02E4A;
			font-weight: 700;
		}

.login-header-container {
	width: 40%;
	margin: auto;
	min-width: 380px;
	padding-bottom: 40px;
}

.login-info-block-header {

}
	.login-info-block-header h1 {
		color: #ffffff;
		font-size: 18px;
		margin: 0px;
		margin-bottom: 6px;
	}
	.login-info-block-header p {
		color: #9DA0AD !important;
		font-size: 12px !important;
		margin: 0px;
		line-height: 18px;
	}

.login-info-block {
	position: absolute;
	z-index: 200;
	top: calc(50vh - 225px);
	left: calc(50% - 200px);
	border-radius: 10px;
	padding: 38px 24px;
	background-color: #1F1F21;
	margin: auto;
	width: 400px;
	height: 454px;
	border: solid;
	border-width: 1px;
	border-color: #2B2B30;
	box-shadow: 0 0px 50px 12px rgba(0, 0, 0, 0.3);
}

.login-info-block p {
	font-size: 14px;
	color: #C2C5CD;
}
	.login-info-block-input-container {
		width: 100%;
		height: 210px;
		min-height: 210px;
	}
	.login-info-block-input-container-lg {

	}
		.login-info-block-input-container-lg h4 {

		}
		.login-info-block-input-container-lg p {

		}
		.login-info-block-input-container-lg span {
			color: #7278F2;
			opacity: 1;
			font-weight: 600;
		}
		.login-info-block-input-container-lg a {
			color: #7278F2;
			opacity: 1;
		}

.sign-in-button {
	font-size: 12px;
	padding: 0px 40px;
}

.register-container {
	padding-top: 12px !important;
	text-align: center;	
}
	.register-container p {
		font-size: 12px;
		color: #ffffff;
	}
	.register-container a {
		color: #5B62FF;
		font-weight: 600;
		margin-left: 4px;
		cursor: pointer;
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}
	.register-container a:hover {
		color: #7278F2;
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}
.reset-password-button-container {
	padding-top: 0px !important;
}
	.reset-password-button-container a {
		font-size: 12px;
		color: #5B62FF;
		font-weight: 600;
		margin-left: 4px;
		cursor: pointer;
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}

.login-copyright-container {
	position: absolute;
	top: calc(100vh - 42px);
	left: 0px;
	width: 100%;
	text-align: center;
	font-size: 10px;
	color: #6C6C6C;
	opacity: 0.8;
}
	.login-error-container {
		width: 100%;
	}
		.login-error-container p {
		    font-size: 13.5px;
		    color: #FF4D4D;
		    margin: 0px;
		    margin-top: -20px;
		    margin-bottom: 12px;
		    width: 100%;
		    text-align: center;
		}
.login-loading-spinner-container {
	width: 100%;
	padding-top: 100px;
}
	.login-loading-spinner-container p {
	    font-size: 12px;
	    color: #9DA0AD;
	    margin: 0px;
	    margin-top: 12px;
	    width: 100%;
	    text-align: center;
	}
.login-loading-spinner {
    margin: 0px auto;
    margin-top: -3px;
    width: 24px;
    height: 24px;
    border: 3px solid rgba(0,0,0,0); /* Light grey */
    border-top: 3px solid #5B62FF;
    border-right: 3px solid #5B62FF;
    border-bottom: 3px solid #5B62FF;
    border-radius: 12px;
    animation: spin 0.8s linear infinite;
}
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }


