.node-stream-analysis {
	width: 100%;
	height: 100%;
	overflow-y: scroll;
	position: relative;
}

.node-stream-analysis-upper-container {
	width: 100%;
	display: flex;
	padding: 24px;
	position: absolute;
	top: 0px;
	z-index: 200;
	background: rgba(0, 0, 0, 0.35);
	border-bottom: solid;
	border-width: 1px;
	border-color: var(--ef-border);
	box-shadow: 0px 0px 24px 12px rgba(0, 0, 0, 0.2);
	-webkit-backdrop-filter: blur(8px);
	backdrop-filter: blur(8px);
}
	.node-stream-analysis-upper-container-system-status-container {
		margin-left: auto;
		float: right;
		display: flex;
		background: #000000;
		border-radius: 5px;
		padding: 0px 12px;
		padding-top: 6px;
		margin-top: -8px;
	}
		.node-stream-analysis-upper-container-system-status-icon {
			width: 8px;
			height: 8px;
			border-radius: 4px;
			margin-right: 8px;
			margin-top: 5px;
			margin-bottom: 0px;
		}
			.node-stream-analysis-upper-container-system-status-icon-green {
				background: #5BFF62;
				box-shadow: 0px 0px 6px 3px rgba(50, 255, 50, 0.4);
			}
			.node-stream-analysis-upper-container-system-status-icon-red {
				background: #FF5B62;
				box-shadow: 0px 0px 6px 3px rgba(255, 50, 50, 0.4);
			}
			.node-stream-analysis-upper-container-system-status-text-container {
				padding-top: 1px;
			}
				.node-stream-analysis-upper-container-system-status-text-container p {
				    font-size: var(--ef-font-size-s);
				    color: var(--ef-text-light);
				    margin: 0px !important;
				}
	.node-stream-analysis-video-container {
		
		width: 533px;
		height: 400px;
	}
		.node-stream-analysis-video-container video {
			height: 100%;
			width: 100%;
			border-radius: 12px;
			box-shadow: 0px 0px 12px 6px rgba(0, 0, 0, 0.2);
		}


		.node-stream-analysis-info-container {
			padding: 12px 38px;
			width: 300px;
			
		}
			.node-stream-analysis-info-container h4 {
			    font-size: 18px;
			    color: #ffffff;
			    margin: 0px;
			    padding-bottom: 6px;
			}
		.node-stream-analysis-info-download-button {
		    padding: 4px 8px;
		    background: #5B62FF;
		    margin: 12px 0px;
		    display: flex;
		    border-radius: 5px;
		    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.12);
		    color: white;
		    text-decoration: none;
		    -webkit-transition: all 0.15s;
		    -moz-transition: all 0.15s;
		    transition: all 0.15s;
		    float: left;
		    font-size: 12px;
		}
		.node-stream-analysis-info-download-button:hover {
		    background: #7278F2;
		    cursor: pointer;
		    -webkit-transition: all 0.15s;
		    -moz-transition: all 0.15s;
		    transition: all 0.15s;
		}

			.node-stream-analysis-info-button-container {
				padding: 12px 0px;
				margin: 12px 0px;
				border-top: solid;
				border-bottom: solid;
				border-width: 1px;
				border-color: #2B2B30;
				display: inline-block;
				width: 100%;
			}
				.node-stream-analysis-info-button {
				    padding: 6px 8px;
				    height: 30px;
				    margin-right: 32px;
				    margin-bottom: 8px;
				    background: #1F1F21;
				    border: solid;
				    border-width: 1px;
				    border-color: #2B2B30;
				    display: block;
				    border-radius: 5px;
				    position: relative;
				    float: left;
				    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.12);
				    -webkit-transition: all 0.15s;
				    -moz-transition: all 0.15s;
				    transition: all 0.15s;
				}
				.node-stream-analysis-info-button:hover {
				    background: #1F1F21;
				    cursor: pointer;
				    -webkit-transition: all 0.15s;
				    -moz-transition: all 0.15s;
				    transition: all 0.15s;
				}
					.node-stream-analysis-info-button p {
					    font-size: 13.5px;
					    color: white;
					    margin: 0px;
					}
					.node-stream-analysis-info-button-blue {
						background: #5B62FF;
					}
					.node-stream-analysis-info-button-blue:hover {
						background: #7278F2;
					}
					.node-stream-analysis-info-button-red {
						background: #FF4D4D;
					}

		.node-stream-analysis-3d-container{
			padding: 0px 24px;
			margin-right: 12px;
			width: 400px;/*calc(100% - 933px);*/
		}

		.node-stream-analysis-stream-container {
			width: calc(100% - 900px);
			height: 100%;
			padding: 0px 24px;
			padding-right: 0px;
		}

	.node-stream-analysis-chart-wrapper {
		width: 100%;
		height: 100%;
		padding-top: 500px;
		overflow-y: scroll;
	}


	.node-stream-analysis-upper-container-select-session {
		width: 100%;
		height: 100%;
		display: block;
	}
		.node-stream-analysis-upper-container-select-session-header {
			border-bottom: solid;
			border-width: 1px;
			border-color: #2B2B30;
			padding: 24px 0px;
			padding-top: 12px;
			margin-bottom: 24px;
		}

			.node-stream-analysis-upper-container-select-session-header-content-container {
				display: flex;
				width: 100%;
			}
				.node-stream-analysis-upper-container-select-session-header-content-container h4 {
				    font-size: 18px;
				    color: #ffffff;
				    margin: 0px;
				}
				.node-stream-analysis-upper-container-select-session-header-button {
				    padding: 4px 8px;
				    margin-left: 12px;
				    margin-top: -4px;
				    background: #1F1F21;
				    border: solid;
				    border-width: 1px;
				    border-color: #2B2B30;
				    display: flex;
				    border-radius: 5px;
				    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.12);
				    -webkit-transition: all 0.15s;
				    -moz-transition: all 0.15s;
				    transition: all 0.15s;
							}
				.node-stream-analysis-upper-container-select-session-header-button:hover {
				    background: #1F1F21;
				    cursor: pointer;
				    -webkit-transition: all 0.15s;
				    -moz-transition: all 0.15s;
				    transition: all 0.15s;
				}
					.node-stream-analysis-upper-container-select-session-header-button p {
					    font-size: 13.5px;
					    color: #9DA0AD;
					    margin: 0px;
					}

				.node-stream-analysis-upper-container-select-session-header-button-blue {
					background: #5B62FF;
					border-color: #7278F2;
					width: 130px;
					white-space: nowrap;
				}
					.node-stream-analysis-upper-container-select-session-header-button:hover {
					    background: #7278F2;
					}
						.node-stream-analysis-upper-container-select-session-header-button p {
						    font-size: 13.5px;
						    color: #ffffff;
						    margin: 0px;
						    width: 100%;
						    text-align: center;
						}
				.node-stream-analysis-upper-container-select-session-header-button-red {
					background: #FF4D4D;
					border-color: #FF6D6D;
				    width: 200px;
				}
			.node-stream-analysis-upper-container-select-session-header-back-container {
				margin-top: -12px;
				display: flex;
				padding: 8px 0px;
				cursor: pointer;
			}
				.node-stream-analysis-upper-container-select-session-header-back-container p {
				    font-size: 12px;
				    color: #6C6C6C;
				    margin: 0px;
				}
				.node-stream-analysis-upper-container-select-session-header-back {
					margin-top: -2px;
				}
				.node-stream-analysis-upper-container-select-session-header-back-icon {
					font-size: 12px !important;
					color: #6C6C6C;
					margin-top: -5px;
				}

	.node-stream-analysis-upper-container-select-session-body-inner {
		width: 100%;
		display: block;
	}

		.node-stream-analysis-upper-container-select-session-body-list-cell {
			width: 100%;
			padding: 16px 16px;
			padding-bottom: 8px;
			border-bottom: solid;
			border-width: 1px;
			border-color: #2B2B3040;
			margin-top: 0px;
			display: flex;
			position: relative;
		}
		.node-stream-analysis-upper-container-select-session-body-list-cell:hover {
			cursor: pointer;
			box-shadow: 0px 0px 12px 6px rgba(0, 0, 0, 0.02);
			background: rgba(0, 0, 0, 0.16);
		}
			.node-stream-analysis-upper-container-select-session-body-list-cell-inner {
				display: flex !important;
				position: relative;
				width: 100%;
			}
				.node-stream-analysis-upper-container-select-session-body-list-cell-inner h4 {
				    font-size: 15px !important;
				    color: #ffffff;
				    margin: 0px;
				    width: calc(50%);
				}
				.node-stream-analysis-upper-container-select-session-body-list-cell-inner p {
				    width: calc(15%);
				    min-width: 200px;
				}
				.node-stream-analysis-upper-container-select-session-body-list-cell-inner-delete-button {
					position: absolute;
					top: 12px;
					right: 12px;
					border-radius: 5px;
					z-index: 5;
					padding: 4px 8px;
				}
				.node-stream-analysis-upper-container-select-session-body-list-cell-inner-delete-button:hover {
					background: rgba(255, 0, 0, 0.3);
					cursor: pointer;
				}
					.node-stream-analysis-upper-container-select-session-body-list-cell-inner-delete-button p {
					    font-size: 11px !important;
					    color: #6C6C6C !important;
					    margin: 0px;
					}
					.node-stream-analysis-upper-container-select-session-body-list-cell-inner-delete-button:hover p {
						color: #FF4D4D !important;
					}
				.node-stream-analysis-upper-container-select-session-body-list-cell-inner-complete {

				}
					.node-stream-analysis-upper-container-select-session-body-list-cell-inner-complete h4 {
						color: rgba(20, 255, 30, 1.0) !important;
					}
					.node-stream-analysis-upper-container-select-session-body-list-cell-inner-complete p {
						color: rgba(20, 255, 30, 1.0) !important;
					}

				.node-stream-analysis-upper-container-select-session-body-list-cell-inner-motion-info-container {
					margin-left: 24px;
					display: flex;
				}
					.node-stream-analysis-upper-container-select-session-body-list-cell-inner-motion-info-col {
						padding-left: 24px;
					}
						.node-stream-analysis-upper-container-select-session-body-list-cell-inner-motion-info-col p {
						    font-size: 13.5px;
						    color: #FF4D4D !important;
						    margin: 0px;
						    width: 100% !important;
						    min-width: 100px !important;
						}
						.node-stream-analysis-upper-container-select-session-body-list-cell-inner-motion-info-col-valid p {
							color: rgba(20, 255, 30, 1.0) !important;
						}

			.node-stream-analysis-upper-container-select-session-body-list-cell h4 {
				font-size: 18px;
			    color: #ffffff;
			    margin: 0px;
			    margin-bottom: 8px;
			}
			.node-stream-analysis-upper-container-select-session-body-list-cell p {
				font-size: 13.5px;
			    color: #7278F2;
			    margin: 0px;
			}
			.node-stream-analysis-upper-container-select-session-body-list-cell span {
				color: #6C6C6C;
				font-weight: 300;
				margin-left: 12px;
			}

		.node-stream-analysis-info-button-dropdown-wrapper {
			position: relative;
		}
			.node-stream-analysis-info-button-dropdown-container {
				position: absolute;
				top: 72px;
				left: 0px;
				width: 200px;
				background: #1F1F21;
				border: solid;
				border-width: 1px;
				border-color: #2B2B30;
				border-radius: 5px;
				box-shadow: 0px 0px 12px 6px rgba(0, 0, 0, 0.12);
				padding: 8px 0px;
			}
				.node-stream-analysis-info-button-dropdown-row {
					border-bottom: solid;
					border-width: 1px;
					border-color: #2B2B30;
					padding: 8px 16px;
					padding-top: 12px;
				}
				.node-stream-analysis-info-button-dropdown-row:hover {
					background: #1F1F21;
					cursor: pointer;
				}
					.node-stream-analysis-info-button-dropdown-row h4 {
					    font-size: 15px;
					    color: #ffffff;
					    margin: 0px;
					}


	.node-stream-analysis-upper-params-container {
		width: 100%;
		background: #1F1F21;
		border-radius: 5px;
		border: solid;
		border-width: 1px;
		border-color: #2B2B30;
		padding: 24px 20px;
		margin-bottom: 24px;
	}
		.node-stream-analysis-upper-params-header {
			width: 100%;
			/*border-bottom: solid;
			border-width: 1px;
			border-color: #2B2B30;*/
			padding-bottom: 16px;
			position: relative;
		}
			.node-stream-analysis-upper-params-header h4 {
			    font-size: 16px;
			    color: #ffffff;
			    margin: 0px;
			}
			.node-stream-analysis-upper-params-header p {
			    font-size: 13.5px;
			    color: #6C6C6C;
			    margin: 0px;
			    margin-top: 2px;
			}
			.node-stream-analysis-upper-params-header span {
			    font-size: 13.5px;
			    color: #5B62FF;
			    margin: 0px;
			    margin-top: 2px;

			}
			.node-stream-analysis-upper-params-header span:hover {
			    cursor: pointer;
			    color: #7278F2;
			    text-decoration: underline;
			}
			.node-stream-analysis-upper-params-header-save-button {
				position: absolute;
				top: 0px;
				right: 0px;
			    padding: 8px 12px;
			    background: #5B62FF;
			    display: flex;
			    border-radius: 5px;
			    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.12);
			    -webkit-transition: all 0.15s;
			    -moz-transition: all 0.15s;
			    transition: all 0.15s;
			}
			.node-stream-analysis-upper-params-header-save-button:hover {
			    background: #7278F2;
			    cursor: pointer;
			    -webkit-transition: all 0.15s;
			    -moz-transition: all 0.15s;
			    transition: all 0.15s;
			}
				.node-stream-analysis-upper-params-header-save-button p {
				    font-size: 13.5px;
				    color: #ffffff;
				    margin: 0px;
				}
		.node-stream-analysis-upper-params-body {
			width: 100%;
			min-height: 240px;
		}
		.node-stream-analysis-upper-params-body-flex {
			padding-top: 24px;
			display: flex;
		}
		.node-stream-analysis-upper-params-body-obj {
			width: 100%;
		}
		.node-stream-analysis-upper-params-body-loading {
			align-items: center;
			justify-content: center;
			padding-top: 100px;
		}
		.node-stream-analysis-upper-params-body-loading p {
			width: 100%;
			text-align: center;
		    font-size: 13.5px;
		    color: #6C6C6C;
		    margin: 0px;
		    margin-top: 12px;
		}
			.node-stream-analysis-upper-params-body-header {
				width: 100%;
				display: flex;
			}
				.node-stream-analysis-upper-params-body-col-header {
					padding: 12px 0px;
					border-bottom: solid;
					border-width: 1px;
					border-color: #2B2B30;
					padding-bottom: 12px;
					width: 33%;
				}
					.node-stream-analysis-upper-params-body-col-header p {
					    font-size: 12px !important;
					    color: #6C6C6C !important;
					    margin: 0px;
					}

			.node-stream-analysis-upper-params-body-row {
				padding: 8px 0px;
				border-bottom: solid;
				border-width: 1px;
				border-color: #2B2B30;
				width: 100%;
				display: flex;
				align-items: center;
			}
				.node-stream-analysis-upper-params-body-col {
					width: 33%;
					display: flex;
				}
					.node-stream-analysis-upper-params-body-col p {
					    font-size: 15px;
					    color: #ffffff;
					    margin: 0px;
					}
					.node-stream-analysis-upper-params-body-col span {
					    font-size: 15px;
					    color: #6C6C6C;
					    margin: 0px;
					    margin-left: 12px;
					}

					.node-stream-analysis-upper-params-body-col h4 {
					    font-size: 15px;
					    color: var(--ef-alert);
					    margin: 0px;
					    background: #ff00003F;
					    padding: 4px 8px;
					    border-radius: 5px;
					}
					.node-stream-analysis-upper-params-body-input-container {
						background: #00000040;
						border-radius: 5px;
						border: solid;
						border-width: 1px;
						border-color: #2B2B30;
						box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.08);
						width: 200px;
					}
					.node-stream-analysis-upper-params-body-input-container-lg {
						width: 700px;
					}
						.node-stream-analysis-upper-params-body-input-container .native-input {
							padding: 6px 12px;
						}
					.node-stream-analysis-upper-params-body-input-container h4 {
						font-size: 16px;
						margin: 0px;
						padding: 6px 12px;
					}
			.node-stream-analysis-upper-params-body-col-input-selector {
				padding: 4px 8px;
				margin: 4px 6px;
				margin-left: 0px;
				border-radius: 5px;
				border: solid;
				border-width: 1px;
				border-color: #2B2B3000;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
			.node-stream-analysis-upper-params-body-col-input-selector:hover {
				cursor: pointer;
				background: #5B62FF3f;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
				.node-stream-analysis-upper-params-body-col-input-selector p {
				    font-size: 13.5px;
				    color: #6C6C6C;
				    margin: 0px;
				}

			.node-stream-analysis-upper-params-body-col-input-selector-selected {
				background: #5B62FF;
				border-color: #7278F2;
				box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.1);
			}
			.node-stream-analysis-upper-params-body-col-input-selector-selected:hover {
				background: #7278F2 !important;
			}
				.node-stream-analysis-upper-params-body-col-input-selector-selected p {
					color: #ffffff;
				}
			.node-stream-analysis-upper-params-body-col-input-selector-selected-dark {
				background: #00000040;
			}
				.node-stream-analysis-upper-params-body-col-input-selector-selected-dark p {
					color: #FF4D4D;
				}


iframe {
	height: calc(100vh - 200px);
	top: 200px;
	display: none !important;
}
