.scheduled-message-row {
	width: 100%;
	background: var(--ef-card);
	border-radius: var(--ef-radius-m);
	border: solid;
	border-width: 1px;
	border-color: var(--ef-border);
	box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.08);
}
	.scheduled-message-row-header {
		width: 100%;
		display: flex;
		padding: 12px;
		position: relative;
	}
		.scheduled-message-row-header h4 {
		    font-size: var(--ef-font-size-l);
		    color: var(--ef-text-light);
		    margin: 0px;
		    margin-top: 5px;
		    font-weight: 300;
		}
		.scheduled-message-row-header-icon-container {
			width: 31px;
			height: 31px;
			border-radius: var(--ef-radius-s);
			background: var(--ef-color-phase-transparent);
			padding: 5px 5px;
			margin-right: 16px;
		}
			.scheduled-message-row-header-icon {
				font-size: 20px !important;
				color: var(--ef-color-phase);
			}
		.scheduled-message-row-header-input-container {
			background: #00000040;
			border-radius: var(--ef-radius-s);
			padding: 5px 10px;
			height: 31px;
			margin: 0px 6px;
			cursor: pointer;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
		.scheduled-message-row-header-input-container:hover {
			cursor: pointer;
			background: #000000A0;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
			.scheduled-message-row-header-input-container h4 {
				margin-top: 0px;
			}

		.scheduled-message-row-header-input-container-hrs {
			padding: 5px 6px;
			margin-right: 3px;
		}
		.scheduled-message-row-header-input-container-mins {
			padding: 5px 6px;
			margin: 0px 3px;
		}
		.scheduled-message-row-header-input-container-ampm {
			margin: 0px 0px;
			padding: 5px 6px;
		}
		.scheduled-message-row-header-date-selector-container {
			position: absolute;
			top: 6px;
			left: 66px;
		}
		.scheduled-message-row-header-input-dropdown-container {
			position: absolute;
			top: 48px;
			z-index: 2;
		}
		.scheduled-message-row-header-input-dropdown-container-hours {
			left: 196px;
		}
		.scheduled-message-row-header-input-dropdown-container-mins {
			left: 234px;
		}
			.scheduled-message-row-header-input-dropdown-container {
				background: var(--ef-card);
				border-radius: var(--ef-radius-m);
				box-shadow: 0px 0px 12px 6px rgba(0, 0, 0, 0.08);
				border: solid;
				border-width: 1px;
				border-color: var(--ef-border);
				padding: 6px 6px;
				min-width: 60px;
				height: 140px;
				overflow-y: scroll;
			}
				.scheduled-message-row-header-input-dropdown-row {
					width: 100%;
					padding: 3px 6px;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
				.scheduled-message-row-header-input-dropdown-row:hover {
					background: var(--ef-button-card);
					border-radius: var(--ef-radius-s);
					cursor: pointer;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
					.scheduled-message-row-header-input-dropdown-row p {
					    font-size: var(--ef-font-size-m);
					    color: var(--ef-text-mid);
					    margin: 0px;
						-webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}
					.scheduled-message-row-header-input-dropdown-row:hover p {
						color: var(--ef-text-white);
						-webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}
		.scheduled-message-row-header-buttons-container {
			margin-left: auto;
			float: right;
			display: flex;
		}
			.scheduled-message-row-header-button {
			    padding: 6px 6px;
			    width: 31px;
			    height: 31px;
			    background: var(--ef-button);
			    border: solid;
			    border-width: 1px;
			    border-color: var(--ef-border);
			    display: flex;
			    border-radius: 5px;
			    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.12);
			    -webkit-transition: all 0.15s;
			    -moz-transition: all 0.15s;
			    transition: all 0.15s;
			}
			.scheduled-message-row-header-button:hover {
			    background: var(--ef-button-card);
			    cursor: pointer;
			    -webkit-transition: all 0.15s;
			    -moz-transition: all 0.15s;
			    transition: all 0.15s;
			}
			.scheduled-message-row-header-button-icon {
				font-size: 18px !important;
				color: var(--ef-text-dark);
			}
	.scheduled-message-row-body {
		width: 100%;
		margin-top: -12px;
	}
		.scheduled-message-row-body-input-wrapper {
			padding: 0px 16px;
			z-index: 0;
			position: relative;
/*			border-radius: var(--ef-radius-m);*/
/*			box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.12);*/
		}





