/* .create-plan-workout-list-cell- */

.create-plan-workout-list-cell-wrapper {
	width: 100%;
	display: flex;
	position: relative;
}
	.create-plan-workout-list-cell-arrow-container {
		position: absolute;
		left: -24px;
		padding: 0px 0px;
		top: 0px;
		height: 60px;
	}
		.create-plan-workout-list-cell-arrow-up {
			width: 24px;
			height: 24px;
			border-radius: 5px;
			padding: 4px 4px;
			margin-bottom: 4px;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
			.create-plan-workout-list-cell-arrow-up:hover {
				background: rgba(0, 0, 0, 0.2);
				cursor: pointer;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
			.create-plan-workout-list-cell-arrow-up-placeholder:hover {
				background: transparent;
				cursor: default;
			}
			.create-plan-workout-list-cell-arrow-up-icon {
				font-size: 15px !important;
				color: #6C6C6C;
			}
			.create-plan-workout-list-cell-arrow-up:hover .create-plan-workout-list-cell-arrow-up-icon {
				color: #5B62FF;
			}

	.create-plan-workout-list-cell {

	}
	.create-plan-workout-list-day-container {
		width: 60px;
		padding: 8px 0px;
	}
		.create-plan-workout-list-day-container p {
		    font-size: 13.5px;
		    color: #9DA0AD;
		    margin: 0px;
		}
		.create-plan-workout-list-day-container h4 {
		    font-size: 20px;
		    color: #ffffff;
		    margin: 0px;
		}

	.create-plan-workout-list-cell-container {
		background: #1F1F21;
		border: solid;
		border-width: 1px;
		border-color: #2B2B30;
		border-radius: 5px;
		margin-bottom: 12px;
		width: calc(100% - 60px);
	}

	.create-plan-workout-list-configure-cell-content {
		padding: 20px;
		width: 100%;	
		display: flex;
	}

	.create-plan-workout-list-configure-cell-content .create-workout-exercise-list-cell-remove-container {
		padding-left: 24px;
	}
	.create-plan-workout-list-configure-cell-content .create-workout-exercise-list-cell-remove-container:hover {
		cursor: pointer;
	}

	.create-plan-workout-list-configure-cell-header-buttons-container {
		width: 50px;
		margin-left: auto;
		float: right;
		display: flex;
	}
			.create-plan-workout-list-configure-cell-content {
				display: flex;
				width: 100%;

			}
				.create-plan-workout-list-cell-configure-cell-image {
					background-color: rgba(0,0,0,0.3);
					width: 40px;
					height: 40px;
					max-width: 40px;
					max-height: 40px;
					border-radius: 5px;
					overflow: hidden;
					margin-right: 16px;
					padding: 0px;
				}
					.create-plan-workout-list-configure-cell-image {
						height: 100% !important;
						width: 400px !important;
						border-radius: 4px !important;
					}
				.create-plan-workout-list-configure-cell-col-container {
					display: flex;
					width: 100%;
				}
					.create-plan-workout-list-configure-cell-col-container {
						display: flex;
						width: calc(100% - 50px);
					}
					.create-plan-workout-list-configure-cell-info-card-col {
						display: block;
						padding-right: 16px;
						width: 20%;
						overflow-x: hidden;
					}
				.create-plan-workout-list-configure-cell-info-card-info-container {
					position: relative !important;
				}
				.create-plan-workout-list-configure-cell-info-card-info-container-title {
					width: 40%;
				}
					.create-plan-workout-list-configure-cell-info-card-info-container h4 {
						font-weight: 500;
						width: 100%;
						overflow: hidden;
						font-size: 13.5px;
						color: #ffffff;
						text-align: left;
						margin: 0px;
					}

					.create-plan-workout-list-configure-cell-info-card-info-container p {
						font-size: 12px;
						font-size: 10px;
						color: #9DA0AD;
						height: 16px;
						width: 100%;
						overflow: hidden;
						margin: 0px;
					}
						.create-plan-workout-list-configure-cell-info-card-info-container-no-workouts {

						}
						.create-plan-workout-list-configure-cell-info-card-info-container-no-workouts h4 {
							color: #6C6C6C !important;
						}
						.create-plan-workout-list-configure-cell-info-card-info-container-no-workouts p {
							color: #6C6C6C !important;
						}
							.create-plan-workout-list-configure-cell-info-card-info-container-no-workouts-alert {
								position: absolute;
								left: 55px;
								top: 6px;
								height: 12px;
								width: 12px;
								border-radius: 6px;
								background: rgba(255,23,23,0.3);
								padding-left: 4.5px;
								padding-top: 1px;
							}
								.create-plan-workout-list-configure-cell-info-container-no-workouts-alert p {
									color: #F02E4A !important;
									font-weight: 700;
									font-size: 8px;
								}

						.create-plan-workout-list-configure-cell-info-card-info-trained-with-me-container {
							display: flex;
						}
							.create-plan-workout-list-configure-cell-info-card-info-trained-with-me-icon {
								width: 10px;
								height: 10px;
								max-height: 10px;
								min-width: 10px;
								background: #4959C1;
								border: solid;
								border-color: #2B3158;
								border-width: 3px;
								border-radius: 5px;
								margin-top: 1px;
								margin-right: 6px;
							}

						.create-plan-workout-list-cell-button-container {
							padding: 7px 12px;
							margin-top: 3px;
							padding-left: 8px;
							background: #1F1F21;
							border: solid;
							border-radius: 5px;
							border-width: 0.5px;
							border-color: #2B2B30;
							display: flex;
							height: 28px;
							box-shadow: 0px 2px 12px 2px rgba(0, 0, 0, 0.08);
							-webkit-transition: all 0.15s;
							-moz-transition: all 0.15s;
							transition: all 0.15s;
						}
							.create-plan-workout-list-cell-button-container:hover {
								cursor: pointer;
								background: #1F1F21;
								-webkit-transition: all 0.15s;
								-moz-transition: all 0.15s;
								transition: all 0.15s;
							}
						.create-plan-workout-list-cell-edit-container {
							margin-right: 8px;

						}
							.create-plan-workout-list-cell-button-container p {
								font-size: 10px;
								margin: 0px;
								color: #ffffff;
								margin-left: 4px;
							}

							.create-plan-workoute-list-cell-button-icon {
								font-size: 10px !important;
							}
								.create-plan-workoute-list-cell-edit-icon {
									color: #ffffff;
									margin-top: 1px;
									margin-right: 1px;
								}
								.create-plan-workoute-list-cell-remove-icon {
									color: #F02E4A;
									font-size: 12px !important;
									margin-top: 1px;
								}

	.create-plan-workout-list-configure-cell-muscle-string-container {
		margin: auto;
		margin-top: 20px;
		padding: 4px 12px;
		background-color: #242C45;
		border-radius: 4px;
		color: #4959C1;
		font-size: 12px;
		text-align: center;
		width: 140px;
		max-width: 100%;
		max-height: 24px;
		overflow: hidden;
	}

	.create-plan-workout-list-configure-cell-rest {
		padding: 20px 20px;
		display: flex;
	}
		.create-plan-workout-list-configure-cell-rest-title-container {
			width: 20%;
		}
			.create-plan-workout-list-configure-cell-rest-title-container p {
			    font-size: 13.5px;
			    color: #9DA0AD;
			    margin: 0px;
			}
		.create-plan-workout-list-configure-cell-rest-button-container {
			display: flex;
			cursor: pointer;
			margin-left: auto;
		    float: right;
		}

			.create-plan-workout-list-configure-cell-rest-button-container p {
			    font-size: 13.5px;
			    color: #5B62FF;
			    margin: 0px;
			    padding-right: 6px;
			}
			.create-plan-workout-list-configure-cell-rest-button-container:hover p {
				color: #7278F2;
			}
				.create-plan-workout-list-configure-cell-rest-button-icon {
					font-size: 15px !important;
					color: #5B62FF;
					margin-top: 1px;
				}
				.create-plan-workout-list-configure-cell-rest-button-container:hover .create-plan-workout-list-configure-cell-rest-button-icon {
					color: #7278F2;
				
				}





