/* .settings-page-header- */

.settings-page-header {
	width: 100%;
	padding: 42px 0px;
	padding-bottom: 18px;
	border-bottom: solid;
	border-width: 1px;
	border-color: #2B2B30;
}
	.settings-page-header h4 {
		font-size: 18px;
		color: #ffffff;
		margin: 0px;
	}

	.settings-page-header-subtitle-container {
		padding: 8px 0px;
	}
		.settings-page-header-subtitle-container p {
			font-size: 13.5px;
			color: #6C6C6C;
			margin: 0px;
		}