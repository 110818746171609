/* .client-plan-cell- */

.client-plan-cell {
	width: 100%;
	border-bottom: solid;
	border-width: 1px;
	border-color: #2B2B30;
	padding: 12px 0px;
	height: 60px;
}

	.client-plan-cell-content {
		display: flex;
		position: relative;
	}

	.client-plan-cell-col {
		width: 25%;
	}
		.client-plan-cell-col h4 {
			font-size: 13.5px;
			color: #ffffff;
			margin: 0px;
			margin-top: 4px;
		}
		.client-plan-cell-col p {
			font-size: 10px;
			color: #6C6C6C;
			margin: 0px;
		}
	.client-plan-cell-col-head {
		width: 50%;
	}

	.client-plan-cell-content-loading {
		width: 100%;
		padding: 12px;
	}
		.client-plan-cell-content-loading p {
			font-size: 10px;
			color: #6C6C6C;
			margin: 0px;
			width: 100%;
			text-align: center;
		}
	.client-plan-cell-current-plan-circle {
		position: absolute;
		top: 21px;
		left: -16px;
		width: 8px;
		height: 8px;
		background: #4959C1;
		border-radius: 4px;
	}