.new-workout-confirm {
	padding: 12px 48px;
}
	.new-workout-confirm-button-container {
		margin-bottom: 0px;
		margin-top: calc(100vh - 700px);
		float: right !important;
		margin-left: auto !important;
	}
	.new-workout-confirm-button {
		
	}
		.new-workout-confirm-button-container .standard-button-container p {
			font-size: 13.5px !important;
			color: white;
			font-weight: 600;
		}