.workout-card {
	display: block;
	margin-right: 12px;
	min-width: 400px;
	height: 200px;
	background-color: rgba(0,0,0,0.3);
	border-radius: 5px;
	box-shadow: 0 0px 40px 0 rgba(0, 0, 0, 0.12);
	overflow: hidden;
}

.workout-card-image-container {
	width: 120px;
	height: 120px;
	background-color: rgba(0,0,0,0.3);
	overflow: hidden;
}

.workout-card-image {
	height: 100%;
}

.workout-card-info-container {
	padding: 12px 24px;
}

.workout-card-upper-container {
	display: flex;
	width: 100%;
}

.workout-card-lower-container {
	width: 100%;
}




