/* .exercise-builder- */

.exercise-builder {
	height: 100%;
	
}
	.exercise-builder-body {
		height: 100%;
		overflow-y: scroll;
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
	    scrollbar-width: none;  /* Firefox */
	}
	.exercise-builder-body::-webkit-scrollbar { 
	    display: none;  /* Safari and Chrome */
	}

	.exercise-builder-floaty-button {
		position: absolute;
		z-index: 900;
		top: 15px;
		right: 42px;
	}
	.exercise-video-upload-progress-container {
		width: 100%;
		height: 200px;
		align-items: center;
		justify-content: center;
	}