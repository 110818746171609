.assign-modal-background {
	padding-top: 7vh;/*calc(50vh - 280px);*/
}
	
	.assign-modal {
		margin: auto;
		margin-top: 0px;
		background: var(--ef-background);
		border-radius: var(--ef-radius-m);
		border: solid;
		border-width: 1px;
		border-color: var(--ef-border);
		/*overflow: hidden;*/
		box-shadow: 0 0px 100px 12px rgba(0, 0, 0, 0.3);
		position: relative;
		padding-bottom: 4px;
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}
	.assign-modal-max {
		height: 86vh;
		margin: auto;
		margin-top: 0px;
		min-width: 900px;
		width: 85%;
		max-width: 1200px;
		padding-bottom: 4px;
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}
	.assign-modal-min {
		height: 86vh;
		margin: auto;
		margin-top: 0px;
		min-width: 520px;
		width: 30%;
		max-width: 1200px;
		padding-bottom: 4px;
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}
		.assign-modal-upper {
			width: 100%;
			padding: 22px 24px;
			border-bottom: solid;
			border-width: 1px;
			border-color: #2B2B30;
			display: flex;
		}
			.assign-modal-upper h4 {
			    font-size: 18px;
			    color: #ffffff;
			    margin: 0px;
			    padding-top: 4px;
			}
			.assign-modal-upper-close-button-container {
				width: 28px;
				height: 28px;
				
				border-radius: 5px;
				padding: 5.5px 6px;
				margin-left: auto;
				float: right;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
			.assign-modal-upper-close-button-container:hover {
				background: rgba(0,0,0,0.2);
				cursor: pointer;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
				.assign-modal-upper-close-button-icon {
					font-size: 16px !important;
					color: #6C6C6C;
				}
		.assign-modal-body {
			width: 100%;
			height: calc(100% - 140px);
			max-height: calc(100% - 140px);
			display: flex;
			
			/*padding: 16px 24px;*/
			/*overflow-y: scroll;*/
		}

			.assign-modal-body-left {
				height: 100%;
				padding: 12px 0px;
				overflow-y: scroll;
				box-shadow: 12px 0px 12px -4px rgba(0, 0, 0, 0.1);
				position: relative;
				z-index: 6;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
			.assign-modal-body-left-min {
				width: 100%;
			}
			.assign-modal-body-left-max {
				width: 35%;
			}
				.assign-modal-body-curtain {
					position: absolute;
					z-index: 50;
					width: 100%;
					-webkit-backdrop-filter: blur(2px);
					backdrop-filter: blur(2px);
					background: #161719CC;
					height: 500px;
				}
			.assign-modal-body-right {
				width: 65%;
				border-left: solid;
				border-width: 1px;
				border-color: #2B2B30;
				overflow-y: hidden;
				position: relative;
			}
				.assign-modal-body-right-loading-container {
					width: 100%;
					height: 100%;
					position: absolute;
					z-index: 500;
					-webkit-backdrop-filter: blur(2px);
					backdrop-filter: blur(2px);
					background: #161719CC;

				}
					.assign-modal-body-right-loading-container p {
					    font-size: 13.5px;
					    color: #9DA0AD;
					    margin: 0px;
					    width: 100%;
					    text-align: center;
					    margin-top: 8px;
					}
				.assign-modal-body-right-inner {
					width: 100%;
					height: 100%;
					overflow-y: scroll;
				}
					.assign-modal-body-row-edit-plan-body-program {
						width: 100%;
						height: calc(100% - 80px);
						overflow-y: scroll;
						margin-top: 12px;
						border-top: solid;
						border-width: 1px;
						border-color: #2B2B30;
					}
				.assign-modal-body-add-button-container {
					padding: 12px 32px;
					float: left;
				}
					.assign-modal-body-add-button {
						float: left;
						padding: 8px 12px;
						border-radius: 5px;
						-webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}
					.assign-modal-body-add-button:hover {
						background: rgba(0,0,0,0.2);
						cursor: pointer;
						-webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}
						.assign-modal-body-add-button p {
							font-size: 13.5px;
							font-weight: 600;
							color: #5B62FF;
							margin: 0px;
						}

				.assign-modal-body-add-to-restday-container {
					padding: 40px 40px;
				}
					.assign-modal-body-add-to-restday-header-container {
						margin-top: 0px;
					}
						.assign-modal-body-add-to-restday-header-container h4 {
						    font-size: 22px;
						    color: #D9DCE5;
						    margin: 0px;
						    margin-bottom: 6px;
						}
						.assign-modal-body-add-to-restday-header-container p {
						    font-size: 13.5px;
						    color: #707484;
						    margin: 0px;
						}
					.assign-modal-body-restday-add-button {
						margin-top: 20px;
					    padding: 10px 16px;
					    background: #5437DC;
					    display: flex;
					    border-radius: 5px;
					    float: left;
					    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.12);
					    -webkit-transition: all 0.15s;
					    -moz-transition: all 0.15s;
					    transition: all 0.15s;
								}
					.assign-modal-body-restday-add-button:hover {
					    background: #7963E0;
					    cursor: pointer;
					    -webkit-transition: all 0.15s;
					    -moz-transition: all 0.15s;
					    transition: all 0.15s;
					}
						.assign-modal-body-restday-add-button p {
						    font-size: 13.5px;
						    color: #ffffff;
						    margin: 0px;
						}
	.assign-modal-body-exercise-config-container {
		width: 100%;
		height: 100%;
		overflow-y: scroll;
		padding: 0px 32px;
		-webkit-tap-highlight-color: transparent;
	    -webkit-touch-callout: none;
	    -webkit-user-select: none;
	    -khtml-user-select: none;
	    -moz-user-select: none;
	    -ms-user-select: none;
	    user-select: none;
	}
	.assign-modal-body-exercise-config-container:focus {
	    outline: none !important;
	}
		.assign-modal-body-exercise-builder-container {
			height: 100%;
			padding: 0px 42px;
			overflow-y: hidden;
		}
			.assign-modal-body-row {
				width: 100%;
				/*display: flex;*/
				padding: 12px 32px;
				position: relative;
			}
			.assign-modal-body-row-full {
				display: flex;
			}
			.assign-modal-body-row-header {
				padding: 12px 32px;
			}
				.assign-modal-body-row-header h4 {
				    font-size: 13.5px;
				    color: #9DA0AD;
				    margin: 0px;
				    font-weight: 400;
				}
				.assign-modal-body-row-label-container {
					width: 100%;
					padding-bottom: 8px;
				}
					.assign-modal-body-row-label-container h4 {
					    font-size: 13.5px;
					    color: #ffffff;
					    margin: 0px;
					    width: 100%;
					    font-weight: 600;
					}
				.assign-modal-body-row-label-container-extra-margin {
					margin-top: 32px;
				}
				.assign-modal-body-row-input-container {
					width: 100%;
					
					position: relative;
				}
					.assign-modal-body-row-input-container .date-selector-input-container {
						background: #1F1F21;
					}
					.assign-modal-body-client-name-input-container {
						height: 38px;
						/*background-color: rgba(0,0,1,0.1);*/
						background-color: #1F1F21;
						padding: 0px 8px;
						width: auto;
						border-radius: 5px;
						border: solid;
						border-color: #2B2B30;
						border-width: 1px !important;
						font-size: 13.5px;
						color: #ffffff;
						display: flex;
						box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
						-webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}
					.assign-modal-body-client-name-input-container:hover {
						background-color: rgba(0,0,1,0.1);
						box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
						-webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}
					.assign-modal-body-client-name-input-container-focus {
						border-color: #5437DC;
						background-color: rgba(0,0,1,0.1);
						box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
						-webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}
						.assign-modal-body-client-name-input-arrow-icon-container {
							width: 22px;
							height: 22px;
							
							border-radius: 5px;
							padding: 4px 5px;
							margin-top: 6px;
							-webkit-transition: all 0.15s;
							-moz-transition: all 0.15s;
							transition: all 0.15s;
						}
						.assign-modal-body-client-name-input-arrow-icon-container:hover {
							background: rgba(0,0,0,0.2);
							cursor: pointer;
							-webkit-transition: all 0.15s;
							-moz-transition: all 0.15s;
							transition: all 0.15s;
						}	
							.assign-modal-body-client-name-input-arrow-icon {
								font-size: 13.5px !important;
								color: #6C6C6C;
							}
							.assign-modal-body-client-name-input-arrow-icon-container:hover .assign-modal-body-client-name-input-arrow-icon {
								color: #ffffff;
							}
						.assign-modal-body-row-input-container-date-duration-container {
							position: absolute;
							right: 12px;
							top: 9px;
						}
							.assign-modal-body-row-input-container-date-duration-container p {
							    font-size: 13.5px;
							    color: #9DA0AD;
							    margin: 0px;
							}
					.assign-modal-body-row-input-phase-duration-container {
						height: 38px;
						margin-top: -14px;
						background-color: #1F1F21;
						padding: 0px 8px;
						width: 100%;
						border-radius: 5px;
						border: solid;
						border-color: #2B2B30;
						border-width: 1px !important;
						font-size: 13.5px;
						color: #ffffff;
						display: flex;
						position: relative;
						box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
						-webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}
					.assign-modal-body-row-input-phase-duration-container:hover {
						background: #28282D;
						cursor: pointer !important;
					}
					.assign-modal-body-row-input-phase-duration-container-focus {
						border-color: #5437DC;
						background-color: rgba(0,0,1,0.1);
						box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
						-webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}
						.assign-modal-body-row-input-phase-duration-unit-container {
							padding-top: 9px;
							padding-right: 4px;
						}
							.assign-modal-body-row-input-phase-duration-unit-container p {
							    font-size: 13.5px;
							    color: #9DA0AD;
							    margin: 0px;
							}
						.assign-modal-body-row-input-phase-duration-button {
							display: flex;
							padding-top: 9px;
						}
						.assign-modal-body-row-input-phase-duration-button:hover {
							cursor: pointer;
						}
						.assign-modal-body-row-input-phase-duration-button p {
						    font-size: 13.5px;
						    color: #5B62FF;
						    font-weight: 600;
						    margin: 0px;
						    cursor: pointer !important;
						}
						.assign-modal-body-row-input-phase-duration-button:hover p {
							color: #7963E0;
						}

							.assign-modal-body-row-input-phase-duration-button-icon {
								font-size: 16px !important;
								color: #5B62FF;
								margin-left: 4px;
							}

				.assign-modal-body-client-name-menu-container {
					position: absolute;
					top: 80px;
					right: 32px;
					width: calc(100% - 64px);
					min-height: 100px;
					max-height: 240px;
					background: #1F1F21;
					border-radius: 5px;
					box-shadow: 0 4px 20px 12px rgba(0, 0, 0, 0.1);
					overflow-y: scroll;
					z-index: 100;
				}
					.assign-modal-body-client-name-input-container-selected-client {
						padding-top: 6px;
						background: #1F1F21;
					}
					.assign-modal-body-client-name-input-container-selected-client:hover {
						background: #28282D !important;
					}
						.assign-modal-body-client-name-input-container-selected-client-text-container {
							padding-top: 4px;
						}
							.assign-modal-body-client-name-input-container-selected-client-text-container h4 {
							    font-size: 13.5px;
							    color: #ffffff;
							    margin: 0px;
							}

						.assign-modal-body-client-name-input-container-selected-client-remove-container {
							width: 22px;
							height: 22px;
							margin-top: 2px;
							border-radius: 5px;
							padding: 3px 4px;
							margin-left: auto;
							float: right;
							-webkit-transition: all 0.15s;
							-moz-transition: all 0.15s;
							transition: all 0.15s;
						}
						.assign-modal-body-client-name-input-container-selected-client-remove-container:hover {
							background: rgba(0,0,0,0.2);
							cursor: pointer;
							-webkit-transition: all 0.15s;
							-moz-transition: all 0.15s;
							transition: all 0.15s;
						}
							.assign-modal-body-client-name-input-container-selected-client-remove-icon {
								font-size: 13.5px !important;
								color: #6C6C6C;
							}
							.assign-modal-body-client-name-input-container-selected-client-remove-container:hover .assign-modal-body-client-name-input-container-selected-client-remove-icon {
								color: #ffffff;
							}
			.assign-modal-body-row-input-row-container {
				display: flex;
				width: 100%;
				padding-top: 16px;
			}
				.assign-modal-body-row-input-container-checkbox-container {

				}
				.assign-modal-body-row-input-container-checkbox-text-container {
					padding-left: 8px;
				}
					.assign-modal-body-row-input-container-checkbox-text-container h4 {
					    font-size: 13.5px;
					    color: #ffffff;
					    margin: 0px;
					    margin-bottom: 6px;
					}
					.assign-modal-body-row-input-container-checkbox-text-container p {
					    font-size: 13px;
					    color: #9DA0AD;
					    margin: 0px;
					}
					.assign-modal-body-row-input-container-checkbox-text-container span {
					    font-size: 12px;
					    color: #7278F2;
					    font-weight: 500;
					    margin: 0px;
					}
				.assign-modal-body-row-input-row-container-edit {
					margin-top: 32px;
					border-top: solid;
					border-width: 1px;
					border-color: #2B2B30;
					padding-top: 24px;
					display: flex;
				}
				.assign-modal-body-row-input-row-container-edit:hover {
					cursor: pointer;
				}
				
					.assign-modal-body-row-input-row-container-edit h5 {
					    font-size: 18px;
					    color: #5B62FF;
					    margin: 0px;
					    -webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}
					.assign-modal-body-row-input-row-container-edit:hover h5 {
						color: #7278F2;
						-webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}
					.assign-modal-body-row-input-row-edit-button-container {
						width: 22px;
						height: 22px;
						
						border-radius: 5px;
						margin-left: 8px;
						padding: 4px 4px;
						-webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}
					.assign-modal-body-row-input-row-container-edit:hover .assign-modal-body-row-input-row-edit-button-container {
						background: rgba(0,0,0,0.2);
						-webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}
						.assign-modal-body-row-input-row-edit-button-icon {
							color: #5B62FF;
							font-size: 13.5px !important;
						}
						.assign-modal-body-row-input-row-container-edit:hover .assign-modal-body-row-input-row-edit-button-icon {
							color: #7278F2;
						}
		.assign-modal-body-loading {
			width: 100%;
			height: calc(100% - 124px);
			max-height: calc(100% - 124px);
			padding: 24px;
			/*padding-top: 15vh;*/
		}
			.assign-modal-body-loading-inner {
			}
				.assign-modal-body-loading-inner p {
				    font-size: 13.5px;
				    color: #9DA0AD;
				    margin: 0px;
				    width: 100%;
				    text-align: center;
				    margin-top: 8px;
				}
		.assign-modal-body-complete {
			width: 100%;
			height: calc(100% - 124px);
			max-height: calc(100% - 124px);
			padding: 24px;
			padding-top: 22vh;
		}
			.assign-modal-body-complete h4 {
			    font-size: 18px;
			    color: #ffffff;
			    margin: 0px;
			    width: 100%;
			    text-align: center;
			}
			.assign-modal-body-complete p {
			    font-size: 13.5px;
			    font-weight: 400;
			    line-height: 20px;
			    color: #9DA0AD;
			    margin: 0px auto;
			    margin-top: 8px;
			    width: 50%;
			    min-width: 440px;
			    text-align: center;
			}
				.assign-modal-body-complete-button {
					margin-top: 24px;
					margin-left: calc(50% - 36px);
					float: none;
				}

	.assign-modal-body-row-edit-plan-header {
		display: flex;
		padding-top: 12px;
		width: 100%;
	}
		.assign-modal-body-row-edit-plan-header h2 {
		    font-size: 13.5px;
		    color: #ffffff;
		    margin: 0px;
		}
	.assign-modal-body-row-edit-plan-header-text-container {

	}
	.assign-modal-body-row-edit-plan-header-bottom {

	}
		.assign-modal-body-row-edit-plan-header-bottom p {
		    font-size: 12px;
		    color: #9DA0AD;
		    margin: 0px;
		}
		.assign-modal-body-row-edit-plan-header-arrow-icon-container {
			width: 22px;
			height: 22px;
			
			border-radius: 5px;
			padding: 4px 5px;
			margin-left: 12px;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
		.assign-modal-body-row-edit-plan-header-arrow-icon-container:hover {
			background: rgba(0,0,0,0.2);
			cursor: pointer;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}	
			.assign-modal-body-row-edit-plan-header-arrow-icon {
				font-size: 13.5px !important;
				color: #6C6C6C;
			}
			.assign-modal-body-row-edit-plan-header-arrow-icon-container:hover .assign-modal-body-row-edit-plan-header-arrow-icon {
				color: #ffffff;
			}
		.assign-modal-body-row-edit-plan-header-optional-container {
			padding: 4px 8px;
			border-radius: 5px;
			background: #1F1F21;
			margin-left: 12px;
			margin-top: -1px;
		}
			.assign-modal-body-row-edit-plan-header-optional-container p {
			    font-size: 10px;
			    color: #6C6C6C;
			    margin: 0px;
			}

		.assign-modal-body-row-edit-plan-body {
			width: 100%;
			min-height: 240px;
			
		}
			.assign-modal-body-row-edit-plan-body-workout-contianer {
				width: 100%;
				padding: 12px;
				background: red;
				margin-bottom: 2px;
			}
				.assign-modal-body-row-edit-plan-body-workout-contianer h4 {
				    font-size: 13.5px;
				    color: #ffffff;
				    margin: 0px;
				}
		.assign-modal-body-row-edit-plan-body-header-row {
			background: #1F1F21;
			display: flex;
			padding: 12px 32px;
			position: relative;
			margin-top: 16px;
		}
			.assign-modal-body-row-edit-plan-body-header-row h4 {
			    font-size: 13.5px;
			    color: #ffffff;
			    margin: 0px;
			}
			.assign-modal-body-row-edit-plan-body-header-row p {
			    font-size: 13.5px;
			    color: #5B62FF;
			    margin: 0px;
			    margin-left: 8px;
			}
			.assign-modal-body-row-edit-plan-body-header-row-add-button {
				width: 22px;
				height: 22px;
				border-radius: 5px;
				position: absolute;
				right: 32px;
				top: 9px;
				padding: 3px 4px;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
			.assign-modal-body-row-edit-plan-body-header-row-add-button:hover {
				background: rgba(0,0,0,0.2);
				cursor: pointer;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}

				.assign-modal-body-row-edit-plan-body-header-row-add-icon {
					font-size: 15px !important;
					color: #6C6C6C;
				}
				.assign-modal-body-row-edit-plan-body-header-row-add-button:hover .assign-modal-body-row-edit-plan-body-header-row-add-icon {
					color: #ffffff;
				}

		.assign-modal-body-row-full-workout {
			padding-left: 42px;
			display: flex;
		}
			.assign-modal-body-row-edit-plan-header-back-button {
				width: 22px;
				height: 22px;
				margin-top: 2px;
				border-radius: 5px;
				padding: 2px 8px;
				position: absolute;
				left: 12px;
				top: 26px;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
			.assign-modal-body-row-edit-plan-header-back-button:hover {
				background: rgba(0,0,0,0.2);
				cursor: pointer;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
				.assign-modal-body-row-edit-plan-header-back-button-icon {
					font-size: 11px !important;
					color: #6C6C6C;
				}
				.assign-modal-body-row-edit-plan-header-back-button:hover .assign-modal-body-row-edit-plan-header-back-button-icon {
					color: #ffffff;
				}
			.assign-modal-body-row-edit-plan-header-button-container {
				margin-left: auto;
				float: right;
				display: flex;
				padding-top: 10px;
			}
				.assign-modal-body-row-edit-plan-header-button {
					padding: 8px 12px;
					border-radius: 5px;
					height: 32px;
					margin-left: 12px;
					background: rgba(0,0,0,0.2);
					box-shadow: 0 0px 12px 4px rgba(0, 0, 0, 0.1);
				}
					.assign-modal-body-row-edit-plan-header-button p {
						font-size: 12px;
						color: #ffffff;
						margin: 0px;
					}
				.assign-modal-body-row-edit-plan-header-button-dark {
					background: #1F1F21;
					border-width: 0.5px !important;
					border: solid;
					border-color: #2B2B30;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
				.assign-modal-body-row-edit-plan-header-button-dark:hover {
					background: #28282D;
					cursor: pointer;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
					.assign-modal-body-row-edit-plan-header-button-dark p {
						color: #9DA0AD;
					}
				.assign-modal-body-row-edit-plan-header-button-blue {
					background: #5437DC;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
				.assign-modal-body-row-edit-plan-header-button-blue:hover {
					background: #7963E0;
					cursor: pointer;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
				.assign-modal-body-row-edit-plan-header-button-blue-unselected {
					background: #5437DC;
					opacity: 0.2;
				}
				.assign-modal-body-row-edit-plan-header-button-blue-unselected:hover {
					background: #7963E0; 
					opacity: 0.2;
					cursor: default;
				}


		.assign-modal-lower {
			width: 100%;
			padding: 16px 24px;
			border-top: solid;
			border-width: 1px;
			border-color: #2B2B30;
			/*display: flex;*/
		}
			.assign-modal-cta-button-unselected {
				opacity: 0.36;
			}
			.assign-modal-cta-button-unselected:hover {
				background: #4959C1;
				cursor: default;
			}


	.assign-modal-body-folder-row-cycle-container {
		width: 100%;
	}
		.assign-modal-body-folder-row-cycle-header {
			width: 100%;
			background: #1F1F21;
			display: flex;
			padding: 12px 32px;
			position: relative;
			margin-top: 16px;
			height: 42px;
		}
			.assign-modal-body-folder-row-cycle-header-cycle-number-container {

			}
				.assign-modal-body-folder-row-cycle-header-cycle-number-container h4 {
				    font-size: 13.5px;
				    color: #ffffff;
				    margin: 0px;
				}
			.assign-modal-body-folder-row-cycle-header-cycle-circle {
				position: absolute;
				top: 11px;
				left: 86px;
				width: 14px;
				height: 14px;
				border-radius: 7px;
				background: #FFD15B;
				margin: 2px 12px;
			}
			.assign-modal-body-folder-row-cycle-header-cycle-date-container {
				position: absolute;
				top: 12px;
				left: 112px;
				display: flex;
				padding: 0px 12px;
			}
				.assign-modal-body-folder-row-cycle-header-cycle-date-container h4 {
				    font-size: 13.5px;
				    color: #9DA0AD;
				    margin: 0px;
				}
				.assign-modal-body-folder-row-cycle-header-cycle-date-container p {
				    font-size: 13.5px;
				    color: #5B62FF;
				    margin: 0px;
				    margin-left: 62px;
				}
				.assign-modal-body-folder-row-cycle-header-cycle-date-icon-container {
					padding: 0px 8px;
				}
					.assign-modal-body-folder-row-cycle-header-cycle-date-icon {
						font-size: 14px !important;
						color: #6C6C6C;
					}
			.assign-modal-body-folder-row-cycle-header-cycle-dropdown-container {
				width: 22px;
				height: 22px;
				border-radius: 5px;
				margin-left: auto;
				float: right;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
			.assign-modal-body-folder-row-cycle-header-cycle-dropdown-container:hover {
				cursor: pointer;
				background: rgba(0, 0, 0, 0.2);
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
				.assign-modal-body-folder-row-cycle-header-cycle-dropdown-content {
					width: 100%;
					height: 100%;
					padding: 3px 4px;
				}
					.assign-modal-body-folder-row-cycle-header-cycle-dropdown-icon {
						font-size: 16px !important;
						color: #6C6C6C;
						-webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}
					.assign-modal-body-folder-row-cycle-header-cycle-dropdown-container:hover .assign-modal-body-folder-row-cycle-header-cycle-dropdown-icon {
						color: #ffffff;
						-webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}
		.assign-modal-body-folder-row-cycle-body {
			width: 100%;
		}
			.assign-modal-body-folder-row-phase-container {
				width: 100%;
				padding: 0px;
				position: relative;
			}
				.assign-modal-body-folder-row-phase-content-upper {
					width: 100%;
					display: flex;
					padding: 12px 32px;
					border-bottom: solid;
					border-width: 1px;
					border-color: #2B2B30;
				}
					.assign-modal-body-folder-row-phase-content-upper-phase-number-container {
						width: 100px;
					}
						.assign-modal-body-folder-row-phase-content-upper-phase-number-container h4 {
						    font-size: 13.5px;
						    color: #ffffff;
						    margin: 0px;
						}
					.assign-modal-body-folder-row-phase-content-upper-phase-name-container {
						width: 25%;
					}
						.assign-modal-body-folder-row-phase-content-upper-phase-name-container h4 {
						    font-size: 13.5px;
						    color: #ffffff;
						    margin: 0px;
						}
					.assign-modal-body-folder-row-phase-content-upper-phase-info-container {
						padding-left: 24px;
						width: 20%;
					}
						.assign-modal-body-folder-row-phase-content-upper-phase-info-container h4 {
						    font-size: 13.5px;
						    color: #9DA0AD;
						    margin: 0px;
						}
				.assign-modal-body-folder-row-phase-circle {
					position: absolute;
					top: 34px;
					left: 98px;
					width: 14px;
					height: 14px;
					border-radius: 7px;
					border-color: #5437DC !important;
					border-width: 2px !important;
					border: solid;
					background: #28282D;
					z-index: 100;
				}
				.assign-modal-body-folder-row-phase-circle-unselected {
					border-color: #6C6C6C !important;
				}
					.assign-modal-body-folder-row-phase-line-up {
						border-left: dotted;
						border-width: 2px !important;
						border-color: #6C6C6C;
						top: -58px;
						left: 104px;
						width: 1px;
						height: 92px;
						position: absolute;
					}
					.assign-modal-body-folder-row-phase-line-up-short {
						top: -12px;
						height: 50px;
					}
			.assign-modal-body-folder-row-phase-content-lower {
				width: calc(100% - 132px);
				display: flex;
				position: relative;
				height: 64px;
				margin-left: 132px;
				padding-top: 12px;
				border-bottom: solid;
				border-width: 1px;
				border-color: #2B2B30;
			}
				.assign-modal-body-folder-row-phase-content-lower-curtain {
					position: absolute;
					top: 0px;
					left: 0px;
					width: 100%;
					height: 100%;
					background: #161719CC;
					-webkit-backdrop-filter: blur(2px);
					backdrop-filter: blur(2px);
					z-index: 300;
				}
					.assign-modal-body-folder-row-phase-content-lower-curtain p {
					    font-size: 13.5px;
					    color: #9DA0AD;
					    margin: 0px;
					    width: 100%;
					    text-align: center;
					    padding-top: 22px;
					}
				.assign-modal-body-folder-row-phase-content-lower-date-range-container {
					width: 240px;
				}
				.assign-modal-body-folder-row-phase-content-lower-duration-container {
					margin-left: 12px;
					padding-top: 9px;
				}
					.assign-modal-body-folder-row-phase-content-lower-duration-container p {
					    font-size: 13.5px;
					    color: #5B62FF;
					    margin: 0px;
					    font-weight: 400;
					}
				.assign-modal-body-folder-row-phase-content-lower-buttons-container {
					margin-left: auto;
					float: right;
					padding-right: 24px;
				}
					.assign-modal-body-folder-row-phase-content-lower-button {
						padding: 6px 12px;
						border-radius: 5px;
						border: solid;
						border-width: 1px;
						border-color: #2B2B30;
						box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.08);
						display: flex;
						-webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}
					.assign-modal-body-folder-row-phase-content-lower-button:hover {
						cursor: pointer;
						background: #1F1F21;
						-webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}
						.assign-modal-body-folder-row-phase-content-lower-button p {
						    font-size: 12px;
						    color: #9DA0AD;
						    margin: 0px;
						}
						.assign-modal-body-folder-row-phase-content-lower-button-icon {
							font-size: 14px !important;
							color: #ffffff;
							margin-right: 6px;
						}
		.assign-modal-body-folders {
			width: 100%;
			height: calc(100% - 140px);
			max-height: calc(100% - 140px);
		}







