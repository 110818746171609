

.settings-page-container {
	width: 100%;
	max-width: 600px;
	margin: 0px auto;
}
	.settings-page-section-container {
		padding: 24px 0px;
		padding-top: 42px;
	}
		.settings-page-section-container h2 {
			font-size: 18px;
			color: #ffffff;
			margin: 0px;
		}

	.settings-page-card {
		width: 100%;
		background: #1F1F21;
		padding: 16px;
		border-radius: 5px;
		border: solid;
		border-width: 0.5px;
		border-color: #28282D;
		box-shadow: 0 2px 24px 4px rgba(0, 0, 0, 0.1);

	}
		.settings-page-card-header {
			padding: 8px 0px;
			padding-bottom: 24px;
			border-bottom: solid;
			border-width: 1px;
			border-color: #2B2B30;
			display: flex;
		}
			.settings-page-card-header h4 {
				font-size: 18px;
				color: #ffffff;
				margin: 0px;
			}