.messages-page-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.messages-content-container {
    display: flex;
    height: 100%;
    width: 100%;
}

.messages-preview-container {
    width: calc(36% + 25px);
}

.ClientChat {
    padding-left: 20px;
    box-sizing: border-box;
}

.messages-header {
    height: 80px;
}

.messages-header-text-container {
    padding: 24px 36px;
}
    .messages-header-text-container h1 {
        font-size: 36px;
        color: #ffffff;
        margin: 0px;
    }

.messages-container {
    width: 100%;
    padding: 20px 20px;
    height: calc(100vh - 132px);
    overflow-y: scroll;
}

.messages-header-bottom {
    display: flex;
    width: 100%;
    background: #1F1F21;
    padding: 16px 36px;
    padding-right: 12px;
    position: relative;
    z-index: 0;
}
    .messages-header-bottom h4 {
        font-size: 13.5px;
        color: #9DA0AD;
        margin: 0px;
    }
    .messages-header-bottom p {
        font-size: 13.5px;
        color: #5B62FF;
        font-weight: 500;
        margin: 0px;
        margin-left: 8px;
    }


    .messages-client-chat-container {
        width: calc(64% - 25px);

        padding: 24px 24px;
        padding-left: 4px;
        z-index: 1;
        position: relative;
    }
        .messages-client-chat-container-inner {
            width: 100%;
            height: 100%;
            box-shadow: 0px 6px 18px 6px rgba(0, 0, 0, 0.12);
            border-radius: var(--ef-radius-l);
            border: solid;
            border-width: 1px;
            border-color: var(--ef-border);
            overflow: hidden;
        }
