/* .discovered-node-set-cell- */

.discovered-node-set-cell-container {
	border: solid;
	border-width: 1px;
	border-color: rgba(0,0,0,0);
	border-radius: 4px;
	width: 100%;
	padding: 22px;
	display: flex;
	margin-bottom: 12px;
	background: #161825;
	box-shadow: 0px 2px 12px 4px rgba(0, 0, 0, 0.08);
	-webkit-transition: all 0.1s;
	-moz-transition: all 0.1s;
	transition: all 0.1s;
}

.discovered-node-set-cell-content-container {
	height: 100%;
	width: calc(100% - 40px);
}

.discovered-node-set-cell-button-container {
	height: 100%;
	width: 40px;
}

.discovered-node-set-cell-button {
	width: 36px;
	height: 36px; 
	border-radius: 18px;
	background: rgba(73,89,193,0.16);
	color: #4959C1;
	padding: 8px;
	margin-top: 16px;
}