.page-container {
	padding: 0px;
	position: relative;
	height: 100%;
}

.page-container-no-padding {
	padding: 0px;
	position: relative;
	height: calc(100% - 52px);
}

.page-container p {
	margin: 0px;
	opacity: 0.8;
}

.page-container h1 {
	margin-top: 6px;
	font-size: 36px;
}

.client-container p {
	margin: 0px;
	opacity: 0.8;
}

.client-container h1 {
	margin-top: 6px;
}

.page-full-height-width {
	width: 100%;
	height: 100%;
}

.page-title-header-container {
	padding: 24px 36px;
	width: 100%;
}
	.page-title-header-container h1 {
		font-size: 28px !important;
		color: #ffffff;
		margin: 0px;
	}

@media screen and (max-width: 800px) {
	.page-container {
		padding: 40px 20px;
	}
}

/* Mobile CSS */
@media screen and (max-width: 560px) {
	.page-container {
		padding: 40px 0px;
	}
}