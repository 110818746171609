.top-client-cell-container {
	padding: 12px 20px;
	width: 100%;
	margin-top: 0px; 
	position: relative;
	display: flex;
	border-bottom: solid;
	border-width: 1px;
	border-color: #2B2B30;
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
}
.top-client-cell-container:hover {
	background: rgba(35,40,54,0.3);
	cursor: pointer;
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
}
	.top-client-cell-name-container {
		width: 30%;
		padding-top: 5px;
	}

		.top-client-cell-name-container h4 {
			font-size: 13.5px;
			color: #ffffff;
			margin: 0px;
		}
		.top-client-cell-name-container p {
			font-size: 10px;
			color: #9DA0AD;
			margin: 0px;
		}
	.top-client-cell-col-container {
		width: 15%;
		overflow: hidden;
		padding-top: 3px;
	}
	.top-client-cell-col-container-pills {
		width: 40%;
	}
		.top-client-cell-col-container-pills-inner {
			float: right;
			display: flex;
			margin-left: auto;
		}

		.top-client-cell-col-container h4 {
			font-size: 13.5px;
			color: #ffffff;
			margin: 0px;
		}
		.top-client-cell-col-container p {
			font-size: 10px;
			color: #9DA0AD;
			margin: 0px;
		}
			.top-client-cell-pill-container {
				padding: 4px 12px;
				border-radius: 12px;
				border: solid;
				border-width: 1px;
				border-color: #2B2B30;
				float: right;
				margin-right: 0px;
				margin-right: 4px;
			}
	.top-client-cell-arrow-icon-container {
		width: 12px;
		height: 12px;
		position: absolute;
		top: 16px;
		right: 6px;
	}
		.top-client-cell-arrow-icon {
			font-size: 10px !important;
			color: #6C6C6C;
			opacity: 0.5;
		}


/*.top-client-cell-last-workout-container {
	background-color: rgba(100,100,255,0.1);
	padding: 4px 12px;
	color: rgba(100,100,255);
	font-size: 12px;
	border-radius: 4px;
	max-width: 80%;
	min-width: 100px;
	margin: auto;
	text-align: center;
}

.top-client-cell-last-workout-container-red {
	background-color: rgba(255,100,100,0.1);
	padding: 4px 12px;
	color: rgba(255,100,100);
	font-size: 12px;
	border-radius: 4px;
	max-width: 80%;
	min-width: 100px;
	margin: auto;
	text-align: center;
}*/