
.performance-analysis-log-card {
	width: 100%;
	border-bottom: solid;
	border-width: 1px;
	border-color: #141414;
	padding: 22px 0px;
	padding-left: 32px;
	cursor: pointer;
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
}

	.performance-analysis-log-card:hover .performance-analysis-log-card-isselected {
		background: #5B62FFA0;
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}
	.performance-analysis-log-card-header {
		width: 100%;
		position: relative;
	}
		.performance-analysis-log-card-header-text-container {

		}
		.performance-analysis-log-card-header-text-container-unselected {
			opacity: 0.3;
		}
		
		.performance-analysis-log-card-header-text-container h4 {
			font-size: 21px;
			color: #ffffff;
			margin: 0px;
			margin-top: 4px;
			font-weight: 300;
		}
		.performance-analysis-log-card-header-date-container {
			display: flex;
		}
			.performance-analysis-log-card-header-date-container p {
				font-size: 13.5px;
				color: #6C6C6C;
				margin: 0px;
			}
			.performance-analysis-log-card-recording-container {
				background: #5EFF5B33;
				padding: 2px 8px;
				border-radius: 5px;
				margin-top: -2px;
				margin-left: 8px;
			}
				.performance-analysis-log-card-recording-container p {
					font-size: 12px;
					color: #5EFF5B;
					margin: 0px;
				}
		.performance-analysis-log-card-isselected {
			width: 32px;
			height: 32px;
			border-radius: 16px;
			border-width: 1px !important;
			border: solid;
			border-color: #181818;
			position: absolute;
			right: 0px;
			top: 4px;
		}
			.performance-analysis-log-card-isselected-selected {
				border: none;
				background: #5B62FF;
			}
	.performance-analysis-log-card-body {
		width: 100%;
		display: flex;
		padding-top: 16px;
	}
		.performance-analysis-log-card-body-col {
			width: 33%;
		}
			.performance-analysis-log-card-body-col p {
				font-size: 12px;
				color: #6C6C6C;
				margin: 0px;
			}
			.performance-analysis-log-card-body-col h4 {
				font-size: 16px;
				color: #ffffff;
				margin: 0px;
				margin-top: 2px;
				font-weight: 300;
			}