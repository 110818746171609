
.log-measurement-modal-container {
	width: 100% !important;

}

	.log-measurement-modal-header-container {
		padding: 20px 32px;
		width: 100%;
		display: flex;
		border-bottom: solid;
		border-width: 1px;
		border-color: #2B2B30;
		box-shadow: 0px 0px 12px 6px rgba(0, 0, 0, 0.08);
	}
		.log-measurement-modal-header-container h1 {
			font-size: 18px;
			color: #ffffff;
			margin: 0px;
			margin-top: 3px;
		}
		.log-measurement-modal-header p {
			font-size: 13.5px;
			margin-bottom: 12px;
			color: #ffffff;
			line-height: 20px;
		}
			.log-measurement-modal-header-close-button-container {
				display: flex;
				border-right: solid;
				border-width: 1px;
				border-color: #2B2B30;
				padding-right: 12px;
				margin-right: 12px;
			}
			.log-measurement-modal-header-close-button {
					border-radius: 5px;
					width: 29px;
					height: 29px;
					padding: 6px 7px;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
				.log-measurement-modal-header-close-button:hover {
					background: rgba(0, 0, 0, 0.2);
					cursor: pointer;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
				.log-measurement-modal-header-close-button-close-icon {
					font-size: 16px !important;
					color: #6C6C6C;
				}
			.log-measurement-modal-cta-button {
				padding: 6px 12px;
			    background: #5B62FF;
			    display: flex;
			    border-radius: 5px;
			    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.12);
			    font-size: 13.5px;
			    color: white;
			    margin: 0px;
			    height: 29px;
			    margin-right: 12px;
			    margin-left: auto;
				float: right;

			    -webkit-transition: all 0.15s;
			    -moz-transition: all 0.15s;
			    transition: all 0.15s;
			}
			.log-measurement-modal-cta-button:hover {
			    background: #7278F2;
			    cursor: pointer;
			    -webkit-transition: all 0.15s;
			    -moz-transition: all 0.15s;
			    transition: all 0.15s;
			}
				

	.log-measurement-modal-done-button {
		width: 220px;
		margin: 24px auto;
	}
		.log-measurement-modal-done-button p {
			width: 100%;
			text-align: center;
		}

		.log-measurement-modal-loading-container {
			width: 100%;
			padding-top: 5vh;
		}
			.log-measurement-modal-loading-container p {
				font-size: 13.5px;
				margin: 12px;
				color: #6C6C6C;
				width: calc(100% - 24px);
				text-align: center;
			}
			.log-measurement-modal-chart-container {
				margin: 0px auto;
				width: 700px;
			}
			.log-measurement-modal-chart-container .body-measurement-chart {
				border: none;
				box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.0);
				background: transparent;
			}