.new-client-container {
	height: 100%;
	
	background: #161719;
}

.new-client-header-container {
	padding: 24px 32px;
	display: block;
	width: 33%;
	min-width: 33%;
	height: 100%;
	background: #28282D;
	position: relative;
	-webkit-transition: all 0.35s;
	-moz-transition: all 0.35s;
	transition: all 0.35s;
}

.new-client-header-icon-container {
	margin-top: 3px;
	width: 38px;
	height: 38px;
	border-radius: 5px;
	background: rgba(193,101,73,0.3);
	padding: 10px 10px;
}
	.new-client-header-icon {
		font-size: 18px !important;
		color: #C16549;
	}

	.new-client-header-icon-container-first-screen {
		padding: 0px;
		margin: 0px;
		height: 40px;
		margin-top: calc(50vh - 210px);
	}
	.new-client-header-icon-container-first-screen .new-client-header-icon {
		color: white;
		font-size: 24px !important;
	}

.new-client-header-container-coloured {
	background: #C16549;
	-webkit-transition: all 0.35s;
	-moz-transition: all 0.35s;
	transition: all 0.35s;
}
.new-client-header-container {
	-webkit-transition: all 0.35s;
	-moz-transition: all 0.35s;
	transition: all 0.35s;
}

.new-client-content-container-wrapper {
	width: 100%;
	height: 100%;
	display: flex;
}
.new-client-content-container-wrapper-loading {
	width: 100%;
}
	.new-client-content-container-wrapper-loading-container {
		width: 100%;
		padding-top: 36vh;
	}
	.new-client-content-container-wrapper-loading-container p {
	    font-size: 13.5px;
	    color: #9DA0AD;
	    margin: 0px;
	    width: 100%;
	    text-align: center;
	    margin-top: 8px;
	}
	.new-client-content-container {
		height: 100%;
		width: 67%;
		padding: 12px 64px;
		overflow-y: scroll;
		position: relative;
		width: 40%;
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
	    scrollbar-width: none;  /* Firefox */
	/*	box-shadow: 0 0px 36px 8px rgba(0, 0, 0, 0.2);*/
	}
	.new-client-content-container-full {
		width: 100%;
		-webkit-transition: all 0.35s;
		-moz-transition: all 0.35s;
		transition: all 0.35s;
	}
	.new-client-content-container-lean {
		padding: 12px 32px !important;
		padding-bottom: 80px !important;
		position: relative;
	}
		.new-client-content-page-section-curtain {
			position: absolute;
			width: 100%;
			height: 920px;
			top: 260px;
			left: 0px;
			z-index: 100;
			background: #161719CC;
			-webkit-backdrop-filter: blur(2px);
			backdrop-filter: blur(2px);
		}
	.new-client-content-container::-webkit-scrollbar { 
	    display: none;  /* Safari and Chrome */
	}
		.new-client-right-pane-content-container {
			/*box-shadow: 0 24px 36px 8px rgba(0, 0, 0, 0.15);*/
			overflow-y: scroll;
			height: calc(100% - 60px);
			/*background: #161719;*/
			border-left: solid;
			border-width: 1px;
			border-color: #2B2B30;
			padding: 38px 64px;
			width: 60%;
		}
			.new-client-right-pane-content-header-container {
				width: calc(100% + 124px);
				padding-left: 64px;
				margin-left: -64px;
				border-bottom: solid;
				border-width: 1px;
				border-color: #2B2B30;
				display: flex;
				margin-bottom: 24px;
				padding-bottom: 16px;
			}
				.new-client-right-pane-content-header-option {
					padding-right: 24px;
					position: relative;
				}
				.new-client-right-pane-content-header-option:hover {
					cursor: pointer;
				}
					.new-client-right-pane-content-header-option p {
						font-size: 13.5px;
						color: #6C6C6C;
						margin: 0px;
					}
					.new-client-right-pane-content-header-option-selected {

					}
						.new-client-right-pane-content-header-option-selected p {
							color: #ffffff !important;
							font-weight: 600;
						}
					.new-client-right-pane-content-header-selected-option-bar {
						position: absolute;
						bottom: -16px;
						left: 0px;
						width: calc(100% - 24px);
						height: 2px;
						border-radius: 1px;
						background: var(--ef-cta);
					}
	.new-client-content-container-single {
		height: calc(100% - 58px);
		width: 100%;
		overflow-y: scroll;
	}
		.new-client-content-container-single-inner {
			width: 60%;
			max-width: 660px;
			min-width: 300px;
			margin: 0px auto;
			
			
			padding: 0px;
			position: static;
		}

.new-client-content-page {
	width: 100%;
	padding-top: 12px;
}
	.new-client-content-page-section-header {
		width: 100%;
		max-width: 660px;
		padding-bottom: 16px;
		margin-bottom: 24px;
		border-bottom: solid;
		border-width: 1px;
		border-color: #2B2B30;
	}
	.new-client-content-page-section-header-noline {
		border-bottom: none;
	}
	.new-client-content-page-section-header-extra-margin {
		margin-top: 32px;
		margin-bottom: 6px;
	}
		.new-client-content-page-section-header h2 {
			font-size: 18px !important;
			font-weight: 500;
			color: #ffffff !important;
			margin: 0px;
			margin-bottom: 4px;
		}
		.new-client-content-page-section-header h4 {
			font-size: 13.5px !important;
			font-weight: 500;
			color: #ffffff !important;
			margin: 0px;
			margin-bottom: 4px;
		}
		.new-client-content-page-section-header p {
			font-size: 13.5px !important;
			color: #9DA0AD !important;
			margin: 0px;
		}
	.new-client-content-page-section-header-line {
		border-top: solid;
		border-width: 0.5px;
		border-color: #393D54;
		margin-top: 24px;
		padding-top: 24px;
		min-width: 100%;
		padding-bottom: 36px;
	}
	.new-client-content-page-subsection-header {
		width: 100%;
		max-width: 660px;
		padding-bottom: 16px;
		margin-top: 36px;
	}
		.new-client-content-page-subsection-header h4 {
		    font-size: 13.5px;
		    color: #ffffff;
		    margin: 0px;
		}
	.new-client-content-page-section {

	}
		.new-client-header-upper-content {
			width: 100%;
			display: flex;
		}
			.new-client-header-upper-content h2 {
				font-size: 18px;
				color: #ffffff;
				margin: 0px;
				margin-left: 12px;
				margin-top: 12px;
			}
		.new-client-header-body-container {
			padding-top: 10vh;
		}
			.new-client-header-body-container h1 {
				font-size: 28px;
				color: #ffffff;
				margin: 0px;
			}
			.new-client-header-body-container p {
				margin: 0px;
				margin-top: 12px;
				font-size: 13.5px;
				line-height: 18px;
				max-width: 400px;
				color: #ffffff;
			}

			.new-client-header-body-content {
				padding-top: 24px;

			}
				.new-client-header-body-row-container {
					display: flex;
					padding: 3vh 0px;
					position: relative;
					cursor: pointer;
					margin-left: -12px;
					width: calc(100% + 12px);
					padding-left: 12px;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
				.new-client-header-body-row-container:hover {
					background: #28282D;
					border-radius: 5px;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
					.new-client-header-body-row-circle {
						width: 32px;
						height: 32px;
						border-radius: 50%;
						border-color: #2B2B30 !important;
						border-width: 1px !important;
						border: solid;
						-webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}
					.new-client-header-body-row-container:hover .new-client-header-body-row-circle {
						border-color: #9DA0AD !important;
						-webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}
						.new-client-header-body-row-circle h4 {
							font-size: 13.5px;
							padding-top: 7px;
							margin: 0px;
							width: 100%;
							text-align: center;
							color: #2B2B30;
						}
						.new-client-header-body-row-container:hover .new-client-header-body-row-circle h4 {
							color: #9DA0AD !important;
							-webkit-transition: all 0.15s;
							-moz-transition: all 0.15s;
							transition: all 0.15s;
						}
					.new-client-header-body-row-container-selected .new-client-header-body-row-circle {
						border-color: #ffffff !important;
					}
						.new-client-header-body-row-container-selected .new-client-header-body-row-circle h4 {
							color: #ffffff;
						}
					.new-client-header-body-row-text-container {
						padding-left: 12px;
					}
						.new-client-header-body-row-text-container h4 {
							font-size: 13.5px;
							padding-top: 8px;
							margin: 0px;
							width: 100%;
							text-align: center;
							color: #2B2B30;
							-webkit-transition: all 0.15s;
							-moz-transition: all 0.15s;
							transition: all 0.15s;
						}
						.new-client-header-body-row-container:hover .new-client-header-body-row-text-container h4 {
							color: #9DA0AD !important;
							-webkit-transition: all 0.15s;
							-moz-transition: all 0.15s;
							transition: all 0.15s;
						}
					.new-client-header-body-row-container-selected .new-client-header-body-row-text-container h4 {
						color: #ffffff;
					}

					.new-client-header-body-row-container-completed .new-client-header-body-row-circle {
						border-color: var(--ef-cta) !important;
					}
						.new-client-header-body-row-container-completed .new-client-header-body-row-circle h4 {
							color: var(--ef-cta) !important;
						}
					.new-client-header-body-row-container-completed .new-client-header-body-row-text-container h4 {
						color: #9DA0AD !important;
					}
					.new-client-header-body-row-container-completed .new-client-header-body-row-line {
						background: var(--ef-cta);
					}

				.new-client-header-body-row-line {
					width: 1px;
					height: calc(6vh - 8px);
					background: #2B2B30;
					position: absolute;
					top: calc(3vh + 36px);
					left: 28px;
				}
				.new-client-header-body-sub-page-container {
					position: absolute;
					left: 42px;
					top: calc(3vh + 36px);
					display: flex;
				}
					.new-client-header-body-sub-page-container h4 {
						margin: 0px;
						margin-left: 0px;
						color: #ffffff;
						font-size: 13.5px;
					}
					.new-client-header-body-sub-page-icon-container {
						width: 20px;
						height: 20px;

					}
						.new-client-header-body-sub-page-icon {
							font-size: 13.5px !important;
						}

	.new-client-container-unit-select-container {
		width: 100%;
		display: flex;
		max-width: 700px;
		padding: 16px;
		padding-left: 32px;
		border: solid;
		border-width: 1px;
		border-color: #2B2B30;
		border-radius: 5px;
		box-shadow: 0 2px 24px 4px rgba(0, 0, 0, 0.1);

		/*border-top: solid;
		border-bottom: solid;
		border-width: 1px;
		border-color: #2B2B30;*/
	}
		.new-client-container-unit-select-text-container {
			padding-top: 2px;
		}
			.new-client-container-unit-select-text-container h4 {
				margin: 0px;
				color: #ffffff;
				font-size: 13.5px;
			}
			.new-client-container-unit-select-text-container p {
				margin: 0px;
				color: #6C6C6C;
				font-size: 10px;
			}
		.new-client-container-unit-select-selector-container {
			width: 140px;
			margin-left: auto;
			float: right;
			margin-top: -2px;
		}
		.new-client-container-unit-select-options-container {
			margin-left: auto;
			float: right;
			display: flex;
		}
			.new-client-container-unit-select-option {
				display: flex;
				padding: 7px 8px;
				margin-left: 4px;
			}
				.new-client-container-unit-select-option-text-container {
					cursor: pointer;
					margin-left: 8px;
				}
					.new-client-container-unit-select-option-text-container p {
						margin: 0px;
						color: #ffffff;
						font-size: 13.5px;
					}
	.new-client-content-page-section-spacer {
		width: 100%;
		height: 12px;
	}
	.new-client-content-page-section-spacer-5vh {
		width: 100%;
		height: 5vh;
	}
	.new-client-content-page-info-container {
		width: 100%;
		padding: 0px;
		padding-bottom: 24px;
		display: flex;
	}
		.new-client-content-page-info-container p {
			font-size: 10px;
			color: #9DA0AD;
			margin: 0px;
			margin-left: 8px;
			margin-top: 2px;
			font-weight: 500;
		}
		.new-client-content-page-info-icon-container {
			width: 16px;
			height: 16px;
			border-radius: 8px;
			background: rgba(73,89,193,0.2);
			padding: 3px;
			animation-name: example;
  			animation-duration: 1.67s;
			animation-iteration-count: infinite;
		}
			.new-client-content-page-info-icon {
				width: 10px;
				height: 10px;
				border-radius: 5px;
				background: var(--ef-cta);
			}
		@keyframes example {
		  0%   {background: rgba(73,89,193,0.0); left:0px; top:0px;}
		  75%  {background: rgba(73,89,193,0.5); left:0px; top:200px;}
		  100% {background: rgba(73,89,193,0.0); left:0px; top:0px;}
		}
	.new-client-content-page-info-container-email-verify {
		margin-top: -2px;
	}
		.new-client-content-page-info-container-email-verify p {
			font-size: 12px !important;
			margin-top: 0px;
			color: #7278F2;
			width: calc(100% - 80px);
			line-height: 18px;
		}
	.new-client-content-page-info-container-user {
		margin-top: -2px;
	}
		.new-client-content-page-info-container-user-inner {
			display: flex;
		}
			.new-client-content-page-info-container-user-inner p {
			    font-size: 13.5px;
			    color: #7278F2;
			    margin: 0px;
			    margin-left: 6px;
			}
			.new-client-content-page-info-container-user-icon {
				font-size: 15px !important;
				color: #7278F2;
			}

	.new-client-content-page-row {
		width: 100%;
		display: flex;
		padding-top: 12px;
	}
	.new-client-content-page-row-no-padding {
		padding-top: 0px;
		margin-top: -8px;
	}
		.new-client-content-page-row-col {
			width: calc(50% - 12px);
			margin: auto;
		}
		.new-client-content-page-row-col-single {
			margin: 0px !important;
		}
	.new-client-content-page-row-account-info {
		display: block;
		padding-top: 12px;
	}

.new-client-header {
	padding-left: 0px;
	padding-top: 4px;
}

	.new-client-header-text-container {

	}
	.new-client-header-text-container h1 {
		margin: 0px;
		margin-bottom: 16px;
		font-size: 28px;
		font-weight: 700;
		color: white;
	}
	.new-client-header-text-container p {
		margin: 0px;
		font-size: 13.5px;
		line-height: 18px;
		max-width: 400px;
		color: rgba(255,255,255,0.8);
	}
	.new-client-header-close-button-container {
		position: absolute;
		top: 24px;
		right: 24px;
	}
		.new-client-header-close-button {
			width: 40px;
			height: 40px;
			background: rgba(0,0,0,0.24);
			color: #9DA0AD;
			border-radius: 5px;
			padding-left: 13px;
			padding-top: 12px;
			-webkit-transition: all 0.15s;
		  	-moz-transition: all 0.15s;
		  	transition: all 0.15s;
		}
		.new-client-header-close-button:hover {
			color: #ffffff;
			background: rgba(0,0,0,0.12);
			cursor: pointer;
			-webkit-transition: all 0.15s;
		  	-moz-transition: all 0.15s;
		  	transition: all 0.15s;
		}
			.new-client-header-close-button-close-icon {
				font-size: 15px !important;
				
			}

	.new-client-header-save-close-buttons-container {
		position: absolute;
		z-index: 500;
		top: 24px;
		right: 56px;
		display: flex;
	}
		.new-client-header-save-close-buttons-close-button {
			padding: 6px 12px;
		}
		.new-client-header-save-close-buttons-close-button:hover {
			cursor: pointer;
		}
			.new-client-header-save-close-buttons-close-button p {
				font-size: 13.5px;
				color: #6C6C6C;
				margin: 0px;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
			
			.new-client-header-save-close-buttons-close-button:hover p {
				color: #ffffff !important;
				cursor: pointer;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
		.new-client-header-save-close-buttons-save-button {
			background: var(--ef-cta);
			border-radius: 5px;
			box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
			padding: 6px 12px;
			margin-left: 12px;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
		.new-client-header-save-close-buttons-save-button:hover {
			background: #5C6BC9;
			cursor: pointer;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
			.new-client-header-save-close-buttons-save-button p {
				font-size: 13.5px;
				color: #ffffff;
				margin: 0px;

			}

	.new-client-header-dots-container {
		width: 100%;
		display: flex;
		margin-top: 10vh;
	}
		.new-client-header-dot-long {
			width: 30px;
			height: 6px;
			border-radius: 3px;
			margin-right: 4px;
			background: rgba(255,255,255,0.9);
		}
		.new-client-header-dot-short {
			width: 15px;
			height: 6px;
			border-radius: 3px;
			margin-right: 4px;
			background: rgba(255,255,255,0.5);
		}
.new-client-content-page-input-container {
	/*max-width: 500px;*/
}
	.new-client-content-page-input-container-with-spacing {
		padding: 24px 0px;
		padding-top: 30px;
	}

	.new-client-content-page-input-container-with-button {
		display: flex;
		width: 100%;
	}
	.new-client-content-page-input-container-with-button .form-input-container {
		min-width: calc(100% - 130px);
	}
		.new-client-content-page-input-button {
			float: right;
			margin-left: 12px;
			background: var(--ef-cta);
			padding: 11px 12px;
			height: 38px;
			border-radius: 5px;
			margin-top: 10px;
			width: 118px;
			box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.12);
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
		.new-client-content-page-input-button:hover {
			background: #7278F2;
			cursor: pointer;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
		.new-client-content-page-input-button-unselected {
			opacity: 0.2;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
		.new-client-content-page-input-button-unselected:hover {
			background: var(--ef-cta-light);
			cursor: default;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
	.new-client-content-page-input-verified-container {
		width: 118px;
		background: rgba(92, 98, 255, 0.2);
		border: solid;
		border-width: 1px;
		border-color: #7278F2;
		height: 38px;
		border-radius: 5px;
		margin-top: 10px;
		width: 118px;
		display: flex;
		margin-left: 12px;
		padding: 10px 12px;
	}
		.new-client-content-page-input-verified-container p {
		    font-size: 13.5px;
		    font-weight: 600;
		    color: #7278F2;
		    margin: 0px;
		    width: calc(100% - 30px);
		    text-align: center;
		}
		.new-client-content-page-input-verified-icon {
			font-size: 16px !important;
			margin-left: 5px;
			color: #7278F2;
		}

			.new-client-content-page-input-button p {
			    font-size: 13.5px;
			    font-weight: 600;
			    color: white;
			    margin: 0px;
			    white-space: nowrap;
			    width: 100%;
			    text-align: center;
			}
			.new-client-content-page-input-button-spinner {
				width: 18px !important;
				height: 18px !important;
				border-top-color: white !important;
				border-right-color: white !important;
				border-bottom-color: white !important;
			}



.new-client-workflow-state-container {
	display: flex;
	padding-top: 8px;
}
	.new-client-workflow-state-container div {
		display: flex;
	}

.new-client-workflow-state-container p {
	margin: 0px;
	font-size: 10px;
	margin-right: 12px;
}

	.new-client-selected-workflow-state {
		color: #C1C4CC !important;
		font-weight: 500;
	}
	.new-client-selected-workflow-state:hover {
		color: white !important;
		cursor: pointer;
	}
	

	.new-client-unselected-workflow-state {
		color: #6C6C6C !important;
	}
	.new-client-unselected-workflow-state:hover {
		cursor: pointer;
	}

	.new-client-completed-workflow-state {
		color: var(--ef-cta-light) !important;
		font-weight: 500;
	}
	.new-client-completed-workflow-state:hover {
		color: #5C6BC9 !important;
		cursor: pointer;
	}

.modal-progress-circle {
	background-color: rgba(0,0,0,0.3);
	color: white;
	font-size: 24px;
	width: 60px;
	height: 60px;
	border-radius: 30px;
	text-align: center;
	padding-top: 13px;
}

.new-client-static-header {
	font-size: 14px;
	color: #C1C4CC;
	margin: 0px;
}

.new-client-header-warning-text {
	margin-top: 0px;
	margin-bottom: 24px;
	font-size: 13.5px;
	padding: 12px;
	color: #F02E4A;
	border-color: #F02E4A;
	border-width: 1px !important;
	border: solid;
	border-radius: 5px;
	background: rgba(200,23,23,0.12);
}

.new-client-gender-select-container {}

.new-client-gender-select-label {
	font-size: 14px;
	color: #C1C4CC;
}

.new-client-centered-container {
	width: 100%;
	height: 100%;
}

.new-client-centered-container h2 {
	font-size: 18px;
	margin: 0px;
	width: 100%;
	text-align: center;
	padding-top: calc(50vh - 60px);
	color: #ffffff;
}

.new-client-centered-container p {
	font-size: 13.5px;
	color: #9DA0AD;
	margin: 0px;
	width: 70%;
	margin-left: 15%;
	text-align: center;
	padding-top: 8px;
}

.new-client-centerd-loading-text {
	padding-top: calc(50vh - 60px) !important;
}

.create-client-reminder-empty-button-container {
	width: 140px !important;
}
	.create-client-reminders-list {
		min-height: 400px;
	}

	.create-client-reminders-list-cell {
		width: 100%;
		display: flex;
		padding: 8px 12px;
		padding-top: 10px;
		background: rgba(180,200,255,0.03);
		border: solid;
		border-width: 1px;
		border-color: #2B2B30;
		border-radius: 5px;
		box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
		margin-bottom: 8px;
	}
		
		.create-client-reminders-list-cell-col {

		}
			.create-client-reminders-list-cell-col h4 {
				font-size: 13.5px;
				color: #ffffff;
				margin: 0px;
			}
		.create-client-reminders-list-cell-col-header {
			width: 40%;
			max-width: 40%;
			padding-right: 12px;
		}

		.create-client-reminders-list-cell-col-body {
			
			width: 20%;
			min-width: 20%;
			padding-right: 12px;
		}
			.create-client-reminders-list-cell-col-buttons {
				width: 140px;
				display: flex;
			}
			.create-client-reminders-list-new-reminder-button {
				padding: 6px 12px;
				box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
				border-radius: 5px;
				margin: auto 0px;
				margin-left: 8px;
			}
			.create-client-reminders-list-new-reminder-button:hover {
				cursor: pointer;
			}
				.create-client-reminders-list-new-reminder-button p {
					font-size: 13.5px;
					margin: 0px;
				}

			.create-client-reminders-list-new-reminder-button-cancel {
				background: #1F1F21;
				border: solid;
				border-width: 0.5px;
				border-color: #2B2B30;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
			.create-client-reminders-list-new-reminder-button-cancel:hover {
				background: #28282D;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s; 
			}

				.create-client-reminders-list-new-reminder-button-cancel p {
					color: #9DA0AD;
				}
			.create-client-reminders-list-new-reminder-button-save {
				background: var(--ef-cta);
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
			.create-client-reminders-list-new-reminder-button-save:hover {
				background: #5C6BC9;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s; 
			}

				.create-client-reminders-list-new-reminder-button-save p {
					color: #ffffff;
				}

		.create-client-reminders-list-header {
			display: flex;
			padding: 8px 12px;
		}	
			.create-client-reminders-list-header-col {

			}
				.create-client-reminders-list-header-col p {
					font-size: 10px;
					color: #6C6C6C;
					margin: 0px;
				}
			.create-client-reminders-list-header-col-type {
				width: 40%;
			}
			.create-client-reminders-list-header-col-body {
				width: 20%;
				padding-left: 4px;
			}
			.create-client-reminders-list-header-col-options {
				width: 140px !important;
				padding-left: 12px;
			}
				.create-client-reminders-list-cell-col-options {
					width: 134px;
				}
				.create-client-reminders-list-cell-col-options-button {
					width: 20px;
					height: 20px;
					border-radius: 5px;
					padding: 2px 4px;
					margin-left: auto;
					margin-right: 0px;
					float: right;
				}
				.create-client-reminders-list-cell-col-options-button:hover {
					background: rgba(0,0,0,0.3);
					cursor: pointer;
				}
					.create-client-reminders-list-cell-col-options-button-icon {
						font-size: 12px !important;
						color: #6C6C6C;
					}
					.create-client-reminders-list-cell-col-options-button:hover .create-client-reminders-list-cell-col-options-button-icon {
						color: #ffffff;
					}
			.create-client-reminders-add-new-container {
				width: 200px;
				padding: 8px 0px;
				cursor: pointer;
			}
			.create-client-reminders-add-new-container:hover p {
				color: #5C6BC9;
				cursor: pointer;
			}
				.create-client-reminders-add-new-container p {
					font-size: 13.5px;
					margin: 0px;
					color: var(--ef-cta-light);
				}


	.new-client-cotent-goals-list-container {
		width: 100%;
		padding: 24px 0px;
		padding-bottom: 8px;
	}
		.new-client-cotent-goals-new-goal-container {
			cursor: pointer;
		}
			.new-client-cotent-goals-new-goal-container p {
				font-size: 13.5px;
				margin: 0px;
				color: var(--ef-cta-light);
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
			.new-client-cotent-goals-new-goal-container:hover p {
				color: #5C6BC9;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}

	.new-client-content-page-account-password-hint-container {
		width: 100%;
		padding: 8px;
	}
		.new-client-content-page-account-password-hint-container h4 {
			font-size: 13.5px;
			color: #ffffff;
			margin: 0px;
		}
		.new-client-content-page-account-password-hint-container p {
			font-size: 13.5px;
			color: #ffffff;
			margin: 0px;
		}

		.new-client-content-page-account-password-hint-container-error h4 {
			color: #F02E4A;
		}
		.new-client-content-page-account-password-hint-container-error p {
			color: #F02E4A;
		}

.new-client-content-page-section-selector-container {
	padding: 12px 0px;
}
	.new-client-content-page-section-selector-container h4 {
	    font-size: 13.5px;
	    font-weight: 500;
	    color: #ffffff;
	    margin: 0px;
	    margin-bottom: 6px;
	}
	.new-client-container-body-measurement-container {
		
	}

