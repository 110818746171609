.workout-plan-list-folder {
    width: 100%;
    display: block;
    position: relative;
}
    .workout-plan-list-folder-branch-container {
        width: 50px;
        height: 50px;
        position: absolute;
        z-index: 0;
        top: -50px;
        left: -25px;
    }
        .workout-plan-list-folder-branch-container-inner {
            width: 100%;
            height: 100%;
            position: relative;
        }
            .workout-plan-list-folder-branch {
                width: 50px;
                height: 80px;
                border-bottom-left-radius: 12px;
                border-color: var(--ef-text-dark) !important;
                border-width: 1px !important;
                border-left: solid;
                border-bottom: solid;
            }
                .workout-plan-list-folder-branch-folder {
                    border-color: var(--ef-color-folder) !important;
                }
                .workout-plan-list-folder-branch-plan {
                    border-color: var(--ef-color-program) !important;
                }

    
    .workout-plan-list-folder-container {
        position: relative;
        z-index: 1;
        display: flex;
        height: 50px;
        background: var(--ef-card);
        margin-bottom: 4px; 
        border: solid;
        border-radius: var(--ef-radius-m);
        border-width: 1px;
        border-color: var(--ef-border);
        -webkit-transition: all 0.15s;
        -moz-transition: all 0.15s;
        transition: all 0.15s;
    }
        .workout-plan-list-folder-container:hover {
            position: relative;
            z-index: 1;
            background: var(--ef-card-light);
            border-color: var(--ef-border-light);
            cursor: pointer;
            -webkit-transition: all 0.15s;
            -moz-transition: all 0.15s;
            transition: all 0.15s;
        }
        .workout-plan-list-folder-container:hover .workout-plan-list-folder-card-body-list-title-container h4{
            color: white !important;
        }
    
    .workout-plan-list-folder-container-selected {
        position: relative;
        z-index: 1;
        background: var(--ef-cta-pseudotransparent);
        border: solid;
        border-width: 1px;
        border-color: var(--ef-cta);
        box-shadow: 0px 0px 20px 12px #5437DC10;
    }
    .workout-plan-list-folder-container-selected:hover {
        background: var(--ef-cta-pseudotransparent) !important;
        border-color: #7963E0 !important;
        position: relative;
        z-index: 1;
    }
        .workout-plan-list-folder-container-selected .workout-plan-list-folder-card-body-list-container-folder h4 {
            color: var(--ef-text-white);
            font-weight: 700;
        }
        .workout-plan-list-folder-container-selected:hover .workout-plan-list-folder-card-body-list-container-folder h4 {
            color: #7963E0 !important;
            font-weight: 700;
        }

        .workout-plan-list-folder-container-header {
            
            width: 50px;
            padding: 8px 11px;
            border-right: solid;
            border-width: 1px;
            border-color: var(--ef-border-highlight-dark);
        }

        .workout-plan-list-folder-container-body {
            display: flex;
            height: 100%;
            flex: 1;
            padding: 12px 12px;
            border-left: solid;
            border-width: 1px;
            border-color: var(--ef-border);
            -webkit-transition: all 0.15s;
            -moz-transition: all 0.15s;
            transition: all 0.15s;
        }
            .workout-plan-list-folder-container-selected .workout-plan-list-folder-container-body {
                border-color: var(--ef-cta);
            }
            .workout-plan-list-folder-container-body-drag-icon-container {
                width: 16px;
                padding-top: 3px;
                cursor: move;
            }
                .workout-plan-list-folder-container-body-drag-icon {
                    font-size: 14px !important;
                    color: var(--ef-text-dark);
                }
            .workout-plan-list-folder-container:hover .workout-plan-list-folder-container-body {
                 border-color: var(--ef-border-light);
                -webkit-transition: all 0.15s;
                -moz-transition: all 0.15s;
                transition: all 0.15s;
            }

        .workout-plan-list-folder-card-body-list-title-container {
            padding-top: 2px;
            padding-left:  8px;
            flex: 1;
            display: flex;
        }
        .workout-plan-list-folder-card-body-list-title-container-phase {
            width: 100%;
        }
            .workout-plan-list-folder-card-body-list-title-container h4 {
                font-size: var(--ef-font-size-m);
                color: var(--ef-text-light);
                margin: 0px;
            }

            .workout-plan-list-card-icon-container {
                width: 24px;
                height: 24px;
                max-width: 24px;
                max-height: 24px;
                min-width: 24px;
                min-height: 24px;
                padding: 8px 5px;
                position: relative;
                margin-top: 4px;
                border-radius: var(--ef-radius-s);
            }
            .workout-plan-list-card-icon-container-folder {
                background: var(--ef-color-folder-transparent);
            }
            .workout-plan-list-card-icon-container-plan {
                background: var(--ef-color-program-transparent);
            }
            .workout-plan-list-card-icon-container-cycle {
                border-color: rgba(184,193,73,1);
            }
                .workout-plan-list-card-icon {
                    font-size: 13px !important;
                    position: absolute;
                    top: 5px;
                }
                    .workout-plan-list-card-icon-folder {
                        color: var(--ef-color-folder);
                    }
                    .workout-plan-list-card-icon-plan {
                        top: 3px;
                        left: 3px;
                        font-size: 18px !important;
                        color: var(--ef-color-program);
                    }
                    .workout-plan-list-card-icon-cycle {
                        color: #B8C149;
                    }
        .workout-plan-list-card-summary-container {
            padding: 4px 8px;
            margin-left: auto;
        }
            .workout-plan-list-card-summary-circle {
                background: #1F1F21;
                width: 8px;
                height: 8px;
                min-width: 8px;
                min-height: 8px;
                border-radius: 4px;
                margin-top: 2px;
            }
            .workout-plan-list-card-summary-circle-colour-plan {
                background: #5B62FF;
            }
            .workout-plan-list-card-summary-circle-colour-folder {
                background: #CB5BFF;
            }
            .workout-plan-list-card-summary-circle-colour-cycle {
                background: #B8C149;
            }
                .workout-plan-list-card-summary-text-container {
                    margin-left: 6px;
                }
                    .workout-plan-list-card-summary-text-container p {
                        display: flex;
                        font-size: var(--ef-font-size-s);
                        color: var(--ef-text-dark);
                        font-weight: 300;
                        margin: 0px;
                    }
                .workout-plan-list-card-summary-text-container-program {
                    background: var(--ef-color-program-transparent);
                    padding: 2px 6px;
                    border-radius: var(--ef-radius-s);
                    margin-top: -2px;
                    margin-right: -6px;
                }
                    .workout-plan-list-card-summary-text-container-program p {
                        color: var(--ef-cta-light);
                    }
        .workout-plan-list-folder-expand-container {
            height: 24px;
        }
        .workout-plan-list-folder-container:hover .workout-plan-list-folder-expand-container {

            cursor: pointer;
            -webkit-transition: all 0.15s;
            -moz-transition: all 0.15s;
            transition: all 0.15s;  
        }
            .workout-plan-list-folder-expand-icon-container {
                width: 100%;
                height: 100%;
                padding: 4px 2px;

            }
                .workout-plan-list-folder-expand-icon {
                    font-size: 18px !important;
                    color: var(--ef-text-dark);
                }

    .workout-plan-list-folder-sub-folder-container {
        position: relative;
        z-index: 0;
        padding-left: 50px;
        background: rgba(0,0,0,0);
    }
    .workout-plan-list-folder-sub-folder-container-empty {
        padding-top: 16px;
        padding-bottom: 8px;
        height: 50px;
    }
        .workout-plan-list-folder-sub-folder-container-empty p {
            font-size: 10px;
            color: #6C6C6C;
            margin: 0px;
        }
    .workout-plan-list-card-summary-active-circle {
       
        height: 18px;
        padding: 3px 5px;
        border-radius: 9px;
        background: rgba(91,255,127,0.12);
        margin-left: 8px;
        margin-top: 0px;
    }
        .workout-plan-list-card-summary-active-circle p {
            font-size: 10px;
            font-weight: 700;
            color: #5BFF7F;
            margin: 0px;
        }



