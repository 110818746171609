/* .performance-analysis */

.performance-analysis {
	background: black;
	height: 100vh;
}

.performance-analysis-upper {
	width: 100%;
	padding: 22px 24px;
	border-bottom: solid;
	border-width: 1px;
	border-color: #141414;
	display: flex;
}
	.performance-analysis-upper-content {
		display: flex;
	}
	.performance-analysis-upper-content h4 {
	    font-size: 18px;
	    color: #ffffff;
	    margin: 0px;
	    padding-top: 4px;
	}
	.performance-analysis-upper-close-button-container {
		margin-right: 32px;
		border-right: solid;
		border-width: 1px;
		border-color: #141414;
		height: 28px;
		padding: 5.5px 6px;
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}
	.performance-analysis-upper-close-button-container:hover .performance-analysis-upper-close-button-icon {
		opacity: 1.0;
		cursor: pointer;
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}
		.performance-analysis-upper-close-button-icon {
			font-size: 16px !important;
			color: #ffffff;
			margin-right: 24px;
			opacity: 0.3;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
	.performance-analysis-upper-client-picker-container {
		margin-left: auto;
		float: right;
		margin-top: -6px;
		margin-bottom: -2px;
		width: 260px;
	}
	.performance-analysis-body {
		width: 100%;
		height: 100%;
		display: flex;
	}
	.performance-analysis-list-wrapper {
		width: 30%;
	}
		.performance-analysis-list-container {
			width: 100%;
			height: calc(100% - 72px);
			/*border-right: solid;
			border-width: 1px;
			border-color: #141414;*/
			overflow-y: scroll;
		}

			.performance-analysis-list-header {
				width: 100%;
				border-bottom: solid;
				border-width: 1px;
				border-color: #141414;
				height: 106px;
			}
				.performance-analysis-list-header-showing-row {
					width: 100%;
					display: flex;
					padding: 22px 32px;
				}
					.performance-analysis-list-header-showing-title-container {
						margin-right: 24px;
					}
						.performance-analysis-list-header-showing-title-container h4 {
						    font-size: 15px;
						    color: #ffffff;
						    margin: 0px;
						    font-weight: 300;
						}
					.performance-analysis-list-header-showing-pill {
						padding: 4px 12px;
						border-radius: 20px;
						background: #5B62FF30;
						margin-top: -4px;
						display: flex;
						align-items: center;
							-webkit-transition: all 0.15s;
							-moz-transition: all 0.15s;
							transition: all 0.15s;
					}
						.performance-analysis-list-header-showing-pill h4 {
						    font-size: 15px;
						    color: #7278F2;
						    margin: 0px;
						    font-weight: 300;
						}
						.performance-analysis-list-header-showing-pill-close-button {
							width: 12px;
							height: 12px;
							margin-top: -3px;
							margin-left: 6px;
						}
							.performance-analysis-list-header-showing-pill-close-button-icon {
								font-size: 16px !important;
								color: #7278F2;
							}
						.performance-analysis-list-header-showing-pill:hover {
							cursor: pointer;
							background: #5B62FF60;
							-webkit-transition: all 0.15s;
							-moz-transition: all 0.15s;
							transition: all 0.15s;
						}
			.performance-analysis-list-content {
				width: 100%;
				min-height: 100%;
				padding-right: 32px;
			}
			.performance-analysis-list-content-loading {
				justify-content: center;
				align-items: center;
			}
			.performance-analysis-list-content-loading p {
				font-size: 13.5px;
				color: #6C6C6C;
				text-align: center;
				padding-top: 4px;
			}


		.performance-analysis-data-container {
			width: 70%;
			height: 100%;
		}
			.performance-analysis-data-content {
				width: 100%;
				height: 100%;
			}
			.performance-analysis-data-content-loading {
				justify-content: center;
				align-items: center;
			}
				.performance-analysis-data-content-loading p {
					font-size: 13.5px;
					color: #6C6C6C;
					text-align: center;
					padding-top: 4px;
				}

				.performance-analysis-data-content-header {
					width: 100%;
					padding: 0px 32px;
					/*border-bottom: solid;
					border-width: 1px;
					border-color: #141414;*/
				}
					.performance-analysis-data-content-header-title-container {
						padding: 24px 0px;
					}
						.performance-analysis-data-content-header-title-container h4 {
							font-size: 16px;
						    color: #ffffff;
						    margin: 0px;
						    padding-top: 4px;
						}
						.performance-analysis-data-content-header-title-container span {
							font-size: 16px;
						    color: #6C6C6C;
						    font-weight: 300;
						    margin: 0px;
						    padding-top: 4px;
						}
					.performance-analysis-data-content-header-page-options-container {
						width: 100%;
						display: flex;
						padding-top: 4px;
					}
					
						.performance-analysis-data-content-header-page-option {
							padding-right: 32px;
							opacity: 0.3;
							-webkit-transition: all 0.15s;
							-moz-transition: all 0.15s;
							transition: all 0.15s;
						}
						.performance-analysis-data-content-header-page-option:hover {
							opacity: 0.6;
							-webkit-transition: all 0.15s;
							-moz-transition: all 0.15s;
							transition: all 0.15s;
							cursor: pointer;
						}
							.performance-analysis-data-content-header-page-option p {
								font-size: 14px;
							    color: #ffffff;
							    margin: 0px;
							    margin-bottom: 12px;
							}
						.performance-analysis-data-content-header-page-option-selected { 
							opacity: 1.0;
						}
						.performance-analysis-data-content-header-page-option-selected:hover {
							opacity: 1.0;
						}
						.performance-analysis-data-content-header-page-option-selected-bar {
							width: 100%;
							height: 2px;
							background: #5B62FF;
						}
				
					
			.performance-analysis-body-empty {
				width: 100%;
				height: calc(100% - 200px);
				justify-content: center;
				align-items: center;
			}
				.performance-analysis-body-empty-content {
					width: 400px;
				}
					.performance-analysis-body-empty-content-header {
						width: 100%;
					}
						.performance-analysis-body-empty-content-header h4 {
						    font-size: 18px;
						    color: #ffffff;
						    font-weight: 300;
						    margin: 0px;
						    width: 100%;
						    text-align: center;
						}
					.performance-analysis-body-empty-content-picker-container {
						width: 100%;
						padding-top: 12px;
					}










