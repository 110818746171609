.chat-input-container {
    display: flex;
    background-color: var(--ef-card);
    border-radius: var(--ef-radius-m);
    border-width: 1px;
    border-color: var(--ef-border) !important;
    border: solid;
    margin: 0;
    height: 88px;
    width: 100%;
    box-shadow: 0px 0px 8px 6px rgba(0, 0, 0, 0.1);
    top: 3px;
    margin-right: 10px;
    overflow: hidden;
    padding: 16px;
    -webkit-transition: all 0.15s;
    -moz-transition: all 0.15s;
    transition: all 0.15s;
}
.chat-input-container-highlighted {
    border-color: var(--ef-cta-light) !important;
    border-width: 1px !important;
    -webkit-transition: all 0.15s;
    -moz-transition: all 0.15s;
    transition: all 0.15s;
}
    .chat-input-container-inner {

        width: 100%;
    }
    .chat-input {
        border: none;
        flex-shrink: 0;
        background-color: #00000000;
        height: 30px;
        width: 100%;
        resize: none;
        overflow-y: auto;
        padding-left: 0px;
    }
    .chat-input::placeholder {
        color: var(--ef-text-dark);
        font-size: 18px;
        font-weight: 400;
     }

.chat-send-button-container {
    margin-top: auto;
    height: 34px;
    padding: 8px 12px;
    background: var(--ef-card);
    border-radius: var(--ef-radius-s);
    box-shadow: var(--ef-shadow-button-dark);
    border: solid;
    border-width: 1px;
    border-color: var(--ef-border);
    -webkit-transition: all 0.15s;
    -moz-transition: all 0.15s;
    transition: all 0.15s;
}
.chat-send-button-container:hover {
    background: var(--ef-cta-light) !important;
    -webkit-transition: all 0.15s;
    -moz-transition: all 0.15s;
    transition: all 0.15s;
}
    .chat-send-button-inner {
        display: flex;
    }
        .chat-send-button-inner-text-container {
            padding-right: 10px;
        }
            .chat-send-button-inner-text-container p {
                font-size: var(--ef-font-size-m);
                color: var(--ef-text-dark);
                margin: 0px;
            }


    .chat-send-button-image {
        height: 13px;
        width: 20px;
        margin-top: 1px;
    }

    .chat-send-button-loading-spinner {
        margin: 0px auto;
        width: 16px;
        height: 16px;
        border: 2px solid rgba(0,0,0,0); /* Light grey */
        border-top: 2px solid #ffffff;
        border-right: 2px solid #ffffff;
        border-bottom: 2px solid #ffffff;
        border-radius: 8px;
        animation: spin 1s linear infinite;
    }
        @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
        }