.client-profile-stats {

}

.home-stats-card-container {
	padding-bottom: 12px;
}	
	.home-stats-card-container h4 {
		font-size: 18px;
		color: #C1C4CC;
		margin: 0px;
	}
	.home-stats-card-container p {
		font-size: 13px;
		color: #5F6C76;
		margin-top: 4px;
		margin-bottom: 4px;
	}