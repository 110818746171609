/* .body-measurement-chart- */

.body-measurement-chart {
	width: 100%;
	display: flex;
	max-width: 700px;
	min-width: 700px;
	min-height: 410px;
	background: #1F1F21;
	border-radius: 5px;
	border: solid;
	border-width: 1px;
	border-color: #2B2B30;
	box-shadow: 0 2px 24px 6px rgba(0, 0, 0, 0.1);
	margin-top: 24px;
	padding: 16px;
	padding-bottom: 32px;
}
.body-measurement-chart-no-style {
    min-height: 400px;
    width: 330px;
}
    .body-measurement-chart-no-style  .body-measurement-chart-body-container {
        width: 100%;
    }
@media screen and (max-width: 1100px) {
	.body-measurement-chart {
		display: block;
		min-width: 100%;
	}
	.body-measurement-chart-content-container {
		width: 100% !important;
	}
	.body-measurement-chart-body-container {
    	width: 350px !important;
    	margin: 0px auto;
    	/*padding-left: 24px;*/
    }
    .body-measurement-chart-content-selector-container {
    	height: 120px !important;
    	min-height: 120px !important;
    }
}


	.body-measurement-chart-content-container {
		width: 50%;
		padding: 16px;
		/*padding-right: 24px;*/
	}
		.body-measurement-chart-content-header-container {

		}
			.body-measurement-chart-content-header-container h4 {
				font-size: 13.5px;
	            color: #ffffff;
	            margin: 0px;
	            margin-bottom: 4px;
	            font-weight: 600;
			}
			.body-measurement-chart-content-header-container p {
				font-size: 10px;
	            color: #9DA0AD;
	            margin: 0px;
	            font-weight: 600;
			}
		.body-measurement-chart-content-selector-container {
			padding-top: 24px;
			height: 150px;
			min-height: 150px;
		}
			.body-measurement-chart-content-selector-container p {
				font-size: 10px;
	            color: #6C6C6C;
	            margin: 4px 0px;
			}
			.body-measurement-chart-content-selector-container h5 {
				font-size: 13.5px;
	            color: #5B62FF;
	            margin: 12px 0px;
	            font-weight: 600;
			}

		.body-measurement-chart-content-input-outter-container {
			display: flex;
			margin-top: 36px;
		}
			.body-measurement-chart-content-input-container {
				position: relative;
				width: 50%;
			}
				.body-measurement-chart-content-input-container p {
					font-size: 10px;
					margin: 0px;
					color: #ffffff;
					font-weight: 600;
					margin-top: -8px;
				}
				.body-measurement-chart-content-input-container .form-input-container .form-text-input-container {
					border-width: 1px !important;
					border-color: #5B62FF;
					box-shadow: 0 2px 6px 2px rgba(73,89,193, 0.2);
					margin-top: -4px;
				}
				.body-measurement-chart-content-input-container .form-input-container .form-text-input-container .form-text-input-label {
					color: red !important;
				}
				.body-measurement-chart-content-input-units-container {
					position: absolute;
					right: 12px;
					top: 20px;
				}
					.body-measurement-chart-content-input-units-container p {
						font-size: 13.5px;
						color: #ffffff;
						margin: 0px;
					}
				.body-measurement-chart-content-input-button {
					width: calc(50% - 8px);
					margin-left: 8px;
					font-size: 13.5px;
					background-color: #5B62FF;
					border-radius: 5px;
					padding: 8px 12px;
					margin-top: 11px;
					height: 34px;
					box-shadow: 0 2px 6px 2px rgba(0, 0, 100, 0.1);
					-webkit-transition: all 0.15s;
				  	-moz-transition: all 0.15s;
				  	transition: all 0.15s;
				}
				.body-measurement-chart-content-input-button:hover {
					background: #5C6BC9;
					cursor: pointer;
					-webkit-transition: all 0.15s;
				  	-moz-transition: all 0.15s;
				  	transition: all 0.15s;
				}
					.body-measurement-chart-content-input-button p {
						width: 100%;
						text-align: center;
						margin: 0px;
						color: #ffffff;
					}
			.body-measurement-chart-content-error-container {
				width: 100%;
				padding-top: 32px;
			}
				.body-measurement-chart-content-error-container  p {
					font-size: 13.5px;
					width: 100%;
					margin: 0px;
					color: #F02E4A;
				}


    .body-measurement-chart-body-container {
    	width: 50%;
    	position: relative;
    	/*padding-left: 24px;*/
    }
    	.body-measurement-chart-body-top-container {
    		width: 100%;
    		padding: 8px 0px;
    		padding-bottom: 12px;
    		border-bottom: solid;
    		border-width: 1px;
    		border-color: #2B2B30;
    		display: flex;
    		margin-bottom: 12px;
    	}
    		.body-measurement-chart-body-top-col {
    			width: 33.3%;
    			display: flex;
    			padding: 6px 4px;
    			border-radius: 5px;
    			border: solid;
	    		border-width: 1px;
	    		border-color: rgba(0,0,0,0);
    			-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
    		}
    		.body-measurement-chart-body-top-col:hover {
    			cursor: pointer;
    			background: rgba(0,0,0,0.2);
    			-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
    		}
    			

	    		.body-measurement-chart-body-top-col-icon-container {

	    		}
	    			.body-measurement-chart-body-top-col-icon {
	    				font-size: 15px !important;
	    				color: #6C6C6C;
		    		}
		    	.body-measurement-chart-body-top-col-text-container {
		    		padding-left: 8px;
		    	}
		    		.body-measurement-chart-body-top-col-text-container p {
		    			margin: 0px;
		    			padding-top: 2px;
						color: #6C6C6C;
						font-size: 10px;
						font-weight: 600;
		    		}
		    		.body-measurement-chart-body-top-col-text-container h4 {
		    			margin: 0px;
		    			padding-top: 2px;
						color: #ffffff;
						font-size: 13.5px;
						font-weight: 600;
		    		}

		   	.body-measurement-chart-body-top-col-selected {
		   		background: rgba(0,0,0,0.5);
		   		border: solid;
		   		border-width: 1px;
		   		border-color: #5B62FF; 

		   	}
		    .body-measurement-chart-body-top-col-selected .body-measurement-chart-body-top-col-icon-container .body-measurement-chart-body-top-col-icon {
    			color: #5B62FF !important;
    		}

    		.body-measurement-chart-body-top-col-error {
    			background: rgba(255,50,50,0.2);
		   		border: solid;
		   		border-width: 1px;
		   		border-color: #F02E4A; 
    		}
    		.body-measurement-chart-body-top-col-error:hover {
    			background: rgba(255,50,50,0.2);
    		}
    		.body-measurement-chart-body-top-col-error .body-measurement-chart-body-top-col-icon-container .body-measurement-chart-body-top-col-icon {
    			color: #F02E4A !important;
    		}
    		.body-measurement-chart-body-top-col-error .body-measurement-chart-body-top-col-text-container p {
    			color: #F02E4A !important;
    		}

    	.body-measurement-chart-body-container-wrapper {
			width: 100%;
			height: 100%;
		}
    	.body-measurement-chart-body-image-container {
    		width: 80%;
    		margin: 0px auto;
    	}
    	.body-measurement-chart-body-overlay-container {
    		position: absolute;
    		height: 100%;
    		width: 80%;
    		left: 10%;
    	}
    	.body-measurement-chart-body-overlay-container-inner {
    		width: 100%;
    		height: 100%;
    		position: relative;
    	}

    	/* ----- body-measurement-chart-body-overlay-outter -----  */
    	.body-measurement-chart-body-overlay-outter {
    		position: absolute;
    		z-index: 2;
    	}
    		.body-measurement-chart-body-overlay-outter-bodyWeight {
    			display: none;
    		}
    		.body-measurement-chart-body-overlay-outter-bodyFatPercentage {
    			display: none;
    		}
    		.body-measurement-chart-body-overlay-outter-leanMass {
    			display: none;
    		}
    		.body-measurement-chart-body-overlay-outter-legLength {
    			display: none;
    		}
    		.body-measurement-chart-body-overlay-outter-torsoLength {
    			display: none;
    		}
    		.body-measurement-chart-body-overlay-outter-reachLength {
    			display: none;
    		}
    		.body-measurement-chart-body-overlay-outter-height {
    			left: calc(50% - 2px);
    			z-index: 0;
    			height: 100%;
    		}
    		.body-measurement-chart-body-overlay-outter-neck {
    			width: 16%;
    			top: 64px;
    			left: calc(42% - 4px);
    		}
    		.body-measurement-chart-body-overlay-outter-shoulders {
    			width: 54%;
    			top: 92px;
    			left: calc(23% - 3px);
    		}
    		.body-measurement-chart-body-overlay-outter-chest {
    			width: 52%;
    			top: 118px;
    			left: calc(32% - 3px);
    		}
    		.body-measurement-chart-body-overlay-outter-arm_L {
    			transform: rotate(12deg);
    			width: 14%;
    			top: 144px;
    			left: calc(20% - 3px);
    		}
    		.body-measurement-chart-body-overlay-outter-arm_R {
    			transform: rotate(-12deg);
    			width: 14%;
    			top: 144px;
    			right: calc(20% + 3px);
    		}
    		.body-measurement-chart-body-overlay-outter-forearm_L {
    			width: 12%;
    			top: 210px;
    			left: calc(15% - 3px);
    		}
    		.body-measurement-chart-body-overlay-outter-forearm_R {
    			width: 12%;
    			top: 192px;
    			left: calc(72% - 3px);
    		}
    		.body-measurement-chart-body-overlay-outter-waistAtMinimum {
    			width: 58%;
    			top: 170px;
    			left: calc(34% - 3px);
    		}
    		.body-measurement-chart-body-overlay-outter-waistAtBellyButton {
    			width: 58%;
    			top: 184px;
    			left: calc(10% - 3px);
    		}
    		.body-measurement-chart-body-overlay-outter-glutes {
    			width: 60%;
    			top: 214px;
    			left: calc(32% - 3px);
    		}
    		.body-measurement-chart-body-overlay-outter-hips {
    			display: none;
    		}
    		.body-measurement-chart-body-overlay-outter-thighAtHamTie_L {
    			transform: rotate(10deg);
    			width: 18%;
    			top: 234px;
    			left: calc(31% - 3px);
    		}
    		.body-measurement-chart-body-overlay-outter-thighAtHamTie_R {
    			transform: rotate(-10deg);
    			width: 18%;
    			top: 234px;
    			left: calc(50% - 3px);
    		}
    		.body-measurement-chart-body-overlay-outter-thighMid_L {
    			width: 18%;
    			top: 284px;
    			left: calc(28% - 3px);
    		}
    		.body-measurement-chart-body-overlay-outter-thighMid_R {
    			width: 18%;
    			top: 284px;
    			left: calc(53% - 3px);
    		}
    		.body-measurement-chart-body-overlay-outter-calf_L {
    			width: 16%;
    			top: 384px;
    			left: calc(30% - 3px);
    		}
    		.body-measurement-chart-body-overlay-outter-calf_R {
    			width: 16%;
    			top: 384px;
    			left: calc(55% - 3px);
    		}




    	.body-measurement-chart-body-overlay-outter:hover {
    		cursor: pointer;
    	}
    	.body-measurement-chart-body-overlay-outter:hover .body-measurement-chart-body-overlay-line-long {
    		background: white !important;
    	}
    	.body-measurement-chart-body-overlay-outter:hover .body-measurement-chart-body-overlay-line-a {
    		background: white !important;
    	}
    	.body-measurement-chart-body-overlay-outter:hover .body-measurement-chart-body-overlay-line-b {
    		background: white !important;
    	}
    	.body-measurement-chart-body-overlay-outter:hover .body-measurement-chart-body-overlay-line-b {
    		background: white !important;
    	}



    	.body-measurement-chart-body-overlay-inner {
    		position: relative;
    		width: 100%;
    		-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
    	}

    		.body-measurement-chart-body-overlay-inner-neck {
    			
    		}


    	.body-measurement-chart-body-overlay-inner-completed .body-measurement-chart-body-overlay-line-long {
    		background: #ffffff !important;
    	}
    	.body-measurement-chart-body-overlay-inner-completed .body-measurement-chart-body-overlay-line-a {
    		background: #ffffff !important;
    	}
    	.body-measurement-chart-body-overlay-inner-completed .body-measurement-chart-body-overlay-line-b {
    		background: #ffffff !important;
    	}
    	.body-measurement-chart-body-overlay-inner-completed .body-measurement-chart-body-overlay-measurement p {
    		color: #ffffff !important;
    	}

    	.body-measurement-chart-body-overlay-inner-selected .body-measurement-chart-body-overlay-line-long {
    		background: rgba(112,138,240,1.0) !important;
    		box-shadow: 0 2px 6px 0px rgba(12, 38, 120, 0.2);
    	}
    	.body-measurement-chart-body-overlay-inner-selected .body-measurement-chart-body-overlay-line-a {
    		background: rgba(112,138,240,1.0) !important;
    		box-shadow: 0 2px 6px 0px rgba(12, 38, 120, 0.2);
    	}
    	.body-measurement-chart-body-overlay-inner-selected .body-measurement-chart-body-overlay-line-b {
    		background: rgba(112,138,240,1.0) !important;
    		box-shadow: 0 2px 6px 0px rgba(12, 38, 120, 0.2);
    	}
    	.body-measurement-chart-body-overlay-inner-selected:hover .body-measurement-chart-body-overlay-line-long {
    		background: rgba(112,138,240,1.0) !important;
    		box-shadow: 0 2px 6px 0px rgba(12, 38, 120, 0.2);
    	}
    	.body-measurement-chart-body-overlay-inner-selected:hover .body-measurement-chart-body-overlay-line-a {
    		background: rgba(112,138,240,1.0) !important;
    		box-shadow: 0 2px 6px 0px rgba(12, 38, 120, 0.2);
    	}
    	.body-measurement-chart-body-overlay-inner-selected:hover .body-measurement-chart-body-overlay-line-b {
    		background: rgba(112,138,240,1.0) !important;
    		box-shadow: 0 2px 6px 0px rgba(12, 38, 120, 0.2);
    	}

    	.body-measurement-chart-body-overlay-inner-selected .body-measurement-chart-body-overlay-measurement p {
    		color: rgba(112,138,240,1.0) !important;
    	}
    	.body-measurement-chart-body-overlay-inner-selected .body-measurement-chart-body-overlay-measurement {
    		background: rgba(0,0,0,0.8);
    	}
    	.body-measurement-chart-body-overlay-inner-completed .body-measurement-chart-body-overlay-measurement {
    		background: rgba(0,0,0,0.5);
    	}

    	.body-measurement-chart-body-overlay-inner-error .body-measurement-chart-body-overlay-line-long {
    		background: #F02E4A !important;
    		box-shadow: 0 2px 6px 0px rgba(120, 38, 12, 0.2);
    	}
    	.body-measurement-chart-body-overlay-inner-error .body-measurement-chart-body-overlay-line-a {
    		background: #F02E4A !important;
    		box-shadow: 0 2px 6px 0px rgba(120, 38, 12, 0.2);
    	}
    	.body-measurement-chart-body-overlay-inner-error .body-measurement-chart-body-overlay-line-b {
    		background: #F02E4A !important;
    		box-shadow: 0 2px 6px 0px rgba(120, 38, 12, 0.2);
    	}

    	.body-measurement-chart-body-overlay-inner-error .body-measurement-chart-body-overlay-measurement {
    		background: rgba(255,50,50,0.3) !important;
    	}
    	.body-measurement-chart-body-overlay-inner-error .body-measurement-chart-body-overlay-measurement p {
    		color: #F02E4A;
    	}




    	.body-measurement-chart-body-overlay-line-long {
    		position: absolute;
    		left: 0px;
    		top: 6px;
    		background: #6C6C6C !important;
    		width: 100%;
    		height: 4px;
    		border-radius: 2px;   
    		-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
    	}
    		.body-measurement-chart-body-overlay-line-long-height {
	    		display: none;
	    	}

    	.body-measurement-chart-body-overlay-line-a {
    		position: absolute;
    		left: 0px;
    		top: 0px;
    		background: #6C6C6C !important;
    		width: 4px;
    		height: 16px;
    		border-radius: 2px;    
    		-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
    	}
    		.body-measurement-chart-body-overlay-line-a-height {
    			height: 50px;
    			transform: rotate(90deg);
    			top: -15px;
    			left: -3px;
    		}
    		.body-measurement-chart-body-overlay-line-a-waistAtBellyButton {
	    		left: 38%;
	    	}

    	.body-measurement-chart-body-overlay-line-b {
    		position: absolute;
    		right: 0px;
    		top: 0px;
    		background: #6C6C6C !important;
    		width: 4px;
    		height: 16px;
    		border-radius: 2px;   
    		-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s; 		
    	}
    		.body-measurement-chart-body-overlay-line-b-height {
    			height: 100px;
    			transform: rotate(90deg);
    			top: 448px;
    		}
    		.body-measurement-chart-body-overlay-line-b-chest {
	    		right: 32%;
	    	}
	    	.body-measurement-chart-body-overlay-line-b-waistAtMinimum {
	    		right: 45%;
	    	}
	    	.body-measurement-chart-body-overlay-line-b-glutes {
	    		right: 40%;
	    	}

    	/* ----- body-measurement-chart-body-overlay-measurement ----- */
    	.body-measurement-chart-body-overlay-measurement {
    		/*right: -108px;*/
    		left: calc(100% + 8px);
    		top: -2px;
    		position: absolute;
    		/*width: 100px;*/
    		padding: 4px 8px;
    		border-radius: 5px;
    		background: rgba(0,0,0,0.2);
    	}
    		.body-measurement-chart-body-overlay-measurement p {
	    		font-size: 10px;
	    		font-weight: 700;
	    		margin: 0px;
	    		width: 100%;
	    		white-space: nowrap;
			    overflow: hidden;
			    text-overflow: ellipsis;
	    		color: #6C6C6C;
	    	}

	    	.body-measurement-chart-body-overlay-measurement-height {
	    		right: calc(100% + 30px) !important;
	    		left: auto;
	    	}
	    		.body-measurement-chart-body-overlay-measurement-height p {
	    			text-align: right;
	    		}

	    	.body-measurement-chart-body-overlay-measurement-neck {
	    		right: calc(100% + 4px) !important;
	    		left: auto;
	    	}
	    		.body-measurement-chart-body-overlay-measurement-neck p {
	    			text-align: right;
	    		}

	    	.body-measurement-chart-body-overlay-measurement-arm_L {
	    		transform: rotate(-12deg);
	    		right: calc(100% + 4px) !important;
	    		left: auto;
	    		top: 12px;
	    	}
	    		.body-measurement-chart-body-overlay-measurement-arm_L p {
	    			text-align: right;
	    		}
	    	.body-measurement-chart-body-overlay-measurement-arm_R {
	    		transform: rotate(12deg);
	    		/*right: -108px;*/
	    		top: 12px;
	    	}
	    		.body-measurement-chart-body-overlay-measurement-arm_R p {
	    			/*text-align: left;*/
	    		}
	    	.body-measurement-chart-body-overlay-measurement-forearm_L {
	    		right: calc(100% + 4px) !important;
	    		left: auto;
	    		top: 0px;
	    	}
	    		.body-measurement-chart-body-overlay-measurement-forearm_L p {
	    			text-align: right;
	    		}
	    	.body-measurement-chart-body-overlay-measurement-waistAtBellyButton {
	    		right: calc(100% + 4px) !important;
	    		left: auto;
	    		top: 0px;
	    	}
	    		.body-measurement-chart-body-overlay-measurement-waistAtBellyButton p {
	    			text-align: right;
	    		}
	    	.body-measurement-chart-body-overlay-measurement-thighAtHamTie_L {
	    		transform: rotate(-10deg);
	    		right: calc(100% + 4px) !important;
	    		left: auto;
	    		top: 12px;
	    	}
	    		.body-measurement-chart-body-overlay-measurement-thighAtHamTie_L p {
	    			text-align: right;
	    		}
	    	.body-measurement-chart-body-overlay-measurement-thighAtHamTie_R {
	    		transform: rotate(10deg);

	    		top: 12px;
	    	}
	    		.body-measurement-chart-body-overlay-measurement-thighAtHamTie_R p {
	    			
	    		}
	    	.body-measurement-chart-body-overlay-measurement-thighMid_L {
	    		right: calc(100% + 4px) !important;
	    		left: auto;
	    		top: 0px;
	    	}
	    		.body-measurement-chart-body-overlay-measurement-thighMid_L p {
	    			text-align: right;
	    		}
	    	.body-measurement-chart-body-overlay-measurement-calf_L {
	    		right: calc(100% + 4px) !important;
	    		left: auto;
	    		top: 0px;
	    	}
	    		.body-measurement-chart-body-overlay-measurement-calf_L p {
	    			text-align: right;
	    		}




