.multi-select-container {
	width: 100%;
}

	.multi-select-container-selected {
		width: 100%;
		background: #1F1F21;
		border-radius: 5px;
		padding: 8px 24px;
		margin-bottom: 2px;
		box-shadow: 0 2px 6px 4px rgba(0, 0, 0, 0.06);
		display: flex;
	}	
		.multi-select-container-selected h3 {
			font-size: 13.5px;
			font-weight: 500;
			margin: 0px;
			margin-top: 2px;
			width: 100%;
			color: #ffffff;
		}
		.multi-select-container-selected p {
			font-size: 13.5px;
			font-weight: 300;
			margin: 0px;
			color: #ffffff;
		}


		.multi-select-container-title {
			width: 33%;
		}
		.multi-select-container-title-full {
			width: 100%;
		}

	.multi-select-container-unselected {
		width: 100%;
		padding: 8px 24px;
		margin-bottom: 2px;
		display: flex;
		border-radius: 5px;
		-webkit-transition: all 0.15s;
	  	-moz-transition: all 0.15s;
	  	transition: all 0.15s;
	}
	.multi-select-container-unselected:hover {
		background: #1F1F21;
		cursor: pointer;
		
		-webkit-transition: all 0.15s;
	  	-moz-transition: all 0.15s;
	  	transition: all 0.15s;
	}
		.multi-select-container-unselected h3 {
			font-size: 13.5px;
			font-weight: 500;
			margin: 0px;
			margin-top: 2px;
			width: 100%;
			color: #ffffff;
		}
		.multi-select-container-unselected p {
			font-size: 13.5px;
			font-weight: 300;
			margin: 0px;
			color: #ffffff;
		}
		.multi-select-check-container {
			width: 20px;
			height: 20px;
			border-radius: 5px;
			margin-left: auto;
		}
		.multi-select-check-container-selected {
			background: #5B62FF;
		}
		.multi-select-check-container-unselected {
			background: rgba(0,0,0,0.2);
		}

			.multi-select-check-container-icon-container {
				padding-left: 4.5px;
			}
				.multi-select-check-container-icon {
					font-size: 11px !important;
				}

