.new-workout-workout-details-container {
	display: flex;
}

.new-workout-workout-details-content {
	display: flex;
}

.new-workout-workout-details-content p {
	padding-top: 4px;
	padding-left: 6px;
	margin-right: 12px !important;
}

.new-workout-workout-name-container {
	height: 100%;
	width: calc(50% - 50px);
	max-width: calc(50% - 50px);
	overflow: hidden;
	display: block;
	padding-top: 8px;
}

.new-workout-workout-name-container h2 {
	margin: 0px;
	min-height: 28px;
	overflow: hidden;
}

.new-workout-workout-name-container p {
	margin: 0px;
	font-size: 14px;
	opacity: 0.7;
}

.new-workout-workout-exercise-tag-container {
	max-height: 100%;
	max-width: 100%;
	padding-top: 4px;
}

.new-workout-workout-start-button-container {
	width: 100%;
}