/* .schedule-main- */

.schedule-main {
	
}
	.schedule-main-calendar-header {
		width: 100%;
		height: 80px;
		display: flex;
		padding-right: 36px;
	}
		.schedule-main-calendar-header-filter-container {
			margin-left: auto;
			float: right;
			display: flex;
			margin-top: 20px;
			position: relative;
		}
			.schedule-main-calendar-header-filter-button {
				padding: 0px;
				background: #1F1F21;
				border: solid;
				border-width: 1px;
				border-color: #2B2B30;
				display: flex;
				border-radius: 5px;
				white-space: nowrap;
				height: 36px;
				box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.12);
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
			.schedule-main-calendar-header-filter-button:hover {
				background: #1F1F21;
				cursor: pointer;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
			.schedule-main-calendar-header-filter-button-clients {

			}
				.schedule-main-calendar-header-filter-button p {
				    font-size: 13.5px;
				    color: #ffffff;
				    margin: 0px;
				    padding-top: 2px;
				}
				.schedule-main-calendar-header-filter-button-clients-inner {
					display: flex;
					padding: 6px 12px;
				}
					.schedule-main-calendar-header-filter-button-clients-inner .assign-modal-body-client-name-input-container-selected-client-remove-container {
						margin-left: 8px;
						margin-top: 0px;
						padding-top: 4px;
					}

		.schedule-main-calendar-header-filter-menu {
			top: 42px !important;
			right: 0px !important;
			width: 400px;
			min-width:  400px;
			border: solid;
			border-width: 1px;
			border-color: #2B2B30;
		}
			.schedule-main-calendar-header-filter-menu-search-container {
				border-bottom: solid;
				border-width: 1px;
				border-color: #2B2B30;
				padding: 8px 12px;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
				.schedule-main-calendar-header-filter-menu-highlighted {
					border-color: #5B62FF;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
			.schedule-main-calendar-header-filter-menu-list-container {
				padding: 12px;
				width: 100%;
				height: 182px;
				overflow-y: scroll;
			}
	.schedule-main-calendar-content {
		width: 100%;
		height: calc(100vh - 132px);
		/*overflow-y: scroll;*/
	}
