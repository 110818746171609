.current-exercise-modal-background {
	padding-top: calc(50vh - 300px);
}

.current-exercise-modal-container {
	height: 80%;
	min-height: 600px;
	margin: auto;
	margin-top: 0px;
	min-width: 800px;
	width: 80%;
	background: #161719;
	border-radius: 5px;
	border: solid;
	border-width: 0.5px;
	border-color: #2B2B30;
	box-shadow: 0 0px 100px 12px rgba(0, 0, 0, 0.5);
	position: relative;
}
	.current-exercise-modal-header-container {
		width: 100%;
		height: 68px;
		/*background: rgba(255,0,0,0.4);*/
		display: flex;
		padding: 24px 24px;
		border-bottom: solid;
		border-width: 1px;
		border-color: #2B2B30;
	}
		.current-exercise-modal-header-set-id-container {
			width: 32px;
			height: 32px;
			border-radius: 5px;
			background: rgba(127,72,192, 0.3);
			padding: 8.5px 0px;
		}
			.current-exercise-modal-header-set-id-container h4 {
				color: #7F48C0;
				font-size: 13.5px;
				width: 100%;
				text-align: center;
				margin: 0px;
			}
		.current-exercise-modal-header-set-title-container {
			display: flex;
		}
			.current-exercise-modal-header-set-title-container h4 {
				font-size: 18px;
				color: #ffffff;
				margin: 0px;
			}
			.current-exercise-modal-header-set-title-container p {
				font-size: 10px;
				color: #6C6C6C;
				margin: 0px;
			}
			.current-exercise-modal-header-record-icon {
				height: 12px;
				width: 12px;
				border-radius: 6px;
				background: #FF4D4D;
				opacity: 1.0;
				margin-right: 12px;
				margin-top: 4px;
				animation: blink 1.67s linear infinite;
	        }
	            @keyframes blink {
	                0% { opacity: 1.0; }
	                35% { opacity: 1.0; }
	                50% { opacity: 0.0; }
	                85% { opacity: 0.0; }
	                100% { opacity: 1.0; }
	            }
			.current-exercise-modal-header-buttons-container {
				margin-left: auto;
				float: right;
				display: flex;
				position: relative;
			}
				.current-exercise-modal-flag-menu {
					right: 108px;
					top: 34px;
				}
				.current-exercise-modal-all-flags-menu {
					right: 32px !important;
					top: 64px !important;
				}
				.current-exercise-modal-header-button {
					display: flex;
					padding: 7px 12px;
					padding-right: 16px;
					height: 30px;
					border-radius: 5px;
					margin-left: 8px;
					margin-top: -4px;
					box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.12);
				} 
					.current-exercise-modal-header-button p {
					    font-size: 12px;
					    color: white ;
					    margin: 0px;
					}
				.current-exercise-modal-header-button-red {
					background: #FF4D4D;
					padding-right: 8px;
				}
					.current-exercise-modal-header-button-inner {
						display: flex;
					}
				.current-exercise-modal-header-button-grey {
					background: #1F1F21;
					border: solid;
					border-width: 1px;
					border-color: #2B2B30;
				}
					.current-exercise-modal-header-button-grey p {
					    font-size: 12px;
					    color: #9DA0AD;
					    margin: 0px;
					}
				.current-exercise-modal-header-button-blue {
					background: #5B62FF;
				}
					.current-exercise-modal-header-button-icon {
						font-size: 15px !important;
						margin-right: 6px;
					}
					.current-exercise-modal-header-button-dropdown-option {
						border-left: solid;
						border-width: 1px;
						border-color: rgba(255, 255, 255, 0.3);
						padding-left: 6px;
						padding-top: 6px;
						margin-left: 8px;
						height: 30px;
						margin-top: -7px;
					}
						.current-exercise-modal-header-button-dropdown-option-icon {
							font-size: 14px !important;

						}

				.current-exercise-modal-body-container {
					width: 100%;
					display: flex;
					height: calc(100% - 68px);
				}
					.current-exercise-modal-body-reps-container {
						width: 37%;
					}
						.current-exercise-modal-body-reps-header-container {
							width: 100%;
							display: flex;
						}
							.current-exercise-modal-body-reps-header-col-container {
								width: 40%;
								padding: 24px;
								padding-right: 0px;
							}
								.current-exercise-modal-body-reps-header-col-container h1 {
								    font-size: 28px;
								    color: #ffffff;
								    margin: 0px;
								}
								.current-exercise-modal-body-reps-header-col-container p {
								    font-size: 12px;
								    color: #9DA0AD;
								    margin: 0px;
								}
							.current-exercise-modal-body-reps-header-col-container-time {
								width: 60%;
							}
						.current-exercise-modal-body-reps-list-header-container {
							width: 100%;
							display: flex;
							background: #1F1F21;
							padding: 16px 24px;
							padding-right: 12px;
						}
							.current-exercise-modal-body-reps-list-header-text-container {

							}
								.current-exercise-modal-body-reps-list-header-text-container h4 {
								    font-size: 13.5px;
								    color: #ffffff;
								    margin: 0px;
								}
							.current-exercise-modal-body-reps-list-header-button-container {
								margin-left: auto;
								float: right;
								display: flex;
							}
								.current-exercise-modal-body-reps-list-header-button-container p {
								    font-size: 12px;
								    color: #5B62FF;
								    margin: 0px;
								}
								.current-exercise-modal-body-reps-list-header-button-icon {
									font-size: 11px !important;
								    color: #5B62FF;
								    margin-top: 2px;
								    margin-left: 4px;
								}
							.current-exercise-modal-body-reps-list-container {
								width: 100%;
								height: 100%;
							}
							.current-exercise-modal-body-reps-list-container-empty {
								width: 100%;
								height: 100%;
								padding-top: 15vh;
							}
								.current-exercise-modal-body-reps-list-container-empty h4 {
								    font-size: 13.5px;
								    color: #ffffff;
								    margin: 0px;
								    width: 100%;
								    text-align: center;
								}
								.current-exercise-modal-body-reps-list-container-empty p {
								    font-size: 12px;
								    color: #9DA0AD;
								    margin: 0px;
								    width: 70%;
								    text-align: center;
								    margin: 0px auto;
								    margin-top: 8px;
								}
								.current-exercise-modal-body-reps-list-descs-container {
									width: 100%;
									display: flex;
									padding: 8px 24px;
								}
									.current-exercise-modal-body-reps-list-col {
										font-size: 11px;
									    color: #9DA0AD;
									    margin: 0px;
									}
									.current-exercise-modal-body-reps-list-col-0 {
										width: 18%;
									}
									.current-exercise-modal-body-reps-list-col-1 {
										width: 25%;
									}
									.current-exercise-modal-body-reps-list-col-2 {
										width: 25%;
									}
									.current-exercise-modal-body-reps-list-col-3 {
										display: none;
									}
									.current-exercise-modal-body-reps-list-col-4 {
										width: 32%;
									}
								.current-exercise-modal-body-reps-list-content {
									width: 100%;
									height: calc(100% - 174px);
									overflow-y: scroll;
									padding-bottom: 16px;
									z-index: 0;
									position: relative;
								}
									.current-exercise-modal-body-reps-list-cell {
										width: 100%;
										padding: 8px 24px;
										display: flex;
										border-bottom: solid;
										border-width: 1px;
										border-color: #2B2B30;
										position: relative;
									}
									.current-exercise-modal-body-reps-list-cell-current-bar {
										position: absolute;
										height: 100%;
										width: 3px;
										border-radius: 1.5px;
										background: #5B62FF;
										top: 0px;
										left: 0px;
									}
										.current-exercise-modal-body-reps-list-cell-selected {
											background: #1F1F21;
										}
										.current-exercise-modal-body-reps-list-col-cell {

										}
											.current-exercise-modal-body-reps-list-col-cell h4 {
											    font-size: 15px;
											    padding-top: 2px;
											    color: #ffffff;
											    margin: 0px;
											}
										.current-exercise-modal-body-reps-list-col-cell-buttons {
											display: flex;
										}
											.current-exercise-modal-body-reps-list-col-cell-form-button {
												width: 24px;
												height: 24px;
												border: solid;
												border-width: 1px;
												border-color: #2B2B30;
												border-radius: 5px;
												margin-right: 4px;
												margin-top: -2px;
												padding: 1px 5px;
											}
												.current-exercise-modal-body-reps-list-col-cell-form-button-red {
													background: rgba(255,0,0,0.2);
													border-color: #FF4D4D;
												}
												.current-exercise-modal-body-reps-list-col-cell-form-button-green {
													background: rgba(91,255,98,0.2);
													border-color: #5BFF7F;
												}
												.current-exercise-modal-body-reps-list-col-cell-form-button-blue {
													background: rgba(91,98,255,0.2);
													border-color: #5B62FF;
												}


												.current-exercise-modal-body-reps-list-col-cell-form-button-icon {
													font-size: 12px !important;
													color: #6C6C6C;
												}
												.current-exercise-modal-body-reps-list-col-cell-form-button-icon-red {
													color: #FF4D4D;
												}
												.current-exercise-modal-body-reps-list-col-cell-form-button-icon-green {
													color: #5BFF7F;
												}
												.current-exercise-modal-body-reps-list-col-cell-form-button-icon-blue {
													color: #5B62FF;
												}



					.current-exercise-modal-body-content-container {
						width: 63%;
						border-left: solid;
						border-width: 1px;
						border-color: #2B2B30;
						box-shadow: -24px 0px 24px 12px rgba(0, 0, 0, 0.08);
						height: 100%;
						z-index: 1;
						position: relative;
					}
						.current-exercise-modal-body-content-header-container {
							width: 100%;
							padding: 24px;
							padding-bottom: 0px;
							border-bottom: solid;
							border-width: 1px;
							border-color: #2B2B30;
						}
							.current-exercise-modal-body-content-header-container h1 {
							    font-size: 28px;
							    color: #ffffff;
							    margin: 0px;
							}
							.current-exercise-modal-body-content-header-page-names-container {
								width: 100%;
								display: flex;
								padding-top: 8px;
							}
								.current-exercise-modal-body-content-header-page-name-cell {
									padding: 8px 0px;
									padding-right: 32px;
									position: relative;
								}
									.current-exercise-modal-body-content-header-page-name-cell p {
									    font-size: 12px;
									    color: #6C6C6C;
									    margin: 0px;
									}
									.current-exercise-modal-body-content-header-page-name-cell-selected p {
									    color: #ffffff;
									    font-weight: 500;
									}

									.current-exercise-modal-body-content-header-page-name-cell-selected-bar {
										width: calc(100% - 32px);
										height: 2px;
										border-radius: 1px;
										background: #5B62FF;
										position: absolute;
										bottom: -1px;
										left: 0px;
									}
								.current-exercise-modal-body-content-header-form-selector-container {
									position: absolute;
									top: 24px;
									right: 32px;
									display: flex;
									padding: 6px 12px;
									border-radius: 5px;
									border: solid;
									border-width: 1px;
									height: 32px;
									box-shadow: 0px 0px 12px 6px rgba(0, 0, 0, 0.24);
								}
								.current-exercise-modal-body-content-header-form-selector-container-red {
									background: rgba(255,0,0,0.2);
									border-color: #FF4D4D;
								}
								.current-exercise-modal-body-content-header-form-selector-container-blue {
									background: rgba(91,98,255,0.2);
									border-color: #5B62FF;
								}
								.current-exercise-modal-body-content-header-form-selector-container-green {
									background: rgba(91,255,98,0.2);
									border-color: #5BFF7F;
								}
									.current-exercise-modal-body-content-header-form-selector-container-icon-container {
										padding-right: 8px;
									}
									.current-exercise-modal-body-content-header-form-selector-container-icon-container-red {
										color: #FF4D4D;
									}
									.current-exercise-modal-body-content-header-form-selector-container-icon-container-blue {
										color: #5B62FF;
									}
									.current-exercise-modal-body-content-header-form-selector-container-icon-container-green {
										color: #5BFF7F;
									}
										.current-exercise-modal-body-content-header-form-selector-container-icon {
											font-size: 14px !important;
											margin-top: 1px;
										}
									.current-exercise-modal-body-content-header-form-selector-text-container {

									}
										.current-exercise-modal-body-content-header-form-selector-text-container p {
										    font-size: 13.5px;
										    color: white;
										    margin: 0px;
										    font-weight: 500;
										}
										.current-exercise-modal-body-content-header-form-selector-container-red .current-exercise-modal-body-content-header-form-selector-text-container p {
											color: #FF4D4D;
										}
										.current-exercise-modal-body-content-header-form-selector-container-blue .current-exercise-modal-body-content-header-form-selector-text-container p {
											color: #7278F2;
										}
										.current-exercise-modal-body-content-header-form-selector-container-green .current-exercise-modal-body-content-header-form-selector-text-container p {
											color: #5BFF7F;
										}

							.current-exercise-modal-body-content-body-section-container {
								padding-bottom: 24px;
								margin-bottom: 24px;
								border-bottom: solid;
								border-width: 1px;
								border-color: #2B2B30;
							}
								.current-exercise-modal-body-content-body-section-container .intra-workout-section-header-container {
									padding: 0px;
									display: flex;
								}
								.current-exercise-modal-body-content-body-container {
									padding: 24px;
									width: 100%;
									height: calc(100% - 82px);
								}
									.current-exercise-modal-body-content-body-chart-container {
										width: 100%;
										height: 220px;
									}
								.current-exercise-modal-body-content-body-section-more-info-button {
									display: flex;
									margin-left: auto;
									float: right;
								}
									.current-exercise-modal-body-content-body-section-more-info-button p {
									    font-size: 12px;
									    color: #7278F2;
									    margin: 0px;
									}
									.current-exercise-modal-body-content-body-section-more-info-button-icon {
										font-size: 11px !important;
										color: #7278F2;
										margin-top: 2px;
										margin-left: 6px;
									}
							.current-exercise-modal-all-flags-menu-row-icon-container {
								padding-top: 2px;
							}
								.current-exercise-modal-all-flags-menu-row-icon {
									font-size: 13px !important;
								}
								.current-exercise-modal-all-flags-menu-row-icon-green {
									color: #5BFF7F;
								}
								.current-exercise-modal-all-flags-menu-row-icon-blue {
									color: #5B62FF;
								}
							.current-exercise-modal-all-flags-menu-flags-container {
								width: calc(100% - 24px);
								margin-left: 12px;
								border-top: solid;
								border-width: 0.5px;
								border-color: #2B2B30;
								margin-top: 8px;
								padding-top: 12px;
							}
								.current-exercise-modal-all-flags-menu-flags-container h4 {
								    font-size: 13.5px;
								    color: #FF4D4D;
								    margin: 0px;
								    margin-bottom: 4px;
								    padding-left: 2px;
								}
								.current-exercise-modal-all-flags-menu-flags-container .workout-plan-list-cycle-overview-header-card-menu-row {
									padding-left: 0px;
									padding-right: 0px;
								}
								.current-exercise-modal-all-flags-menu-flags-container .workout-plan-list-cycle-overview-header-card-menu-row p {
									padding: 0px;
								}









