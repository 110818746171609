/* .my-profile-information-section */

.my-profile-information-section {
	width: 100%;
	display: flex;
	margin-bottom: 12px;
}
	.my-profile-information-section-number-container {
		width: 50px;
		padding-top: 12px;
	}
		.my-profile-information-section-number-container h2 {
		    font-size: 24px;
		    color: #6C6C6C;
		    margin: 0px;
		}

	.my-profile-information-section-card {
		width: calc(100% - 50px);
		background: #1F1F21;
		border: solid;
		border-width: 1px;
		border-color: #2B2B30;
		border-radius: 5px;
		box-shadow: 0px 0px 12px 6px rgba(0, 0, 0, 0.08);
		padding: 16px;
	}
		.my-profile-information-section-card-header {
			width: 100%;
			display: flex;
		}
			.my-profile-information-section-card-header-text {

			} 
				.my-profile-information-section-card-header-text h2 {
				    font-size: 18px;
				    color: white;
				    margin: 0px;
				}
			.my-profile-information-section-card-header-text-frozen {
				padding-top: 2px;
				cursor: pointer;
			}

			.my-profile-information-section-card-header-text-editable {
				width: calc(100% - 40px);
			}
				.my-profile-information-section-card-header-text-editable-inner {
	                border-bottom: dotted;
	                border-width: 2px;
	                border-color: #4959C1;
	                margin-bottom: 2px;
	                min-height: 18px;
	                width: 100%;
	            }
	                .my-profile-information-section-card-header-text-editable-inner ion-textarea {
	                    font-size: 18px;
	                    color: white;
	                    font-weight: 500;
	                    min-height: 18px;
	                    margin-top: 2px;
	                    margin-bottom: 2px;
	                    --placeholder-color: #5C6BC9;
	                    --placeholder-opacity: 1.0;
	                    --padding-start: 0px;
	                    --padding-end: 0px;
	                    --padding-top: 0px;
	                    --padding-bottom: 0px;
	                }
	                	.my-profile-information-section-card-header-text-editable-inner textarea {
	                		min-height: 32px;
	                		height: 32px;
	                		margin: 0px;
	                	}
	                .my-profile-information-section-card-header-text-editable-inner ion-input {
	                    font-size: 13.5px;
	                    color: rgba(112,138,240,1.0);
	                    font-weight: 700;
	                    min-height: 32px;
	                    padding: 0px;
	                    --placeholder-color: #5C6BC9;
	                    --placeholder-opacity: 1.0;
	                }


			.my-profile-information-section-card-header-arrow-container {
				margin-left: auto;
				float: right;
				width: 27px;
				height: 27px;
				background: rgba(0, 0, 0, 0.2);
				border-radius: 5px;
				padding: 5px 5px;
			}
				.my-profile-information-section-card-header-arrow-icon {
					font-size: 18px !important;
					color: #6C6C6C;
				}

	.my-profile-information-section-card-body {
		width: 100%;
	}
		.my-profile-information-section-card-body-content-container {
			padding-top: 16px;
		}
			.my-profile-information-section-card-body-content-container p {
			    font-size: 12px;
			    font-weight: 500;
			    color: #6C6C6C;
			    margin: 0px;
			}
			.my-profile-information-section-card-body-text-editable-inner {
				min-height: 100px;
				height: auto;
				padding: 8px 0px;
			}
				.my-profile-information-section-card-body-text-editable-inner ion-textarea {
                    font-size: 18px;
                    color: white;
                    font-weight: 400;
                    min-height: 100px;
                    margin-top: 2px;
                    margin-bottom: 2px;
                    height: auto;
                    --placeholder-color: #5C6BC9;
                    --placeholder-opacity: 1.0;
                    --padding-start: 0px;
                    --padding-end: 0px;
                    --padding-top: 0px;
                    --padding-bottom: 0px;
                }
                	.my-profile-information-section-card-body-text-editable-inner textarea {
                		min-height: 100px;
                		height: auto;
                		margin: 0px;
                	}
                .my-profile-information-section-card-body-text-editable-inner ion-input {
                    font-size: 13.5px;
                    color: rgba(112,138,240,1.0);
                    font-weight: 700;
                    min-height: 32px;
                    height: auto;
                    padding: 0px;
                    --placeholder-color: #5C6BC9;
                    --placeholder-opacity: 1.0;
                }

        .my-profile-information-section-card-body-image-container {
        	width: 100%;
        	margin-top: 24px;
        	padding-top: 16px;
        	border-top: solid;
			border-width: 1px;
			border-color: #2B2B30;
        }
        	.my-profile-information-section-card-body-image-content {
        		width: 100%;
        		display: flex;
        		padding-left: 30px;
        		padding-top: 20px;
        	}
        		.my-profile-information-section-card-body-image-content-img-container {
        			width: 90px;
        			border-radius: 5px;
        			overflow: hidden;
        			min-height: 10px;
        		}
        			.my-profile-information-section-card-body-image-content-img-container img {
        				border-radius: 5px;
        			}
        		.my-profile-information-section-card-body-image-content-text-container {
        			padding-left: 20px;
        		}
        			.my-profile-information-section-card-body-image-content-text-container h4 {
					    font-size: 13.5px;
					    font-weight: 500;
					    color: #ffffff;
					    margin: 0px;
					}
					.my-profile-information-section-card-body-image-content-remove-button {
						display: flex;
						padding: 4px 0px;
					}
					.my-profile-information-section-card-body-image-content-remove-button:hover {
						cursor: pointer;
					}
						.my-profile-information-section-card-body-image-content-remove-button p {
						    font-size: 12px;
						    color: #6C6C6C;
						    margin: 0px;
						    margin-left: 8px;
						}
						.my-profile-information-section-card-body-image-content-remove-button-icon {
							font-size: 15px !important;
							color: #6C6C6C;
						}

				.my-profile-information-section-card-body-image-content-upload {

				}
					.my-profile-information-section-card-body-image-content h4 {
						width: 100% !important;
						text-align: center;
					}


        .my-profile-information-section-card-body-button-container {
        	width: 100%;
        	margin-top: 24px;
        	padding-top: 16px;
        	border-top: solid;
			border-width: 1px;
			border-color: #2B2B30;
        }

        	.my-profile-information-section-card-body-option-container {
        		width: 100%;
        		display: flex;
        	}
        		.my-profile-information-section-card-body-option-checkbox-container {
        			width: 30px;
        			padding-top: 3px;
        		}
        		.my-profile-information-section-card-body-option-text-container {
        			width: calc(100% - 30px);
        			padding: 0px 0px;
        		}
        			.my-profile-information-section-card-body-option-text-container h4 {
					    font-size: 18px;
					    color: #ffffff;
					    margin: 0px;
					}
					.my-profile-information-section-card-body-option-text-container p {
					    font-size: 13.5px;
					    color: #9DA0AD;
					    margin: 0px;
					}

		.my-profile-information-section-card-body-remove-container {
			width: calc(100% + 32px);
			margin-left: -16px;
			margin-top: 24px;
        	
        	border-top: solid;
			border-width: 1px;
			border-color: #2B2B30;
		}
			.my-profile-information-section-card-body-remove-button {
				width: 100%;
				display: flex;
				padding: 20px 16px;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
			.my-profile-information-section-card-body-remove-button:hover {
				background: #1F1F21;
				cursor: pointer;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
				.my-profile-information-section-card-body-remove-icon {
					font-size: 18px !important;
					color: #9DA0AD;
				}
				.my-profile-information-section-card-body-remove-button p {
				    font-size: 13.5px;
				    color: #9DA0AD;
				    margin: 0px;
				    margin-top: 1px;
				    margin-left: 6px;
				}















