.form-long-input-container {
	position: relative;
	padding-top: 36px;
	padding-bottom: 12px;
	/*margin-top: -12px;*/
	height: 100%;
	min-height: 100px !important;
	max-height: 100%;
}
	.form-long-input-container p {
		/*font-size: 10px;
		position: absolute;
		top: 4px;
		left: 0px;
		color: #6C6C6C;*/
	}

.form-long-text-input-container {
	/*background-color: rgba(0,0,0,0);
	padding: 11px 18px;
	width: auto;
	min-height: 200px;
	border-radius: 4px;
	border: solid;
	border-color: #5F6C76;
	border-width: 1px !important;*/
	background-color: rgba(0,0,0,0.1);
	padding: 8px 12px;
	padding-bottom: 5px;
	width: auto;
	/*height: 100%;*/

	margin-top: -12px;
	min-height: 100%;
	min-height: 80px !important;
	/*max-height: 100% !important;*/
	border-radius: 5px;
	border: solid;
	border-color: #2B2B30;
	border-width: 1px !important;
	display: flex;
	color: #ffffff;
	font-size: 13.5px;
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
}
.form-long-text-input-container ion-textarea {
	min-height: 100% !important;
}
.form-long-text-input-container textarea {
	min-height: 80px !important;
}
	.form-long-text-input-container-focused {
		border-color: #5B62FF;
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}
	.form-long-text-input-container .textarea-wrapper {
		height: 100%;
	}
		.form-long-text-input-container .textarea-wrapper .native-textarea {
			height: 100%;
		}

.form-long-text-input-container-alert {
	background-color: rgba(100,0,0,0.12);
	padding: 0px 12px;
	width: auto;
	border-radius: 4px;
	border: solid;
	border-color: #F02E4A;
	border-width: 1px !important;
	height: 100%;
	min-height: 100%;
}
	.form-text-input-alert-label {
		color: #F02E4A !important;
	}