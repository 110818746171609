.edit-goal-modal {
/*	height: 50%;
*/	/*min-height: 180px;*/
	/*min-height: 540px;*/
	max-height: 90vh;
	height: 540px;
	margin: auto;
	margin-top: calc(50vh - 320px);
	/*margin-top: 0px;*/
	min-width: 400px;
	width: 40%;
	max-width: 500px;
	background: #161719;
	border-radius: 5px;
	border: solid;
	border-width: 0.5px;
	border-color: #2B2B30;
	/*overflow: hidden;*/
	box-shadow: 0 0px 100px 12px rgba(0, 0, 0, 0.5);
	position: relative;
	padding: 20px 0px;
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
}
	.edit-goal-modal-header {
		/*padding: 24px;*/
		padding: 0px 20px;
		padding-bottom: 20px;
		border-bottom: solid;
		border-width: 1px;
		border-color: #2B2B30;
	}
		.edit-goal-modal-header h4 {
			font-size: 18px;
			color: #ffffff;
			margin: 0px;
		}

		.edit-goal-modal-header .confirm-modal-floating-close {
			top: 12px;
		}

	.edit-goal-modal-content {
		width: 100%;
		margin: 0px auto;
		height: calc(100% - 90px);
		overflow-y: scroll;
		padding: 12px 20px;
		/*background: red;*/
	}
		.edit-goal-modal-content-header {
			padding-top: 12px;
			display: flex;
		}
			.edit-goal-modal-content-header h3 {
				font-size: 16px;
				color: #ffffff;
				margin: 0px;
			}
		.edit-goal-modal-content-header-toggle-container {
			margin-left: 16px;
		}

		.edit-goal-modal-content-body {
			padding: 12px 0px;
		}
			.edit-goal-modal-content-body-input-container {
				width: 100%;
				padding: 12px 0px;
			}
				.edit-goal-modal-content-body-input-container p {
					font-size: 13.5px;
					color: #9DA0AD;
					margin: 0px;
					margin-bottom: 8px;
				}
			.edit-goal-modal-content-body-input-container-metric {
				padding-bottom: 0px;
				position: relative;
			}

				.edit-goal-modal-content-body-input-container-metric p {
					margin-bottom: 0px;
				}
				.goal-card-editing-body-upper-col-body-unit-container {
					position: absolute;
					right: 20px;
					top: 48px;
					width: 100px;
				}


		.edit-goal-modal-button-container {
			width: 100%;
			padding: 18px 20px;
			padding-bottom: 0px;
			border-top: solid;
			border-width: 1px;
			border-color: #2B2B30;
		}
			.edit-goal-modal-button {
				width: 120px;
				margin-left: auto;
				float: right;
				background: #5B62FF;
				border-radius: 5px;
				padding: 8px 0px;
				box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
			.edit-goal-modal-button-finish {
				width: 100px;
			}
				.edit-goal-modal-button:hover {
					cursor: pointer;
					background: #7278F2;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
				.edit-goal-modal-button p {
					font-size: 13.5px;
					color: white;
					margin: 0px;
					width: 100%;
					text-align: center;
				}

		.edit-goal-modal-content-loading {
			width: 100%;
			padding-top: 30%;
		}
			.edit-goal-modal-content-loading p {
				font-size: 13.5px;
			    color: #6C6C6C;
			    margin: 0px auto;
			    width: 80%;
			    text-align: center;
			    font-weight: 300;
			    margin-top: 12px;
			    line-height: 20px;
			}
		.edit-goal-modal-content-complete {
			width: 100%;
			padding-top: 20%;
		}
			.edit-goal-modal-content-complete h4 {
				font-size: 18px;
			    color: #ffffff;
			    margin: 0px;
			    width: 100%;
			    text-align: center;
			}
			.edit-goal-modal-content-complete p {
				font-size: 13.5px;
			    color: #ffffff;
			    margin: 0px auto;
			    width: 80%;
			    text-align: center;
			    font-weight: 300;
			    margin-top: 12px;
			    line-height: 20px;
			}
		.edit-goal-modal-content-actions-list-columns {
			width: 100%;
		}
			.edit-goal-modal-content-actions-list-columns-inner {
				width: 100%;
				display: block;
			}










