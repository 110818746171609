.new-workout-workout-list-container {
	padding: 0px 48px;
	padding-top: 12px;
	height: calc(100vh - 500px);
}

.new-workout-workout {
	padding: 0px 48px;
	padding-top: 12px;
}

.new-workout-workout-header {
	padding: 24px 0px;
	white-space: nowrap;
}
	.new-workout-workout-header h1 {
		font-size: 28px;
		margin: 0px;
		color: #ffffff;
	}
.new-workout-workout-loading-plan-container {
	width: 100%;
	/*padding-top: 20vh;*/
}
	.new-workout-workout-loading-plan-container p {
		font-size: 13.5px;
		color: #9DA0AD;
		width: 100%;
		text-align: center;
		margin-top: 8px;
	}

.new-workout-workout-plan-container-details {
	width: 100%;
	padding-bottom: 24px;
}
	.new-workout-workout-plan-container-details p {
		font-size: 13.5px;
		color: #ffffff;
		margin: 0px;
	}

.new-workout-workout-plan-container {
	padding-top: 36px;
}
	.new-workout-workout-plan-container-header {
		padding: 16px 24px;
		width: 100%;
		background: #1F1F21;
	}
		.new-workout-workout-plan-container-header p {
			font-size: 10px;
			color: #6C6C6C;
			margin: 0px;
			margin-bottom: 4px;
			display: none;
		}
		.new-workout-workout-plan-container-header h4 {
			font-size: 13.5px;
			color: #9DA0AD;
			margin: 0px;
		}
		.new-workout-workout-plan-container-header span {
			font-size: 13.5px;
			color: #ffffff;
			margin: 0px;
		}

		.new-workout-workout-plan-container-schedule-container {
			width: 100%;
			display: flex;
			padding-bottom: 24px;
			height: calc(100vh - 300px);
		}
			.new-workout-workout-plan-container-schedule {
				width: 60%;
				padding-right: 32px;
				padding-bottom: 24px;
			}
				.new-workout-workout-plan-container-schedule-header {
					width: 100%;
					padding-bottom: 6px;
					display: flex;
				}
					.new-workout-workout-plan-container-schedule-header-title {

					}
						.new-workout-workout-plan-container-schedule-header-title h2 {
						    font-size: 28px;
						    color: #ffffff;
						    margin: 0px;
						}
					.new-workout-workout-plan-container-schedule-header-button-container {
						margin-left: auto;
						float: right;
						display: flex;
					}
						.new-workout-workout-plan-container-schedule-header-button {
							padding: 6px 12px;
							height: 32px;
							border-radius: 5px;
							border: solid;
							border-width: 1px;
							border-color: #2B2B30;
							background: #1F1F21;
							box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.12);
							margin-left: 8px;
							display: flex;
						}
						.new-workout-workout-plan-container-schedule-header-button-today {
							margin-right: 8px;
						}
							.new-workout-workout-plan-container-schedule-header-button p {
							    font-size: 13.5px;
							    font-weight: 500;
							    color: #ffffff;
							    margin: 0px;
							}
							.new-workout-workout-plan-container-schedule-header-button-icon {
								font-size: 14px !important;
								margin-top: 2px;
								margin-left: 6px;
								margin-right: 0px;
								color: #9DA0AD;
							}
								.new-workout-workout-plan-container-schedule-header-button-prev .new-workout-workout-plan-container-schedule-header-button-icon {
									margin-left: 0px;
									margin-right: 6px;
								}
				.new-workout-workout-plan-container-schedule-days-container-outter {
					width: 100%;
					margin-top: 8px;
					height: calc(100% - 24px);
				}
					.new-workout-workout-plan-container-schedule-weekdays-container {
						width: 100%;
						display: flex;
						padding-top: 24px;
					}
						.new-workout-workout-plan-container-schedule-weekdays {
							min-width: 14.28%;
						}	
							.new-workout-workout-plan-container-schedule-weekdays p {
							    font-size: 11px;
							    color: #9DA0AD;
							    margin: 0px;
							    width: 100%;
							    text-align: center;
							}
					.new-workout-workout-plan-container-schedule-days-container {
						width: 100%;
						display: inline-block;
						padding: 12px 0px;
						height: calc(100%);
					}
						.new-workout-workout-plan-container-schedule-day-content {
							min-width: 14.28%;
							display: inline-block;
							position: relative;
							padding: 0px;
							min-height: 30px;
							height: 16.67%;
							max-height: 16.67%;
							
							margin-top: 0px;
							border-radius: 5px;
							-webkit-transition: all 0.15s;
							-moz-transition: all 0.15s;
							transition: all 0.15s;
						}
						.new-workout-workout-plan-container-schedule-day-content:hover {
							/*background: rgba(73,89,193,0.7);*/
							cursor: pointer;
							-webkit-transition: all 0.15s;
							-moz-transition: all 0.15s;
							transition: all 0.15s;
						}
							.new-workout-workout-plan-container-schedule-day-content-line-container {
								position: absolute;
								left: 0px;
								top: 30px;
								width: 100%;
							}
								.new-workout-workout-plan-container-schedule-day-content-line-phase {
									width: calc(100% + 4px);
									height: 4px;
									border-radius: 2px;
									background: #5B62FF;
									
								}
									.new-workout-workout-plan-container-schedule-day-content-line-short {
										width: calc(100% - 12px) !important;
										
									}
								.new-workout-workout-plan-container-schedule-day-content-line-cycle {
									width: calc(100% + 4px);
									height: 4px;
									border-radius: 2px;
									background: #FFD15B;
									margin-top: 4px;
								}
						.new-workout-workout-plan-container-schedule-day-content-invisible {
							background: transparent !important;
							padding-top: 16px;
						}
						.new-workout-workout-plan-container-schedule-day-content-invisible:hover {
							background: rgba(0,0,0,0) !important;
							cursor: default !important;
						}
							.new-workout-workout-plan-container-schedule-day-content-inner {
								width: 100%;
								height: 100%;
								padding-top: 16px;
							}
								.new-workout-workout-plan-container-schedule-day-content-inner p {
									width: 100%;
									text-align: center;
									font-size: 18px;
									color: #ffffff;
									margin: 0px;

								}
							.new-workout-workout-plan-container-schedule-day-content-inner-dark {

							}
								.new-workout-workout-plan-container-schedule-day-content-inner-dark p {
									color: #6C6C6C;
								}
							.new-workout-workout-plan-container-schedule-day-content-inner-complete {

							}
								.new-workout-workout-plan-container-schedule-day-content-inner-complete p {
									color: #5BFF7F !important;
									font-weight: 600;
								}
							.new-workout-workout-plan-container-schedule-day-content-inner-invisible {
								width: 100%;
							}
								.new-workout-workout-plan-container-schedule-day-content-inner-invisible p {
								    font-size: 13.5px;
								    color: transparent;
								    margin: 0px;
								}
							.new-workout-workout-plan-container-schedule-day-content-inner-selected {
								background: rgba(73,89,193,0.4);
								border-radius: 5px;
							}
								.new-workout-workout-plan-container-schedule-day-content-inner-selected p {
									color: white;
									font-weight: 600;
								}
							.new-workout-workout-plan-container-schedule-day-content-inner-today {

							}
								.new-workout-workout-plan-container-schedule-day-content-inner-today p {
									font-weight: 600;
									color: #5B62FF;
								}
							.new-workout-workout-plan-container-schedule-day-content-selected {
								background: #5B62FF;
								box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
							}
							.new-workout-workout-plan-container-schedule-day-content-selected:hover {
								background: #7278F2;
							}
							.new-workout-workout-plan-container-schedule-day-content-selected p {
								color: white !important;
							}
							.new-workout-workout-plan-container-schedule-day-content-selected-minor {
								background: rgba(73,89,193,0.7);
								border-radius: 0px;
							}

							.new-workout-workout-plan-container-schedule-day-content-no-select {
								opacity: 0.3;
							}
							.new-workout-workout-plan-container-schedule-day-content-no-select:hover {
								cursor: not-allowed;
								background: transparent !important;

							}
								.new-workout-workout-plan-container-schedule-day-content-no-select p {

								}

		.new-workout-workout-plan-container-day-panel-outter {
			width: 40%;
			height: 100%;
		}
			.new-workout-workout-plan-container-day-panel {
				width: 100%;
				height: 100%;
				border-left: solid;
				border-width: 1px;
				border-color: #2B2B30;
				padding: 0px;
				padding-left: 32px;
				position: relative;
			}
				.new-workout-workout-plan-container-day-panel-inner {
					height: 100%;
				}
				.new-workout-workout-plan-container-day-panel-body-section-faded-curtain {
					position: absolute;
					width: calc(100%);
					height: 60px;
					bottom: 60px;
					left: 16px;
					background: rgb(31,33,44);
					background: linear-gradient(900deg, rgba(31,33,44,0) 0%, rgba(31,33,44,1) 100%);
				}
				.new-workout-workout-plan-container-day-panel-header {
					width: 100%;
					position: relative;
					padding-bottom: 16px;
				}
					.new-workout-workout-plan-container-day-panel-header h4 {
					    font-size: 18px;
					    color: #ffffff;
					    margin: 0px;
					}
					.new-workout-workout-plan-container-day-panel-header h2 {
					    font-size: 28px;
					    color: #ffffff;
					    margin: 0px;
					}
					.new-workout-workout-plan-container-day-panel-day-floater {
						position: absolute;
						padding: 4px 8px;
						background: rgba(91, 98, 255, 0.2);
						border-radius: 5px;
						top: 0px;
						right: 0px;
					}
						.new-workout-workout-plan-container-day-panel-day-floater p {
						    font-size: 12px;
						    font-weight: 500;
						    color: #7278F2;
						    margin: 0px;
						}
				.new-workout-workout-plan-container-day-panel-body {
					width: 100%;
					height: calc(100% - 130px);
					overflow-y: scroll;
					padding-bottom: 60px;
					
				}
					.new-workout-workout-plan-container-day-panel-body-section {
						width: 100%;
						border-top: solid;
						border-width: 1px;
						border-color: #2B2B30;
						padding: 16px 0px;
					}
						.new-workout-workout-plan-container-day-panel-body-section-row {
							width: 100%;
							display: flex;
							padding: 8px 0px;
						}
							.new-workout-workout-plan-container-day-panel-body-section-row-title {
								width: 140px;
								white-space: nowrap;
							}
								.new-workout-workout-plan-container-day-panel-body-section-row-title p {
								    font-size: 13.5px;
								    color: #9DA0AD;
								    margin: 0px;
								}
							.new-workout-workout-plan-container-day-panel-body-section-row-content {
								width: calc(100% - 140px);
							}
								.new-workout-workout-plan-container-day-panel-body-section-row-content h4 {
								    font-size: 13.5px;
								    color: #ffffff;
								    margin: 0px;
								}
						.new-workout-workout-plan-container-day-panel-body-section-header {
							width: 100%;
							display: flex;
							padding: 8px 0px;
							padding-bottom: 16px;
						}
							.new-workout-workout-plan-container-day-panel-body-section-header h4 {
							    font-size: 13.5px;
							    color: #ffffff;
							    margin: 0px;
							}
							.new-workout-workout-plan-container-day-panel-body-section-header p {
							    font-size: 13.5px;
							    color: #7278F2;
							    margin: 0px;
							    margin-left: auto;
							    float: right;
							}
						.new-workout-workout-plan-container-day-panel-body-section-note {
							width: 100%;
						}
							.new-workout-workout-plan-container-day-panel-body-section-note p {
							    font-size: 13.5px;
							    color: #ffffff;
							    margin: 0px;
							    line-height: 18px;
							}
				.new-workout-workout-plan-container-day-panel-button {
					width: 100%;
					border-radius: 5px;
					background: #5B62FF;
					padding: 12px;
					display: flex;
					box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.12);
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
				.new-workout-workout-plan-container-day-panel-button:hover {
					cursor: pointer;
					background: #7278F2;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
					.new-workout-workout-plan-container-day-panel-button p {
					    font-size: 13.5px;
					    font-weight: 500;
					    color: white;
					    margin: 0px;
					    width: 100%;
					    text-align: center;
					}
					.new-workout-workout-plan-container-day-panel-button-icon {
						font-size: 14px !important;
						color: white;
						margin-top: 1px;
					}
		.new-workout-workout-plan-container-day-panel-inner-loading {
			width: 100%;
			padding-top: 18vh;
		}
			.new-workout-workout-plan-container-day-panel-inner-loading p {
			    font-size: 13.5px;
			    color: #9DA0AD;
			    margin: 0px;
			    width: 100%;
			    text-align: center;
			    margin-top: 8px;
			}
			.new-workout-workout-plan-container-day-panel-inner-loading .client-list-container-loading-spinner {
				margin-top: 0px;
			}
		.new-workout-workout-plan-container-day-panel-body-section-note {
			width: 100%;
		}
			.new-workout-workout-plan-container-day-panel-body-section-note-empty-image-container {
				width: 120px;
				margin: 48px auto;
				margin-bottom: 8px;
			}
				.new-workout-workout-plan-container-day-panel-body-section-note-empty-image-container p {
				    font-size: 13.5px;
				    color: #9DA0AD;
				    margin: 0px;
				    width: 100%;
				    text-align: center;
				}
				.new-workout-workout-plan-container-day-panel-body-section-note-empty-image {
					width: 100%;
				}







