/* .my-profile-background-cell- */

.my-profile-background-cell {
	width: 100%;
	padding: 20px 20px;
	padding-bottom: 8px;
	border: solid;
	border-width: 1px;
	border-color: #2B2B30;
	border-radius: 5px;
	box-shadow: 0px 0px 12px 6px rgba(0, 0, 0, 0.08);
	background: #1F1F21;
	position: relative;
	margin-bottom: 12px;
}
	.my-profile-background-cell-close-button {
		position: absolute;
		
		width: 27px;
		height: 27px;
		border-radius: 5px;
		top: 4px;
		right: 4px;
		padding: 5px 5px;
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}
	.my-profile-background-cell-close-button:hover {
		background: rgba(0, 0, 0, 0.2);
		cursor: pointer;
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}
		.my-profile-background-cell-close-icon {
			font-size: 16px !important;
			color: #6C6C6C;
		}

	.my-profile-background-cell-row {
		width: 100%;
		display: flex;
		padding-bottom: 12px;
	}
		.my-profile-background-cell-row-title {
			width: 56px;
		}
			.my-profile-background-cell-row-title p {
			    font-size: 18px;
			    color: #6C6C6C;
			    margin: 0px;
			}
		.my-profile-background-cell-row-input-container {
			width: calc(100% - 56px);
		}
			.my-profile-background-cell-row-input-container-inner {
				margin-top: -3px;
			}
