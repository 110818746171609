/* .calendar- */

.calendar {
	width: 100%;
	height: 100%;
	padding: 0px 36px;
}
	.calendar-header-container {
		width: 100%;
		padding: 16px 0px;
		border-bottom: solid;
		border-width: 1px;
		border-color: #2B2B30;
		height: 60px;
		display: flex;
		position: relative;
	}
		.calendar-header-container-title {

		}
			.calendar-header-container-title h4 {
			    font-size: 21px;
			    color: #ffffff;
			    margin: 0px;
			}
		.calendar-header-container-buttons {
			display: flex;
			margin-left: 16px;
			position: absolute;
			top: 14px;
			left: 152px;
		}
			.calendar-header-container-button {
				padding: 6px 12px;
				background: #1F1F21;
				border: solid;
				border-width: 1px;
				border-color: #2B2B30;
				border-radius: 5px;
				box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.12);
				display: flex;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
			.calendar-header-container-button:hover {
				cursor: pointer;
				background: #1F1F21;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
				.calendar-header-container-button p {
				    font-size: 13.5px;
				    color: #ffffff;
				    margin: 0px;
				    margin-top: -2px;
				}
				.calendar-header-container-button-icon {
					font-size: 12px !important;
					color: #ffffff !important;
					margin-top: 1px;
				}
					.calendar-header-container-button-icon-left {
						margin-right: 2px;
					}
					.calendar-header-container-button-icon-right {
						margin-left: 4px;
					}
				.calendar-header-container-button-left {
					margin-right: 8px;
				}
				.calendar-header-container-button-right {
					padding-right: 8px;
				}

		.calendar-header-container-phase-builder-container {
			margin-left: auto;
			float: right;
			display: flex;
		}
			.calendar-header-container-phase-builder-text-container {

			}
				.calendar-header-container-phase-builder-text-container h4 {
				    font-size: 18px;
				    color: #ffffff;
				    margin: 0px;
				}
				.calendar-header-container-phase-builder-text-container span {
					color: #7278F2;
				}
			.calendar-header-container-phase-builder-button-container {
				padding-left: 20px;
				margin-top: -5px;
			}
				.calendar-header-container-phase-builder-button-dark {
				    padding: 6px 12px;
				    background: #1F1F21;
				    border: solid;
				    border-width: 1px;
				    border-color: #2B2B30;
				    display: flex;
				    border-radius: 5px;
				    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.12);
				    float: left;
				    -webkit-transition: all 0.15s;
				    -moz-transition: all 0.15s;
				    transition: all 0.15s;
							}
				.calendar-header-container-phase-builder-button-dark:hover {
				    background: #1F1F21;
				    cursor: pointer;
				    -webkit-transition: all 0.15s;
				    -moz-transition: all 0.15s;
				    transition: all 0.15s;
				}
					.calendar-header-container-phase-builder-button-dark p {
					    font-size: 12px;
					    color: #9DA0AD;
					    margin: 0px;
					}
				.calendar-header-container-phase-builder-button-blue {
				    padding: 6px 12px;
				    background: #5B62FF;
				    border: solid;
				    border-width: 1px;
				    border-color: #5B62FF;
				    display: flex;
				    border-radius: 5px;
				    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.12);
				    float: left;
				    margin-left: 12px;
				    -webkit-transition: all 0.15s;
				    -moz-transition: all 0.15s;
				    transition: all 0.15s;
							}
				.calendar-header-container-phase-builder-button-blue:hover {
				    background: #7278F2;
				    cursor: pointer;
				    -webkit-transition: all 0.15s;
				    -moz-transition: all 0.15s;
				    transition: all 0.15s;
				}
					.calendar-header-container-phase-builder-button-blue p {
					    font-size: 12px;
					    font-weight: 600;
					    color: white;
					    white-space: nowrap;
					    margin: 0px;
					}
					.calendar-header-container-phase-builder-button-blue-unselected {
						opacity: 0.3;
					}
					.calendar-header-container-phase-builder-button-blue-unselected:hover {
						background: #5B62FF;
						cursor: default;
					}
					.calendar-header-container-phase-builder-button-blue-loading {
						width: 104px;
						height: 29px;
					}
					.calendar-header-container-phase-builder-button-blue-loading:hover {
						background: #5B62FF;
						cursor: default;
					}
						.calendar-header-container-phase-builder-button-blue-loading-spinner {
							width: 16px !important;
							height: 16px !important;
							border-width: 2px !important;
							border-top-color: white !important;
							border-right-color: white !important;
							border-bottom-color: white !important;
						}
	.calendar-content-container {
		height: calc(100% - 60px);
		overflow-y: scroll;
		position: relative;
	}
		.calendar-content-loading-container {
			position: absolute;
			width: 100%;
			height: 100%;
			padding-top: calc(40vh - 100px);
			background: rgba(31, 33, 44, 0.3);
			-webkit-backdrop-filter: blur(8px);
			backdrop-filter: blur(8px);
			z-index: 10;
		}
			.calendar-content-loading-container p {
			    font-size: 13.5px;
			    color: #9DA0AD;
			    margin: 0px;
			    margin-top: 12px;
			    width: 100%;
			    text-align: center;
			}









