/* .help-and-feedback- */

.help-and-feedback-content-container {
	width: 100%;
	height: calc(100% - 51px);
	padding: 38px 36px;
	overflow-y: scroll;
	overflow-x: hidden;
	position: relative;
}

		
		.help-and-feedback-content-section {
			width: 100%;
			padding: 12px 0px;
			margin-bottom: 12px;
		}
		.help-and-feedback-content-section-feedback {
			
			padding-bottom: 80px;
			border-top: solid;
			border-width: 1px;
			border-color: #2B2B30;
			padding-top: 12px;
		}
			.help-and-feedback-content-section-feedback .help-and-feedback-content-section-header {
				border-bottom: none;
				width: 60%;
				min-width: 600px;
			}
			.help-and-feedback-content-section-header {
				width: 100%;
				border-bottom: solid;
				border-width: 1px;
				border-color: #2B2B30;
				padding: 12px 0px;
			}
				.help-and-feedback-content-section-header h4 {
				    font-size: 28px;
				    color: #ffffff;
				    margin: 0px;
				    margin-bottom: 8px;
				}
				.help-and-feedback-content-section-header p {
				    font-size: 18px;
				    color: #ffffff;
				    margin: 0px;
				}
				.help-and-feedback-content-section-header span {
				    font-size: 18px;
				    color: #5B62FF;
				    font-weight: 600;
				    margin: 0px;
				}
			.help-and-feedback-content-section-content {
				padding: 24px 0px;
			}
				.help-and-feedback-content-section-content-row {
					width: 100%;
					display: flex;
					margin-bottom: 16px;
				}
					.help-and-feedback-content-section-card {
						width: 33%;
						margin-right: 12px;
						border: solid;
						border-width: 1px;
						border-color: #2B2B30;
						background: #1F1F21;
						box-shadow: 0px 0px 12px 6px rgba(0, 0, 0, 0.08);
						border-radius: 5px;
						overflow: hidden;
						position: relative;
						z-index: 10;
						-webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}
					.help-and-feedback-content-section-card:hover {
						background: #1F1F21;
						box-shadow: 0px 0px 12px 6px rgba(20, 20, 50, 0.12);
						cursor: pointer;
						-webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}
					.help-and-feedback-content-section-card-small {
						display: flex;
					}
						.help-and-feedback-content-section-card-icon-container {
							width: 48px;
							padding: 24px 0px;
							padding-left: 20px;
						}
							.help-and-feedback-content-section-card-icon {
								font-size: 18px !important;
								color: #9DA0AD;
							}
						.help-and-feedback-content-section-card-img-container {
							width: 100%;
							height: 180px;
							background: rgb(91,98,255);
							background: linear-gradient(120deg, rgba(91,98,255,1) 0%, rgba(127,72,192,1) 100%);
						}
							.help-and-feedback-content-section-card-img-container img {
								width: 115px;
								margin: 0px calc(50% - 57px);
								margin-top: 35px;
								
								-webkit-transition: all 0.15s;
								-moz-transition: all 0.15s;
								transition: all 0.15s;
							}
							.help-and-feedback-content-section-card:hover .help-and-feedback-content-section-card-img-container img {
								width: 125px;
								margin: 0px calc(50% - 62px);
								margin-top: 30px;
							}

							.help-and-feedback-content-section-card-img-container-0 img {
								width: 100px;
								margin: 0px calc(50% - 50px);
								margin-top: 45px;
							}
								.help-and-feedback-content-section-card:hover .help-and-feedback-content-section-card-img-container-0 img {
									width: 110px;
									margin: 0px calc(50% - 55px);
									margin-top: 40px;
								}
							.help-and-feedback-content-section-card-img-container-1 img {
								width: 140px;
								margin: 0px calc(50% - 70px);
								margin-top: 30px;
							}
								.help-and-feedback-content-section-card:hover .help-and-feedback-content-section-card-img-container-1 img {
									width: 150px;
									margin: 0px calc(50% - 75px);
									margin-top: 25px;
								}
							.help-and-feedback-content-section-card-img-container-2 img {
								width: 115px;
								margin: 0px calc(50% - 57px);
								margin-top: 35px;
							}
								.help-and-feedback-content-section-card:hover .help-and-feedback-content-section-card-img-container-2 img {
									width: 125px;
									margin: 0px calc(50% - 62px);
									margin-top: 30px;
								}
							.help-and-feedback-content-section-card-img-container-3 img {
								width: 130px;
								margin: 0px calc(50% - 65px);
								margin-top: 35px;
							}
								.help-and-feedback-content-section-card:hover .help-and-feedback-content-section-card-img-container-3 img {
									width: 140px;
									margin: 0px calc(50% - 70px);
									margin-top: 30px;
								}
							.help-and-feedback-content-section-card-img-container-4 {
								
							}
							.help-and-feedback-content-section-card-img-container-5 {
								
							}

						.help-and-feedback-content-section-card-text-container {
							padding: 24px 20px;
							min-height: 100px;
						}
							.help-and-feedback-content-section-card-text-container h4 {
							    font-size: 13.5px;
							    color: #ffffff;
							    margin: 0px;
							    margin-bottom: 6px;
							}
							.help-and-feedback-content-section-card-text-container p {
							    font-size: 13.5px;
							    color: #9DA0AD;
							    margin: 0px;
							}







