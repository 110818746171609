.new-workout-node-image-container {
	height: 50px;
	max-width: 84px;
	position: relative;
}

.new-workout-node-body-image {
	height: 50px;
	max-width: 60px;
	position: absolute;
	top: 0px;
	left: 14px;
}

.new-workout-client-name-tag-content {
	background: rgba(0,0,0,0);
	padding: 0px;
}