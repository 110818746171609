.settings-main {
	width: 100%;
	height: 100%;
	display: flex;
}
	.settings-main-menu {
		background-color: #161719;
		width: 220px;
		height: 100%;
		border-right: solid;
		border-width: 0.5px;
		border-color: #2B2B30;
		padding: 20px 20px;
		-webkit-transition: all 0.35s;
	  	-moz-transition: all 0.35s;
	  	transition: all 0.35s;
	}
		.settings-main-menu-header {
			width: 100%;
			display: flex;
		}
			.settings-main-menu-header h4 {
				padding-top: 2px;
				font-size: 18px;
				color: #ffffff;
				margin: 0px;
			}
			.settings-main-menu-header-back-icon-container {
				width: 24px;
				height: 24px;
				border-radius: 5px;
				padding: 4px 8px;
				margin-right: 8px;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
			.settings-main-menu-header-back-icon-container:hover {
				background: rgba(0,0,0,0.3);
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
				cursor: pointer;
			}
				.settings-main-menu-header-back-icon {
					font-size: 12px !important;
					color: #6C6C6C;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
				.settings-main-menu-header-back-icon-container:hover .settings-main-menu-header-back-icon {
					color: #ffffff;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
		.settings-main-menu-content {
			padding-top: 8px;
		}
			.settings-main-menu-content-row-header {
				padding: 6px 12px;
				padding-top: 32px;
				display: flex;
			}
				.settings-main-menu-content-row-header h4 {
					font-size: 13.5px;
					color: #6C6C6C;
					margin: 0px;
					margin-top: 2px;
				}
				.settings-main-menu-content-row-header-icon {
					font-size: 18px !important;
					color: #6C6C6C;
					margin-right: 14px;
				}
			.settings-main-menu-content-row {
				width: calc(100% - 36px);
				padding: 5px 8px;
				margin-left: 36px;
				border-radius: 5px;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
			.settings-main-menu-content-row:hover {
				background: #1F1F21;
				cursor: pointer;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
				.settings-main-menu-content-row h4 {
					font-size: 13.5px;
					color: #ffffff;
					margin: 0px;
				}
				.settings-main-menu-content-row-selected {
					background: #1F1F21;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
				.settings-main-menu-content-row-selected:hover {
					background: #1F1F21;
					cursor: default;
				}
	.settings-main-content {
		background-color: #161719; /* #202436;*/
		width: calc(100% - 220px);
		
		-webkit-transition: all 0.35s;
	  	-moz-transition: all 0.35s;
	  	transition: all 0.35s;
	  	position: relative;
	  	overflow: hidden;
	}
		.settings-main-content-inner {
			width: 100%;
			height: 100vh;
			overflow-y: scroll;
			overflow-x: visible;
			padding: 0px 16px;
			padding-bottom: 42px;
		}