
.exercise-breakdown-card {
    width: 100%;
    min-height: 100px;
    background: #101012;
    border: solid;
    border-width: 1px;
    border-color: #202020;
    border-radius: 16px;
    position: relative;
    overflow: hidden;
    margin-bottom: 12px;
}
    .exercise-breakdown-card-header {
        padding: 16px 8px;
        display: flex;
        align-items: center;
        z-index: 1;
        position: relative;
        border-bottom: solid;
        border-width: 1px;
        border-color: #202020;
    }
        .exercise-breakdown-card-header-icon-container {
            background: #fff;
            width: 32px;
            height: 32px;
            border-radius: 6px;
            align-items: center;
            justify-content: center;
            padding: 6px 6px;
        }
            .exercise-breakdown-card-header-icon {
                color: black;
                font-size: 18px !important;
            }
        .exercise-breakdown-card-header h4 {
            font-size: 18px;
            color: #ffffff;
            font-weight: 300;
            margin: 0px;
            margin-left: 12px;
        }
        .exercise-breakdown-card-header-more-button {
            padding: 4px 8px;
            border-radius: 5px;
            margin-left: auto;
            float: right;
            -webkit-transition: all 0.15s;
            -moz-transition: all 0.15s;
            transition: all 0.15s;
        }
        .exercise-breakdown-card-header-more-button:hover {
            background: #7278F230;
            cursor: pointer;
            -webkit-transition: all 0.15s;
            -moz-transition: all 0.15s;
            transition: all 0.15s;
        }
            .exercise-breakdown-card-header-more-button p {
                font-size: 13.5px;
                color: #7278F2;
                margin: 0px;
            }
        .exercise-breakdown-card-header-summary-container {
            padding: 4px 8px;
            border-radius: 5px;
            margin-left: auto;
            float: right;
        }
            .exercise-breakdown-card-header-summary-container p {
                font-size: 13.5px;
                color: #6C6C6C;
                margin: 0px;
            }

    .exercise-breakdown-card-body-container {
        width: 100%;
        height: calc(100% - 110px);
        padding: 16px;
        padding-right: 0px;
        z-index: 1;
        position: relative;
    }
    .exercise-breakdown-card-body-empty-container {
        width: 100%;
        height: 120px;
        padding-top: 60px;
    }
        .exercise-breakdown-card-body-empty-container p {
            font-size: 13.5px;
            color: #6C6C6C;
            margin: 0px;
            width: 100%;
            text-align: center;
        }
        .exercise-breakdown-card-body-header {
            width: 100%;
            display: flex;
            padding: 16px 0px;
            padding-right: 16px;
            border-bottom: solid;
            border-width: 1px;
            border-color: #1A1A1A;
        }
        .exercise-breakdown-card-body-col-header-col {

        } 
            .exercise-breakdown-card-body-col-header-col p {
                font-size: 12px;
                color: #6C6C6C;
                margin: 0px;
                font-weight: 300;
            }
        .exercise-breakdown-card-body-col-content-col {
            display: flex;
        } 
            .exercise-breakdown-card-body-col-content-col p {
                font-size: 16px;
                color: #ffffff;
                font-weight: 500;
                margin: 0px;
                font-weight: 300;
            } 
            .exercise-breakdown-card-body-col-content-col span {
                color: #6C6C6C;
            } 
            .exercise-breakdown-card-body-col-content-col h4 {
                font-size: 16px;
                color: #7278F2;
                margin: 0px;
                font-weight: 300;
                margin-left: 12px;
            } 
        .exercise-breakdown-card-body-col {
            width: 25%;
        } 
        .exercise-breakdown-card-body-col-name {
            width: 35%;
            display: flex;
            align-items: center;
        }
        .exercise-breakdown-card-body-col-measurement {
            width: 25%;
        }
        .exercise-breakdown-card-body-col-datapoints {
            width: 25%;
        }
        .exercise-breakdown-card-body-col-score {
            width: 15%;
        }
        .exercise-breakdown-card-body-col-setnum {
            width: 15%;
            display: flex;
            align-items: center;
        }
        .exercise-breakdown-card-body-col-reps {
            width: 6%;
        }
        .exercise-breakdown-card-body-col-cross {
            width: 5%;
        }
            .exercise-breakdown-card-body-col-cross-icon {
                font-size: 16px !important;
                color: #9DA0AD;
            }
        .exercise-breakdown-card-body-col-weight {
            width: 15%;
        }
        .exercise-breakdown-card-body-col-tempo {
            width: 20%;
        }
        .exercise-breakdown-card-body-col-rest {
            width: 20%;
        }

    .exercise-breakdown-card-selector-container {
        width: 100%;
        display: flex;
        padding: 16px;
        z-index: 1;
        position: relative;
    }
        .exercise-breakdown-card-selector-demo {
            width: 36px;
            border-radius: 2px;
            height: 4px;
            background: #6C6C6C;
        }

    .exercise-breakdown-card-bg-container {
        width: 100%;
        height: 100%;
        border-radius: 16px;
        position: absolute;
        z-index: 0;
        top: 0px;
        left: 0px;
    }