.home-container {
	width: 100%;
	height: calc(100vh - 52px);
	display: flex;
}
.home-testing-container {
	width: 100%;
	height: 100%;
}
	.home-loading-container {
		width: 100%;
		height: calc(100% - 80px);
		padding-top: 100px;
	}
		.home-loading-container h4 {
			font-size: 13.5px;
			color: #9DA0AD;
			margin: 0px;
			width: 100%;
			text-align: center;
		}
		.home-loading-container p {
			font-size: 10px;
			color: #9DA0AD;
			margin: 0px;
			width: 100%;
			text-align: center;
		}

	.home-container-row-upper {
		height: calc(100% - 332px);

	}
	.home-container-row-lower {
		height: 40%;
	}
		.home-section-header-container {
			width: 100%;
			padding-bottom: 4px;
			padding: 12px 20px;
		}
			.home-section-header-container h4 {
				font-size: 13.5px;
				color: #ffffff;
				margin: 0px;
				margin-bottom: 2px;
			}
			.home-section-header-container p {
				font-size: 10px;
				color: #9DA0AD;
				margin: 0px;
			}

.home-container-clients-column-container {
	
	width: 66%;
	height: 100%;

}
	.home-container-clients-column-container-left {
		border-right: solid;
		border-color: #2B2B30;
		border-width: 1px;
	}


.home-container-stats-column-container {
	width: 34%;
	min-width: 34%;
	height: 100%;
	box-shadow: 0px 18px 24px 12px rgba(0, 0, 0, 0.1);
}

.home-container-clients-top-performers-content {
	height: calc(100% - 25px);
	
}
	.home-container-clients-top-performers-chart-container {
		width: 100%;
		height: calc(100% - 100px);
		padding: 0px 36px;
	}

	.home-container-clients-top-performers-list-container {
		width: 100%;
		padding-top: 4px;
		padding-left: 48px;
	}
		.home-container-clients-top-performers-list-header-container {
			width: 100%;
			display: flex;
		}
		.home-container-clients-top-performers-list-cell-name-container {
			width: 28%;
			padding-left: 24px;
		}
			.home-container-clients-top-performers-list-cell-name-container p {
				font-size: 10px;
				color: #6C6C6C;
				margin: 0px;
				margin-left: 24px;
			}
		.home-container-clients-top-performers-list-cell-col-container {
			width: 18%;
		}
			.home-container-clients-top-performers-list-cell-col-container p {
				font-size: 10px;
				color: #6C6C6C;
				margin: 0px;
				padding-left: 12px;
			}
	.home-container-clients-top-performers-list-cell {
		width: 100%;
		display: flex;
		padding: 4px 0px;
		
	}
		.home-container-clients-top-performers-list-cell-colour-block {
			width: 14px;
			height: 14px;
			min-width: 14px;
			border-radius: 4px;
		}
			.home-container-clients-top-performers-list-cell-colour-block-blue {
				background: #4959C1;
			}
			.home-container-clients-top-performers-list-cell-colour-block-red {
				background: #F02E4A;
			}
			.home-container-clients-top-performers-list-cell-colour-block-purple {
				background: #7F48C0;
			}

			.home-container-clients-top-performers-list-cell-name-container {
				width: 30%;
			}
				.home-container-clients-top-performers-list-cell-name-container h4{
					font-size: 13.5px;
					color: #ffffff;
					margin: 0px;
					padding-left: 12px;
				}
			.home-container-clients-top-performers-list-cell-col-container {
				width: 16.67%;
			}
				.home-container-clients-top-performers-list-cell-col-container h4{
					font-size: 13.5px;
					color: #ffffff;
					font-weight: 300;
					margin: 0px;
				}



.home-container-clients-most-recent-container {

}
	.home-container-clients-most-recent-content {
		display: block;
	}

		.home-container-clients-most-recent-list-header-container {
			width: 100%;
			display: flex;
			background: #1F1F21;
			padding: 12px 12px;
			padding-left: 58px;
		}
			.home-container-clients-most-recent-list-header-container-col {
				width: 15%;

			}
				.home-container-clients-most-recent-list-header-container-col p {
					font-size: 10px;
					margin: 0px;
					color: #6C6C6C;
				}
			.home-container-clients-most-recent-list-header-container-col-name {
				width: 30%;
			}
			.home-container-clients-most-recent-list-header-container-col-muscles {
				padding-left: 12px;
				width: 40%;
			}
	.home-container-exiring-plans-container {
		padding: 16px 12px;
	}
.home-container-stats-container {
	width: 100%;
}
	.home-container-stats-content {
		display: flex;
		width: 100%;
		padding-top: 12px;
	}
		.home-container-stats-card {
			width: calc(33% - 8px);
			margin-right: 12px;
			padding: 16px 20px;
			border-radius: 5px;
			background-color: #1F1F21;
			box-shadow: 0 4px 38px 0 rgba(0, 0, 0, 0.1);
			height: 156px;
		}
			.home-container-stats-card h4 {
				font-size: 9px;
				color: #6C6C6C;
				margin: 0px;
				padding-top: 20px;
			}
			.home-container-stats-card h3 {
				font-size: 13.5px;
				color: #ffffff;
				margin: 0px;
				padding-top: 8px;
			}
			.home-container-stats-card p {
				font-size: 10px;
				color: #9DA0AD;
				margin: 0px;
				padding-top: 12px;
			}


			.home-container-stats-header-icon-container {
				width: 32px;
				height: 32px;
				border-radius: 5px;
				padding: 5px 11px;
			}
				.home-container-stats-header-icon-container-blue {
					background: rgba(73,89,193,0.24);
				}
					.home-container-stats-header-icon-blue {
						color: rgba(73,89,193,1.0);
					}
				.home-container-stats-header-icon-container-purple {
					background: rgba(127,72,192,0.3);
				}
					.home-container-stats-header-icon-purple {
						color: rgba(127,72,192,1.0);
					}
				.home-container-stats-header-icon-container-green {
					background: rgba(73,193,89,0.24);
				}
					.home-container-stats-header-icon-green {
						color: rgba(73,193,89,1.0);
					}
				.home-container-stats-header-icon {
					font-size: 10px !important;
				}

	.home-container-clients-column-container-left-loading {
		width: 100%;
		padding-top: calc(40vh - 100px);
	}
		.home-container-clients-column-container-left-loading p {
		    font-size: 13.5px;
		    color: #9DA0AD;
		    margin: 0px;
		    margin-top: 12px;
		    width: 100%;
		    text-align: center;
		}
	.home-container-clients-column-container-left-welcome {
		overflow-y: scroll;
	}
	.home-container-clients-column-container-left-welcome-inner {
		padding: 38px 36px;
		position: relative;
		overflow: hidden;
		width: 100%;
	}
		.home-container-clients-column-container-left-welcome-header {
 			/*position: relative;*/
 			padding-bottom: 60px;
		}
			.home-container-clients-column-container-left-welcome-header-content {
				position: relative;
				z-index: 10;
			}
			.home-container-clients-column-container-left-welcome-header-content h1 {
			    font-size: 42px;
			    line-height: 42px;
			    color: #ffffff;
			    margin: 0px;
			    margin-bottom: 20px;
			}
			.home-container-clients-column-container-left-welcome-header-content h4 {
			    font-size: 18px;
			    color: #ffffff;
			    font-weight: 300;
			    margin: 0px;
			    max-width: 600px;
			}
				.home-container-clients-column-container-left-welcome-video-button {
				    padding: 8px 12px;
				    margin-top: 18px;
				    background: #5437DC;
				    display: flex;
				    border-radius: 5px;
				    float: left;
				    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.12);
				    -webkit-transition: all 0.15s;
				    -moz-transition: all 0.15s;
				    transition: all 0.15s;
				}
				.home-container-clients-column-container-left-welcome-video-button:hover {
				    background: #7963E0;
				    cursor: pointer;
				    -webkit-transition: all 0.15s;
				    -moz-transition: all 0.15s;
				    transition: all 0.15s;
				}
					.home-container-clients-column-container-left-welcome-video-button p {
					    font-size: 15px;
					    font-weight: 600;
					    color: white;
					    margin: 0px;
					    opacity: 1;
					    margin-top: -2px;
					}
					.home-container-clients-column-container-left-welcome-video-button-icon {
						font-size: 16px !important;
						color: white;
						margin-left: 12px;
					}
			.home-container-clients-column-container-left-welcome-img-container {
				position: absolute;
				z-index: 0;
				top: -32px;
				right: -32vw;
			}
			.home-container-clients-column-container-left-welcome-nodes-img-container {
				position: absolute;
				z-index: 0;
				top: -200px;
				left: calc(-400px + 10vw);
				width: 200%;
			}
		.home-container-clients-column-container-left-welcome-start-container {
			padding: 8px 0px;
			margin-top: 24px;
			position: relative;
			z-index: 10;
		}
		.home-container-clients-column-container-left-welcome-start-container-nodes {
			z-index: 0;
		}
			.home-container-clients-column-container-left-welcome-start-header-container {
				padding-top: 12px;
				padding-bottom: 24px;
				position: relative;
				z-index: 10;
			}
				.home-container-clients-column-container-left-welcome-start-header-container p {
				    font-size: 13.5px;
				    color: #7278F2;
				    margin: 0px;
				}
				.home-container-clients-column-container-left-welcome-start-header-container h2 {
				    font-size: 28px;
				    color: white;
				    margin: 0px;
				}
				.home-container-clients-column-container-left-welcome-start-header-container h4 {
				    font-size: 18px;
				    color: #ffffff;
				    margin: 0px;
				    margin-top: 12px;
				    font-weight: 400;
				}
			.home-container-clients-column-container-left-welcome-start-add-container {
				background: #1F1F21;
				border: solid;
				border-width: 1px;
				border-color: #2B2B30;
				border-radius: 5px;
				box-shadow: 0px 0px 12px 6px rgba(0, 0, 0, 0.12);
				margin-bottom: 12px;
				display: flex;
				padding: 24px 16px;
				position: relative;
				z-index: 10;
			}
				.home-container-clients-column-container-left-welcome-start-add-colour-container {
					padding-right: 10px;
					padding-top: 3px;
				}
					.home-container-clients-column-container-left-welcome-start-add-colour-block {
						width: 12px;
						height: 12px;
						border-radius: 3px;
						background: #5B62FF;
					}
						.home-container-clients-column-container-left-welcome-start-add-colour-block-client {
							background: #FF774D;
						}
						.home-container-clients-column-container-left-welcome-start-add-colour-block-program {
							background: #5B62FF;
						}
						.home-container-clients-column-container-left-welcome-start-add-colour-block-folder {
							background: #7F48C0;
						}
						.home-container-clients-column-container-left-welcome-start-add-colour-block-exercise {
							background: rgba(73,188,193,1.0);;
						}
				.home-container-clients-column-container-left-welcome-start-add-text-container {
					padding-right: 24px;
				}
					.home-container-clients-column-container-left-welcome-start-add-text-container h4 {
					    font-size: 18px;
					    color: #ffffff;
					    margin: 0px;
					    margin-bottom: 8px;
					}
					.home-container-clients-column-container-left-welcome-start-add-text-container p {
					    font-size: 13.5px;
					    color: #9DA0AD;
					    margin: 0px;
					    margin-bottom: 16px;
					}
					.home-container-clients-column-container-left-welcome-start-add-text-container-completed h4 {
						color: #9DA0AD;
					}
						.home-container-clients-column-container-left-welcome-start-add-text-button {
							padding: 8px;
							margin-left: -8px;
							margin-bottom: -8px;
							border-radius: 5px;
							display: flex;
							float: left;
							-webkit-transition: all 0.15s;
							-moz-transition: all 0.15s;
							transition: all 0.15s;
						}
						.home-container-clients-column-container-left-welcome-start-add-text-button:hover {
							background: rgba(0, 0, 0, 0.2);
							cursor: pointer;
							-webkit-transition: all 0.15s;
							-moz-transition: all 0.15s;
							transition: all 0.15s;
						}
							.home-container-clients-column-container-left-welcome-start-add-text-button p {
							    font-size: 13.5px;
							    color: #5B62FF;
							    margin: 0px;
							    font-weight: 600;
								-webkit-transition: all 0.15s;
								-moz-transition: all 0.15s;
								transition: all 0.15s;
							}
							.home-container-clients-column-container-left-welcome-start-add-text-button:hover p {
								color: #7278F2;
								-webkit-transition: all 0.15s;
								-moz-transition: all 0.15s;
								transition: all 0.15s;
							}
							.home-container-clients-column-container-left-welcome-start-add-text-button-icon {
								font-size: 15px !important;
								color: #5B62FF;
								margin-top: 1px;
								margin-left: 4px;
								-webkit-transition: all 0.15s;
								-moz-transition: all 0.15s;
								transition: all 0.15s;
							}
							.home-container-clients-column-container-left-welcome-start-add-text-button:hover .home-container-clients-column-container-left-welcome-start-add-text-button-icon {
								color: #7278F2;
								-webkit-transition: all 0.15s;
								-moz-transition: all 0.15s;
								transition: all 0.15s;
							}

							.home-container-clients-column-container-left-welcome-start-add-text-complete {
								display: flex;
								padding-top: 8px;
								margin-bottom: -8px;
								margin-left: -24px;
							}
								.home-container-clients-column-container-left-welcome-start-add-text-complete p {
								    font-size: 13.5px;
								    font-weight: 600;
								    color: #5BFF62;
								    margin: 0px;
								}
								.home-container-clients-column-container-left-welcome-start-add-text-complete-icon {
									font-size: 18px !important;
									margin-right: 8px;
									color: #5BFF62;
								}



					.home-container-clients-column-container-left-welcome-start-add-img-container {
						padding: 0px 24px;
					}
						.home-container-clients-column-container-left-welcome-start-add-img-container img {
							width: 90px;
							min-width: 90px;
							height: 100px;
						}
					.home-container-clients-column-container-left-welcome-multiple-nodes-img-container {
						width: 100%;
						position: relative;
						z-index: 20;
						height: 300px;
					}
						.home-container-clients-column-container-left-welcome-multiple-nodes-img {
							position: absolute;
						}
							.home-container-clients-column-container-left-welcome-multiple-nodes-img-1 {
								top: -48px;
								left: -50px;
								min-width: 300px;
								width: 300px;
							}
							.home-container-clients-column-container-left-welcome-multiple-nodes-img-2 {
								top: -88px;
								right: -150px;
								min-width: 500px;
								width: 500px;
							}
							.home-container-clients-column-container-left-welcome-multiple-nodes-img-3 {
								top: -18px;
								left: calc(50% - 240px);
								min-width: 600px;
								width: 600px;
							}
							

	.home-container-clients-column-container-right {
		position: relative;
		z-index: 20;
		height: 100%;
		overflow-y: scroll;
	}

		.home-container-clients-column-container-left-welcome-nodes-content-container {
			width: 100%;
			padding: 24px 0px;
			position: relative;
			z-index: 10;
		}
			.home-container-clients-column-container-left-welcome-nodes-content-row {
				width: calc(100% + 12px);
				display: flex;
				margin-bottom: 12px;
			}
				.home-container-clients-column-container-left-welcome-nodes-content-card {
					width: 33.33%;
					padding: 24px;
					padding-top: 42px;
					background: rgba(35, 40, 54, 0.6);
					-webkit-backdrop-filter: blur(18px);
					backdrop-filter: blur(18px);
					border: solid;
					border-width: 1px;
					border-color: rgba(70, 80, 108, 0.2);
					border-radius: 5px;
					box-shadow: 0px 0px 12px 6px rgba(0, 0, 0, 0.1);
					margin-right: 12px;
				}
				.home-container-clients-column-container-left-welcome-nodes-content-card-full {
					width: 100%;
					padding-right: 20%;
					background: #5B62FF;
					margin-top: 12px;
				}
					.home-container-clients-column-container-left-welcome-nodes-content-text-container {

					}
						.home-container-clients-column-container-left-welcome-nodes-content-text-container h4 {
						    font-size: 18px;
						    color: white;
						    margin: 0px;
						    margin-bottom: 8px;
						}
						.home-container-clients-column-container-left-welcome-nodes-content-text-container p {
						    font-size: 13.5px;
						    color: #ffffff;
						    margin: 0px;
						}
					.home-container-clients-column-container-left-welcome-nodes-content-card-full h2 {
					    font-size: 28px;
					    color: white;
					    margin: 0px;
					    margin-bottom: 8px;
					}
					.home-container-clients-column-container-left-welcome-nodes-content-card-full p {
						font-size: 13.5px;
					    color: white;
					    margin: 0px;
					    margin-bottom: 16px;
					}
					.home-container-clients-column-container-left-welcome-nodes-content-card-full .home-container-clients-column-container-left-welcome-start-add-text-button p {
						color: white;
						margin: 0px;
					}
					.home-container-clients-column-container-left-welcome-nodes-content-card-full .home-container-clients-column-container-left-welcome-start-add-text-button:hover p {
						color: white;
					}
					.home-container-clients-column-container-left-welcome-nodes-content-card-full .home-container-clients-column-container-left-welcome-start-add-text-button .home-container-clients-column-container-left-welcome-start-add-text-button-icon {
						color: white !important;
					}
	.home-container-clients-column-container-right-header-container {
		padding: 24px;
		padding-top: 38px;
		position: relative;
		padding-bottom: 38px;
	}
		.home-container-clients-column-container-right-header-container h2 {
		    font-size: 28px;
		    color: #ffffff;
		    margin: 0px;
		    margin-bottom: 12px;
		}
		.home-container-clients-column-container-right-header-container h4 {
		    font-size: 18px;
		    color: #6C6C6C;
		    font-weight: 400;
		    margin: 0px;
		    margin-bottom: 4px;
		}
		.home-container-clients-column-container-right-header-container span {
		    color: #ffffff;
		    font-weight: 600;
		}
		.home-container-clients-column-container-right-header-button-container {
			position: absolute;
			top: 40px;
			right: 20px;
			width: 29px;
			height: 29px;
			border-radius: 5px;
			padding: 3px 5px;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
		.home-container-clients-column-container-right-header-button-container:hover {
			background: rgba(0, 0, 0, 0.3);
			cursor: pointer;
			box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.08);
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
			.home-container-clients-column-container-right-header-button-icon {
				color: #6C6C6C;
				font-size: 21px !important;
			}
			.home-container-clients-column-container-right-header-button-container:hover .home-container-clients-column-container-right-header-button-icon {
				color: #5B62FF;
			}

		.home-container-clients-column-container-right-loading-curtain {
			width: 100%;
			position: absolute;
			height: calc(100% - 74px);
			z-index: 10;
			top: 74px;
			padding-top: calc(40vh - 100px);
			background: #1617193F;
			-webkit-backdrop-filter: blur(4px);
			backdrop-filter: blur(4px);
		}
			.home-container-clients-column-container-right-loading-curtain p {
			    font-size: 13.5px;
			    color: #9DA0AD;
			    margin: 0px;
			    margin-top: 12px;
			    width: 100%;
			    text-align: center;
			}
	.home-container-clients-column-container-right-section-container {
		padding: 8px 20px;
	}

/* Mobile CSS */
@media screen and (max-width: 560px) {
	.home-container-clients-column-container-right {
		display: none;
	}
	.home-container-clients-column-container-left {
		width: 100% !important;
		border-right: none;
	}
	.home-container-clients-column-container-left-welcome-inner {
		padding: 32px 20px;
	}
}

