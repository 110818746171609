.page-full-height-width-exercise-list {
	margin-top: 36px;
}

.exercise-list-upper-content {
	width: 100%;
	display: flex;
}
	.exercise-list-upper-selector-container {
		width: 20%;
	}
	.exercise-list-upper-search-container {
		width: 40%;
		padding-right: 12px;
	}
	.exercise-list-upper-content-add-button-container {
		width: 106px !important;
		margin-left: auto;
	}
		.exercise-list-upper-content-add-button {
				padding: 11px 24px;
				padding-right: 12px;
				display: flex;
				font-size: 10px;
				border-radius: 5px;
				background: black;
				margin-left: 12px;
				color: #ffffff !important;
				background: #181B2A;
				border: solid;
				border-width: 0.5px;
				border-color: #2E3247;
				box-shadow: 0 2px 20px 0 rgba(5, 5, 23, 0.2);
			}
				.exercise-list-upper-content-add-button-icon {
					font-size: 10px !important;
					margin-top: 2px;
					margin-right: 2px;
					margin-left: auto;
				}
	.exercise-list-list-headding {
		padding-left: 24px !important;
	}
	.exercise-list-headding-row-first {
		width: calc(40% - 15px) !important;
	}
	.exercise-list-headding-row {
		width: 20% !important;
	}
.exercise-list-blank-page-container {
	width: 100%;
}
	.exercise-list-blank-page-content {
		padding-top: 100px;
		width: 100%;
	}
		.exercise-list-blank-page-content p {
			font-size: 10px;
			color: #6C6C6C;
			width: 100%;
			text-align: center;
		}