/* .goal-skill-column- */

.goal-skill-column {
    width: 100%;
    display: block;
    margin-bottom: 12px;
    padding-right: 24px;
    min-width: 400px;
    background: rgba(0,0,0,0.2);
    padding: 0px 12px;
    margin-right: 12px;
    border-radius: 5px;
    /*background: #1F1F21;
    border-radius: 5px;
    border: solid;
    border-width: 1px;
    border-color: #2B2B30;
    box-shadow: 0 2px 12px 2px rgba(0, 0, 0, 0.1);*/
}
    .goal-skill-column-header {
        width: 100%;
        border-bottom: solid;
        border-width: 1px;
        border-color: #2B2B30;
        padding: 16px 0px;
        display: flex;
    }
        .goal-skill-column-header-number-container {
            padding-right: 12px;
        }
            .goal-skill-column-header-number-container p {
                font-size: 10px;
                color: #6C6C6C;
                margin: 0px;
            }
            .goal-skill-column-header-number-container h4 {
                font-size: 20px;
                color: #ffffff;
                margin: 0px;
            }
        .goal-skill-column-header-skill-desc-container {
            width: 100%;
            padding-right: 12px;
        }
            
            .goal-skill-column-header-input-header-container {
                padding-bottom: 2px;
                display: flex;
            }
                .goal-skill-column-header-input-header-container p {
                    font-size: 10px;
                    color: #6C6C6C;
                    margin: 0px;
                    margin-right: 8px;
                }
            .goal-skill-column-header-input-container {
                border-bottom: dotted;
                border-width: 2px;
                border-color: #4959C1;
                margin-bottom: 2px;

            }
                .goal-skill-column-header-input-container ion-textarea {
                    font-size: 13.5px;
                    color: #ffffff;
                    font-weight: 700;
                    min-height: 16px;
                    margin-top: 2px;
                    margin-bottom: 2px;
                    --placeholder-color: #5C6BC9;
                    --placeholder-opacity: 1.0;
                }
                .goal-skill-column-header-input-container ion-textarea .textarea-wrapper {
                    width: 100%;
                    min-height: 20px !important;
                }
                .goal-skill-column-header-input-container ion-textarea .textarea-wrapper .native-textarea {
                    width: 100%;
                    min-height: 20px !important;
                }
                .goal-skill-column-header-input-container ion-input {
                    font-size: 13.5px;
                    color: rgba(112,138,240,1.0);
                    font-weight: 700;
                    min-height: 16px;
                    --placeholder-color: #5C6BC9;
                    --placeholder-opacity: 1.0;
                }
        .goal-skill-column-header-skill-date-container {
            width: 110px;
            padding-top: 0px;
        }
            .goal-skill-column-header-date-container {
                width: 100%;
            }
                .goal-skill-column-header-date-container h4 {
                    font-size: 13.5px;
                    color: #6C6C6C;
                    margin: 0px;
                    margin-top: 2px;
                }


    .goal-skill-column-body {
        padding: 12px 0px;
    }
        .goal-skill-column-body-header {
            padding-top: 6px;
        }
            .goal-skill-column-body-header h4 {
                font-size: 10px;
                color: #6C6C6C;
                margin: 0px;
            }
            .goal-skill-column-body-actions-container {
                padding: 12px 0px;
            }

    .goal-skill-column-body-add-actions-container {
        cursor: pointer;
    }
        .goal-skill-column-body-add-actions-container p {
            font-size: 13.5px;
            color: #4959C1;
            margin: 0px;
            -webkit-transition: all 0.15s;
            -moz-transition: all 0.15s;
            transition: all 0.15s;
        }
        .goal-skill-column-body-add-actions-container:hover p {
            color: #5C6BC9;
            -webkit-transition: all 0.15s;
            -moz-transition: all 0.15s;
            transition: all 0.15s;
        }




