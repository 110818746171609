/* .workouts-more-loading-patterns- */

.workouts-more-loading-patterns {
	width: 100%;
	position: relative;
}
	.workouts-more-loading-patterns-button {
		position: absolute;
		top: -54px;
		right: 32px;
	    padding: 7px 12px;
	    background: #5B62FF;
	    display: flex;
	    border-radius: 5px;
	    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.12);
	    -webkit-transition: all 0.15s;
	    -moz-transition: all 0.15s;
	    transition: all 0.15s;
	}
	.workouts-more-loading-patterns-button:hover {
	    background: #7278F2;
	    cursor: pointer;
	    -webkit-transition: all 0.15s;
	    -moz-transition: all 0.15s;
	    transition: all 0.15s;
	}
		.workouts-more-loading-patterns-button p {
		    font-size: 12px;
		    color: white;
		    font-weight: 500;
		    margin: 0px;
		}
		.workouts-more-loading-patterns-button-icon {
			font-size: 15px !important;
			color: white;
			margin-right: 6px;
		}
	.workouts-more-loading-patterns-header-row {
		width: 100%;
		padding: 16px 32px;
		background: #1F1F21;
		display: flex;
		border-bottom: solid;
		border-width: 1px;
		border-color: #2B2B30;
	}
		.workouts-more-loading-patterns-header-row h4 {
		    font-size: 13.5px;
		    color: #ffffff;
		    margin: 0px;
		}
		.workouts-more-loading-patterns-header-row p {
		    font-size: 13.5px;
		    color: #7278F2;
		    margin: 0px;
		    margin-left: 6px;
		}
	.workouts-more-loading-patterns-content {
		width: 100%;
	}
		.workouts-more-loading-patterns-content-row {
			width: 100%;
			border-bottom: solid;
			border-width: 1px;
			border-color: #2B2B30;
			display: flex;
			padding: 16px 32px;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
		.workouts-more-loading-patterns-content-row:hover {
			background: #1F1F21;
			cursor: pointer;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
			.workouts-more-loading-patterns-content-col {
				display: flex;
			}
				.workouts-more-loading-patterns-content-col h4 {
				    font-size: 13.5px;
				    color: #ffffff;
				    margin: 0px;
				}
				.workouts-more-loading-patterns-content-col p {
				    font-size: 13.5px;
				    color: #ffffff;
				    margin: 0px;
				}

				.workouts-more-loading-patterns-content-col-name {
					width: calc(35% - 28px);
				}
				.workouts-more-loading-patterns-content-col-type {
					width: 30%;
				}
				.workouts-more-loading-patterns-content-col-reps {
					width: 35%;
					padding-right: 12px;
				}
					.workouts-more-loading-patterns-content-col-reps-container {
						display: flex;
					}
						.workouts-more-loading-patterns-content-col-reps-container-inner {
							padding-right: 8px;
						}
							.workouts-more-loading-patterns-content-col-reps-container-inner p {
							    font-size: 13.5px;
							    color: white;
							    font-weight: 500;
							    margin: 0px;
							}
						.workouts-more-loading-patterns-content-col-reps-volume-container {
								margin-left: auto;
							    float: right;
						}
							.workouts-more-loading-patterns-content-col-reps-volume-container p {
							    font-size: 13.5px;
							    color: #7278F2;
							    margin: 0px;
							}
				.workouts-more-loading-patterns-content-col-close {
					width: 28px;
					height: 28px;
					border-radius: 5px;
					padding: 6px;
					margin-bottom: -8px;
					margin-top: -6px;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
				.workouts-more-loading-patterns-content-col-close:hover {
					background: rgba(0, 0, 0, 0.3);
					cursor: pointer;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
					.workouts-more-loading-patterns-content-col-close-icon {
						font-size: 16px !important;
						color: #6C6C6C;
					}
					.workouts-more-loading-patterns-content-col-close:hover .workouts-more-loading-patterns-content-col-close-icon {
						color: #FF4D4D;
					}





