.create-workout-set-rep-input {
	display: flex;
	border-color: #2B2B30;
	background: rgba(0,0,0,0.1);
	border-width: 1px;
	border-style: solid;
	border-radius: 5px;
	width: 100%;
	position: relative;
	height: 36px;
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
}
.create-workout-set-rep-input-focus {
	border-color: #5B62FF;
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
}
	.create-workout-set-rep-input-floating-unit {
		position: absolute;
		right: 32px;
		top: 10px;
	}
		.create-workout-set-rep-input-floating-unit p {
			font-size: 12px;
			font-weight: 40;
			margin: 0px;
			color: var(--ef-text-dark) !important;
		}
	.create-workout-set-rep-input-amrap {
		width: 100%;
		position: absolute;
	}
		.create-workout-set-rep-input-amrap h4 {
			font-size: 13.5px;
			columns: #ffffff;
			font-weight: 500;
			margin: 0px;
			width: 100%;
			text-align: center;
			padding-top: 9px;
		}
		.create-workout-set-rep-input-amrap-show {
			opacity: 1.0;
		}
		.create-workout-set-rep-input-amrap-hide {
			opacity: 0.0;
		}
	.create-workout-set-rep-input-empty {
		width: 100%;
		position: absolute;
	}
		.create-workout-set-rep-input-empty h4 {
			font-size: 13.5px;
			color: #6C6C6C;
			font-weight: 300;
			margin: 0px;
			width: 100%;
			text-align: center;
			padding-top: 9px;
		}
		.create-workout-set-rep-input-empty-show {
			opacity: 1.0;
		}
		.create-workout-set-rep-input-empty-hide {
			opacity: 0.0;
		}
	.create-workout-set-rep-input-number {
		position: absolute;
	}
	.create-workout-set-rep-input-number ion-input native-input {
		padding-left: 0px !important;
		-webkit-padding-start: 0px;
	}
		.create-workout-set-rep-input-number-show {
			opacity: 1.0;
		}
		.create-workout-set-rep-input-number-hide {
			opacity: 0.0;
		}

	.create-workout-set-rep-input-key-hint {
		position: absolute;
		right: 12px;
		top: 11px;
		height: 14px;
		width: 14px;
		border-radius: 2px;
		background: #6C6C6C;
		padding-top: 0.5px;
	}
		.create-workout-set-rep-input-key-hint p {
			font-size: 10px;
			font-weight: 700;
			margin: 0px;
			width: 100%;
			text-align: center;
			color: #1F1F21;
		}

	.create-workout-set-rep-input-typing-blinker {
        position: absolute;
        top: 9px;
        right: calc(50% - 26px);
        height: 16px;
        width: 1px;
        background: #ffffff;
        animation: blinker 1s linear infinite;
    }
        @keyframes blinker {
            0% { opacity: 0.0; }
            45% { opacity: 0.0; }
            50% { opacity: 1.0; }
            95% { opacity: 1.0; }
            100% { opacity: 0.0; }
        }