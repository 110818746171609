/* .client-profile-measurements- */

.client-profile-measurements {

}
	.client-profile-measurements-body-card {
		width: 100%;
		background-color: transparent;
		border-bottom: solid;
		border-width: 0.5px;
		border-color: #2B2B30;
		box-shadow: 0 4px 38px 0 rgba(0, 0, 0, 0.1);
		min-height: 80px;
		padding: 24px 0px;
	}
		.client-profile-measurements-body-card-header {
			width: 100%;
			display: flex;
			padding: 0px 28px;
			padding-bottom: 24px;
			border-bottom: solid;
			border-width: 1px;
			border-color: #2B2B30;
		}
			.client-profile-measurements-body-card-header-text-container {
				padding-top: 1px;
			}
				.client-profile-measurements-body-card-header-text-container h4 {
					font-size: 13.5px;
					color: #ffffff;
					margin: 0px;
				} 
				.client-profile-measurements-body-card-header-text-container p {
					font-size: 12px;
					color: #ffffff;
					margin: 0px;
					padding-top: 1px;
					
				} 
			.client-profile-measurements-body-card-header-button-container {
				background: #5B62FF;
				border-radius: 5px;
				padding: 6px 12px;
				height: 29px;
				float: right;
				margin-left: auto;
				display: flex;
				box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
			.client-profile-measurements-body-card-header-button-container:hover {
				background: #7278F2;
				cursor: pointer;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
				.client-profile-measurements-body-card-header-button-container p {
					color: white;
					font-size: 12px;
					font-weight: 500;
					margin: 0px;
					margin-left: 6px;
					margin-top: 1px;
				} 
				.client-profile-measurements-body-card-header-button-icon {
					font-size: 14px !important;
					color: white;
					margin-top: 1px;
				}
	.client-profile-measurements-body-container {
		width: 100%;
		position: relative;
	}
		.client-profile-measurements-body-blank-container {
			position: absolute;
			width: 100%;
			top: 200px;
			left: 0px;
			text-align: center;
			z-index: 0;
		}
			.client-profile-measurements-body-blank-container h4 {
				color: #ffffff;
				font-size: 13.5px;
				margin: 0px;
				font-weight: 600;
				margin-bottom: 4px;
			}
			.client-profile-measurements-body-blank-container p {
				color: #9DA0AD;
				font-size: 13.5px;
				margin: 0px;
				width: 72%;
				margin: 0px auto;
			}
		.client-profile-measurements-body-container-inner {
			width: 350px;
			margin: 0px auto;
			
		}
		.client-profile-measurements-body-container-inner-hidden {
			opacity: 0.12;
		}


		.client-profile-measurements-history-card {
			max-height: 400px;
		}
			.client-profile-measurements-card-content {
				width: 100%;
				height: calc(100% - 22px);
			}
				.client-profile-measurements-card-content-list-container {
					width: 100%;
					padding: 16px;
					height: 100%;
				}
					.client-profile-measurements-card-content-list-header {
						display: flex;
						padding-bottom: 8px;
						border-bottom: solid;
						border-width: 1px;
						border-color: #2B2B30;
					}
						.client-profile-measurements-card-content-list-header-col {

						}
							.client-profile-measurements-card-content-list-header-col p {
								font-size: 10px;
								color: #6C6C6C;
								margin: 0px;
							}
						.client-profile-measurements-card-content-list-col-0 {
							width: 40%;
						}
						.client-profile-measurements-card-content-list-col-1 {
							width: 30%;
						}
						.client-profile-measurements-card-content-list-col-2 {
							width: 30%;
						}
			.client-profile-measurements-card-content-list-content {
				width: 100%;
				height: 100%;
				overflow-y: scroll;
			}
				.client-profile-measurements-card-content-list-cell {
					width: 100%;
					display: flex;
					padding: 8px 0px;
					border-bottom: solid;
					border-width: 0.5px;
					border-color: #2B2B30;
				}
				.client-profile-measurements-card-content-list-cell:hover {
					background: #1F1F21;
					border-radius: 5px;
				}
					.client-profile-measurements-card-content-list-cell-col {

					}
						.client-profile-measurements-card-content-list-cell-col p {
							font-size: 13.5px;
							color: #ffffff;
							margin: 0px;
						}

.client-profile-list-spacer {
	width: 100%;
	height: 12px;
}






