/* .calendar-month- */

.calendar-month {
	width: 100%;
	height: 100%;

}
	.calendar-month-weekdays-container {
		width: 100%;

		display: flex;
	}
		.calendar-month-weekdays {
			width: 14.14%;
			padding: 12px 0px;
		}
			.calendar-month-weekdays p {
			    font-size: 12px;
			    color: #9DA0AD;
			    margin: 0px;
			}
	.calendar-month-content-container {
		width: 100%;
		display: inline-block;
		padding: 12px 0px;
	}
		.calendar-month-day {
			min-width: calc(14% - 16px);
			max-width: calc(14.28% - 16px);
			height: auto/*270px*/;
			/*overflow-y: hidden;*/
			margin-right: 16px;
			display: inline-table;
			position: relative;
			padding: 0px;
			min-height: 260px;
			margin-top: 0px;
			padding-bottom: 24px;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
			.calendar-month-day-line-upper {
				width: 100%;
				height: 3px;
				background: #2B2B30;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
			.calendar-month-day:hover .calendar-month-day-line-upper {
				background: #9DA0AD;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
			.calendar-month-day-inner {
				padding-top: 4px;
				position: relative;
			}
				.calendar-month-day-inner p {
				    font-size: 28px;
				    color: #9DA0AD;
				    margin: 0px;
				    -webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
				.calendar-month-day:hover .calendar-month-day-inner p {
					color: white;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
				.calendar-month-day:hover .calendar-month-day-inner-book-button p {
					color: #5B62FF;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
				.calendar-month-day-inner-book-button {
					position: absolute;
					top: 0px;
					right: 0px;
					display: flex;
					padding: 12px 0px;
					opacity: 0.0;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
				.calendar-month-day:hover .calendar-month-day-inner-book-button {
					opacity: 1.0;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
				.calendar-month-day-inner-book-button:hover {
					cursor: pointer;
				}
					.calendar-month-day-inner-book-button p {
					    font-size: 12px;
					    color: #5B62FF;
					    margin: 0px;
					}
					.calendar-month-day-inner-book-button:hover p {
					    color: #7278F2 !important;
					}
				.calendar-month-day-inner-book-button-icon {
					font-size: 14px !important;
					color: #5B62FF;
					margin-left: 4px;
					margin-top: 1px;
				}
					.calendar-month-day-inner-book-button:hover .calendar-month-day-inner-book-button-icon {
						color: #7278F2
					}
				.calendar-month-day-create-workout-cell-container {
					width: 100%;
					display: none;
				}
				.calendar-month-day:hover .calendar-month-day-create-workout-cell-container {
					display: block;
					cursor: pointer;
				}
					.calendar-month-day-create-workout-cell-container p {
					    font-size: 13.5px;
					    color: #9DA0AD !important;
					    margin: 0px;
					    width: 100%;
					    text-align: center;
					    margin-top: 12px;
					}
					.calendar-month-day-create-workout-cell-add-container {
						width: 32px;
						height: 32px;
						padding: 6px;
						background: rgba(0, 0, 0, 0.2);
						border-radius: 5px;
						margin: 0px auto;
						margin-top: 42px;
					}
						.calendar-month-day-create-workout-cell-add-icon {
							font-size: 20px !important;
							color: #5B62FF;
						}
						
			.calendar-month-day-inner-invisible {

			}
				.calendar-month-day-inner-invisible p {
					color: transparent;
				}









				