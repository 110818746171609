.blank-page-container {
	width: 100%;
	display: block;
}

.blank-page-content {
	display: block;
	width: 100%;
	margin: auto;
}

.blank-page-content h4 {
	text-align: center;
	padding-top: calc(50vh - 200px);
	font-size: 13.5px;
	color: #ffffff;
}

.blank-page-content p {
	text-align: center;
	margin-top: 0px;
	font-size: 10px;
	color: #ffffff;
}