.muscle-selector {
    width: 100%;
    padding: 0px 24px;
    
}
    .muscle-selector-content {
        width: 440px;
        height: 400px;
        margin: 0px auto;
        position: relative;
    }
        .muscle-selector-muscle-buttons-container {
            width: 100%;
            height: 100%;
            margin: 0px auto;
            position: absolute;
            /*background: rgba(255,0,0,0.2);*/
            z-index: 300;
        }
            .muscle-selector-muscle-buttons-inner-container {
                width: 100%;
                height: 100%;
                position: relative;
            }
                .muscle-selector-muscle-buttons {
                    cursor: pointer;
                }
                .muscle-selector-muscle-buttons-Rectus-Abdominis {
                    position: absolute;
                    top: 110px;
                    left: 85px;
                    width: 40px;
                    height: 80px;
                    /*background: rgba(0,255,0,0.2);*/
                }
                .muscle-selector-muscle-buttons-External-Obliques-1 {
                    position: absolute;
                    top: 110px;
                    left: 70px;
                    width: 20px;
                    height: 75px;
                    z-index: 200;
                }
                .muscle-selector-muscle-buttons-External-Obliques-2 {
                    position: absolute;
                    top: 110px;
                    left: 120px;
                    width: 20px;
                    height: 75px;
                    z-index: 200;
                }
                .muscle-selector-muscle-buttons-Internal-Obliques-1 {
                    position: absolute;
                    top: 130px;
                    left: 70px;
                    width: 20px;
                    height: 30px;
                    z-index: 300;
                }
                .muscle-selector-muscle-buttons-Internal-Obliques-2 {
                    position: absolute;
                    top: 130px;
                    left: 120px;
                    width: 20px;
                    height: 30px;
                    z-index: 300;
                }
                .muscle-selector-muscle-buttons-Biceps-Short-Head-1 {
                    position: absolute;
                    top: 100px;
                    left: 55px;
                    width: 15px;
                    height: 40px;
                    z-index: 300;
                }
                .muscle-selector-muscle-buttons-Biceps-Short-Head-2 {
                    position: absolute;
                    top: 100px;
                    left: 138px;
                    width: 15px;
                    height: 40px;
                    z-index: 300;
                }
                .muscle-selector-muscle-buttons-Biceps-Long-Head-1 {
                    position: absolute;
                    top: 100px;
                    left: 40px;
                    width: 15px;
                    height: 40px;
                    z-index: 300;
                }
                .muscle-selector-muscle-buttons-Biceps-Long-Head-2 {
                    position: absolute;
                    top: 100px;
                    left: 153px;
                    width: 15px;
                    height: 40px;
                    z-index: 300;
                }
                .muscle-selector-muscle-buttons-Triceps-Long-Head-1 {
                    position: absolute;
                    top: 95px;
                    left: 270px;
                    width: 15px;
                    height: 35px;
                    z-index: 200;
                }
                .muscle-selector-muscle-buttons-Triceps-Long-Head-2 {
                    position: absolute;
                    top: 95px;
                    left: 352px;
                    width: 15px;
                    height: 35px;
                    z-index: 200;
                }
                .muscle-selector-muscle-buttons-Triceps-Lateral-Head-1 {
                    position: absolute;
                    top: 95px;
                    left: 255px;
                    width: 18px;
                    height: 30px;
                    z-index: 300;
                }
                .muscle-selector-muscle-buttons-Triceps-Lateral-Head-2 {
                    position: absolute;
                    top: 95px;
                    left: 364px;
                    width: 18px;
                    height: 30px;
                    z-index: 300;
                }
                .muscle-selector-muscle-buttons-Triceps-Medial-Head-1 {
                    position: absolute;
                    top: 125px;
                    left: 255px;
                    width: 30px;
                    height: 20px;
                    z-index: 400;
                }
                .muscle-selector-muscle-buttons-Triceps-Medial-Head-2 {
                    position: absolute;
                    top: 125px;
                    left: 354px;
                    width: 30px;
                    height: 20px;
                    z-index: 400;
                }

                .muscle-selector-muscle-buttons-Brachioradialis-1 {
                    /*background: rgba(255,0,0,0.2);*/
                    position: absolute;
                    top: 131px;
                    left: 31px;
                    width: 13px;
                    border-radius: 6.5px;
                    height: 55px;
                    z-index: 400;
                    transform: rotate(20deg);
                }
                .muscle-selector-muscle-buttons-Brachioradialis-2 {
                    /*background: rgba(255,0,0,0.2);*/
                    position: absolute;
                    top: 131px;
                    left: 165px;
                    width: 13px;
                    border-radius: 6.5px;
                    height: 55px;
                    z-index: 400;
                    transform: rotate(-20deg);
                }
                .muscle-selector-muscle-buttons-Brachioradialis-3 {
                    position: absolute;
                    top: 125px;
                    left: 247px;
                    width: 15px;
                    height: 65px;
                    z-index: 400;
                    transform: rotate(20deg);
                }
                .muscle-selector-muscle-buttons-Brachioradialis-4 {
                    position: absolute;
                    top: 125px;
                    left: 377px;
                    width: 15px;
                    height: 65px;
                    z-index: 400;
                    transform: rotate(-20deg);
                }
                .muscle-selector-muscle-buttons-Flexor-Carpi-1 {
                    position: absolute;
                    top: 130px;
                    left: 48px;
                    width: 15px;
                    height: 58px;
                    z-index: 400;
                    transform: rotate(20deg);
                }
                .muscle-selector-muscle-buttons-Flexor-Carpi-2 {
                    position: absolute;
                    top: 133px;
                    left: 148px;
                    width: 15px;
                    height: 58px;
                    z-index: 400;
                    transform: rotate(-20deg);
                }
                .muscle-selector-muscle-buttons-Trapezius-1 {
                    position: absolute;
                    top: 55px;
                    left: 75px;
                    width: 60px;
                    height: 25px;
                    z-index: 400;
                }
                .muscle-selector-muscle-buttons-Trapezius-2 {
                    position: absolute;
                    top: 65px;
                    left: 290px;
                    width: 57px;
                    height: 60px;
                    z-index: 200;
                }
                .muscle-selector-muscle-buttons-Rhomboid-Major-1 {
                    position: absolute;
                    top: 95px;
                    left: 290px;
                    width: 57px;
                    height: 30px;
                    z-index: 300;
                }
                .muscle-selector-muscle-buttons-Latissimus-Dorsi-1 {
                    position: absolute;
                    top: 95px;
                    left: 285px;
                    width: 25px;
                    height: 55px;
                    z-index: 400;
                }
                .muscle-selector-muscle-buttons-Latissimus-Dorsi-2 {
                    position: absolute;
                    top: 95px;
                    left: 327px;
                    width: 25px;
                    height: 55px;
                    z-index: 400;
                }
                .muscle-selector-muscle-buttons-Erector-Spinae {
                    position: absolute;
                    top: 125px;
                    left: 300px;
                    width: 40px;
                    height: 55px;
                    z-index: 500;
                }

                .muscle-selector-muscle-buttons-Pectoralis-Clavicular-Head {
                    position: absolute;
                    top: 75px;
                    left: 72px;
                    width: 65px;
                    height: 15px;
                    z-index: 200;
                }
                .muscle-selector-muscle-buttons-Pectoralis-Sternocosial-Head {
                    position: absolute;
                    top: 90px;
                    left: 72px;
                    width: 65px;
                    height: 22px;
                    z-index: 500;
                }

                .muscle-selector-muscle-buttons-Deltoid-Lateral-Head-1 {
                    position: absolute;
                    top: 70px;
                    left: 50px;
                    width: 23px;
                    height: 32px;
                    z-index: 200;
                }
                .muscle-selector-muscle-buttons-Deltoid-Lateral-Head-2 {
                    position: absolute;
                    top: 70px;
                    left: 135px;
                    width: 23px;
                    height: 32px;
                    z-index: 200;
                }

                .muscle-selector-muscle-buttons-Deltoid-Anterior-Head-1 {
                    position: absolute;
                    top: 80px;
                    left: 61px;
                    width: 15px;
                    height: 28px;
                    z-index: 500;
                    border-radius: 10px;
                    transform: rotate(30deg);
                }

                .muscle-selector-muscle-buttons-Deltoid-Anterior-Head-2 {
                    position: absolute;
                    top: 80px;
                    left: 135px;
                    width: 15px;
                    height: 28px;
                    z-index: 500;
                    border-radius: 10px;
                    transform: rotate(-30deg);
                }
                .muscle-selector-muscle-buttons-Deltoid-Posterior-Head-1 {
                    position: absolute;
                    top: 75px;
                    left: 274px;
                    width: 15px;
                    height: 32px;
                    z-index: 500;
                    border-radius: 10px;
                    transform: rotate(40deg);
                }
                .muscle-selector-muscle-buttons-Deltoid-Posterior-Head-2 {
                    position: absolute;
                    top: 75px;
                    left: 346px;
                    width: 15px;
                    height: 32px;
                    z-index: 500;
                    border-radius: 10px;
                    transform: rotate(-40deg);
                }

                .muscle-selector-muscle-buttons-Gastrocnemius-Lateral-1 {
                    position: absolute;
                    top: 276px;
                    left: 277px;
                    width: 15px;
                    height: 43px;
                    z-index: 200;
                }
                .muscle-selector-muscle-buttons-Gastrocnemius-Lateral-2 {
                    position: absolute;
                    top: 276px;
                    left: 345px;
                    width: 15px;
                    height: 43px;
                    z-index: 200;
                    
                }

                .muscle-selector-muscle-buttons-Gastrocnemius-Medial-1 {
                    position: absolute;
                    top: 279px;
                    left: 292px;
                    width: 16px;
                    height: 47px;
                    z-index: 500;
                    border-radius: 7px;
                    
                }
                .muscle-selector-muscle-buttons-Gastrocnemius-Medial-2 {
                    position: absolute;
                    top: 279px;
                    left: 329px;
                    width: 16px;
                    height: 47px;
                    z-index: 500;
                    border-radius: 7px;
                }
                .muscle-selector-muscle-buttons-Gastrocnemius-Medial-3 {
                    position: absolute;
                    top: 285px;
                    left: 80px;
                    width: 15px;
                    height: 70px;
                    z-index: 200;
                    border-radius: 7px;
                }
                .muscle-selector-muscle-buttons-Gastrocnemius-Medial-4 {
                    position: absolute;
                    top: 285px;
                    left: 116px;
                    width: 15px;
                    height: 70px;
                    z-index: 200;
                    border-radius: 7px;
                }




                .muscle-selector-muscle-buttons-Soleus-1 {
                    position: absolute;
                    top: 320px;
                    left: 275px;
                    width: 32px;
                    height: 52px;
                    z-index: 100;
                }
                .muscle-selector-muscle-buttons-Soleus-2 {
                    position: absolute;
                    top: 320px;
                    left: 328px;
                    width: 32px;
                    height: 52px;
                    z-index: 100;
                }

                .muscle-selector-muscle-buttons-Tibialis-Anterior-1 {
                    position: absolute;
                    top: 285px;
                    left: 64px;
                    width: 15px;
                    height: 70px;
                    z-index: 200;
                    border-radius: 7px;
                }
                .muscle-selector-muscle-buttons-Tibialis-Anterior-2 {
                    position: absolute;
                    top: 285px;
                    left: 132px;
                    width: 15px;
                    height: 70px;
                    z-index: 200;
                    border-radius: 7px;
                }


                .muscle-selector-muscle-buttons-Rectus-Femoris-1 {
                    position: absolute;
                    top: 180px;
                    left: 70px;
                    width: 22px;
                    height: 100px;
                    z-index: 200;
                }
                .muscle-selector-muscle-buttons-Rectus-Femoris-2 {
                    position: absolute;
                    top: 180px;
                    left: 117px;
                    width: 22px;
                    height: 100px;
                    z-index: 200;
                }
                .muscle-selector-muscle-buttons-Vastus-Lateralis-1 {
                    position: absolute;
                    top: 170px;
                    left: 60px;
                    width: 15px;
                    height: 100px;
                    z-index: 300;
                }
                .muscle-selector-muscle-buttons-Vastus-Lateralis-2 {
                    position: absolute;
                    top: 170px;
                    left: 135px;
                    width: 15px;
                    height: 100px;
                    z-index: 300;
                }

                .muscle-selector-muscle-buttons-Adductor-Longus-1 {
                    position: absolute;
                    top: 180px;
                    left: 83px;
                    width: 20px;
                    height: 50px;
                    z-index: 400;
                    border-radius: 10px;
                    transform: rotate(-20deg);
                }
                .muscle-selector-muscle-buttons-Adductor-Longus-2 {
                    position: absolute;
                    top: 180px;
                    left: 103px;
                    width: 20px;
                    height: 50px;
                    z-index: 400;
                    border-radius: 10px;
                    transform: rotate(20deg);
                }
                .muscle-selector-muscle-buttons-Vastus-Medialis-1 {
                    position: absolute;
                    top: 240px;
                    left: 85px;
                    width: 15px;
                    height: 40px;
                    z-index: 400;
                }
                .muscle-selector-muscle-buttons-Vastus-Medialis-2 {
                    position: absolute;
                    top: 240px;
                    left: 110px;
                    width: 15px;
                    height: 40px;
                    z-index: 400;
                }
                .muscle-selector-muscle-buttons-Adductor-Mangus-1 {
                    position: absolute;
                    top: 200px;
                    left: 306px;
                    width: 12px;
                    height: 27px;
                    z-index: 500;
                }
                .muscle-selector-muscle-buttons-Adductor-Mangus-2 {
                    position: absolute;
                    top: 200px;
                    left: 320px;
                    width: 12px;
                    height: 27px;
                    z-index: 500;
                }
                .muscle-selector-muscle-buttons-Bicep-Femoris-1 {
                    position: absolute;
                    top: 200px;
                    left: 273px;
                    width: 25px;
                    height: 80px;
                    z-index: 300;
                    border-radius: 15px;
                }
                .muscle-selector-muscle-buttons-Bicep-Femoris-2 {
                    position: absolute;
                    top: 200px;
                    left: 340px;
                    width: 25px;
                    height: 80px;
                    z-index: 300;
                    border-radius: 15px;
                }

                .muscle-selector-muscle-buttons-Semitendinosus-1 {
                    position: absolute;
                    top: 200px;
                    left: 295px;
                    width: 25px;
                    height: 80px;
                    z-index: 100;
                    border-radius: 15px;
                }
                .muscle-selector-muscle-buttons-Semitendinosus-2 {
                    position: absolute;
                    top: 200px;
                    left: 315px;
                    width: 25px;
                    height: 80px;
                    z-index: 100;
                    border-radius: 15px;
                }


                .muscle-selector-muscle-buttons-Gluteus-Medius-1 {
                    position: absolute;
                    top: 153px;
                    left: 280px;
                    width: 25px;
                    height: 25px;
                    z-index: 600;
                }
                .muscle-selector-muscle-buttons-Gluteus-Medius-2 {
                    position: absolute;
                    top: 153px;
                    left: 332px;
                    width: 25px;
                    height: 25px;
                    z-index: 600;
                }
                .muscle-selector-muscle-buttons-Gluteus-Maximus-1 {
                    position: absolute;
                    top: 165px;
                    left: 285px;
                    width: 35px;
                    height: 40px;
                    z-index: 500;
                }
                .muscle-selector-muscle-buttons-Gluteus-Maximus-2 {
                    position: absolute;
                    top: 165px;
                    left: 320px;
                    width: 35px;
                    height: 40px;
                    z-index: 500;
                }

        .muscle-selector-muscle-images-container {
            width: 430px;
            height: 400px;
            /*margin: 0px auto;*/
            position: absolute;
            z-index: 200;
            top: 0px;
            left: 0px;
            /*margin-left: -2.2px;*/
        }
            .muscle-selector-body-img {
                width: 440px;
                height: 400px;
                position: absolute;
                /*background: rgba(0,0,255,0.2);*/
            }
    .muscle-selector-empty-body-container {
        height: 100%;
        width: 100%;
        margin: 0px auto;
        z-index: 100;
    }
        .muscle-selector-empty-body-img {
            height: 100%;
        }