.form-date-container {
	display: flex;
	margin-bottom: 12px;
	width: calc(100% + 12px);
	padding-top: 0px;
}

.form-width-3 {
	display: flex;
	width: 33%;
	margin-right: 12px;
	background-color: rgba(0,0,0,0.1);
	padding: 0px 12px;
	width: auto;
	border-radius: 4px;
	border: solid;
	border-color: #393D54;
	border-width: 1px !important;
	color: #ffffff;
	font-size: 13.5px;
	box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
}
	.form-width-3-focused {
		border-color: #4959C1;
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}

.form-width-3-alert {
	display: flex;
	width: 33%;
	margin-right: 12px;
	background-color: rgba(100,0,0,0.12);
	padding: 0px 12px;
	width: auto;
	border-radius: 4px;
	border: solid;
	border-color: #F02E4A;
	border-width: 1px !important;
	color: #ffffff;
	font-size: 13.5px;
}

.form-date-inner-text {
	font-size: 13.5px;
	margin: 0px;
	margin-top: 9px;
	margin-right: 6px;
	color: #ffffff;
}

.form-date-header-container {
	display: flex;
	padding-bottom: 4px;
}

.form-date-header-container p {
	width: 50%;
	margin: 0px;
	font-size: 13.5px;
	font-weight: 500;
	color: #ffffff;
}

.form-date-input-label {
	color: #C1C4CC;
}

.form-date-input-alert-label {
	color: #F02E4A !important;
	font-size: 10px !important;
	text-align: right;
	width: 100%;
}

