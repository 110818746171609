.form-body-input-container {
	width: 100%;
	position: relative;
}

.form-body-header-container-label-container {
	width: 100%;
}
	.form-body-header-container-label-container h4 {
		font-size: 13.5px;
		color: #ffffff;
		font-weight: 500;
		margin: 0px;
	}
	.form-body-header-container-label-container p {
		font-size: 10px;
		color: #6C6C6C;
		margin: 0px;
	}
	.form-body-header-container-label-container-with-desc {
		margin-top: -12px;
	}

.form-body-container {
	display: flex;
	margin-bottom: 12px;
	width: 100%;
	position: relative;
}
	.form-body-container-unit-container {
		position: absolute;
		right: 18px;
		top: -3px;
	}
		.form-body-container-unit-container p {
			font-size: 13.5px;
			color: #9DA0AD;

		}

.form-body-full {
	width: 100% !important;
	background-color: rgba(0,0,0,0.1);
	padding: 0px 12px;
	width: auto;
	border-radius: 4px;
	border: solid;
	border-color: #2B2B30;
	border-width: 1px !important;
	font-size: 13.5px;
	color: #ffffff;
}

.form-body-alert-full {
	width: 100% !important;
	background-color: rgba(100,0,0,0.12);
	padding: 0px 12px;
	width: auto;
	border-radius: 4px;
	border: solid;
	border-color: #F02E4A;
	border-width: 1px !important;
	font-size: 13.5px;
	color: #ffffff;
}

.form-body-header-container {
	height: 24px;
	display: flex;
	position: relative;
}


.form-body-input-label {
	
}

.form-body-optional-container {
	font-size: 10px !important;
	color: #6C6C6C;
	position: absolute;
	text-align: right;
	top: -4px;
	right: 0px;
}

.form-body-input-alert-label {
	font-size: 10px !important;
	color: #F02E4A;
	position: absolute;
	text-align: right;
	top: -4px;
	right: 0px;
}

