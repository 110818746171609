
.workout-profile-content-container {
	width: 100%;
	display: flex;
}
	.workout-profile-summary-container {
		width: 42%;
		display: block;
		height: calc(100vh - 130px);
	}
		.workout-profile-summary-profile-image-container {
			background-color: rgba(0,0,0,0.3);
			width: 220px;
			height: 220px;
			max-width: 220px;
			max-height: 220px;
			border-radius: 5px;
			overflow: hidden;
			margin-right: 16px;
			padding: 0px;
			box-shadow: 0 4px 38px 0 rgba(0, 0, 0, 0.3);
		}
			.workout-profile-image {
				height: 100% !important;
				width: 400px !important;
				border-radius: 4px !important;
			}
		.workout-profile-summary-header {
			padding-top: 24px;
		}
			.workout-profile-summary-header h3 {
				font-size: 28px;
				color: #ffffff;
				margin: 0px;
			}
			.workout-profile-summary-header p {
				font-size: 13.5px;
				color: #9DA0AD;
				margin: 0px;
				margin-top: 4px;
				width: calc(100% - 52px);
			}
		.workout-profile-summary-contact-container {
			width: 100%;
			display: flex;
			padding-top: 12px;
		}
			.workout-profile-summary-contact-icon-container {
				background: rgba(0,0,0,0.2);
				width: 42px;
				height: 42px;
				border-radius: 5px;
				margin-right: 12px;
				padding: 12px;
				padding-left: 14px;
			}
				.workout-profile-summary-contact-icon-container-chat {
					background: rgba(73,89,193,0.2);
				}
				.workout-profile-summary-contact-icon-container-email {
					background: rgba(73,193,115,0.2);
				}
				.workout-profile-summary-contact-icon-container-phone {
					background: rgba(156,73,193,0.2);
				}
				.workout-profile-summary-contact-icon-container-phone-unavailable {
					background: rgba(217,220,229,0.05);
				}

				.workout-profile-summary-contact-icon {
					font-size: 15px !important;
				}
					.workout-profile-summary-contact-icon-chat {
						color: rgba(73,89,193,1.0)!important;
					}
					.workout-profile-summary-contact-icon-email {
						color: rgba(73,193,115,1.0)!important;
					}
					.workout-profile-summary-contact-icon-phone {
						color: rgba(156,73,193,1.0)!important;
					}
					.workout-profile-summary-contact-icon-phone-unavailable {
						color: rgba(217,220,229,0.1)!important;
					}

		.workout-profile-summary-plan-container {
			width: 100%;
			height: calc(100% - 420px);
			
		}
			.workout-profile-summary-plan-inner-container {
				height: 130px;
				padding-top: calc(100vh - 580px);
			}
				.workout-profile-summary-plan-header-container {
					width: 100%;
				}
					.workout-profile-summary-plan-header-container h4 {
						width: 100%;
						font-size: 13.5px;
						color: #ffffff;
						margin: 0px;
					}
				.workout-profile-summary-plan-card-container {
					margin-top: 12px;
					padding: 16px 20px;
					padding-bottom: 16px;
					width: calc(100% - 24px);
					border-radius: 5px;
					background-color: #1F1F21;
					box-shadow: 0 4px 24px 6px rgba(0, 0, 0, 0.1);
					display: flex;

				}
					.workout-profile-summary-plan-card-header-icon-container {
						width: 36px;
						height: 36px;
						border-radius: 5px;
						background: rgba(73,89,193,0.2);
						padding: 10px;
						margin-top: 3px;
					}
						.workout-profile-summary-plan-card-header-icon {
							font-size: 13.5px !important;
							color: #4959C1;
						}
					.workout-profile-summary-plan-card-header-container {
						padding-left: 12px;
					}
						.workout-profile-summary-plan-card-header-container h4 {
							font-size: 13.5px;
							color: #ffffff;
							margin: 0px;
						}
						.workout-profile-summary-plan-card-header-container p {
							font-size: 10px;
							color: #9DA0AD;
							margin: 0px;
							width: 90%;
						}

					.workout-profile-summary-plan-card-completion-container {
						padding-top: 12px;
						display: flex;
					}
						.workout-profile-summary-plan-card-completion-loading-bar-container {
							width: calc(100% - 110px);
							padding-top: 3px;
						}
							.workout-profile-summary-plan-card-completion-loading-bar-outer {
								width: 100%;
								height: 6px;
								background: #1A1B2A;
								border-radius: 3px;
							}
								.workout-profile-summary-plan-card-completion-loading-bar-inner {
									height: 6px;
									background: #4959C1;
									border-radius: 3px;
								}
						

						.workout-profile-summary-plan-card-completion-loading-text-container {
							width: 100px;
							padding-left: 12px;
						}
							.workout-profile-summary-plan-card-completion-loading-text-container p {
								font-size: 10px;
								color: #9DA0AD;
								margin: 0px;
							}

				.workout-profile-summary-plan-card-container-no-plan {
					background: #1A1B2A;
					height: 100px;
					padding-top: 44px;
				}
					.workout-profile-summary-plan-card-container-no-plan p {
						font-size: 10px;
						color: #ffffff;
						width: 100%;
						text-align: center;
					}

	.workout-profile-outer-page-container {
		width: 58%;
	}

.workout-profile-inner-page-container {
	width: calc(100% + 36px);
	margin-left: -18px;
	padding: 0px 18px;
	padding-top: 24px;
	height: calc(100vh - 128px);
	overflow-y: scroll;
}