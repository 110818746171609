/* .intra-workout-stats- */

.intra-workout-stats-container {
	width: 100%;
	display: block;
	margin-top: 0px;
	height: calc(100vh - 240px);
	overflow-y: scroll;
	padding-bottom: 24px;
}

.intra-workout-stats-container-large {
	width: 100%;
	display: block;
	margin-top: 12px;
	min-height: calc(100vh - 240px);
	overflow-y: scroll;
	padding-bottom: 24px;
}

.intra-workout-stats-card {
	background-color: #111920;
	padding: 24px 24px;
	border-radius: 4px;
	box-shadow: 0 2px 20px 0 rgba(0, 0, 10, 0.08);
	width: 100%;
	margin-top: 16px;
	display: block;
}

.intra-workout-stats-card-header-container {
	width: 100%;
	height: 100%;
}

.intra-workout-stats-card-header-container h3 {
	margin: 0px;
	font-size: 18px;
	color: #C1C4CC;
}

.intra-workout-stats-card-header-container p {
	font-size: 12px;
	color: #C1C4CC;
	opacity: 0.8;
	margin: 0px;
}

.intra-workout-stats-chart-container {
	width: 100%;
	position: relative;
	margin: auto;
	/*margin-top: 24px;*/
	height: 300px;
	/*margin-top: auto;*/
	margin-bottom: 0px;
}

.intra-workout-stats-chart-container-large {
	width: 100%;
	position: relative;
	margin: auto;
	/*margin-top: 24px;*/
	min-height: 240px;
	/*margin-top: auto;*/
	margin-bottom: 0px;
}

