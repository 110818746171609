.client-profile-page-container {
	width: 100%;
}

.client-profile-info-container {
	padding-bottom: 0px;
	width: 33%;
}

.client-profile-info-header-label {
	font-size: 12px !important;
	color: #5F6C76;
}

.client-profile-info-label {
	font-size: 18px !important;
	font-weight: 500;
	color: #E5E5E5;
}

.client-profile-inline-icon {
	margin-bottom: -2px;
}

.client-profile-user-experience-pill-green {
	position: absolute;
	top: 0px;
	right: 0px;
	background-color: rgba(100,255,100,0.1);
	padding: 4px 12px;
	color: rgba(100,255,100);
	font-size: 14px;
	border-radius: 4px;
	min-width: 80px;
	margin: auto;
	text-align: center;
}

.client-profile-user-experience-pill-red {
	position: absolute;
	top: 0px;
	right: 0px;
	background-color: rgba(255,100,100,0.1);
	padding: 4px 12px;
	color: rgba(255,100,100);
	font-size: 14px;
	border-radius: 4px;
	min-width: 80px;
	margin: auto;
	text-align: center;
}

.client-profile-page-blank {
	height: 100%;
	width: 100%;
	display: block;
}

.client-profile-page-blank h2 {
	margin: auto;
	margin-top: 120px;
	font-size: 13.5px;
	text-align: center;
	color: #ffffff;
}

.client-profile-page-blank p {
	font-size: 13.5px;
	text-align: center;
	color: #9DA0AD;
}
