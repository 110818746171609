.node-stream-analysis-stream {
	width: 100%;
	height: 400px;
}

.node-stream-analysis-stream-card {
	width: 100%;
	border-radius: 12px;
	background: rgba(0, 0, 0, 0.6);
	box-shadow: 0px 0px 24px 12px rgba(0, 0, 0, 0.12);
	padding: 20px;
	height: calc(50% - 8px);
}
.node-stream-analysis-stream-card-stats {
	margin-top: 16px;
}
	.node-stream-analysis-stream-header {
		width: 100%;
		display: flex;
		white-space: nowrap;
		padding-bottom: 8px;
		position: relative;
	}
		.node-stream-analysis-stream-header h4 {
		    font-size: 12px;
		    color: #9DA0AD;
		    margin: 0px;
		    padding-right: 12px;
		    min-width: 8%;
		}
		.node-stream-analysis-stream-header h2 {
		    font-size: 12px;
		    color: white;
		    margin: 0px;
		    padding-right: 24px;
		}

		.node-stream-analysis-stream-header h3 {
		    font-size: 12px;
		    color: #FF4D4D;
		    font-weight: 700;
		    margin: 0px;
		    padding-right: 24px;
		    position: relative;
		    z-index: 100;
		}
		.node-stream-analysis-stream-header-peak-stats {
			display: flex;
		}
			.node-stream-analysis-stream-header-peak-stats h2 {
			    font-size: 12px;
			    color: white;
			    margin: 0px;
			    padding-right: 0px !important;
			}

		.node-stream-analysis-stream-header-button {
			position: absolute;
			top: -8px;
			right: -4px;
		    padding: 4px 8px;
		    background: #1F1F21;
		    border: solid;
		    border-width: 1px;
		    border-color: #2B2B30;
		    display: flex;
		    border-radius: 5px;
		    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.12);
		    -webkit-transition: all 0.15s;
		    -moz-transition: all 0.15s;
		    transition: all 0.15s;
		}
		.node-stream-analysis-stream-header-button:hover {
		    background: #1F1F21;
		    cursor: pointer;
		    -webkit-transition: all 0.15s;
		    -moz-transition: all 0.15s;
		    transition: all 0.15s;
		}

	.node-stream-analysis-stream-chart-container {
		width: 100%;
		height: calc(100% - 18px);
		border-right: solid;
		border-width: 2px;
		border-color: rgba(255, 0, 255, 1.0);;
	}