
.my-studio-page {
	width: 100%;
	display: flex;
	height: calc(100vh - 52px);
	position: relative;
	z-index: 0;
}
	.my-studio-page-content {
		height: 100%;
		overflow-y: scroll;
		padding-bottom: 60px;
	}
		.my-studio-page-content-section {
			width: 100%;
			padding: 12px 0px;

		} 
		.my-studio-page-content-section-studio-card {
			padding: 12px 38px;
		}
			.my-studio-page-content-section-header {
				width: 100%;
				display: flex;
				padding-bottom: 12px;
			}
				.my-studio-page-content-section-header h4 {
				    font-size: 13.5px;
				    color: #9DA0AD;
				    margin: 0px;
				}
				.my-studio-page-content-section-header-button {
					margin-left: auto;
					float: right;
					padding: 2px 12px;
					padding-right: 0px;
					margin-top: -2px;
					display: flex;
				}
				.my-studio-page-content-section-header-button:hover {
					cursor: pointer;
				}
					.my-studio-page-content-section-header-button p {
					    font-size: 13.5px;
					    color: #7278F2;
					    margin: 0px;
					}
					.my-studio-page-content-section-header-button-icon {
						font-size: 15px !important;
					    color: #7278F2;
					    margin-left: 6px;
					    margin-top: 1px;
					}
			/* BEGIN STUDIO CARD CSS */
			.my-studio-card {
				width: 100%;
				border-radius: 20px;
				box-shadow: 0px 0px 32px 12px rgba(0, 0, 0, 0.24);
				padding: 25px 28px;
				display: flex;
				height: 150px;
				border: solid;
				border-width: 0px;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
			.my-studio-card:hover {
				cursor: pointer;
				box-shadow: 0px 0px 36px 16px rgba(0, 0, 0, 0.32);
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
				.my-studio-card-text-container {
					width: calc(100% - 80px);
				}
					.my-studio-card-text-header-container {
						width: 100%;
					}
						.my-studio-card-text-header-container h1 {
						    font-size: 28px;
						    font-weight: 600;
						    margin: 0px;
						    margin-bottom: 2px;
						}
						.my-studio-card-text-header-container h4 {
						    font-size: 18px;
						    margin: 0px;
						}
					.my-studio-card-text-info-container {
						width: 100%;
						display: flex;
						margin-top: 20px;
					}
						.my-studio-card-text-info-container h4 {
						    font-size: 18px;
						    margin: 0px;
						    padding-right: 42px;
						}
				.my-studio-card-img-container {
					width: 80px;
					padding-top: 7px;
				}
					.my-studio-card-img-container img {
						border-radius: 8px;
					}
			/* END STUDIO CARD CSS */

			.my-studio-page-content-section-studio-card-info-text {
				width: 100%;
				margin-top: 8px;
			}
				.my-studio-page-content-section-studio-card-info-text p {
				    font-size: 12px;
				    color: #6C6C6C;
				    margin: 0px;
				    width: 100%;
				    text-align: center;
				}
				.my-studio-page-content-section-studio-card-info-text a {
					color: #7278F2;
					cursor: pointer;
					text-decoration: none;
				}

		.my-studio-page-content-section-locations {
			width: 100%;
			padding-top: 24px;
		}
			.my-studio-page-content-section-locations .my-studio-page-content-section-header {
				padding: 12px 38px;
			}
			.my-studio-page-content-section-locations-list {
				width: 100%;
			}
				.my-studio-page-content-section-locations-list-header {
					width: 100%;
					display: flex;
					background: #1F1F21;
					padding: 12px 38px;
				}
					.my-studio-page-content-section-locations-list-header-col {

					}
						.my-studio-page-content-section-locations-list-header-col p {
						    font-size: 12px;
						    color: #6C6C6C;
						    margin: 0px;
						}
					.my-studio-page-content-section-locations-list-header-col-name {
						width: 25%;
					}
					.my-studio-page-content-section-locations-list-header-col-address {
						width: 50%;
					}
					.my-studio-page-content-section-locations-list-header-col-coaches {
						width: 25%;
					}
				.my-studio-page-content-section-locations-list-row {
					width: 100%;
					display: flex;
					padding: 16px 38px;
					border-bottom: solid;
					border-width: 1px;
					border-color: #2B2B30;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
				.my-studio-page-content-section-locations-list-row:hover {
					background: #1F1F21;
					cursor: pointer;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
					.my-studio-page-content-section-locations-list-row-col {
						padding-right: 12px;
					}
						.my-studio-page-content-section-locations-list-row-col p {
						    font-size: 13.5px;
						    color: #ffffff;
						    margin: 0px;
						}
					.my-studio-page-content-section-locations-list-row .my-studio-page-content-section-locations-list-header-col-name p {
						font-weight: 600;
					}

			.my-studio-page-content-section-locations-list-content-empty {
				width: 100%;
				padding-top: 5vh;
			}
				.my-studio-page-content-section-locations-list-content-empty-img-container {
					width: 220px;
					margin: 24px auto;
				}
				.my-studio-page-content-section-locations-list-content-empty-text-container {
					width: 70%;
					min-width: 300px;
					margin: 12px auto;
				}
					.my-studio-page-content-section-locations-list-content-empty-text-container h4 {
					    font-size: 13.5px;
					    color: #ffffff;
					    margin: 0px;
					    width: 100%;
					    text-align: center;
					}
					.my-studio-page-content-section-locations-list-content-empty-text-container p {
					    font-size: 13.5px;
					    color: #9DA0AD;
					    margin: 0px;
					    width: 100%;
					    text-align: center;
					    margin-top: 4px;
					}
					.my-studio-page-content-section-locations-list-content-empty-button {
					    padding: 8px 8px;
					    margin: 24px auto;
					    width: 140px;
					    background: #5B62FF;
					    display: flex;
					    border-radius: 5px;
					    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.12);
					    -webkit-transition: all 0.15s;
					    -moz-transition: all 0.15s;
					    transition: all 0.15s;
					}
					.my-studio-page-content-section-locations-list-content-empty-button:hover {
					    background: #7278F2;
					    cursor: pointer;
					    -webkit-transition: all 0.15s;
					    -moz-transition: all 0.15s;
					    transition: all 0.15s;
					}
						.my-studio-page-content-section-locations-list-content-empty-button p {
						    font-size: 13.5px;
						    color: white;
						    margin: 0px;
						}
						.my-studio-page-content-section-locations-list-content-empty-button-icon {
							font-size: 15px !important;
							color: white;
							margin-top: 1px;
						}
				.my-studio-page-content-section-locations-list-button {
					padding: 8px 8px;
					padding-right: 12px;
					background: rgba(0, 0, 0, 0.12);
					border-radius: 5px;
					display: flex;
					float: left;
					margin-left: 32px;
					margin-top: 16px;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
				.my-studio-page-content-section-locations-list-button:hover {
					cursor: pointer;
					background: rgba(0, 0, 0, 0.24);
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
					.my-studio-page-content-section-locations-list-button p {
					    font-size: 13.5px;
					    color: #5B62FF;
					    margin: 0px;
					}
					.my-studio-page-content-section-locations-list-button:hover p {
						color: #7278F2;
					}
					.my-studio-page-content-section-locations-list-button-icon {
						font-size: 15px !important;
					    color: #5B62FF;
					    margin-right: 4px;
					}
					.my-studio-page-content-section-locations-list-button:hover .my-studio-page-content-section-locations-list-button-icon {
						color: #7278F2;
					}


	.my-studio-page-info-panel-container {
		height: 100%;
		overflow-y: scroll;
		padding-bottom: 60px;

	}
		.my-studio-page-info-panel-container .trainers-page-info-panel-container-content-section-header {
			padding-top: 12px;
			padding-bottom: 24px;
		}
		.my-studio-page-info-panel-container .trainers-page-info-panel-container-content {
			height: auto;
			overflow: visible;
		}

			.my-studio-page-info-panel-container-section-header {
				position: relative;
				display: block;
			}
				.my-studio-page-info-panel-container-section-header h4 {
					font-weight: 600;
				}
				.my-studio-page-info-panel-container-section-header p {
					color: #9DA0AD;

					margin-left: 0px;
					margin-top: 8px;
				}
				
				.my-studio-page-info-panel-container-section-header-save-button {
					position: absolute;
					z-index: 10;
					right: 20px;
					top: 20px;
				    padding: 8px 12px;
				    background: #5B62FF;
				    display: flex;
				    border-radius: 5px;
				    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.12);
				    -webkit-transition: all 0.15s;
				    -moz-transition: all 0.15s;
				    transition: all 0.15s;
				}
				.my-studio-page-info-panel-container-section-header-save-button:hover {
				    background: #7278F2;
				    cursor: pointer;
				    -webkit-transition: all 0.15s;
				    -moz-transition: all 0.15s;
				    transition: all 0.15s;
				}
					.my-studio-page-info-panel-container-section-header-save-button p {
					    font-size: 13.5px;
					    color: white;
					    margin: 0px;
					}

				.my-studio-page-info-panel-input-row {
					width: 100%;
					display: flex;
				}
					.my-studio-page-info-panel-input-row-header {
						width: 140px;
						padding: 8px 0px;
					}
						.my-studio-page-info-panel-input-row-header h4 {
						    font-size: 18px;
						    color: #6C6C6C;
						    margin: 0px;
						}
					.my-studio-page-info-panel-input-row-text {
						padding: 8px 12px;
						border-radius: 5px;
						float: left;
						-webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}
					.my-studio-page-info-panel-input-row-text:hover {
						cursor: pointer;
						background: rgba(0, 0, 0, 0.2);
						-webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}
						.my-studio-page-info-panel-input-row-text h4 {
						    font-size: 18px;
						    color: #ffffff;
						    margin: 0px;
						}
					.my-studio-page-info-panel-input-row-text-input {
						width: calc(100% - 160px);
						margin-top: 8px;
					}
					.my-studio-page-info-panel-input-row-text-dropdown {
						display: flex;
						position: relative;
					}
						.my-studio-page-info-panel-input-row-text-dropdown-selection {

						}
						.my-studio-page-info-panel-input-row-text-dropdown-selection-empty {

						}
							.my-studio-page-info-panel-input-row-text-dropdown-selection-empty h4 {
								color: #7278F2;
							}
						.my-studio-page-info-panel-input-row-text-dropdown-icon-container {
							padding: 2px 6px;
						}
							.my-studio-page-info-panel-input-row-text-dropdown-icon {
								font-size: 16px !important;
								color: #ffffff;
								margin-top: 2px;
								margin-bottom: -2px;
							}
						.my-studio-page-info-panel-input-row-text-dropdown-menu {
							position: absolute;
							top: 40px;
							max-height: 400px;
							overflow-y: scroll;
							width: 220px;
							z-index: 10;
							background: #1F1F21;
							padding: 12px 0px;
							border: solid;
							border-width: 1px;
							border-color: #2B2B30;
							border-radius: 5px;
							box-shadow: 0px 0px 12px 6px rgba(0, 0, 0, 0.08);
							-webkit-transition: all 0.15s;
							-moz-transition: all 0.15s;
							transition: all 0.15s;
						}
							.my-studio-page-info-panel-input-row-text-dropdown-menu-row {
								padding: 12px 20px;
								border-bottom: solid;
								border-width: 1px;
								border-color: #2B2B30;
							}
							.my-studio-page-info-panel-input-row-text-dropdown-menu-row:hover {
								background: #1F1F21;
								cursor: pointer;
								-webkit-transition: all 0.15s;
								-moz-transition: all 0.15s;
								transition: all 0.15s;
							}

			.my-studio-page-info-panel-input-row-location-button {
				padding: 8px 12px;
				padding-right: 16px;
				border-radius: 5px;
				float: left;
				display: flex;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
			.my-studio-page-info-panel-input-row-location-button:hover {
				cursor: pointer;
				background: rgba(0, 0, 0, 0.2);
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
				.my-studio-page-info-panel-input-row-location-button h4 {
				    font-size: 18px;
				    color: #5B62FF;
				    margin: 0px;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
				.my-studio-page-info-panel-input-row-location-button:hover h4 {
					color: #7278F2;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
				.my-studio-page-info-panel-input-row-location-button-icon {
					font-size: 18px !important;
				    color: #5B62FF;
				    margin-top: 1px;
				    margin-right: 4px;
				    -webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
				.my-studio-page-info-panel-input-row-location-button:hover .my-studio-page-info-panel-input-row-location-button-icon {
					color: #7278F2;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}

			.my-studio-page-info-drop-area-text-container span {
				font-size: 13.5px !important;
				font-weight: 400 !important;
				text-align: left !important;
				margin: 0px !important;
				color: #7278F2;
			}
			.trainers-page-info-panel-container-content-section .my-coaching-profile-page-theme-card {
				width: calc(50% - 16px);
				min-width: 178px;
			}

			.my-studio-page-info-panel-container-option {
				width: 100%;
				display: flex;
				margin-top: 12px;
				margin-bottom: 32px;
			}
				.my-studio-page-info-panel-container-option-checkbox-container {
					width: 30px;
					padding-top: 1px;
				}
				.my-studio-page-info-panel-container-option-text-container {
					width: calc(100% - 30px);
				}
					.my-studio-page-info-panel-container-option-text-container h4 {
					    font-size: 18px;
					    color: #ffffff;
					    margin: 0px;
					    margin-bottom: 4px;
					}
					.my-studio-page-info-panel-container-option-text-container p {
					    font-size: 13.5px;
					    color: #9DA0AD;
					    margin: 0px;
					}









