
.assign-modal-client-cell {
	display: flex;
	padding: 8px 12px;
	border-bottom: solid;
	border-width: 1px;
	border-color: #2B2B30;
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
}	
.assign-modal-client-cell:hover {
	background: #1F1F21;
	cursor: pointer;
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
}
	.assign-modal-client-cell-image-container {
		
	}

	.assign-modal-client-cell-text-container {
		padding-top: 4px;
	}
		.assign-modal-client-cell-text-container h4 {
		    font-size: 13.5px;
		    color: #ffffff;
		    margin: 0px;
		}