/* .client-profile-page-goals- */

.client-profile-page-goals-container {
	width: 100%;
}

.client-profile-page-goals-header-container {
	width: 100%;
	display: flex;
}
	.client-profile-page-goals-header-title-container {
		width: 50%;
		padding-top: 9px;
	}
		.client-profile-page-goals-header-title-container h4 {
			font-size: 13.5px;
			color: #ffffff;
			margin: 0px;
		}
	.client-profile-page-goals-header-buttons-container {
		margin-left: auto;
		margin-top: -8px;
		float: right;
		display: flex;
		position: relative;
		padding: 0px;
	}

		.client-profile-page-goals-header-button {
			padding: 6px 12px;
			padding-left: 10px;
			display: flex;
			font-size: 12px;
			border-radius: 5px;
			background: black;
			margin-left: 12px;
			color: #ffffff !important;

		}
			.client-profile-page-goals-header-button p {
				margin-left: 4px;
				font-weight: 500;
			}
			.client-profile-page-goals-header-button-icon {
				font-size: 14px !important;
				margin-top: 1px;
				color: #ffffff;
			}

			.client-profile-page-goals-header-button-dark {
				background: #1F1F21;
				border: solid;
				border-width: 0.5px;
				border-color: #2B2B30;
				box-shadow: 0 2px 20px 0 rgba(5, 5, 23, 0.2);
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
				.client-profile-page-goals-header-button-dark p {
					color: #ffffff;
					font-size: 12px;
				}
			.client-profile-page-goals-header-button-dark:hover {
				background: #1F1F21;
				cursor: pointer;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}

			.client-profile-page-goals-header-button-blue {
				background: #5B62FF;
				border: none;
				color: white !important;
				padding-top: 6px;
				padding-bottom: 6px;
				box-shadow: 0 2px 20px 0 rgba(23, 23, 200, 0.2);
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
			.client-profile-page-goals-header-button-blue:hover {
				background: #5C6BC9;
				cursor: pointer;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
				.client-profile-page-goals-header-button-blue p {
					color: white;
					font-size: 12px;
				}

	.client-profile-page-goals-content-container {
		width: 100%;
		padding-top: 16px;
	}


	.client-profile-page-goal-card-content {
		padding-top: 16px;
	}
		.client-profile-page-goal-card-content h4 {
			font-size: 13.5px;
			color: #ffffff;
			margin: 0px;
			font-weight: 700;
		}
		.client-profile-page-goal-card-content p {
			font-size: 13.5px;
			color: #ffffff;
			margin: 0px;
			margin-bottom: 4px;
			margin-top: -6px;
		}
	.client-profile-page-goal-card-content-summary {
		padding-bottom: 24px;
	}

.client-profile-page-goals-header-add-menu-container {
	top: 40px !important;
	right: 0px !important;
}
	.client-profile-page-goals-header-add-menu-content-input-button {
		padding: 6px 18px;
		border: solid;
		border-width: 0.5px;
		border-color: #2B2B30;
		background: #1F1F21;
		border-radius: 5px;
		box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
		font-size: 13.5px;
		width: 100px;
		color: #6C6C6C;
		float: right;
		margin-left: auto;
		margin-right: 12px;
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
		cursor: default;
	}
		.client-profile-page-goals-header-add-menu-content-input-button-blue {
			background: #5B62FF;
			border-color: rgba(0,0,0,0);
			color: #ffffff;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
		.client-profile-page-goals-header-add-menu-content-input-button-blue:hover {
			background: #5C6BC9;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
			cursor: pointer;
		}

		.client-profile-goals-bottom-spacer {
			width: 100%;
			height: 24px;
		}










