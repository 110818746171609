.logout-header-container {
	position: relative;
}

.logout-header-container h1 {
	color: #C1C4CC;
	margin: 0px;
}

.logout-header-container p {
	color: #C1C4CC;
	opacity: 0.8;
	margin: 0px;
}

.profile-info-container {
	width: 100%;
	height: calc(100% - 32px);
	overflow-y: scroll;
	padding-top: 24px;
}


.logout-button {
	color: red;
	position: absolute;
	right: 0px;
	top: 12px;
	border-radius: 4px;
	border-color: red;
	border-width: 1px;
	border-style: solid;
	padding: 8px 16px;
}

.profile-card-container {
	width: 100%;
	padding: 24px;
	background: #202A34;
	color: #C1C4CC;
	border-radius: 4px;
	box-shadow: 0px 2px 12px 4px rgba(0, 0, 0, 0.12);
	margin-bottom: 16px;
}

.profile-card-container h2 {
	margin: 0px;
	margin-bottom: 4px;
	color: #C1C4CC;
}


.profile-info-section-container {
	padding-top: 12px;
}


.profile-info-section-container h4 {
	margin: 0px;
	color: #C1C4CC;
}

.profile-info-section-container p {
	margin: 0px;
	font-size: 12px;
	color: #5F6C76;
}