.dual-selector-container {
	width: 100%;
	height: 60px;
	display: flex;
	margin-bottom: 36px;
}

.dual-selector-selected-container {
	width: 50%;
	height: 50px;
	background-color: #4A5AC1;
	padding: 2px 40px;
	border-radius: 5px;
	text-align: center;
	box-shadow: 0 4px 38px 0 rgba(0, 0, 200, 0.15);
	-webkit-transition: all 0.35s;
  	-moz-transition: all 0.35s;
  	transition: all 0.35s;
}


.dual-selector-selected-container p {
	color: #ffffff !important;
}

.dual-selector-unselected-container {
	width: 50%;
	height: 50px;
	background-color: none;
	padding: 2px 40px;
	text-align: center;
	box-shadow: none;
	-webkit-transition: all 0.35s;
  	-moz-transition: all 0.35s;
  	transition: all 0.35s;
}

.dual-selector-unselected-container p {
	color: #9DA0AD !important;
}