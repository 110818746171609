.client-profile-history-load-more-container {
	width: 100%;
	display: block;
	border-radius: 5px;
	background-color: #1A1B2A;
	box-shadow: 0 4px 38px 2px rgba(0, 0, 0, 0.16);
	height: 46px;
	margin-bottom: 24px;
	padding: 16px 0px;
	text-align: center;
	font-size: 10px;
	color: #ffffff;
	border: solid;
	border-width: 1px;
	border-color: #2E3247;
}