.intraworkout-page-container-no-padding {
	padding: 0px;
	height: calc(100vh - 84px);
}
.intra-workout-container {
	position: relative;
}

.intra-workout-alert-container {
	width: calc(100% - 8px);
	top: 26px;
	right: 12px;
}

.intra-workout-content-container {
	width: 100%;
	display: flex;
}
	.intra-workout-overview-container {
		width: 40%;
	}
		.intra-workout-overview-container-header {
			width: 100%;
			display: flex;
			border-bottom: solid;
			border-width: 1px;
			border-color: #2B2B30;
			padding-bottom: 12px;
			
		}
			.intra-workout-overview-container-header h4 {
				margin: 0px;
				font-size: 13.5px;
				color: #ffffff;
			}
			.intra-workout-overview-container-header p {
				margin: 0px;
				font-size: 10px;
				color: #9DA0AD;
				float: right;
				margin-left: auto;
			}
	.intra-workout-outer-page-container {
		width: 60%;
		padding: 16px 0px;
		background-color: #161719;
		box-shadow: -2px 18px 24px 12px rgba(0, 0, 0, 0.1);
		border-left: solid;
		border-width: 1px;
		border-color: #2B2B30;
		position: relative;
	}