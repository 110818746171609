.info-block-container {
    margin: 8px 0px;
    color: #4959C1;
    width: 100%;
    border: solid;
    border-width: 1px;
    border-color: #4959C1;
    border-radius: 4px;
    background: rgba(73,89,193,0.06);
    display: flex;
    padding: 12px;
}

.info-block-icon-container {
    width: 20px;
    color: #4959C1;
}

.info-block-text-container {
    width: calc(100% - 20px);
    color: #4959C1;
    font-size: 14px;
}