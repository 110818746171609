

.workout-plan-list-plan-workout {
	padding: 0px;
}
	.workout-plan-list-plan-workout-header {
		padding: 0px 42px;
		padding-bottom: 0px;
		/*border-bottom: solid;
		border-width: 0.5px;
		border-color: #2B2B30;*/
		width: 100%;
		display: flex;
		position: relative;
		background: #1F1F21;
	}
		.workout-plan-list-plan-workout-header h3 {
			font-size: 13.5px;
			color: #ffffff;
			margin: 0px;
		}
		
		.workout-plan-list-plan-workout-header-back-button {
			position: absolute;
			left: 10px;
			top:  12px;
			width: 22px;
			height: 22px;
			border-radius: 5px;
			padding: 2.5px 8px;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
		.workout-plan-list-plan-workout-header-back-button:hover {
			background: #28282D;
			box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
			cursor: pointer;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
			.workout-plan-list-plan-workout-header-back-button-icon {
				font-size: 12px !important;
				color: #6C6C6C;
			}
			.workout-plan-list-plan-workout-header-back-button:hover .workout-plan-list-plan-workout-header-back-button-icon {
				color: #ffffff;
			}
	.workout-plan-list-plan-workout-body-card-header {
		position: relative;
	}
		.workout-plan-list-plan-workout-header-card-header {
			position: absolute;
			right: 24px;
			margin-top: -64px;
		}
			.workout-plan-list-plan-workout-header-card-header-day-container {
				margin-left: auto;
				float: right;
				position: relative;
				padding-top: 14px;
			}
				.workout-plan-list-plan-workout-header-card-header-day-container p {
					font-size: 13.5px;
					color: #6C6C6C;
					margin: 0px;
				}
			.workout-plan-list-plan-workout-header-card-header .assign-modal-body-row-edit-plan-header-button-dark {
				background: #1F1F21;
			}
				.workout-plan-list-plan-workout-header-card-header .assign-modal-body-row-edit-plan-header-button-dark p {
					color: #ffffff;
				}
			.workout-plan-list-plan-workout-header-card-header .assign-modal-body-row-edit-plan-header-button-dark:hover {
				background: #313645;
			}
			.workout-plan-list-plan-workout-header-card-header-day {
				padding-top: 16px;
			}
				.workout-plan-list-plan-workout-header-card-header-day p {
					color: #9DA0AD;
				}
.workout-plan-list-plan-workout-body-container {
	width: 100%;
	height: calc(100vh - 168px);
	overflow-y: scroll;
}
	.workout-plan-list-plan-workout-body-card {
		padding: 20px 12px;
	}
		.workout-plan-list-plan-workout-body-card-content {
			width: 100%;
			padding: 56px 12px;
			margin-top: -48px;
			z-index: 0;
			position: relative;
			overflow: hidden;
		}
			.workout-plan-list-plan-workout-body-card-content-series-cell-container {

			}
				.workout-plan-list-plan-workout-body-card-content-series-cell-wrapper {
					padding-left: 48px;
					position: relative;
				}
					.workout-plan-list-plan-workout-body-card-content-series-cell-branch-container {
						width: 48px;
						height: 600px;
						position: absolute;
						z-index: 0;
						left: 12px;
						top: -572px;
					}
					.workout-plan-list-plan-workout-body-card-content-series-cell-branch {
						width: 100%;
						height: 100%;
						border-left: solid;
						border-bottom: solid;
						border-width: 2px;
						border-color: var(--ef-color-exercise);
						border-bottom-left-radius: 16px;
						z-index: 0;
					}
					.workout-plan-list-plan-workout-body-card-content-series-cell-branch-dot {
						width: 10px;
						height: 10px;
						border-radius: 5px;
						position: absolute;
						z-index: 2;
						left: 43px;
						top: 22px;
						background: var(--ef-color-exercise);;
					}

					.workout-plan-list-plan-workout-body-card-content-series-cell-wrapper-cell {
						z-index: 1;
						position: relative;
					}
	.workout-plan-list-plan-workout-body-loading {
		padding-top: 0px;
	}
	.workout-plan-list-plan-workout-body-loading p {
	    font-size: 13.5px;
	    color: #9DA0AD;
	    margin: 0px;
	    width: 100%;
	    text-align: center;
	    margin-top: 8px;
	}
		.workout-plan-list-plan-workout-body-loading .client-list-container-loading-spinner {
			margin-top: 7vh;
		}

.workout-plan-list-plan-workout-body-card-content .assign-modal-body-add-button-container {
	padding-left: 24px !important;
	margin-left: 0px !important;
}
	.workout-plan-list-plan-body-header-button-blue {

	}
		.workout-plan-list-plan-body-header-button-blue p {
			font-size: 12px;
			font-weight: 600;
			color: white;
			opacity: 1;
		}


