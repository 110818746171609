.client-cell-container {
	padding: 12px 36px;
	width: 100%;
	/*border-radius: 5px;
	background-color: #1F1F21;
	box-shadow: 0 4px 24px 6px rgba(0, 0, 0, 0.1);*/
	/*margin-bottom: 12px;*/
	border-bottom: solid;
	border-width: 1px;
	border-color: #2B2B30;
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
}
.client-cell-container:hover {
	background-color: #212430;
	cursor: pointer;
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
}
	.client-cell-container p {
		margin: 0px;
	}

	.client-cell-content {
		width: 100%;
		display: flex;
	}
		.client-card-col-container {
			display: flex;
			width: calc(100% - 50px);
		}
		.client-card-col {
			display: block;
			padding-right: 16px;
			width: 25%;
			overflow-x: hidden;
		}

.client-card-image-container {
	background-color: rgba(0,0,0,0.3);
	width: 24px;
	height: 24px;
	max-width: 24px;
	max-height: 24px;
	border-radius: 12px;
	overflow: hidden;
	margin-right: 16px;
	padding: 0px;
}
	.client-card-image {
		height: 100% !important;
		width: 400px !important;
		border-radius: 4px !important;
	}

.client-card-info-container {
	padding-top: 4px;
	position: relative !important;
}
	.client-card-info-container h4 {
		font-weight: 500;
		width: 100%;
		overflow: hidden;
		font-size: 13.5px;
		color: #ffffff;
		text-align: left;
		margin: 0px;
	}
	.client-cell-container:hover .client-card-info-container h4 {
		color: white;
	}

	.client-card-info-container p {
		font-size: 12px;
		font-size: 10px;
		color: #9DA0AD;
		height: 16px;
		width: 100%;
		overflow: hidden;
	}
	.client-card-info-container span {
		color: #ffffff;
		padding-left: 2px;
		font-weight: 500;
	}
		.client-card-info-container-no-workouts {

		}
		.client-card-info-container-no-workouts h4 {
			color: #6C6C6C !important;
		}
		.client-card-info-container-no-workouts p {
			color: #6C6C6C !important;
		}
			.client-card-info-container-no-workouts-alert {
				position: absolute;
				left: 55px;
				top: 6px;
				height: 12px;
				width: 12px;
				border-radius: 6px;
				background: rgba(255,23,23,0.3);
				padding-left: 4.5px;
				padding-top: 1px;
			}
				.client-card-info-container-no-workouts-alert p {
					color: #F02E4A !important;
					font-weight: 700;
					font-size: 8px;
				}

	.client-card-info-trained-with-me-container {
		display: flex;
	}
		.client-card-info-trained-with-me-icon {
			width: 10px;
			height: 10px;
			max-height: 10px;
			min-width: 10px;
			background: #4959C1;
			border: solid;
			border-color: #2B3158;
			border-width: 3px;
			border-radius: 5px;
			margin-top: 1px;
			margin-right: 6px;
		}

	.client-cell-goal-container {
		display: flex;
	}
		.client-cell-goal {
			margin-top: 12px;
			padding: 4px 12px;
			background-color: #242C45;
			border-radius: 4px;
			color: #4959C1;
			font-size: 10px;
			text-align: center;
			max-height: 24px;
		}

		.client-cell-goal-text-container {
			padding-top: 8px;
			padding-left: 8px;
		}
			.client-cell-goal-text-container h4 {
				font-size: 13.5px;
				color: #ffffff;
				margin: 0px;
			}
			.client-cell-goal-text-container p {
				font-size: 10px;
				color: #ffffff;
				margin: 0px;
			}

.selected {
	margin-top: -3px;
	margin-bottom: 11px;
	border: solid;
	border-width: 2px;
	border-color: #4A5AC1;
	box-shadow: 0 4px 38px 0 rgba(0, 0, 200, 0.15);
}

.info-container {
	width: 60px;
	height: 60px;
	position: absolute;
	top: 10px;
	right: 0px;
}

.info-button {
	height: 22px;
	width: 22px;
	border-radius: 13px;
	margin: auto;
	margin-top: 7px;
	border: solid;
	border-width: 2px;
	border-color: #5F6C76;
	color: #5F6C76;
	font-weight: 900;
	text-align: center;
	font-size: 12px;
	padding-top: 1px;
}

.client-cell-standing-container {
	display: block;
}



.client-cell-poor-standing {
	margin: auto;
	margin-top: 20px;
	padding: 4px 12px;
	background-color: #5A2B3A;
	border-radius: 4px;
	color: #F02E4A;
	font-size: 12px;
	text-align: center;
	width: 140px;
	max-width: 100%;
}
