/* .client-profile-schedule */

.client-profile-schedule {
	width: 100%;
	height: 100%;
}
	.client-profile-schedule-header {

	}

	.client-profile-schedule-content {
		width: 100%;
		height: 100%;
	}

