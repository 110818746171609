
.new-workout-modal-header-container {
	width: 100%;
	display: flex;
	padding: 16px 36px;
	padding-left: 24px;
	border-bottom: solid;
	border-color: #2B2B30;
	border-width: 1px;
	background: #161719;
	box-shadow: 0px 0px 24px 12px rgba(0, 0, 0, 0.1);
}
	.new-workout-modal-header-close-container {
		padding-right: 16px;
		border-right: solid;
		border-width: 1px;
		border-color: #2B2B30;
	}

		.new-workout-modal-header-close-button {
			/*position: absolute;
			right: 0px;*/
			margin-top: 3px;
			width: 22px;
			height: 22px;
			border-radius: 5px;
			padding: 2.5px;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
		.new-workout-modal-header-close-button:hover {
			background: rgba(0,0,0,0.2);
			cursor: pointer;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
			.new-workout-modal-header-close-button-icon {
				color: #9DA0AD;
				font-size: 17px !important;
			}
			.new-workout-modal-header-close-button:hover .new-workout-modal-header-close-button-icon {
				color: #ffffff;
			}
	.new-workout-modal-header-content-container {
		display: flex;
		padding-left: 16px;
		padding-top: 4px;
	}
		.new-workout-modal-header-content-icon-container {
			margin-top: 2px;
			width: 26px;
			height: 26px;
			border-radius: var(--ef-radius-s);
			background: var(--ef-color-workout-transparent);
			margin-top: -4px;
			padding: 5px;
			margin-right: 6px;
		}

			.new-workout-modal-header-content-icon {
				font-size: 15px !important;
				color: var(--ef-color-workout);
			}
		.new-workout-modal-header-content-text-container {
			padding-left: 8px;
			display: flex;
		}
			.new-workout-modal-header-content-text-container h4 {
			    font-size: 15px;
			    color: #ffffff;
			    margin: 0px;
			}
			.new-workout-modal-header-content-text-container-date {

			}
				.new-workout-modal-header-content-text-container-date h4 {
				    font-size: 15px;
				    color: #6C6C6C;
				    margin: 0px;
				    margin-left: 16px;
				}
	.new-workout-modal-header-button-container {
		margin-left: auto;
		float: right;
		display: flex;
	}
		.new-workout-modal-header-toggle-container {
			display: flex;
			padding-top: 3px;
			position: relative;
		}
			.new-workout-modal-header-toggle-container p {
			    font-size: 12px;
			    color: #9DA0AD;
			    margin: 0px;
			    padding: 2px 8px;
			    padding-right: 16px;
			    cursor: default;
			}
			.new-workout-modal-header-toggle-info-container {
				display: none;
				position: absolute;
				top: 32px;
				right: -32px;
				background: rgba(0, 0, 0, 0.6);
				box-shadow: 0px 0px 12px 6px rgba(0, 0, 0, 0.12);
				border-radius: 5px;
				padding: 6px 8px;
				width: 240px;
			}
			.new-workout-modal-header-toggle-container:hover .new-workout-modal-header-toggle-info-container {
				display: block;
			}
		.new-workout-modal-header-button-cta {
			background: var(--ef-cta);
			padding: 6px 10px;
			border-radius: 5px;
			box-shadow: 0px 0px 6px 3px rgba(0,0,20,0.12);
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
		.new-workout-modal-header-button-cta:hover {
			background: var(--ef-cta-light);
			cursor: pointer;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
			.new-workout-modal-header-button-cta p {
			    font-size: 12px;
			    color: white;
			    margin: 0px;
			    font-weight: 500;
			}
		.new-workout-modal-header-button-cta-unselected {
			opacity: 0.2;
		}
		.new-workout-modal-header-button-cta-unselected:hover {
			background: #5B62FF;
			cursor: default;
		}
/*.new-workout-header-container-coloured {
	background: #5B62FF;
	-webkit-transition: all 0.35s;
	-moz-transition: all 0.35s;
	transition: all 0.35s;
}
.new-workout-header-container {
	-webkit-transition: all 0.35s;
	-moz-transition: all 0.35s;
	transition: all 0.35s;
}
.new-workout-header-icon-container {
	margin-top: 3px;
	width: 38px;
	height: 38px;
	border-radius: 5px;
	background: rgba(60,193,73,0.2);
	padding: 10px 10px;
}
	.new-workout-header-icon {
		font-size: 18px !important;
		color: #49C16B;
	}

	.new-workout-header-icon-container-first-screen {
		padding: 0px;
		margin: 0px;
		height: 40px;
		margin-top: calc(50vh - 210px);
	}
	.new-workout-header-icon-container-first-screen .new-workout-header-icon {
		color: white;
		font-size: 24px !important;
	}
.new-workout-header-icon-container-previous-container {
	position: absolute;
	padding-top: 16px;
	left: 16px;
	display: flex;
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
}
.new-workout-header-icon-container-previous-container:hover {
	left: 0px;
	padding-top: 2px;
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
	cursor: pointer;
}
	.new-workout-header-icon-container-plan {
		width: 12px;
		height: 12px;
		border-radius: 5px;
		background: rgba(73,89,193,1.0);
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}

	.new-workout-header-icon-container-previous-container:hover .new-workout-header-icon-container-plan {
		width: 38px;
		height: 38px;
		padding: 10px;
		background: rgba(73,89,193,0.2);
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}

	.new-workout-header-icon-container-plan {
		
	}
	.new-workout-header-icon-container-previous-container:hover .new-workout-header-icon-container-plan .new-workout-header-icon-plan {
		opacity: 1.0;
	}
		.new-workout-header-icon-plan {
			opacity: 0.0;
			font-size: 16px !important;
			color: #4959C1;
		}
	.new-workout-header-back-icon-container {
		width: 10px;
		height: 10px;
		margin-top: -1px;
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}
	.new-workout-header-icon-container-previous-container:hover .new-workout-header-back-icon-container {
		margin-top: 12px;
		margin-left: -5px;
		width: 4px;
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}
		.new-workout-header-back-icon {
			font-size: 15px !important;
			color: #6C6C6C;

		}
*/

.new-workout-placeholder-image-container {
	width: calc(360px + 6%);
	margin: 0px auto;
}

.new-workout-modal-image-container {
	width: 100%;
	max-width: 100%;
	height: 100%;
	max-height: 100%;
	overflow-y: scroll;
	display: inline-block;
}

.new-workout-modal-image-content {
	width: 120px !important;
	height: 120px !important;
	border-radius: 5px;
	overflow: hidden;
	margin-right: 2%;
	margin-bottom: 12px;
	display: inline-flex;
	box-shadow: 0 2px 12px 6px rgba(0, 0, 0, 0.1);
}
	.new-workout-modal-image-content:hover {
		cursor: pointer;
	}
	.new-workout-modal-image-content:hover .new-workout-modal-image-unselected {
		opacity: 0.9;
	}

.new-workout-modal-page-full-height {
	/*height: 100vh;*/
	position: relative;
}
	.new-workout-exercise-list-container {
		min-height: 50vh;
	}
.new-workout-next-button-container {

}
	.new-workout-next-button-container-page-0 {
		padding-top: 10vh;
		display: flex;
		padding-bottom: 48px;
	}

	.new-workout-next-button-container-page-6 {
		padding-top: 10vh;
		display: flex;
		padding-bottom: 48px;
		width: 190px;
		margin: 0px auto;
	}

.new-workout-modal-image-selected {
	opacity: 1.0;
	border: solid;
	border-radius: 8px;
	border-width: 3px;
	border-color: var(--ef-cta-light);
}

.new-workout-modal-image-unselected {
	opacity: 0.6;
	border-radius: 4px;
}

.new-workout-modal-page-header-text-container {
	display: flex;
	margin-bottom: 12px;
}

.new-workout-modal-page-header-text-container p {
	width: 50%;
	margin: 0px;
	color: #ffffff;
	font-size: 13.5px;
}

.new-workout-modal-page-header-text-right {
	color: #9DA0AD !important;
	text-align: right;
	font-size: 10px !important;
	padding-top: 6px;
}

.new-workout-modal-image-text p {
	color: #C1C4CC;
	font-size: 14px;
	margin-top: 0px;
}

.new-workout-modal-image-text-error p {
	color: #F02E4A;
	font-size: 14px;
	margin-top: 0px;
}

.new-workout-modal-availability-container {
	width: 100%;
	display: block;
}

.new-workout-modal-availability-container p {
	margin: 0px;
	margin-bottom: 12px;
	font-size: 18px;
	color: #C1C4CC;
}

.new-workout-modal-availability-row {
	width: 100%;
	display: block;
}

.new-workout-modal-availability-cell {
	width: 100%;
	margin: 0px;
	margin-bottom: 12px;
	padding: 16px;
	border-radius: 5px;
	border: solid;
	border-color: #2B2B30;
	box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
	border-width: 1px;
	position: relative;
}

.new-workout-modal-availability-cell:hover {
	cursor: pointer;
	border-color: var(--ef-cta-light);
	background: rgba(91,98,255,0.06);
	color: #9DA0AD !important;
}
.new-workout-modal-availability-cell:hover p {
	
	color: #9DA0AD !important;
}
.new-workout-modal-availability-cell:hover .new-workout-modal-availability-cell-header-icon {
	color: var(--ef-cta-light);
}
	.new-workout-modal-availability-cell-header h3 {
		color: #9DA0AD;
		opacity: 0.9;
		font-size: 13.5px;
		font-weight: 700;
		margin: 0px;
		
	}
	.new-workout-modal-availability-cell p {
		color: #6C6C6C;
		opacity: 0.9;
		font-size: 12px;
		margin: 0px;
	}
	.new-workout-modal-availability-cell-header {
		display: flex;
		margin-bottom: 8px;
	}
		.new-workout-modal-availability-cell-header-icon {
			font-size: 13.5px !important;
			color: #9DA0AD;
			margin-top: 1px;
			margin-right: 8px;
		}
		.new-workout-modal-availability-cell-selected .new-workout-modal-availability-cell-header-icon {
			color: var(--ef-cta);
		}

.new-workout-modal-availability-cell-selected {
	background: rgba(91,98,255,0.12);
	border-color: var(--ef-cta);
	box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
}
.new-workout-modal-availability-cell-selected:hover {
	background: rgba(100,100,255,0.12);
	/*cursor: default;*/
}
.new-workout-modal-availability-cell-selected:hover p {
	color: #ffffff !important;
}

.new-workout-modal-availability-cell-selected h3 {
	color: #ffffff;
	opacity: 1.0;
}

.new-workout-modal-availability-cell-selected p {
	color: #ffffff;
}

.new-workout-modal-availability-cell-floater {
	position: absolute;
	top: 12px;
	right: 18px;
	color: white;
	font-size: 10px;
	padding: 4px 8px;
	border-radius: 4px;
	background: var(--ef-cta);
}



