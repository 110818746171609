/* .date-selector- */

.date-selector {
	position: relative;
}
	.date-selector-input-container {
		width: 100%;
		padding: 9px 12px;
		background: rgba(0,0,0,0);
		border-radius: 5px;
		border: solid;
		border-width: 1px !important;
		border-color: #2B2B30;
		box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}
	.date-selector-input-container:hover {
		background: #28282D !important;
		cursor: pointer;
	}
		.date-selector-input-container-selected {
			border-color: #5437DC;
			background: rgba(0,0,0,0.1);
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
	.date-selector-input-container:hover {
		cursor: pointer;
		background: rgba(0,0,0,0.1);
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}
		.date-selector-input-container h4 {
			font-size: 13.5px;
			margin: 0px;
			color: #ffffff;
		}


	.date-selector-calendar {
		position: absolute;
		top: 42px;
		left: 0px;
		width: 300px;
		z-index: 400;
		/*height: 300px;*/
		background: #1F1F21;
		border-radius: 7px;
		border: solid;
		border-width: 1px;
		border-color: #2B2B30;
		box-shadow: 0 2px 24px 4px rgba(0, 0, 0, 0.1);
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}
	.date-selector-calendar-left {
		left: -180px;
	}
	.date-selector-calendar-above {
		top: auto;
		bottom: 0px;
		left: auto;
		right: 0px;
	}
		.date-selector-calendar-header {
			display: flex;
			padding: 12px 16px;
			border-bottom: solid;
			border-width: 1px;
			border-color: #00000032;
		}
			.date-selector-calendar-header-text-container {
				width: calc(100% - 20px);
				overflow: hidden;
			}
				.date-selector-calendar-header-text-container h4 {
					font-size: 13.5px;
					color: #ffffff;
					margin: 0px;
				}
			.date-selector-calendar-header-close-icon-container {
				width: 20px;
				height: 20px;
				border-radius: 5px;
				padding: 2px 4px;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
			.date-selector-calendar-header-close-icon-container:hover {
				background: rgba(0,0,0,0.2);
				cursor: pointer;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			} 
				.date-selector-calendar-header-close-icon {
					font-size: 13.5px !important;
					color: #6C6C6C;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
				.date-selector-calendar-header-close-icon-container:hover .date-selector-calendar-header-close-icon {
					color: #ffffff;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}

			.date-selector-calendar-body {
				border-top: solid;
				border-width: 1px;
				border-color: #2B2B30;
			}
				.date-selector-calendar-month-row {
					display: flex;
					padding: 8px 16px;
				}
					.date-selector-calendar-month-row-text-container {

					}
						.date-selector-calendar-month-row-text-container h4 {
							font-size: 10px;
							color: #ffffff;
							margin: 0px;
							margin-top: 6px;
						}
					.date-selector-calendar-month-row-buttons-container {
						display: flex;
						margin-left: auto;
						float: right;
					}
						.date-selector-calendar-month-row-button {
							background: #1F1F21;
							padding: 0px 6px;
							height: 22px;
							width: 22px;
							box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
							border-radius: 5px;
							margin: auto 0px;
							margin-left: 8px;
							border: solid;
							border-width: 0.5px;
							border-color: #2B2B30;
							-webkit-transition: all 0.15s;
							-moz-transition: all 0.15s;
							transition: all 0.15s;
						}
						.date-selector-calendar-month-row-button:hover {
							background: #28282D;
							cursor: pointer;
							-webkit-transition: all 0.15s;
							-moz-transition: all 0.15s;
							transition: all 0.15s;
						}
							.date-selector-calendar-month-row-button-icon {
								font-size: 10px !important;
								color: #9DA0AD;
							}
					.date-selector-calendar-weekday-row {
						width: 100%;
						display: flex;
						padding: 0px 12px;
						padding-top: 8px;
					}
						.date-selector-calendar-weekday-container {
							width: 14.28%;
						}
							.date-selector-calendar-weekday-container p {
								width: 100%;
								text-align: center;
								font-size: 10px;
								color: #6C6C6C;
								margin: 0px;
							}
					.date-selector-calendar-days-container {
						width: 100%;
						display: inline-block;
						padding: 0px 12px;
						padding-bottom: 12px;
					}
						.date-selector-calendar-day-content {
							min-width: 14.28%;
							display: inline-block;
							padding: 3.5px 0px;
							height: 26px;
							border-radius: 5px;
							-webkit-transition: all 0.15s;
							-moz-transition: all 0.15s;
							transition: all 0.15s;
						}
						.date-selector-calendar-day-content:hover {
							background: #5437DCF0;
							cursor: pointer;
							-webkit-transition: all 0.15s;
							-moz-transition: all 0.15s;
							transition: all 0.15s;
						}
						.date-selector-calendar-day-content-invisible:hover {
							background: rgba(0,0,0,0) !important;
							cursor: default !important;
						}
							.date-selector-calendar-day-content-inner {
								width: 100%;
							}
							.date-selector-calendar-day-content-inner p {
								width: 100%;
								text-align: center;
								font-size: 13.5px;
								color: #ffffff;
								margin: 0px;
							}
							.date-selector-calendar-day-content-selected {
								background: #5437DC;
								box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
							}
							.date-selector-calendar-day-content-selected:hover {
								background: #7963E0;
							}
							.date-selector-calendar-day-content-selected p {
								color: white !important;
							}







