.form-input-container {
	position: relative;
	padding-top: 10px;
	padding-bottom: 12px;
}

.form-input-container p {
	margin: 0px;
}

input[type=text]:focus {
  border: none;
}



.form-text-input-container {
	height: 38px;
	background-color: rgba(0,0,1,0.1);
	background-color: none;
	padding: 0px 8px;
	width: auto;
	border-radius: 5px;
	border: solid;
	border-color: #2B2B30;
	border-width: 1px !important;
	font-size: 13.5px;
	color: #ffffff;
	/*box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);*/
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
}
	.form-text-input-container:hover {
		background-color: rgba(0,0,1,0.1);
		box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}
	.form-text-input-container-focused {
		border-color: #5B62FF;
		background-color: rgba(0,0,1,0.1);
		box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}

.form-text-input-container-alert {
	height: 38px;
	background-color: rgba(100,0,0,0.12);
	padding: 0px 12px;
	width: auto;
	border-radius: 5px;
	border: solid;
	border-color: #F02E4A;
	font-size: 13.5px;
	border-width: 1px !important;
	color: #ffffff;
}

.form-text-input-container-search {
	background-color: #1F1F21;
	padding: 8px 12px;
	width: auto;
	border-radius: 5px;
	margin: 8px 0px;
}

.form-text-input-label {
	font-size: 13.5px;
	color: #ffffff;
	font-weight: 500;
	position: absolute;
	top: -12px;
	left: 0px;
}

.form-text-input-alert-label {
	font-size: 10px;
	color: #F02E4A;
	position: absolute;
	text-align: right;
	top: -10px;
	right: 0px;
}

.form-text-input-optional-label {
	font-size: 10px;
	color: #6C6C6C;
	position: absolute;
	text-align: right;
	top: -10px;
	right: 0px;
	font-weight: 500;
	padding: 4px 8px;
	background: #1F1F21;
	border-radius: 5px;
}