.create-plan-modal-info-card-container {
	width: 100%;
	position: absolute;
	bottom: 8px;
	left: 0px;
}

.create-plan-workout-list-placeholder-day-container {
	width: calc(100%);
}
	.create-plan-workout-list-placeholder-day-container-header {
		width: 100%;
		border-bottom: solid;
		border-width: 0.5px;
		border-color: #2B2B30;
		padding: 12px 20px;
		display: flex;
	}
		.create-plan-workout-list-placeholder-day-container-header h4 {
			font-size: 13.5px;
			color: #ffffff;
			font-weight: 700;
			margin: 0px;
			margin-right: 12px;
			padding-top: 1px;
		}
		.create-plan-workout-list-placeholder-day-container-header p {
			font-size: 13.5px;
			color: #6C6C6C;
			margin: 0px;
			padding-top: 1px;
		}
	.create-plan-workout-list-placeholder-day-container-content {
		width: 100%;
		display: flex;
		padding: 0px;
		box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
	}
		.create-plan-workout-list-placeholder-day-container-content-col {
			width: 50%;
			padding: 20px;
			border-right: solid;
			border-width: 0.5px;
			border-color: #2B2B30;
			display: flex;
			position: relative;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
			.create-plan-workout-list-placeholder-day-container-content-col:hover {
				background: rgba(180,200,255,0.04);
				cursor: pointer;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
		.create-plan-workout-list-placeholder-day-container-content-icon-container {
			width: 38px;
			height: 38px;
			border-radius: 5px;
			background: rgba(60,193,73,0.2); 
			padding: 9px;
		}
			.create-plan-workout-list-placeholder-day-container-content-icon {
				font-size: 18px !important;
				color: white;
			}
			.create-plan-workout-list-placeholder-day-container-content-icon-container-cloud {
				/*background: rgba(73,89,193,0.2); */
				padding: 9px 10px;
			}
			.create-plan-workout-list-placeholder-day-container-content-icon-cloud {
				/*color: #4959C1;*/
			}
			.create-workout-exercise-list-empty-content-container .create-plan-workout-list-placeholder-day-container-content-icon-cloud {
				color: white;
			}
		.create-plan-workout-list-placeholder-day-container-content-text-container {

		}
			.create-plan-workout-list-placeholder-day-container-content-text-container {
				padding-left: 12px;
				padding-top: 3px;
			}
			.create-plan-workout-list-placeholder-day-container-content-text-container h4 {
				font-size: 13.5px;
				color: #ffffff;
				margin: 0px;
				margin-bottom: 2px;
			}
			.create-plan-workout-list-placeholder-day-container-content-text-container p {
				font-size: 10px;
				color: #6C6C6C;
				margin: 0px;
			}

			.create-plan-workout-list-placeholder-day-container-content-arrow-icon-container {
				position: absolute;
				top: 20px;
				right: 20px;
				width: 20px;
				height: 20px;
			}
				.create-plan-workout-list-placeholder-day-container-content-arrow-icon {
					font-size: 13.5px !important;
					color: #6C6C6C;
				}

			.create-plan-workout-list-empty-button-container {
				display: flex;
				/*width: calc(40% - 8px);*/
				margin-right: 8px;
				min-width: 200px;
			}




