
.goal-chart {
	width: 100%;
	height: 100%;
	padding-top: 48px;
	position: relative;
}
	.goal-chart-bar-container {
		width: 100%;
		height: 100%;
		display: flex;
		column-gap: 2px;
/*		background: #FF4D4D30;*/
	}
		.goal-chart-bar {
			flex: 1;
			margin-top: auto;
			background: #ffffff10;
			min-height: 4px;
			max-height: 100%;
		}
	.goal-chart-target-line {
		width: 2px;
		height: calc(100% - 48px);
		background: #5B62FF;
		position: absolute;
		right: 0px;
		bottom: 0px;
	}
	.goal-chart-target-gradient-container {
		width: 60%;
		height: calc(100% - 48px);
		min-width: 200px;
		position: absolute;
		right: 0px;
		bottom: 0px;
	}
		.goal-chart-target-gradient-image {
			width: 100%;
			height: 100%;
		}
	.goal-chart-date-container {
		position: absolute;
		bottom: -12;
		left: 0;
		width: 100%;
		display: flex;
	}
		.goal-chart-date {
			padding-top: 6px;
		}
			.goal-chart-date p {
			    font-size: 12px;
			    color: #6C6C6C;
			    margin: 0px;
			}
		.goal-chart-date-starting {

		}
		.goal-chart-date-ending {
			margin-left: auto;
			float: right;
		}




