.create-workout-search-list-cell-container {
    width: 100%;
    -webkit-transition: all 0.15s;
    -moz-transition: all 0.15s;
    transition: all 0.15s;
    overflow: hidden;
    border-bottom: solid;
    border-width: 1px;
    border-color: #2B2B30;
}


.create-workout-search-list-cell-container-content {
    display: flex;
    padding: 10px 20px;
    padding-top: 12px;
}
.create-workout-search-list-cell-container-content:hover {
    cursor: pointer;
    background: #202025;
    -webkit-transition: all 0.15s;
    -moz-transition: all 0.15s;
    transition: all 0.15s;
}

.create-workout-search-list-cell-container:hover .create-workout-search-list-cell-select-icon {
    color: #5B62FF;
    -webkit-transition: all 0.15s;
    -moz-transition: all 0.15s;
    transition: all 0.15s;
}

.create-workout-search-list-cell-name-container {
    width: 70%;
    display: block;
    padding-right: 24px;
}

.create-workout-search-list-cell-name-container h2 {
    margin: 0px;
    color: #ffffff !important;
    font-size: 13.5px;
    font-weight: 500;
}

.create-workout-search-list-cell-name-container p {
    margin: 0px;
    color: #9DA0AD;
    font-size: 10px;
    font-weight: 500;
    height: 15px !important;
    overflow: hidden;
}


.create-workout-search-list-cell-info-container {
    width: calc(50%);
    display: block;
}

.create-workout-search-list-cell-info-content {
    display: flex;
    color: #5F6C76;
    padding-bottom: 2px
}
    .create-workout-search-list-cell-info-content p {
        color: #C1C4CC;
        font-size: 10px;
        margin: 0px;
        margin-left: 12px;
    }
    .create-workout-search-list-cell-info-icon {
        font-size: 10px !important;
        padding-top: 1px;
    }
.create-workout-search-list-cell-info-content-nodes {
    background: rgba(0, 0, 0, 0.5);
    width: 124px;
    border-radius: 7px;
    margin-left: -6px;
    padding-left: 6px;
    padding-top: 1px;
}
    .create-workout-search-list-cell-info-content-nodes p {
        color: #7278F2;
    }
.create-workout-search-list-cell-arrow-container {
    padding-top: 6px;
    width: 12px;
}

.create-workout-search-list-cell-select-icon {
    color: #6C6C6C;
    font-size: 13.5px !important;
    -webkit-transition: all 0.15s;
    -moz-transition: all 0.15s;
    transition: all 0.15s;
}

.create-workout-search-list-cell-add-sets-container-content {
    width: 100%;
    display: flex;
    padding: 20px;
    background: rgba(0,0,0,0.4);
}
.create-workout-search-list-cell-add-sets-container-content-col {
    width: calc(50% - 40px);
}
    .create-workout-search-list-cell-add-sets-container-content-col h4 {
        font-size: 13.5px;
        color: #ffffff;
        margin: 0px;
    }
    .create-workout-search-list-cell-add-sets-container-content-col p {
        font-size: 10px;
        color: #ffffff;
        margin: 0px;
    }

    .create-workout-search-list-cell-add-sets-container-content-button {
        width: 140px;
        height: 32px;
        background: #5B62FF;
        padding: 9px 8px;
        border-radius: 5px;
        position: relative;
        -webkit-transition: all 0.15s;
        -moz-transition: all 0.15s;
        transition: all 0.15s;
    }
    .create-workout-search-list-cell-add-sets-container-content-button:hover {
        cursor: pointer;
        background: #7278F2;
        -webkit-transition: all 0.15s;
        -moz-transition: all 0.15s;
        transition: all 0.15s;
    }
        .create-workout-search-list-cell-add-sets-container-content-button p {
            width: 90%;
            text-align: center;
            font-size: 10px;
            color: #ffffff;
            margin: 0px;
        }
        .create-workout-search-list-cell-add-sets-container-content-button-icon {
            font-size: 10px !important;
            color: #ffffff;
            position: absolute;
            top: 10.5px;
            right: 8px;
        }

        .create-workout-search-list-cell-add-sets-container-content-col-sets {
            display: flex;
        }
            .create-workout-search-list-cell-add-sets-container-content-col-input-container {
                width: 40%;
                padding: 0px 24px;
                border-radius: 5px;
                border: solid;
                border-width: 1px;
                border-color: #2B2B30;
                height: 32px;
                margin: 0px 8px;
                font-size: 13.5px;
            }
                .create-workout-search-list-cell-add-sets-container-content-col-input-container .native-input {
                    margin: 0px;
                    padding: 7px 0px;
                    width: 100%;
                    text-align: center;
                }
            .create-workout-search-list-cell-add-sets-container-content-col-button {
                padding: 5px 24px;
                border-radius: 5px;
                border: solid;
                border-width: 1px;
                border-color: #2B2B30;
                height: 32px;
                cursor: pointer;
                background: rgba(255,255,255,0.02);
                -webkit-transition: all 0.15s;
                -moz-transition: all 0.15s;
                transition: all 0.15s;
            }
            .create-workout-search-list-cell-add-sets-container-content-col-button:hover {
                background: rgba(255,255,255,0.06);
                cursor: pointer;
                -webkit-transition: all 0.15s;
                -moz-transition: all 0.15s;
                transition: all 0.15s;
            }

                .create-workout-search-list-cell-add-sets-container-content-col-button p {
                    color: #ffffff;
                    font-size: 13.5px;
                    margin: 0px;
                }



