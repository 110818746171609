/* .trainers-page- */

.trainers-page {
	width: 100%;
	height: 100%;
}
.trainers-page-container {
	width: 100%;
	height: 100%;
	display: flex;
	position: relative;
}

	.trainers-page-content {
		width: 60%;
		height: 100%;
	}
		.trainers-page-upper-content {
			display: flex;
			padding: 0px 36px;
		}
			.trainers-page-upper-content-search-container {
				width: 50%;
			}
			.trainers-page-upper-content-showing-number-container {
				padding: 10px 12px;
			}
				.trainers-page-upper-content-showing-number-container p {
					font-size: 13.5px;
					margin: 0px;
					color: #6C6C6C;
				}
			.trainers-page-upper-content-add-button-container {

			}
			.trainers-page-upper-content-add-button-container {
				width: 90px;
				margin-left: auto;
			}
				.trainers-page-upper-content-add-button {
					padding: 8px 12px;
					padding-left: 12px;
					display: flex;
					font-size: 12px;
					border-radius: 5px;
					margin-left: 0px;
					color: #ffffff !important;
					background: #1F1F21;
					border: solid;
					border-width: 0.5px;
					border-color: #2B2B30;
					box-shadow: 0 2px 12px 2px rgba(0, 0, 0, 0.1);
					float: right;
					white-space: nowrap;
					margin-top: 2px;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
				.trainers-page-upper-content-add-button:hover {
					background: #1F1F21;
					cursor: pointer;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
					.trainers-page-upper-content-add-button p {
						font-size: 12px;
						color: #ffffff;
						margin: 0px;
					}
					.trainers-page-upper-content-add-button-icon {
						font-size: 13px !important;
						margin-top: 1px;
						margin-right: 4px;
						margin-left: auto;
					}
		.trainers-page-content-list-container {
			width: 100%;
		}
			.trainers-page-content-list-header-col {

			}
			.trainers-page-content-list-header-col-header {
				width: 30%;
			}
			.trainers-page-content-list-header-col-md {
				width: 35%;
				padding-left: 12px;
			}
				.trainers-page-content-list-header {
					width: 100%;
					display: flex;
					background: #1F1F21;
					padding: 12px 35px;
				}
					.trainers-page-content-list-header p {
						font-size: 10px;
						color: #6C6C6C;
						margin: 0px;
					}

	.trainers-page-info-panel-container {
		width: 40%;
		border-left: solid;
		border-width: 1px;
		border-color: #2B2B30;
		position: relative;
		box-shadow: 0px 18px 24px 12px rgba(0, 0, 0, 0.08);
	}
		.trainers-page-info-panel-container-empty {
			width: 100%;
			padding: 36px;
			margin-top: calc(50vh - 120px);
		}
			.trainers-page-info-panel-container-empty h4 {
				font-size: 16px;
				color: #ffffff;
				margin: 0px;
			}
			.trainers-page-info-panel-container-empty p {
				font-size: 13.5px;
				color: #6C6C6C;
				margin: 0px;
				margin-top: 3px;
			}
		.trainers-page-info-panel-container-content {
			width: 100%;
			padding: 36px;
			padding-top: 6px;
			height: calc(100% - 52px);
			overflow-y: scroll;
			
		}
		/*.trainers-page-info-panel-container-content-noscroll {
			overflow-y: hidden !important;
		}*/
		.trainers-page-info-panel-container-content-loading-curtain {
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
			background: rgba(12,13,20,0.1);
			-webkit-backdrop-filter: blur(2px);
		}
			.trainers-page-info-panel-container-loading-container {
				width: 100%;
				margin-top: calc(50vh - 100px);
			}
				.trainers-page-info-panel-container-loading-container p {
					font-size: 13.5px;
					color: #6C6C6C;
					margin: 0px;
					margin-top: 8px;
					width: 100%;
					text-align: center;
				}

			.trainers-page-info-panel-container-content-section {
				padding: 36px 0px;
			}
			.trainers-page-info-panel-container-content-section-line {
				width: 100%;
				border-bottom: solid;
				border-width: 1px;
				border-color: #2B2B30;
			}
				.trainers-page-info-panel-container-content-section-header { 
					display: flex;
					padding-bottom: 16px;
				}
					.trainers-page-info-panel-container-content-section-header h4 {
						font-size: 13.5px;
						color: #9DA0AD;
						margin: 0px;
					}
					.trainers-page-info-panel-container-content-section-header p {
						font-size: 13.5px;
						color: #4959C1;
						margin: 0px;
						margin-left: 8px;
					}
				.trainers-page-info-panel-container-content-section-body {
					min-height: 150px;
				}
					.trainers-page-info-panel-container-content-section-body-row {
						width: 100%;
						display: flex;
						padding: 12px 4px;
						border-bottom: solid;
						border-width: 1px;
						border-color: #2B2B30;
						-webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}
					.trainers-page-info-panel-container-content-section-body-row:hover {
						border-radius: 5px;
						background: #1F1F21;
						cursor: pointer;
						-webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}
						.trainers-page-info-panel-container-content-section-body-row-col {
							width: 50%;
						}
							.trainers-page-info-panel-container-content-section-body-row-col h4 {
								font-size: 13.5px;
								color: #ffffff;
								margin: 0px;
								font-weight: 400;
							}
		.trainers-header-save-button {
			right: 38px !important;
		}



