
.node-stream-analyze-chart-container {
	height: 300px;
	width: calc(100% + 30px);
	margin-bottom: 48px;
	cursor: none !important;
	background: #1F1F21;
	position: relative;
}
.node-stream-analyze-chart-container:hover {
	cursor: none;
}
	.node-stream-analyze-chart-container-mid-line {
		width: 100%;
		height: 1px;
		border-width: 1px !important;
		border: dashed;
		border-color: #ffffff;
		opacity: 0.2;
		position: absolute;
		left: 30px;
		top: 158px;
		z-index: 10;
	}
.node-stream-analysis-chart-simulate-button {
	position: absolute;
	background: #5B62FF;
	padding: 4px 9px;
	border-radius: 5px;
	z-index: 200;
	top: 440px;
	left: 20px;
	cursor: pointer;
}

	.node-stream-analysis-chart-update-location-button {
		position: absolute;
		z-index: 200;
		top: 440px;
		left: 130px;
	    padding: 4px 8px;
	    background: #1F1F21;
	    border: solid;
	    border-width: 1px;
	    border-color: #2B2B30;
	    display: flex;
	    border-radius: 5px;
	    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.12);
	    -webkit-transition: all 0.15s;
	    -moz-transition: all 0.15s;
	    transition: all 0.15s;
	}
	.node-stream-analysis-chart-update-location-button:hover {
	    background: #1F1F21;
	    cursor: pointer;
	    -webkit-transition: all 0.15s;
	    -moz-transition: all 0.15s;
	    transition: all 0.15s;
	}
		.node-stream-analysis-chart-update-location-button-plus {
			left: 220px;
		}
		.node-stream-analysis-chart-update-location-button-viewing {
			left: 320px;
		}
		.node-stream-analysis-chart-update-location-button-viewing:hover {
			cursor: default !important;
			background: #1F1F21 !important;
		}
		.node-stream-analysis-chart-update-location-button-antiquated {
			left: 700px;
			color: #FF4D4D !important;
		}
		.node-stream-analysis-chart-update-location-button-stream {
			left: 550px;
			color: #ffffff !important;
		}
	
	.node-stream-analysis-chart-rep-marker-container {
		width: calc(100% - 26px);
		float: right;
		margin-left: auto;
		position: relative;
		height: 8px;
		background: green;
		margin-top: 12px;
	}
		.node-stream-analysis-chart-rep-marker {
			position: absolute;
			z-index: 100;
			top: 0px;
			height: 1000px;
			width: 2px;
			background: #FF4D4D;
		}
		.node-stream-analysis-chart-rep-marker:hover {
			cursor: none;
		}
			.node-stream-analysis-chart-rep-marker-inner {
				position: relative;
			}
				.node-stream-analysis-chart-rep-marker-text {
					position: absolute;
					top: -34px;
					white-space: nowrap;
				}
					.node-stream-analysis-chart-rep-marker-text p {
					    font-size: 12px;
					    color: #9DA0AD;
					    margin: 0px;
					}
		.node-stream-analysis-chart-manual-rep-marker {
			position: absolute;
			z-index: 100;
			top: 0px;
			height: 1000px;
			/*background: rgba(255, 255, 255, 0.1);*/
			border-right: solid;
			border-width: 2px;
			/*border-color: #FF4D4D;*/
		}
			.node-stream-analysis-chart-manual-rep-marker-detected {
				border-color: #5B62FF !important;
				border-width: 3px;
				background: rgba(91, 98, 255, 0.2) !important;
				top: -32px;
			}
				.node-stream-analysis-chart-manual-rep-marker-detected .node-stream-analysis-chart-rep-marker-text p {
					color: #5B62FF;
					font-weight: 600;
				}

			.node-stream-analysis-chart-manual-rep-marker-adding {
				border-color: #FF62FF !important;
				border-width: 3px;
				background: rgba(255, 98, 255, 0.2) !important;
				top: -32px;
			}

			.node-stream-analysis-chart-manual-rep-marker-rising {
				border-color: #5BFFFF !important;
				border-width: 2px !important;
				border-left: solid;
				background: rgba(91, 255, 255, 0.2) !important;
				top: -32px;
			}
			.node-stream-analysis-chart-manual-rep-marker-pause {
				border-color: #FFFFFF !important;
				border-width: 0px !important;
				border-left: solid;
				background: rgba(255, 255, 255, 0) !important;
				top: -32px;
			}
			.node-stream-analysis-chart-manual-rep-marker-hold {
				border-color: rgba(255, 91, 255, 0.4) !important;
				border-width: 1px !important;
				border-left: solid;
				background: rgba(255, 91, 255, 0.1) !important;
				top: -32px;
			}

		.node-stream-analysis-chart-rep-marker-hover {
			background: white;
		}

		.node-stream-analysis-chart-rep-marker-video {
			background: rgba(255, 0, 255, 1.0);
			top: -12px;
			height: 1024px;
		}




