.client-goal-progress {
	border-bottom: solid;
	border-width: 1px;
	border-color: #2B2B30;
	padding-bottom: 24px;
	width: 100%;
}
.client-goal-progress-no-border {
	border-bottom: none;
}
	.client-goal-progress-header {
		border-bottom: solid;
		border-width: 1px;
		border-color: #2B2B30;
		width: calc(100% + 72px);
		margin-left: -36px;
		padding: 0px 36px;
		padding-bottom: 24px;
		position: relative;
	}
		.client-goal-progress-header h4 {
			font-size: 13.5px;
			color: #ffffff;
			margin: 0px;
			font-weight: 600;
		}
		.client-goal-progress-header p {
			padding-top: 4px;
			font-size: 13.5px;
			color: #ffffff;
			margin: 0px;
			font-weight: 400;
		}
		.client-goal-progress-header-active {
			position: absolute;
			top: -4px;
			right: 20px;
			background: rgba(73,89,193,0.2);
			padding: 0px 8px;
			padding-bottom: 4px;
			border-radius: 5px;
		}
			.client-goal-progress-header-active p {
				font-weight: 500;
				font-size: 10px;
				color: rgba(112,138,240,1.0);
				margin: 0px;
			}

	.client-goal-progress-content {
		width: 100%;
		/*height: 300px;*/
		/*border-bottom: solid;
		border-width: 1px;
		border-color: #2B2B30;*/
		padding-bottom: 12px;
	}
		.client-goal-progress-content-upper {
			width: 100%;
			display: flex;
			
			margin-bottom: 12px;
		}
			.client-goal-progress-content-upper-col {
				padding: 16px 0px;
			}
				.client-goal-progress-content-upper-col-0 {
					display: flex;
					width: 33%;
				}
				.client-goal-progress-content-upper-col-1 {
					width: 25%;
				}
				.client-goal-progress-content-upper-col-2 {
					width: 42%;
				}


				.client-goal-progress-content-upper-col-pie-container {
					width: 40px;
					height: 40px;
					position: relative;
					margin-right: 12px;
				}
					.client-goal-progress-content-upper-col-pie-overlay {
						position: absolute;
						width: 28px;
						height: 28px;
						top: 6px;
						left: 6px;
						border-radius: 40px;
						background: #161719;
					}
				.client-goal-progress-content-upper-col-text-container {

				}
					.client-goal-progress-content-upper-col-text-container p {
						font-size: 10px;
						color: #9DA0AD;
						margin: 0px;
					}
					.client-goal-progress-content-upper-col-text-container h4 {
						font-size: 18px;
						color: #ffffff;
						margin: 0px;
						margin-top: 4px;
						font-weight: 600;
					}


		.client-goal-progress-content-progress-graph-container {
			width: 100%;
			height: 200px;
			position: relative;
		}
			.client-goal-progress-content-progress-graph-dot {
				position: absolute;
				height: 14px;
				width: 14px;
				z-index: 100;
				background: #4959C1;
				border-radius: 7px;
				border:solid;
				border-width: 2px;
				border-color: #1F1F21;
				/*left: 0px;
				top: 0px;*/
			}

			.client-goal-progress-content-progress-graph-end-line {
				width: 50px;
				border-left: solid;
				border-width: 1px;
				border-color: #F02E4A;
				height: 184px;
				top: 8px;
				position: absolute;
				background: rgb(240,46,74);
				background: linear-gradient(90deg, rgba(240,46,74,0.2) 0%, rgba(240,46,74,0) 100%);
			}
				.client-goal-progress-content-progress-graph-end-line-inner {
					width: 100%;
					height: 100%;
					position: relative;
				}
					.client-goal-progress-content-progress-graph-end-line-tick {
						position: absolute;
						left: -1px;
						background: #6C6C6C;
						height: 12px;
						width: 1px;
						bottom: -12px;
					}
					.client-goal-progress-content-progress-graph-end-line-text-container {
						position: absolute;
						bottom: -27px;
						left: -50%;
						width: 100%;
					}
						.client-goal-progress-content-progress-graph-end-line-text-container p {
							text-align: center;
							width: 100%;
							font-size: 10px;
							color: #9DA0AD;
							margin: 0px;
						}
				.client-goal-progress-content-progress-graph-target-container {
					position: absolute;
					top: -8px;
					right: 12px;
					font-weight: 500;
				}
					.client-goal-progress-content-progress-graph-target-container p {
						font-size: 10px;
						color: #ffffff;
						margin: 0px;
						font-weight: 700;
					}
					.client-goal-progress-content-progress-graph-target-container-downward {
						top: auto;
						bottom: 16px;
						right: -8px;
					}


				.client-goal-progress-content-progress-graph-starting-container {
					position: absolute;
					bottom: 32px;
					left: -12px;
					font-weight: 500;
				}
					.client-goal-progress-content-progress-graph-starting-container p {
						font-size: 10px;
						color: #ffffff;
						margin: 0px;
						font-weight: 700;
					}
					.client-goal-progress-content-progress-graph-starting-container-downward {
						top: -12px;
						bottom: auto;
					}


				.client-goal-progress-content-progress-graph-start-line-inner {
					width: 100%;
					height: 100%;
					position: absolute;
					left: 3px;
					bottom: 8px;
				}
					.client-goal-progress-content-progress-graph-start-line-tick {
						position: absolute;
						left: -1px;
						background: #6C6C6C;
						height: 12px;
						width: 1px;
						bottom: -12px;
					}
					.client-goal-progress-content-progress-graph-start-line-text-container {
						position: absolute;
						bottom: -27px;
						left: -50%;
						width: 100%;
					}
						.client-goal-progress-content-progress-graph-start-line-text-container p {
							text-align: center;
							width: 100%;
							font-size: 10px;
							color: #9DA0AD;
							margin: 0px;
						}

		.client-goal-progress-content-lower {
			width: 100%;
			display: flex;
			padding-top: 32px;
		}
			.client-goal-progress-content-lower-col {
				padding: 16px 0px;
				padding-bottom: 0px;
			}
				.client-goal-progress-content-lower-col-0 {
					display: flex;
					width: 33%;
				}
				.client-goal-progress-content-lower-col-1 {
					width: 33%;
				}
				.client-goal-progress-content-lower-col-2 {
					width: 33%;
				}

				.client-goal-progress-content-lower-col-text-container {

				}
					.client-goal-progress-content-lower-col-text-container p {
						font-size: 10px;
						color: #9DA0AD;
						margin: 0px;
					}
					.client-goal-progress-content-lower-col-text-container h4 {
						font-size: 13.5px;
						color: #ffffff;
						margin: 0px;
						margin-top: 4px;
						font-weight: 600;
					}
			.client-goal-progress-content-lower-info {
				width: 100%;
				padding-top: 24px;
			}
				.client-goal-progress-content-lower-info p {
					font-size: 10px;
					font-weight: 600;
					margin: 0px;
					color: #ffffff;
				}



