.client-chat-message-container {
    width: 100%;
    display: flex;
    margin-bottom: 12px;
    padding: 20px 8px;
    border-top: solid;
    border-width: 0.5px !important;
    border-color: var(--ef-border);
}

.trainer-chat-message-container {
    width: 100%;
    display: flex;
    margin-bottom: 12px;
    padding: 20px 8px;
    border-top: solid;
    border-width: 0.5px !important;
    border-color: var(--ef-border);
}


    .chat-message-profile-container {
        width: 80px;
        min-width: 80px;
    }
        .chat-message-icon-container {
            position: relative;
            align-items: center;
            justify-content: center;
            width: fit-content;
            height: fit-content;
            width: 50px;
            height: 50px;
            border-radius: 25px;
            overflow: hidden;
            background: #0B0C0F;
            box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.1);
        }
            .chat-message-icon-container-image {
                width: 50px;
                height: 50px;
                border-radius: 25px;
            }
            .chat-message-icon-container h4 {
                font-size: var(--ef-font-size-l);
                color: var(--ef-text-mid);
                margin: 0px;
                width: 100%;
                text-align: center;
                padding-top: 15px;
            }

.client-username-timestamp-row {
    display: flex;
    flex-direction: row;
}

.trainer-username-timestamp-row {
    display: flex;
    flex-direction: row;
}

.client-icon-container {
    
}

.trainer-icon-container {
    
}

.client-username-container {
    margin-left: 3px;
}

    .client-username-container p {
        font-size: var(--ef-font-size-l);
        color: var(--ef-text-white);
        margin: 0px;
        margin-top: 4px;
    }

.trainer-username-container {
    margin-right: 3px;
}

    .trainer-username-container p {
        font-size: var(--ef-font-size-l);
        color: var(--ef-text-mid);
        margin: 0px;
        margin-top: 4px;
    }

.client-timestamp-container {
    margin-left: 24px;
    margin-bottom: 20px;
}
    .client-timestamp-container p {
        font-size: 14px;
        color: var(--ef-text-dark);
        margin: 0px;
    }

.trainer-timestamp-container {
    margin-left: 24px;
    margin-bottom: 20px;
}

    .trainer-timestamp-container p {
        font-size: 14px;
        color: var(--ef-text-dark);
        margin: 0px;
    }

.client-message-text-container {
    margin-top: -7px;
    flex-direction: row;
}
    .client-message-text-container p {
        font-size: var(--ef-font-size-xl);
        color: var(--ef-text-white);
        font-weight: 300;
        margin: 0px;
    }

.trainer-message-text-container {
    margin-top: -7px;
    margin-right: 67px;
    text-align: left;
    display: flex;
    justify-content: flex-start;
}
    .trainer-message-text-container p {
        font-size: var(--ef-font-size-xl);
        color: var(--ef-text-mid);
        font-weight: 300;
        margin: 0px;
    }

    .client-message-video-container {
        width: 100%;
        max-height: 420px;
        border-radius: var(--ef-radius-m);
        overflow: hidden;
    }
        .client-message-video-container-image {
            max-height: 100%;
            border-radius: var(--ef-radius-m);
            overflow: hidden;
            
        }
        .client-message-video-container-video {
            width: 100%;
            border-radius: var(--ef-radius-m);
            overflow: hidden;
            
        }

    .trainer-message-video-container {
        width: 100%;
        padding-right: 32px;
        max-height: 420px;
        border-radius: var(--ef-radius-m);
        overflow: hidden;
    }

