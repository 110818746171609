.client-profile-page {

}

.client-profile-page-add-first-note-button {
	max-width: 150px;
	padding: 8px 12px;
	border-radius: 5px;
	background: #4959C1;
	box-shadow: 0 1px 12px 2px rgba(20, 5, 50, 0.1);
	position: relative;
	margin: auto;
	margin-top: 16px;
}
.client-profile-page-add-first-note-button:hover {
	background: #5C6BC9;
	cursor: pointer;
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
}
	.client-profile-page-add-first-note-button p {
		font-size: 13.5px;
		font-weight: 500;
		color: white;
		width: 100%;
		padding-left: 12px;
		text-align: center;
	}
	.client-profile-page-add-first-note-button-icon {
		position: absolute;
		font-size: 14px !important;
		top: 9px;
		left: 16px;
		color: white;
	}
.client-profile-page-note-header-container {
	width: 100%;
	padding: 36px;
	border-bottom: solid;
	border-width: 1px;
	border-color: #2B2B30;
	display: flex;
}
	.client-profile-page-note-header-container h4 {
	    font-size: 18px;
	    color: #ffffff;
	    margin: 0px;
	}
	.client-profile-page-note-header-buttons-container {
		margin-left: auto;
		float: right;
		display: flex;
		margin-top: -9px;
	}
		.client-profile-page-note-header-button {
			padding: 6px 12px;
			border-radius: 5px;
			display: flex;
			margin-left: 12px;
			box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.12);
		}
		.client-profile-page-note-header-button:hover {
			cursor: pointer;
		}
			.client-profile-page-note-header-button p {
			    font-size: 13.5px;
			    color: #9DA0AD;
			    margin: 0px;
			}
			.client-profile-page-note-header-button-blue {
				background: #5B62FF;
				padding-right: 16px;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
			.client-profile-page-note-header-button-blue:hover {
				background: #7278F2;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
				.client-profile-page-note-header-button-blue p {
					color: white;
				}
			.client-profile-page-note-header-button-dark {
				background: #1F1F21;
				border: solid;
				border-width: 1px;
				border-color: #2B2B30;
				padding-right: 4px;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
			.client-profile-page-note-header-button-dark:hover {
				background: #1F1F21;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
				.client-profile-page-note-header-button-dark p {
					color: #ffffff !important;
				}

				.client-profile-page-note-header-button-icon {
					font-size: 14px !important;
					margin-right: 4px;
					margin-top: 1px;
				}
					.client-profile-page-note-header-button-blue-icon {
						
					}
					.client-profile-page-note-header-button-dark-icon {
						color: #9DA0AD;
						margin-right: 6px;
					}
					.client-profile-page-note-header-button-dark-icon-arrow {
						margin-left: 4px;
					}

.client-profile-page-note-container {
	width: 100%;
}
	.client-profile-page-note-container-add-new-note-cell {
		background: #1A1B2A;
		border-width: 0.5px !important;
		border-color: #2E3247 !important;
		border: solid;
		border-radius: 5px;
		box-shadow: 0 1px 32px 8px rgba(0, 0, 0, 0.1);
		padding: 18px;
		position: relative;
	}
		.client-profile-page-note-container-add-new-note-cell h4 {
			font-size: 13.5px;
			margin: 0px;
			width: 100%;
			text-align: center;
		}
		.client-profile-page-note-container-add-new-note-cell p {
			font-size: 10px;
			margin: 0px;
			width: 100%;
			text-align: center;
		}
		.client-profile-page-add-new-note-button-icon {
			position: absolute;
			font-size: 13.5px !important;
			top: calc(50% - 15px);
			right: calc(50% - 90px);
			color: white;
		}

	.client-profile-page-note-cell {
		padding: 36px;
		position: relative;
		border-bottom: solid;
		border-width: 1px;
		border-color: #2B2B30;
	}
		.client-profile-page-note-cell-header {

		}
			.client-profile-page-note-cell-header h2 {
				margin: 0px;
				font-size: 16px;
				margin-bottom: 2px;
				color: #ffffff;
			}
			.client-profile-page-note-cell-header p {
				margin: 0px;
				font-size: 12px;
			}
			.client-profile-page-note-cell-type-container {
				padding: 2px 12px;
				border-radius: 3px;
				position: absolute;
				top: 20px;
				right: 24px;
			}
				.client-profile-page-note-cell-type-container {

				}
		.client-profile-page-note-cell-body {
			margin-top: 8px;
		}
			.client-profile-page-note-cell-body p {
				margin: 0px;
				font-size: 12px;
			}






