.contact-modal-container {
	height: 30%;
	min-height: 420px;
	margin: auto;
	margin-top: 6%;
	min-width: 400px;
	width: 50%;
	background: #202A34;
	border-radius: 4px;
	/*overflow: hidden;*/
	box-shadow: 0 0px 100px 0 rgba(0, 0, 0, 0.5);
	position: relative;
}

.contact-modal-top {
	width: 100%;
	height: calc(100% - 60px);
	padding: 36px;
	padding-top: 18px;
}

.contact-modal-top h3 {
	margin-bottom: 8px;
	color: #C1C4CC;
	font-size: 24px;
}

.contact-modal-top p {
	margin: 0px;
	color: #C1C4CC;
	font-size: 12px;
}

.contact-modal-input-container {
	padding-top: 12px;
}