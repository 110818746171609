/* .intra-workout-current-exercise-rep-summary- */

.intra-workout-current-exercise-rep-summary {
	display: flex;
	width: 100%;
	padding: 12px 0px;
}
	.intra-workout-current-exercise-rep-summary .intra-workout-overview-summary-stats-input-container-outter {

	}
		.intra-workout-current-exercise-rep-summary .intra-workout-overview-summary-stats-input-container-outter-lg {
			width: 270px;
		}
			.intra-workout-current-exercise-rep-summary .intra-workout-overview-summary-stats-input-container-outter-lg .intra-workout-overview-summary-stats-input-container {
				padding-top: 4px;
			}