/* .new-location-modal */

.new-location-modal {

}
	.new-location-modal-body {

	}
		.new-location-modal-map-container {
			width: 100%;
			height: calc(100vh - 200px);
		}
	.new-location-modal-body-section {
		padding: 12px 0px;
		width: calc(100% - 64px);
		margin: 0px auto;
		padding-bottom: 24px;
		border-bottom: solid;
		border-width: 1px;
		border-color: #2B2B30;
	}
		.new-location-modal-body-section .assign-modal-body-row {
			padding-left: 0px;
			padding-right: 0px;
		}
		.new-location-modal-body-section .assign-modal-body-row-header {
			width: 100%;
			padding-left: 0px;
			padding-right: 0px;
		}

	.new-location-modal-row-label-container {
		position: relative;
	}
		.new-location-modal-row-label-optional-container {
			position: absolute;
			top: -1px;
			right: 0px;
			background: #2B2B30;
			border-radius: 3px;
			padding: 2px 6px;
		}
			.new-location-modal-row-label-optional-container p {
			    font-size: 11px;
			    color: #9DA0AD;
			    margin: 0px;
			}

	.new-location-modal-times-header {
		width: 100%;
		display: flex;
		padding-left: 182px;
	}
		.new-location-modal-times-header-col {
			width: 140px;
		}
			.new-location-modal-times-header-col p {
			    font-size: 12px;
			    color: #9DA0AD;
			    margin: 0px;
			}
	.new-location-modal-day-time-row {
		display: flex;
		width: 100%;
		padding: 8px 0px;
	}
		.new-location-modal-day-time-row-checkbox-container {
			width: 50px;
			padding-top: 6px;
		}
		.new-location-modal-day-time-row-title-container {
			padding: 8px 0px;
			width: 130px;
		}
			.new-location-modal-day-time-row-title-container p {
			    font-size: 13.5px;
			    color: white;
			    font-weight: 600;
			    margin: 0px;
			    width: 120px;
			}
			.new-location-modal-day-time-row-unselected .new-location-modal-day-time-row-title-container p {
				color: #6C6C6C;
			}
			.new-location-modal-day-time-row-unselected .new-location-modal-day-time-row-input-container {
				opacity: 0.3;
			}
	.new-location-modal-day-time-row-input-container {
		position: relative;
		width: 140px;
		margin-left: 12px;
	}
		.new-location-modal-day-time-row-input-container-inner {
			
			padding: 8px 12px;
			width: 100%;
			border-radius: 5px;
			border: solid;
			border-width: 1px;
			border-color: #2B2B30;
			box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.08);
			
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
		.new-location-modal-day-time-row-input-container-inner:hover {
			background: #1F1F21;
			cursor: pointer;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
			.new-location-modal-day-time-row-input-container p {
			    font-size: 13.5px;
			    color: white;
			    margin: 0px;
			    width: 100%;
			    text-align: center;
			}
			.new-location-modal-day-time-row-input-menu {
				position: absolute;
				top: 40px;
				left: -8px;
				border-radius: 5px;
				background: #1F1F21;
				box-shadow: 0px 0px 24px 16px rgba(0, 0, 0, 0.16);
				border: solid;
				border-width: 1px;
				border-color: #2B2B30;
				width: 146px;
				height: 200px;
				overflow-y: scroll;
				z-index: 100;
			}
				.new-location-modal-day-time-row-input-menu-row {
					padding: 6px 16px;
					border-bottom: solid;
					border-width: 1px;
					border-color: #2B2B30;
					width: 100%;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
				.new-location-modal-day-time-row-input-menu-row:hover {
					background: #1F1F21;
					cursor: pointer;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
					.new-location-modal-day-time-row-input-menu-row p {
					    font-size: 13.5px;
					    color: whte;
					    margin: 0px;
					    width: 100%;
					    text-align: center;
					}

	.new-location-modal-amenity-container {
		width: 100%;
	}
		.new-location-modal-amenity-row {
			width: 100%;
		}
			.new-location-modal-amenity-row-header {
				width: 100%;
				padding: 12px 0px;
				margin-top: 16px;
			}
				.new-location-modal-amenity-row-header h4 {
				    font-size: 16px;
				    color: #ffffff;
				    margin: 0px;
				}
			.new-location-modal-amenity-row-content {
				display: flex;
				padding: 8px 0px;
			}
				.new-location-modal-amenity-row-content-checkbox-container {
					width: 50px;
				}
				.new-location-modal-amenity-row-content-text-container {

				}
					.new-location-modal-amenity-row-content-text-container p {
					    font-size: 13.5px;
					    color: #9DA0AD;
					    margin: 0px;
					}

				.new-location-modal-amenity-row-content-text-container-upper {
					display: flex;
				}
					.new-location-modal-amenity-row-content-text-container-upper h4 {
					    font-size: 13.5px;
					    color: white;
					    margin: 0px;
					}
					.new-location-modal-amenity-row-content-text-container-upper-icon {
						font-size: 15px !important;
						color: white;
						margin-right: 8px;
					}

	.new-location-modal-hq-container {
		margin-top: -20px;
	}













