
.create-workout-exercise-list-cell-rep-col-container-editable-focused {
	padding-top: 0px !important;
	padding-left: 4px;
}
	.create-workout-exercise-list-cell-rep-col-container-editable-focused ion-input {
		margin-top: -4px;

	}

.create-workout-exercise-list-cell-name-container-editable {
	display: flex;
	white-space: nowrap;
}
	.create-workout-exercise-list-cell-name-container-editable h2 {
		display: flex;
		white-space: nowrap;
	}
	.create-workout-exercise-list-cell-name-remove-button {
		width: 22px;
		height: 22px;
		border-radius: 5px;
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
		padding: 3px 4px;
		margin-top: -2px;
		margin-left: 6px;
	}
	.create-workout-exercise-list-cell-name-remove-button:hover {
		background: rgba(0,0,0,0.2);
		box-shadow: 0 0px 8px 4px rgba(3, 5, 15, 0.1);
		cursor: pointer;
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}
		.create-workout-exercise-list-cell-name-remove-button-icon {
			font-size: 13.5px !important;
			color: #6C6C6C;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
		.create-workout-exercise-list-cell-name-remove-button:hover .create-workout-exercise-list-cell-name-remove-button-icon {
			color: #F02E4A;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}