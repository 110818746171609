/* .exercise-cell- */

.exercise-cell-container {
		/*height: 70px;
		padding: 16px 20px;
		width: 100%;
		border-radius: 5px;
		background-color: #1F1F21;
		box-shadow: 0 4px 24px 6px rgba(0, 0, 0, 0.1);
		margin-bottom: 12px;*/
		padding: 12px 24px;
		width: 100%;
		/*border-radius: 5px;
		background-color: #1F1F21;
		box-shadow: 0 4px 24px 6px rgba(0, 0, 0, 0.1);*/
		/*margin-bottom: 12px;*/
		border-bottom: solid;
		border-width: 1px;
		border-color: #2B2B30;
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}
		.exercise-cell-info-card-content {
			display: flex;
			width: 100%;
		}
			.exercise-cell-image-container {
				background-color: rgba(0,0,0,0.3);
				width: 40px;
				height: 40px;
				max-width: 40px;
				max-height: 40px;
				border-radius: 5px;
				overflow: hidden;
				margin-right: 16px;
				padding: 0px;
			}
				.exercise-cell-image {
					height: 100% !important;
					width: 400px !important;
					border-radius: 4px !important;
				}
			.exercise-cell-info-card-col-container {
				display: flex;
				width: 100%;
			}
				.exercise-cell-info-card-col-container {
					display: flex;
					width: calc(100%);
				}
				.exercise-cell-info-card-col {
					display: block;
					padding-right: 16px;
					width: 20%;
					overflow-x: hidden;
				}
				.exercise-cell-info-card-col-large {
					width: calc(40% - 15px);
				}
			.exercise-cell-info-card-info-container {
				padding-top: 6px;
				position: relative !important;
			}
				.exercise-cell-info-card-info-container h4 {
					font-weight: 500;
					width: 100%;
					overflow: hidden;
					font-size: 13.5px;
					color: #ffffff;
					text-align: left;
					margin: 0px;
				}

				.exercise-cell-info-card-info-container p {
					font-size: 12px;
					font-size: 10px;
					color: #9DA0AD;
					height: 15px;
					width: 100%;
					overflow: hidden;
					
				}
					.exercise-cell-info-card-info-container-no-workouts {

					}
					.exercise-cell-info-card-info-container-no-workouts h4 {
						color: #6C6C6C !important;
					}
					.exercise-cell-info-card-info-container-no-workouts p {
						color: #6C6C6C !important;
					}
						.exercise-cell-info-card-info-container-no-workouts-alert {
							position: absolute;
							left: 55px;
							top: 6px;
							height: 12px;
							width: 12px;
							border-radius: 6px;
							background: rgba(255,23,23,0.3);
							padding-left: 4.5px;
							padding-top: 1px;
						}
							.exercise-cell-info-card-info-container-no-workouts-alert p {
								color: #F02E4A !important;
								font-weight: 700;
								font-size: 8px;
							}

					.exercise-cell-info-card-info-trained-with-me-container {
						display: flex;
					}
						.exercise-cell-info-card-info-trained-with-me-icon {
							width: 10px;
							height: 10px;
							max-height: 10px;
							min-width: 10px;
							background: #5B62FF;
							border: solid;
							border-color: #2B3158;
							border-width: 3px;
							border-radius: 5px;
							margin-top: 1px;
							margin-right: 6px;
						}

		.exercise-cell-info-card-col-video {
			width: 30px;
			height: 30px;
			background: rgba(73,89,193,0.2);
			margin-top: 4px;
			border-radius: 5px;
			padding: 6.5px 8.5px;
			overflow: hidden;

		}
		.exercise-cell-info-card-col-video-unselected {
			background: rgba(255,255,255,0.04);
		}
			.exercise-cell-info-card-video-icon {
				font-size: 13.5px !important;
			}
				.exercise-cell-info-card-video-icon-selected {
					color: #5B62FF;
				}
				.exercise-cell-info-card-video-icon-unselected {
					color: #6C6C6C;
				}

.exercise-cell-muscle-string-container {
	margin: auto;
	margin-top: 20px;
	padding: 4px 12px;
	background-color: #242C45;
	border-radius: 4px;
	color: #5B62FF;
	font-size: 12px;
	text-align: center;
	width: 140px;
	max-width: 100%;
	max-height: 24px;
	overflow: hidden;
}
