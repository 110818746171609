/* .assign-modal-workout-cell- */

.assign-modal-workout-cell {
	width: 100%;
	border-bottom: solid;
	border-width: 1px;
	border-color: #2B2B30;
	padding: 12px 32px;
	min-height: 41px;
	max-height: 41px;
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
}
.assign-modal-workout-cell:hover {
	background: #28282D;
	cursor: pointer;
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
}
	.assign-modal-workout-cell-content {
		width: 100%;
		display: flex;
		position: relative;
	}
		.assign-modal-workout-cell-content-col {
			padding-right: 8px;
			width: 30%;
		}
		.assign-modal-workout-cell-content-col-xs {
			width: 64px;
		}
		.assign-modal-workout-cell-content-col-lg {
			width: calc(40% - 64px);
		}
			.assign-modal-workout-cell-content-col-lg h4 {
			    font-weight: 600 !important;
			}

			.assign-modal-workout-cell-content-col p {
			    font-size: 13.5px;
			    color: #6C6C6C;
			    margin: 0px;
			    font-weight: 400;
			}
			.assign-modal-workout-cell-content-col h4 {
			    font-size: 13.5px;
			    color: #ffffff;
			    margin: 0px;
			    font-weight: 400;
			    overflow: hidden;
			    height: 18px;
			}

		.assign-modal-workout-cell-content-col-arrow {
			width: 22px;
			height: 22px;
			background: ;
		}
		.assign-modal-workout-cell-content-col-arrow {
			width: 22px;
			height: 22px;
			border-radius: 5px;
			padding: 1px 7px;
			position: absolute;
			right: 0px;
			top: -3px;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
		.assign-modal-workout-cell-content-col-arrow:hover {
			background: rgba(0,0,0,0.2);
			cursor: pointer;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}	
		.assign-modal-workout-cell:hover .assign-modal-workout-cell-content-col-arrow {
			background: rgba(0,0,0,0.2);
			cursor: pointer;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
			.assign-modal-workout-cell-content-col-arrow-icon {
				font-size: 10px !important;
				color: #6C6C6C;
			}
			.assign-modal-workout-cell-content-col-arrow:hover .assign-modal-workout-cell-content-col-arrow-icon {
				color: #ffffff;
			}
			.assign-modal-workout-cell:hover .assign-modal-workout-cell-content-col-arrow-icon {
				color: #ffffff;
			}



