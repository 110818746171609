/* .pair-nodes-modal- */

.pair-nodes-modal {
	/*height: 60vh;*/
	height: 450px;
	margin-top: 16vh;
	width: 50vw;
}
	.pair-nodes-modal-content-container {
		width: 100%;
		height: 100%;
		padding: 24px;
	}
		.pair-nodes-modal-floating-close {
			white-space: nowrap;
			right: 32px;
		}
			.pair-nodes-modal-floating-close p {
			    font-size: 13.5px;
			    color: #7278F2;
			    margin: 0px;
			}
		.pair-nodes-modal-header {
			width: 100%;
			padding: 24px 0px;
		}
			.pair-nodes-modal-header h2 {
			    font-size: 18px;
			    color: #ffffff;
			    margin: 0px;
			    width: 100%;
			    text-align: center;
			    margin-bottom: 6px;
			}
			.pair-nodes-modal-header p {
			    font-size: 13.5px;
			    color: #9DA0AD;
			    margin: 0px;
			    text-align: center;
			    width: 60%;
			    margin: 0px auto;
			}
			.pair-nodes-modal-header span {
				color: #5B62FF;
				font-weight: 500;
			}

		.pair-nodes-modal-body {
			width: 100%;
			padding-bottom: 24px;
		}
			.pair-nodes-modal-body-image-container {
				width: 80%;
				margin: 0px auto;
				padding-top: 12px;
			}
				.pair-nodes-modal-body-image {
					width: 100%;
				}

			.pair-nodes-modal-body-button {
				background: #5B62FF;
				border-radius: 5px;
				padding: 8px 12px;
				box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.12);
				float: left;
			}
			.pair-nodes-modal-body-button:hover {
				cursor: pointer;
				background: #7278F2;
			}
				.pair-nodes-modal-body-button p {
				    font-size: 13.5px;
				    color: white;
				    margin: 0px;
				    width: 100%;
				    text-align: center;
				}
			.pair-nodes-modal-body-button-search {
				width: 50%;
				margin: 0px auto;
				float: none;
				margin-top: 48px;
			}

		.pair-nodes-modal-header-loading-circles-container {
			display: flex;
			width: 80px;
			margin: 12px auto;
		}
			.pair-nodes-modal-header-loading-circle {
				width: 8px;
				height: 8px;
				border-radius: 4px;
				margin: 0px 4px;
				border: solid;
				border-width: 1.5px;
				border-color: #6C6C6C;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
				.pair-nodes-modal-header-loading-circle-highlighted {
					border-color: #5B62FF;
				}
		.pair-nodes-modal-body {
			width: 100%;
			height: 308px;
		}
			.pair-nodes-modal-body-nodes-list-header-container {
				width: 100%;
				display: flex;
				padding-bottom: 8px;
			}
				.pair-nodes-modal-body-nodes-list-header-container h4 {
				    font-size: 13.5px;
				    color: #9DA0AD;
				    margin: 0px;
				    width: 50%;
				}
				.pair-nodes-modal-body-nodes-list-header-container p {
				    font-size: 13.5px;
				    color: #6C6C6C;
				    margin: 0px;
				    width: 50%;
				    text-align: right;
				}
			.pair-nodes-modal-body-nodes-list-container {
				width: 100%;
				height: 298px;
				overflow-y: scroll;
			}
				.pair-nodes-modal-body-nodes-list-cell-container {
					width: 100%;
				}

					.pair-nodes-modal-body-nodes-list-cell-header-container {
						width: 100%;
						padding: 8px 12px;
						background: #1F1F21;
						border-radius: 5px;
						display: flex;
					}
						.pair-nodes-modal-body-nodes-list-cell-header-bt-circle {
							width: 24px;
							height: 24px;
							border-radius: 12px;
							background: rgba(91, 98, 255, 0.2);
							padding: 4px 4px;
							margin-right: 12px;
						}
							.pair-nodes-modal-body-nodes-list-cell-header-bt-circle-icon {
								font-size: 16px !important;
								color: #5B62FF;
							}
						.pair-nodes-modal-body-nodes-list-cell-header-name {
							padding: 5px 0px;
						}
							.pair-nodes-modal-body-nodes-list-cell-header-name h4 {
								font-size: 13.5px;
								color: #ffffff;
								margin: 0px;
							}
						.pair-nodes-modal-body-nodes-list-cell-header-count {
							margin-left: auto;
							float: right;
							padding: 4px 4px;
						}
							.pair-nodes-modal-body-nodes-list-cell-header-count p {
							    font-size: 13.5px;
							    color: #9DA0AD;
							    margin: 0px;
							}
						.pair-nodes-modal-body-nodes-list-cell-header-pair-state-container {
							margin-left: auto;
							float: right;
							padding: 4px 4px;
							position: relative;
						}
							.pair-nodes-modal-body-nodes-list-cell-header-button {
								position: absolute;
								top: -2px;
								right: -4px;
								background: #5B62FF;
								border-radius: 5px;
								padding: 6px 12px;
							}
								.pair-nodes-modal-body-nodes-list-cell-header-button p {
								    font-size: 13.5px;
								    color: white;
								    white-space: nowrap;
								    margin: 0px;
								}
							.pair-nodes-modal-body-nodes-list-cell-header-pairing-container {
								display: flex;
							}
								.pair-nodes-modal-body-nodes-list-cell-header-pairing-container p {
								    font-size: 13.5px;
								    color: #7278F2;
								    margin: 0px;
								    margin-left: 8px;
								    padding-top: 1px;
								    padding-right: 4px;
								}
								.pair-nodes-modal-body-nodes-list-cell-header-pairing-spinner {
						            margin: 0px auto;
						            width: 18px;
						            height: 18px;
						            border: 2px solid rgba(0,0,0,0); /* Light grey */
						            border-top: 2px solid #7278F2;
						            border-right: 2px solid #7278F2;
						            border-bottom: 2px solid #7278F2;
						            border-radius: 9px;
						            animation: spin 1s linear infinite;
						        }
						            @keyframes spin {
						                0% { transform: rotate(0deg); }
						                100% { transform: rotate(360deg); }
						            }
						        .pair-nodes-modal-body-nodes-list-cell-header-pairing-check {
						        	font-size: 16px !important;
						        	color: #7278F2;
						        	margin-top: 1.5px;
						        }

						.pair-nodes-modal-body-nodes-list-cell-body-container {
							width: 100%;
							padding: 12px 4px;
							padding-left: 18px;
						}
							.pair-nodes-modal-body-nodes-list-cell-body-cell-container {
								width: 100%;
								display: flex;
								padding: 4px 4px;
								padding-left: 0px;
							}
								.pair-nodes-modal-body-nodes-list-cell-body-cell-circle {
									width: 10px;
									height: 10px;
									border-radius: 5px;
									background: #5B62FF;
									margin-right: 20px;
								}
								.pair-nodes-modal-body-nodes-list-cell-body-cell-name {

								}
									.pair-nodes-modal-body-nodes-list-cell-body-cell-name h4 {
									    font-size: 13.5px;
									    color: #ffffff;
									    margin: 0px;
									}
								.pair-nodes-modal-body-nodes-list-cell-body-cell-id {
									margin-left: auto;
									float: right;
									
								}
									.pair-nodes-modal-body-nodes-list-cell-body-cell-id p {
										font-size: 12px;
									    color: #6C6C6C;
									    margin: 0px;
									    margin-top: -1px;
									}









