
.goal-card {
	width: 100%;
	height: 400px;
	background: #101012;
	border: solid;
	border-width: 1px;
	border-color: #202020;
	border-radius: 16px;
	position: relative;
	overflow: hidden;
}
	.goal-card-header {
		padding: 16px 16px;
		display: flex;
		align-items: center;
		z-index: 1;
		position: relative;
		border-bottom: solid;
		border-width: 1px;
		border-color: #202020;
	}
		.goal-card-header-icon-container {
			background: #fff;
			width: 32px;
			height: 32px;
			border-radius: 6px;
			align-items: center;
			justify-content: center;
			padding: 6px 6px;
		}
			.goal-card-header-icon {
				color: black;
				font-size: 18px !important;
			}
		.goal-card-header h4 {
		    font-size: 14px;
		    color: #ffffff;
		    margin: 0px;
		    margin-left: 12px;
		}
		.goal-card-header-more-button {
			padding: 4px 8px;
			border-radius: 5px;
			margin-left: auto;
			float: right;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
		.goal-card-header-more-button:hover {
			background: #7278F230;
			cursor: pointer;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
			.goal-card-header-more-button p {
			    font-size: 13.5px;
			    color: #7278F2;
			    margin: 0px;
			}

	.goal-card-body-container {
		width: 100%;
		height: calc(100% - 110px);
		padding: 16px;
		z-index: 1;
		position: relative;
	}
	.goal-card-body-content-contianer {
		width: 100%;
		position: relative;
		z-index: 1;
	}
		.goal-card-body-status-container {
			width: 100%;
		}
			.goal-card-body-status-container h1 {
			    font-size: 40px;
			    font-weight: 200;
			    color: #ffffff;
			    margin: 0px;
			}
			.goal-card-body-status-container h2 {
			    font-size: 21px;
			    font-weight: 200;
			    color: #7278F2;
			    margin: 0px;
			}
		.goal-card-body-status-info-container {
			width: 40%;
			min-width: 200px;
			padding: 24px 0px;
		}
			.goal-card-body-status-info-container h4 {
			    font-size: 18px;
			    color: #6C6C6C;
			    margin: 0px;
			    font-weight: 300;
			}
			.goal-card-body-status-info-container span {
				color: #ffffff;
			}
			.goal-card-body-status-info-container p {
				font-size: 13.5px;
			    color: #6C6C6C;
			    margin: 0px;
			    font-weight: 300;
			    margin-top: 32px;
			}
	.goal-card-chart-container {
		width: 50%;
		min-width: 360px;
		height: 100%;
		position: absolute;
		right: 0;
		bottom: 0;
		z-index: 0;
		padding-right: 22px;
	}
	.goal-card-selector-container {
		width: 100%;
		display: flex;
		padding: 16px;
		z-index: 1;
		position: relative;
	}
		.goal-card-selector-demo {
			width: 36px;
			border-radius: 2px;
			height: 4px;
			background: #6C6C6C;
		}

	.goal-card-bg-container {
		width: 100%;
		height: 100%;
		border-radius: 16px;
		position: absolute;
		z-index: 0;
		top: 0px;
		left: 0px;
	}