.filter-single-input-container {
	position: relative;
	padding-top: 0px;
	padding-bottom: 0px;
	/*width: 106px;*/
}

.filter-single-input-container p {
	margin: 0px;
}

.filter-single-text {
	width: calc(100% - 30px);
	border-right: solid;
	border-width: 0.5px;
	border-color: #2B2B30;
	margin-right: 6px;
	white-space: nowrap;
}

	.filter-single-text h4 {
		font-size: 13.5px;
		color: #ffffff;
		margin: 0px;
		margin-left: 6px;
		margin-right: 6px;
		margin-top: -1px;

	}

.filter-single-menu-count {
	width: 16px;
	height: 16px;
	border-radius: 2px;
}
	.filter-single-menu-count p {
		font-size: 10px;
		margin: 0px;
		font-weight: 500;
	}
	.filter-single-menu-count-unhighlighted {
		background: rgba(190,210,255,0.1);
		padding: 1px 0px;
	}
		.filter-single-menu-count-unhighlighted p {
			color: #6C6C6C;
			width: 100%;
			text-align: center;
		}
	.filter-single-menu-count-highlighted {
		background: rgba(73,89,193,0.2);
		padding: 1px 0px;
	}
		.filter-single-menu-count-highlighted p {
			color: #5C6BC9;
			width: 100%;
			text-align: center;
		}

.filter-single-text-input-container {
	background-color: #1F1F21;
	padding: 7px 12px;
	padding-top: 10px;
	padding-bottom: 8px;
	border-radius: 5px;
	border: solid;
	border-color: #2B2B30;
	border-width: 0.5px !important;
	display: flex;
	color: #ffffff;
	font-size: 13.5px;
	box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;	
}
	.filter-single-text-input-container:hover {
		background: #1F1F21;
		cursor: pointer;
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;	
	}

.filter-single-text-input-container-alert {
	background-color: rgba(0,0,0,0.1);
	padding: 18px 24px;
	padding-bottom: 12px;
	width: auto;
	border-radius: 4px;
	border: solid;
	border-color: #F02E4A;
	border-width: 1px !important;
	display: flex;
}

.filter-single-text-input-container-search {
	background-color: #2E3A48;
	padding: 0px 12px;
	width: auto;
	border-radius: 5px;
	margin: 8px 0px;
	
}

.filter-single-text-input-label {
	font-size: 14px;
	color: #C1C4CC;
	position: absolute;
	top: 0px;
	left: 0px;
}

.filter-single-menu-container {
	right: 0px;
	z-index: 350;
	top: 42px;
	position: absolute;
	background-color: #1F1F21;
	box-shadow: 0 4px 32px 2px rgba(0, 0, 0, 0.14);
	width: 220px;
	border-radius: 7px;
	padding-bottom: 5px;
	border: solid;
	border-width: 0.5px;
	border-color: #2B2B30;
	
}
	.filter-single-menu-header-container {
		width: 100%;
		padding: 8px 12px;
		display: flex;
		border-bottom: solid;
		border-width: 1px;
		border-color: #2B2B30;
	}
	.filter-single-menu-header-container h4 {
		font-size: 13.5px;
		color: #ffffff;
		padding-top: 5px;
		margin: 0px auto;

	}
		.filter-single-menu-header-button {
			padding: 6px 10px;
			border: solid;
			border-radius: 5px;
			border-width: 0.5px;
			border-color: #2B2B30;
			background: #1F1F21;
			height: 28px;
			box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
		}
		.filter-single-menu-header-button:hover {
			cursor: pointer;
		}
			.filter-single-menu-header-button p {
				font-size: 10px;
				font-weight: 500;
				color: #ffffff;
				margin: 0px;
			}
			.filter-single-menu-header-button-dark {
				background: #1F1F21;
				/*margin-right: 30px;*/
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
			.filter-single-menu-header-button-dark:hover {
				background: rgba(35,40,54,0.4);
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
			.filter-single-menu-header-button-blue {
				padding-top: 7px;
				border: none;
				background: #4959C1;
				/*margin-left: 32px;*/
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
			.filter-single-menu-header-button-blue:hover {
				background: #5C6BC9;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}

	.filter-single-menu-body-container {
		width: 100%;
		max-height: calc(50vh - 200px);
		min-height: 60px;
		overflow-y: scroll;
		padding: 0px;
	}

.filter-single-menu-icon-container {
	float: right;
	padding-top: 0px;
	color: #ffffff;
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;	
}
.filter-single-menu-icon-container:hover {
	color: #9DA0AD;
	cursor: pointer;
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;	
}
	.filter-single-menu-icon {
		
		font-size: 13.5px !important;
	}


.filter-single-menu-option {
	color: #9DA0AD;
	font-weight: 500;
	font-size: 13.5px !important;
	padding: 8px 16px;
	display: flex;
	border-bottom: solid;
	border-width: 0.5px;
	border-color: #2B2B30;
}
.filter-single-menu-option:hover {
	color: #ffffff;
	cursor: pointer;
	background: rgba(35,40,54,0.4);
}

.filter-single-menu-option-selected {
	color: white;
	font-size: 13.5px !important;
	font-weight: 500;
	padding: 8px 16px;
	color: white;
	cursor: default;
	display: flex;
	border-bottom: solid;
	border-width: 0.5px;
	border-color: #2B2B30;
}
	.filter-single-menu-option-box {
		width: 14px;
		height: 14px;
		margin-top: 1px; 
		margin-right: 6px;
		background: rgba(35,40,54,0.4);
		border: solid;
		border-color: #2B2B30;
		border-width: 0.5px;
		border-radius: 5px;
	}

	.filter-single-menu-option-box-selected {
		border-color: #4959C1;
		background: #4959C1;
	}



