.summary-client-header {
	width: 100%;
	height: 60px;
	position: relative;
}

.summary-client-header h4 {
	font-size: 28px;
	color: #C1C4CC;
	margin: 0px;
}

.summary-client-header p {
	font-size: 14px;
	color: rgba(255,255,255,0.8);
	margin: 0px;
}

.summary-done-button {
	position: absolute;
	top: -4px;
	right: 0px;
	width: 140px;
	background-color: #4959C1;
	border-radius: 4px;
	color: rgba(255,255,255,0.9);
	padding: 12px 12px;
	font-size: 14px;
	text-align: center;
}

.summary-content-container {
	width: 100%;
	height: calc(100% - 38px);
	overflow-y: scroll;
	padding-bottom: 32px;
}