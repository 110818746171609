/* .trainers-list-cell- */

.trainers-list-cell {
	width: 100%;
	padding: 0px 36px;
	border-bottom: solid;
	border-width: 1px;
	border-color: #2B2B30;
	display: flex;
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
}
.trainers-list-cell:hover {
	background: #1F1F21;
	cursor: pointer;
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
}
	.trainers-list-cell-col {
		width: 20%;
		position: relative;
		padding: 12px 0px;
	}
		.trainers-list-cell-col h4 {
			font-size: 13.5px;
			color: #ffffff;
			margin: 0px;
		}
	.trainers-list-cell-col-header {
		width: 30%;
	}
		.trainers-list-cell-col-header h4 {
			
		}
	.trainers-list-cell-col-md {
		width: 35%;
		overflow-x: hidden;
		overflow-y: hidden;
		white-space: nowrap;
		padding-left: 12px;
	}
		.trainers-list-cell-col-md h4 {
			font-weight: 400;
			color: #9DA0AD;
		}
	.trainers-list-cell-col-header-unverified {

	}
		.trainers-list-cell-col-header-unverified h4 {
			font-weight: 400;
			color: #9DA0AD;
		}

	.trainers-list-cell-col-resend-button {
		background: #1F1F21;
		border: solid;
		border-width: 1px;
		border-color: #2B2B30;
		border-radius: 5px;
		padding: 4px 12px;
		box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.12);
		float: left;
		position: absolute;
		top: 7px;
		right: 0px;
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}
	.trainers-list-cell-col-resend-button:hover {
		background: #1F1F21;
		cursor: pointer;
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}
		.trainers-list-cell-col-resend-button p {
		    font-size: 13.5px;
		    color: #9DA0AD;
		    margin: 0px;
		}
	.trainers-list-cell-col-resend-button-sent {
		background: #49C16B;
		border-color: transparent;
	}
	.trainers-list-cell-col-resend-button-sent:hover {
		background: #49C16B;
		cursor: default;
		border-color: transparent;
	}
		.trainers-list-cell-col-resend-button-sent p {
			color: white;
		}



