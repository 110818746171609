/* .intra-workout-overview-summary-stats */

.intra-workout-overview-summary-stats {
	width: 100%;
	display: flex;
	padding-bottom: 8px;
}
	.intra-workout-overview-summary-stats-input-container-outter {
		padding-right: 12px;
	}
	.intra-workout-overview-summary-stats-input-container-outter-sm {
		width: 20%;
	}
	.intra-workout-overview-summary-stats-input-container-outter-md {
		width: 25%;
	}
	.intra-workout-overview-summary-stats-input-container-outter-lg {
		width: 30%;
	}
		.intra-workout-overview-summary-stats-input-header {
			width: 100%;
			margin-bottom: 4px;
		}
			.intra-workout-overview-summary-stats-input-header p {
			    font-size: 10px;
			    color: #9DA0AD;
			    margin: 0px;
			}
		.intra-workout-overview-summary-stats-input-container {
			width: 100%;
			height: 56px;
			background: rgba(0,0,0,0.2);
			border-radius: 5px;
			border: solid;
			border-width: 1px;
			border-color: #2B2B30;
			display: flex;
			position: relative;
			padding-right: 6px;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
		.intra-workout-overview-summary-stats-input-container-focused {
			border-color: #5B62FF;
			background: rgba(0,0,0,0.3);
			box-shadow: 0px 0px 24px 12px rgba(0,0,0,0.13);
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}

			.intra-workout-overview-summary-stats-input-units-container {
				position: absolute;
				bottom: 12px;
				right: 12px;
			}
				.intra-workout-overview-summary-stats-input {
					font-size: 28px;
					text-align: center;
					width: calc(100% - 12px);
				}
				.intra-workout-overview-summary-stats-input-units-container p {
				    font-size: 10px;
				    color: #9DA0AD;
				    margin: 0px;
				}

			.intra-workout-overview-summary-stats-x-icon-container {
				width: 30px;
				margin-left: -8px;
				padding-top: 32px;
			}
				.intra-workout-overview-summary-stats-x-icon {
					font-size: 16px;
					color: #9DA0AD;
				}


		
			.intra-workout-overview-summary-stats-input-container .form-tempo-input {
				width: calc(100% + 6px);
				min-width: calc(100% + 6px);
				height: 100%;
			}
			.intra-workout-overview-summary-stats-input-container .form-tempo-input-container-no-border {
				width: 100%;
				height: 100%;
			}

			.intra-workout-overview-summary-stats-input-container .form-tempo-input-container {
				background: transparent;
			}
			.intra-workout-overview-summary-stats-input-container .form-tempo-input-text-show-container h5 {
				font-size: 28px !important;
			}
			.intra-workout-overview-summary-stats-input-container .form-tempo-input-text-show-container span {
				font-size: 18px !important;
				padding: 0px 8px;
			}
			.intra-workout-overview-summary-stats-input-container .form-tempo-input-text-input-container {
				height: 100%;
				
			}
			.intra-workout-overview-summary-stats-input-container .form-tempo-input-text-input-container ion-input {
				height: 100%;
			}











