/* .date-range-selector- */

.date-range-selector {
	position: relative;
}
	.date-range-selector-input-container {
		width: 100%;
		display: flex;
		padding: 9px 12px;
		background: rgba(0,0,0,0);
		border-radius: 5px;
		border: solid;
		border-width: 1px;
		border-color: #2B2B30;
		box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.15);
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}
		.date-range-selector-input-container-selected {
			border-color: #5B62FF;
			background: rgba(0,0,0,0.1);
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
	.date-range-selector-input-container:hover {
		cursor: pointer;
		background: rgba(0,0,0,0.1);
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}
		.date-range-selector-input-container h4 {
			font-size: 13.5px;
			margin: 0px;
			color: #ffffff;
		}

		.date-range-selector-input-container-date-icon-container {
			margin-right: 8px;
		}
			.date-range-selector-input-container-date-icon {
				font-size: 14px !important;
				color: #6C6C6C;
			}

	.date-range-selector-calendar {
		position: absolute;
		top: 42px;
		left: 0px;
		width: 500px;
		z-index: 400;
		/*height: 300px;*/
		background: #1F1F21;
		border-radius: 5px;
		border: solid;
		border-width: 0.5px;
		border-color: #2B2B30;
		box-shadow: 0 2px 24px 4px rgba(0, 0, 0, 0.1);
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}
	.date-range-selector-calendar-left {
		left: -180px;
	}
	.date-range-selector-calendar-above {
		top: auto;
		bottom: 0px;
		left: auto;
		right: 0px;
	}
		.date-range-selector-calendar-footer {
			width: 100%;
			padding: 12px 16px;
			border-top: solid;
			border-width: 1px;
			border-color: #2B2B30;
			display: flex;
		}
			.date-range-selector-calendar-footer-buttons-container {
				display: flex;
				margin-left: auto;
				float: right;
			}
				.date-range-selector-calendar-footer-button {
					margin-left: 12px;
					padding: 7px 12px;
					border-radius: 5px;
					height: 32px;
				}
					.date-range-selector-calendar-footer-button p {
					    font-size: 13.5px;
					    color: #9DA0AD;
					    margin: 0px;
					}
					.date-range-selector-calendar-footer-button-dark {
						background: #1F1F21;
						border: solid;
						border-width: 1px;
						border-color: #2B2B30;
						box-shadow: 0px 0px 12px 6px rgba(0, 0, 0, 0.08);
						padding-top: 6px;
					}
					.date-range-selector-calendar-footer-button-dark:hover {
						cursor: pointer;
					}
						.date-range-selector-calendar-footer-button-dark p {
							color: #9DA0AD;
							-webkit-transition: all 0.15s;
							-moz-transition: all 0.15s;
							transition: all 0.15s;
						}
						.date-range-selector-calendar-footer-button-dark:hover p {
							color: #ffffff;
							-webkit-transition: all 0.15s;
							-moz-transition: all 0.15s;
							transition: all 0.15s;
						}
					.date-range-selector-calendar-footer-button-blue {
						background: #5B62FF;
						box-shadow: 0px 0px 12px 6px rgba(0, 0, 9, 0.12);
						float: right;
						height: 30px;
						-webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}
					.date-range-selector-calendar-footer-button-blue:hover {
						cursor: pointer;
						background: #7278F2;
						-webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}
						.date-range-selector-calendar-footer-button-blue p {
							color: white;
						}
					.date-range-selector-calendar-footer-button-blue-unselected {
						opacity: 0.3 !important;
					}
		.date-range-selector-calendar-header {
			display: flex;
			padding: 12px 16px;
			border-bottom: solid;
			border-width: 1px;
			border-color: #2B2B30;
		}
			.date-range-selector-calendar-header-text-container {
				display: flex;
				padding-top: 8px;
			}
				.date-range-selector-calendar-header-text-container h4 {
					font-size: 13.5px;
					color: #ffffff;
					margin: 0px;
					margin-right: 4px;
				}
				.date-range-selector-calendar-header-text-container p {
					font-size: 13.5px;
					color: #ffffff;
					margin: 0px;
				}
			.date-range-selector-calendar-header-close-icon-container {
				width: 20px;
				height: 20px;
				border-radius: 5px;
				padding: 2px 4px;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
			.date-range-selector-calendar-header-close-icon-container:hover {
				background: rgba(0,0,0,0.2);
				cursor: pointer;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			} 
				.date-range-selector-calendar-header-close-icon {
					font-size: 13.5px !important;
					color: #6C6C6C;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
				.date-range-selector-calendar-header-close-icon-container:hover .date-range-selector-calendar-header-close-icon {
					color: #ffffff;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}

			.date-range-selector-calendar-body {
				padding-top: 8px;
				padding-bottom: 4px;
			}
				.date-range-selector-calendar-month-row {
					display: flex;
					padding: 8px 16px;
				}
					.date-range-selector-calendar-month-row-text-container {
						width: calc(50% - 22px);
					}
						.date-range-selector-calendar-month-row-text-container h4 {
							font-size: 10px;
							color: #ffffff;
							margin: 0px;
							margin-top: 6px;
							width: 100%;
							text-align: center;
						}
					.date-range-selector-calendar-month-row-buttons-container {
						display: flex;
						margin-left: auto;
						float: right;
					}
						.date-range-selector-calendar-month-row-button {
							background: #1F1F21;
							padding: 0px 6px;
							height: 22px;
							width: 22px;
							box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
							border-radius: 5px;
							margin: auto 0px;
							border: solid;
							border-width: 0.5px;
							border-color: #2B2B30;
							-webkit-transition: all 0.15s;
							-moz-transition: all 0.15s;
							transition: all 0.15s;
						}
						.date-range-selector-calendar-month-row-button:hover {
							background: #28282D;
							cursor: pointer;
							-webkit-transition: all 0.15s;
							-moz-transition: all 0.15s;
							transition: all 0.15s;
						}
							.date-range-selector-calendar-month-row-button-icon {
								font-size: 10px !important;
								color: #9DA0AD;
							}
				.date-range-selector-calendar-weekday-row-outter {
					width: 100%;
					display: flex;
				}
					.date-range-selector-calendar-weekday-row {
						width: 100%;
						display: flex;
						padding: 0px 12px;
						padding-top: 8px;
					}
						.date-range-selector-calendar-weekday-container {
							width: 14.28%;
						}
							.date-range-selector-calendar-weekday-container p {
								width: 100%;
								text-align: center;
								font-size: 10px;
								color: #6C6C6C;
								margin: 0px;
							}
				.date-range-selector-calendar-days-container-outter {
					width: 100%;
					display: flex;
				}
					.date-range-selector-calendar-days-container {
						width: 100%;
						display: inline-block;
						padding: 0px 12px;
						padding-bottom: 12px;
					}
						.date-range-selector-calendar-day-content {
							min-width: 14.28%;
							display: inline-block;
							padding: 3.5px 0px;
							height: 26px;
							border-radius: 5px;
							-webkit-transition: all 0.15s;
							-moz-transition: all 0.15s;
							transition: all 0.15s;
						}
						.date-range-selector-calendar-day-content:hover {
							background: rgba(73,89,193,0.7);
							cursor: pointer;
							-webkit-transition: all 0.15s;
							-moz-transition: all 0.15s;
							transition: all 0.15s;
						}
						.date-range-selector-calendar-day-content-invisible {
							background: transparent !important;
						}
						.date-range-selector-calendar-day-content-invisible:hover {
							background: rgba(0,0,0,0) !important;
							cursor: default !important;
						}
							.date-range-selector-calendar-day-content-inner {
								width: 100%;
							}
							.date-range-selector-calendar-day-content-inner p {
								width: 100%;
								text-align: center;
								font-size: 13.5px;
								color: #ffffff;
								margin: 0px;
							}
							.date-range-selector-calendar-day-content-selected {
								background: #5B62FF;
								box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
							}
							.date-range-selector-calendar-day-content-selected:hover {
								background: #7278F2;
							}
							.date-range-selector-calendar-day-content-selected p {
								color: white !important;
							}
							.date-range-selector-calendar-day-content-selected-minor {
								background: rgba(73,89,193,0.7);
								border-radius: 0px;
							}

							.date-range-selector-calendar-day-content-no-select {
								opacity: 0.3;
							}
							.date-range-selector-calendar-day-content-no-select:hover {
								cursor: not-allowed;
								background: transparent !important;

							}
								.date-range-selector-calendar-day-content-no-select p {

								}







