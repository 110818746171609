/* .add-trainer-modal- */
.add-trainer-modal-container {
/*	height: 50%;
*/	
	min-height: 280px;
	/*height: 362px;*/
	margin: auto;
	margin-top: 0px;
	min-width: 400px;
	width: 67%;
	max-width: 740px;
	background: #161719;
	border-radius: 5px;
	border: solid;
	border-width: 0.5px;
	border-color: #2B2B30;
	/*overflow: hidden;*/
	box-shadow: 0 0px 100px 12px rgba(0, 0, 0, 0.5);
	position: relative;
	padding-bottom: 0px;
}

	.new-trainer-modal-body-container {
		width: 100%;

	}
		.new-trainer-modal-input-container-outter {
			display: flex;
			width: 100%;
			padding: 12px 36px;
		}
			.new-trainer-modal-input-container {
				width: 100%;
			}
				.add-trainer-modal-add-button {
					padding: 9px 12px;
					height: 35px;
					display: flex;
					font-size: 12px;
					border-radius: 5px;
					color: #ffffff !important;
					/*background: #1F1F21;*/
					border: solid;
					border-width: 0.5px;
					border-color: #2B2B30;
					box-shadow: 0 2px 12px 2px rgba(0, 0, 0, 0.1);
					white-space: nowrap;
					margin-top: 10px;
					margin-left: 12px;
					float: right;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
				.add-trainer-modal-add-button:hover {
					cursor: not-allowed;
				}
				.add-trainer-modal-add-button-active {
					background: #5B62FF;
					border-color: transparent;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
				.add-trainer-modal-add-button-active:hover {
					cursor: pointer;
					background: #7278F2;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
					.add-trainer-modal-add-button p {
						font-size: 12px;
						margin: 0px;
						color: #6C6C6C;
					}
					.add-trainer-modal-add-button-icon {
						font-size: 13.5px !important;
						color: #6C6C6C;
						margin-right: 4px;
					}
					.add-trainer-modal-add-button-active p {
						color: white;
					}
						.add-trainer-modal-add-button-active .add-trainer-modal-add-button-icon {
							color: white;
						}
			.new-trainer-modal-trainers-container {
				padding: 0px 36px;
				width: 100%;
				min-height: 50px;
				padding-bottom: 4px;
				/*height: 120px;
				max-height: 120px;
				overflow-y: scroll;*/
			}
				.new-trainer-modal-trainers-cell {
					width: 100%;
					display: flex;
					padding: 4px 12px;
					border: solid;
					border-width: 0.5px;
					border-color: #2B2B30;
					border-radius: 5px;
					margin-bottom: 8px;
				}
					.new-trainer-modal-trainers-cell-email-container {
						width: 40%;
						padding: 8px 0px;
					}
						.new-trainer-modal-trainers-cell-email-container h4 {
							font-size: 13.5px;
							color: #ffffff;
							margin: 0px;
							padding-top: 1px;
						}
				.new-trainer-modal-trainers-cell-permissions-container-outter {
					position: relative;
					width: 25%;
					min-width: 180px;
				}
					.new-trainer-modal-trainers-cell-permissions-container {
						/*width: 40%;*/
						padding: 8px 4px;
						padding-left: 12px;
						
						border-radius: 5px;
						display: flex;
						-webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}
					.new-trainer-modal-trainers-cell-permissions-container:hover {
						background: #1F1F21;
						cursor: pointer;
						-webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}
						.new-trainer-modal-trainers-cell-permissions-container h4 {
							font-size: 13.5px;
							color: #9DA0AD;
							margin: 0px;
							padding-top: 1px;
						}

						.new-trainer-modal-trainers-cell-permissions-container-blank:hover {
							background: transparent;
							cursor: default;
						}
							.new-trainer-modal-trainers-cell-permissions-container-blank h4 {
								color: #6C6C6C;
							}
						.new-trainer-modal-trainers-cell-permissions-arrow-icon-container {
							/*width: 20px;
							height: 20px;*/
							padding: 1px 8px;
							margin-left: auto;
							float: right;
						}
							.new-trainer-modal-trainers-cell-permissions-arrow-icon {
								font-size: 14px !important;
								color: #9DA0AD;
							}
					.new-trainer-modal-trainers-cell-close-container {
						width: 20px;
						height: 20px;
						
						float: right;
						margin-left: auto;
						border-radius: 5px;
						margin-top: 6px;
						padding: 1px 4px;
						-webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}
					.new-trainer-modal-trainers-cell-close-container:hover {
						background: #28282D;
						cursor: pointer;
						-webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}
						.new-trainer-modal-trainers-cell-close-icon {
							font-size: 12px !important;
							color: #6C6C6C;
						}
						.new-trainer-modal-trainers-cell-close-container:hover .new-trainer-modal-trainers-cell-close-icon {
							color: #ffffff;
							-webkit-transition: all 0.15s;
							-moz-transition: all 0.15s;
							transition: all 0.15s;
						}


					.new-trainer-modal-trainers-cell-permissions-menu-container {
						position: absolute;
						z-index: 500;
						padding: 0px;
						background: #1F1F21;
						border-radius: 7px;
						box-shadow: 0 0px 24px 6px rgba(0, 0, 0, 0.1);
						left: 0px;
						top: 40px;
						width: 340px;
						min-height: 200px;
					}
						.new-trainer-modal-trainers-cell-permissions-menu-header-container {
							width: 100%;
							padding: 16px;
							border-bottom: solid;
							border-width: 1px;
							border-color: #2B2B30;
						}
							.new-trainer-modal-trainers-cell-permissions-menu-header-container h4 {
								font-size: 13.5px;
								color: #ffffff;
								margin: 0px;
							}
						.new-trainer-modal-trainers-cell-permissions-menu-body-container {
							width: 100%;
							padding: 4px 12px;
						}
							.new-trainer-modal-trainers-cell-permissions-menu-row-container {
								padding: 12px 4px;
								width: 100%;
								border-bottom: solid;
								border-width: 0.5px;
								border-color: #2B2B30;
								-webkit-transition: all 0.15s;
								-moz-transition: all 0.15s;
								transition: all 0.15s;
							}
							.new-trainer-modal-trainers-cell-permissions-menu-row-container:hover {
								background: #1F1F21;
								border-radius: 5px;
								cursor: pointer;
								-webkit-transition: all 0.15s;
								-moz-transition: all 0.15s;
								transition: all 0.15s;
							}
								.new-trainer-modal-trainers-cell-permissions-menu-row-container h4 {
									font-size: 13.5px;
									color: #ffffff;
									margin: 0px;
								}
								.new-trainer-modal-trainers-cell-permissions-menu-row-container p {
									font-size: 12px;
									color: #9DA0AD;
									margin: 0px;
									margin-top: 4px;
								}
							.new-trainer-modal-trainers-cell-permissions-menu-row-container-selected {

							}
	.new-trainer-modal-body-container-loading {
		padding-top: 120px;
	}
		.new-trainer-modal-body-container-loading p {
			font-size: 13.5px;
			color: #6C6C6C;
			margin: 0px;
			margin-top: 8px;
			width: 100%;
			text-align: center;
		}
	.new-trainer-modal-body-container-done {
		padding-top: 90px;
	}
		.new-trainer-modal-body-container-done h4 {
			font-size: 18px;
			color: #ffffff;
			margin: 0px;
			margin-top: 0px;
			width: 100%;
			text-align: center;
		}
		.new-trainer-modal-body-container-done p {
			font-size: 13.5px;
			color: #6C6C6C;
			margin: 0px;
			margin-top: 6px;
			width: 100%;
			text-align: center;
		}

	.new-trainer-modal-trainers-header-container {
		width: 100%;
		display: flex;
		padding: 0px 36px;
		padding-bottom: 8px;
	}
		.new-trainer-modal-trainers-header-col {
			padding: 0px;
			padding-left: 12px;
		}
			.new-trainer-modal-trainers-header-col p {
			    font-size: 12px;
			    color: #6C6C6C;
			    margin: 0px;
			}


