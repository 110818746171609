.input-container {
	position: relative;
	overflow: show !important !important;
}

input[type=text]:focus {
  border: none;
}

.text-input-container {
	background-color: rgba(0,0,0,0.12);
	padding: 6px 12px;
	width: auto;
	border-radius: 5px;
	margin: 32px 0px;
	font-size: 13.5px;
	color: #ffffff;
	border-width: 0.5px !important;
	border-color: #2B2B30 !important;
	border: solid;
}

.text-input-container-search {
	background-color: #2E3A48;
	padding: 8px 12px;
	width: auto;
	border-radius: 5px;
	margin: 8px 0px;
}

.text-input-label {
	font-size: 10px !important;
	color: #606C77;
	position: absolute;
	top: -30px;
	left: 0px;
}