.add-exercise-to-workout-modal {
	width: 60%;
	min-width: 600px;
	overflow-y: hidden;
	position: relative;
}

.add-exercise-to-workout-modal-upper {
	width: 100%;
	display: flex;
	padding: 20px 32px;
	border-bottom: solid;
	border-width: 1px;
	border-color: #2B2B30;
	position: relative;
	z-index: 0;
}
	.add-exercise-to-workout-modal-upper-close-container {
		border-right: solid;
		border-width: 1px;
		border-color: #2B2B30;
		padding-right: 16px;
	}

	.add-exercise-to-workout-modal-upper-close-button {
			/*position: absolute;
			right: 0px;*/
			margin-top: 3px;
			width: 22px;
			height: 22px;
			border-radius: 5px;
			padding: 2.5px;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
		.add-exercise-to-workout-modal-upper-close-button:hover {
			background: rgba(0,0,0,0.2);
			cursor: pointer;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
			.add-exercise-to-workout-modal-upper-close-button-icon {
				color: #9DA0AD;
				font-size: 17px !important;
			}
			.add-exercise-to-workout-modal-upper-close-button:hover .add-exercise-to-workout-modal-upper-close-button-icon {
				color: #ffffff;
			}
	.add-exercise-to-workout-modal-upper-text-container {
		padding: 4px 16px;
	}
		.add-exercise-to-workout-modal-upper-text-container h4 {
		    font-size: 15px;
		    color: #ffffff;
		    margin: 0px;
		}

.add-exercise-to-workout-modal .create-workout-exercise-configure-section-container {
	padding-top: 24px;
}
	.add-exercise-to-workout-modal-body {
		height: calc(100% - 0px);
		max-height: calc(100% - 68px);
	}


	.add-exercise-to-workout-modal-body-exercise-config-container {
		position: relative;
		z-index: 800;
	}

	.add-exercise-to-workout-modal-upper-button-container {
		margin-left: auto;
		float: right;
		margin-right: 164px;
		position: relative;
		z-index: 900;
	}
		.add-exercise-to-workout-modal-help-button {
			position: absolute;
			top: -48px;
			right: 198px;
			background: #1F1F21;
			border: solid;
			border-width: 1px;
			border-color: #2B2B30;
			border-radius: 5px;
			box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.12);
			padding: 5.5px 6px;
			width: 28px;
			height: 28px;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
		.add-exercise-to-workout-modal-help-button:hover {
			background: #1F1F21;
			cursor: pointer;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
			.add-exercise-to-workout-modal-help-button-icon {
				font-size: 15px !important;
				color: #9DA0AD;
			}

		.add-exercise-to-workout-modal-help-menu {
			position: absolute;
			top: -16px;
			right: 198px;
			background: #1F1F21;
			border: solid;
			border-width: 1px;
			border-color: #2B2B30;
			border-radius: 5px;
			box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.12);
			width: 320px;
			z-index: 500;
			padding: 20px;
			padding-top: 24px;
			padding-bottom: 18px;
		}
			.add-exercise-to-workout-modal-help-menu-header {
				padding-bottom: 18px;
			}
				.add-exercise-to-workout-modal-help-menu-header h4 {
				    font-size: 18px;
				    color: #ffffff;
				    margin: 0px;
				}
			.add-exercise-to-workout-modal-help-menu-content {

			}
				.add-exercise-to-workout-modal-help-menu-content-row {
					padding: 4px 0px;
					display: flex;
				}
					.add-exercise-to-workout-modal-help-menu-content-row-text {
						padding-top: 4px;
					}
						.add-exercise-to-workout-modal-help-menu-content-row-text p {
						    font-size: 13.5px;
						    color: #9DA0AD;
						    margin: 0px;
						}
					.add-exercise-to-workout-modal-help-menu-content-row-letter-container {
						background: #1F1F21;
						border: solid;
						border-width: 1px;
						border-color: #2B2B30;
						box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.08);
						border-radius: 5px;
						height: 28px;
						padding: 4px 8px;
						margin-left: auto;
						float: right;
					}
						.add-exercise-to-workout-modal-help-menu-content-row-letter-container p {
						    font-size: 13.5px;
						    color: white;
						    margin: 0px;
						}



