/**/

.my-profile-card-preview {
	width: 360px;
	height: 600px;
	margin-top: 30px;
} 
	.my-profile-card-preview .my-coaching-profile-preview-phone-content {
		overflow-y: hidden;
	}

	.my-profile-card-preview .my-coaching-profile-preview-phone-content-section-header-studio-img-container {
		top: 24px;
	}

	.my-coaching-profile-preview-card-content-section-header-studio-accepting-clients-tag {
		top: 24px;
	}