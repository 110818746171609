.client-goal-progress-card-log-cell {
	width: 100%;
	display: flex;
	padding: 8px;
	border-bottom: solid;
	border-width: 0.5px;
	border-color: #2B2B30;
}
.client-goal-progress-card-log-cell:hover {
		background: #1F1F21;
		border-radius: 5px;
		cursor: pointer;
	}
	.client-goal-progress-card-log-cell-col {
	}
		.client-goal-progress-card-log-cell-col h4 {
			font-size: 13.5px;
			color: #ffffff;
			margin: 0px;
			font-weight: 400;
		}

	.client-goal-progress-card-log-cell-col-0 {
		width: 50%;
	}
	.client-goal-progress-card-log-cell-col-1 {
		width: 20%;
	}
	.client-goal-progress-card-log-cell-col-2 {
		width: 30%;
	}