.chat-preview-button {
    width: 100%;
    background: none;
    border: none;
    padding: 6px 0px;
    padding-left: 16px;
    cursor: pointer;
    user-select: none;
    height: 72px;
    border-radius: var(--ef-radius-m);
}
    .chat-preview-button:hover {
        background-color: var(--ef-button-card);
        cursor: pointer;
        -webkit-transition: all 0.15s;
        -moz-transition: all 0.15s;
        transition: all 0.15s;
    }

.chat-preview-button-selected {
    background-color: var(--ef-card);
    box-shadow: 0px 0px 12px 6px rgba(0, 0, 0, 0.08);
    -webkit-transition: all 0.15s;
    -moz-transition: all 0.15s;
    transition: all 0.15s;
}
    .chat-preview-button-selected:hover {
        background-color: var(--ef-button-card);
        cursor: pointer;
        -webkit-transition: all 0.15s;
        -moz-transition: all 0.15s;
        transition: all 0.15s;
    }

.chat-preview-container {
    /*outline: solid chartreuse 2px;*/
    width: 100%;
}

.chat-preview-items-container {
   
}

.chat-preview-profile-icon-username-container {
    /*outline: solid blue 2px;*/
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
}
    .chat-preview-profile-icon-unread-dot {
        position: absolute;
        left: -20px;
        height: 8px;
        width: 8px;
        border-radius: 4px;
        background: var(--ef-cta);
    }


.chat-preview-profile-icon-container {
    /*outline: solid beige 2px;*/
    position: relative;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: fit-content;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    overflow: hidden;
    background: #0B0C0F;
    box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.1);
}
    .chat-preview-profile-icon-image {
        width: 50px;
        height: 50px;
        border-radius: 25px;
    }
    .chat-preview-profile-icon-container h4 {
        font-size: var(--ef-font-size-l);
        color: var(--ef-text-mid);
        margin: 0px;
        width: 100%;
        text-align: center;
        padding-top: 17px;
    }

.chat-preview-profile-info-container {
    padding-left: 20px;
}

.chat-preview-username-container {
    
}
    .chat-preview-username-container p {
        font-size: 18px;
        color: #ffffff;
        margin: 0px;
        text-align: left;
    }

.chat-preview-timestamp-container{
    /*outline: solid wheat 1px;*/
    padding-top: 4px;
}

    .chat-preview-timestamp-container p {
        font-size: 12px;
        color: var(--ef-text-dark);
        margin: 0px;
        text-align: left;
    }
    .chat-preview-button-selected .chat-preview-timestamp-container p {
        color: var(--ef-text-white);
    }

.chat-preview-text-container {
    padding-left: 44px;
    padding-top: 6px;
}

    .chat-preview-text-container p {
        font-size: 14px;
        color: #9DA0AD;
        margin: 0px;
        text-align: left;
    }

.chat-preview-text-container-unread {
    padding-left: 44px;
    padding-top: 6px;
}

    .chat-preview-text-container-unread p {
         font-size: 14px;
        color: #ffffff;
        margin: 0px;
        text-align: left;
    }
