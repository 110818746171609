.node-playground {
	width: 100%;
}
	.node-playground .page-title-header-container {
		display: flex;
	}
		.node-playground-header-button-container {
			margin-left: auto;
			float: right;
		}
			.node-playground-header-button {
			    padding: 6px 16px;
			    background: #5B62FF;

			    display: flex;
			    border-radius: 5px;
			    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.12);
			    -webkit-transition: all 0.15s;
			    -moz-transition: all 0.15s;
			    transition: all 0.15s;
			}
			.node-playground-header-button:hover {
			    cursor: pointer;
			    -webkit-transition: all 0.15s;
			    -moz-transition: all 0.15s;
			    transition: all 0.15s;
			}
				.node-playground-header-button-stream {
					background: #FF4D4D;
				}
				.node-playground-header-button p {
				    font-size: 13.5px;
				    color: white;
				    margin: 0px;
				}
	.node-playground-content {
		width: 100%;
		padding: 0px 36px;
	}
		.node-playground-content-graph-container {
		    padding: 4px 8px;
		    background: #1F1F21;
		    border: solid;
		    border-width: 1px;
		    border-color: #2B2B30;
		    display: flex;
		    border-radius: 5px;
		    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.12);
		    -webkit-transition: all 0.15s;
		    -moz-transition: all 0.15s;
		    transition: all 0.15s;
		    height: 500px;
		}
			.node-playground-content-graph-container .current-exercise-modal-chart-graph-chart-no-data-container {
				height: 400px;
			}

