/* .create-plan-workout-configure-cell- */

.create-plan-workout-configure-cell {
	width: 100%;
	border-bottom: solid;
	border-width: 1px;
	border-color: #2B2B30;
}
.create-plan-workout-configure-cell-content{
	padding: 12px 0px;
	padding-left: 12px;
	width: 100%;
	display: flex;
	
}


.create-plan-workout-configure-cell:hover {
	/*background: #28282D;
	cursor: pointer;*/
}


		.create-plan-workout-configure-cell-info-card-content {
			display: flex;
			width: 100%;
		}

		.create-plan-workout-configure-cell-expand-container {
			width: 24px;
			height: 16px;
			margin-top: 4px;
		}
			.create-plan-workout-configure-cell-expand-icon-container {
				width: 16px;
				height: 100%;
				border-radius: 5px;
				padding: 1px 1.5px;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
			.create-plan-workout-configure-cell-expand-icon-container:hover {
				background: rgba(0,0,0,0.2);
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
				.create-plan-workout-configure-cell-expand-icon {
					font-size: 13.5px !important;
				}

				.create-plan-workout-configure-cell-expand-icon-contracted {
					transform: rotate(-90deg);
					color: #6C6C6C;
					-webkit-transition: all 0.35s;
					-moz-transition: all 0.35s;
					transition: all 0.35s;
				}
				.create-plan-workout-configure-cell-expand-icon-container:hover .create-plan-workout-configure-cell-expand-icon-contracted {
					transform: rotate(0deg);
					color: #9DA0AD;
					-webkit-transition: all 0.35s;
					-moz-transition: all 0.35s;
					transition: all 0.35s;
				}

			.create-plan-workout-configure-cell-image-container {
				/*background-color: rgba(0,0,0,0.3);
				width: 32px;
				height: 32px;
				max-width: 32px;
				max-height: 32px;
				border-radius: 5px;
				overflow: hidden;
				margin-right: 16px;
				padding: 0px;
				margin-top: 4px;*/
				display: none;
			}
				.create-plan-workout-configure-cell-image {
					height: 100% !important;
					width: 400px !important;
					border-radius: 4px !important;
				}
			.create-plan-workout-configure-cell-info-card-col-container {
				display: flex;
				width: 100%;
			}
				.create-plan-workout-configure-cell-info-card-col-container {
					display: flex;
					width: calc(100% - 50px);
				}
				.create-plan-workout-configure-cell-info-card-col {
					display: block;
					padding-right: 16px;
					width: 20%;
					overflow-x: hidden;
				}
			.create-plan-workout-configure-cell-info-card-info-container {
				padding-top: 4px;
				position: relative !important;
			}
			.create-plan-workout-configure-cell-info-card-info-container-title {
				width: 30%;
			}
				.create-plan-workout-configure-cell-info-card-info-container-title h4 {
					font-weight: 500 !important;
				}
				.create-plan-workout-configure-cell-info-card-info-container h4 {
					font-weight: 300;
					width: 100%;
					overflow: hidden;
					font-size: 13.5px;
					color: #ffffff;
					text-align: left;
					margin: 0px;
				}

				.create-plan-workout-configure-cell-info-card-info-container p {
					font-size: 12px;
					font-size: 10px;
					color: #9DA0AD;
					height: 16px;
					width: 100%;
					overflow: hidden;
					margin: 0px;
					display: none;
				}
					.create-plan-workout-configure-cell-info-card-info-container-no-workouts {

					}
					.create-plan-workout-configure-cell-info-card-info-container-no-workouts h4 {
						color: #6C6C6C !important;
					}
					.create-plan-workout-configure-cell-info-card-info-container-no-workouts p {
						color: #6C6C6C !important;
					}
						.create-plan-workout-configure-cell-info-card-info-container-no-workouts-alert {
							position: absolute;
							left: 55px;
							top: 6px;
							height: 12px;
							width: 12px;
							border-radius: 6px;
							background: rgba(255,23,23,0.3);
							padding-left: 4.5px;
							padding-top: 1px;
						}
							.create-plan-workout-configure-cell-info-card-info-container-no-workouts-alert p {
								color: #F02E4A !important;
								font-weight: 700;
								font-size: 8px;
							}

					.create-plan-workout-configure-cell-info-card-info-trained-with-me-container {
						display: flex;
					}
						.create-plan-workout-configure-cell-info-card-info-trained-with-me-icon {
							width: 10px;
							height: 10px;
							max-height: 10px;
							min-width: 10px;
							background: #4959C1;
							border: solid;
							border-color: #2B3158;
							border-width: 3px;
							border-radius: 5px;
							margin-top: 1px;
							margin-right: 6px;
						}

			.create-plan-workout-configure-cell-add-button {
				background: #1F1F21;
				border: solid;
				border-width: 0.5px;
				border-color: #2B2B30;
				border-radius: 5px;
				padding: 6px 12px;
				padding-left: 8px;
				display: flex;
				margin-right: 8px;
				color: #6C6C6C;
			}
			.create-plan-workout-configure-cell:hover .create-plan-workout-configure-cell-add-button {
				background: #4959C1;
				border-color: rgba(0,0,0,0);
				box-shadow: 0 2px 8px 2px rgba(0, 0, 0, 0.1);
				color: #ffffff !important;
				cursor: pointer;
			}
			.create-plan-workout-configure-cell-add-button:hover {
				background: #4959C1;
				border-color: rgba(0,0,0,0);
				box-shadow: 0 2px 8px 2px rgba(0, 0, 0, 0.1);
				color: #ffffff !important;
				cursor: pointer;
			}
				.create-plan-workout-configure-cell-add-button p {
					font-size: 10px;
					
					margin: 0px;
				}
				.create-plan-workout-configure-cell-add-button-icon {
					font-size: 10px !important;
					margin-top: 1px;
					margin-right: 4px;
				}


.create-plan-workout-configure-cell-muscle-string-container {
	margin: auto;
	margin-top: 20px;
	padding: 4px 12px;
	background-color: #242C45;
	border-radius: 4px;
	color: #4959C1;
	font-size: 12px;
	text-align: center;
	width: 140px;
	max-width: 100%;
	max-height: 24px;
	overflow: hidden;
}
	.create-plan-workout-configure-cell-expanded-content {
		border-top: solid;
		border-width: 1px;
		border-color: #2B2B30;
		width: calc(100% - 24px);
		margin-left: 12px;
		padding: 4px 24px;
	}
		.create-plan-workout-configure-cell-expanded-content-row {
			padding: 4px 0px;
		}
		.create-plan-workout-configure-cell-expanded-content-row h4 {
			font-size: 13.5px;
			color: #ffffff;
			margin: 0px;
		}


