/*   .create-workout-exercise-configure   */
:focus {
    outline: none !important;
}
.create-workout-exercise-configure-container {
	width: 100%;
	height: 100%;
	padding: 12px 0px;
	display: block;
}
	.create-workout-exercise-configure-header-container {
		padding: 0px 0px;
		padding-top: 10px;
		width: 100%;
	}

		.create-workout-exercise-configure-header-container h1 {
			font-size: 22px;
			color: #ffffff;
			margin: 0px;
		}
		.create-workout-exercise-configure-header-back-text {
			margin: 0px;
			margin-bottom: 4px;
			color: #6C6C6C;
			font-size: 13.5px;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
		.create-workout-exercise-configure-header-back-text:hover {
			cursor: pointer;
			color: #ffffff;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}

	.create-workout-exercise-configure-body-container {
		width: 100%;
	}
		.create-workout-exercise-configure-section-container {
			padding-top: 48px;
			width: 100%;
			position: relative;
		}
			.create-workout-exercise-configure-section-curtain {
				-webkit-backdrop-filter: blur(2px);
				backdrop-filter: blur(2px);
				background-color: #161719CC;
				opacity: 1.0;
				position: absolute;
				width: 100%;
				height: 800px;
				top: 0px;
				left: 0px;
				z-index: 100;

			}
			.create-workout-exercise-configure-section-header-container {
				display: flex;
				
			}
				.create-workout-exercise-configure-section-header-container h2 {
					font-size: 18px;
					color: #ffffff;
					margin: 0px;
					margin-right: 12px;
					cursor: pointer;
				}

				.create-workout-exercise-configure-section-header-container-small {

				}
					.create-workout-exercise-configure-section-header-container-small h2 {
						font-size: 13.5px;
						color: #ffffff;
						margin: 0px;
						margin-right: 12px;
						cursor: pointer;
						padding-top: 4px;
					}

			.create-workout-exercise-configure-section-header-exercise-duration-container {
				margin-left: auto;
				float: right;
				padding-top: 2px;
			}
				.create-workout-exercise-configure-section-header-exercise-duration-container p {
					font-size: 13.5px;
					margin: 0px;
					color: #9DA0AD;
				}
			.create-workout-exercise-configure-section-content {
				width: 100%;
			}
				.create-workout-exercise-configure-section-search-exercises {
					display: flex;
					position: relative;
				}
					.create-workout-exercise-configure-muscle-seletor-content {
						width: 105px;
						margin-right: 12px;
						margin-top: 10px;/* 22px;*/
					}

					.create-workout-exercise-configure-muscle-search-content {
						width: 100%;
						margin-right: 12px;
						padding-top: 0px;
						margin-bottom: 12px;
						-webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}
					.create-workout-exercise-configure-muscle-search-content-error {
						border-color: #F02E4A;
						color: #F02E4A;
					}
					.create-workout-exercise-configure-muscle-search-content-focus {
						border-color: #5437DC !important;
						box-shadow: 0 0px 24px 12px #5437DC12;
						-webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}

					.create-workout-exercise-configure-search-button {
						margin-top: 10px; /*22px;*/
						height: 36px;
						background-color: #5437DC;
						border-radius: 5px;
						color: white;
						padding: 9px;
						padding-left: 12px;
						padding-right: 12px;
						display: flex;
						box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
						-webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}
						.create-workout-exercise-configure-search-button p {
							font-size: 13.5px;
							color: white;
							margin: 0px;

							margin-left: 6px;
						}
					.create-workout-exercise-configure-search-button:hover {
						cursor: pointer;
						background: #7963E0;
						-webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}
					.create-workout-exercise-configure-search-icon {
						margin: auto;
						color: white !important;
						font-size: 13.5px !important;
					}
				.create-workout-exercise-configure-section-search-result-container {
					position: absolute;
					left: 0px;
					top: 56px;
					background: #1F1F21;
					border-radius: 5px;
					border: solid;
					border-width: 0.5px;
					border-color: #2B2B30;
					width: calc(100% - 216px);
					padding: 4px 0px;
					max-height: 300px;
					overflow-y: scroll;
					box-shadow: 0 2px 32px 2px rgba(5, 5, 23, 0.2);
					z-index: 300;
				}
					.create-workout-exercise-configure-section-search-result-content-loading {
						padding: 48px 0px;
					}
						.create-workout-exercise-configure-section-search-result-content-loading p {
							font-size: 13.5px;
							color: #6C6C6C;
							width: 100%;
							text-align: center;
							margin: 0px;
							padding-top: 8px;
						}
						.create-workout-exercise-configure-loading-spinner {
				            margin: 0px auto;
				            width: 24px;
				            height: 24px;
				            border: 3px solid rgba(0,0,0,0); /* Light grey */
				            border-top: 3px solid #5437DC;
				            border-right: 3px solid #5437DC;
				            border-bottom: 3px solid #5437DC;
				            border-radius: 12px;
				            animation: spin 1s linear infinite;
				        }
				            @keyframes spin {
				                0% { transform: rotate(0deg); }
				                100% { transform: rotate(360deg); }
				            }
				    .create-workout-exercise-configure-section-search-result-content-empty {
				    	width: 100%;
				    	padding-top: 24px;
				    }	
				    	.create-workout-exercise-configure-section-search-result-content-empty h4 {
							font-size: 13.5px;
							color: #ffffff;
							width: 90%;
							margin: 0px;
							margin-bottom: 8px;
							padding-left: 20px;
						}
				    	.create-workout-exercise-configure-section-search-result-content-empty p {
							font-size: 10px;
							color: #6C6C6C;
							width: 90%;
							margin: 0px;
							padding-left: 20px;
							line-height: 14px;
							padding-bottom: 16px;
						}
					.create-workout-exercise-configure-add-exercise-button {
						width: 100%;
						padding: 12px 20px;
						padding-bottom: 8px;
						position: relative;
						color: #ffffff;
						display: flex;
						background: rgba(0, 0, 0, 0.2);
					}

					.create-workout-exercise-configure-add-exercise-button p {
						width: 100%;
						text-align: center;
						margin: 0px;
						font-size: 10px;
					}
					.create-workout-exercise-configure-add-exercise-button h4 {
						
						margin-left: 8px;
						margin-top: 4px;
						font-size: 13.5px;
						font-weight: 500;
						padding-left: 0px !important;
					}
					.create-workout-exercise-configure-add-exercise-button:hover {
						background: rgba(0, 0, 0, 0.4);
						cursor: pointer;
					}
						.create-workout-exercise-configure-add-exercise-button-icon-container {
							width: 24px;
							height: 24px;
							background: rgba(73,188,193,0.2);
							border-radius: 5px;
							padding: 4px 4.5px;
						}
						.create-workout-exercise-configure-add-exercise-button-icon {
							font-size: 16px !important;
							color: #49BCC1;
						}
				.create-workout-exercise-configure-selected-exercise-card {
					width: 100%;
					display: flex;
					background: #1F1F21;
					border-radius: 5px;
					border: solid;
					border-width: 0.5px;
					border-color: #2B2B30;
					padding: 12px 12px;
					box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
					margin-top: 8px;
				}
					.create-workout-exercise-configure-selected-exercise-card-col {
						width: 100%;
						overflow: hidden;
					}
						.create-workout-exercise-configure-selected-exercise-card-col h4 {
							font-size: 13.5px;
							max-height: 15px;
							overflow: hidden;
							margin: 0px;
						}
						.create-workout-exercise-configure-selected-exercise-card-col-title {
							width: 40%;
						}
						.create-workout-exercise-configure-selected-exercise-card-col-muscles {
							width: calc(60% - 16px);
							font-weight: 100;
							color: #9DA0AD;
						}
						.create-workout-exercise-configure-selected-exercise-card-col-remove {
							width: 16px;
							height: 16px;
						}
						.create-workout-exercise-configure-selected-exercise-card-col-remove:hover {
							cursor: pointer;
						}
							.create-workout-exercise-configure-selected-exercise-card-col-remove-icon {
								font-size: 13.5px !important;
								color: #6C6C6C;
							}
							.create-workout-exercise-configure-selected-exercise-card-col-remove:hover .create-workout-exercise-configure-selected-exercise-card-col-remove-icon{
								color: #F02E4A;

							}

			.create-workout-exercise-configure-rest-select-loading-pattern-container {
				padding: 12px 0px;
				display: flex;
			}
				.create-workout-exercise-configure-rest-select-loading-pattern-card {
					width: calc(50% - 6px);
					margin-right: auto;
					display: flex;
					border: solid;
					border-width: 1px;
					border-color: #2B2B30;
					border-radius: 5px;
					padding: 12px;
					cursor: pointer;
				}
				.create-workout-exercise-configure-rest-select-loading-pattern-card .create-workout-exercise-configure-selector-container-selected {
					margin-top: 0px;
				}
				.create-workout-exercise-configure-rest-select-loading-pattern-card .create-workout-exercise-configure-selector-container-unselected {
					margin-top: 0px;
				}
					.create-workout-exercise-configure-rest-select-loading-pattern-card h4 {
						font-size: 13.5px;
						color: #6C6C6C;
						margin: 0px;
						margin-left: 8px;
					}
					.create-workout-exercise-configure-rest-select-loading-pattern-card-selected {
						border-color: #5B62FF;
						border-width: 2px;
						background: rgba(91,98,255,0.05);
					}
						.create-workout-exercise-configure-rest-select-loading-pattern-card h4 {
							color: #ffffff;
						}
			.create-workout-exercise-configure-rest-select-sets-container {
				padding-left: 40px;
				padding-bottom: 24px;
				width: 100%;
				border-bottom: solid;
				border-width: 0.5px;
				border-color: #2B2B30;
				margin-bottom: 12px;
			}
				.create-workout-exercise-configure-rest-select-sets-container-sets {
					display: flex;
					width: 100%;
				}
					.create-workout-exercise-configure-rest-select-sets-container-sets h4 {
						font-size: 13.5px;
						width: 180px;
						color: #ffffff;
						margin: 0px;
						margin-top: 12px;
					}
					.create-workout-exercise-configure-rest-select-sets-container-sets-button {
						padding: 6px 12px;
						background: #1F1F21;
						border-radius: 5px;
						border: solid;
						border-width: 0.5px;
						border-color: #2B2B30;
						height: 32px;
						box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
						-webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}
					.create-workout-exercise-configure-rest-select-sets-container-sets-button:hover {
						background: #28282D;
						-webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
						cursor: pointer;
					}
						.create-workout-exercise-configure-rest-select-sets-container-sets-button p {
							font-size: 13.5px;
							color: #ffffff;
							margin: 0px;
						}

					.create-workout-exercise-configure-rest-select-sets-container-sets-input {
						margin: 0px 12px !important;
						width: calc(100% - 500px) !important;
						min-width: 120px;
						height: 33px !important;
						padding: 0px 12px !important;
						text-align: center;
						box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
					}
						.create-workout-exercise-configure-rest-select-sets-container-sets-input IonInput {
							margin-top: -2px !important;
							padding-top: 0px !important;
						}

				.create-workout-exercise-configure-rest-select-sets-container-loading-patterns {
					padding-right: 8px;
					position: relative;
					display: flex;
				}
				.create-workout-exercise-configure-rest-select-sets-container-loading-patterns-block {
					display: block;
				}
					.create-workout-exercise-configure-loading-pattern-filter-container {

					}
						.create-workout-exercise-configure-loading-pattern-filter-container .filter-single-menu-container {
							left: 0px;
						}
					.create-workout-exercise-configure-rest-select-sets-container-loading-patterns-selector {
						padding: 8px 12px;
						background: #1F1F21;
						border-radius: 5px;
						border: solid;
						border-width: 0.5px;
						border-color: #2B2B30;
						display: flex;
						width: calc(100% - 120px);
						margin-left: 12px;
						box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
						-webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}
					.create-workout-exercise-configure-rest-select-sets-container-loading-patterns-selector:hover {
						cursor: pointer;
						background: rgba(35,40,54,0.4);
						-webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}
						.create-workout-exercise-configure-rest-select-sets-container-loading-patterns-selector p {
							color: #6C6C6C;
							font-size: 13.5px;
							margin: 0px;
						}
						.create-workout-exercise-configure-rest-select-sets-container-loading-patterns-selector-expand-icon-container {
							width: 16px;
							height: 16px;
							margin-left: auto;
							float: right;
						}
							.create-workout-exercise-configure-rest-select-sets-container-loading-patterns-selector-expand-icon {
								font-size: 13.5px !important;
								color: #9DA0AD;
							}
						.create-workout-exercise-configure-rest-select-sets-container-loading-patterns-menu {
							position: absolute;
							right: 8px;
							top: 46px;
							background: #1F1F21;
							border-radius: 5px;
							border: solid;
							border-width: 0.5px;
							border-color: #2B2B30;
							width: calc(100% - 128px);
							padding: 4px 0px;
							padding-top: 0px;
							max-height: 300px;
							overflow-y: scroll;
							box-shadow: 0 2px 32px 2px rgba(5, 5, 23, 0.2);
							z-index: 300;
						}
							.create-workout-exercise-configure-rest-select-sets-container-loading-patterns-menu-search-container {
								padding: 8px 8px;
								font-size: 13.5px;
								background: rgba(0,0,0,0.1);
								border-bottom: solid;
								border-width: 1px;
								border-color: #2B2B30;
							}

							.create-workout-exercise-configure-rest-select-sets-container-loading-patterns-menu-cell {
								padding: 16px 16px;
								border-bottom: solid;
								border-width: 1px;
								border-color: #2B2B30;
								display: block;
								position: relative;
								-webkit-transition: all 0.15s;
								-moz-transition: all 0.15s;
								transition: all 0.15s;
							}
							.create-workout-exercise-configure-rest-select-sets-container-loading-patterns-menu-cell:hover {
								background: rgba(35,40,54,0.4);
								cursor: pointer;
								-webkit-transition: all 0.15s;
								-moz-transition: all 0.15s;
								transition: all 0.15s;
							}
								.create-workout-exercise-configure-rest-select-sets-container-loading-patterns-menu-cell-upper {
									display: flex;
								}
									.create-workout-exercise-configure-rest-select-sets-container-loading-patterns-menu-cell-upper h4 {
										font-size: 13.5px;
										color: #ffffff;
										margin: 0px;
									}
									.create-workout-exercise-configure-rest-select-sets-container-loading-patterns-menu-cell-upper-reps-container {
										margin-left: auto;
										display: flex;
										float: right;
										padding-right: 12px;
									}
										.create-workout-exercise-configure-rest-select-sets-container-loading-patterns-menu-cell-upper p {
											font-size: 13.5px;
											color: #9DA0AD;
											margin: 0px;
											margin-right: 12px;
											font-weight: 700;
										}
								.create-workout-exercise-configure-rest-select-sets-container-loading-patterns-menu-cell-lower {
									/*display: flex;*/
									display: none;
									padding-top: 4px;
								}
									.create-workout-exercise-configure-rest-select-sets-container-loading-patterns-menu-cell-lower-set {
										margin-right: 8px;
									}
										.create-workout-exercise-configure-rest-select-sets-container-loading-patterns-menu-cell-lower-set p {
											font-size: 10px;
											color: #9DA0AD;
											margin: 0px;
										}
								.create-workout-exercise-configure-rest-select-sets-container-loading-patterns-menu-cell-lower-selected {
									display: flex !important;
								}

								.create-workout-exercise-configure-rest-select-sets-container-loading-patterns-menu-cell-arrow-container {
									position: absolute;
									height: 20px;
									width: 20px;
									right: 0px;
									top: 14px;
								}
									.create-workout-exercise-configure-rest-select-sets-container-loading-patterns-menu-cell-arrow-icon {
										font-size: 12px !important;
										color: #6C6C6C;
										-webkit-transition: all 0.15s;
										-moz-transition: all 0.15s;
										transition: all 0.15s;
									}
									.create-workout-exercise-configure-rest-select-sets-container-loading-patterns-menu-cell-arrow-container:hover .create-workout-exercise-configure-rest-select-sets-container-loading-patterns-menu-cell-arrow-icon {
										color: #ffffff;
										-webkit-transition: all 0.15s;
										-moz-transition: all 0.15s;
										transition: all 0.15s;
										cursor: pointer;
									}

							.create-workout-exercise-configure-rest-select-sets-container-loading-patterns-selected-card {
								padding: 16px;
								background: #1F1F21;
								border-radius: 5px;
								position: relative;
								border: solid;
								border-width: 0.5px;
								border-color: #2B2B30;
								box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
							}
							.create-workout-exercise-configure-rest-select-sets-container-loading-patterns-selected-revert-container {
								padding: 8px 4px;
							}
								.create-workout-exercise-configure-rest-select-sets-container-loading-patterns-selected-revert-container p {
									font-size: 13.5px;
									color: #5B62FF;
									margin: 0px;
								}
								.create-workout-exercise-configure-rest-select-sets-container-loading-patterns-selected-revert-container:hover p {
									cursor: pointer;
									color: #5C6BC9;
								}
			.create-workout-exercise-configure-rest-select-container {
				width: 100%;
				display: flex;
				padding-top: 8px;
				padding-bottom: 4px;
				padding-left: 12px;
			}
				.create-workout-exercise-configure-rest-select-container-indented {
					padding-left: 40px;
					padding-top: 4px;
					padding-bottom: 8px;
				}

			.create-workout-exercise-configure-selector-container-unselected {
				width: 16px;
				height: 16px;
				border-radius: 5px;
				border-color: #5F6C76;
				border-style: solid;
				border-width: 1px;
				margin-right: 12px;
				margin-top: 7px;
				cursor: pointer;
			}
			.create-workout-exercise-configure-selector-container-unselected:hover {
				background: rgba(73,89,193,0.2);
			}

			.create-workout-exercise-configure-selector-container-selected {
				width: 16px;
				height: 16px;
				border-radius: 5px;
				background-color: #5B62FF;
				margin-right: 12px;
				border-color: rgba(0,0,0,0);
				margin-top: 6px;
				cursor: pointer;
				position: relative;
			}
				.create-workout-exercise-configure-selector-container-icon {
					font-size: 10px !important;
					color: #ffffff;
					position: absolute;
					top: 3px;
					left: 3.5px;
				}

			.create-workout-exercise-configure-rest-select-text-container {
				display: block;
				padding-bottom: 6px;
				cursor: pointer;
			}

			.create-workout-exercise-configure-rest-select-text-container h4 {
				color: #C1C4CC;
				font-size: 13.5px;
				margin: 0px;
			}

			.create-workout-exercise-configure-rest-select-text-container p {
				color: #5F6C76;
				font-size: 10px;
				margin: 0px;
			}

			.create-workout-exercise-configure-rest-input-container {
				margin-left: auto;
				float: right;
				height: 32px;
				border-color: #2B2B30;
				background: rgba(0,0,0,0.1);
				box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
				border-width: 1px;
				border-style: solid;
				border-radius: 5px;
				width: 110px;
				margin-right: 12px;
				text-align: right;
				padding-right: 12px;
				display: flex;
				font-size: 13.5px !important;
				color: #ffffff;
			}
				.create-workout-exercise-configure-rest-input-container p {
					padding-left: 8px;
					margin: 0px;
					margin-top: 6px;
					color: #9DA0AD;
				}

			.create-workout-exercise-configure-rest-input-container-small-container {
				width: 120px;
				display: flex;
				margin-left: auto;
				float: right;
				position: relative;
			}
				.create-workout-exercise-configure-rest-input-container-small {
					width: 50px;
					margin-left: 0px;
					float: right;
					text-align: center;
					padding-left: 10px;
					padding-right: 0px;
				}
				.create-workout-exercise-configure-rest-input-container-small-container p {
					font-size: 13.5px;
					color: #9DA0AD;
					margin: 0px;
					position: absolute;
					top: 6px;
					left: calc(50% - 8px);
				}
					.create-workout-exercise-configure-rest-input-container-dropdown-container {
						padding-left: 8px;
						position: relative;
						cursor: pointer;
					}
						.create-workout-exercise-configure-rest-input-container-dropdown-container p {
							color: #ffffff !important;
							margin-top: 7px;
						}
						.create-workout-exercise-configure-rest-input-container-selector-input {
							display: flex;
							position: relative;
							width: 100%;
						}
							.create-workout-exercise-configure-rest-input-container-selector-input-expand-icon-container {
								width: 24px;
								height: 24px;
								position: absolute;
								right: 0px;
								top: 7px;
							}
								.create-workout-exercise-configure-rest-input-container-selector-input-expand-icon {
									font-size: 15px !important;
									color: #6C6C6C;
								}
						.create-workout-exercise-configure-rest-input-container-selector-menu-container {
							width: 340px;
							max-height: 300px;
							border-radius: 5px;
							box-shadow: 0px 2px 32px 6px rgba(0, 0, 0, 0.1);
							background: #1F1F21;
							border: solid;
							border-width: 0.5px;
							border-color: #2B2B30;
							position: absolute;
							right: 0px;
							top: 38px;
							padding: 6px 0px;
							z-index: 500;
						}
							.create-workout-exercise-configure-rest-input-container-selector-menu-row {
								width: 100%;
								padding: 12px 12px;
								border-bottom: solid;
								border-width: 0.5px;
								border-color: #2B2B30;
								
								-webkit-transition: all 0.15s;
								-moz-transition: all 0.15s;
								transition: all 0.15s;
							}
							.create-workout-exercise-configure-rest-input-container-selector-menu-row:hover {
								background: #1F1F21;
								cursor: pointer;
								-webkit-transition: all 0.15s;
								-moz-transition: all 0.15s;
								transition: all 0.15s;
							}
								.create-workout-exercise-configure-rest-input-container-selector-menu-row p {
									color: #ffffff !important;
									font-size: 13.5px;
									text-align: left;
									font-weight: 500;
									padding-left: 0px !important;
									margin: 0px;
									margin-bottom: 4px;
								}
								.create-workout-exercise-configure-rest-input-container-selector-menu-row h4 {
									color: #9DA0AD !important;
									font-size: 12px;
									font-weight: 300;
									text-align: left;
									margin: 0px;
								}
								.create-workout-exercise-configure-rest-input-container-selector-menu-row-selected p {
									color: #ffffff !important;
								}


			.create-workout-exercise-configure-tempo-input-container {
				margin-left: auto;
				float: right;
				height: 32px;
				width: 122px;
			}
	.create-workout-exercise-configure-options-header-expand-icon-container {
		cursor: pointer;
	}
		.create-workout-exercise-configure-options-header-expand-icon {
			color: #9DA0AD;
		}
		.create-workout-exercise-configure-options-header-expand-icon-container:hover .create-workout-exercise-configure-options-header-expand-icon {
			color: #ffffff;
		}
		.create-workout-exercise-configure-set-list-container-header {
			width: 100%;
			display: flex;
			padding: 0px 16px;
			padding-bottom: 8px;
			border-bottom: solid;
			border-color: #2B2B30;
			border-width: 1px;
			margin-bottom: 12px;
			padding-top: 12px;
		}
			.create-workout-exercise-configure-set-list-container-header p {
				font-size: 10px;
				color: #9DA0AD;
				margin: 0px !important;
			}
			.create-workout-exercise-configure-set-list-container-header-text-container {
				width: 80px;
			}
			.create-workout-exercise-configure-set-list-container-header-stat-container {
				width: calc(20% - 15px);
				display: flex;
			}
				.create-workout-exercise-configure-set-list-container-header-stat-container p {
					margin-right: 8px !important;
				}

			.create-workout-exercise-configure-set-cell {
				width: 100%;
				padding: 8px 16px;
				display: flex;
				border-bottom: solid;
				border-width: 0.5px;
				border-color: #2B2B30;
				position: relative;
			}

			.create-workout-exercise-configure-set-cell-name-container {
				width: 80px;
				font-size: 13.5px;
				color: #ffffff;
				margin-top: 10px;
			}

			.create-workout-exercise-configure-set-cell-sets-container {
				width: calc(20% - 15px);
				font-size: 13.5px;
				color: #ffffff;
			}

			.create-workout-exercise-configure-set-cell-input-container {
				/*display: flex;
				border-color: #2B2B30;
				background: rgba(0,0,0,0.1);
				border-width: 1px;
				border-style: solid;
				border-radius: 5px;*/
				width: calc(100% - 12px);
				position: relative;
				height: 36px;
			}
			.create-workout-exercise-configure-set-cell-input-container-styled {
				display: flex;
				border-color: #2B2B30;
				background: rgba(0,0,0,0.1);
				border-width: 1px;
				border-style: solid;
				border-radius: 5px;
				width: calc(100% - 12px);
				position: relative;
				height: 36px;
			}
			.create-workout-exercise-configure-set-cell-input-container-styled p {
				position: absolute;
				
			}

				.create-workout-exercise-configure-set-cell-input-container-blank {
					opacity: 0.5;
					cursor: pointer;
					height: 36px;
				}
					.create-workout-exercise-configure-set-cell-input-container-blank p {
						width: calc(100% - 12px);
						padding-left: 24px;
						text-align: center;
						font-size: 13.5px !important;
						position: relative !important;
						right: 0px !important;
					}

			.create-workout-exercise-configure-set-cell-input-container-small-container {
				display: flex;

			}
				.create-workout-exercise-configure-set-cell-input-container-small {
					width: calc(50% - 12px);
					text-align: center;
					padding-left: 8px;
				}
				.create-workout-exercise-configure-set-cell-input-container-small-container p {
					font-size: 10px;
					margin: 12px 6px;
				}

			.create-workout-exercise-configure-set-cell-sets-tempo-input-container {

			}

			.create-workout-exercise-configure-set-cell-button {
				height: 24px;
				margin-top: 6px;
				border-radius: 5px;
				padding: 3.5px 3.5px;
				background: #1F1F21;
				border: solid;
				border-width: 0.5px;
				border-color: #2B2B30;
				box-shadow: 0 2px 6px 2px rgba(0,0,0,0.1);
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
				display: flex;
			}
				.create-workout-exercise-configure-set-cell-button p {
					font-size: 12px;
					margin: 0px 4px;
					color: #6C6C6C;
				}
			.create-workout-exercise-configure-set-cell-autofill-container {
				float: right;
				margin-left: auto;
				padding-left: 6px;
			}
			.create-workout-exercise-configure-set-cell-remove-container {
				margin-left: 12px;
			}
			.create-workout-exercise-configure-set-cell-button:hover {
				cursor: pointer;
				background: #1F1F21;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
				.create-workout-exercise-configure-set-cell-button:hover p {
					color: #ffffff;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
				.create-workout-exercise-configure-set-cell-autofill-icon {
					color: #9DA0AD;
					font-size: 12px !important;
					margin-top: 2px;
					margin-right: 0px;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
				.create-workout-exercise-configure-set-cell-remove-icon {
					color: #9DA0AD;
					font-size: 12px !important;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
				.create-workout-exercise-configure-set-cell-button:hover .create-workout-exercise-configure-set-cell-remove-icon {
					color: #F02E4A;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
				.create-workout-exercise-configure-set-cell-button:hover .create-workout-exercise-configure-set-cell-autofill-icon {
					color: #ffffff;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}

			.create-workout-exercise-configure-set-cell-menu-container {
				position: absolute;
				top: 44px;
				right: 16px;
				width: 160px;
				/*max-height: 200px;*/
				overflow-y: scroll;
				background: #1F1F21;
				border-radius: 5px;
				border: solid;
				border-width: 1px;
				border-color: #2B2B30;
				box-shadow: 0 2px 24px 6px rgba(0, 0, 0, 0.1);
				z-index: 500;
			}
				.create-workout-exercise-configure-set-cell-menu-header {
					padding: 12px 16px;
					border-bottom: solid;
					border-width: 0.5px;
					border-color: #2B2B30;
				}
					.create-workout-exercise-configure-set-cell-menu-header h4 {
						font-size: 13.5px;
						color: #ffffff;
						margin: 0px;
					}
				.create-workout-exercise-configure-set-cell-menu-body {
					padding: 4px 16px;
				}
					.create-workout-exercise-configure-set-cell-menu-body-row {
						display: flex;
						padding: 8px 0px;
						-webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}
					.create-workout-exercise-configure-set-cell-menu-body-row:hover {
						cursor: pointer;
						background: rgba(35,40,54,0.4);
						border-radius: 5px;
						-webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}
						.create-workout-exercise-configure-set-cell-menu-body-row p {
							font-size: 13.5px;
							color: #ffffff;
							margin: 0px;
						}

					.create-workout-exercise-configure-set-cell-menu-body-row-line {
						border-top: solid;
						border-width: 0.5px;
						border-color: #2B2B30;
						padding-top: 12px;
						margin-top: 4px;
					}
					.create-workout-exercise-configure-set-cell-menu-icon {
						color: #9DA0AD;
						font-size: 12px !important;
						margin-top: 2px;
						margin-right: 8px;
						-webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}
					.create-workout-exercise-configure-set-cell-button:hover .create-workout-exercise-configure-set-cell-remove-icon {
						color: #F02E4A !important;
						-webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}
					.create-workout-exercise-configure-set-cell-button:hover .create-workout-exercise-configure-set-cell-menu-icon {
						color: #ffffff;
						-webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}

					.create-workout-exercise-configure-set-cell-button:hover .create-workout-exercise-configure-set-cell-menu-body-row-remove {
						color: #ffffff;
						-webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}

			.create-workout-exercise-configure-set-cell-input-container-styled p {
				margin: 0px;
				color: #9DA0AD;
				font-size: 13.5px;
				position: absolute;
				top: 9px;
				right: calc(50% - 32px);
			}

			.create-workout-exercise-configure-set-cell-input {
				margin: 0px;
				width: 100%;
				text-align: center;
			}
	.create-workout-exercise-configure-new-exercise-container-add-buttons-container {
		width: 100%;
		display: flex;
		padding-top: 12px;
	}
		.create-workout-exercise-configure-new-exercise-container-add-another-set {
			padding: 6px 12px;
			margin-bottom: 24px;
			margin-right: 12px;
			float: left;
			border-radius: 5px;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
		.create-workout-exercise-configure-new-exercise-container-add-another-set:hover {
			cursor: pointer;
			background: rgba(0, 0, 0, 0.2);
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
			.create-workout-exercise-configure-new-exercise-container-add-another-set p {
				font-size: 13.5px;
				margin: 0px;
				color: #5B62FF;
			}
			.create-workout-exercise-configure-new-exercise-container-add-another-set:hover p {
				color: #7278F2;
				
			}
		.create-workout-exercise-configure-new-exercise-container-add-another-set-unselected {
		}
		.create-workout-exercise-configure-new-exercise-container-add-another-set-unselected:hover {
			background: transparent;
			cursor: default;
		}
			.create-workout-exercise-configure-new-exercise-container-add-another-set-unselected p {
				color: #6C6C6C;
			}
			.create-workout-exercise-configure-new-exercise-container-add-another-set-unselected:hover p {
				color: #6C6C6C;	
			}

		.create-workout-exercise-configure-note-input-container {
			/*width: 100%;
			max-height: 400px;
			background: red;*/
			min-height: 200px;
		}
	.create-workout-exercise-configure-add-button-container {
		width: 100%;
		padding-top: 48px;
		padding-bottom: 48px;
		display: flex;
	}
		.create-workout-exercise-configure-add-button {
			/*margin-left: auto;
			float: right;*/
			background: #5437DC;
			opacity: 0.2;
			padding: 6px 12px;
			border-radius: 5px;
			border: solid;
			border-width: 0.5px;
			border-color: #2B2B30;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;	
		}
		.create-workout-exercise-configure-add-button {
			position: absolute;
			z-index: 600;
			top: -49px;
			right: 28px;
		}
			.create-workout-exercise-configure-add-button p {
				font-size: 13.5px;
				color: white;
				margin: 0px;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}

			.create-workout-exercise-configure-add-button-selected {
				background: #5437DC;
				opacity: 1.0;
				border-color: rgba(0,0,0,0);
				box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
			.create-workout-exercise-configure-add-button-selected:hover {
				background: #7963E0;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
				cursor: pointer;
			}
				.create-workout-exercise-configure-add-button-selected p {
					color: #ffffff;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}

			.create-workout-exercise-configure-cancel-button {
				background: #1F1F21;
				padding: 8px 12px;
				border-radius: 5px;
				border: solid;
				margin-right: 12px;
				border-width: 0.5px;
				border-color: #2B2B30;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;	
			}
			.create-workout-exercise-configure-cancel-button:hover {
				background: #28282D;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
				cursor: pointer;
			}
				.create-workout-exercise-configure-cancel-button p {
					font-size: 13.5px;
					color: #9DA0AD;
					margin: 0px;
				}


.create-workout-exercise-configure-header-container h1 {
	margin: 0px;
	font-size: 18px;
	color: #ffffff;
}

.create-workout-exercise-configure-search-container {
	width: 100%;
	display: flex;
	margin-top: 16px;
	position: relative;

}

.create-workout-exercise-configure-search-header-container {
	position: absolute;
	top: -12px;
	left: 0px;

}

.create-workout-exercise-configure-search-header-container p {
	font-size: 10px;
	color: #ffffff;
}



.create-workout-exercise-configure-results-container {
	width: calc(100% + 36px);
	margin-left: -18px;
	padding: 0px 18px;
	height: calc(100% - 88px);
	overflow-y: scroll;
}

.create-workout-exercise-configure-results-header-container {
	display: flex;
	width: 100%;
	padding-top: 0px;
}

.create-workout-exercise-configure-results-header-text-light {
	width: 60%;
	color: #ffffff;
	font-size: 13.5px;
}

.create-workout-exercise-configure-results-header-text-dark {
	width: 40%;
	color: #5F6C76;
	text-align: right;
	font-size: 10px;
	padding-top: 8px;
}
	.create-workout-exercise-configure-results-header-row-container {
		width: calc(100% - 16px);
		display: flex;
		padding: 12px 12px;
		padding-left: 32px;
		border-bottom: solid;
		padding-right: 50px;
		border-width: 1px;
		border-color: #2B2B30;
	}
		.create-workout-exercise-configure-results-header-col-container {

		}
			.create-workout-exercise-configure-results-header-col-container p {
				font-size: 10px; 
				font-weight: 500;
				margin: 0px;
				color: #9DA0AD;
			}
			.create-workout-exercise-configure-results-header-col-container-image-spacer{
				width: 0px;
			}
			.create-workout-exercise-configure-results-header-col-container-large {
				width: 30%;
			}
			.create-workout-exercise-configure-results-header-col-container-small {
				width: 20%;
			}

.create-workout-exercise-configure-results-blank-content {
	width: 100%;
	text-align: center;
	font-size: 14px;
	color: #9DA0AD;
	margin-top: 140px;
}

.create-workout-exercise-configure-results-blank-content-new-container {
	width: 60%;
	margin: 48px auto;

}

.create-workout-exercise-configure-results-blank-content-light {
	width: 100%;
	text-align: center;
	font-size: 14px;
	color: #9DA0AD;
	margin-top: 140px;
}



.create-workout-exercise-configure-new-exercise-container {
	width: 100%;
	padding: 12px 24px;
	padding-right: 12px;
	display: flex;
	font-size: 10px;
	border-radius: 5px;
	background: black;
	color: #ffffff !important;
	background: #181B2A;
	border: solid;
	border-width: 0.5px;
	border-color: #2E3247;
	box-shadow: 0 2px 20px 0 rgba(5, 5, 23, 0.2);
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
}
.create-workout-exercise-configure-new-exercise-container:hover {
	cursor: pointer;
	background: #1C1E31;
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
}
	.create-workout-exercise-configure-new-exercise-container p {
		width: 100%;
		text-align: center;
		font-size: 10px;
		margin: 0px;
	}

	.create-workout-exercise-configure-video-input-container {
		width: 100%;
		display: flex;
		padding-top: 16px;
	}

		.create-workout-exercise-configure-video-container {
			width: 200px;
			height: 200px;
			min-width: 200px;
			min-height: 200px;
			border-radius: 8px !important;
			border-color: #2B2B30 !important;
			border-width: 1px !important;
			border: solid;
			box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.1);
			background: #000000A0;
		}
			.create-workout-exercise-configure-video-container-inner {
				max-width: 100%;
				max-height: 100%;
				border-radius: 8px !important;
				
				position: relative;
			}
				.create-workout-exercise-configure-video-remove-custom-button {
					position: absolute;
					bottom: 4px;
					left: 4px;
				    padding: 4px 8px;
				    background: #1F1F21;
				    border: solid;
				    border-width: 1px;
				    border-color: #2B2B30;
				    display: flex;
				    border-radius: 5px;
				    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.12);
				    -webkit-transition: all 0.15s;
				    -moz-transition: all 0.15s;
				    transition: all 0.15s;
				}
				.create-workout-exercise-configure-video-remove-custom-button:hover {
				    background: #1F1F21;
				    cursor: pointer;
				    -webkit-transition: all 0.15s;
				    -moz-transition: all 0.15s;
				    transition: all 0.15s;
				}
					.create-workout-exercise-configure-video-remove-custom-button p {
					    font-size: 13.5px;
					    color: #ffffff;
					    margin: 0px;
					}
				.create-workout-exercise-configure-video-container-inner-loading {
					align-items: center;
					justify-content: center;
					width: 100%;
					height: 100%;
				}
					.create-workout-exercise-configure-video-container-inner-loading p {
					    font-size: 13.5px;
					    color: #6C6C6C;
					    margin: 0px;
					    width: 100%;
					    text-align: center;
					    padding-top: 90px;
					}
				.create-workout-exercise-configure-video-container-inner-video-container {
					max-width: 100%;
					max-height: 100%;
					border-radius: 8px !important;
					overflow: hidden;
				}
					.create-workout-exercise-configure-video-container-inner-video-container video {
						width: 100%;
						height: 100%;
						border-radius: 8px !important;
					}


			.create-workout-exercise-configure-drop-area {
				margin: 0px auto;
				width: calc(100% - 48px);
				padding-left: 24px;
				height: 200px;
				/*background: #161820;*/
				/*width: calc(100% - 48px);
				border: dashed;
				border-width: 1px;
				border-color: #6C6C6C;
				border-radius: 8px;
				margin: 0px auto;
				margin-top: 24px;
				padding: 28px 20px;
				box-shadow: 0px 0px 12px 6px rgba(0, 0, 0, 0.08);*/
			}
			.create-workout-exercise-configure-drop-area .file-drop {
				height: 100%;
			}
				.create-workout-exercise-configure-drop-area-inner-container {
					width: 100%;
				}

				.create-workout-exercise-configure-drop-area-inner-container-has-image {
					display: flex;
					padding-left: 24px;
				}
					.create-workout-exercise-configure-drop-area-image-container {
						width: 100px;
						border-radius: 5px;
						max-height: 100px;
						overflow: hidden;
					}
						.create-workout-exercise-configure-drop-area-image-container img {

						}
					.create-workout-exercise-configure-drop-area-text-container {
						padding-left: 24px;
						padding-top: 26px;
					}
						.create-workout-exercise-configure-drop-area-text-container h4 {
						    font-size: 18px !important;
						    color: #ffffff !important;
						    margin: 0px;
						}
						.create-workout-exercise-configure-drop-area-text-container .my-profile-information-section-card-body-image-content-remove-button {
							margin-top: 4px;
						}
						.create-workout-exercise-configure-drop-area-text-container .my-profile-information-section-card-body-image-content-remove-button p {
							font-size: 12px;
							margin-left: 6px;
							margin-top: -1px;
							color: #6C6C6C;
							text-align: left !important;
						}


					.create-workout-exercise-configure-drop-area h4 {
					    font-size: 15px !important;
					    color: #ffffff;
					    margin: 0px;
					    width: 100% !important;
					    text-align: center;
					}
					.create-workout-exercise-configure-drop-area p {
					    font-size: 13.5px;
					    color: #6C6C6C;
					    margin: 0px;
					    width: 100%;
					    text-align: center;
					    margin-top: 6px;
					}
					.create-workout-exercise-configure-drop-area-icon-container {
						margin: 12px auto;
						margin-top: 0px;
						width: 20px;
					}
						.create-workout-exercise-configure-drop-area-icon {
							color: #9DA0AD;
							font-size: 20px !important;
						}




