/* .workout-plan-list-cycle-overview-card- */


.workout-plan-list-cycle-overview-card {
	/*border: solid;
    border-width: 0.5px;
    border-color: #2B2B30;
    box-shadow: 0 4px 38px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 12px;*/
    background: var(--ef-background);
    width: 100%;
    margin-bottom: 4px;
	border-radius: 12px !important;
	border-width: 1px !important;
	border: solid;
	border-color: var(--ef-border);
}
	.workout-plan-list-cycle-overview-card-header {
		height: 50px;
		background: #1F1F21;
		
		border: solid;
		border-width: 1px !important;
		border-color: var(--ef-border-light);
		border-radius: var(--ef-radius-m);
		display: flex;
		box-shadow: 0 4px 12px 6px rgba(0, 0, 0, 0.12);
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;	
	}
		.workout-plan-list-cycle-overview-card-header:hover {
			background: var(--ef-card-light);
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}

	.workout-plan-list-cycle-overview-card-header-icon-wrapper {
		width: 50px;
		height: 100%;
		padding: 8px 9px;
        border-right: solid;
        border-width: 1px !important;
        border-color: var(--ef-border-highlight-dark);
	}


	.workout-plan-list-cycle-overview-card-header:hover {
		/*background: #28282D;
		cursor: pointer;
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;	*/
	}
	.workout-plan-list-cycle-overview-card-header-arrow-icon-container:hover .workout-plan-list-cycle-overview-card-header-arrow-icon {
		color: #ffffff;
		cursor: pointer;
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;	
	}
		.workout-plan-list-cycle-overview-card-header-icon-container {
			width: 30px;
            height: 30px;
            border-radius: var(--ef-radius-m);
            padding: 6px 6px;
            background: var(--ef-color-cycle-transparent);
		}
			.workout-plan-list-cycle-overview-card-header-icon {
				width: 18px;
				height: 18px;
			}

		.workout-plan-list-cycle-overview-card-header-content-wrapper {
			flex: 1;
			height: 100%;
			padding: 8px 20px;
			padding-left: 12px;
			display: flex;
            border-left: solid;
            border-width: 1px !important;
            border-color: var(--ef-border-light) !important;
		}
		.workout-plan-list-cycle-overview-card-header-drag-handle-container {
			width: 20px;
			height: 20px;
			padding-top: 7px;
			cursor: move;
		}
			.workout-plan-list-cycle-overview-card-header-drag-handle-icon {
				color: var(--ef-text-dark) !important;
				font-size: 18px !important;
			}
		.workout-plan-list-cycle-overview-card-header-text-container {
			padding-left: 8px;
			padding-top: 6px;
			display: flex;
			flex: 1;
		}	
			.workout-plan-list-cycle-overview-card-header-text-container p {
				font-size: 13.5px;
				color: #5B62FF;
				font-weight: 600;
				margin-left: 8px;
			}
			.workout-plan-list-cycle-overview-card-header-text-container h4 {
				font-size: var(--ef-font-size-lg);
				color: var(--ef-text-light);
				font-weight: 600;
				margin: 0px;
				margin-left: 0px;
			}
			.workout-plan-list-cycle-overview-card-header-phase-count-text-container {
				padding-top: 7px;
			}
				.workout-plan-list-cycle-overview-card-header-phase-count-text-container {

				}
		.workout-plan-list-cycle-overview-card-header-arrow-icon-container {
			height: 24px;
			width: 24px;
			float: right;
			margin-left: auto;
			margin-top: 6px;
			display: flex;
			cursor: pointer;
			padding: 6px 7px;
			border-radius: 5px;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;	
		}
		.workout-plan-list-cycle-overview-card-header-arrow-icon-container:hover {
			background: #161719;
			box-shadow: 0 0px 12px 2px rgba(0, 0, 0, 0.1);
		}
			.workout-plan-list-cycle-overview-card-header-arrow-icon-container p {
				font-size: 10px;
				margin: 0px;
				margin-right: 8px;
				color: #9DA0AD;
				font-weight: 500;
			}
			.workout-plan-list-cycle-overview-card-header-arrow-icon {
				font-size: 12px !important;
				color: #6C6C6C;
			}

			.workout-plan-list-cycle-overview-card-header-arrow-icon-container:hover p {
				color: #5C6BC9;
				
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;	
			}
				.workout-plan-list-cycle-overview-card-header-button {

				}


			.workout-plan-list-cycle-overview-card-header-buttons-container {
				opacity: 0.0;
				width: 0px;
				overflow: hidden;
				display: flex;
				padding-left: 12px;
				margin-right: -6px;
			    -webkit-transition: all 0.15s;
			    -moz-transition: all 0.15s;
			    transition: all 0.15s;
			}
			.workout-plan-list-cycle-overview-card-header:hover .workout-plan-list-cycle-overview-card-header-buttons-container {
				opacity: 1.0;
				width: 80px;
			}
				.workout-plan-list-cycle-overview-card-header-button {
				    padding: 8px 8px;
				    width: 30px;
				    height: 30px;
				    margin-left: 4px;
				    background: var(--ef-card);
				    border: solid;
				    border-width: 1px;
				    border-color: var(--ef-border-light);
				    display: flex;
				    border-radius: var(--ef-radius-m);
				    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.12);
				    -webkit-transition: all 0.15s;
				    -moz-transition: all 0.15s;
				    transition: all 0.15s;
				}
				.workout-plan-list-cycle-overview-card-header-button-delete {
					padding: 6px 6px;
				}
				.workout-plan-list-cycle-overview-card-header-button:hover {
				    background: var(--ef-card-light);
				    border-color: var(--ef-border-light);
				    cursor: pointer;
				    -webkit-transition: all 0.15s;
				    -moz-transition: all 0.15s;
				    transition: all 0.15s;
				}
				.workout-plan-list-cycle-overview-card-header-button-delete:hover {
					background: var(--ef-alert);
				    border-color: var(--ef-alert);
				}
					.workout-plan-list-cycle-overview-card-header-button-icon {
						font-size: 13px !important;
						color: var(--ef-text-mid);
					}
					.workout-plan-list-cycle-overview-card-header-button-delete .workout-plan-list-cycle-overview-card-header-button-icon {
						font-size: 16px !important;
					}
					.workout-plan-list-cycle-overview-card-header-button-delete {
						color: var(--ef-text-white);
					}
					.workout-plan-list-cycle-overview-card-header-button:hover .workout-plan-list-cycle-overview-card-header-button-icon {
						color: var(--ef-text-light);
					}
					.workout-plan-list-cycle-overview-card-header-button:hover .workout-plan-list-cycle-overview-card-header-button-icon-delete {
						color: var(--ef-text-white);
					}


	.workout-plan-list-cycle-overview-card-content-container {
		padding: 8px 8px;
		padding-bottom: 4px;
		min-height: 0px;
		display: block;
		overflow: hidden;
		position: relative;
	}
		.workout-plan-list-cycle-overview-card-content-container-inner {
			position: relative;
			width: 100%;
			min-width: 100%;
			margin-bottom: 4px;
		}
			.workout-plan-list-cycle-overview-card-content-branch-container {
				position: absolute;
				left: 16px;
				top: -128px;
				z-index: 0;
			}
				.workout-plan-list-cycle-overview-card-content-branch {
					width: 50px;
					height: 156px;
					border-bottom-left-radius: 14px;
					border-left: solid;
					border-bottom: solid;
					border-width: 1px !important;
					border-color: var(--ef-color-phase);
				}
				.workout-plan-list-cycle-overview-card-content-branch-unselected {
					border-color: var(--ef-border-light);
				}


		.workout-plan-list-cycle-overview-card-content-container-linking-line-container {
			width: 50px;
			height: 100px;
			position: absolute;
			bottom: -2px;
			left: 0px;
			padding-left: 24px;
		}
			.workout-plan-list-cycle-overview-card-content-container-linking-line {
				width: 2px;
				height: 100%;
				border-left: dashed;
				border-width: 1px !important;
				border-color: var(--ef-color-phase);
			}
			.workout-plan-list-cycle-overview-card-content-container-linking-line-dark {
				border-color: var(--ef-border-light) !important; 
			}
		.workout-plan-list-cycle-overview-card-content-phase-purposed-bar {
			position: absolute;
			height: 100%;
			width: 6px;
			border-radius: 3px;
			top: 0px;
			left: -11px;
			background: #5B62FF;
		} 
			.workout-plan-list-cycle-overview-card-content-phase-purposed-bar-end {
				left: auto;
				right: 28px;
				top: 12px;
				height: calc(100% - 24px);
			}

		.workout-plan-list-cycle-overview-card-content-phase-container {
			width: 100%;
			padding-left: 42px;
			position: relative;
			z-index: 1;
		}
		





