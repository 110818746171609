/*  */


.my-coaching-profile-preview-phone {
	width: 380px;
	height: calc(100vh - 210px);
	background: #161820;
	box-shadow: 0px 0px 32px 12px rgba(0, 0, 0, 0.24);
	margin: 0px auto;
	border-radius: 24px;
	border: solid;
	border-width: 5px;
	border-color: black;
	position: relative;
}	
	.my-coaching-profile-preview-phone-ios-default-overlay {
		position: absolute;
		z-index: 10;
		width: 100%;
		padding: 18px 24px;
		display: flex;
	}
		.my-coaching-profile-preview-phone-ios-default-overlay-time {

		}
			.my-coaching-profile-preview-phone-ios-default-overlay-time h4 {
			    font-size: 13.5px;
			    color: white;
			    font-weight: 700;
			    margin: 0px;
			}
		.my-coaching-profile-preview-phone-ios-default-overlay-icon-container {
			display: flex;
			margin-left: auto;
			float: right;
		}
			.my-coaching-profile-preview-phone-ios-default-overlay-icon { 
				font-size: 14px !important;
				color: white;
				margin-left: 2px;
			}

.my-coaching-profile-preview-phone-content {
	width: 100%;
	height: 100%;
	overflow-y: scroll;
	border-radius: 18px;
	padding-bottom: 100px;
	-ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    overflow: -moz-scrollbars-none
}
.my-coaching-profile-preview-phone-content::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}
	.my-coaching-profile-preview-phone-content-section {
		width: 100%;
	}

	.my-coaching-profile-preview-phone-content-section-header {
		position: relative;
		width: 100%;
		height: 100%;
		min-height: 660px;
	}
		.my-coaching-profile-preview-phone-content-section-header-studio-img-container {
			position: absolute;
			width: 60px;
			top: 42px;
			right: 24px;
			z-index: 1;
		}
			.my-coaching-profile-preview-phone-content-section-header-studio-img-container img {
				border-radius: 8px;
			}
		.my-coaching-profile-preview-phone-content-section-header-img-container {
			position: relative;
			overflow: hidden;
			width: 100%;
			height: 100%;
		}
			.my-coaching-profile-preview-phone-content-section-header-img-container img {
				max-width: 100%;
				height: auto;
				position: relative;
				z-index: 0;
			}
			.my-coaching-profile-preview-phone-content-section-header-img-overlay {
				position: absolute;
				z-index: 1;
				width: 100%;
				height: 100%;
				/*background: rgb(189,189,189);
				background: linear-gradient(180deg, rgba(189,189,189,0) 20%, rgba(189,189,189,1) 70%)*/;
			}
		.my-coaching-profile-preview-phone-content-section-header-studio-accepting-clients-tag {
			display: flex;
			position: absolute;
			z-index: 1;
			top: 40px;
			left: 20px;
			padding-left: 8px !important;
			-webkit-backdrop-filter: blur(4px);
		}
			.my-coaching-profile-preview-phone-content-section-header-studio-accepting-clients-tag-icon {
				font-size: 14px !important;
				margin-top: 1px;
				margin-right: 4px;
			}
		.my-coaching-profile-preview-phone-content-section-header-text-container {
			position: absolute;
			width: 100%;
			z-index: 2;
			padding: 0px 20px;
			padding-top: 400px;
			top: 0px;
			left: 0px;
		}
			.my-coaching-profile-preview-phone-content-section-header-text-container h4 {
			    font-size: 18px;
			    font-weight: 600;
			    color: #FFD15B;
			    margin: 0px;
			}
			.my-coaching-profile-preview-phone-content-section-header-text-container h1 {
			    font-size: 48px;
			    line-height: 48px;
			    font-weight: 600;
			    color: white;
			    margin: 0px;
			    padding: 4px 0px;
			    padding-bottom: 8px;
			}
				.my-coaching-profile-preview-phone-content-section-header-text-title-container {
					padding: 4px 12px;
					border-radius: 5px;
					background: rgba(114, 120, 242, 0.2);
					float: left;
					margin-top: 4px;
				}
					.my-coaching-profile-preview-phone-content-section-header-text-title-container p {
					    font-size: 13.5px;
					    color: #7278F2;
					    font-weight: 600;
					    margin: 0px;
					}
				.my-coaching-profile-preview-phone-content-section-header-text-scroll-container {
					width: 120px;
					display: flex;
					margin: 48px auto;
				}
					.my-coaching-profile-preview-phone-content-section-header-text-scroll-container p {
					    font-size: 13.5px;
					    margin: 0px;
					}
					.my-coaching-profile-preview-phone-content-section-header-text-scroll-icon {
						font-size: 16px !important;
						margin-left: 8px;
					}
		.my-coaching-profile-preview-phone-content-section-info-sections-container {
			width: 100%;
			
		}
			.my-coaching-profile-preview-phone-content-section-info-section {
				width: 100%;
				padding-bottom: 60px;
			}
				.my-coaching-profile-preview-phone-content-section-info-section-header {
					width: 90%;
					padding: 0px 20px;
				}
					.my-coaching-profile-preview-phone-content-section-info-section-header h2 {
					    font-size: 36px;
					    margin: 0px;
					    margin-bottom: 16px;
					}
					.my-coaching-profile-preview-phone-content-section-info-section-header p {
						font-size: 18px;
					    margin: 0px;
					    line-height: 24px;
					    margin-top: -12px;
					}
				.my-coaching-profile-preview-phone-content-section-info-section-content {
					width: 100%;
					padding: 0px 20px;
				}
					.my-coaching-profile-preview-phone-content-section-info-section-content p {
					    font-size: 18px;
					    margin: 0px;
					    line-height: 24px;

					}
				.my-coaching-profile-preview-phone-content-section-info-section-button {
					width: calc(100% - 40px);
					margin: 32px auto;
					margin-bottom: 0px;
					padding: 10px 0px;
					border-radius: 5px;
					box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.06);
				}
					.my-coaching-profile-preview-phone-content-section-info-section-button p {
					    font-size: 18px;
					    margin: 0px;
					    font-weight: 600;
					    width: 100%;
					    text-align: center;
					}
				.my-coaching-profile-preview-phone-content-section-info-section-image-container {
					margin-top: 32px;
					max-height: 600px;
					overflow: hidden;
				}

		.my-coaching-profile-preview-phone-content-section-background-section {
			width: 100%;
			padding: 0px 20px;
			padding-top: 24px;
		}
			.my-coaching-profile-preview-phone-content-background-row {
				width: 100%;
				display: flex;
				padding: 8px 0px;
			}
				.my-coaching-profile-preview-phone-content-background-row-icon-container {
					width: 40px;
				}
					.my-coaching-profile-preview-phone-content-background-row-icon {
						font-size: 22px !important;
					}
				.my-coaching-profile-preview-phone-content-background-row-text-container {
					width: calc(100% - 40px);
				}
					.my-coaching-profile-preview-phone-content-background-row-text-container h4 {
					    font-size: 20px !important;
					    margin: 0px;
					    font-weight: 600;
					    margin-bottom: 4px;
					}
					.my-coaching-profile-preview-phone-content-background-row-text-container p {
					    font-size: 13.5px;
					    margin: 0px;
					    font-weight: 600;
					}









