/* .client-programs-folders- */

.client-programs-folders {
	width: 100%;
	display: flex;
	height: 100%;
}
	.client-programs-folders-list-container {
		width: 40%;
		height: calc(100vh - 220px);
	}
		.client-programs-folders-list-container-inner {
			height: 100%;
			padding-bottom: 24px;
		}
	.client-programs-folders-content-container {
		width: 60%;
		height: calc(100vh - 178px);
		border-left: solid;
		border-width: 1px;
		border-color: #2B2B30;
		box-shadow: -8px 12px 18px 2px rgba(0, 0, 0, 0.08);
	}
		.client-programs-folders-content-container h4 {
			font-size: 13.5px;
			color: #ffffff;
			margin: 0px;
		}
		.client-programs-folders-content-container p {
			font-size: 13.5px;
			color: #ffffff;
		}

		.client-programs-folders-content-container .workout-plan-list-plan-content {
			height: calc(100vh - 262px);
		}

		.client-programs-folders-content-container .workout-plan-list-plan-workout-body-container {
			height: calc(100vh - 312px);
		}