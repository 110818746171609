/* .workouts-more- */

.workouts-more {
	width: 100%;
	display: flex;
	height: 100%;
}
	.workouts-more .workout-plan-content-container {
		width: calc(64%);
	}
	.workouts-more-list-folder-card-body {
		width: 100%;
		border-top: solid;
		border-width: 1px;
		border-color: #2B2B30;
		padding: 0px;
	}
		.workouts-more-selection-row {
			width: 100%;
			padding: 16px 32px;
			display: flex;
			border-bottom: solid;
			border-width: 1px;
			border-color: #2B2B30;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
		.workouts-more-selection-row:hover {
			background: #1F1F21;
			cursor: pointer;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
			.workouts-more-selection-row p {
			    font-size: 13.5px;
			    color: #9DA0AD;
			    margin: 0px;
			}
			.workouts-more-selection-row-icon {
				font-size: 15px !important;
				margin-top: 1px;
				color: #9DA0AD;
				margin-left: auto;
				float: right;
			}
		.workouts-more-selection-row-selected {
			background: #1F1F21;
		}
			.workouts-more-selection-row-selected p {
				color: #ffffff;
			}
			.workouts-more-selection-row-selected .workouts-more-selection-row-icon {
				color: #ffffff;
			}
		.workouts-more-selection-row-selected:hover {
			background: #1F1F21;
		}


	.workouts-more .workout-plan-content-container {
		height: 100%;
	}
		.workouts-more-content-page-header {
			width: 100%;
			padding: 24px 32px;
			border-bottom: solid;
			border-width: 1px;
			border-color: #2B2B30;
		}
			.workouts-more-content-page-header h1 {
			    font-size: 28px;
			    font-weight: 400;
			    color: #ffffff;
			    margin: 0px;
			}




