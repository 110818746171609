/* .intra-workout-summary- */

.intra-workout-overview {
	width: 100%;
}
.intra-workout-header {
	width: 100%;
	display: flex;
	padding: 18px 36px;
	padding-bottom: 32px;
	border-bottom: solid;
	border-width: 1px;
	border-color: #2B2B30;
}
	.intra-workout-header-text-container {
		padding-left: 16px;
		margin-top: -4px;
		display: flex;
		width: 100%;
	}
		.intra-workout-header-text-container h1 {
			font-size: 28px;
			color: #ffffff;
			margin: 0px;
		}
		.intra-workout-header-text-container-set {
			float: right;
			margin-left: auto;
			padding-top: 3px;
		}
			.intra-workout-header-text-container h2 {
				font-size: 21px;
				color: #6C6C6C;
				font-weight: 300;
				margin: 0px;
			}

	.intra-workout-body {
		padding-top: 8px;
		width: 100%;
		height: calc(100vh - 156px);
		overflow-y: scroll;
	}	
		.intra-workout-section-container {
			width: 100%;
			border-bottom: solid;
			border-width: 1px;
			border-color: #2B2B30;
			padding: 16px 0px;
		}
			.intra-workout-section-header-container {
				width: 100%;
				display: flex;
				padding: 16px 36px;
			}
				.intra-workout-section-header-container h4 {
				    font-size: 13.5px;
				    color: #ffffff;
				    margin: 0px;
				}
				.intra-workout-section-header-icon-container {
					width: 20px;
					height: 20px;
					margin-right: 8px;
				}
					.intra-workout-section-header-icon {
						font-size: 13.5px !important;
						color: #5B62FF;
					}
				.intra-workout-section-header-link-container {
					margin-left: auto;
					float: right;
					display: flex;
					height: 24px;
					border-radius: 5px;
					margin-top: -5px;
					padding: 4px 8px;
					padding-left: 12px;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
				.intra-workout-section-header-link-container:hover {
					background: rgba(0,0,0,0.2);
					box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.1);
					cursor: pointer;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
					.intra-workout-section-header-link-container p {
					    font-size: 12px;
					    color: #9DA0AD;
					    margin: 0px;
					    -webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}
					.intra-workout-section-header-link-container:hover p {
						color: #ffffff;
						-webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}
					.intra-workout-section-header-link-icon {
						font-size: 11px !important;
						margin-left: 8px;
						color: #9DA0AD;
						margin-top: 3px;
					}
				.intra-workout-section-header-add-container {
					margin-left: auto;
					float: right;
					width: 22px;
					height: 22px;
					border-radius: 5px;
					margin-top: -4px;
					padding: 3px 4px;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
				.intra-workout-section-header-add-container:hover {
					background: rgba(0,0,0,0.2);
					box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.1);
					cursor: pointer;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
					.intra-workout-section-header-add-icon {
						font-size: 15px !important;
						
						color: #9DA0AD;
					}
			.intra-workout-section-body-container {
				width: 100%;
				min-height: 60px;
				padding: 12px 42px;
			}
				.intra-workout-section-body-empty-container {

				}
					.intra-workout-section-body-empty-container p {
					    font-size: 13.5px;
					    color: #9DA0AD;
					    margin: 0px;
					}
			.intra-workout-section-body-details-row-container {
				width: 100%;
				display: flex;
				padding-bottom: 12px;
			}
				.intra-workout-section-body-details-header-col-container {
					width: 33%;
					padding-bottom: 24px;
				}
					.intra-workout-section-body-details-header-col-container h3 {
					    font-size: 28px;
					    color: #ffffff;
					    margin: 0px;
					}
					.intra-workout-section-body-details-header-col-container p {
					    font-size: 13.5px;
					    color: #9DA0AD;
					    margin: 0px;
					    margin-bottom: 8px;
					}
				.intra-workout-section-body-details-content-col-container {
					width: 50%;
					display: flex;
				}
					.intra-workout-section-body-details-content-col-container p {
					    font-size: 13.5px;
					    color: #6C6C6C;
					    margin: 0px;
					    width: 140px;
					}
					.intra-workout-section-body-details-content-col-number-container {
						width: calc(100% - 140px);
						position: relative;
					}
						.intra-workout-section-body-details-content-col-number-container h4 {
						    font-size: 13.5px;
						    color: #ffffff;
						    margin: 0px;
						}
						.intra-workout-overview-half-card-content-icon-container {
							position: absolute;
							width: 12px;
							height: 20px;
							left: -24px;
							top: 0px;
							padding-top: 1px;
						}
							.intra-workout-overview-half-card-content-icon {
								font-size: 24px !important;
							}
							.intra-workout-overview-half-card-content-icon-up {
								color: #5B62FF !important;
								margin-top: -4px;
							}
							.intra-workout-overview-half-card-content-icon-down {
								color: #F02E4A !important;
								margin-top: -4px;
							}

							.intra-workout-overview-half-card-content-icon-circle {
								height: 6px;
								width: 6px;
								border-radius: 3px;
								background: #6C6C6C;
								opacity: 0.5;
								margin-left: 6px;
								margin-top: 5px;
							}


/*
.intra-workout-overview-full-card {
	width: 100%;
	border-radius: 5px;
	background-color: #1F1F21;
	box-shadow: 0 4px 38px 0 rgba(0, 0, 0, 0.1);
	margin-bottom: 12px;
	padding: 14px 32px;
}
	.intra-workout-overview-full-card-content {
		width: 100%;
		display: flex;
	}

		.intra-workout-overview-full-card-content p {
			font-size: 10px;
			color: #9DA0AD;
			margin: 0px;
		}

		.intra-workout-overview-full-card-content h4 {
			font-size: 13.5px;
			color: #ffffff;
			margin: 0px;
		}
	.intra-workout-overview-full-card-content-loading {
		padding-top: 10px;
	}
		.intra-workout-overview-full-card-content-loading p {
			width: 100%;
			text-align: center;
		}

	.intra-workout-overview-full-card-col-2 {
		width: 50%;
	}
	.intra-workout-overview-full-card-col-3 {
		width: 33%;
	}
	.intra-workout-overview-full-card-col-4 {
		width: 25%;
	}

.intra-workout-overview-card-row {
	display: flex;
	width: calc(100% + 12px);
	margin-left: -6px;
}
	.intra-workout-overview-half-card {
		width: calc(50% - 12px);
		border-radius: 5px;
		background-color: #1F1F21;
		box-shadow: 0 4px 38px 0 rgba(0, 0, 0, 0.1);
		min-height: 145px;
		padding: 24px 36px;
		margin: 0px auto;
		margin-bottom: 12px;
		position: relative;
	}
		.intra-workout-overview-half-card-header-icon-container {
			width: 20px;
			height: 20px;
			position: absolute;
			top: 20px;
			left: 15px;
		}	
			.intra-workout-overview-half-card-header-icon {
				color: #4959C1;
				font-size: 10px !important;
			}
		.intra-workout-overview-card-header {
			width: 100%;
			display: flex;
		}
			.intra-workout-overview-card-header h4 {
				font-size: 13.5px;
				color: #ffffff;
				margin: 0px;
			}
			.intra-workout-overview-card-header-unavailable h4 {
				color: #6C6C6C;
			}
			.intra-workout-overview-half-card-header-forward-icon-container {
				width: 16px;
				height: 16px;
				float: right;
				margin-left: auto;
			}
				.intra-workout-overview-half-card-header-forward-icon {
					color: #6C6C6C;
					font-size: 10px !important;
					margin-top: 0px;
					float: right;
				}
		.intra-workout-overview-half-card-content {
			position: relative;
		}
			.intra-workout-overview-half-card-content h3 {
				padding-top: 22px;
				font-size: 18px;
				margin: 0px;
				margin-bottom: 8px;
				color: #ffffff;
			}
			.intra-workout-overview-half-card-content p {
				font-size: 10px;
				color: #9DA0AD;
				margin: 0px;
				margin-top: 2px;
			}
			.intra-workout-overview-half-card-content span {
				margin: 0px 4px;
				font-size: 13.5px;
			}
		.intra-workout-overview-half-card-content-unavailable {

		}
			.intra-workout-overview-half-card-content-unavailable h3 {
				color: #6C6C6C;
			}
		.intra-workout-overview-half-card-content-empty {
			width: 100%;
			padding-top: 48px;
		}
			.intra-workout-overview-half-card-content-empty h5 {
				font-size: 10px;
				margin: 0px;
				color: #ffffff;
				width: 100%;
				text-align: center;
			}
			.intra-workout-overview-half-card-content-empty p {
				font-size: 10px;
				color: #9DA0AD;
				margin: 0px;
				margin-top: 2px;
				width: 100%;
				text-align: center;
			}
		.intra-workout-overview-half-card-content-loading {
			width: 100%;
			padding-top: 48px;
		}	
			.intra-workout-overview-half-card-content-loading p {
				font-size: 10px;
				color: #9DA0AD;
				margin: 0px;
				margin-top: 2px;
				width: 100%;
				text-align: center;
			}
		.intra-workout-overview-half-card-content-icon-container {
			position: absolute;
			width: 12px;
			height: 20px;
			left: -24px;
			top: 79%;
			padding-top: 1px;
		}
			.intra-workout-overview-half-card-content-icon {
				font-size: 18px !important;
			}
			.intra-workout-overview-half-card-content-icon-up {
				color: #4959C1 !important;
			}
			.intra-workout-overview-half-card-content-icon-down {
				color: #F02E4A !important;
			}

			.intra-workout-overview-half-card-content-icon-circle {
				height: 6px;
				width: 6px;
				border-radius: 3px;
				background: #6C6C6C;
				opacity: 0.5;
				margin-left: 6px;
				margin-top: 5px;
			}

	.intra-workout-overview-stats-empty {
		width: 100%;
		padding-top: calc(50% - 200px);
	}
		.intra-workout-overview-stats-empty h4 {
			font-size: 13.5px;
			margin: 0px;
			color: #ffffff;
			width: 100%;
			text-align: center;
		}
		.intra-workout-overview-stats-empty p {
			font-size: 10px;
			color: #6C6C6C;
			margin: 0px;
			margin-top: 2px;
			width: 100%;
			text-align: center;
		}
*/

/*
.intra-workout-summary-container {
	width: 100%;
	display: flex;
}

.intra-workout-summary-overview-card {
	background-color: #111920;
	padding: 24px 24px;
	border-radius: 4px;
	box-shadow: 0 2px 20px 0 rgba(0, 0, 10, 0.08);
	width: 50%;
	height: calc(100vh - 264px);
	margin-top: 16px;
	display: flex;
}

.intra-workout-summary-overview-card-header-container {
	width: 100%;
	height: 100%;
}

.intra-workout-summary-overview-card-header-container h3 {
	margin: 0px;
	font-size: 18px;
	color: #C1C4CC;
}

.intra-workout-summary-overview-card-header-container p {
	font-size: 12px;
	color: #C1C4CC;
	opacity: 0.8;
	margin: 0px;
}

.intra-workout-summary-overview-card-chart-header-container {
	padding: 24px 0px;
	display: flex;
}

.intra-workout-summary-overview-card-chart-header-container h4 {
	margin: 0px;
	font-size: 16px;
	color: #C1C4CC;
	width: calc(100% - 100px);
}

.intra-workout-summary-overview-card-chart-header-see-more-container {
	width: 100px;
}

.intra-workout-summary-overview-card-chart-header-see-more-container p {
	font-size: 12px;
	color: #5F6C76;
	text-align: right;
}

.intra-workout-summary-overview-card-info-container {
	width: 100%;
	height: 20%;
}

.intra-workout-summary-overview-card-info-container p {
	font-size: 12px;
}

.intra-workout-summary-overview-exercise-list-container {
	margin-top: 16px;
	width: 50%;
	padding: 0px 24px;
	height: calc(100vh - 243px);
	overflow-y: scroll;
	display: block;
	padding-bottom: 22px;
	padding-right: 0px;
}

.intra-workout-summary-overview-exercise-list-header-container {
	display: flex;
	width: 100%;
	margin-bottom: 12px;
}

.intra-workout-summary-overview-exercise-list-header-container h4 {
	margin: 0px;
	font-size: 16px;
	color: #C1C4CC;
	width: 50%;
}

.intra-workout-summary-overview-exercise-list-header-container p {
	margin: 0px;
	font-size: 12px;
	color: #5F6C76;
	text-align: right;
	width: 50%;
}

.intra-workout-summary-overview-chart-container {
	width: 100%;
	position: relative;
	margin: auto;
	height: 50%;
	margin-bottom: 0px;
}

.intra-workout-summary-overview-chart-container p {
	color: #5F6C76;
	margin-top: 40px;
	text-align: center;
	width: 100%;
}*/

