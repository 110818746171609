/* .goal-skill-column-action-card- */

.goal-skill-column-action-card {
	display: block;
	padding: 16px;
	background: #1F1F21;
    border-radius: 5px;
    border: solid;
    border-width: 1px;
    border-color: #2B2B30;
    box-shadow: 0 2px 12px 2px rgba(0, 0, 0, 0.1);
    margin-bottom: 12px;
    -webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
}
.goal-skill-column-action-card:hover {
	border-color: #6C6C6C;
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
}
	.goal-skill-column-action-card-header {
		margin-right: 12px;
		display: flex;
		padding-bottom: 8px;
	}
		.goal-skill-column-action-card-header h4 {
			font-size: 13.5px;
            color: #ffffff;
            margin: 0px;
            font-weight: 300;
		}
		.goal-skill-column-action-card-header p {
			font-size: 10px;
            color: #9DA0AD;
            margin: 0px;
            font-weight: 300;
            margin-left: auto;
            padding-top: 1px;
		}

	.goal-skill-column-action-card-contnet {

	}
		.goal-skill-column-action-card-content {
			display: flex;
		}

		.goal-skill-column-action-card-content-input-container {
                border-bottom: dotted;
                border-width: 2px;
                border-color: #4959C1;
                margin-bottom: 2px;
                width: 100%;
            }
                .goal-skill-column-action-card-content-input-container ion-textarea {
                    font-size: 13.5px;
                    color: #ffffff;
                    font-weight: 700;
                    min-height: 16px;
                    margin-top: 2px;
                    margin-bottom: 2px;
                    --placeholder-color: #5C6BC9;
                    --placeholder-opacity: 1.0;
                }
                .goal-skill-column-action-card-content-input-container ion-textarea .textarea-wrapper {
                    width: 100%;
                    min-height: 20px !important;
                }
                .goal-skill-column-action-card-content-input-container ion-textarea .textarea-wrapper .native-textarea {
                    width: 100%;
                    min-height: 20px !important;
                }



			.goal-skill-column-action-card-content p {
				font-size: 13.5px;
	            color: #9DA0AD;
	            margin: 0px;
			}

			.goal-skill-column-action-card-input-container-outter {
				position: relative;
				/*z-index: 10;*/
			}
				.goal-skill-column-action-card-input-container {
					border-bottom: dotted;
	                border-width: 2px;
	                border-color: rgba(112,138,240,1.0);
	                margin: 0px 4px;
	                cursor: pointer;
				}
					.goal-skill-column-action-card-input-container p {
						font-size: 13.5px;
			            color: rgba(112,138,240,1.0);;
			            margin: 0px;
			            -webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}
					.goal-skill-column-action-card-input-container:hover p {
			            color: rgba(129,160,255,1.0);
			            -webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}

					.goal-skill-column-action-card-input-container-unselected {
						border-color: #6C6C6C;
					}
						.goal-skill-column-action-card-input-container-unselected p {
							color: #6C6C6C;
						}
						.goal-skill-column-action-card-input-container-unselected:hover {
							cursor: default;
						}
						.goal-skill-column-action-card-input-container-unselected:hover p {
							color: #6C6C6C;
						}


				.goal-skill-column-action-card-selector-menu-container {
					position: absolute;
					background: #161825;
					z-index: 500;
					min-width: 200px;
					max-height: 300px;
					overflow-y: scroll;
					box-shadow: 0 2px 24px 6px rgba(0, 0, 0, 0.24);
					border-radius: 5px;
					border: solid;
					border-width: 0.5px;
					border-color: #2B2B30;
				}
					.goal-skill-column-action-card-selector-menu-container-metric {
						width: 500px;
						display: flex;
						left: -220px;
						overflow-y: hidden;
					}
					.goal-skill-column-action-card-selector-menu-metric-input-container {
						width: 60%;
						padding: 16px;

					}
						.goal-skill-column-action-card-selector-menu-metric-input-container p {
							font-size: 13.5px;
							margin-bottom: 12px;
						}
						.goal-skill-column-action-card-selector-menu-metric-input-container .goal-skill-card-header-input-container {
							width: 60%;
						}
					.goal-skill-column-action-card-selector-menu-metric-scroll-container {
						width: 40%;
						border-left: solid;
						border-width: 0.5px;
						border-color: #2B2B30;
						height: 300px;
						overflow-y: scroll;
					}




