


.copy-folder-to-client-modal-background {
	padding-top: 120px;
}
	.copy-folder-to-client-modal-container {
		height: calc(100vh - 240px);
		width: 540px;
	}
		.copy-folder-to-client-modal-container .copy-folder-modal-top-error {
			background: #161719;
			padding: 12px 0px;
		}

		.copy-folder-to-client-modal-body {
			width: 100%;
			height: calc(100% - 160px);
			overflow-y: scroll;
		}
		.copy-folder-to-client-modal-body-loading .client-list-container-loading-spinner {
			margin-top: 20vh !important;
		}
			.copy-folder-to-client-modal-body-loading p {
			    font-size: 13.5px;
			    color: #6C6C6C;
			    margin: 0px;
			    width: 100%;
			    text-align: center;
			    margin-top: 12px;
			}
	.copy-folder-to-client-modal-folder-container .assign-modal-client-folders-container {
		padding: 0px !important;
		width: 100% !important;
		min-width: 100%;
	}
	.copy-folder-to-client-modal-folder-container .assign-modal-client-folders-content {
		margin-top: 4px;
		
	}
	.copy-folder-to-client-modal-folder-container .assign-modal-client-folders-content-body {
		height: calc(100vh - 600px);
		min-height: 240px;
		overflow-y: scroll;
	}
