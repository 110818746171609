
.confirm-modal-container {
	height: 35%;
	/*min-height: 180px;*/
	margin: auto;
	margin-top: 0px;
	min-width: 400px;
	width: 50%;
	max-width: 500px;
	background: #161719;
	border-radius: 5px;
	border: solid;
	border-width: 0.5px;
	border-color: #2B2B30;
	/*overflow: hidden;*/
	box-shadow: 0 0px 100px 12px rgba(0, 0, 0, 0.5);
	position: relative;
	padding-bottom: 4px;
}
.confirm-modal-background {
	padding-top: calc(50vh - 200px);
}

.confirm-modal-top {
	width: 100%;
	height: calc(100% - 60px);
	padding: 36px;
	padding-top: 16px;
}

.confirm-modal-top h3 {
	margin-bottom: 8px;
	font-weight: 600;
	color: #ffffff;
	font-size: 18px;
}

.confirm-modal-top p {
	margin: 0px;
	color: #9DA0AD;
	font-size: 13.5px;
	line-height: 24px !important;
}

.confirm-modal-bottom {
	width: 100%;
	height: 60px;
	padding: 24px;
	padding-top: 3px;
	display: inline-flex;
	float: right;
}

.confirm-modal-close-button {
	font-size: 13.5px;
	float: left;
	border-radius: 5px;
	color: #C2C5CD;
	background: #1F1F21;
	padding: 6px 12px;
	margin-top: 4px;
	margin-left: auto;
	float: right;
	display: inline-block;
	cursor: pointer;
	margin-right: 18px;
	border: solid;
	border-width: 1px;
	border-color: #2B2B30;
	text-align: center;
	height: 30px;
	box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
}
	.confirm-modal-close-button:hover {
		background: #28282D;
		border-color: #3B3B46;
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}

.confirm-modal-cta-button {
	font-size: 14px;
	float: right;
	background-color: #5B62FF;
	border-radius: 5px;
	color: white;
	padding: 6px 12px;
	margin-top: 4px;
	display: inline-block;
	cursor: pointer;
	box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
	height: 30px;
}

.confirm-modal-cta-button-red {
	font-size: 13.5px;
	float: right;
	background-color: rgba(255,60,60);
	border-radius: 5px;
	height: 30px;
	color: white;
	text-align: center;
	padding: 6px 12px;
	margin-top: 4px;
	display: inline-block;
	cursor: pointer;
	box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
}
.confirm-modal-cta-button-red:hover {
	background-color: rgba(255,80,80);
	
}

.confirm-modal-floating-close {
	position: absolute;
	top: 18px;
	right: 24px;
	width: 30px;
	height: 30px;
	padding: 6px;
	color: #6C6C6C;
	cursor: pointer;
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
}

.confirm-modal-floating-close:hover {
	color: #ffffff;
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
}
