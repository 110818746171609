.modal-background {
	padding-top: 0px;
	overflow-y: hidden;
	width: 100%;
	height: 100%;
	min-height: 100%;	
	background: #02030566;
	vertical-align: middle;
	position: relative;
	-webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
}

.modal-scroller {
	height: 100%;
	overflow-y: hidden;
}

.modal-container {
	height: calc(100% - 64px);
	/*min-height: 540px;*/
	right: 50px;
	top: 32px;
	min-width: 600px;
	width: calc(100% - 100px);
	background: var(--ef-background);
	border-radius: var(--ef-radius-m);
	border: solid;
	border-width: 1px;
	border-color: var(--ef-border);

	/*border-bottom-right-radius: 0px;
	border-top-right-radius: 0px;*/
	overflow: hidden;
	box-shadow: -50px 0px 300px 100px rgba(0, 0, 0, 0.6);
	z-index: 500 !important;
	position: absolute;
	-webkit-transition: all 0.35s;
	-moz-transition: all 0.35s;
	transition: all 0.35s;
}
.modal-container-small {
	width: 600px;
	right: calc(50% - 300px);
	-webkit-transition: all 0.35s;
	-moz-transition: all 0.35s;
	transition: all 0.35s;
}
	.modal-container-overlay-1 {
		width: calc(100% - 150px);
		right: 50px;
		border-top-left-radius: 12px;
		border-bottom-left-radius: 12px;
		border-left: solid;
		border-top: solid;
		border-bottom: solid;
		border-width: 1px;
		border-color: #2B2B30;
	}
	.modal-container-overlay-2 {
		width: 88%;
		border-top-left-radius: 12px;
		border-bottom-left-radius: 12px;
	}

	.modal-container-depth-1 {
		top: 50px;
		left: 20px;
		position: absolute;
		z-index: 400 !important;
		height: calc(100% - 100px);
		/*min-height: 540px;*/
		min-width: 600px;
		width: calc(100% - 64px);
		/*background: #1F1F21;*/
		border-radius: 5px;
		/*border: solid;
		border-width: 1px;
		border-color: #2B2B30;*/
		overflow: hidden;
		/*box-shadow: 0 0px 100px 12px rgba(0, 0, 0, 0.35);*/
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
		cursor: pointer;
	}
	/*.modal-container-depth-1:hover {
		cursor: pointer;
		background: #28282D;
		left: 28%;
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}*/

	.modal-container-depth-2 {
		top: 0px;
		left: 6%;
		position: absolute;
		z-index: 410 !important;
		height: 100%;
		/*min-height: 540px;*/
		min-width: 600px;
		width: 94%;
		background: #1F1F21;
		/*border-radius: 5px;
		border: solid;
		border-width: 1px;
		border-color: #2B2B30;*/
		overflow: hidden;
		box-shadow: 0 0px 100px 12px rgba(0, 0, 0, 0.35);
		-webkit-backdrop-filter: blur(1px);
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
		cursor: pointer;
		/*top: 7%;
		left: 29%;
		position: absolute;
		z-index: 300 !important;
		height: 86%;
		min-width: 600px;
		width: 66.7%;
		background: #1F1F21;
		border-radius: 12px;
		border-bottom-right-radius: 0px;
		border-top-right-radius: 0px;
		overflow: hidden;
		box-shadow: 0 0px 100px 12px rgba(0, 0, 0, 0.35);*/
	}
	.modal-container-depth-2:hover {
		cursor: pointer;
		background: #28282D;
		left: 2%;
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}

.modal-content-container {
	height: 100%;
	z-index: 600 !important;
}

.modal-top {
	width: 100%;
	height: 80px;
	padding: 12px 32px;
}

.modal-bottom {
	width: 100%;
	height: 86px;
	padding: 18px 32px;
	border-top: solid;
	border-color: #393D54;
	border-width: 0.5px;
	display: none;
}

.modal-content {
	padding: 32px 32px;
	height: calc(100% - 160px);
	overflow-y: scroll;
}

.modal-back-button {
	font-size: 13.5px;
	border-radius: 5px;
	color: #9DA0AD;
	padding: 8px 18px;
	margin-top: 4px;
	width: 72px;
	margin-right: 12px;
	border: solid;
	border-width: 0.5px;
	background: #1F1F21;
	border-color: #2B2B30;
	box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
	-webkit-transition: all 0.15s;
  	-moz-transition: all 0.15s;
  	transition: all 0.15s;
}
.modal-back-button:hover {
	cursor: pointer;
	background: #28282D;
	color: #ffffff;
	-webkit-transition: all 0.15s;
  	-moz-transition: all 0.15s;
  	transition: all 0.15s;
}

.close-button {
	font-size: 14px;
	float: left;
	border-radius: 5px;
	color: #C2C5CD;
	padding: 8px 18px;
	margin-top: 4px;
	margin-left: auto;
	display: inline-block;
	cursor: pointer;
	margin-right: 18px;
}

.modal-cta-button {
	font-size: 13.5px;
	/*float: right;*/
	background-color: #4A5AC1;
	border-radius: 5px;
	color: white;
	padding: 8px 12px;
	margin-top: 4px;
	/*margin-left: auto;*/
	display: inline-block;
	box-shadow: 0 2px 6px 2px rgba(0, 0, 100, 0.1);
	-webkit-transition: all 0.15s;
  	-moz-transition: all 0.15s;
  	transition: all 0.15s;
}
.modal-cta-button:hover {
	cursor: pointer;
	background-color: #5C6BC9;
	-webkit-transition: all 0.15s;
  	-moz-transition: all 0.15s;
  	transition: all 0.15s;
}
	.modal-cta-button-unselected {
		background: rgba(0,0,0,0.08);
		box-shadow: 0 2px 2px 2px rgba(0, 0, 50, 0.05);
		color: #2B2B30;
		-webkit-transition: all 0.15s;
	  	-moz-transition: all 0.15s;
	  	transition: all 0.15s;
	}
	.modal-cta-button-unselected:hover {
		background: rgba(0,0,0,0.08);
		box-shadow: 0 2px 2px 2px rgba(0, 0, 50, 0.05);
		color: #2B2B30;
		cursor: default;
	}

	.modal-cta-button-skip {
		font-size: 13.5px;
		background-color: #161825;
		border-radius: 5px;
		color: #9DA0AD;
		padding: 8px 12px;
		margin-top: 4px;
		display: inline-block;
		border: solid;
		border-width: 0.5px;
		border-color: #2B2B30;
		box-shadow: 0 2px 6px 2px rgba(0, 0, 100, 0.1);
		-webkit-transition: all 0.15s;
	  	-moz-transition: all 0.15s;
	  	transition: all 0.15s;
	}
		.modal-cta-button-skip:hover {
			cursor: pointer;
			background: #1F1F21;
			-webkit-transition: all 0.15s;
		  	-moz-transition: all 0.15s;
		  	transition: all 0.15s;
		}

	.modal-cta-button-right {
		margin-left: auto;
		float: right;
	}
