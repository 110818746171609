/* .new-workout-nodes- */

.new-workout-nodes-container {
	width: 100%;
	height: 100%;
	display: flex;
}

.new-workout-nodes-info-container {
	width: 60%;
	height: 100%;
	padding: 12px 48px;
	position: relative;
}
	.new-workout-nodes-info-container h4 {
		font-size: 18px;
		color: #ffffff;
		margin-bottom: 4px;
	}
	.new-workout-nodes-info-container p {
		font-size: 13.5px;
		color: #9DA0AD;
		margin: 0px;
	}

.new-workout-nodes-info-text-container {
	width: 100%;
	padding-bottom: 12px;
	padding-top: calc(50vh - 250px);
}

.new-workout-nodes-info-text-container h4 {
	font-size: 18px;
	color: #ffffff;
	margin-bottom: 4px;
}

.new-workout-nodes-info-text-container p {
	font-size: 13.5px;
	color: #9DA0AD;
	margin: 0px;
	line-height: 18px;
	margin-top: 8px;
	max-width: 600px;
}

.new-workout-nodes-info-number-of-nodes-container {
	width: 100%;
	display: block;
}

.new-workout-nodes-info-number-of-nodes-cell {
	width: 100%;
	border-width: 0.5px !important;
	border: solid;
	border-radius: 4px;
	padding: 8px 24px;
	margin-top: 12px;
	font-size: 14px;
	text-align: center;
}

.new-workout-nodes-info-number-of-nodes-cell-selected {
	border-color: #5B62FF;
	background: rgba(73,89,193,0.06);
	color: #C1C4CC;
}

.new-workout-nodes-info-number-of-nodes-cell-unselected {
	border-color: #5F6C76;
	color: #5F6C76;
	background: rgba(0,0,0,0.03);
}

.new-workout-nodes-info-number-of-nodes-confirm-button {
	font-size: 13.5px;
	margin-right: 12px;
	margin-top: 24px;
	display: block;
	float: left;
	text-align: center;
	padding: 10px 24px;
	background-color: #5B62FF;
	color: white;
	border-radius: 4px;
	box-shadow: 0 4px 38px 0 rgba(0, 0, 200, 0.15);
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
}
	.new-workout-nodes-info-number-of-nodes-confirm-button:hover {
		background: #7278F2;
		cursor: pointer;
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}
.new-workout-nodes-info-continue-button {
	margin-top: 12px;
	margin-bottom: 12px;
	float: left;
	display: block;
	text-align: center;
	padding: 10px 24px;
	background-color: #5B62FF;
	font-size: 13.5px;
	color: white;
	border-radius: 4px;
	box-shadow: 0 4px 38px 0 rgba(0, 0, 200, 0.15);
	
}
	.new-workout-nodes-info-continue-button-final {
		position: absolute;
		top: calc(5vh + 8px);
		right: 48px;
	}

.new-workout-nodes-info-skip-button {
	font-size: 13.5px;
	margin-top: 24px;
	display: block;
	text-align: center;
	padding: 10px 24px;
	float: left;
	background: #1F1F21;
	border: solid;
	border-width: 0.5px;
	border-color: #2E3247;
	box-shadow: 0 2px 20px 0 rgba(5, 5, 23, 0.2);
	color: #9DA0AD;
	border-radius: 5px;
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
}
	.new-workout-nodes-info-skip-button:hover {
		background: #1F1F21;
		cursor: pointer;
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}

.new-workout-nodes-body-container {
	width: 40%;
	height: calc(100% - 50px);
	padding: 0px;
	border-left: solid;
	border-width: 1px;
	border-color: #2B2B30;
}

.new-workout-nodes-body-card {
	width: 100%;
	height: 100%;
	box-shadow: 0px 18px 24px 12px rgba(0, 0, 0, 0.1);
	margin-top: calc(50vh - 320px);
	height: 100%;
}

.new-workout-nodes-body-card h4 {
	color: #ffffff;
	font-size: 13.5px;
	margin-top: 0px;
	width: 100%;
	text-align: center;
}

.new-workout-body-image-container {
	position: relative;
	height: 100%;
	max-height: 500px;
	max-width: 238px;
	margin: auto;
	margin-top: 24px;
}

.new-workout-body-image {
	position: absolute;
	top: 0px;
	left: 0px;
	max-height: 500px;
	height: calc(100% - 24px);
}

.new-workout-nodes-info-list-container {
	width: 100%;
	height: calc(100% - 170px);
	overflow-y: scroll;
}

.new-workout-sync-nodes-header {
	widows: 100%;
	padding-bottom: 12px;
	padding-top: 5vh;
}

.new-workout-sync-nodes-header p {
	margin: 0px;
	font-size: 18px;
	color: #ffffff;
	padding-bottom: 0px;
}

.new-workout-sync-nodes-header h4 {
	font-size: 28px;
	color: white;
	margin: 0px !important;
}

.new-workout-nodes-circle-container {
	padding-top: 62px;
}

.new-workout-nodes-circle-container h3 {
	font-size: 16px;
	color: #13CDE7;
	width: 100%;
	text-align: center;
}

.new-workout-nodes-circle {
	margin: auto;
	width: 164px;
	height: 164px;
	background: #111920;
	border: solid;
	border-width: 2px;
	border-radius: 82px;
	z-index: 100;
	-webkit-transition: all 0.6s;
	-moz-transition: all 0.6s;
	transition: all 0.6s;
}

.new-workout-nodes-circle-blue {
	border-color: #13CDE7;
	box-shadow: 0px 2px 12px 24px rgba(19, 205, 231, 0.16);
	color: #13CDE7;
}

.new-workout-nodes-circle-dark {
	border-color: rgba(0,0,0,0);
	box-shadow: 0px 2px 12px 12px rgba(0, 0, 0, 0.08);
	color: #5F6C76;
}

.new-workout-nodes-circle-icon {
	margin-left: 68px;
	margin-top: 64px;
}
