

	.account-page-logout-button {
		box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
		background: #1F1F21;
		padding: 6px 12px;
		border-radius: 5px;
		border: solid;
		border-width: 0.5px;
		border-color: #2B2B30;
		float: left;
	}
	.account-page-logout-button:hover {
		background: #28282D;
		cursor: pointer;
	}
		.account-page-logout-button p {
			font-size: 13.5px;
			color: red;
			margin: 0px;
		}