.intra-workout-stats-card-graph {
	width: 100%;
	background: #1F1F21;
	height: 386px;/*298px;*/
	border-radius: 5px;
	margin: auto;
	margin-top: 0px;
	padding: 0px;
	box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.2);
	overflow-y: hidden;
	margin-bottom: 12px;
}

.intra-workout-stats-card-header {
	width: 100%;
	border-bottom: solid;
	border-width: 1px;
	border-color: #2B2B30;
	padding: 20px 24px;
}
	.intra-workout-stats-card-header h4 {
		font-size: 13.5px;
		color: #ffffff;
		margin: 0px;
		margin-top: 4px;
	}
	.intra-workout-stats-card-header p {
		font-size: 10px;
		color: #6C6C6C;
		margin: 0px;
	}

.intra-workout-stats-card-header-container-b {
	display: flex;
	width: 100%;
	padding: 8px 12px;
	border-bottom: solid;
	border-width: 1px;
	border-color: #202A34;
}

.intra-workout-stats-card-header-button-container {
	margin: auto;
	padding: 4px 12px;
}

.intra-workout-stats-card-header-button-container p {
	color: #5F6C76;
	font-size: 10px;
	margin: 0px;
}

.intra-workout-stats-card-header-button-container-selected {
	margin: auto;
	background: rgba(73,89,193,0.2);
	padding: 4px 12px;
	border-radius: 5px;
	color: #4959C1;
}

.intra-workout-stats-card-header-button-container-selected p {
	color: #4959C1 !important;
	font-size: 10px;
	margin: 0px;
}

.intra-workout-stats-card-content-container {
	width: 100%;
	
}

.intra-workout-stats-card-content-header-container {
	display: flex;
	padding: 16px;
}

.intra-workout-stats-card-content-header-content {
	width: 50%;
	padding-left: 8px;
}

.intra-workout-stats-card-content-header-content p {
	font-size: 10px;
	margin-left: 16px;
	margin-top: 0px;
	color: #5F6C76;
}

.intra-workout-stats-card-content-header-stat-container {
	display: flex;
}

.intra-workout-stats-card-content-header-stat-container h2 {
	margin: 0px;
	font-weight: 500;
	font-size: 18px;
	color: #ffffff;
}

.intra-workout-stats-card-content-header-stat-container p {
	margin-left: 6px;
	margin-top: 6px;
	color: #6C6C6C;
	font-size: 10px;
}

.intra-workout-stats-card-content-header-stat-circle-blue {
	width: 8px;
	height: 8px;
	border-radius: 4px;
	margin-top: 7px;
	margin-right: 8px;
	background: #4959C1;
}

.intra-workout-stats-card-content-header-stat-circle-red {
	width: 8px;
	height: 8px;
	border-radius: 4px;
	margin-top: 7px;
	margin-right: 8px;
	background: #F02E4A;
}

.intra-workout-stats-card-graph-chart-container {
	width: 100%;
	height: 228px;
}

.intra-workout-stats-card-graph-chart-no-data-container {
	/*position: absolute;*/
	width: 100%;
	top: 90px;
	height: 100%;
	padding: 0px 48px;
	padding-top: 100px;
}

.intra-workout-stats-card-graph-chart-no-data-container h3 {
	font-size: 13.5px;
	font-weight: 500;
	color: #ffffff;
	margin: 0px;
	margin-bottom: 4px;
	text-align: center;
	width: 100%;
}

.intra-workout-stats-card-graph-chart-no-data-container p {
	font-size: 10px;
	color: #6C6C6C;
	text-align: center;
	width: 100%;
	padding: 0px 20px;
}