.workout-header {
	display: flex;
}

.workout-cta-button {
	font-size: 14px;
	margin: auto 8px;
	margin-left: auto;
	float: right;
	display: inline-block;
	padding: 10px 24px;
	padding-right: 36px;
	background-color: #5B62FF;
	color: white;
	border-radius: 4px;
	position: relative;
	box-shadow: 0 4px 38px 0 rgba(0, 0, 200, 0.15);
}