/* .assign-modal-client-folders-  */

.assign-modal-client-folders {
	width: 100%;
	height: 100%;
}

	.assign-modal-client-folders-container {
		width: 60%;
		margin: 0px auto;
		min-width: 500px;
		padding: 24px;
	}
		.assign-modal-client-folders-header {
			width: 100%;
			position: relative;
		}
			.assign-modal-client-folders-header h4 {
			    font-size: 18px;
			    color: #ffffff;
			    margin: 0px;
			    margin-bottom: 4px;
			}
			.assign-modal-client-folders-header p {
			    font-size: 13.5px;
			    color: #9DA0AD;
			    margin: 0px;
			}
			.assign-modal-client-folders-header-back-button {
				position: absolute;
				top: 6px;
				left: -36px;
				height: 22px;
				width: 22px;
				border-radius: 5px;
				border: solid;
				border-width: 1px;
				border-color: #2B2B30;
				box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.12);
				background: transparent;
				padding: 2px 3px;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
			.assign-modal-client-folders-header-back-button:hover {
				background: #1F1F21;
				cursor: pointer;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
				.assign-modal-client-folders-header-back-button-icon {
					font-size: 14px !important;
					color: #6C6C6C;
				}
				.assign-modal-client-folders-header-back-button:hover .assign-modal-client-folders-header-back-button-icon {
					color: #ffffff;
				}
		.assign-modal-client-folders-content {
			margin-top: 32px;
			
			border-radius: 5px;
			border: solid;
			border-width: 1px;
			border-color: #2B2B30;
		}
			.assign-modal-client-folders-content-header {
				padding: 12px;
				border-bottom: solid;
				border-width: 1px;
				border-color: #2B2B30;
				background: #1F1F21;
				border-top-left-radius: 5px;
				border-top-right-radius: 5px;
				box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
			}
				.assign-modal-client-folders-content-header h4 {
				    font-size: 13.5px;
				    color: #ffffff;
				    margin: 0px;
				}
			.assign-modal-client-folders-content-body {
				padding: 12px;
				padding-bottom: 24px;
			}

