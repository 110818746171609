
.client-profile-content-container {
	width: 100%;
	height: calc(100% - 52px);
	display: flex;
}
	.client-profile-summary-container {
		width: 40%;
		display: block;
		height: calc(100vh - 62px);
		padding: 36px;
	}
		.client-profile-summary-profile-image-container {
			background-color: rgba(0,0,0,0.3);
			width: 220px;
			height: 220px;
			max-width: 220px;
			max-height: 220px;
			border-radius: 5px;
			overflow: hidden;
			margin-right: 16px;
			padding: 0px;
			box-shadow: 0 4px 38px 0 rgba(0, 0, 0, 0.3);
			position: relative;
		}
			.client-profile-summary-profile-image-container:hover img {
				opacity: 0.3;
				cursor: pointer;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
			.client-profile-summary-profile-image-container img {
				opacity: 1.0;
				z-index: 1;
				position: absolute;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
			.client-profile-summary-profile-image-container:hover .client-profile-summary-profile-image-edit {
				opacity: 1.0;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
			.client-profile-summary-profile-image-edit {
				opacity: 0.0;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
				position: absolute;
				width: 100%;
				height: 100%;
				z-index: 2;
				padding-top: 64px;
			}
			.client-profile-summary-profile-image-edit-no-hide {
				opacity: 1.0 !important;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
				position: absolute;
				width: 100%;
				height: 100%;
				z-index: 2;
				padding-top: 64px;
				cursor: pointer;
			}
				.client-profile-summary-profile-image-edit-circle {
					width: 52px;
					height: 52px;
					margin: 0px auto;
					border-radius: 26px;
					border: solid;
					border-color: #ffffff;
					border-width: 2px;
					padding: 14px;
				}
					.client-profile-summary-profile-image-edit-icon {
						font-size: 20px  !important;
						color: #ffffff !important;
					}
				.client-profile-summary-profile-image-edit p {
					width: 100%;
					text-align: center;
					font-size: 13.5px;
					color: #ffffff;
					margin: 0px;
					margin-top: 8px;
					font-weight: 500;
				}
				.client-profile-summary-profile-image-edit-no-hide p {
					width: 100%;
					text-align: center;
					font-size: 13.5px;
					color: #ffffff;
					margin: 0px;
					margin-top: 8px;
					font-weight: 500;
				}
			.client-profile-image {
				height: 100% !important;
				width: 400px !important;
				border-radius: 4px !important;
			}
		.client-profile-summary-header {
			padding-top: 24px;
		}
			.client-profile-summary-header h3 {
				font-size: 28px;
				color: #ffffff;
				margin: 0px;
			}
			.client-profile-summary-header p {
				font-size: 13.5px;
				color: #6C6C6C;
				margin: 0px;
				margin-top: 4px;
			}
		.client-profile-summary-client-type-container {
			padding: 12px 0px;
			padding-bottom: 40px;
			position: relative;
		}
			.client-profile-summary-client-type-content {
				display: flex;
				border: solid;
				border-width: 1px;
				border-color: #2B2B30;
				background: #1F1F21;
				border-radius: 5px;
				float: left;
				box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.12);
				padding: 4px 8px;
			}
			.client-profile-summary-client-type-content:hover {
				background: #1F1F21;
				cursor: pointer;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
				.client-profile-summary-client-type-content p {
				    font-size: 13.5px;
				    color: #ffffff;
				    margin: 0px;
				}
					.client-profile-summary-client-type-content-icon-container {

					}
						.client-profile-summary-client-type-content-icon {
							font-size: 15px !important;
							color: #7278F2;
							margin-left: 6px;
						}
			.client-profile-summary-client-type-menu {
				position: absolute;
				top: 44px;
				background: #1F1F21;
				border-radius: 5px;
				float: left;
				box-shadow: 0px 0px 12px 6px rgba(0, 0, 0, 0.12);
				padding: 16px 0px;
				width: 240px;
			}
				.client-profile-summary-client-type-menu-header {
					padding: 0px 16px;
					padding-bottom: 16px;
					margin-bottom: 6px;
					border-bottom: solid;
					border-width: 1px;
					border-color: #2B2B30;
				}
					.client-profile-summary-client-type-menu-header h4 {
					    font-size: 13.5px;
					    color: white;
					    margin: 0px;
					}
				.client-profile-summary-client-type-menu-content {
					padding: 0px 12px;
				}
					.client-profile-summary-client-type-menu-row {
						width: 100%;
						padding: 10px 4px;
						border-bottom: solid;
						border-width: 1px;
						border-color: #2B2B30;
						-webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}
					.client-profile-summary-client-type-menu-row:hover {
						background: #1F1F21;
						cursor: pointer;
						border-radius: 5px;
						-webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}
					.client-profile-summary-client-type-menu-row-no-border {
						border-bottom: none;
					}
						.client-profile-summary-client-type-menu-row p {
						    font-size: 13.5px;
						    color: #ffffff;
						    margin: 0px;
						}

		.client-profile-summary-contact-container {
			width: 100%;
			display: flex;
			padding-top: 12px;
		}
			.client-profile-summary-contact-icon-container {
				background: rgba(0,0,0,0.2);
				width: 42px;
				height: 42px;
				border-radius: 5px;
				margin-right: 12px;
				padding: 12px;
				padding-left: 14px;
			}
				.client-profile-summary-contact-icon-container-chat {
					background: rgba(73,89,193,0.2);
				}
				.client-profile-summary-contact-icon-container-email {
					background: rgba(73,193,115,0.2);
				}
				.client-profile-summary-contact-icon-container-phone {
					background: rgba(156,73,193,0.2);
				}
				.client-profile-summary-contact-icon-container-phone-unavailable {
					background: rgba(217,220,229,0.05);
				}

				.client-profile-summary-contact-icon {
					font-size: 15px !important;
				}
					.client-profile-summary-contact-icon-chat {
						color: rgba(73,89,193,1.0)!important;
					}
					.client-profile-summary-contact-icon-email {
						color: rgba(73,193,115,1.0)!important;
					}
					.client-profile-summary-contact-icon-phone {
						color: rgba(156,73,193,1.0)!important;
					}
					.client-profile-summary-contact-icon-phone-unavailable {
						color: rgba(217,220,229,0.1)!important;
					}

		.client-profile-summary-plan-container {
			width: 100%;
			height: calc(100% - 420px);
		}
			.client-profile-summary-plan-inner-container {
				height: 130px;
				padding-top: calc(100vh - 624px);
			}
				.client-profile-summary-plan-header-container {
					width: 100%;
				}
					.client-profile-summary-plan-header-container h4 {
						width: 100%;
						font-size: 13.5px;
						color: #ffffff;
						margin: 0px;
					}
				.client-profile-summary-plan-card-container {
					margin-top: 12px;
					padding: 16px;
					width: calc(100% - 24px);
					border-radius: 5px;
					background-color: #1F1F21;
					border: solid;
					border-width: 0.5px;
					border-color: #2B2B30;
					box-shadow: 0 4px 24px 6px rgba(0, 0, 0, 0.1);
				}
					.client-profile-summary-plan-card-header-container {

					}
						.client-profile-summary-plan-card-header-container h4 {
							font-size: 13.5px;
							color: #ffffff;
							margin: 0px;
						}
						.client-profile-summary-plan-card-header-container p {
							font-size: 10px;
							color: #9DA0AD;
							margin: 0px;
							margin-bottom: 4px;
						}

					.client-profile-summary-plan-card-completion-container {
						padding-top: 12px;
						display: flex;
					}
						.client-profile-summary-plan-card-completion-loading-bar-container {
							width: calc(100% - 110px);
							padding-top: 3px;
						}
							.client-profile-summary-plan-card-completion-loading-bar-outer {
								width: 100%;
								height: 6px;
								background: #1A1B2A;
								border-radius: 3px;
							}
								.client-profile-summary-plan-card-completion-loading-bar-inner {
									height: 6px;
									background: #4959C1;
									border-radius: 3px;
								}
						

						.client-profile-summary-plan-card-completion-loading-text-container {
							width: 100px;
							padding-left: 12px;
						}
							.client-profile-summary-plan-card-completion-loading-text-container p {
								font-size: 10px;
								color: #9DA0AD;
								margin: 0px;
							}

				.client-profile-summary-plan-card-container-no-plan {
					background: #1A1B2A;
					height: 100px;
					padding-top: 44px;
				}
					.client-profile-summary-plan-card-container-no-plan p {
						font-size: 10px;
						color: #ffffff;
						width: 100%;
						text-align: center;
					}

	.client-profile-outer-page-container {
		width: 60%;
		border-left: solid;
		border-width: 1px;
		border-color: #2B2B30;
		box-shadow: 0px 24px 32px 6px rgba(0, 0, 0, 0.12);
	}

.client-profile-inner-page-container {
	width: calc(100% + 36px);
	margin-left: -18px;
	padding: 0px 18px;
	height: calc(100vh - 52px);
	overflow-y: scroll;
}





