.create-workout-exercise-list-cell-video {
	width: 100%;
	height: 100%;
	border-radius: var(--ef-radius-m);
	background: var(--ef-button-dark);
	border-width: 1px;
	border: solid;
	border-color: var(--ef-border);
	box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.1);
}
	.create-workout-exercise-list-cell-video-container-inner {
		width: 100%;
		height: 100%;
		border-radius: var(--ef-radius-m);
		overflow: hidden;
	}
		.create-workout-exercise-list-cell-video-container-inner-loading {
			width: 100%;
			height: 100%;
			padding: 30px 4px;
		}
			.create-workout-exercise-list-cell-video-container-inner-loading p {
			    font-size: 10px !important;
			    color: var(--ef-text-dark);
			    margin: 0px;
			    width: 100%;
			    text-align: center;
			}
		.create-workout-exercise-list-cell-video-container-inner-video-container {
			width: 100%;
			height: 100%;
			position: relative;
		}
			.create-workout-exercise-list-cell-video-container-inner-video-container video {
				min-width: 100%;
				height: 100%;
				z-index: 0;
				position: relative;
			}

		.create-workout-exercise-list-cell-video-custom-button {
			position: absolute;
			z-index: 1;
			width: calc(100% - 8px);
			height: 12px;
			background: #000000A0;
			bottom: 4px;
			left: 4px;
			border-radius: 5px;
			height: 12px;
		}
			.create-workout-exercise-list-cell-video-custom-button p {
			    font-size: 10px;
			    color: var(--ef-text-light);
			    margin: 0px;
			    width: 100%;
			    text-align: center;
			}