.my-nodes-container {
	width: 100%;
	/*overflow-y: hidden;*/
	height: 100%;
	display: block;
}

.my-nodes-content-container {
	width: 100%;
	/*overflow-y: hidden;*/
	height: 100%;
	display: flex;
}
	.my-nodes-content-container-left {
		width: 40%;
	}

.my-nodes-empty-container {
	width: 100%;
	overflow-y: hidden;
	height: 100%;
	display: block;
	padding-top: calc(50vh - 200px);
}

.my-nodes-empty-container h2 {
	width: 100%;
	margin: 0px;
	text-align: center;
	font-size: 16px;
	color: #C1C4CC;
}

.my-nodes-empty-container p {
	width: 100%;
	margin: 4px 0px;
	text-align: center;
	font-size: 12px;
	color: #5F6C76;
}

.my-nodes-cta-button {
	font-size: 14px;
	margin-left: calc(50% - 80px);
	margin-top: 12px;
	display: inline-block;
	padding: 10px 24px;
	padding-right: 36px;
	background-color: #5B62FF;
	color: white;
	border-radius: 4px;
	position: relative;
	box-shadow: 0 4px 38px 0 rgba(0, 0, 200, 0.15);
}

.my-nodes-add-icon {
	position: absolute;
	top: 12px;
	right: 12px;
}

.my-nodes-set-list-container {
	width: 100%;
}

.my-nodes-node-list-container {
	
	width: 60%;
	height: 100%;
}

.my-nodes-node-list-container-card {
	padding: 22px 36px;
	padding-bottom: 28px;
	width: 100%;
	height: 100%;
	border-left: solid;
	border-width: 1px;
	border-color: #2B2B30;
	box-shadow: 0px 24px 32px 6px rgba(0, 0, 0, 0.12);
}

.my-nodes-node-list-header-container {
	display: flex;

}
.my-nodes-node-list-header-container h2 {
	display: flex;
	font-size: 28px;
	margin: 0px;
	color: #ffffff;
	width: 40%;
}

.my-nodes-node-list-header-button-container {
	width: 60%;
	display: flex;
	float: right;
}

.my-nodes-node-list-header-button-refresh {
	color: #4959C1;
	font-size: 12px;
	padding-left: 8px;
	padding-top: 2px;
	margin-left: auto;
	margin-right: 0px;
}

.my-nodes-node-list-header-button-ping-all {
	color: #4959C1;
	font-size: 12px;
	padding-left: 18px;
	padding-top: 2px;
	margin-left: auto;
	margin-right: 0px;
}

.my-nodes-node-list-header-button-forget {
	padding-left: 18px;
	padding-top: 2px;
	padding-right: 12px;
	font-size: 12px;
	color: #F02E4A;
}

.my-nodes-node-list-content {
	padding-top: 8px;
	width: 100%;
	height: 100%;
	overflow-y: scroll;
}

