/* .calendar-month-day-cell- */

.calendar-month-day-cell {
	margin-top: 8px;
	display: block;
	width: 100%;
	max-width: 100%;
	height: auto;/*200px;*/
	background: #1F1F21;
	border-radius: 5px;
	border: solid;
	border-width: 1px;
	border-color: #2B2B30;
	box-shadow: 0px 0px 8px 6px rgba(0, 0, 0, 0.08);
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
}
.calendar-month-day-cell:hover {
	cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
	background: #1F1F21;
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
}
	.calendar-month-day-cell-resting {
		background: transparent !important;
		border: none !important;
		box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.0);
	}
	.calendar-month-day-cell-small {
		height: auto;
	}
	.calendar-month-day-cell-dragging {
		background: #5B62FF;
		border-color: transparent;
	}	
	.calendar-month-day-cell-proposed {
		margin-top: 8px;
		width: 100%;
		height: 180px;
		border-radius: 5px;
		border: solid;
		border-width: 1px;
		border-color: #7278F2;
		box-shadow: 0px 0px 8px 6px rgba(0, 0, 0, 0.08);
		background: rgba(91, 98, 255, 0.4);
	}
	.calendar-month-day-cell-in-phase {
		background: #5B62FF;
		border-color: #7278F2;
	}
		.calendar-month-day-cell-in-phase .calendar-month-day-cell-content-container h4 {
			color: white !important;
		}
		.calendar-month-day-cell-in-phase .calendar-month-day-cell-content-row-container p {
			color: white !important;
			opacity: 0.8;
		}
		.calendar-month-day-cell-in-phase:hover {
			background: #7278F2;
		}

	.calendar-month-day-cell-inner {
		display: block;
		width: 100%;
		height: 100%;
		padding: 12px;
		padding-right: 0px;
		overflow: hidden;
	}
		.calendar-month-day-cell-scheduled-container {
			width: 100%;
			display: flex;
			white-space: nowrap;
		}
			.calendar-month-day-cell-scheduled-container p {
			    font-size: 12px;
			    color: #9DA0AD !important;
			    font-weight: 400 !important;
			    margin: 0px;
			}
			.calendar-month-day-cell-scheduled-nosched-circle {
				width: 8px;
				height: 8px;
				border-radius: 4px;
				background: #FF4D4D;
				margin-top: 3px;
				margin-right: 4px;
			}
			.calendar-month-day-cell-scheduled-sched-icon {
				font-size: 15px !important;
				color: #5B62FF;
				margin-right: 8px;
			}
		.calendar-month-day-cell-content-container {
			width: 100%;
			padding-top: 8px;
		}
			.calendar-month-day-cell-content-container h4 {
			    font-size: 16px;
			    color: #ffffff;
			    margin: 0px;
			}
		.calendar-month-day-cell-content-row-container {
			width: 100%;
			padding: 2px 0px;
			/*white-space: nowrap;*/
		}
			.calendar-month-day-cell-content-row-container p {
			    font-size: 13.5px;
			    color: #9DA0AD !important;
			    font-weight: 400 !important;
			    margin: 0px;
			}

	.calendar-month-day-cell-inner-rest {
		display: block;
		width: 100%;
		height: 100%;
		padding: 6px;
		padding-right: 0px;
		overflow: hidden;
		padding-top: 60px;
	}
		.calendar-month-day-cell-inner-rest p {
		    font-size: 13.5px;
		    color: #6C6C6C;
		    margin: 0px;
		}

	.calendar-month-day-cell-inner-loading {
		padding: 12px;
		padding-top: 40%;
	}
		.calendar-month-day-cell-inner-loading p {
		    font-size: 12px;
		    font-weight: 500;
		    color: #9DA0AD !important;
		    margin: 0px;
		    width: 100%;
		    text-align: center;
		    margin-top: 8px;
		}
		.calendar-month-day-cell-content-cyclephase-container {
			width: 100%;
			padding: 2px 0px;
		}	
			.calendar-month-day-cell-content-phase-container {
				display: flex;
				padding-top: 3px;
			}
				.calendar-month-day-cell-content-phase-container p {
				    font-size: 13.5px;
				    color: #ffffff !important;
				    margin: 0px;
				}
				.calendar-month-day-cell-content-phase-circle {
					background: #5B62FF;
					margin-top: 4px;
					height: 12px;
					width: 3px;
					margin-top: 2px;
					margin-right: 8px;
				}
				.calendar-month-day-cell-content-cycle-circle {
					background: #B8C149;
				}
	.calendar-month-day-cell-content-book-button-container {
		display: flex;
		margin-top: 4px;
		float: left;
		padding: 4px 4px;
		margin-left: -4px;
		border-radius: 5px;
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}
	.calendar-month-day-cell-small .calendar-month-day-cell-content-book-button-container {
		margin-top: 0px;
	}
	.calendar-month-day-cell-content-book-button-container:hover {
		background: rgba(0, 0, 0, 0.2);
		cursor: pointer !important;
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}
		.calendar-month-day-cell-content-book-button-container p {
		    font-size: 13.5px;
		    color: #5B62FF !important;
		    margin: 0px;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
		.calendar-month-day-cell-content-book-button-container:hover p {
			color: #7278F2 !important;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
		.calendar-month-day-cell-content-book-button-icon {
			margin-left: 4px;
			font-size: 14px !important;
			color: #5B62FF;
			margin-top: 1px;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
		.calendar-month-day-cell-content-book-button-container:hover .calendar-month-day-cell-content-book-button-icon {
			color: #7278F2;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}

	.calendar-month-day-cell-content-book-button-container-remove {
		opacity: 0.6;
	}
	.calendar-month-day-cell-content-book-button-container-remove:hover {
		opacity: 1.0;
		background: rgba(0, 0, 0, 0.5);
	}
		.calendar-month-day-cell-content-book-button-container-remove p {
			color: white !important;
		}
		.calendar-month-day-cell-content-book-button-icon-remove {
			color: white;
		}
			.calendar-month-day-cell-content-book-button-container-remove:hover .calendar-month-day-cell-content-book-button-icon-remove {
				color: #FF4D4D;
			}
			.calendar-month-day-cell-content-book-button-container-remove:hover p {
				color: #FF4D4D !important;
			}
		.calendar-month-day-cell-content-book-button-icon-left {
			margin-right: 4px;
			margin-left: 0px;
		}




