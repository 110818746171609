.standard-button-container {
	background-color: #5B62FF;
	padding: 12px 40px;
	border-radius: 5px;
	text-align: center;
	box-shadow: 0 4px 12px 6px rgba(0, 0, 20, 0.1);
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
}

.standard-button-container:hover {
	background-color: #7278F2;
	cursor: pointer;
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
}

.standard-button-container p {
	color: #ffffff;
	font-size: 13.5px;
	margin: 0px;
	font-weight: 500;
}

.standard-button-container-unselected {
	background-color: rgba(0,0,0,0.1);
	/*padding: 8px 40px;*/
	padding: 1px 40px;
	border-radius: 5px;
	text-align: center;
}

.standard-button-container-unselected p {
	color: #606C77;
	font-size: 14px;
	font-weight: 500;
}

.standard-button-container-large {
	/*padding: 16px 40px !important;*/
}