.current-exercise-modal-chart-graph {
	width: 100%;
	height: 100%;/*298px;*/
	border-radius: 5px;
	margin: auto;
	margin-top: 0px;
	padding: 0px;
	overflow-y: hidden;
}

.current-exercise-modal-chart-header {
	width: 100%;
	border-bottom: solid;
	border-width: 1px;
	border-color: #2B2B30;
	padding: 20px 24px;
}
	.current-exercise-modal-chart-header h4 {
		font-size: 13.5px;
		color: #ffffff;
		margin: 0px;
		margin-top: 4px;
	}
	.current-exercise-modal-chart-header p {
		font-size: 10px;
		color: #6C6C6C;
		margin: 0px;
	}

.current-exercise-modal-chart-header-container-b {
	display: flex;
	width: 100%;
	padding: 8px 12px;
	border-bottom: solid;
	border-width: 1px;
	border-color: #202A34;
}

.current-exercise-modal-chart-header-button-container {
	margin: auto;
	padding: 4px 12px;
}

.current-exercise-modal-chart-header-button-container p {
	color: #5F6C76;
	font-size: 10px;
	margin: 0px;
}

.current-exercise-modal-chart-header-button-container-selected {
	margin: auto;
	background: rgba(73,89,193,0.2);
	padding: 4px 12px;
	border-radius: 5px;
	color: #4959C1;
}

.current-exercise-modal-chart-header-button-container-selected p {
	color: #4959C1 !important;
	font-size: 10px;
	margin: 0px;
}

.current-exercise-modal-chart-content-container {
	width: 100%;
	
}

.current-exercise-modal-chart-content-header-container {
	display: flex;
	padding: 0px 16px;
	padding-top: 8px;
}

.current-exercise-modal-chart-content-header-content {
	width: 100%;
	padding-left: 8px;
}

	.current-exercise-modal-chart-content-header-content p {
		font-size: 10px;
		margin-left: 16px;
		margin-top: -8px !important;
		color: #ffffff;
	}

.current-exercise-modal-chart-content-header-stat-container {
	display: flex;
}
	.current-exercise-modal-chart-content-header-stat-container h2 {
		margin: 0px;
		font-weight: 500;
		font-size: 18px;
		margin-bottom: 8px;
		color: #ffffff;
	}
	.current-exercise-modal-chart-content-header-content .current-exercise-modal-chart-content-header-stat-container p {
		margin-left: 6px;
		margin-top: 6px !important;
		color: #6C6C6C;
		font-size: 10px;
	}

.current-exercise-modal-chart-content-header-stat-circle-blue {
	width: 8px;
	height: 8px;
	border-radius: 4px;
	margin-top: 7px;
	margin-right: 8px;
	background: #4959C1;
}

.current-exercise-modal-chart-content-header-stat-circle-red {
	width: 8px;
	height: 8px;
	border-radius: 4px;
	margin-top: 7px;
	margin-right: 8px;
	background: #F02E4A;
}

.current-exercise-modal-chart-graph-chart-container {
	width: 100%;
	height: 144px;
}

.current-exercise-modal-chart-graph-chart-no-data-container {
	/*position: absolute;*/
	width: 100%;
	top: 30px;
	height: 100%;
	padding: 0px 12px;
	padding-top: 70px;
}

.current-exercise-modal-chart-graph-chart-no-data-container h3 {
	font-size: 13.5px;
	font-weight: 500;
	color: #ffffff;
	margin: 0px;
	margin-bottom: 4px;
	text-align: center;
	width: 100%;
}

.current-exercise-modal-chart-graph-chart-no-data-container p {
	font-size: 10px;
	color: #6C6C6C;
	text-align: center;
	width: 100%;
	padding: 0px 4px;
}



