.create-plan-workout-configure-muscle-search-content {
	margin-top: 12px !important;
}


.create-plan-workout-configure-results-container {
	width: calc(100% + 36px);
	margin-left: -18px;
	padding: 0px 18px;
	height: calc(100% - 106px);
	
}
.create-plan-workout-configure-results-scroller-container {
		height: calc(100% - 80px);
/*		background: red;
*/		overflow-y: scroll;
}

.create-workout-exercise-configure-results-add-workout-button-container {
	box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
	position: relative;
	width: 100%;
	padding: 16px 20px;
	background: #181B2A;
	border: solid;
	border-width: 0.5px;
	border-color: #2E3247;
	border-radius: 5px;
	margin-bottom: 24px;
	margin-top: 12px;
	cursor: pointer;
}
	.create-workout-exercise-configure-results-add-workout-button-text-container {
		
	}
		.create-workout-exercise-configure-results-add-workout-button-text-container h4 {
			font-size: 13.5px;
			color: #ffffff;
			margin: 0px;
			margin-bottom: 3px;
			width: 100%; 
			text-align: center;
		}
		.create-workout-exercise-configure-results-add-workout-button-text-container p {
			font-size: 10px;
			color: #ffffff;
			margin: 0px;
			width: 100%; 
			text-align: center;
		}
	.create-workout-exercise-configure-results-add-workout-button-add-icon-container {
		position: absolute;
		width: 20px;
		height: 20px;
		top: 24px;
		right: 20px;
		border-radius: 10px;
		background: rgba(0,0,0,0.1);
		padding-top: 2px;
	}
		.create-workout-exercise-configure-results-add-workout-button-add-icon-container h5 {
			font-size: 13.5px;
			color: #ffffff;
			margin: 0px;
			width: 100%; 
			text-align: center;
		}

.create-plan-workout-configure-results-empty-container {
	padding-top: 30%;
	width: 100%;
	margin-left: 0px;
	display: block;
}
	.create-plan-workout-configure-results-empty-container h4 {
		font-size: 13.5px;
		color: #ffffff;
		margin: 0px;
		margin-bottom: 0px;
		width: 100%; 
		text-align: center;
	}
	.create-plan-workout-configure-results-empty-container p {
		font-size: 10px;
		color: #ffffff;
		margin: 0px;
		width: 67%; 
		margin: 6px auto;
		text-align: center;
	}
		.create-plan-workout-configure-results-empty-container .create-workout-exercise-list-empty-button-container p {
			width: 100%;
			margin: 2px 0px;
		}
.create-workout-exercise-configure-results-header-row-container {
	display: flex;
}

.create-plan-workout-configure-muscle-seletor-content {
	padding-top: 12px;
}
.create-plan-workout-configure-search-button {
	margin-top: 22px;
}

.create-plan-workout-configure-empty-button-container {
	width: 160px !important;
	min-width: 160px !important;
	margin: 24px auto !important;
	box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
}
	.reate-plan-workout-configure-empty-button-container p {
		width: 100%;
		text-align: center;
	}

