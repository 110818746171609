/* .new-plan-modal- */

.new-plan-modal {

}

.new-plan-header-icon-container {
	margin-top: 3px;
	width: 38px;
	height: 38px;
	border-radius: 5px;
	background: rgba(73,89,193,0.2);
	padding: 10px 10px;
}
	.new-plan-header-icon {
		font-size: 18px !important;
		color: #4959C1;
	}

	.new-plan-header-icon-container-first-screen {
		padding: 0px;
		margin: 0px;
		height: 40px;
		margin-top: calc(50vh - 210px);
	}
	.new-plan-header-icon-container-first-screen .new-plan-header-icon {
		color: white;
		font-size: 24px !important;
	}

.new-plan-header-container-coloured {
	background: #4959C1;
	-webkit-transition: all 0.35s;
	-moz-transition: all 0.35s;
	transition: all 0.35s;
}
.new-plan-header-container {
	-webkit-transition: all 0.35s;
	-moz-transition: all 0.35s;
	transition: all 0.35s;
}
/*
.new-plan-header-icon-container {
	margin-top: 3px;
	margin-left: 12px;
	width: 38px;
	height: 38px;
	border-radius: 5px;
	background: rgba(73,89,193,0.2);
	padding: 9px 9px;
}
	.new-plan-header-icon {
		font-size: 18px !important;
		color: #4959C1;
	}*/

	.new-plan-modal-folder-selector-container {
		width: 100%;
		overflow-y: scroll;
		height: calc(100vh - 560px);
		max-height: 500px;
		min-height: 240px;
		margin-top: -12px;
		border-radius: 5px;
		border: solid;
		border-width: 1px;
		border-color: #2B2B30;
		box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
	}
		.new-plan-modal-folder-selector-text-container {
			width: 100%;
			padding: 12px 0px;
		}
			.new-plan-modal-folder-selector-text-container h4 {
			    font-size: 13.5px;
			    color: #7278F2 !important;
			    margin: 0px;
			}
	.create-plan-modal-forced-page-spacer {
		height: calc(100vh - 440px);
		width: 100%;
	}

	.new-phase-periodization-header {
		margin: 8px 0px;
		padding: 20px 0px;
		margin-top: 12px;
		border-bottom: solid;
		border-width: 1px;
		border-color: #2B2B30;
	}
		.new-phase-periodization-header h4 {
		    font-size: 18px;
		    color: #ffffff;
		    margin: 0px;
		}







