.new-exercise-content-container {
	width: 60%;
	max-width: 700px;
	min-width: 500px;
	margin: 0px auto;
	height: calc(100% - 58px);
	overflow-y: visible;
	padding: 0px;
	position: static;
}
.new-exercise-modal-container {
	position: relative;
}

.new-exercise-header-container-coloured {
	background: #49BCC1;
	-webkit-transition: all 0.35s;
	-moz-transition: all 0.35s;
	transition: all 0.35s;
}
.new-exercise-header-icon-container {
	margin-top: 3px;
	margin-left: 12px;
	width: 38px;
	height: 38px;
	border-radius: 5px;
	background: rgba(73,188,193,0.2);
	padding: 10px 10px;
	-webkit-transition: all 0.35s;
	-moz-transition: all 0.35s;
	transition: all 0.35s;
}
	.new-exercise-header-icon {
		font-size: 18px !important;
		color: #49BCC1;
	}
	.new-exercise-header-icon-container-first-screen {
		padding: 0px;
		margin: 0px;
		height: 40px;
		margin-top: calc(50vh - 210px);
	}
	.new-exercise-header-icon-container-first-screen .new-exercise-header-icon {
		color: white !important;
		font-size: 24px !important;
	}

.new-exercise-header-icon-container-previous-container {
	position: absolute;
	padding-top: 16px;
	left: 16px;
	display: flex;
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
}
.new-exercise-header-icon-container-previous-container:hover {
	left: 0px;
	padding-top: 2px;
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
	cursor: pointer;
}
	.new-exercise-header-icon-container-workout {
		width: 12px;
		height: 12px;
		border-radius: 5px;
		background: rgba(60,193,73,1.0);
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}

	.new-exercise-header-icon-container-previous-container:hover .new-exercise-header-icon-container-workout {
		width: 38px;
		height: 38px;
		padding: 10px;
		background: rgba(60,193,73,0.2);
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}

	.new-exercise-header-icon-container-workout {
		
	}
	.new-exercise-header-icon-container-previous-container:hover .new-exercise-header-icon-container-workout .new-exercise-header-icon-workout {
		opacity: 1.0;
	}
		.new-exercise-header-icon-workout {
			opacity: 0.0;
			font-size: 16px !important;
			color: #49C16B;
		}
	.new-exercise-header-back-icon-container {
		width: 10px;
		height: 10px;
		margin-top: -1px;
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}
	.new-exercise-header-icon-container-previous-container:hover .new-exercise-header-back-icon-container {
		margin-top: 12px;
		margin-left: -5px;
		width: 4px;
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}
		.new-exercise-header-back-icon {
			font-size: 15px !important;
			color: #6C6C6C;

		}

.new-exercise-modal-exercise-search-result-container {
	width: 100%;
	margin-top: -4px;
	padding-bottom: 12px;
	min-height: 32px;
}
	.new-exercise-modal-exercise-search-result-container-results {
		width: 100%;
		display: flex;
		padding-top: 2px;
	}
		.new-exercise-modal-exercise-search-result-container-results p {
			font-size: 12px;
			color: #F02E4A;
			margin:  0px;
			margin-left: 8px !important;
			margin-top: 2px !important;
		}
		.new-exercise-modal-exercise-search-result-container-results-results-icon-container {
			width: 18px;
			height: 18px;
			border-radius: 5px;
			background: rgba(193,50,50,0.2);
			padding: 2px 7.5px;
		}
			.new-exercise-modal-exercise-search-result-container-results-results-icon {
				color: #F02E4A;
				font-size: 12px;
				margin: 0px;
			}

			.new-exercise-modal-exercise-search-result-container-results-expand-results-container {
				float: right;
				margin-left: auto;
				display: flex;
				
				-webkit-transition: all 0.15s;
			  	-moz-transition: all 0.15s;
			  	transition: all 0.15s;
			}
			.new-exercise-modal-exercise-search-result-container-results-expand-results-container:hover {
				cursor: pointer;
				-webkit-transition: all 0.15s;
			  	-moz-transition: all 0.15s;
			  	transition: all 0.15s;
			}
				.new-exercise-modal-exercise-search-result-container-results-expand-results-container p {
					color: #6C6C6C !important;
				}
				.new-exercise-modal-exercise-search-result-container-results-expand-results-icon-container {
					padding-left: 8px;
				}
					.new-exercise-modal-exercise-search-result-container-results-expand-results-icon {
						font-size: 12px !important;
						color: #6C6C6C !important;
					}


		.new-exercise-modal-searching-exercises-cell {
			width: 100%;
			display: flex;
			padding: 4px 0px;
			padding-top: 8px;
			border-bottom: solid;
			border-width: 1px !important;
			border-color: #2B2B30 !important;
		}
			.new-exercise-modal-searching-exercises-cell-col {
				display: block;
				padding-right: 16px;
				width: 20%;
				overflow-x: hidden;
			}
			.new-exercise-modal-searching-exercises-cell-col-large {
				width: 40%;
			}
			.new-exercise-modal-searching-exercises-cell-col h4 {
				font-weight: 500;
				width: 100%;
				overflow: hidden;
				font-size: 13.5px;
				color: #ffffff;
				text-align: left;
				margin: 0px;
			}

			.new-exercise-modal-searching-exercises-cell-col p {
				font-size: 12px;
				font-size: 10px;
				color: #9DA0AD;
				height: 16px;
				width: 100%;
				overflow: hidden;
			}

		.new-exercise-modal-searching-exercises-cell-container {
			width: 100%;
			padding-top: 12px;
			
			
			background: rgba(0,0,0,0.3);
			box-shadow: 0 0px 24px 2px rgba(0, 0, 0, 0.1);
			border-radius: 5px;
			padding: 12px 20px;
		}
			.new-exercise-modal-searching-exercises-cell-content-container {
				width: 100%;
				max-height: 200px;
				overflow-y: scroll;
				margin-top: 12px;
			}
			.new-exercise-modal-searching-exercises-cell-container p {
				margin: 0px ;
			}
				.new-exercise-modal-searching-exercises-cell-container .exercise-cell-info-card-col-video{
					display: none;
				}

			.new-exercise-modal-searching-exercises-to-library-button {
				background: rgba(0,0,0,0.3);
				box-shadow: 0 0px 24px 2px rgba(0, 0, 0, 0.1);
				border-radius: 5px;
				width: 100%;
				padding: 10px;
				font-weight: 500;
				margin: 12px 0px;
				-webkit-transition: all 0.15s;
			  	-moz-transition: all 0.15s;
			  	transition: all 0.15s;
			}
			.new-exercise-modal-searching-exercises-to-library-button:hover {
				background: rgba(0,0,0,0.2);
				box-shadow: 0 1px 12px 6px rgba(0, 0, 0, 0.06);
				cursor: pointer;
				-webkit-transition: all 0.15s;
			  	-moz-transition: all 0.15s;
			  	transition: all 0.15s;
			}
				.new-exercise-modal-searching-exercises-to-library-button p {
					font-size: 13.5px;
					font-weight: 300;
					color: #ffffff;
					margin: 0px;
					width: 100%;
					text-align: center;
				}

	.new-exercise-modal-exercise-search-result-container-results-empty {
		width: 100%;
		display: flex;
	}
		.new-exercise-modal-exercise-search-result-container-results-empty p {
			font-size: 12px;
			color: #4959C1;
			margin:  0px;
			margin-left: 8px;
			margin-top: 2px;
		}
		.new-exercise-modal-exercise-search-result-container-results-empty-icon-container {
			width: 18px;
			height: 18px;
			border-radius: 5px;
			background: rgba(73,89,193,0.2);
			padding: 0px 3px;
		}
			.new-exercise-modal-exercise-search-result-container-results-empty-icon {
				color: #4959C1;
				font-size: 12px !important;
				margin: 0px;
			}



.new-exercise-modal-searching-exercises-loading-container {
	width: 100%;
	padding-top: 100px;
}
	.new-exercise-modal-searching-exercises-loading-container h4 {
		margin: 0px;
		color: #ffffff;
		font-size: 13.5px;
		width: 100%;
		text-align: center;
	}
	.new-exercise-modal-searching-exercises-loading-container p {
		margin: 0px;
		color: #9DA0AD;
		font-size: 10px;
		width: 100%;
		text-align: center;
	}


.new-exercise-modal-muscle-selector-container {
	width: 100%;
	margin-top: -12px;
}
.new-exercise-modal-selected-muscles-container {
	padding-top: 12px;
	width: 100%;
	display: inline-block;
}
	.new-exercise-modal-selected-muscle-content-container {
		font-size: 10px;
		color: #4959C1;
		background: rgba(73,89,193,0.2);
		padding: 6px 8px;
		border-radius: 5px;
		margin-right: 8px;
		margin-bottom: 8px;
		display: inline-block;
		box-shadow: 0 2px 6px 2px rgba(0, 0, 50, 0.1);
	}

	.new-exercise-modal-selected-muscle-content-container-unselected {
		font-size: 10px;
		color: #6C6C6C;
		background: rgba(255,255,255,0.04);
		padding: 6px 8px;
		border-radius: 5px;
		margin-right: 8px;
		margin-bottom: 8px;
		display: inline-block;
	}
		.new-exercise-modal-selected-muscle-content-container-unselected p {
			margin: 0px;
		}

		.new-exercise-modal-selected-muscle-content-container-unselected-alert {
			font-size: 10px;
			color: #F02E4A;
			border-color: #F02E4A;
			border-width: 1px !important;
			border: solid;
			background: rgba(255,0,0,0.12);
		}

.new-exercise-modal-select-row {
	width: 100%;
	display: flex;
}
	.new-exercise-modal-select-container {
		width: 100%;
		margin-right: 12px;
		margin-bottom: 24px;
	}
		.new-exercise-modal-select-container h5 {
			margin: 0px;
			font-size: 10px;
			color: #ffffff;
			margin-bottom: 4px;
		}

		.new-exercise-modal-select-custom-input-container {
			padding: 4px 0px;
			padding-left: 24px;
			height: 80px;
			margin-top: 24px;
		}
			.new-exercise-modal-select-custom-input-container-inner {
				border-bottom: solid;
				border-width: 1px;
				border-color: #2B2B30;
				padding-bottom: 8px;
			}

.new-exercise-modal-setup-input-container {
	width: 100%;
	height: 120px;
	margin-top: -8px;
	margin-bottom: 0px;
}
.new-exercise-add-step-container {
	width: 100%;
	display: flex;
}
	.new-exercise-add-step-input-container {
		width: calc(100% - 112px);
		height: 38px;
		margin-top: 10px;
		background-color: rgba(0,0,0,0.1);
		background-color: none;
		padding: 0px 12px;
		border-radius: 4px;
		border: solid;
		border-color: #2B2B30;
		border-width: 1px !important;
		font-size: 13.5px;
		color: #ffffff;
	}

	.new-exercise-add-step-input-button {
		width: 100px;
		margin-left: 12px;
		background: #4959C1;
		border-radius: 5px;
		padding: 12px 0px;
		height: 38px;
		margin-top: 10px;
		box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
	}
	.new-exercise-add-step-input-button:hover {
		background: #5C6BC9;
		cursor: pointer;
	}
		.new-exercise-add-step-input-button p {
			width: 100%;
			text-align: center;
			font-size: 10px;
			margin: 0px;
			color: #ffffff;
		}
	.new-exercise-add-step-input-button-unselected {
		background: rgba(255,255,255,0.04);
	}
	.new-exercise-add-step-input-button-unselected:hover {
		background: rgba(255,255,255,0.04);
		/*cursor: default;
*/	}
		.new-exercise-add-step-input-button-unselected p {
			color: #6C6C6C;
		}

	.new-exercise-steps-container {

	}
		.new-exercise-steps-content {
			width: 100%;
			display: flex;
			background: #1F1F21;
			padding: 10px 20px;
			padding-bottom: 14px;
			border-radius: 5px;
			box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
			margin-bottom: 8px;
		}
		.new-exercise-steps-content-number-container {
			width: 30px;
			padding-top: 4px;
		}
			.new-exercise-steps-content-number-container h4 {
				margin: 0px;
				color: #9DA0AD;
				font-size: 13.5px;
			}
		.new-exercise-steps-content-text-container {
			width: calc(100% - 50px);
			padding-top: 5px;
		}
			.new-exercise-steps-content-text-container p {
				margin: 0px;
				color: #ffffff;
				font-size: 13.5px;
			}
		.new-exercise-steps-content-remove-container {
			width: 20px;
			cursor: pointer;
		}
			.new-exercise-steps-content-remove-icon {
				font-size: 10px !important;
				color: #6C6C6C !important;
			}

	.new-exercise-steps-container-empty {
		width: 100%;
		padding-top: 32px;
	}
		.new-exercise-steps-container-empty p {
			width: 100%;
			text-align: center;
			font-size: 10px;
			color: #6C6C6C;
		}

	.new-exercise-button {
		padding: 2px 32px;
		margin: 0px auto;
		margin-top: 12px;
		border-radius: 5px;

		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}
	.new-exercise-button:hover {
		cursor: pointer;
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}

		.new-exercise-button p {
			font-size: 10px;
			margin: 0px;
			margin-bottom: 7px;
			color: #ffffff;
			width: 100%;
			text-align: center;
		}

		.new-exercise-make-another-button {
			margin-top: 24px;
			background: #4959C1;
			width: 180px;
			box-shadow: 0 0px 10px 0 rgba(0, 0, 100, 0.1);
		}
		.new-exercise-to-library-button {
			background: rgba(0,0,0,0.3);
			width: 180px;
			box-shadow: 0 0px 10px 0 rgba(0, 0, 100, 0.1);
		}
		.new-exercise-to-library-button:hover {
			background: rgba(0,0,0,0.2);

		}

.modal-cta-button-library {
	margin-right: 8px;
	background: rgba(0,0,0,0.3);
}






