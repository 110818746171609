.node-set-cell {
	width: 100%;
	display: block;
	border-bottom: solid;
	border-width: 1px;
	border-color: #2B2B30;
	padding-bottom: 16px;
}
.node-set-cell-container {
	width: 100%;
	padding: 16px 22px;
	display: flex;
	margin-bottom: 12px;
	position: relative;
	-webkit-transition: all 0.1s;
	-moz-transition: all 0.1s;
	transition: all 0.1s;
}

.node-set-cell-container-low-opacity {
	opacity: 0.5;
	-webkit-transition: all 0.1s;
	-moz-transition: all 0.1s;
	transition: all 0.1s;
}

.node-set-cell-container-green {
	border-color: #49C16B !important;
	box-shadow: 0px 0px 16px rgba(73, 193, 107, 0.56) !important;
	-webkit-transition: all 0.05s;
	-moz-transition: all 0.05s;
	transition: all 0.05s;
}

.node-set-cell-container-selected {
	background: #1F1F21;
}

.node-set-cell-container-unselected {

}

.node-set-cell-info-container {
	display: flex;
	width: calc(100% - 80px);
}

.node-set-cell-buttons-container {
	display: block;
	width: 80px;
}

.node-set-cell-button {
	margin-top: -4px;
	width: 24px;
	height: 24px;
	margin-left: auto;
	float: right;
	text-align: right;
	font-size: 12px;
	border: solid;
	border-width: 1px;
	border-color: #2B2B30;
	border-radius: 5px;
	padding: 2px 2px;
}
	.node-set-cell-button-icon {
		font-size: 18px !important;
		color: #6C6C6C;
	}
	.node-set-cell-menu {
		top: 42px;
		right: 24px;
	}

.node-set-cell-button-blue p {
	color: #4959C1;
}

.node-set-cell-button-red p {
	color: #F02E4A;
}

.node-set-cell-info-title-container {
	display: flex;
	white-space: nowrap;
	padding-top: 2px;
}

.node-set-cell-info-title-container h2 {
	color: #ffffff;
	font-size: 13.5px;
	font-weight: 500;
	margin: 0px;
}

.node-set-cell-info-node-connected-container {
	display: block;
	width: 100%;
	
}

.node-set-cell-info-node-connected-container p {
	font-size: 13.5px;
	color: #5B62FF;
	padding-left: 6px;
	margin: 0px;
}

.node-set-cell-info-node-connected-cirlces-container {
	display: none;
}

.node-set-cell-info-node-connected-cirlce {
	height: 12px;
	width: 12px;
	border-radius: 6px;
	margin-right: 6px;
}

.node-set-cell-info-node-connected-cirlce-connected {
	background: #F02E4A;
}

.node-set-cell-info-node-connected-cirlce-notconnected {
	background: #5F6C76;
	opacity: 0.4;
}

.node-set-cell-nodes-list-container {
	width: 100%;
}
	.node-set-cell-nodes-cell {
		width: 100%;
		display: flex;
		padding: 4px 24px;
	}	
		.node-set-cell-nodes-cell-circle {
			width: 10px;
			height: 10px;
			border-radius: 5px;
			background: #5B62FF;
			margin-right: 12px;
			margin-top: 2px;
		}
		.node-set-cell-nodes-cell-name {

		}
			.node-set-cell-nodes-cell-name h4 {
			    font-size: 13.5px;
			    color: #ffffff;
			    margin: 0px;
			}







