.new-workout-client-card-container {
	width: 100%;
	border-radius: 5px;
	background-color: #1F1F21;
	/*background-color: rgba(0,0,0,0.3);*/
	box-shadow: 0 4px 24px 6px rgba(0, 0, 0, 0.1);
	padding: 8px 16px;
	display: flex;
	margin-bottom: 12px;
}

.new-workout-client-card-image-container {
	height: 50px;
	width: 50px;
	margin-right: 24px;
}

.new-workout-client-name-container {
	height: 100%;
	width: calc(25%);
	display: block;
	padding-top: 8px;
}
	.new-workout-client-name-container h2 {
		margin: 0px;
		font-size: 13.5px;
		color: #ffffff;
	}
	.new-workout-client-name-container p {
		margin: 0px;
		font-size: 10px;
		color: #9DA0AD;
	}

.new-workout-client-name-tag-container {
	color: #E5E5E5;
	padding-top: 4px;
	display: flex;
	margin-top: 4px;
}

.new-workout-client-info-tag-container {
	color: #E5E5E5;
	padding-top: 4px;
	display: flex;
	margin-top: 18px;
}

.new-workout-client-name-tag-icon {
	color: #5F6C76;
	margin-top: 6px;
}

.new-workout-client-info-tag-icon {
	color: #5F6C76;
	margin-top: 6px;
}

.new-workout-client-name-tag-content {
	color: #5B62FF;
	padding: 6px 12px;
	border-radius: 4px;
	background-color: #242C45;
	margin-left: 8px;
	font-size: 12px;
}

.new-workout-client-info-tag-content {
	color: #5B62FF;
	padding: 6px 12px;
	border-radius: 4px;
	background-color: #242C45;
	margin-left: 8px;
	font-size: 12px;
}

.new-workout-client-info-container {
	padding-top: 8px;
	height: 100%;
	width: calc(25% - 17px);
	position: relative;
}

	.new-workout-client-info-container-2 {
		width: calc(50% - 35px);
	}
	.new-workout-workout-exercise-tag-container h4 {
		margin: 0px !important;
		font-size: 13.5px !important;
		color: #ffffff;
		margin-top: -8px;
		padding-top: 0px;
	}

.new-workout-client-info-container h2 {
	margin: 0px !important;
	font-size: 13.5px !important;
	color: #ffffff;
}

.new-workout-client-info-container p {
	margin: 0px;
	font-size: 10px;
	color: #6C6C6C;
}

.new-workout-client-info-more-info-container {
	position: absolute;
	top: 0px;
	right: 12px;
}

.new-workout-client-info-more-info-container p {
	font-size: 14px;
	color: #5F6C76;
}

.new-workout-client-profile-general-profile-circle {
	background-color: rgba(0,0,0,0.3);
	height: 36px;
	width: 36px;
	border-radius: 18px;
	margin: 0px 0px;
	margin-top: 7px;
	overflow: hidden;
	margin-left: 4px;
}

.new-workout-client-profile-general-image {
	display: block;
  	max-width: 100px;
  	max-height: 36px;
  	width: auto;
  	height: auto;
}