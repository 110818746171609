.node-stream-analysis-average-rep-container {
	width: 100%;
	height: 100%;
	background: #090A0D;
	border-radius: 12px;
	box-shadow: 0px 0px 12px 6px rgba(0,0,0,0.1);
	padding: 24px 20px;
}
	.node-stream-analysis-average-rep-header {
		width: 100%;
		display: flex;
	}
		.node-stream-analysis-average-rep-header-inner {

		}
			.node-stream-analysis-average-rep-header-inner h4 {
			    font-size: 13.5px;
			    color: #ffffff;
			    margin: 0px;
			}

			.node-stream-analysis-average-rep-header-inner p {
			    font-size: 13.5px;
			    color: #9DA0AD;
			    margin: 0px;
			    margin-top: 4px;
			}
		.node-stream-analysis-average-rep-header-button {
			float: right;
			margin-left: auto;
			height: 26px !important;
		}

	.node-stream-analysis-average-rep-body-loading {
		width: 100%;
		align-items: center;
		justify-content: center;
		padding-top: 120px;
	}
		.node-stream-analysis-average-rep-body-loading p {
		    font-size: 13.5px;
		    color: #6C6C6C;
		    margin: 0px;
		    width: 100%;
		    text-align: center;
		    margin-top: 8px;
		}
	.node-stream-analysis-average-rep-body {
		width: 100%;
		height: 280px;
		margin-top: 20px;
		background: #00000000;
	}
		.node-stream-analysis-average-rep-body-inner {
			width: 100%;
			height: 100%;
		}
			.node-stream-analysis-average-rep-management-container {

			}
				.node-stream-analysis-average-rep-management-container .node-stream-analysis-upper-params-body-col-title {
					width: 260px;
				}
				.node-stream-analysis-average-rep-management-container .node-stream-analysis-upper-params-body-input-container {
					width: 100%;
					margin-left: auto;
					float: right;
				}
				.node-stream-analysis-average-rep-management-container .node-stream-analysis-upper-params-body-col-min {
					width: 120px;
					margin-left: auto;
					float: right;
				}

			.node-stream-analysis-average-rep-prep-delete-button {
				margin: 12px 0px;
			    padding: 4px 8px;
			    background: #1F1F21;
			    border: solid;
			    border-width: 1px;
			    border-color: #2B2B30;
			    display: flex;
			    border-radius: 5px;
			    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.12);
			    -webkit-transition: all 0.15s;
			    -moz-transition: all 0.15s;
			    transition: all 0.15s;
				margin-left: auto;
				float: right;
			}
			.node-stream-analysis-average-rep-prep-delete-button:hover {
			    background: #1F1F21;
			    cursor: pointer;
			    -webkit-transition: all 0.15s;
			    -moz-transition: all 0.15s;
			    transition: all 0.15s;
			}	
			.node-stream-analysis-average-rep-prep-delete-button-invalid {
				opacity: 0.3;
			}
			.node-stream-analysis-average-rep-prep-delete-button-valid {
				background: #FF4D4D;
			}
			.node-stream-analysis-average-rep-prep-delete-button-valid:hover {
			    background: #FF6D6D;
			    cursor: pointer;
			    -webkit-transition: all 0.15s;
			    -moz-transition: all 0.15s;
			    transition: all 0.15s;
			}	
			.node-stream-analysis-average-rep-prep-delete-button-invalid:hover {
			    background: #1F1F21;
			    cursor: not-allowed;
			    -webkit-transition: all 0.15s;
			    -moz-transition: all 0.15s;
			    transition: all 0.15s;
			}
				.node-stream-analysis-average-rep-delete-container {
					width: 100%;
					display: flex;
				}
					.node-stream-analysis-average-rep-delete-input-container {
						background: #00000040;
						border-radius: 5px;
						border: solid;
						border-width: 1px;
						border-color: #2B2B30;
						box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.08);
						width: 200px;
						margin-right: 24px;
					}
					.node-stream-analysis-average-rep-delete-input-container .native-input {
							padding: 15px 12px;
						}
					.node-stream-analysis-average-rep-delete-input-container h4 {
						font-size: 16px;
						margin: 0px;
						padding: 6px 12px;
					}
				

				
	.node-stream-analysis-average-rep-bottom {
		width: 100%;
		display: flex;
		margin-top: 4px;
		height: 27px;
		background: #101012;
		border: solid;
		border-width: 1px;
		border-color: #2B2B30;
		border-radius: 5px;
		cursor: col-resize;
		position: relative;
	}
	.node-stream-analysis-average-rep-bottom-progress-bar {
		background: #5B62FF40;
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
		border-right: solid;
		border-width: 2px;
		border-color: #5B62FF;
	}
		.node-stream-analysis-average-rep-bottom-text-container {
			width: 100%;
			padding: 4px 8px;
			position: absolute;
			top: 0;
			left: 0;
		}
			.node-stream-analysis-average-rep-bottom-text-container p {
			    font-size: 13.5px;
			    color: #ffffff;
			    margin: 0px;
			}

