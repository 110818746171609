.workout-profile-history-load-more-container {
	width: 100%;
	display: block;
	border-radius: 4px;
	background-color: #202A34;
	box-shadow: 0 4px 38px 0 rgba(0, 0, 0, 0.1);
	height: 80px;
	margin-bottom: 24px;
	padding: 30px 0px;
	text-align: center;
}

.client-profile-page-exercise-list-container {
	width: 100%;
	border-radius: 5px;
	background-color: #1F1F21;
	box-shadow: 0 4px 38px 0 rgba(0, 0, 0, 0.1);
	min-height: 80px;
	margin-bottom: 12px;
	padding: 24px 36px;
}
	.client-profile-page-exercise-list-cell-container {
		width: 100%;
		border-radius: 5px;
		box-shadow: 0px 0px 38px 2px rgba(0, 0, 0, 0.2);
		background: rgba(73,89,193,0.2);
	}