.client-search-container {
	padding: 12px 0px;
}

.client-search-container p {
	font-size: 12px;
}

.search-bar {
	margin-top: 0px;
}

.cell-container {
	width: 100%;
	display: inline;
	padding-bottom: 64px;
}

.client-container h1 {
	font-size: 36px;
}

.client-container {
	height: 100%;
	width: 100%;
	padding: 40px 0px;
}

.new-client-icon-container {
	/*width: 100px;
	height: 100px;
	border-radius: 50px;
	background: #4959C1;
	margin: auto;*/
}

.new-client-cell-container {
	padding: 28px 20px;
	width: calc(25% - 16px);
	height: 226px;
	border-radius: 4px;
	background-color: #202A34;
	box-shadow: 0 4px 38px 0 rgba(0, 0, 0, 0.1);
	margin-right: 16px;
	margin-top: 0px; 
	margin-bottom: 12px;
	display: inline-block;
}
.new-client-cell-container p {
	display: none;
	text-align: center;
}
.workout-list-upper-content-add-button-container {
	width: 140px;
	margin-right: 20px;
}
.workout-list-upper-content-add-button {
	width: 110px;
}

@media screen and (max-width: 920px) {
	.new-client-cell-container {
		width: calc(33% - 16px);
	}
}

@media screen and (max-width: 600px) {
	.new-client-cell-container {
		width: calc(50% - 16px);
	}
}