
.performance-stat-card {
    width: 100%;
    min-height: 280px;
    background: #101012;
    border: solid;
    border-width: 1px;
    border-color: #202020;
    border-radius: 16px;
    position: relative;
    overflow: hidden;
    margin-bottom: 12px;
}
    .performance-stat-card-header {
        padding: 16px 8px;
        display: flex;
        align-items: center;
        z-index: 1;
        position: relative;
        border-bottom: solid;
        border-width: 1px;
        border-color: #202020;
    }
        .performance-stat-card-header-icon-container {
            background: #fff;
            width: 32px;
            height: 32px;
            border-radius: 6px;
            align-items: center;
            justify-content: center;
            padding: 6px 6px;
        }
            .performance-stat-card-header-icon {
                color: black;
                font-size: 18px !important;
            }
        .performance-stat-card-header h4 {
            font-size: 13.5px;
            color: #ffffff;
            font-weight: 300;
            margin: 0px;
            margin-left: 12px;
        }
        .performance-stat-card-header-options-container {
            display: flex;
            margin-left: auto;
            float: right;
            padding-right: 12px;
        }
            .performance-stat-card-header-option {
                padding: 4px 8px;
                border-radius: 14px;
                border-width: 1px !important;
                border: solid;
                border-color: transparent;
                margin: -4px 0px;
                margin-left: 6px;
                -webkit-transition: all 0.15s;
                -moz-transition: all 0.15s;
                transition: all 0.15s;
            }
            .performance-stat-card-header-option:hover {
                border-color: #5B62FF;
                background: #5B62FF40;
                -webkit-transition: all 0.15s;
                -moz-transition: all 0.15s;
                transition: all 0.15s;
                cursor: pointer;
            }
            .performance-stat-card-header-option:hover p {
                color: #ffffff;
                -webkit-transition: all 0.15s;
                -moz-transition: all 0.15s;
                transition: all 0.15s;
            }
                .performance-stat-card-header-option p {
                    font-size: 13px;
                    color: #9DA0AD;
                    font-weight: 300;
                    margin: 0px;
                    -webkit-transition: all 0.15s;
                    -moz-transition: all 0.15s;
                    transition: all 0.15s;
                }
                .performance-stat-card-header-option-selected {
                    border-color: #5B62FF;
                    background: #5B62FF40;
                    -webkit-transition: all 0.15s;
                    -moz-transition: all 0.15s;
                    transition: all 0.15s;
                }
                .performance-stat-card-header-option-selected p {
                    color: #7278F2;
                    font-weight: 500;
                }
        
        .performance-stat-card-header-more-button {
            padding: 4px 8px;
            border-radius: 5px;
            margin-left: auto;
            float: right;
            -webkit-transition: all 0.15s;
            -moz-transition: all 0.15s;
            transition: all 0.15s;
        }

        .performance-stat-card-header-more-button:hover {
            background: #7278F230;
            cursor: pointer;
            -webkit-transition: all 0.15s;
            -moz-transition: all 0.15s;
            transition: all 0.15s;
        }
            .performance-stat-card-header-more-button p {
                font-size: 13.5px;
                color: #7278F2;
                margin: 0px;
            }
        .performance-stat-card-header-summary-container {
            padding: 4px 8px;
            border-radius: 5px;
            margin-left: auto;
            float: right;
        }
            .performance-stat-card-header-summary-container p {
                font-size: 13.5px;
                color: #6C6C6C;
                margin: 0px;
            }

    .performance-stat-card-body-container {
        width: 100%;
        height: 180px;
        padding: 16px;
        padding-top: 0px;
        padding-right: 0px;
        z-index: 1;
        position: relative;
    }
    .performance-stat-card-body-empty-container {
        width: 100%;
        height: 120px;
        padding-top: 60px;
    }
        .performance-stat-card-body-empty-container p {
            font-size: 13.5px;
            color: #6C6C6C;
            margin: 0px;
            width: 100%;
            text-align: center;
        }
    .performance-stat-card-body-hero-container {

    }
        .performance-stat-card-body-hero-header-container {
            padding-top: 24px;
        }
            .performance-stat-card-body-hero-header-container h1 {
                font-size: 60px;
                font-weight: 300;
                color: #7278F2;
                margin: 0px;
            }
            .performance-stat-card-body-hero-header-container p {
                font-size: 13.5px;
                font-weight: 300;
                color: #ffffff50;
                margin: 0px;
            }
            .performance-stat-card-body-hero-header-container h4 {
                font-size: 18px;
                font-weight: 300;
                color: #5BFF62;
                margin: 0px;
                margin-top: 24px;
            }

    .performance-stat-card-body-chart-container {
        width: 60%;
        position: absolute;
        right: 0px;
        bottom: 0px;
        height: 180px;
    }


    .performance-stat-card-selector-container {
        width: 100%;
        display: flex;
        padding: 16px;
        z-index: 1;
        position: relative;
    }
        .performance-stat-card-selector-demo {
            width: 36px;
            border-radius: 2px;
            height: 4px;
            background: #6C6C6C;
        }

    .performance-stat-card-bg-container {
        width: 100%;
        height: 100%;
        border-radius: 16px;
        position: absolute;
        z-index: 0;
        top: 0px;
        left: 0px;
    }
