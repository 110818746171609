
.activity-feed-row {
	width: 100%;
	min-height: 110px;
	display: flex;
}
	.activity-feed-row-left {
		width: 60px;
		position: relative;
	}
		.activity-feed-row-left-image-container {
			width: 50px;
			height: 50px;
			border-radius: 25px;
			overflow: hidden;
			background: #0B0C0F;
			box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.1);
		}
			.activity-feed-row-left-image-container h4 {
			    font-size: var(--ef-font-size-l);
			    color: var(--ef-text-mid);
			    margin: 0px;
			    width: 100%;
			    text-align: center;
			    padding-top: 15px;
			}
				.activity-feed-row-left-image {
					width: 50px;
					height: 50px;
					border-radius: 25px;
					overflow: hidden;
				}
		.activity-feed-row-left-line {
			position: absolute;
			width: 2px;
			background: #2B2B30;
			height: 70px;
			left: 24px;
			top: 50px;
		}
	.activity-feed-row-right {
		flex: 1;
		padding-left: 8px;
	}
		.activity-feed-row-right-upper {
			width: 100%;
			padding-top: 14px;
		}
			.activity-feed-row-right-upper h4 {
			    font-size: 18px;
			    color: #6C6C6C;
			    font-weight: 400;
			    margin: 0px;
			}
			.activity-feed-row-right-upper span {
				color: #ffffff;
				font-weight: 500;
			}
			.activity-feed-row-name-text {

			}
			.activity-feed-row-name-text:hover {
				color: #7278F2 !important;
				text-decoration: underline;
				cursor: pointer;
			}

		.activity-feed-row-right-lower {
			width: 100%;
		}
			.activity-feed-row-right-lower-left {
				padding-top: 12px;
			}
				.activity-feed-row-status {
					padding: 2px 8px;
					padding-left: 5px;
					border-radius: 5px;
					display: flex;
					float: left;
					margin-right: 12px;
				}
					.activity-feed-row-status-icon {
						font-size: 14px !important;
						margin-top: 1px;
					}
					.activity-feed-row-status p {
					    font-size: 12px;
					    margin: 0px;
					    margin-left: 6px;
					}
				.activity-feed-row-status-log {
					background: #5B62FF40;
				}
					.activity-feed-row-status-log p {
						color: #7278F2 !important;
					}
					.activity-feed-row-status-log {
						color: #7278F2 !important;
					}
				.activity-feed-row-status-workout {
					background: #5BF26240;
				}
					.activity-feed-row-status-workout p {
						color: #72F278 !important;
					}
					.activity-feed-row-status-workout {
						color: #72F278 !important;
					}
				.activity-feed-row-status-workout-missed {
					background: #FF4D4D40;
				}
					.activity-feed-row-status-workout-missed p {
						color: #FF4D4D !important;
					}
					.activity-feed-row-status-workout-missed {
						color: #FF4D4D !important;
					}
			.activity-feed-row-right-lower-left-time-container {

			}
				.activity-feed-row-right-lower-left-time-container p {
				    font-size: 13.5px;
				    color: #9DA0AD;
				    margin: 0px;
				}
		.activity-feed-row-right-lower-btn-container {
			margin-left: auto;
			float: right;
		}
			.activity-feed-row-right-lower-btn {
				display: flex;
				padding: 4px 8px;
				padding-bottom: 5px;
				border-radius: 5px;
				cursor: pointer !important;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
				.activity-feed-row-right-lower-btn p {
				    font-size: 13.5px;
				    color: #7278F2;
				    margin: 0px;
				    cursor: pointer !important;
				}
				.activity-feed-row-right-lower-btn-icon {
					font-size: 16px !important;
				    color: #7278F2;
				    margin-left: 4px;
				    margin-top: 1px;
				}
			.activity-feed-row-right-lower-btn:hover {
				cursor: pointer;
				background: #00000040;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}


