.page-header-container {
	width: 100%;
	padding: 10px 36px;
	border-bottom: solid;
	border-color: #2B2B30;
	border-width: 1px;
	background: #161719;
	height: 51px !important;
}

	.page-header-upper-content-container {
		width: 100%;
		position: relative;
		display: flex;
		padding-bottom: 10px;
	}
		.page-header-upper-content-container-text {
			width: 100%;
			position: relative;
			display: flex;
		}
			.page-header-container h1 {
				font-size: 13.5px;
				font-weight: 600;
				color: #9DA0AD;
				padding-top: 8px;
				margin: 0px;
				white-space: nowrap;
			}
			.page-header-back-text {
				position: absolute;
				font-size: 13.5px;
				top: -24px;
				left: 0px;
				color: #6C6C6C;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
			.page-header-back-text:hover {
				cursor: pointer;
				color: #ffffff;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}

			.age-header-upper-content-container-text-arrow {
				margin: 0px 16px;
				padding-top: 6px;
			}
				.age-header-upper-content-container-text-arrow-icon {
					font-size: 11px !important;
					color: #9DA0AD;
				}
		.page-header-upper-content-container-buttons {
			position: absolute;
			right: 0px;
			top: 0px;
			display: flex;
		}
			.page-header-button {
				padding: 6px 12px;
				padding-right: 12px;
				display: flex;
				font-size: 12px;
				border-radius: 5px;
				background: black;
				margin-left: 12px;
				color: #ffffff !important;
			}
				.page-header-button p {
					font-weight: 600;
					margin: 0px;
				}
				.page-header-upper-content-container-buttons-icon {
					font-size: 13.5px !important;
					margin-top: 1px;
					margin-left: -4px;
					margin-right: 2px;
				}

				.page-header-button-dark {
					background: #1F1F21;
					border: solid;
					border-width: 1px;
					border-color: #2B2B30;
					box-shadow: 0 3px 6px 0 rgba(5, 5, 23, 0.2);
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}

				.alert-unread-messages {
					display: flex;
					align-items: center;
					justify-content: center;
					background: #5031D5;
					height: 15px;
					width: 15px;
					border-radius: 50%;
					margin-right: 6px;
					margin-left: -4px
				}

					.alert-unread-messages p {
						margin: 0;
						font-size: 10px;
					}
				.page-header-button-dark:hover {
					background-color: #28282D;
					border-color: #3B3B46;
					cursor: pointer;
					box-shadow: 0 2px 20px 0 rgba(0, 0, 200, 0.05);
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
				.page-header-button-red {
					background: #181B2A;
					border: solid;
					border-width: 0.5px;
					border-color: #2E3247;
					box-shadow: 0 2px 20px 0 rgba(5, 5, 23, 0.2);
					color: #FF4D4D !important;
				}

				.page-header-button-blue {
					background: #5437DC;
					border: none;

					padding-top: 6.5px;
					box-shadow: 0 2px 20px 0 rgba(23, 23, 200, 0.2);
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
					.page-header-button-blue p {
						color: white !important;
					}
				.page-header-button-blue:hover {
					background-color: #7963E0;
					cursor: pointer;
					box-shadow: 0 2px 20px 0 rgba(0, 0, 200, 0.05);
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
			.page-header-upper-content-container-account-icon-container {
				display: flex;
				margin-left: 12px;
				border-radius: 5px;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
			.page-header-upper-content-container-account-icon-container:hover {
				cursor: pointer;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
				.page-header-upper-content-container-account-icon {
					width: 28px;
					height: 28px;
					border-radius: 14px;
					border: solid;
					border-width: 0.5px;
					border-color: #2B2B30;
					background: rgba(0,0,0,0.4);
					box-shadow: 0 2px 12px 4px rgba(5, 5, 23, 0.2);
				}
				.page-header-upper-content-container-account-arrow-icon-container {
					padding-top: 6px;
					padding-left: 4px;
				}
					.page-header-upper-content-container-account-arrow-icon {
						font-size: 15px !important;
						color: #6C6C6C;
					}
					.page-header-upper-content-container-account-icon-container:hover .page-header-upper-content-container-account-arrow-icon {
						color: #ffffff;
					}

.page-header-add-menu-container {
	position: absolute;
	width: 240px;
	right: 174px;
	top: 36px;
	background: #1F1F21E0;
	-webkit-backdrop-filter: blur(8px);
	backdrop-filter: blur(8px);
	z-index: 200;
	border-radius: 10px;
	border: solid !important;
	border-width: 1px !important;
	border-color: #2B2B30 !important;
	box-shadow: 0px 2px 32px 24px rgba(5, 5, 23, 0.3);
	-webkit-transition: all 0.35s;
  	-moz-transition: all 0.35s;
  	transition: all 0.35s;
}
.page-header-profile-menu-container {
	right: 0px;
}

	.page-header-add-menu-content {
		position: relative;
		padding-bottom: 8px;
	}
		.page-header-add-menu-header {
			width: 100%;
			padding: 12px 16px;
			border-bottom: solid;
			border-color: #00000030;
			border-width: 1px;
		}
			.page-header-add-menu-header h4 {
				font-size: 13.5px;
				color: #ffffff;
				margin: 0px;
			}
		.page-header-add-menu-triangle {
			/*position: relative;
			top: -12px;
			left: calc(50% - 38px);
			width: 0;
		  	height: 0;
		  	border-left: 12px solid transparent;
		  	border-right: 12px solid transparent;
		  	border-bottom: 20px solid #1A1B2A;*/

		}
		.page-header-add-menu-row-container {
			display: flex;
			padding: 10px 16px;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
		.page-header-add-menu-row-container:hover {
			background: rgba(200,220,240,0.04);
			border-radius: 5px;
			cursor: pointer;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
			.page-header-add-menu-row-icon-container {
				width: 18px;
				height: 18px;
				border-radius: 5px;
				border-width: 2px !important;
				border: solid;
				background: rgba(73,89,193,1.0);
				border-color: rgba(73,89,193,1.0);
				margin-top: 1px;
			}
				.page-header-add-menu-row-icon {
					font-size: 13.5px !important;
					margin-left: 9px;
					margin-top: 9px;
					color: #5B62FF;
					display: none !important;
				}
				.page-header-add-menu-row-icon-show {
					display: block !important;
				}
					.page-header-add-menu-row-icon-container-logout {
						background: transparent;
						border-color: transparent;
					}
					.page-header-add-menu-row-icon-logout {
						color: #9DA0AD;
						font-size: 13.5px !important;
					}


					.page-header-add-menu-row-icon-container-workout {
						background: rgba(60,193,73,1.0);
						border-color: rgba(60,193,73,1.0);
					}
						.page-header-add-menu-row-icon-workout {
							color: #49C16B;

						}
					.page-header-add-menu-row-icon-container-exercise {
						background: rgba(73,188,193,1.0);
						border-color: rgba(73,188,193,1.0);
					}
						.page-header-add-menu-row-icon-exercise {
							color: #49BCC1;
						}
					.page-header-add-menu-row-icon-container-client {
						background: rgba(193,101,73,1.0);
						border-color: rgba(193,101,73,1.0);
					}
						.page-header-add-menu-row-icon-client {
							color: #C16549;
						}

			.page-header-add-menu-row-text-container {
				padding-left: 12px;
				padding-top: 1px;
			}
				.page-header-add-menu-row-text-container h4 {
					font-size: 13.5px;
					color: #ffffff;
					margin: 0px;
					margin-bottom: 0px;
					margin-top: 1px;
				}
				.page-header-add-menu-row-text-container p {
					font-size: 10px;
					color: #9DA0AD;
					margin: 0px;
					display: none !important;
				}
			.page-header-add-menu-row-hotkey-container {
				margin-left: auto;
				float: right;
				display: flex;
				margin-top: -2px;
			}
				.page-header-add-menu-row-hotkey {
					margin-left: 2px;
					background: #1F1F21;
					border-radius: 5px;
					padding: 2px 7px;
					height: 21px;
					box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.08);
				}
					.page-header-add-menu-row-hotkey p {
					    font-size: 12px;
					    color: #9DA0AD;
					    margin: 0px;
					}
		.page-header-add-menu-row-divide {
			border-top: solid;
			border-width: 1px;
			border-color: #2B2B30;
			width: 100%;
		}



.page-header-subpage-container {
	display: flex;
	padding: 0px;
	padding-top: 8px;

	width: 100%;
	position: relative;
}

.page-header-subpage-container-partial {
	width: 58%;
	margin-left: auto;
	float: right;
	padding-top: 18px;
}
	.page-header-subpage-selected-bar {
		position: absolute;
		width: 60px;
		height: 2px;
		border-radius: 1px;
		background: #5437DC;
		box-shadow: 0 -4px 24px 4px #5437DCAF;
		bottom: -16px;
	}

.page-header-selected-subpage {
	color: #ffffff;
	font-weight: 500;
	margin-right: 36px;
	font-size: 13.5px;
	display: inline-flex;
	cursor: pointer;
	-webkit-transition: all 0.15s;
  	-moz-transition: all 0.15s;
  	transition: all 0.15s;
}

.page-header-unselected-subpage {
	margin-right: 37px;
	font-size: 13.5px;
	display: inline-flex;
	color: #5F6C76;
	-webkit-transition: all 0.15s;
  	-moz-transition: all 0.15s;
  	transition: all 0.15s;
}

.page-header-unselected-subpage:hover {
	color: #9DA0AD;
	cursor: pointer;
	-webkit-transition: all 0.15s;
  	-moz-transition: all 0.15s;
  	transition: all 0.15s;
}

.page-header-alert-circle {
	background-color: #FF4D4D;
	height: 6px;
	width: 6px;
	border-radius: 3px;
	margin: auto;
	margin-left: 8px;
}

.page-header-close-button-container-outter {
	width: 60px;
	padding-top: 4px;
}
	.page-header-close-button-container-inner {
		/*margin-top: 4px;*/
		width: 70%;
		border-right: solid;
		border-width: 1px;
		border-color: #2B2B30;
	}

	.page-header-close-button {
		/*position: absolute;
		right: 0px;*/
		width: 22px;
		height: 22px;
		border-radius: 5px;
		padding: 2.5px;
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}
	.page-header-close-button:hover {
		background: rgba(0,0,0,0.2);
		cursor: pointer;
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}
		.page-header-close-button-icon {
			color: #9DA0AD;
			font-size: 17px !important;
		}
		.page-header-close-button:hover .page-header-close-button-icon {
			color: #ffffff;
		}

.page-header-mobile-container {
	display: none;
	position: relative;
}
	.page-header-mobile-brand-container {

	}
		.page-header-mobile-brand-container h4 {
		    font-size: var(--ef-font-size-xl);
		    color: var(--ef-text-white);
		    margin: 0px;
		    font-weight: 600;
		}
	.page-header-mobile-menu-button-container {
		margin-left: auto;
		float: right;
	}
		.page-header-mobile-menu-button {
			width: 40px;
			height: 40px;
			background: #00000060;
			border-radius: var(--ef-radius-m);
			padding: 8px 8px;
			margin-top: -8px;
		}
			.page-header-mobile-menu-button-icon {
				font-size: 24px !important;
				color: var(--ef-text-dark);
			}
	.page-header-mobile-menu-menu {
		position: absolute;
		top: -8px;
		border-radius: var(--ef-radius-l);
		box-shadow: 0px 0px 36px 18px rgba(0,0,0,0.52);
		background: #000000D0;
		-webkit-backdrop-filter: blur(8px);
		backdrop-filter: blur(8px);
		left: 0px;
		width: 100%;
		min-height: 420px;
		z-index: 300;
	}
		.page-header-mobile-menu-menu-inner {
			width: 100%;
			padding: 20px 20px;
			position: relative;
		}
			.page-header-mobile-menu-menu-header {
				position: absolute;
				right: 0px;
				top: 8px;
			}
		.page-header-mobile-menu-menu-content {
			width: 100%;

		}
			.page-header-mobile-menu-menu-content-row {
				padding-bottom: 12px;
			}
				.page-header-mobile-menu-menu-content-row h4 {
				    font-size: var(--ef-font-size-xxl);
				    color: var(--ef-text-light);
				    margin: 0px;
				    font-weight: 300;
				}

/* Mobile CSS */
@media screen and (max-width: 560px) {
	.page-header-container {
		padding: 10px 20px;
		padding-right: 16px;
	}
	/*.page-header-upper-content-container-buttons {
		display: none;
	}
	
	.page-header-selected-subpage {
		white-space: nowrap;
	}
	.page-header-unselected-subpage {
		white-space: nowrap;
	}*/
	.page-header-upper-content-container {
		display: none;
	}
	.page-header-mobile-container {
		display: flex;
	}
}
