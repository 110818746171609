.feedback-modal-container {
/*	height: 50%;
*/	/*min-height: 180px;*/
	height: 360px;
	margin: auto;
	margin-top: 0px;
	min-width: 400px;
	width: 67%;
	max-width: 740px;
	background: #161719;
	border-radius: 5px;
	border: solid;
	border-width: 0.5px;
	border-color: #2B2B30;
	/*overflow: hidden;*/
	box-shadow: 0 0px 100px 12px rgba(0, 0, 0, 0.5);
	position: relative;
	padding-bottom: 4px;
}
.feedback-modal-background {
	padding-top: calc(50vh - 240px);
}

.feedback-modal-top {
	width: 100%;
	padding: 20px 36px;
	padding-bottom: 4px;
}

.feedback-modal-top h3 {
	margin-bottom: 12px;
	font-weight: 600;
	color: #ffffff;
	font-size: 18px;
}

.feedback-modal-top p {
	margin: 0px;
	color: #9DA0AD;
	font-size: 13.5px;
	line-height: 18px !important;
}

.feedback-modal-body-container {
	width: 100%;
	padding: 0px 36px;
	padding-right: 28px;
	height: 190px;
}
	.feedback-modal-input-container {
		width: 100%;
		height: 100%;
		max-height: 300px;
		overflow-y: hidden;
	}
	.feedback-modal-body-container-loading {
		padding-top: 150px;
	}
		.feedback-modal-body-container-loading p {
			font-size: 13.5px;
			color: #6C6C6C;
			margin: 0px;
			margin-top: 8px;
			width: 100%;
			text-align: center;
		}
	.feedback-modal-body-container-done {
		padding-top: 120px;
	}
		.feedback-modal-body-container-done h4 {
			font-size: 18px;
			color: #ffffff;
			margin: 0px;
			margin-top: 0px;
			width: 100%;
			text-align: center;
		}
		.feedback-modal-body-container-done p {
			font-size: 13.5px;
			color: #6C6C6C;
			margin: 0px;
			margin-top: 6px;
			width: 100%;
			text-align: center;
		}


.feedback-modal-bottom {
	width: 100%;
	height: 60px;
	padding: 28px;
	padding-top: 3px;
	display: flex;
	/*float: right;*/
}

.feedback-modal-close-button {
	font-size: 13.5px;
	float: left;
	border-radius: 5px;
	color: #C2C5CD;
	background: #1F1F21;
	padding: 6px 12px;
	margin-top: 4px;
	margin-left: auto;
	float: right;
	display: inline-block;
	cursor: pointer;
	margin-right: 18px;
	border: solid;
	border-width: 1px;
	border-color: #2B2B30;
	text-align: center;
	height: 30px;
	box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
}
	.feedback-modal-close-button:hover {
		background: #28282D;
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}

.feedback-modal-cta-button {
	font-size: 13.5px;
	float: right;
	background: #5437DC;
	border-radius: 5px;
	color: white;
	padding: 6px 12px;
	margin-top: 4px;
	display: inline-block;
	cursor: pointer;
	box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
	height: 30px;
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
}
	.feedback-modal-cta-button:hover {
		background: #7963E0;
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}
	.feedback-modal-cta-button-done {
		margin: 0px auto;
		margin-top: 48px;
		display: block;
		float: none;
		width: 58px;
	}

.feedback-modal-cta-button-red {
	font-size: 13.5px;
	float: right;
	background-color: rgba(255,60,60);
	border-radius: 5px;
	height: 30px;
	color: white;
	text-align: center;
	padding: 6px 12px;
	margin-top: 4px;
	display: inline-block;
	cursor: pointer;
	box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
}
.feedback-modal-cta-button-red:hover {
	background-color: rgba(255,80,80);
	
}

.feedback-modal-floating-close {
	position: absolute;
	top: 18px;
	right: 24px;
	width: 30px;
	height: 30px;
	padding: 6px;
	color: #6C6C6C;
	cursor: pointer;
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
}

.feedback-modal-floating-close:hover {
	color: #ffffff;
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
}