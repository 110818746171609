/* .toggle-switch- */

.toggle-switch {
	width: 32px;
	height: 21px;
	border-radius: 10px;
	background: #161719;
	padding: 3px;
	border-width: 1px !important;
	border: solid;
	border-color: transparent;
	-webkit-transition: all 0.10s;
	-moz-transition: all 0.10s;
	transition: all 0.10s;
}
.toggle-switch:hover {
	background: #1F1F21;
	border-color: #2A2A2F;
	cursor: pointer;
	-webkit-transition: all 0.10s;
	-moz-transition: all 0.10s;
	transition: all 0.10s;
}
	.toggle-switch-on {
		background: #4959C1;
		-webkit-transition: all 0.10s;
		-moz-transition: all 0.10s;
		transition: all 0.10s;
	}
	.toggle-switch-on:hover {
		background: #5C6BC9;
		-webkit-transition: all 0.10s;
		-moz-transition: all 0.10s;
		transition: all 0.10s;
	}

	.toggle-switch-circle {
		height: 14px;
		width: 14px;
		border-radius: 7px;
		background: #28282D;
		margin-left: 0px;
		-webkit-transition: all 0.10s;
		-moz-transition: all 0.10s;
		transition: all 0.10s;
	}
	.toggle-switch-circle-on {
		margin-left: 12px;
		background: #ffffff;
		-webkit-transition: all 0.10s;
		-moz-transition: all 0.10s;
		transition: all 0.10s;
	}