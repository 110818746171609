/* .workout-plan-list- */

.workout-plan-list {
    width: calc(100% + 24px);
    height: 100%;
    display: flex;
    position: relative;
}

.workout-plan-list-background {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
}

    .workout-plan-list h4 {
        font-size: 13.5px;
        color: #ffffff;
        margin: 0px;
    }
    .workout-plan-list p {
        font-size: 13.5px;
        color: #6C6C6C;
        margin: 0px;
    }

    .workout-plan-list-folder-card-container {
        width: 36%;
        height: 100%;
        padding: 0px;
        z-index: 10;
    }
        .workout-plan-list-folder-card {
            /*background: #1F1F21;
            border: solid;
            border-width: 0.5px;
            border-color: #2B2B30;
            
            border-radius: 5px;
            box-shadow: 0 4px 38px 0 rgba(0, 0, 0, 0.1);
            width: 100%;*/
            height: 100%;
            padding-right: 0px;
        }
            .workout-plan-list-folder-card-header {
                width: 100%;
                padding: 32px 36px;
                padding-bottom: 24px;
                /*display: none;*/
            }
                .workout-plan-list-folder-card-header h1 {
                    font-size: var(--ef-font-size-xxl);
                    color: var(--ef-text-white);
                    margin: 0px;
                    font-weight: 600;
                }
            .workout-plan-list-folder-card-body {
                padding: 4px;
                padding-left: 20px;
                padding-bottom: 20px;
                overflow-y: scroll;
                width: 100%;
                height: calc(100% - 94px);
            }
                .workout-plan-list-folder-card-body-loading {
                    padding-top: 200px;
                }
                    .workout-plan-list-folder-card-body-loading p {
                        width: 100%;
                        text-align: center;
                    }
                .workout-plan-list-folder-card-body-empty {
                    padding: 0px 32px;
                    padding-top: calc(25vh - 84px);
                }
                    .workout-plan-list-folder-card-body-empty-inner {

                    }
                        .workout-plan-list-folder-card-body-empty-img-container {

                        }
                            .workout-plan-list-folder-card-body-empty-img-container img {
                                width: 120px;
                            }
                        .workout-plan-list-folder-card-body-empty-text-container {
                            margin-bottom: 16px;
                        }
                            .workout-plan-list-folder-card-body-empty-text-container h4 {
                                font-size: 18px;
                                color: #ffffff;
                                margin: 0px;
                                margin-bottom: 12px;
                            }
                            .workout-plan-list-folder-card-body-empty-text-container p {
                                font-size: 13.5px;
                                color: #9DA0AD;
                                margin: 0px;
                                margin-bottom: 24px;
                            }
                        .workout-plan-list-folder-card-body-empty-button-container {
                            float: left;
                            display: flex;
                            padding: 10px 12px;
                            padding-right: 16px;
                            border-radius: 5px;
                            background: var(--ef-cta);
                            -webkit-transition: all 0.15s;
                            -moz-transition: all 0.15s;
                            transition: all 0.15s;
                        }
                        .workout-plan-list-folder-card-body-empty-button-container:hover {
                            background: var(--ef-cta-light);
                            cursor: pointer;
                            -webkit-transition: all 0.15s;
                            -moz-transition: all 0.15s;
                            transition: all 0.15s;
                        }
                            .workout-plan-list-folder-card-body-empty-button-container p {
                                font-size: 13.5px;
                                color: white;
                                margin: 0px;
                                font-weight: 600;
                                opacity: 1;

                            }
                            .workout-plan-list-folder-card-body-empty-button-container:hover p {
                                color: white;
                            }
                            .workout-plan-list-folder-card-body-empty-button-icon {
                                font-size: 15px !important;
                                color: white;
                                margin-right: 8px;
                                margin-top: 1px;
                            }


            .workout-plan-list-folder-card-row-header {
                width: 100%;
                background: #1F1F21;
                display: flex;
                padding: 16px 36px;
                padding-right: 12px;
                position: relative;
            }
                .workout-plan-list-folder-card-row-header-text-container {
                    display: flex;
                }
                    .workout-plan-list-folder-card-row-header-text-container h4 {
                        font-size: 13.5px;
                        color: #9DA0AD;
                        margin: 0px;
                    }
                    .workout-plan-list-folder-card-row-header-text-container p {
                        font-size: 13.5px;
                        color: var(--ef-cta-light);
                        margin: 0px;
                        margin-left: 8px;
                        font-weight: 600;
                    }
                .workout-plan-list-folder-card-row-header-add-button {
                    width: 24px;
                    height: 24px;
                    margin-top: -4px;
                    margin-bottom: -4px;
                    border-radius: 5px;
                    float: right;
                    margin-left: auto;
                    padding: 4px 4px;
                    position: relative;
                    -webkit-transition: all 0.15s;
                    -moz-transition: all 0.15s;
                    transition: all 0.15s;  
                }
                .workout-plan-list-folder-card-row-header-add-button:hover {
                    box-shadow: 0 0px 12px 0 rgba(0, 0, 0, 0.1);
                    background: #161719;
                    cursor: pointer;
                    -webkit-transition: all 0.15s;
                    -moz-transition: all 0.15s;
                    transition: all 0.15s;  
                }
                    .workout-plan-list-folder-card-row-header-add-button-icon {
                        font-size: 16px !important;
                        color: #6C6C6C;
                        -webkit-transition: all 0.15s;
                        -moz-transition: all 0.15s;
                        transition: all 0.15s;  
                    }
                    .workout-plan-list-folder-card-row-header-add-button:hover .workout-plan-list-folder-card-row-header-add-button-icon {
                        color: #ffffff;
                        -webkit-transition: all 0.15s;
                        -moz-transition: all 0.15s;
                        transition: all 0.15s;  
                    }
                    .workout-plan-list-folder-card-row-header-add-button:hover .workout-plan-list-folder-card-row-header-add-button-tooltip {
                        display: block;
                        opacity: 1.0;
                        -webkit-transition: all 0.5s;
                        -moz-transition: all 0.5s;
                        transition: all 0.5s;  
                    }
                    .workout-plan-list-folder-card-row-header-add-button-tooltip {
                        display: none;
                        opacity: 0.0;
                        position: absolute;
                        bottom: -38px;
                        right: 0px;
                        width: 110px;
                        background: #1F1F21;
                        padding: 8px;
                        border-radius: 5px;
                        box-shadow: 0 0px 24px 12px rgba(0, 0, 0, 0.1);
                        -webkit-transition: all 0.5s;
                        -moz-transition: all 0.5s;
                        transition: all 0.5s;  
                    }
                        .workout-plan-list-folder-card-row-header-add-button-tooltip p {
                            color: #ffffff;
                            font-size: 13.5px;
                            width: 100%;
                            text-align: center;
                        }
.workout-plan-content-wrapper {
    width: calc(64% - 25px);
    padding: 20px;
    padding-left: 6px;
    position: relative;
}
    .workout-plan-content-copied-container {
        position: absolute;
        bottom: 10px;
        border-radius: var(--ef-radius-m);
        background: var(--ef-color-workout-transparent);
        width: 400px;
        left: calc(50% - 200px);
        z-index: 2;
        box-shadow: 0px 18px 24px 12px rgba(0, 0, 0, 0.1);
        border: solid;
        border-width: 1px;
        border-color: var(--ef-color-workout) !important;
        -webkit-backdrop-filter: blur(12px);
        backdrop-filter: blur(12px);
        padding: 6px 6px;
    }
    .workout-plan-content-copied-container-phase {
        background: var(--ef-color-phase-transparent) !important;
        border-color: var(--ef-color-phase) !important;
    }
        .workout-plan-content-copied-inner {
            width: 100%;
            position: relative;
            display: flex;
            padding: 0px 36px;
        }
            .workout-plan-content-copied-text-container {
                width: 100%;
            }
                .workout-plan-content-copied-text-container p {
                    font-size: var(--ef-font-size-s);
                    color: var(--ef-text-light);
                    margin: 0px;
                    font-weight: 300;
                    width: 100%;
                    text-align: center;
                    white-space: nowrap;
                }
                .workout-plan-content-copied-text-container span {
                    font-size: var(--ef-font-size-s);
                    color: #ffffff;
                    margin: 0px;
                    font-weight: 500;
                    padding-right: 2px;
                }
            .workout-plan-content-copied-close-container {
                position: absolute;
                right: 2px;
                top: 1;
                background: #FFFFFF00;
                border-radius: 4px;
                width: 17px;
                height: 17px;
                padding: 1px 1px;
                -webkit-transition: all 0.15s;
                -moz-transition: all 0.15s;
                transition: all 0.15s;
            }
            .workout-plan-content-copied-close-container:hover {
                cursor: pointer;
                background: #FFFFFF1F;
                -webkit-transition: all 0.15s;
                -moz-transition: all 0.15s;
                transition: all 0.15s;
            }
                .workout-plan-content-copied-close-icon {
                    font-size: 16px !important;
                    color: #FFFFFF80 !important;
                    -webkit-transition: all 0.15s;
                    -moz-transition: all 0.15s;
                    transition: all 0.15s;
                }
                .workout-plan-content-copied-close-container:hover .workout-plan-content-copied-close-icon {
                    color: #FFFFFFFF !important;
                    -webkit-transition: all 0.15s;
                    -moz-transition: all 0.15s;
                    transition: all 0.15s;
                }
    .workout-plan-content-container {
        width: 100%;
        box-shadow: 0px 18px 24px 12px rgba(0, 0, 0, 0.1);
        padding: 0px;
        height: calc(100% - 0px);
        overflow-y: scroll;
        position: relative;
        border-radius: var(--ef-radius-l);
        border: solid;
        border-width: 1px;
        z-index: 0;
        border-color: var(--ef-border);
        background: var(--ef-card);
        -ms-overflow-style: none !important;  /* IE and Edge */
        scrollbar-width: none !important;  /* Firefox */
    } 
    .workout-plan-content-container::-webkit-scrollbar {
        display: none !important;
    }

    .workout-plan-content-loading-container {
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100%;
        width: 100%;
        background: #161719CC;
        z-index: 200;
    }
        .workout-plan-content-loading-container p {
            width: 100%;
            margin-top: 8px;
            color: #ffffff;
            text-align: center;
        }
        .workout-plan-content-loading-spinner {
            margin: 0px auto;
            margin-top: calc(50vh - 160px);
            width: 24px;
            height: 24px;
            border: 3px solid rgba(0,0,0,0); /* Light grey */
            border-top: 3px solid var(--ef-cta);
            border-right: 3px solid var(--ef-cta);
            border-bottom: 3px solid var(--ef-cta);
            border-radius: 12px;
            animation: spin 1s linear infinite;
        }
            @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
            }
        .workout-plan-content {
            height: 100%;
            width: 100%;
            /*overflow-y: hidden;*/
        }  
            .workout-plan-content-add-container {

            }

            .workout-plan-content-getting-started-container {
                padding: 32px 38px;
                
            }
                .workout-plan-content-getting-started-header {
                    width: 100%;
                    margin-bottom: 100px;
                }
                    .workout-plan-content-getting-started-header p {
                        font-size: 13.5px;
                        color: #7278F2;
                        margin: 0px;
                    }
                    .workout-plan-content-getting-started-header h1 {
                        font-size: 28px;
                        
                        color: #ffffff;
                        margin: 0px;
                        margin-bottom: 12px;
                    }

                    .workout-plan-content-getting-started-header h4 {
                        font-size: 13.5px;
                        color: #9DA0AD;
                        font-weight: 400;
                        margin: 0px;
                        width: 80%;
                    }
                    .workout-plan-content-getting-started-header-button {
                        margin-top: 24px;
                        float: left;
                        padding: 8px 12px;
                        background: var(--ef-cta);
                        display: flex;
                        border-radius: 5px;
                        box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.12);
                        -webkit-transition: all 0.15s;
                        -moz-transition: all 0.15s;
                        transition: all 0.15s;
                                }
                    .workout-plan-content-getting-started-header-button:hover {
                        background: var(--ef-cta-light);
                        cursor: pointer;
                        -webkit-transition: all 0.15s;
                        -moz-transition: all 0.15s;
                        transition: all 0.15s;
                    }
                        .workout-plan-content-getting-started-header-button p {
                            font-size: 13.5px;
                            color: white;
                            margin: 0px;
                        }
                        .workout-plan-content-getting-started-header-button-icon {
                            font-size: 15px !important;
                            color: white;
                            margin-left: 12px;
                            margin-top: 0px;
                        }
                .workout-plan-content-getting-started-content {
                    padding-bottom: 50px;
                }
                    .workout-plan-content-getting-started-content-card {
                        background: #1F1F21;
                        border: solid;
                        border-width: 1px;
                        border-color: #2B2B30;
                        border-radius: 5px;
                        box-shadow: 0px 0px 12px 6px rgba(0, 0, 0, 0.12);
                        margin-bottom: 12px;
                        padding: 20px 20px;
                        display: flex;
                    }
                        .workout-plan-content-getting-started-content-card-colour-container {
                            width: 32px;
                            min-width: 32px;
                            padding: 4px 0px;
                        }
                            .workout-plan-content-getting-started-content-card-colour-block {
                                width: 12px;
                                height: 12px;
                                border-radius: 3px;
                                background: red;
                            }
                            .workout-plan-content-getting-started-content-card-colour-block-folder {
                                background: #7F48C0;
                            }
                            .workout-plan-content-getting-started-content-card-colour-block-cycle {
                                background: #B8C149;
                            }
                            .workout-plan-content-getting-started-content-card-colour-block-phase {
                                background: var(--ef-cta);
                            }
                        .workout-plan-content-getting-started-content-card-content-container {

                        }
                            .workout-plan-content-getting-started-content-card-content-container h2 {
                                font-size: 18px;
                                color: #ffffff;
                                margin: 0px;
                                margin-bottom: 8px;
                            }
                            .workout-plan-content-getting-started-content-card-content-container p {
                                font-size: 13.5px;
                                color: #ffffff;
                                margin: 0px;
                                margin-bottom: 18px;
                            }
                            .workout-plan-content-getting-started-content-card-content-container h4 {
                                font-size: 18px;
                                color: var(--ef-cta-light);
                                font-weight: 400;
                                margin: 0px;
                            }


                .workout-plan-top-row-header-container {

                }
                .workout-plan-add-header-container {
                    width: 60%;
                    min-width: 420px;
                    max-width: 560px;
                    margin: 0px auto;
                    margin-top: 10vh;
                }
                .workout-plan-add-header-container h3 {
                    font-size: 18px;
                    margin-bottom: 4px;
                    color: #ffffff;
                }
                .workout-plan-add-header-container p {
                    margin: 0px;
                    font-size: 13.5px;
                    margin-bottom: 4px;
                    line-height: 20px;
                    color: #9DA0AD;
                }
                    .workout-plan-add-header-image-container {
                        width: 60px;
                        height: 60px;
                        margin-bottom: 24px;
                    }
                        .workout-plan-add-header-image {

                        }

                .workout-plan-add-element-container {
                    width: 100%;
                    margin-top: 16px;
                    padding: 8px;
                    border-radius: 5px;
                    background: #1F1F21;
                    border-color: #2B2B30 !important;
                    border-width: 0.5px !important;
                    border: solid;
                    box-shadow: 0 0px 24px 6px rgba(0, 0, 0, 0.1);
                    
                }
                    .workout-plan-add-element-row-container {
                        display: flex;
                        width: calc(100%);

                        padding: 12px;
                        -webkit-transition: all 0.15s;
                        -moz-transition: all 0.15s;
                        transition: all 0.15s;
                    }
                    .workout-plan-add-element-row-container:hover {
                        background: #28282D;
                        border-radius: 5px;
                        cursor: pointer;
                        -webkit-transition: all 0.15s;
                        -moz-transition: all 0.15s;
                        transition: all 0.15s;
                    }
                    .workout-plan-add-element-row-line {
                        border-bottom: solid;
                        border-width: 0.5px;
                        border-color: #2B2B30;
                    }
                        .workout-plan-add-element-row-icon-container {
                            width: 28px;
                            height: 28px;
                            border-radius: 5px;
                            padding: 6px;
                            margin-top: 3px;
                        }
                        .workout-plan-add-element-row-icon-container-folder {
                            background: #7F48C0;
                        }
                        .workout-plan-add-element-row-icon-container-list {
                            background: #B8C149;
                        }
                        .workout-plan-add-element-row-icon-container-phase {
                            background: #4959C1;
                        }
                            .workout-plan-add-element-row-icon {
                                font-size: 15px !important;
                                color: #1F1F21;
                            }
                        .workout-plan-add-element-row-text-container {
                            padding: 0px 12px;
                        }
                            .workout-plan-add-element-row-text-container h4 {
                                font-size: 13.5px;
                                color: #ffffff;
                                margin: 0px;
                            }
                            .workout-plan-add-element-row-text-container p {
                                font-size: 13.5px;
                                color: #9DA0AD;
                                margin: 0px;
                            }
                        .workout-plan-add-element-row-add-button-container {
                            margin-top: 6px;
                        }
                        .workout-plan-add-element-row-container:hover .workout-plan-add-element-row-add-button-container {
                            box-shadow: 0 0px 12px 0 rgba(0, 0, 0, 0.1);
                            background: #161719;
                            cursor: pointer;
                        }
                        .workout-plan-add-element-row-container:hover .workout-plan-list-folder-card-row-header-add-button-icon {
                            color: #ffffff;
                        }


                .workout-plan-list-header-summary-container {
                    position: absolute;
                    right: 46px;
                    top: 11px;
                }
                .workout-plan-add-folder-button {
                    padding: 6px 12px;
                    padding-right: 12px;
                    display: flex;
                    border-radius: 5px;
                    margin-top: 18px;    
                    float: left;                
                    background: var(--ef-cta);
                    border: none;
                    color: white !important;
                    padding-top: 6.5px;
                    box-shadow: 0 2px 20px 0 rgba(23, 23, 200, 0.2);
                    -webkit-transition: all 0.15s;
                    -moz-transition: all 0.15s;
                    transition: all 0.15s;
                }
                .workout-plan-add-folder-button:hover {
                    background: var(--ef-cta-light);
                    cursor: pointer;
                    -webkit-transition: all 0.15s;
                    -moz-transition: all 0.15s;
                    transition: all 0.15s;
                }
                    .workout-plan-add-folder-button p {
                        color: white !important;
                        font-size: 12px;
                        margin: 0px;
                        font-weight: 500;
                    }

                .workout-plan-add-header-container-left {
                    padding-left: 24px;
                }
                    .workout-plan-add-header-container-left h3 {
                        text-align: left;
                        margin-top: 24px;
                    }
                    .workout-plan-add-header-container-left p {
                        text-align: left;
                        width: 80%;
                        margin-left: 0px;
                    }
                    .workout-plan-add-header-container-left .workout-plan-add-header-folder-container {
                        left: 24px;
                        transform: translate(0%, -32px);
                    }
                    .workout-plan-add-header-folder-container {
                        position: absolute;
                        left: 50%;
                        transform: translate(-50%, -32px);
                        display: flex;
                        display: none;
                    }
                        .workout-plan-add-header-folder-container h4 {
                            margin-top: 14px;
                            margin-left: 8px;
                        }
                        .workout-plan-add-header-folder-icon-container {
                            background: rgba(128,73,193,0.12);
                            width: 38px;
                            height: 38px;
                            border-radius: 5px;
                            padding: 11px;
                        }
                            .workout-plan-add-header-folder-icon {
                                font-size: 16px !important;
                                color: #8049C1;
                            }
                        .workout-plan-add-header-cycle-icon-container {
                            background: rgba(184,193,73,0.12);
                        }
                            .workout-plan-add-header-cycle-icon {
                                color: #B8C149 !important;
                            }
                        

                .workout-plan-add-button-container {
                    width: 100%;
                    display: flex;
                    /*padding-left: 16px;*/
                }
                    .workout-plan-add-button {
                        width: calc(33% - 8px);
                        max-width: 400px;
                        background: #1F1F21;
                        border-radius: 5px;
                        border: solid;
                        border-width: 0.5px;
                        border-color: #2E3247;
                        padding: 0px 16px;
                        margin: 0px auto;
                        margin-bottom: 8px;
                        box-shadow: 0 0px 12px 0 rgba(0, 0, 0, 0.1);
                        -webkit-transition: all 0.15s;
                        -moz-transition: all 0.15s;
                        transition: all 0.15s;  
                        display: block;
                    }
                        
                        .workout-plan-add-button-unselected {
                            opacity: 0.36 !important;
                        }
                        .workout-plan-add-button-unselected:hover {
                            background: #1F1F21 !important;
                            cursor: default;
                        }
                        .workout-plan-add-button-content {
                            padding: 16px 0px;
                            padding-bottom: 8px;
                        }
                            .workout-plan-add-button-header-container {
                                width: 100%;
                                display: flex;
                            }
                                .workout-plan-add-button-header-container h4 {
                                    margin-left: 8px;
                                    padding-top: 3px;
                                }
                            .workout-plan-add-button-body-container {
                                width: 100%;
                                padding-top: 12px;
                            }
                                .workout-plan-add-button-body-container p {
                                    color: #9DA0AD;
                                    font-size: 13.5px;
                                }
                            .workout-plan-add-button-icon-container {
                                width: 22px;
                                height: 22px;
                                border-radius: 5px;
                                padding: 2px 5px;
                            }

                                .workout-plan-add-button-icon-container-folder {
                                    background: rgba(128,73,193,0.12);
                                }
                                .workout-plan-add-button-icon-container-cycle {
                                    background: rgba(184,193,73,0.12);
                                }
                                .workout-plan-add-button-icon-container-plan {
                                    background: rgba(73,89,193,0.12);
                                }
                                .workout-plan-add-button-icon {
                                    font-size: 12px !important;
                                }
                                    .workout-plan-add-button-icon-folder {
                                        color: #8049C1;
                                    }
                                    .workout-plan-add-button-icon-cycle {
                                        color: #B8C149;
                                    }
                                    .workout-plan-add-button-icon-plan {
                                        color: #4959C1;
                                    }
                            .workout-plan-add-button-text-container {
                                padding-left: 12px;
                                padding-top: 5px;
                            }
                                .workout-plan-add-button-text-container p {
                                    color: #9DA0AD;
                                }
                            .workout-plan-add-button-open-icon-container {
                                margin-top: 18px;
                                width: 100px;
                                border-radius: 5px;
                                padding: 6px 12px;
                                padding-bottom: 7px;
                                display: flex;
                                background: #1F1F21;
                                border: solid;
                                border-width: 0.5px;
                                border-color: #2B2B30;
                                box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.08);
                                -webkit-transition: all 0.15s;
                                -moz-transition: all 0.15s;
                                transition: all 0.15s;  
                            }
                                .workout-plan-add-button-open-icon-container:hover {
                                    background: #28282D;
                                    cursor: pointer;
                                    -webkit-transition: all 0.15s;
                                    -moz-transition: all 0.15s;
                                    transition: all 0.15s;  
                                }
                                .workout-plan-add-button-open-icon-container p {
                                    font-size: 10px;
                                    color: #ffffff;
                                    margin-right: 4px;
                                }
                                .workout-plan-add-button:hover .workout-plan-add-button-open-icon {
                                    color: white;
                                    -webkit-transition: all 0.15s;
                                    -moz-transition: all 0.15s;
                                    transition: all 0.15s;  
                                }
                                .workout-plan-add-button-open-icon {
                                    font-size: 11px !important;
                                    float: right;
                                    margin-left: auto;
                                    margin-top: 1px;
                                    color: #ffffff;
                                    -webkit-transition: all 0.15s;
                                    -moz-transition: all 0.15s;
                                    transition: all 0.15s;  
                                }
                    .workout-plan-add-button-details-container {
                        width: 100%;
                        padding: 12px 0px;
                        border-top: solid;
                        border-width: 0.5px;
                        border-color: #2B2B30;
                        min-height: 100px;
                    }
                        .workout-plan-add-button-details-container p {
                            color: #ffffff;
                        }
                        .workout-plan-add-button-details-container-content {
                            padding-left: 24px;
                            width: 80%;
                            display: flex;
                        }
                            .workout-plan-add-button-details-container-content-loading {
                                padding-top: 14px;
                            }
                                .workout-plan-add-button-details-container-content-loading p {
                                    font-size: 13.5px;
                                    color: #ffffff;
                                }
                            .workout-plan-add-button-details-container-content-done {
                                padding-top: 14px;
                                display: flex;
                            }
                                .workout-plan-add-button-details-container-content-done h4 {
                                    font-size: 13.5px;
                                    color: #ffffff;
                                    margin: 0px;
                                    margin-right: 8px;
                                }
                                .workout-plan-add-button-details-container-content-done p {
                                    font-size: 13.5px;
                                    color: #ffffff;
                                    margin: 0px;
                                    margin-top: -3px;
                                }

                        .workout-plan-add-button-details-container-loading {
                            width: 100%;
                            padding-top: 32px;
                        }
                            .workout-plan-add-button-details-container-loading p {
                                width: 100%;
                                text-align: center;
                                
                            }
                            .workout-plan-add-button-details-container-loading h4 {
                                width: 100%;
                                text-align: center;
                            }
                .workout-plan-list-header-add-menu-content-input-container {
                    width: 100%;
                    padding-right: 12px;
                }
                .workout-plan-list-header-add-menu-content-input-button {
                        width: 100%;
                        max-width: 140px;
                        padding: 8px;
                        font-size: 13.5px;
                        text-align: center;
                        border-radius: 5px;
                        padding: 20px 0px;
                        margin-top: 10px;
                        padding-top: 9px !important;
                        height: 38px !important;
                    }
                        .workout-plan-list-header-add-menu-content-input-button-dark {
                            background: #181B2A;
                            border: solid;
                            border-width: 0.5px;
                            border-color: #2E3247;
                            color: #6C6C6C;
                            height: 12px;
                            box-shadow: 0 2px 20px 0 rgba(5, 5, 14, 0.2);
                            -webkit-transition: all 0.15s;
                            -moz-transition: all 0.15s;
                            transition: all 0.15s;  
                        }
                        .workout-plan-list-header-add-menu-content-input-button-dark:hover {
                           /* background-color: #1E2236;*/
                            cursor: default;
                            /*box-shadow: 0 2px 20px 0 rgba(0, 0, 200, 0.05);
                            -webkit-transition: all 0.15s;
                            -moz-transition: all 0.15s;
                            transition: all 0.15s;  */
                        }
                        .workout-plan-list-header-add-menu-content-input-button-blue {
                            background: #4959C1;
                            border: none;
                            color: #ffffff;
                            /*padding-top: 9px;*/
                            box-shadow: 0 2px 20px 0 rgba(23, 23, 40, 0.2);
                            -webkit-transition: all 0.15s;
                            -moz-transition: all 0.15s;
                            transition: all 0.15s;  
                        }
                        .workout-plan-list-header-add-menu-content-input-button-blue:hover {
                            background-color: #5C6BC9;
                            cursor: pointer;
                            box-shadow: 0 2px 20px 0 rgba(0, 0, 200, 0.05);
                            -webkit-transition: all 0.15s;
                            -moz-transition: all 0.15s;
                            transition: all 0.15s;  
                        }

                        .workout-plan-list-header-add-menu-content-input-button-cancel {
                            margin-left: 24px;
                            width: 100px !important;
                            color: #9DA0AD !important;
                            position: absolute;
                            bottom: 40px;
                        }
                        .workout-plan-list-header-add-menu-content-input-button-cancel:hover {
                            background-color: #1E2236;
                            color: #ffffff !important;
                            cursor: pointer;
                            -webkit-transition: all 0.15s;
                            -moz-transition: all 0.15s;
                            transition: all 0.15s;  
                        }
                        .workout-plan-list-header-add-menu-content-input-button-done {
                            margin-left: 24px;
                            margin-top: 24px;
                            background: #4959C1 !important;
                            width: 100px !important;
                            border: none !important;
                            color: #ffffff !important;
                            -webkit-transition: all 0.15s;
                            -moz-transition: all 0.15s;
                            transition: all 0.15s; 
                        }
                        .workout-plan-list-header-add-menu-content-input-button-done:hover {
                            background-color: #5C6BC9 !important;
                            cursor: pointer;
                            box-shadow: 0 2px 20px 0 rgba(0, 0, 200, 0.05);
                            -webkit-transition: all 0.15s;
                            -moz-transition: all 0.15s;
                            transition: all 0.15s;  
                        }
            .workout-plan-list-header-add-menu-content-input-container-cycles {
                display: flex;
                width: 100%;
                padding: 12px 0px;
                padding-right: 12px;
            }
            .workout-plan-list-header-add-menu-content-input-container-cycles-spacing {
                margin-right: 12px;
            }
                .workout-plan-list-header-add-menu-content-input-content {
                        width: 33%;
                        border-radius: 5px;
                        border-width: 1px !important;
                        border-color: #2B2B30 !important;
                        border: solid;
                        height: 36px;
                        padding: 9px 0px;
                        margin: 0px auto;
                    }
                        .workout-plan-list-header-add-menu-content-input-content h4 {
                            font-size: 13.5px;
                            width: 100%;
                            text-align: center;
                            margin: 0px;
                            color: #ffffff;
                        }
                .workout-plan-list-header-add-menu-content-input-content-button {
                        width: calc(33% - 12px);
                        border-radius: 5px;
                        border-width: 1px !important;
                        border-color: #2B2B30 !important;
                        border: solid;
                        height: 36px;
                        padding: 9px 0px;
                        background: rgba(0,0,0,0.2);
                        box-shadow: 0 4px 38px 0 rgba(0, 0, 0, 0.1);
                        -webkit-transition: all 0.15s;
                        -moz-transition: all 0.15s;
                        transition: all 0.15s;  
                    }
                        .workout-plan-list-header-add-menu-content-input-content-button:hover {
                            background-color: #1E2236;
                            cursor: pointer;
                            box-shadow: 0 2px 20px 0 rgba(0, 0, 200, 0.05);
                            -webkit-transition: all 0.15s;
                            -moz-transition: all 0.15s;
                            transition: all 0.15s;  
                        }
                        .workout-plan-list-header-add-menu-content-input-content-button h3 {
                            font-size: 13.5px;
                            width: 100%;
                            text-align: center;
                            margin: 0px;
                            color: #ffffff;
                        }
                        .workout-plan-list-header-add-menu-content-input-content-button:hover h3 {
                            color: white;
                        }

.workout-plan-info-card-container {
    position: absolute;
    bottom: 20px;
    width: 80%;
    left: 10%;
    display: none;
}

    .workout-plan-info-container {
        width: 100%;
        border-radius: 5px;
        background: #1F1F21;
        display: flex;
        border: solid;
        border-width: 0.5px;
        border-color: #2B2B30;
        padding: 12px 12px;
        margin-top: 32px;
    }
        .workout-plan-info-icon-container {
            width: 32px;
        }
            .workout-plan-info-icon {
                font-size: 13px !important;
                color: #4959C1;
            }
        .workout-plan-info-content-container {

        }
            .workout-plan-info-content-container h4 {
                margin-bottom: 3px;
            }
                .workout-plan-info-content-container span {
                    color: #4959C1;
                }
                .workout-plan-info-content-container:hover span {
                    color: #5C6BC9;
                    cursor: pointer;
                }

/*
.workout-plan-list {
    width: 100%;
}

    .workout-plan-list-header {
        width: 100%;
        padding: 16px 0px;
        border-bottom: solid;
        border-color: #2B2B30;
        border-width: 1px;
        display: flex;
        height: 64px;
    }
        .workout-plan-list-header-path-container {
            display: flex;
            padding-top: 6px;
        }
            .workout-plan-list-header-path-content {
                display: flex;
            }
                .workout-plan-list-header-path-content p {
                    font-size: 13.5px;
                    color: #9DA0AD;
                }
                .workout-plan-list-header-path-divider {
                    padding: 0px 12px;
                }
        .workout-plan-list-header-button-container {
            display: flex;
            margin-left: auto;
            position: relative;
        }
            .workout-plan-list-header-button {
                padding: 8px 24px;
                padding-right: 12px;
                display: flex;
                font-size: 10px;
                border-radius: 5px;
                background: black;
                margin-left: 12px;
                color: #ffffff !important;
            }
                .workout-plan-list-header-button p {
                    margin-right: 8px;
                    font-weight: 500;
                }
                .workout-plan-list-header-upper-content-container-buttons-icon {
                    font-size: 10px !important;
                    margin-top: 2px;
                }

                .workout-plan-list-header-button-dark {
                    background: #181B2A;
                    border: solid;
                    border-width: 0.5px;
                    border-color: #2E3247;
                    box-shadow: 0 2px 20px 0 rgba(5, 5, 23, 0.2);
                }

                .workout-plan-list-header-button-blue {
                    background: #4959C1;
                    border: none;
                    color: white !important;
                    padding-top: 9px;
                    box-shadow: 0 2px 20px 0 rgba(23, 23, 200, 0.2);
                }
        .workout-plan-list-header-add-menu-container {
            position: absolute;
            width: 360px;
            right: 110px;
            top: 48px;
            background: #1A1B2A;
            z-index: 200;
            border-radius: 5px;
            box-shadow: 0 2px 24px 12px rgba(5, 5, 23, 0.12);
            -webkit-transition: all 0.35s;
            -moz-transition: all 0.35s;
            transition: all 0.35s;
        }
        .workout-plan-list-header-add-menu-container-cycle {
            right: 0px;
        }
            .workout-plan-list-header-add-menu-content {
                position: relative;
                padding: 16px;
                padding-top: 0px;
                height: 168px;
            }
            .workout-plan-list-header-add-menu-content-loading {
                width: 100%;
                padding-top: 0px;
            }
                .workout-plan-list-header-add-menu-content-loading p {
                    font-size: 10px;
                    width: 100%;
                    text-align: center;
                    margin: 0px;
                    padding-top: 68px;
                }
            .workout-plan-list-header-add-menu-content-complete {
                width: 100%;
                padding-top: 0px;
            }
                .workout-plan-list-header-add-menu-content-complete h4 {
                    font-size: 13.5px;
                    width: 100%;
                    text-align: center;
                    margin: 0px;
                    padding-top: 54px;
                }
                .workout-plan-list-header-add-menu-content-complete p {
                    font-size: 10px;
                    width: 100%;
                    text-align: center;
                    margin: 0px;
                }
                .workout-plan-list-header-add-menu-triangle {
                    position: relative;
                    top: -12px;
                    left: 170px;
                    width: 0;
                    height: 0;
                    border-left: 12px solid transparent;
                    border-right: 12px solid transparent;
                    border-bottom: 20px solid #1A1B2A;
                }
                    .workout-plan-list-header-add-menu-content-header {

                    }
                        .workout-plan-list-header-add-menu-content-header h4 {
                            font-size: 13.5px;
                            color: #ffffff;
                            margin: 0px;
                            margin-bottom: 4px;
                        }
                        .workout-plan-list-header-add-menu-content-header p {
                            font-size: 10px;
                            color: #9DA0AD;
                            margin: 0px;
                        }
                    .workout-plan-list-header-add-menu-content-input-button {
                        width: 100%;
                        padding: 8px;
                        font-size: 13.5px;
                        text-align: center;
                        border-radius: 5px;
                    }
                        .workout-plan-list-header-add-menu-content-input-button-dark {
                            background: #181B2A;
                            border: solid;
                            border-width: 0.5px;
                            border-color: #2E3247;
                            color: #6C6C6C;
                            box-shadow: 0 2px 20px 0 rgba(5, 5, 23, 0.2);
                        }
                        .workout-plan-list-header-add-menu-content-input-button-blue {
                            background: #4959C1;
                            border: none;
                            color: #ffffff;
                            padding-top: 9px;
                            box-shadow: 0 2px 20px 0 rgba(23, 23, 200, 0.2);
                        }

                .workout-plan-list-header-add-menu-content-input-container-cycles {
                    display: flex;
                    width: 100%;
                    padding: 12px 0px;
                }
                    .workout-plan-list-header-add-menu-content-input-content {
                        width: 33%;
                        border-radius: 5px;
                        border-width: 1px !important;
                        border-color: #2B2B30 !important;
                        border: solid;
                        height: 36px;
                        padding: 9px 0px;
                        margin: 0px auto;
                    }
                        .workout-plan-list-header-add-menu-content-input-content h4 {
                            font-size: 13.5px;
                            width: 100%;
                            text-align: center;
                            margin: 0px;
                            color: #ffffff;
                        }
                    .workout-plan-list-header-add-menu-content-input-content-button {
                        width: calc(33% - 12px);
                        border-radius: 5px;
                        border-width: 1px !important;
                        border-color: #2B2B30 !important;
                        border: solid;
                        height: 36px;
                        padding: 9px 0px;
                        background: rgba(0,0,0,0.2);
                        box-shadow: 0 4px 38px 0 rgba(0, 0, 0, 0.1);
                    }
                        .workout-plan-list-header-add-menu-content-input-content-button h3 {
                            font-size: 13.5px;
                            width: 100%;
                            text-align: center;
                            margin: 0px;
                            color: #ffffff;
                        }
    .workout-plan-list-content {

    }
        .workout-plan-list-content-container {

        }
            .workout-plan-list-content-container h4 {
                font-size: 13.5px;
                color: #ffffff;
            }
            .workout-plan-list-content-container-list {
                width: 100%;
                min-height: 150px;
            }
            .workout-plan-list-content-container-list-empty {
                width: 100%;
                min-height: 150px;
                padding-top: 30px;
            }
                .workout-plan-list-content-container-list-empty h4 {
                    width: 100%;
                    text-align: center;
                    font-size: 13.5px;
                    color: #9DA0AD;
                    margin: 0px;
                    margin-bottom: 4px;
                }
                .workout-plan-list-content-container-list-empty p {
                    width: 100%;
                    text-align: center;
                    font-size: 10px;
                    color: #6C6C6C;
                }
            .workout-plan-list-card {
                display: inline-block;
                width: calc(25% - 12px);
                margin-right: 12px;
                margin-bottom: 12px;
                background: #1F1F21;
                padding: 16px;
                border-radius: 5px;
                box-shadow: 0 4px 38px 0 rgba(0, 0, 0, 0.1);
            }
            .workout-plan-list-card-folder {

            }
                .workout-plan-list-card-container {
                    display: flex;
                }
                .workout-plan-list-card-icon-container {
                    width: 30px;
                    height: 30px;
                    border-radius: 5px;
                    padding: 6px;
                    padding-left: 9px;
                }
                .workout-plan-list-card-icon-container-folder {
                    background: rgba(128,73,193,0.12);
                }
                .workout-plan-list-card-icon-container-plan {
                    background: rgba(73,89,193,0.2);
                }
                    .workout-plan-list-card-icon {
                        font-size: 13.5px !important;
                        
                    }
                        .workout-plan-list-card-icon-folder {
                            color: #8049C1;
                        }
                        .workout-plan-list-card-icon-plan {
                            color: #4959C1;
                        }

                .workout-plan-list-card-content-container {
                    padding-left: 12px;
                }
                    .workout-plan-list-card-content-container h4 {
                        font-size: 13.5px;
                        color: #ffffff;
                        margin: 0px;
                        margin-top: 2px;
                    }
                    .workout-plan-list-card-content-container p {
                        font-size: 10px;
                        color: #9DA0AD;
                        margin: 0px;
                    }
            .workout-plan-plan-content-list-container {
                width: 100%;
                padding-top: 24px;
            }
            .workout-plan-plan-content-list-header {
                padding-top: 16px;
            }
                .workout-plan-plan-content-list-header h4 {
                    font-size: 13.5px;
                    color: #ffffff;
                    margin: 0px;
                    margin-top: 2px;
                }
                .workout-plan-plan-content-list-header p {
                    font-size: 10px;
                    color: #9DA0AD;
                    margin: 0px;
                    margin-top: 2px;
                }

        .workout-plan-list-content-container-empty {
            width: 100%;
            padding-top: calc(50vh - 180px);
        }
            .workout-plan-list-content-container-empty-header {
                width: 100%;

            }
                .workout-plan-list-content-container-empty-header h4 {
                    width: 100%;
                    text-align: center;
                    font-size: 13.5px;
                    color: #ffffff;
                    margin: 0px;
                    margin-bottom: 4px;
                }
                .workout-plan-list-content-container-empty-header p {
                    width: 100%;
                    text-align: center;
                    font-size: 10px;
                    color: #ffffff;
                    margin: 0px;
                }

*/



