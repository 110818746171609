/* .info-hover- */

.info-hover {
	position: relative;
	width: 12px;
	height: 12px;
}
.info-hover:hover {
	cursor: pointer;
}
	.info-hover-button {
		width: 12px;
		height: 12px;
		border-radius: 3px;
		background: rgba(0,0,0,0.35);
		padding: 1px 0px;
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}
	.info-hover:hover .info-hover-button {
		background: #5C6BC9;
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}
	
		.info-hover-button p {
			font-size: 8px;
			color: #ffffff;
			margin: 0px;
			width: 100%;
			text-align: center;
			font-weight: 900;
		}
	.info-hover-text-container {
		display: none;
		max-width: 240px;
		width: 100vw;
		background: #161825;
		padding: 12px 16px;
		border-radius: 5px;
		box-shadow: 0 2px 24px 4px rgba(0, 0, 0, 0.1);
		border: solid;
		border-width: 0.5px;
		border-color: #2B2B30;
		position: absolute;
		z-index: 500;
		left: 0px;
		bottom: 18px;
	}
	.info-hover-text-container-bottom {
		top: 12px !important;
		bottom: auto;
	}
		.info-hover-text-container p {
			color: #ffffff !important;
			font-size: 13.5px !important;
			margin: 0px;
		}
		.info-hover:hover .info-hover-text-container {
			display: block !important;
			
		}
