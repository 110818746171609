.new-workout-header-container {
	padding: 32px 0px;
	padding-top: 0px;
	display: flex;
	width: 100%;
}

.new-workout-progress-circle {
	background-color: #161825;
	color: #ffffff;
	font-size: 18px;
	width: 50px;
	height: 50px;
	border-radius: 5px;
	text-align: center;
	padding-top: 13px;
}

.new-workout-header {
	padding-left: 16px;
	padding-top: 3px;
}

.new-workout-header h1 {
	margin: 0px;
	font-size: 18px;
	color: #ffffff;
}

.new-workout-workflow-state-container {
	display: flex;
	padding-top: 2px;
}

.new-workout-workflow-state-container p {
	margin: 0px;
	font-size: 14px;
	margin-right: 0px;
}

.new-workout-selected-workflow-state-container {
	color: #5B62FF;
	padding: 4px 12px;
	border-radius: 4px;
	background-color: #242C45;

}
.new-workout-selected-workflow-state-container p {
	font-size: 10px !important;
}

.new-workout-selected-workflow-state {
	color: #5B62FF;
}

.new-workout-unselected-workflow-state-container {
	color: #5F6C76;
	padding: 4px 12px;
}
.new-workout-unselected-workflow-state-container p {
	font-size: 10px !important;
}

.new-workout-unselected-workflow-state {
	color: #5F6C76;
}

.new-workout-completed-workflow-state-container {
	color: #5B62FF;
	padding: 4px 12px;
}
.new-workout-completed-workflow-state-container p {
	font-size: 10px !important;
}

.new-workout-completed-workflow-state {
	color: #5B62FF;
}

.new-workout-inner-page {
	width: 100%;
	height: calc(100% - 4px);
}

.new-workout-inner-page-container {
	width: 100%;
	height: 100%;
}