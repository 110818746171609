
.workout-summary-card {
	width: 100%;
	height: 350px;
	background: #101012;
	border: solid;
	border-width: 1px;
	border-color: #202020;
	border-radius: 16px;
	position: relative;
	overflow: hidden;
	margin-bottom: 12px;
}
	.workout-summary-card-header {
		padding: 16px 16px;
		display: flex;
		align-items: center;
		z-index: 1;
		position: relative;
	}
		.workout-summary-card-header-icon-container {
			background: #fff;
			width: 32px;
			height: 32px;
			border-radius: 6px;
			align-items: center;
			justify-content: center;
			padding: 6px 6px;
		}
			.workout-summary-card-header-icon {
				color: black;
				font-size: 18px !important;
			}
		.workout-summary-card-header h4 {
		    font-size: 14px;
		    color: #ffffff;
		    margin: 0px;
		    margin-left: 12px;
		}

	.workout-summary-card-body-container {
		width: 100%;
		height: calc(100% - 110px);
		padding: 16px;
		z-index: 1;
		position: relative;
	}
		.workout-summary-card-body-status-container {
			width: 100%;
		}
			.workout-summary-card-body-status-container h1 {
			    font-size: 60px;
			    font-weight: 200;
			    color: #ffffff;
			    margin: 0px;
			}
			.workout-summary-card-body-status-container h4 {
			    font-size: 21px;
			    font-weight: 400;
			    color: #ffffff70;
			    margin: 0px;
			}
		.workout-summary-card-body-status-info-container {
			width: 100%;
			padding: 24px 0px;
		}
			.workout-summary-card-body-status-info-container h4 {
			    font-size: 18px;
			    color: #6C6C6C;
			    margin: 0px;
			    font-weight: 300;
			}
			.workout-summary-card-body-status-info-container span {
				color: #ffffff;
			}
			.workout-summary-card-body-status-info-container p {
				font-size: 13.5px;
			    color: #6C6C6C;
			    margin: 0px;
			    font-weight: 300;
			    margin-top: 32px;
			}

			.workout-summary-card-body-status-info-col-container {
				width: 100%;
				display: flex;
				padding-top: 48px;
			}
				.workout-summary-card-body-status-info-col {
					width: 33%;
				}
					.workout-summary-card-body-status-info-container h2 {
					    font-size: 32px;
					    color: #ffffff;
					    margin: 0px;
					    font-weight: 300;
					}
					.workout-summary-card-body-status-info-container p {
						font-size: 13.5px;
					    color: #6C6C6C;
					    margin: 0px;
					    font-weight: 300;
					    margin-top: 6px;
					}

	.workout-summary-card-selector-container {
		width: 100%;
		display: flex;
		padding: 16px;
		z-index: 1;
		position: relative;
	}
		.workout-summary-card-selector-demo {
			width: 36px;
			border-radius: 2px;
			height: 4px;
			background: #6C6C6C;
		}

	.workout-summary-card-bg-container {
		width: 100%;
		height: 100%;
		border-radius: 16px;
		position: absolute;
		z-index: 0;
		top: 0px;
		left: 0px;
	}
		.workout-summary-card-bg-image {
			width: 100%;
			height: 100%;
		}