.menu {
	width: 100%;
	height: 100%;
	padding: 0px;
}

.menu-container {
	height: 100%;
	width: 100%;
	position: relative;
}

	.menu-eigen-icon-container {
		width: 100%;
		padding-top: 15px;
		display: flex;
		padding-bottom: 17px;
		border-bottom: solid;
		border-width: 1px;
		border-color: #2B2B30;
	}
		.menu-eigen-icon {
			width: 18px;
			height: 18px;
			margin: auto;
			margin-top: 0px;
			margin-bottom: 0px;
			position: relative;
		}
			.menu-eigen-icon-circle {
				width: 100%;
				height: 100%;
				border-radius: 50%;
				background: #1D242F;
				box-shadow: 0 2px 20px 0 rgba(23, 23, 200, 0.4);
				border-width: 2px !important;
				border: solid;
				border-color: #5B62FF;
			}
			.menu-eigen-icon-line {
				width: 51%;
				height: 2px;
				border-radius: 1px;
				background: #5B62FF;
				right: 0px;
				top: 8px;
				position: absolute;
			}

.profile-container {
	display: flex;
}
.profile-container:hover {
	background: rgba(73,89,193,0.05);
	cursor: pointer;
}

.profile-container h2 {
	color: #C1C4CC;
}

.profile-image {
	height: 100% !important;
	width: 100% !important;
	border-radius: 5px !important;
}

.profile-image-container {
	background-color: rgba(0,0,0,0.3);
	width: 30px !important;
	height: 30px !important;
	max-width: 30px;
	max-height: 30px;
	border-radius: 5px !important;
	margin-bottom: 12px !important;
	margin: auto;
	overflow: hidden;
}

.profile-level-pill {
	background-color: rgba(100,100,255,0.1);
	/*background-color: #4A5AC1;*/
	padding: 4px 12px;
	color: rgba(100,100,255);
	font-size: 10px;
	border-radius: 4px;
	width: 100px;
	margin: auto;
}

.profile-level-pill-small {
	padding: 4px 2px !important;
	width: 60px !important;
	margin-top: 12px;
}

.menu-content-container-upper {
	/*padding-top: 12px;*/
	height: calc(100% - 228px) !important;
	/*min-height: 484px;*/
	overflow-y: scroll;

	/*height: calc(75% - 80px);*/
	/*min-height: calc(100% - 300px);*/
}
.menu-content-container-lower {
	height: 179px !important;
	border-top: solid;
	border-width: 1px;
	border-color: #2B2B30;
}
	.menu-bottom-gym-container {
		width: 100%;
		height: 60px;
		margin-top: 16px;
		background: #1F1F21;
		display: flex;
		padding: 16px 14px;
		-webkit-transition: all 0.15s;
	  	-moz-transition: all 0.15s;
	  	transition: all 0.15s;
	}
	.menu-bottom-gym-container:hover {
		background: #1F1F21;
		cursor: pointer;
		-webkit-transition: all 0.15s;
	  	-moz-transition: all 0.15s;
	  	transition: all 0.15s;
	}
		.menu-bottom-gym-icon-container {
			width: 28px;
			height: 28px;
			min-width: 28px;

			border-radius: 14px;
			background: #5B62FF;
		}
			.menu-bottom-gym-icon-container img {
				border-radius: 5px;
			}
		.menu-bottom-gym-name-container {
			display: none;
			width: calc(100% - 28px);
			padding-left: 12px;
		}
			.menu-bottom-gym-name-container p {
				font-size: 13.5px;
				color: #9DA0AD;
				margin: 0px;
				margin-top: 6px;
			}
	.menu-cell-trial {
		display: none;
	}

.menu-cell {
	height: 50px;
	width: 100%;
	margin: 0px;
	display: flex;
	position: relative;
	-webkit-transition: all 0.35s;
  	-moz-transition: all 0.35s;
  	transition: all 0.35s;
}
.menu-cell:hover {
	background: #1F1F21;
	border-radius: 5px;
	cursor: pointer;
	-webkit-transition: all 0.35s;
  	-moz-transition: all 0.35s;
  	transition: all 0.35s;
}
	.menu-icon {
		font-size: 16px !important;
		margin: auto;
	}
		.menu-icon-selected {
			color: #5B62FF;
			-webkit-transition: all 0.35s;
  			-moz-transition: all 0.35s;
  			transition: all 0.35s;
		}
		.menu-icon-unselected {
			color: #ffffff;
			-webkit-transition: all 0.35s;
  			-moz-transition: all 0.35s;
  			transition: all 0.35s;
		}
		.menu-icon-menu-icon-white {
			color: #ffffff;
			-webkit-transition: all 0.35s;
  			-moz-transition: all 0.35s;
  			transition: all 0.35s;
		}
	

.selected-cell {
	background: #1F1F21;
	border-radius: 5px;
	color: #5B62FF;
	-webkit-transition: all 0.35s;
  	-moz-transition: all 0.35s;
  	transition: all 0.35s;
}

.unselected-cell {
	color: #6C6C6C;
	-webkit-transition: all 0.35s;
  	-moz-transition: all 0.35s;
  	transition: all 0.35s;
}

.icon-container {

	width: 34px;
	height: 34px;
	margin: auto;
	background: rgba(0,0,0,0);
}

.selected-dot {
	display: none;
	position: absolute;
	left: 178px;
	top: 14px;
	height: 32px;
	width: 2px;
	border-radius: 1px;
	background-color: #4A5AC1;
	-webkit-transition: all 0.35s;
  	-moz-transition: all 0.35s;
  	transition: all 0.35s;
  	z-index: 200;
}

.contact-card {
	position: absolute;
	left: 0px;
	bottom: -20px;
	padding: 10px 18px;
	background: rgba(0,0,0,0.3);
	height: 80px;
	width: 100%;
	border-radius: 4px;
	box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
}

.contact-card h4 {
	color: #C1C4CC;
}

.contact-card-small {
	width: 60px !important;
	height: 60px !important;
	left: 10px !important;
}

.contact-card p {
	opacity: 0.7;
	font-size: 12px;
	margin: 0px;
	margin-top: 10px;
}

.contact-card h4 {
	font-size: 16px;
	margin: 0px;
	margin-top: 2px;
}

.hide-on-desktop {
	display: block;
}

.show-on-desktop {
	display: none;
}




.workout-running-card {

	padding: 12px 13px;
	background: #5B62FF;
	width: 40px;
	height: 40px;
	margin: 12px auto;
	border-radius: 4px;
	box-shadow: 0 2px 20px 0 rgba(0, 0, 200, 0.1);
}

.workout-running-card-small {
	width: 60px !important;
	height: 60px !important;
	margin-left: 10px !important;
	padding: 14px 4px;
	text-align: center;
}
.workout-running-card-small p {
	opacity: 1.0 !important;
}

.workout-running-card h4 {
	font-size: 16px;
	margin: 0px;
	margin-top: 2px;
}


.workout-running-card p {
	opacity: 0.7;
	font-size: 12px;
	margin: 0px;
}



@media screen and (min-width: 1100px) {
	.menu-eigen-icon-container {
		width: 100%;
		padding-top: 2px;
		display: flex;
		padding-bottom: 5px;
	}
	.profile-image-container {
		width: 30px !important;
		height: 30px !important;
		min-width: 30px;
		min-height: 30px;
		border-radius: 5px !important;
		background: rgba(0,0,0,0.3);
		margin: 7px 12px !important;
	}
	.profile-text-container {

	}
		.profile-text-container p {
			font-size: 10px;
			color: #6C6C6C;
			margin: 0px;
		}
		.profile-text-container h4 {
			font-size: 13.5px;
			color: #ffffff;
			margin: 0px;
			font-weight: 700;
			width: calc(100% - 10px);
		}


	.menu {
		padding: 0px 0px;
		padding-bottom: 32px;
	}

	.menu-content-container-upper {
		/*padding-top: 12px;*/
		height: calc(100% - 194px) !important;
		padding-top: 12px;
		/*height: calc(75% - 80px);*/
		/*min-height: calc(100% - 300px);*/
	}
	.menu-content-container-lower {
		padding-top: 12px;
	}

	.menu-bottom-gym-container {
		padding: 16px;
	}
	.menu-bottom-gym-name-container {
		display: block;
			width: calc(100% - 28px);
			padding-left: 12px;
		}
			.menu-bottom-gym-name-container p {
				font-size: 13.5px;
				font-weight: 500;
				color: #9DA0AD;
				margin: 0px;
				margin-top: 6px;
			}

	.icon-container {
		width: 40px;
		height: 40px;
		margin: auto;
	}

	.menu-eigen-icon {
		width: 18px;
		height: 18px;
		margin-left: 22px;
		margin-right: 12px;
		margin-top: 16px;
		margin-bottom: 0px;
		position: relative;
	}
		.menu-eigen-text-container {
			padding-top: 0px;
			margin-top: -5px;
			margin-left: 22px;
			margin-bottom: -2px;
		}
			.menu-eigen-text-container h4 {
				font-size: 20px;
				color: #ffffff;
				font-weight: 700;
			}
			.menu-eigen-text-container span {
				font-size: 15px;
				color: #ffffff;
				font-weight: 700;
				padding-left: 4px;
			}

	.workout-running-card {

		padding: 12px 13px;
		background: #5B62FF;
		width: calc(100% - 24px);
		height: 50px;
		margin: 12px auto;
		border-radius: 4px;
		box-shadow: 0 2px 20px 0 rgba(0, 0, 200, 0.1);
		display: flex;
	}
		.workout-running-card .menu-text {
			width: 100px;
			margin-top: -17px;
			margin-left: 8px;
		}
			.workout-running-card .menu-icon {
				margin: 0px;
				margin-top: 7px;
				margin-left: 2px;
				font-size: 13.5px !important;
			}
		.workout-running-card h4 {
			margin: 0px;
		}

	.menu-cell {
		height: 41px;
		width: calc(100% - 24px);
		margin: 0px auto;
		display: flex;
		position: relative;
		margin-bottom: 2px;
	}

	.menu-icon {
		font-size: 17px !important;
		margin-left: 10px;
		margin-right: 12px;
	}

	.menu-text {
		width: calc(100% - 20px);
		padding-top: 12px;
	}
		.menu-text h4 {
			color: #5B62FF;
			font-size: 13.5px;
			margin: 0px;
		}
		.menu-text-unselected h4 {
			color: #ffffff;
		}

	.menu-section-header {
		width: 100%;
		margin-top: 24px;
		margin-bottom: 4px;
	}
		.menu-section-header h4 {
			color: #6C6C6C;
			font-size: 13.5px;
			margin: 0px;
			padding-left: 22px;
		}

	.menu-content-feedback-container {
		width: calc(100% - 24px);
		margin: 0px auto;
		margin-top: 12px;
		padding: 12px;
		padding-right: 8px;
		background: #1F1F21;
		border: solid;
		border-width: 0.5px;
		border-color: #2B2B30;
		border-radius: 5px;
		box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
	}
		.menu-content-feedback-container-header {
			display: flex;
			width: 100%;
		}
			.menu-content-feedback-container-header h5 {
				font-size: 13.5px;
				color: #ffffff;
				margin: 0px;
			}

			.menu-content-feedback-container-header-icon-container {
				height: 14px;
				width: 14px;
				margin-right: 6px;
			}
				.menu-content-feedback-container-header-icon {
					font-size: 13.5px !important;
					color: #5B62FF;
				}
		.menu-content-feedback-container-body {
			width: 100%;
			padding-left: 20px;
			padding-top: 4px;
		}
			.menu-content-feedback-container-body p {
				font-size: 12px;
				color: #6C6C6C;
				margin: 0px;
			}
			.menu-content-feedback-container-body-action-container {
				width: 100%;
				display: flex;
				padding-top: 8px;
			}
			.menu-content-feedback-container-body-action-container:hover {
				cursor: pointer;
			}
				.menu-content-feedback-container-body-action-container p {
					font-size: 13.5px;
					color: #5B62FF;
					margin: 0px;
					margin-right: 6px;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
				.menu-content-feedback-container-body-action-icon {
					font-size: 12px !important;
					color: #5B62FF;
					margin-top: 3px;
				}
			.menu-content-feedback-container-body-action-container:hover p {
				color: #5C6BC9 !important;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}

		.menu-cell-trial {
			background: #5B62FF;
			display: block;
			height: 52px;
			border-radius: 5px;
			box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.1);
			width: calc(100% - 40px);
			margin: 0px 20px;
			margin-top: 8px;
			padding: 8px 12px;
		}
			.menu-cell-trial h4 {
			    font-size: 13.5px;
			    color: white;
			    margin: 0px;
			    font-weight: 600;
			}
			.menu-cell-trial p {
			    font-size: 13.5px;
			    color: white;
			    margin: 0px;
			}

	.selected-dot {
		right: 8px;
	}

  	.hide-on-desktop {
		display: none;
	}

	.show-on-desktop {
		display: block;
	}
}

/*
.menu {
	width: 100%;
	height: 100%;
	padding: 0px;
}

.menu-container {
	height: 100%;
	width: 100%;
	position: relative;
}

	.menu-eigen-icon-container {
		width: 100%;
		height: 80px;
		padding-top: 24px;
		display: flex;
	}
		.menu-eigen-icon {
			width: 22px;
			height: 22px;
			margin: auto;
			margin-top: 24px;
			margin-bottom: 0px;
			position: relative;
		}
			.menu-eigen-icon-circle {
				width: 100%;
				height: 100%;
				border-radius: 50%;
				background: #1D242F;
				box-shadow: 0 2px 20px 0 rgba(200, 23, 23, 0.4);
				border-width: 3px !important;
				border: solid;
				border-color: #F02E4A;
			}
			.menu-eigen-icon-line {
				width: 51%;
				height: 3px;
				border-radius: 1.5px;
				background: #F02E4A;
				right: 0px;
				top: 9.5px;
				position: absolute;
			}

.profile-container {
	display: flex;
}
.profile-container:hover {
	background: rgba(73,89,193,0.05);
	cursor: pointer;
}

.profile-container h2 {
	color: #C1C4CC;
}

.profile-image {
	height: 100% !important;
	width: 100% !important;
	border-radius: 5px !important;
}

.profile-image-container {
	background-color: rgba(0,0,0,0.3);
	width: 30px !important;
	height: 30px !important;
	max-width: 30px;
	max-height: 30px;
	border-radius: 5px !important;
	margin-bottom: 12px !important;
	margin: auto;
	overflow: hidden;
}

.profile-level-pill {
	background-color: rgba(100,100,255,0.1);
	padding: 4px 12px;
	color: rgba(100,100,255);
	font-size: 10px;
	border-radius: 4px;
	width: 100px;
	margin: auto;
}

.profile-level-pill-small {
	padding: 4px 2px !important;
	width: 60px !important;
	margin-top: 12px;
}

.menu-content-container-upper {
	padding-top: 12px;
	height: calc(75% - 80px);
	min-height: calc(100% - 200px);
}
.menu-content-container-lower {
	height: 25%;
	max-height: 120px;
}

.menu-cell {
	height: 60px;
	width: 100%;
	margin: 0px;
	display: flex;
	position: relative;
}
.menu-cell:hover {
	background: rgba(73,89,193,0.1);
	border-radius: 5px;
	cursor: pointer;
}
	.menu-icon {
		font-size: 16px !important;
		margin: auto;
	}
		.menu-icon-selected {
			color: #4959C1;
			-webkit-transition: all 0.35s;
  			-moz-transition: all 0.35s;
  			transition: all 0.35s;
		}
		.menu-icon-unselected {
			color: #6C6C6C;
			-webkit-transition: all 0.35s;
  			-moz-transition: all 0.35s;
  			transition: all 0.35s;
		}
		.menu-icon-menu-icon-white {
			color: #ffffff;
			-webkit-transition: all 0.35s;
  			-moz-transition: all 0.35s;
  			transition: all 0.35s;
		}

.selected-cell {
	color: #4959C1;
	-webkit-transition: all 0.35s;
  	-moz-transition: all 0.35s;
  	transition: all 0.35s;
}

.unselected-cell {
	color: #6C6C6C;
	-webkit-transition: all 0.35s;
  	-moz-transition: all 0.35s;
  	transition: all 0.35s;
}

.icon-container {

	width: 34px;
	height: 34px;
	margin: auto;
	background: rgba(0,0,0,0);
}

.selected-dot {
	position: absolute;
	left: 178px;
	top: 14px;
	height: 32px;
	width: 2px;
	border-radius: 1px;
	background-color: #4A5AC1;
	-webkit-transition: all 0.35s;
  	-moz-transition: all 0.35s;
  	transition: all 0.35s;
  	z-index: 200;
}

.contact-card {
	position: absolute;
	left: 0px;
	bottom: -20px;
	padding: 10px 18px;
	background: rgba(0,0,0,0.3);
	height: 80px;
	width: 100%;
	border-radius: 4px;
	box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
}

.contact-card h4 {
	color: #C1C4CC;
}

.contact-card-small {
	width: 60px !important;
	height: 60px !important;
	left: 10px !important;
}

.contact-card p {
	opacity: 0.7;
	font-size: 12px;
	margin: 0px;
	margin-top: 10px;
}

.contact-card h4 {
	font-size: 16px;
	margin: 0px;
	margin-top: 2px;
}

.hide-on-desktop {
	display: block;
}

.show-on-desktop {
	display: none;
}




.workout-running-card {

	padding: 12px 13px;
	background: #4959C1;
	width: 40px;
	height: 40px;
	margin: 12px auto;
	border-radius: 4px;
	box-shadow: 0 2px 20px 0 rgba(0, 0, 200, 0.1);
}

.workout-running-card-small {
	width: 60px !important;
	height: 60px !important;
	margin-left: 10px !important;
	padding: 14px 4px;
	text-align: center;
}
.workout-running-card-small p {
	opacity: 1.0 !important;
}

.workout-running-card h4 {
	font-size: 16px;
	margin: 0px;
	margin-top: 2px;
}


.workout-running-card p {
	opacity: 0.7;
	font-size: 12px;
	margin: 0px;
}

@media screen and (min-width: 1100px) {
	.profile-image-container {
		width: 30px !important;
		height: 30px !important;
		min-width: 30px;
		min-height: 30px;
		border-radius: 5px !important;
		background: rgba(0,0,0,0.3);
		margin: 7px 12px !important;
	}
	.profile-text-container {

	}
		.profile-text-container p {
			font-size: 10px;
			color: #6C6C6C;
			margin: 0px;
		}
		.profile-text-container h4 {
			font-size: 13.5px;
			color: #ffffff;
			margin: 0px;
			font-weight: 700;
			width: calc(100% - 10px);
		}


	.menu {
		padding: 0px 0px;
		padding-bottom: 32px;
	}

	.icon-container {
		width: 40px;
		height: 40px;
		margin: auto;
	}

	.menu-eigen-icon {
		width: 22px;
		height: 22px;
		margin-left: 28px;
		margin-right: 16px;
		margin-top: 24px;
		margin-bottom: 0px;
		position: relative;
	}
		.menu-eigen-text-container {
			padding-top: 8px;
		}
			.menu-eigen-text-container h4 {
				font-size: 18px;
				color: #ffffff;
			}
			.menu-eigen-text-container span {
				font-size: 18px;
				color: #F02E4A;
				font-weight: 700;
			}
	.workout-running-card {

		padding: 12px 13px;
		background: #4959C1;
		width: calc(100% - 24px);
		height: 50px;
		margin: 12px auto;
		border-radius: 4px;
		box-shadow: 0 2px 20px 0 rgba(0, 0, 200, 0.1);
		display: flex;
	}
		.workout-running-card .menu-text {
			width: 100px;
			margin-top: -17px;
			margin-left: 8px;
		}
			.workout-running-card .menu-icon {
				margin: 0px;
				margin-top: 7px;
				margin-left: 2px;
				font-size: 13.5px !important;
			}
		.workout-running-card h4 {
			margin: 0px;
		}
	.menu-icon {
		font-size: 17px !important;
		margin-left: 28px;
		margin-right: 12px;
	}

	.menu-text {
		width: calc(100% - 80px);
		padding-top: 23px;
	}
		.menu-text h4 {
			color: #ffffff;
			font-size: 13.5px;
			margin: 0px;
		}
		.menu-text-unselected h4 {
			color: #6C6C6C;
		}

	.selected-dot {
		right: 8px;
	}

  	.hide-on-desktop {
		display: none;
	}

	.show-on-desktop {
		display: block;
	}
}
*/
