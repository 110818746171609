.selector-input-container {
	position: relative;
	padding-top: 0px;
	padding-bottom: 0px;
	cursor: pointer  !important;
}

.selector-input-container p {
	margin: 0px;
}

.selector-text {
	width: calc(100% - 30px);
	cursor: pointer;
}

.selector-text-input-container {
	/*background-color: rgba(0,0,0,0.1);*/
	padding: 7px 12px;
	padding-right: 0px;
	padding-top: 9px;
	padding-bottom: 5px;
	width: auto;
	border-radius: 5px;
	border: solid;
	border-color: #2B2B30;
	border-width: 1px !important;
	display: flex;
	color: #ffffff;
	font-size: 13.5px;
	/*box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);*/
	cursor: pointer !important;
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
}
.selector-text-input-container:hover {
	background-color: rgba(0,0,0,0.1);
	box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
}

.selector-text-input-container-alert {
	background-color: rgba(0,0,0,0.1);
	padding: 18px 24px;
	padding-bottom: 12px;
	width: auto;
	border-radius: 4px;
	border: solid;
	border-color: #F02E4A;
	border-width: 1px !important;
	display: flex;
}

.selector-text-input-container-search {
	background-color: #2E3A48;
	padding: 0px 12px;
	width: auto;
	border-radius: 5px;
	margin: 8px 0px;
	cursor: pointer;
	
}

.selector-text-input-label {
	font-size: 14px;
	color: #C1C4CC;
	position: absolute;
	top: 0px;
	left: 0px;
	cursor: pointer;
}

.selector-menu-container {
	z-index: 100;
	top: 42px;
	position: absolute;
	background-color: #1F1F21;
	border: solid;
	border-width: 0.5px;
	border-color: #2B2B30;
	box-shadow: 0 2px 24px 6px rgba(0, 0, 0, 0.24);
	width: 100%;
	border-radius: 4px;
	padding: 4px 0px;
	max-height: calc(50vh - 200px);
	min-height: 60px;
	overflow-y: scroll;
}

.selector-menu-icon-container {
	float: right;
	padding-top: 2px;
	padding-bottom: 1px;
	color: #6C6C6C;
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;	
}
.selector-menu-icon-container:hover {
	color: #9DA0AD;
	cursor: pointer;
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;	
}
	.selector-text-input-container-open {
		border-color: #5B62FF;
		background-color: rgba(0,0,0,0.1);
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}
	.selector-text-input-container-open:hover {
		border-color: #7278F2;
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}

	.selector-menu-icon {
		
		font-size: 13.5px !important;
	}


.selector-menu-option {
	color: #9DA0AD;
	font-size: 13.5px !important;
	padding: 8px 20px;
	border-bottom: solid;
	border-width: 0.5px;
	border-color: #2B2B30;
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
}

.selector-menu-option:hover {
	color: #ffffff;
	background: rgba(35,40,54,0.4);
	cursor: pointer !important;
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
}

.selector-menu-option-selected {
	color: white;
	font-size: 13.5px !important;
	font-weight: 500;
	padding: 8px 20px;
	color: #ffffff;
	cursor: default;
	border-bottom: solid;
	border-width: 0.5px;
	border-color: #2B2B30;
}