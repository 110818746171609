.client-goal-progress-card {
	padding: 0px;
	width: 100%;
	border-top: solid;
	border-width: 0.5px;
	border-color: #2B2B30;
	padding-bottom: 24px;
}
	.client-goal-progress-card-header {
		
		width: 100%;
		padding: 24px 36px;
		padding-right: 0px;
		padding-bottom: 24px;
		position: relative;
	}
		.client-goal-progress-card-header-title {
			display: flex;
		}
		.client-goal-progress-card-header-title h4 {
			font-size: 13.5px;
			color: #ffffff;
			margin: 0px;
			font-weight: 600;
		}
		.client-goal-progress-card-header-desc {
			padding-top: 4px;
			font-size: 13.5px;
			color: #ffffff;
			margin: 0px;
			font-weight: 400;
			width: calc(100% - 126px);
		}
		.client-goal-progress-card-header-active {
			margin-left: 12px;
			background: rgba(91,98,255,0.2);
			padding: 3px 8px;
			padding-bottom: 4px;
			border-radius: 5px;
			margin-top: -3px;
		}
			.client-goal-progress-card-header-active p {
				font-weight: 500;
				font-size: 10px;
				color: rgba(112,138,240,1.0);
				width: 100%;
			}

			.client-goal-progress-card-header-menu-button {
				position: absolute;
				right: 32px;
				top: 28px;
				padding: 5px 12px;
				border-radius: 5px;
				border: solid;
				border-width: 0.5px;
				border-color: #2B2B30;
				display: flex;
				height: 28px;
				background: #1F1F21;
				box-shadow: 0 1px 6px 3px rgba(0, 0, 0, 0.1);
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
				.client-goal-progress-card-header-menu-button:hover {
					background: #1F1F21 !important;
					cursor: pointer;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
				/*.client-goal-progress-card:hover .client-goal-progress-card-header-menu-button {
					box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
					background: #1F1F21;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
				.client-goal-progress-card:hover .client-goal-progress-card-header-menu-button p {
					color: #ffffff;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}*/
				.client-goal-progress-card-header-menu-button p {
					font-size: 12px;
					color: #9DA0AD;
					width: 100%;
					margin: 0px !important;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
				.client-goal-progress-card-header-menu-button:hover p {
					color: #ffffff;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
				.client-goal-progress-card-header-menu-button-icon {
					font-size: 13.5px !important;
					color: #9DA0AD;
					margin-top: 1px;
					margin-right: 6px;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
				/*.client-goal-progress-card:hover .client-goal-progress-card-header-menu-button-icon {
					color: #ffffff;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}*/


			.client-goal-progress-card-header-menu-container {
				top: 60px;
				right: 32px;
			}

	.client-goal-progress-card-chart-container {
		width: 100%;
		padding: 32px;
		padding-top: 6px;
		padding-bottom: 0px;
	}

	.client-goal-progress-card-info-container {
		padding: 0px 36px;
		width: 100%;
	}
		.client-goal-progress-card-info-header-container {
			width: 100%;
			border-bottom: solid;
			border-width: 1px;
			border-color: #2B2B30;
			width: 100%;
			padding-right: 0px;
			padding-bottom: 12px;
			padding-top: 12px;
			position: relative;
			display: flex;
			cursor: pointer;
		}
			.client-goal-progress-card-info-header-text-container {
				display: flex;
				padding-top: 6px;
			}
				.client-goal-progress-card-info-header-text-container h4 {
					font-size: 13.5px;
					color: #ffffff;
					margin: 0px;
					font-weight: 600;
				}

			.client-goal-progress-card-info-expand-icon-container {
				width: 28px;
				height: 28px;
				border-radius: 5px;
				padding: 6px;
				margin-top: -6px;
				margin-left: 16px;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
			.client-goal-progress-card-info-header-text-container:hover .client-goal-progress-card-info-expand-icon-container {
				background: rgba(0,0,0,0.2);
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
				.client-goal-progress-card-info-expand-icon {
					font-size: 16px !important;
					color: #ffffff !important;
				}
			.client-goal-progress-card-info-save-button {
				position: absolute;
				right: 0px;
				margin-top: -6px;
				float: right;
				margin-right: 0px;
				padding: 6px 10px;
				background: #5B62FF;
				border-radius: 5px;
				box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
				margin-left: 200px;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
			.client-goal-progress-card-info-save-button:hover {
				background: #5C6BC9;
				cursor: pointer;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
				.client-goal-progress-card-info-save-button {
					font-size: 13.5px;
					color: #ffffff;
					margin: 0px;
				}
		.client-goal-progress-card-info-content {
			
		}
			.client-goal-progress-card-info-content-log-list-header {
				display: flex;
				padding: 12px 8px;
				padding-top: 12px;
				border-bottom: solid;
				border-width: 0.5px;
				border-color: #2B2B30;
				background: #1F1F21;
			}
				.client-goal-progress-card-info-content-log-list-header-col {

				}
					.client-goal-progress-card-info-content-log-list-header-col p {
						font-size: 10px;
					    color: #9DA0AD;
					    margin: 0px;
					}
				.client-goal-progress-card-info-content-log-list-header-col-0 {
					width: 50%;
				}
				.client-goal-progress-card-info-content-log-list-header-col-1 {
					width: 20%;
				}
				.client-goal-progress-card-info-content-log-list-header-col-2 {
					width: 30%;
				}


			.client-goal-progress-card-info-content-inner-actions-container {
				/*display: flex;*/
				width: calc(100% + 72px);
				overflow-x: scroll;
				padding: 0px 36px;
				margin-left: -36px;
			}

		.client-goal-progress-content-lower {
			width: 100%;
			display: flex;
		}
			.client-goal-progress-card-info-content-inner-goals-empty {
				width: 100%;
				padding: 20px;
				padding-bottom: 84px;
			}
				.client-goal-progress-card-info-content-inner-goals-empty h4 {
				    font-size: 13.5px;
				    color: #ffffff;
				    margin: 0px;
				    font-weight: 600;
				    margin-bottom: 6px;
				}

				.client-goal-progress-card-info-content-inner-goals-empty p {
				    font-size: 13.5px;
				    color: #ffffff;
				    margin: 0px;
				    line-height: 20px;
				}

				.client-goal-progress-card-info-content-button {
					float: left;
					position: relative;
					margin-top: 12px;
					padding: 6px 12px;
					border-radius: 5px;
					background: #5B62FF;
					box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
				.client-goal-progress-card-info-content-button:hover {
					background: #5C6BC9;
					cursor: pointer;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
					.client-goal-progress-card-info-content-button p {
						font-size: 13.5px;
					    color: #ffffff;
					    margin: 0px;
					}







