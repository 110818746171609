.referral-register {
	width: 100vw;
	height: 100vh;
	display: flex;
}

	.referral-register-left-pane {
		width: 33%;
		background: #28282D;
		padding: 36px 42px;
	}
	.referral-register-left-pane-icon-container {
		background: #161719;
		box-shadow: 0px 0px 12px 6px rgba(0, 0, 0, 0.07);
		width: 36px;
		height: 36px;
		min-width: 36px;
		min-height: 36px;
		border-radius: 5px;
		padding: 8px 0px;
		position: relative;
	}
		.referral-register-left-pane-background-container {
			position: absolute;
			z-index: 0;
			height: calc(100vh + 80px);
			min-height: calc(100vh + 80px);
			min-width: calc(33vw + 80px);
			top: -40px;
			left: -40px;
		}
			.referral-register-left-pane-background-container img {
				height: 100%;
				min-width: 100%;
			}
		.referral-register-eigen-icon-container {
			width: 100%;
			padding-top: 6px;
			display: flex;
			padding-bottom: 12px;
		}
			.referral-register-eigen-icon {
				width: 18px;
				height: 18px;
				margin: auto;
				margin-top: 0px;
				margin-bottom: 0px;
				position: relative;
			}
				.referral-register-eigen-icon-circle {
					width: 100%;
					height: 100%;
					border-radius: 50%;
					background: #1D242F;
					box-shadow: 0 2px 20px 0 rgba(23, 23, 200, 0.4);
					border-width: 2px !important;
					border: solid;
					border-color: #4959C1;
				}
				.referral-register-eigen-icon-line {
					width: 51%;
					height: 2px;
					border-radius: 1px;
					background: #4959C1;
					right: 0px;
					top: 8px;
					position: absolute;
				}
	.referral-register-left-pane-text-container {
		width: 100%;
		padding-top: calc(50vh - 180px);
		max-width: 320px;
		position: relative;

	}
		.referral-register-left-pane-text-container h2 {
			font-size: 28px;
			color: #ffffff;
			margin: 0px;
			margin-bottom: 12px;
		}
		.referral-register-left-pane-text-container h5 {
			font-size: 16px;
			color: #9DA0AD;
			margin: 0px;
			line-height: 26px;
			font-weight: 400;
		}
	.referral-register-left-pane-name-container {
		position: absolute;
		z-index: 2;
		bottom: 36px;
	}
		.referral-register-left-pane-name-container h4 {
			font-size: 13.5px;
			color: #9DA0AD;
			margin: 0px;
		}

	.referral-register-right-pane {
		position: relative;
		background: #161719;
		width: 67%;
		border-left: solid;
		border-width: 1px;
		border-color: #2B2B30;
		box-shadow: 0px 18px 24px 12px rgba(0, 0, 0, 0.07);
		padding-bottom: 48px;
		overflow-y: scroll;
	}	
		.referral-register-right-pane-loading-curtain {
			position: absolute;
			z-index: 10;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
			background: rgba(12,13,20,0.05);
			-webkit-backdrop-filter: blur(2px);
		}
			.referral-register-right-pane-loading-container {
				width: 100%;
				margin-top: calc(50vh - 30px);
			}
				.referral-register-right-pane-loading-container p {
					font-size: 13.5px;
					color: #6C6C6C;
					margin: 0px;
					margin-top: 8px;
					width: 100%;
					text-align: center;
				}
		.referral-register-right-pane-content {
			padding: 36px 12px;
			width: 80%;
			margin: 0px auto;
		}
			.referral-register-right-pane-content-header {
				padding-bottom: 24px;
			}
				.referral-register-right-pane-content-header h2 {
					font-size: 28px;
					color: #ffffff;
					margin: 0px;
					margin-bottom: 12px;
				}
				.referral-register-right-pane-content-header p {
					font-size: 13.5px;
					color: #9DA0AD;
					margin: 0px;
				}
				.referral-register-right-pane-content-header span {
					font-weight: 500;
					color: #ffffff;
				}
			.referral-register-right-pane-content-body {
				max-width: 600px;
				padding-top: 42px;
			}
				.referral-register-right-pane-content-body-text-inputs {
					z-index: 0;
				}
				.referral-register-right-pane-content-body-email-container {
					height: 38px;
					/*background-color: rgba(0,0,1,0.1);*/
					background-color: none;
					padding: 9px 16px;
					width: auto;
					border-radius: 5px;
					border: solid;
					border-color: #2B2B30;
					border-width: 1px !important;
					font-size: 13.5px;
					color: #ffffff;
					margin-top: 10px;
					margin-bottom: 12px;
				}
					.referral-register-right-pane-content-body-email-container p {
						font-size: 13.5px;
						color: #ffffff;
						margin: 0px;
					}
				.referral-register-right-pane-content-body-experience-header {
					width: 100%;
					padding: 16px 0px;
				}
					.referral-register-right-pane-content-body-experience-header h4 {
						font-size: 13.5px;
						color: #ffffff;
						margin: 0px;
						font-weight: 400;
					}

				.referral-register-right-pane-content-body-agree-container {
					display: flex;
					padding: 36px 0px;
					position: relative;
				}
					.referral-register-right-pane-content-body-agree-text {
						margin-left: 12px;
					}
						.referral-register-right-pane-content-body-agree-text p {
							font-size: 13.5px;
							color: #9DA0AD;
							margin: 0px;
						}
						.referral-register-right-pane-content-body-agree-text a {
							color: #4959C1;
							text-decoration: none;
							-webkit-transition: all 0.15s;
							-moz-transition: all 0.15s;
							transition: all 0.15s;
						}
						.referral-register-right-pane-content-body-agree-text a:hover {
							color: #5C6BC9;
							text-decoration: none;
							-webkit-transition: all 0.15s;
							-moz-transition: all 0.15s;
							transition: all 0.15s;
						}
				.referral-register-right-pane-content-body-continue-container {
					border-bottom: solid;
					border-width: 1px;
					border-color: #2B2B30;
					padding-bottom: 64px;
				}
					.referral-register-right-pane-content-body-continue-button {
						padding: 8px 12px;
						border-radius: 5px;
						background: #4959C1;
						float: left;
						margin-bottom: 12px;
						-webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}
					.referral-register-right-pane-content-body-continue-button:hover {
						background: #5C6BC9;
						cursor: pointer;
						-webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}
						.referral-register-right-pane-content-body-continue-button p {
							font-size: 12px;
							color: white;
							margin: 0px;
						}
						.referral-register-right-pane-content-body-continue-button-inactive {
							opacity: 0.3;
							-webkit-transition: all 0.15s;
							-moz-transition: all 0.15s;
							transition: all 0.15s;
						}
						.referral-register-right-pane-content-body-continue-button-inactive:hover {
							background: #4959C1;
							cursor: default;
							-webkit-transition: all 0.15s;
							-moz-transition: all 0.15s;
							transition: all 0.15s;
						}
					.referral-register-right-pane-content-body-signin-container {
						width: 100%;
						padding: 24px 0px;
						display: flex;
					}
						.referral-register-right-pane-content-body-signin-container h4 {
							font-size: 13.5px;
							color: #ffffff;
							margin: 0px;
						}
						.referral-register-right-pane-content-body-signin-container h5 {
							font-size: 13.5px;
							color: #4959C1;
							margin: 0px;
							margin-left: 12px;
							-webkit-transition: all 0.15s;
							-moz-transition: all 0.15s;
							transition: all 0.15s;
						}
						.referral-register-right-pane-content-body-signin-container h5:hover {
							color: #5C6BC9;
							cursor: pointer;
							-webkit-transition: all 0.15s;
							-moz-transition: all 0.15s;
							transition: all 0.15s;
						}
				.referral-register-right-pane-content-body-agree-alert-container {
					width: 16px;
					height: 16px;
					border-radius: 8px;
					position: absolute;
					top: 36px;
					left: -32px;
					padding: 3px;
					background: rgba(240,46,74,0.3);
				}
					.referral-register-right-pane-content-body-agree-alert-inner {
						width: 10px;
						height: 10px;
						border-radius: 5px;
						background: rgba(240,46,74,1);
					}
		.referral-register-right-pane-complete-container {
			width: 80%;
			margin: 0px auto;
			padding-top: calc(50vh - 110px);
		}
			.referral-register-right-pane-complete-container h2 {
				font-size: 28px;
				color: #ffffff;
				margin: 0px;
				margin-bottom: 12px;
			}
			.referral-register-right-pane-complete-container p {
				font-size: 16px;
				color: #9DA0AD;
				margin: 0px;
				line-height: 26px;
				font-weight: 400;
				max-width: 600px;
			}
			.referral-register-right-pane-content-body-dashboard-button {
				margin-top: 32px;
			}
				.referral-register-right-pane-content-body-dashboard-button p {
					color: white;
					font-size: 12px;
					line-height: normal;
				}

