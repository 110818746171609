.register-container {
	padding: 0px;
	position: relative;
}

.register-background-container {
	width: 100%;
	height: 100%;
}
	.register-background-image {
		object-fit: cover;
		max-width: 100vw;
		min-height: 100vh;
		opacity: 0.2;
	}

	.register-logo-container {
		position: absolute;
		top: 24px;
		left: 24px;
	}
		.register-logo-container p {
			font-size: 13.5px;
			color: #ffffff;
			font-weight: 500;
		}
		.register-logo-container span {
			color: #F02E4A;
			font-weight: 700;
		}

.register-header-container {
	width: 100%;
	text-align: left;
	display: flex;
}
	.register-header-container h1 {
		font-size: 18px;
		margin: 0px;
		margin-bottom: 16px;
		color: #ffffff;
	}

	.register-header-close-button-container {
		margin-left: auto;
		margin-top: -12px;
	}
		.register-header-close-button {
			width: 40px;
			height: 40px;
			background: rgba(0,0,0,0.2);
			border-radius: 5px;
			padding-left: 13px;
			padding-top: 11px;
		}
			.register-header-close-button-close-icon {
				font-size: 15px !important;
				color: #9DA0AD;
			}

.register-header-container p {
	margin: 0px;
	opacity: 0.5;
}

.register-header-container p:hover {
	opacity: 1.0;
}

.register-info-block-header {
	padding-bottom: 12px;
}

.register-info-block {
	/*text-align: left;
	border-radius: 4px;
	padding: 36px 40px;
	background-color: #2E3A48;
	width: 80%;
	margin: auto;
	min-width: 500px;
	box-shadow: 0 4px 50px 0 rgba(0, 0, 0, 0.08);*/
	position: absolute;
	z-index: 200;
	top: calc(50vh - 207px);
	left: calc(50% - 220px);
	border-radius: 10px;
	padding: 38px 24px;
	background-color: #1F1F21;
	border-color: #2A2A2F !important;
	border-width: 1px !important;
	border: solid;
	margin: auto;
	width: 480px;
	height: 414px;
	box-shadow: 0 0px 50px 12px rgba(0, 0, 0, 0.24);
}

.register-info-block h2 {
	line-height: 42px;
}

.register-info-block p {
	font-size: 14px;
	color: #C2C5CD;
}

.button-container {
	position: absolute;
	bottom: 0px;
	width: calc(100% - 48px);
	height: 80px;
}

.loading-container {
	width: 100%;
	padding-top: 20px;
	text-align: center;
}

.configuring-account-container {
	height: 300px;
	width: 100%;
	text-align: center;
	padding-top: 36px;
}
	.configuring-account-container h1 {
		font-size: 18px;
		color: #ffffff;
	}
	.configuring-account-container p {
		font-size: 13.5px;
		color: #9DA0AD;
	}

.register-copyright-container {
	position: absolute;
	top: calc(100vh - 36px);
	left: 0px;
	width: 100%;
	text-align: center;
	
	color: #6C6C6C;
	opacity: 0.5;
}
	.register-copyright-container p {
		font-size: 8px !important;
	}

.register-error-text {
	color: red !important;
	font-size: 10px;
	margin-bottom: 32px !important;
	margin-top: -24px !important;
}

