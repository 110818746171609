.create-workout-exercise-list-cell-container {
    border: solid;
    border-width: 1px;
    border-color: #2B2B30;
    border-radius: var(--ef-radius-m);
    margin-bottom: 6px;
    overflow: hidden;
    box-shadow: 0px 0px 6px 3px rgba(0,0,0, 0.08);
    background: var(--ef-card);
    -webkit-transition: all 0.15s;
    -moz-transition: all 0.15s;
    transition: all 0.15s;
}
.create-workout-exercise-list-cell-container:hover {
    box-shadow: 0px 0px 16px 12px rgba(0,0,0, 0.12);
    -webkit-transition: all 0.15s;
    -moz-transition: all 0.15s;
    transition: all 0.15s;
}
    .create-workout-exercise-list-cell-container-merge {
        border-color: #5B62FF;
        box-shadow: 0px 0px 16px 12px rgba(92,98,255, 0.12);
    }

.create-workout-exercise-list-adjust-container {
    display: flex;
    width: 100%;
    padding: 0px;
    position: relative;
    background: var(--ef-card);
    height: 102px;
    z-index: 5;
    overflow: hidden;
    border-bottom: solid;
    border-width: 1px;
    border-color: #2B2B30;
    -webkit-transition: all 0.15s;
    -moz-transition: all 0.15s;
    transition: all 0.15s;
}
.create-workout-exercise-list-adjust-container:hover {
    background: var(--ef-card-light) !important;
    -webkit-transition: all 0.15s;
    -moz-transition: all 0.15s;
    transition: all 0.15s;
}
    .create-workout-exercise-list-adjust-container-sm {
        height: 50px;
    }
    .create-workout-exercise-list-adjust-container-letter-wrapper {
        width: 50px;
        padding: 10px 10px;
        border-right: solid;
        border-width: 1px;
        border-color: var(--ef-border-highlight-dark);
    }
    .create-workout-exercise-list-adjust-container-inner {
        display: block;
        width: calc(100% - 50px);
        border-width: 1px !important;
        border-left: solid;
        border-color: var(--ef-border);
    }
    .create-workout-exercise-list-adjust-container-inner:hover {
        cursor: pointer;
    }
        .create-workout-exercise-list-adjust-container-inner-header {
            width: 100%;
            display: flex;
            padding: 10px 12px;
            padding-bottom: 0px;
        }
        .create-workout-exercise-list-adjust-container-inner-body {
            width: 100%;
            display: flex;
            padding: 0px 16px;
        }
            .create-workout-exercise-list-adjust-container-inner-body-video-container {
                width: 104px;
                height: 104px;
                float: right;
                margin-left: auto;
                padding: 8px;
                margin-top: -40px;
                margin-right: -16px;
                position: relative;
                z-index: 0;
            }
                .create-workout-exercise-list-adjust-container-inner-body-video-container-curtain {
                    position: absolute;
                    z-index: 1;
                    top: 0px;
                    left: 0px;
                    width: 100%;
                    height: 100%;
                    -webkit-transition: all 0.15s;
                    -moz-transition: all 0.15s;
                    transition: all 0.15s;
                }
                .create-workout-exercise-list-adjust-container:hover .create-workout-exercise-list-adjust-container-inner-body-video-container-curtain {
                    background: #28282DA0;
                    backdrop-filter: blur(4px);
                    -webkit-backdrop-filter: blur(4px);
                    -webkit-transition: all 0.15s;
                    -moz-transition: all 0.15s;
                    transition: all 0.15s;
                }
    
    .create-workout-exercise-list-adjust-container .create-workout-exercise-list-adjust-text {
        padding-top: 7px !important;
    }
    .create-workout-exercise-list-adjust-container .create-workout-exercise-list-cell-name-container {
        padding-top: 7px !important;

    }
    .create-workout-exercise-list-adjust-container .create-workout-exercise-list-cell-param-container {
        padding-top: 7px !important;
    }

    .create-workout-exercise-list-adjust-container-drag {
        padding-top: 5px;
        cursor: move; /* fallback if grab cursor is unsupported */
        cursor: grab;
        cursor: -moz-grab;
        cursor: -webkit-grab;
    }
        .create-workout-exercise-list-adjust-container-drag-icon {
            font-size: 20px !important;
            color: #6C6C6C;
        }
.create-workout-exercise-list-adjust-arrow-container {
    width: 100%;
    height: 20px;

}

.create-workout-exercise-list-adjust-arrow-active {
    color: #5B62FF;
    margin-left: 4px;
    margin-right: 14px;
}
.create-workout-exercise-list-adjust-arrow-active:hover {
    color: #5C6BC9;
    cursor: pointer;
}

.create-workout-exercise-list-adjust-arrow-inactive {
    color: rgba(73,89,193,0.4);
    margin-left: 4px;
    margin-right: 14px;
}

.create-workout-exercise-list-adjust-arrow-down {
    position: absolute;
    bottom: 8px;
    left: 8px;
}

.create-workout-exercise-list-series-show-arrow-icon-container {
    width: 22px;
    height: 22px;
    border-radius: 5px;
    padding: 2px 4px;
    margin-top: 4px;
    margin-right: 8px;
    -webkit-transition: all 0.15s;
    -moz-transition: all 0.15s;
    transition: all 0.15s;
}
.create-workout-exercise-list-series-show-arrow-icon-container:hover {
    cursor: pointer;
    background: rgba(0,0,0,0.3);
    -webkit-transition: all 0.15s;
    -moz-transition: all 0.15s;
    transition: all 0.15s;
}
    .create-workout-exercise-list-series-show-arrow-icon {
        color: #6C6C6C;
    }
    .create-workout-exercise-list-series-show-arrow-icon-container:hover .create-workout-exercise-list-series-show-arrow-icon {
        color: #ffffff;
    }
.create-workout-exercise-list-adjust-circle {
    width: 28px;
    min-width: 28px;
    height: 28px;
    padding: 1px;
    border-radius: var(--ef-radius-m);
    background: var(--ef-color-exercise-transparent);
    margin-top: 1px;
    cursor: pointer;
}
    .create-workout-exercise-list-adjust-circle p {
        width: 100%;
        margin: 0px;
        margin-top: 5px;
        text-align: center;
        font-size: 13.5px;
        font-weight: 700;
        color: var(--ef-color-exercise);
    }
.create-workout-exercise-list-adjust-text {
    padding: 8px 0px;
    display: flex;
    cursor: pointer;
}
    .create-workout-exercise-list-adjust-text h4 {
        font-size: 13.5px !important;
        color: #ffffff;
        margin: 0px;
        margin-left: 12px;
    }
    .create-workout-exercise-list-adjust-text p {
        font-size: 13.5px;
        color: #5B62FF;
        margin: 0px;
        margin-left: 8px;
    }
.create-workout-exercise-list-cell-content-container {
    width: 100%;
    display: block;
    position: relative;
    border-top: solid;
    border-width: 1px;
    border-color: var(--ef-border-highlight-dark);
    padding-left: 50px;
}


.create-workout-exercise-list-cell-content-outter {
    position: relative;
}
.create-workout-exercise-list-cell-content-branch-container {
    width: 50px;
    height: 50px;
    position: absolute;
    left: -26px;
    top: -574px;
}
    .create-workout-exercise-list-cell-content-branch-container {
        width: 23px;
        height: 600px;
        border-left: dotted;
        border-bottom: dotted;
        border-width: 2px;
        border-color: var(--ef-color-exercise);
        border-bottom-left-radius: 12px;
    }

    .create-workout-exercise-list-cell-content-branch-dot {
        width: 10px;
        height: 10px;
        border-radius: 5px;
        border-width: 1px;
        border-color: var(--ef-color-exercise) !important;
        background: var(--ef-color-exercise-transparent);
        border: solid;
        position: absolute;
        left: -4px;
        top: 20px;
        z-index: 5;
    }

.create-workout-exercise-list-cell-content-outter-container {
    display: flex;
}

.create-workout-exercise-list-cell-content-outter-multi {
    
}
    .create-workout-exercise-list-cell-content-multi-lettering-container {
        width: 28px;
        height: 28px;
        min-width: 28px;
        border-radius: var(--ef-radius-m);
        border: solid;
        border-width: 1px;
        border-color: var(--ef-color-exercise);
        padding-top: 5px;
        box-shadow: 0 0px 6px 0 rgba(23, 5, 23, 0.08);
        margin-right: 10px;
    }
        .create-workout-exercise-list-cell-content-multi-lettering-container p {
            font-size: 13.5px;
            font-weight: 500;
            color: var(--ef-color-exercise);
            width: 100%;
            text-align: center;
            margin: 0px;
        }

    .create-workout-exercise-list-cell-content {
        display: block;
        width: 100%;
        /*background-color: #1F1F21;*/
        /*padding: 10px 24px;*/
        
        /*height: 60px;*/
        position: relative;
    }
        .create-workout-exercise-list-cell-content-purposed {
            width: 100%;
            height: 4px;
            border-radius: 2px;
            background: #7278F2;
        }

    .create-workout-exercise-list-cell-content-multi {
        margin-left: -12px;
    }

        .create-workout-exercise-list-cell-content-upper {
            display: block;
            width: 100%;
            height: 104px;
            padding: 6px 0x;
            padding-bottom: 14px;
            position: relative;
            border-left: dotted;
            border-bottom: dotted;
            border-spacing: 2px;
            border-width: 2px;
            border-color: var(--ef-border);
            -webkit-transition: all 0.15s;
            -moz-transition: all 0.15s;
            transition: all 0.15s;
        }

        .create-workout-exercise-list-cell-content-upper:hover {
            background: #28282D;
            cursor: pointer;
            -webkit-transition: all 0.15s;
            -moz-transition: all 0.15s;
            transition: all 0.15s;
        }
            .create-workout-exercise-list-cell-content-upper-drag-container {
                width: 24px;
                padding: 3px 0px;
                cursor: grab;
            }
                .create-workout-exercise-list-cell-content-upper-drag-icon {
                    color: #6C6C6C;
                    font-size: 20px !important;
                    
                }
                .create-workout-exercise-list-cell-content-upper .create-workout-exercise-list-cell-superset-button-container {
                    padding-top: 6px;
                    margin-bottom: -6px;
                    margin-right: -4px;
                }

        .create-workout-exercise-list-cell-content-lower {
            display: block;
            width: 100%;
            
            padding: 4px 0px;
            padding-left: 0px;
            /*margin-top: 14px;*/
            /*background-color: #1F1F21;*/
        }
            .create-workout-exercise-list-cell-content-lower-reps-list-header {
                display: flex;
                width: 100%;
                padding-top: 4px;
                padding-left: 12px;
            }
            .create-workout-exercise-list-cell-content-lower-reps-list-content {
                display: block;
                width: 100%;
                margin-left: auto;
                float: right;
                padding-bottom: 24px;
                border-left: dotted;
                border-width: 1px;
                margin-bottom: 0px;
                padding-left: 12px;
                border-bottom: dotted;
                border-color: var(--ef-border);
                border-width: 1px;
            }
                .create-workout-exercise-list-cell-content-lower-reps-list-content-add-set-button {
                    padding: 8px 12px;
                    border-radius: 5px;
                    float: left;
                    -webkit-transition: all 0.15s;
                    -moz-transition: all 0.15s;
                    transition: all 0.15s;
                }
                .create-workout-exercise-list-cell-content-lower-reps-list-content-add-set-button:hover {
                    background: rgba(0,0,0,0.2);
                    cursor: pointer;
                    -webkit-transition: all 0.15s;
                    -moz-transition: all 0.15s;
                    transition: all 0.15s;
                }
                    .create-workout-exercise-list-cell-content-lower-reps-list-content-add-set-button p {
                        color: #5B62FF;
                        font-size: 13.5px;
                        font-weight: 600;
                        margin: 0px;
                    }

            .create-workout-exercise-list-cell-content-lower-options-container {
                width: 100%;
                display: flex;
                padding-top: 6px;
                /*border-top: solid;
                border-color: #2E3247;
                border-width: 1px;*/
                padding-bottom: 8px;
            }
                .create-workout-exercise-list-cell-content-lower-options-content {
                    /*width: 25%;*/
                    display: flex;
                    color: #9DA0AD;
                    background: #1F1F21;
                    padding: 6px 8px;
                    border-radius: 5px;
                    border: solid;
                    border-width: 0.5px;
                    border-color: #2B2B30;
                    box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.08);
                    margin-right: 8px;
                    -webkit-transition: all 0.15s;
                    -moz-transition: all 0.15s;
                    transition: all 0.15s;
                }
                .create-workout-exercise-list-cell-content-lower-options-content:hover {
                   /* background: rgba(30,40,54,0.4);*/
                    background: #1F1F21;
                    cursor: pointer;
                }
                .create-workout-exercise-list-cell-content-lower-options-unselected-content {
                    opacity: 0.3;
                    cursor: default !important;
                }
                    .create-workout-exercise-list-cell-content-lower-options-content p {
                        font-size: 10px;
                        font-weight: 500;
                        margin: 0px;
                    }
                    .create-workout-exercise-list-cell-content-lower-options-icon {
                        font-size: 12px !important;
                        margin-right: 6px;
                    }
                    .create-workout-exercise-list-cell-content-lower-options-icon-merge {
                        transform: rotate(180deg);
                    }
                    .create-workout-exercise-list-cell-content-lower-options-content-inner {
                        margin: 0px;
                        display: flex;
                        padding: 0px;
                        border-radius: 5px;
                        -webkit-transition: all 0.15s;
                        -moz-transition: all 0.15s;
                        transition: all 0.15s;
                        
                    }
                .create-workout-exercise-list-cell-content-lower-options-content-red {
                    float: right;
                    margin-left: auto;
                    margin-right: 0px;
                    color: #F02E4A !important;
                }

.create-workout-exercise-list-cell-name-container {
    display: block;
    width: 40%;
    overflow: hidden;
    padding-left: 8px;
    padding-top: 5px;
    
}
.create-workout-exercise-list-cell-name-container-header {
    padding-right: 4px;
    height: 26px !important;
    overflow-y: hidden;
    cursor: pointer;
}
    .create-workout-exercise-list-cell-name-container h2 {
        margin: 0px;
        color: #ffffff;
        font-size: 13.5px;
        font-weight: 500;
        white-space: nowrap;
        display: flex;
    }
        .create-workout-exercise-list-cell-name-container-letter {
            margin-right: 6px;
        }
    .create-workout-exercise-list-cell-name-container h4 {
        margin: 0px;
        color: #ffffff;
        font-size: var(--ef-font-size-m);
        font-weight: 500;
        margin-top: -1px;
    }
    .create-workout-exercise-list-cell-name-container p {
        margin: 0px;
        font-size: 10px;
        color: #9DA0AD;
    }

    .create-workout-exercise-list-cell-rep-row-container {
        display: flex;
    }
        .create-workout-exercise-list-cell-rep-col-container {
            display: flex;

        }
            .create-workout-exercise-list-cell-rep-col-container-editable {
                border-radius: 5px;
                border: solid;
                border-width: 1px;
                border-color: #2B2B30;
                padding-top: 7px !important;
                width: 80%;
                height: 32px;
                margin-left: -12px;
                margin-top: -10px;
                padding-left: 12px;

                -webkit-transition: all 0.15s;
                -moz-transition: all 0.15s;
                transition: all 0.15s;
            }
            .create-workout-exercise-list-cell-rep-col-container-editable:hover {
                border-color: #2B2B30;
                background: rgba(0,0,0,0.2);
                box-shadow: 0 0px 8px 4px rgba(3, 5, 15, 0.1);
                cursor: pointer;
                -webkit-transition: all 0.15s;
                -moz-transition: all 0.15s;
                transition: all 0.15s;
            }
            .create-workout-exercise-list-cell-rep-col-container-editable-focused {
                background: rgba(0,0,0,0.2);
                box-shadow: 0 0px 8px 4px rgba(3, 5, 15, 0.1);
                border-color: #5B62FF;
            }
            .create-workout-exercise-list-cell-rep-col-container-editable-focused:hover {
                background: rgba(0,0,0,0.2);
                box-shadow: 0 0px 8px 4px rgba(3, 5, 15, 0.1);
                border-color: #5B62FF;
            }
.create-workout-exercise-list-cell-param-container {
    display: block;
    width: 30%;
    overflow: hidden;
    padding-top: 14px;
    /*background: rgba(0,0,0,0.5) !important;*/
    border-radius: 5px;
}
    .create-workout-exercise-list-cell-param-container .create-workout-exercise-list-cell-rep-col-container-editable {
        margin-left: 0px;
    }
.create-workout-exercise-list-cell-param-container-small { 
    width: 15% !important;
    /*background: rgba(0,0,0,0.5) !important;*/
    border-radius: 5px;
}
    .create-workout-exercise-list-cell-param-container h2 {
        margin: 0px;
        color: #ffffff;
        font-size: 13.5px;
        font-weight: 500;
    }
    .create-workout-exercise-list-cell-param-container p {
        margin: 0px;
        font-size: 10px;
        color: #9DA0AD;
    }

        .create-workout-exercise-list-cell-param-colon {
            font-size: 10px;
            padding: 0px 2px;
            margin-top: -4px;
            color: #9DA0AD;
        }

    .create-workout-exercise-list-cell-expand-icon-container {
        position: absolute;
        left: 8px;
        top: 17px;
        
        border-radius: 5px;
        width: 22px;
        height: 22px;
        padding: 4px 4px;
        -webkit-transition: all 0.15s;
        -moz-transition: all 0.15s;
        transition: all 0.15s;
    }
    .create-workout-exercise-list-cell-expand-icon-container:hover {
        background: rgba(0,0,0,0.3);
        cursor: pointer;
        -webkit-transition: all 0.15s;
        -moz-transition: all 0.15s;
        transition: all 0.15s;
    }
        .create-workout-exercise-list-cell-expand-icon {
            font-size: 14px !important;
            color: #6C6C6C;
        }
        .create-workout-exercise-list-cell-expand-icon-container:hover .create-workout-exercise-list-cell-expand-icon {
            color: #ffffff;
        }

    .create-workout-exercise-list-cell-remove-icon-container {
        position: absolute;
        right: 8px;
        top: 17px;
        border-radius: 5px;
        width: 22px;
        height: 22px;
        padding: 4px 5px;
        -webkit-transition: all 0.15s;
        -moz-transition: all 0.15s;
        transition: all 0.15s;
    }
    .create-workout-exercise-list-cell-remove-icon-container:hover {
        background: rgba(0,0,0,0.3);
        cursor: pointer;
        -webkit-transition: all 0.15s;
        -moz-transition: all 0.15s;
        transition: all 0.15s;
    }
        .create-workout-exercise-list-cell-remove-icon-small {
            font-size: 14px !important;
            color: #6C6C6C;
            margin: 0px;
            position: relative;
        }
        .create-workout-exercise-list-cell-remove-icon-container:hover .create-workout-exercise-list-cell-remove-icon-small {
            color: #ffffff;
        }

.create-workout-exercise-list-cell-info-container {
    display: block;
    width: calc(50% - 20px);
    overflow: hidden;
}

.create-workout-exercise-list-cell-info-container p {
    margin: 0px;
    font-size: 10px;
    color: #9DA0AD;
    text-align: right;
    height: 50%;
    padding-top: 24px;
}

.create-workout-exercise-list-cell-reps-container {
    display: flex;
    float: right;
    height: 50%;
}

.create-workout-exercise-list-cell-reps-content {
    border-color: #2B2B30;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    text-align: center;
    font-size: 10px;
    color: #C1C4CC;
    min-width: 32px;
    margin-left: 8px;
    float: right;
    padding-top: 2px;
    margin-right: 0px;
}

.create-workout-exercise-list-cell-remove-container {
    width: 20px;
    margin-left: 20px;
}

.create-workout-exercise-list-cell-remove-icon {
    color: #F02E4A;
    font-size: 13.5px !important;
    margin-left: 8px;
    margin-top: 12px;
}

.create-workout-exercise-list-cell-superset-button-container {
    top: 12px;
    right: -120px;
    position: absolute;
    display: flex;
    -webkit-transition: all 0.15s;
    -moz-transition: all 0.15s;
    transition: all 0.15s;
}
    .create-workout-exercise-list-adjust-container:hover .create-workout-exercise-list-cell-superset-button-container {
        right: 12px;
        -webkit-transition: all 0.15s;
        -moz-transition: all 0.15s;
        transition: all 0.15s;
    }

    .create-workout-exercise-list-cell-content-outter:hover .create-workout-exercise-list-cell-superset-button-container {
        right: 12px;
        top: 0px;
        -webkit-transition: all 0.15s;
        -moz-transition: all 0.15s;
        transition: all 0.15s;
    }
    .create-workout-exercise-list-cell-content-outter:hover .create-workout-exercise-list-adjust-container-inner-body-video-container-curtain {
        background: #28282DA0;
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
        -webkit-transition: all 0.15s;
        -moz-transition: all 0.15s;
        transition: all 0.15s;
    }

    .create-workout-exercise-list-cell-superset-button {
        padding: 8px 8px;
        width: 30px;
        height: 30px;
        margin-left: 4px;
        background: var(--ef-card);
        border: solid;
        border-width: 1px;
        border-color: var(--ef-border-light);
        display: flex;
        border-radius: var(--ef-radius-m);
        box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.12);
        -webkit-transition: all 0.15s;
        -moz-transition: all 0.15s;
        transition: all 0.15s;
    }
    .create-workout-exercise-list-cell-superset-button:hover {
        background: var(--ef-card-light);
        border-color: var(--ef-border-light);
        cursor: pointer;
        -webkit-transition: all 0.15s;
        -moz-transition: all 0.15s;
        transition: all 0.15s;
    }
        .create-workout-exercise-list-cell-superset-button p {
            margin: 0px;
            margin-left: 6px;
            margin-top: 1px;
            font-size: 12px;
            font-weight: 500;
        }
        .create-workout-exercise-list-cell-superset-button-icon {
            font-size: 13px !important;
            color: var(--ef-text-mid);
        }
            .create-workout-exercise-list-cell-superset-button-icon-remove {
                color: #6C6C6C;
            }
            .create-workout-exercise-list-cell-superset-button:hover .create-workout-exercise-list-cell-superset-button-icon-remove {
                color: #FF4D4D;
            }
    .create-workout-exercise-list-cell-superset-button-small-container {
        margin-left: auto;
        float: right;
        padding: 3px 3px;
        height: 22px;
        width: 22px;
        border-radius: 5px;
        margin-top: 5px;
        -webkit-transition: all 0.15s;
        -moz-transition: all 0.15s;
        transition: all 0.15s;
    }
    .create-workout-exercise-list-cell-superset-button-small-container:hover {
        background: rgba(0,0,0,0.2);
        box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.1);
        cursor: pointer;
        -webkit-transition: all 0.15s;
        -moz-transition: all 0.15s;
        transition: all 0.15s;
    }
        .create-workout-exercise-list-cell-superset-button-small-icon {
            font-size: 15px !important;
            color: #6C6C6C;
        }
        .create-workout-exercise-list-cell-superset-button-small-container:hover .create-workout-exercise-list-cell-superset-button-small-icon {
            color: #ffffff;
        }
    .create-workout-exercise-list-cell-remove-button-small-container {
        margin-left: 4px;
    }




