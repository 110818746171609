/* .new-loading-pattern-modal- */

.new-loading-pattern-modal-background {
	padding-top: 10vh;
}

.new-loading-pattern-modal {
	height: 80vh;
	position: relative;
}
	.new-loading-pattern-modal-loading-curtain {
		position: absolute;
		width: 100%;
		height: calc(100% - 154px);
		top: 86px;
		-webkit-backdrop-filter: blur(8px);
		z-index: 10;
		padding-top: calc(40% - 40px);
	}
		.new-loading-pattern-modal-loading-curtain p {
		    font-size: 12px;
		    color: #9DA0AD;
		    margin: 0px;
		    width: 100%;
		    text-align: center;
		    margin-top: 12px;
		}

	.new-loading-pattern-modal-header {
		width: 100%;
		padding: 32px 32px;
		border-bottom: solid;
		border-width: 1px;
		border-color: #2B2B30;
		display: flex;
	}
		.new-loading-pattern-modal-header h1 {
		    font-size: 18px;
		    color: white;
		    margin: 0px;
		}
		.new-loading-pattern-modal-header-close-button {
			margin-left: auto;
			float: right;
			height: 28px;
			width: 28px;
			border-radius: 5px;
			padding: 7px 7px;
			margin-top: -6px;
			margin-bottom: -12px;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
		.new-loading-pattern-modal-header-close-button:hover {
			cursor: pointer;
			background: rgba(0, 0, 0, 0.2);
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
			.new-loading-pattern-modal-header-close-button-icon {
				font-size: 15px !important;
				color: #6C6C6C;
			}
			.new-loading-pattern-modal-header-close-button:hover .new-loading-pattern-modal-header-close-button-icon {
				color: #ffffff;
			}

	.new-loading-pattern-modal-content {
		padding: 24px 32px;
		height: calc(100% - 150px);
		overflow-y: scroll;
	}


	
		.new-loading-pattern-modal-content-name-container {

		}
			.new-loading-pattern-modal-content-name-input-container {
				width: 100%;
			}
				.new-loading-pattern-modal-content-name-input-container h4 {
				    font-size: 13.5px;
				    color: #ffffff;
				    margin: 0px;
				}
			.new-loading-pattern-modal-content-name-input-container-selector {
				padding-top: 12px;
				margin-bottom: 38px;
			}
				.new-loading-pattern-modal-content-name-input-container-selector h4 {
					padding-bottom: 8px;
				}

		.new-loading-pattern-modal-content-sets-container {
			padding: 24px 0px;
			border-top: solid;
			border-width: 1px;
			border-color: #2B2B30;
		}
			.new-loading-pattern-modal-content-sets-header-container {
				display: flex;
			}
				.new-loading-pattern-modal-content-sets-header-container h4 {
				    font-size: 13.5px;
				    color: #ffffff;
				    margin: 0px;
				}
			.new-loading-pattern-modal-content-sets-content {
				padding: 12px 12px;
			}
				.new-loading-pattern-modal-content-sets-row {
					width: 100%;
					display: flex;
					padding: 8px 0px;
				}
					.new-loading-pattern-modal-content-sets-row-title-container {
						width: 25%;
						min-width: 80px;
						padding-top: 9px;
					}
						.new-loading-pattern-modal-content-sets-row-title-container h4 {
						    font-size: 13.5px;
						    color: #ffffff;
						    margin: 0px;
						}
					.new-loading-pattern-modal-content-sets-row-input-container {
						width: calc(85% - 84px);

					}
						.new-loading-pattern-modal-content-sets-row-input-container-inner {
							width: 100%;
							border: solid;
							border-width: 1px;
							border-color: #2B2B30;
							background: rgba(0, 0, 0, 0.12);
							box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.08);
							border-radius: 5px;
							cursor: pointer;
							-webkit-transition: all 0.15s;
							-moz-transition: all 0.15s;
							transition: all 0.15s;
						}
						.new-loading-pattern-modal-content-sets-row-input-container-inner-focus {
							border-color: #7278F2;
							-webkit-transition: all 0.15s;
							-moz-transition: all 0.15s;
							transition: all 0.15s;
						}
							.new-loading-pattern-modal-content-sets-row-input-container-inner input {
								padding-top: 8px !important;
								padding-bottom: 8px !important;
								padding-left: 12px !important;
								font-size: 13.5px !important;
								text-align: center !important;
								color: #ffffff !important;
								cursor: pointer !important;
							}
						.new-loading-pattern-modal-content-sets-row-input-container-inner h4 {
						    font-size: 13.5px;
						    color: #ffffff;
						    margin: 0px;
						    width: 100%;
						    text-align: center;
						    padding-top: 8px !important;
							padding-bottom: 8px !important;
							cursor: default !important;
						}

					.new-loading-pattern-modal-content-sets-row-close-container {
						margin-left: 12px;
						float: right;
						height: 28px;
						width: 28px;
						border-radius: 5px;
						padding: 7px 7px;
						margin-top: 2px;
						-webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}
					.new-loading-pattern-modal-content-sets-row-close-container:hover {
						cursor: pointer;
						background: rgba(0, 0, 0, 0.2);
						-webkit-transition: all 0.15s;
						-moz-transition: all 0.15s;
						transition: all 0.15s;
					}
						.new-loading-pattern-modal-content-sets-row-close-icon {
							font-size: 15px !important;
							color: #6C6C6C;
						}
						.new-loading-pattern-modal-content-sets-row-close-container:hover .new-loading-pattern-modal-content-sets-row-close-icon {
							color: #ffffff;
						}

		.new-loading-pattern-modal-content-sets-add-more-button {
			padding: 6px 12px;
			margin-left: auto;
			margin-right: -6px;
			float: right;
			border-radius: 5px;
			margin-top: -6px;
			float: left;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
		.new-loading-pattern-modal-content-sets-add-more-button:hover {
			background: rgba(0, 0, 0, 0.2);
			cursor: pointer;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
			.new-loading-pattern-modal-content-sets-add-more-button p {
			    font-size: 13.5px;
			    color: #5B62FF;
			    margin: 0px;
			}
			.new-loading-pattern-modal-content-sets-add-more-button:hover p {
				color: #7278F2;
			}

	.new-loading-pattern-modal-bottom {
		border-top: solid;
		border-width: 1px;
		border-color: #2B2B30;
		padding: 16px 32px;
	}
		.new-loading-pattern-modal-bottom-button {
			margin-left: auto;
			float: right;
		    padding: 8px 12px;
		    background: #5B62FF;
		    display: flex;
		    border-radius: 5px;
		    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.12);
		    -webkit-transition: all 0.15s;
		    -moz-transition: all 0.15s;
		    transition: all 0.15s;
		}
		.new-loading-pattern-modal-bottom-button:hover {
		    background: #7278F2;
		    cursor: pointer;
		    -webkit-transition: all 0.15s;
		    -moz-transition: all 0.15s;
		    transition: all 0.15s;
		}
			.new-loading-pattern-modal-bottom-button p {
			    font-size: 12px;
			    font-weight: 500;
			    color: white;
			    margin: 0px;
			}
				.new-loading-pattern-modal-bottom-button-icon {
					font-size: 11px !important;
					margin-top: 1.5px;
					color: white;
					margin-left: 6px;
				}
		.new-loading-pattern-modal-bottom-button-unselected {
			opacity: 0.3;
		}
		.new-loading-pattern-modal-bottom-button-unselected:hover {
			background: #5B62FF;
			cursor: default;
		}






