
.stat-graph {
	width: 100%;
	height: 100%;
	padding-top: 48px;
	position: relative;
	padding-right: 24px;
}
	.stat-graph-bar-container {
		width: 100%;
		height: 100%;
		display: flex;
		column-gap: 2px;
/*		background: #FF4D4D30;*/
	}
		.stat-graph-bar {
			flex: 1;
			margin-top: auto;
			background: #ffffff10;
			border-radius: 8px;
			min-height: 4px;
			max-height: 100%;
			position: relative;
			z-index: 0;
		}
			.stat-graph-bar-top {
				position: absolute;
				height: 8px;
				border-radius: 8px;
				width: 100%;
				left: 0px;
				top: 0px;
			}
			.stat-graph-bar-num-container {
				position: absolute;
				bottom: -20px;
				left: 0px;
				width: 100%;
			}	
				.stat-graph-bar-num-container p {
				    font-size: 11px;
				    color: #6C6C6C;
				    width: 100%;
				    text-align: center;
				    margin: 0px;
				}
				.stat-graph-bar-num-container-invalid p {
				    
				}
			.stat-graph-bar-metric-container {
				position: absolute;
				top: -20px;
				left: 0px;
				width: 100%;
			}
				.stat-graph-bar-metric-container p {
				    font-size: 11px;
				    color: #ffffff;
				    width: 100%;
				    text-align: center;
				    margin: 0px;
				    white-space: nowrap;
				}
	.stat-graph-target-line {
		width: 2px;
		height: calc(100% - 48px);
		background: #5B62FF;
		position: absolute;
		right: 0px;
		bottom: 0px;
	}
	.stat-graph-target-gradient-container {
		width: 60%;
		height: calc(100% - 48px);
		min-width: 200px;
		position: absolute;
		right: 0px;
		bottom: 0px;
	}
		.stat-graph-target-gradient-image {
			width: 100%;
			height: 100%;
		}


	.stat-graph-target-container {
		width: calc(100% - 24px);
		height: 100%;
		position: absolute;
		top: 0px;
		left: 0px;
		z-index: 2;
		padding-top: 48px;
	}
		.stat-graph-target-container-inner {
			width: 100%;
			height: 100%;
			position: relative;

		}
		.stat-graph-target {
			width: 100%;
			border-top: dashed;
			border-bottom: dashed;
			border-width: 1px;
			border-color: #5BFF62 !important;
			background-color: #5BFF6260 !important;
			position: absolute;
			bottom: 0px;
			left: 0px;
			z-index: 1;
		}
	.stat-graph-average-container {
		width: calc(100% - 24px);
		height: 100%;
		position: absolute;
		top: 0px;
		left: 0px;
		z-index: 2;
		padding-top: 48px;
	}
		.stat-graph-average-container-inner {
			width: 100%;
			height: 100%;
			position: relative;

		}
		.stat-graph-average {
			width: calc(100% + 48px);
			border-top: dashed;
			border-bottom: dashed;
			border-width: 1px;
			position: absolute;
			bottom: 0px;
			left: -24px;
			z-index: 2;
		}
	.stat-graph-date-container {
		position: absolute;
		bottom: -12;
		left: 0;
		width: 100%;
		display: flex;
	}
		.stat-graph-date {
			padding-top: 6px;
		}
			.stat-graph-date p {
			    font-size: 12px;
			    color: #6C6C6C;
			    margin: 0px;
			}
		.stat-graph-date-starting {

		}
		.stat-graph-date-ending {
			margin-left: auto;
			float: right;
		}




