
.client-picker-cell {
	display: flex;
	padding: 8px 12px;
	border-bottom: solid;
	border-width: 1px;
	border-color: #ffffff10;
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
}	
.client-picker-cell:hover {
	background: #ffffff10;
	cursor: pointer;
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
}
	.client-picker-cell-image-container {
		
	}

	.client-picker-cell-text-container {
		padding-top: 4px;
	}
		.client-picker-cell-text-container h4 {
		    font-size: 13.5px;
		    color: #ffffff;
		    margin: 0px;
		}