.upload-image-modal {
/*	height: 50%;
*/	/*min-height: 180px;*/
	height: 360px;
	margin: auto;
	margin-top: 0px;
	min-width: 400px;
	width: 40%;
	max-width: 740px;
	background: #1F1F21;
	border-radius: 5px;
	border: solid;
	border-width: 0.5px;
	border-color: #2B2B30;
	/*overflow: hidden;*/
	box-shadow: 0 0px 100px 12px rgba(0, 0, 0, 0.5);
	position: relative;
	padding: 32px;
}
	.upload-image-modal-header {
		/*padding: 24px;*/
		padding-bottom: 24px;
	}
		.upload-image-modal-header h4 {
			font-size: 18px;
			color: #ffffff;
			margin: 0px;
		}
		

	.upload-image-modal-content {
		width: 100%;
		height: 200px;
		margin: 0px auto;
	}
		.upload-image-modal-content h4 {
			font-size: 13.5px;
			color: #ffffff;
			margin: 0px;
			width: 100%;
			text-align: center;
		}
		.upload-image-modal-loading-bar-container {
			width: 100%;
			display: flex;
			padding-top: 24px;
		}
			.upload-image-modal-loading-bar-container p {
				padding-left: 12px;
				font-size: 10px;
				margin: 0px;
				margin-top: -4px;
				color: #ffffff;
			}
			.upload-image-modal-loading-bar {
				width: 100%;
				height: 6px;
				border-radius: 3px;
				background: rgba(0,0,0,0.2);
				position: relative;
			}
				.upload-image-modal-loading-bar-inner {
					position: absolute;
					height: 6px;
					border-radius: 3px;
					background: #5B62FF;
					top: 0px;
					left: 0px;
				}

		.upload-image-modal-button-container {
			width: 100%;
		}
			.upload-image-modal-button {
				width: 60px;
				margin: 0px auto;
				background: #5B62FF;
				border-radius: 5px;
				padding: 6px 0px;
				box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
			}
				.upload-image-modal-button p {
					font-size: 13.5px;
					color: #ffffff;
					margin: 0px;
					width: 100%;
					text-align: center;
				}

.file-drop {
  /* relatively position the container bc the contents are absolute */
  position: relative;
  height: 140px;
  width: 100%;
  /*background: #1F1F21;*/
  margin: 0px auto;
  border-radius: 5px;
  border: dashed;
  border-width: 2px;
  border-color: #2B2B30;

}
	.file-drop h4 {
		padding-top: 0px;
		width: 200px;
		font-size: 18px;
		color: #ffffff;
		font-weight: 700;
	}
	.file-drop span {
		font-size: 18px;
		color: rgba(112,138,240,1.0);
		font-weight: 700;
	}
	.file-drop p {
		padding-top: 0px;
		font-size: 10px;
		color: #ffffff;
		font-weight: 400;
	}

.file-drop > .file-drop-target {
  /* basic styles */
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 2px;

  /* horizontally and vertically center all content */
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;

  flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;

  align-items: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;

  justify-content: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;

  align-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;

  text-align: center;
}

.file-drop > .file-drop-target.file-drop-dragging-over-frame {
  /* overlay a black mask when dragging over the frame */
  border: none;
  background-color: #1F1F21;
  box-shadow: none;
  z-index: 50;
  opacity: 1;

  /* typography */
  color: white;
}

.file-drop > .file-drop-target.file-drop-dragging-over-target {
  /* turn stuff orange when we are dragging over the target */
  color: #5B62FF;
  background: #5B62FF;
  border-color: #5B62FF;
  box-shadow: 0 0 13px 3px rgba(20,40,200,0.2);
}